import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import {
  mainLogin,
  resetStore,
  isForgotPasswordOpen,
  localeLanguage,
} from "../../actions";
// import LoginErrorBar from "./errorbar";
import TailwindAlert from "../../common/tailwindalert.js";
import ForgotPassword from "./forgotpassword";
import Language from "../dashboard/language/components/index";
import LocaleStrings from "../languages/index.js";
import { classNames } from "../../common/common-functions";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  fetchAdminRoles,
  onSidebarMenuSelected,
} from "../dashboard/sidebar/actions/index";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showerror: false,
      alertMessage: "",
      btnLoader: false,
    };

    // Bind Functions
    this.forGotPassword_click = this.forGotPassword_click.bind(this);
  }

  componentWillMount() {
    if (this.props.isLoggedIn === true) {
      // this.props.history.push("/dashboard/dashboardside");
    } else {
      // this.props.history.push("/login");
    }
  }

  // Forgot Password Button Click
  forGotPassword_click() {
    this.props.isForgotPasswordOpen(true);
  }

  renderField(field) {
    var {
      meta: { touched, error },
    } = field;
    var className = `form-group login-element ${
      touched && error ? "text-red-600 text-xs" : ""
    }`;
    return (
      <div className={className}>
        <label>{field.label}</label>
        <input
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />
        <div className="text-help h-4">{touched ? error : ""}</div>
      </div>
    );
  }

  onFormSubmit(values) {
    this.setState({ loading: true, btnLoader: true });

    this.props.mainLogin(values, this.props.session, (response) => {
      if (response.success === 0) {
        debugger;
        this.setState({
          ...this.state,
          alertMessage: LocaleStrings.invalid_email_password,
          showerror: true,
          loading: false,
          btnLoader: false,
        });
        setTimeout(
          () => this.setState({ ...this.state, showerror: false }),
          2000
        );
        this.props.resetStore();
      } else {
        this.props.fetchAdminRoles(
          this.props.session,
          response.userid,
          (response) => {
            // console.log('response',response)
            this.props.onSidebarMenuSelected(response.data.position);
            this.props.history.push("/dashboard/" + response.data.href);
          }
        );
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.showModal) {
    //   this.setState({ ...this.state, loading: false });
    // }
  }

  // On Language Dropdown Change
  _handelLangauge(e, lang) {
    this.props.localeLanguage(lang);
  }

  // Language Drop Down Component
  renderLanguageDropDown() {
    let { language } = this.props;
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md shadow-sm px-4 py-2  text-sm font-medium text-custWhite uppercase">
            {language}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-custWhite">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? "bg-quaternary text-primary" : "text-secondary",
                      "block px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={(e) => this._handelLangauge(e, "en")}>
                    English
                  </span>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? "bg-quaternary text-primary" : "text-secondary",
                      "block px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={(e) => this._handelLangauge(e, "ar")}>
                    عربي
                  </span>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

  render() {
    var { handleSubmit, isforgotfasswordopen } = this.props;

    const navigation = [
      { name: "Solutions", href: "#" },
      { name: "Pricing", href: "#" },
      { name: "Docs", href: "#" },
      { name: "Company", href: "#" },
    ];
    return (
      <>
        <div
          className={classNames(this.props.language === "en" ? "" : "rtl", "")}>
          <header className="bg-primary min-h-64 px-6">
            <nav
              className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
              aria-label="Top">
              <div className="w-full flex items-center justify-between border-b border-primary lg:border-none">
                <div className="flex items-center gap-4">
                  <a href="#">
                    <span className="sr-only">Shopez-Superadmin-Web</span>
                    <img
                      className="h-4 w-auto bg-custWhite"
                      src="../images/product_management_images/app_suggestions.svg"
                      alt="image"
                    />
                  </a>
                  {this.renderLanguageDropDown()}
                </div>
              </div>
            </nav>
          </header>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div
            className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 "
            style={{ marginTop: "40%" }}>
            <div className="mb-12">
              <div className="text-secondary font-normal text-3xl">
                {LocaleStrings.superadmin_signin}
              </div>
              <div className="text-hint font-normal text-ternary">
                {LocaleStrings.enteremail_pass}
              </div>
            </div>
            <div className="space-x-4 ">
              {this.state.showerror ? (
                // <LoginErrorBar alertMessage={this.state.alertMessage} />

                <TailwindAlert
                  alertType="error"
                  alertMessage={this.state.alertMessage}
                />
              ) : (
                ""
              )}
              <ForgotPassword
                data={this.props.isforgotfasswordopen}
                hedaer={LocaleStrings.forgot_password}
              />
              <form
                className="grid  md:grid-cols-3 gap-6"
                onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
                style={{ display: "block" }}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-ternary">
                    {LocaleStrings.email}
                  </label>
                  <div className="mt-1">
                    <Field
                      name="email"
                      placeholder={LocaleStrings.email}
                      type="email"
                      component={this.renderField}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-ternary">
                    {LocaleStrings.password}
                  </label>
                  <div className="mt-1">
                    <Field
                      name="password"
                      type="password"
                      placeholder={LocaleStrings.password}
                      component={this.renderField}
                    />
                  </div>
                  <div className="text-sm">
                    <a
                      href="#"
                      onClick={this.forGotPassword_click}
                      className="font-medium text-primary hover:text-primaryHover">
                      {LocaleStrings.forgot_your_password}
                    </a>
                  </div>
                </div>

                <div className="flex flex-wrap content-center my-10">
                  <button type="submit" className="btn-primary">
                    <svg
                      className={classNames(
                        this.state.btnLoader ? "" : "sr-only",
                        "animate-spin-medium h-5 w-5 rounded-full mx-2"
                      )}
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                    {LocaleStrings.signin}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <NotificationContainer />
        {/* </div>     
      </nav>
    </header> */}
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.login_form_validation_email_required;
  }

  if (!values.password) {
    errors.password = LocaleStrings.login_form_validation_password_required;
  }
  return errors;
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  showModal: state.openLoginOTPModal,
  isforgotfasswordopen: state.isForgotPasswordOpen,
  language: state.language,
});

export default reduxForm({
  validate,
  form: "LoginForm",
})(
  connect(mapStateToProps, {
    mainLogin,
    resetStore,
    isForgotPasswordOpen,
    localeLanguage,
    fetchAdminRoles,
    onSidebarMenuSelected,
  })(Login)
);
