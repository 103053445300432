import axios from "axios";
import { APP_API_KEY, INSTANCE_URL, LOGIN } from "../common/constants.js";
import { postRequest } from "../common/network-call";
import LocaleStrings from "../components/languages/index";

export var ACTION_LOGOUT = "ACTION_LOGOUT";
export var SESSION_TOKEN = "SESSION_TOKEN";
export var USER_PICTURE = "USER_PICTURE";
export var USER_NAME = "USER_NAME";
export var USER_ID = "USER_ID";
export var USER_EMAIL = "USER_EMAIL";
export var USER_TYPE = "USER_TYPE";
export var IS_APPROVE = "IS_APPROVE";
export var IS_ACTIVE = "IS_ACTIVE";
export var IS_VENDORTOO = "IS_VENDORTOO";
export var CAN_RECEIVE_NOTIFICATION = "CAN_RECEIVE_NOTIFICATION";
export var DEVICE_SIZE = "DEVICE_SIZE";
export var IS_FORGOT_PASSWORD_OPEN = "IS_FORGOT_PASSWORD_OPEN";
export var LOCALE_LANGUAGE = "LOCALE_LANGUAGE";
export var SET_CURRENCY = "SET_CURRENCY";
export var USER_PHONE = "USER_PHONE";
export var COUNTRY_PHONE_LENGTH = "COUNTRY_PHONE_LENGTH";

export function localeLanguage(lang) {
  if (lang === "ar") {
    LocaleStrings.setLanguage("ar");
  } else if (lang === "en") {
    LocaleStrings.setLanguage("en");
  } else {
  }

  return { type: LOCALE_LANGUAGE, payload: lang };
}
export function setCurrency(currency) {
  return { type: SET_CURRENCY, payload: currency };
}

export function deviceSize(size) {
  return { type: DEVICE_SIZE, payload: size };
}

// Forgot password Modal open or not
export function isForgotPasswordOpen(isOpen) {
  return { type: IS_FORGOT_PASSWORD_OPEN, payload: isOpen };
}

export function resetStore() {
  return (dispatch) => {
    dispatch({ type: "RESET" });
    dispatch({ type: SESSION_TOKEN, payload: {} });
  };
}

export function mainLogin(values, session, callback) {
  //This function is used on Login screen only
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var data = {
    email: values.email,
    password: values.password,
    type: "email",
  };
  // console.log('data',data)
  var url = `${INSTANCE_URL}/api/v2/user/session`;
  var response = axios.post(url, data, config);
  return (dispatch) => {
    response
      .then(({ data }) => {
        //Validate if the user is admin or, not
        var checkusertype = data.usertype;
        if (checkusertype == "superadmin" || checkusertype == "moderator") {
          dispatch({ type: SESSION_TOKEN, payload: data });
          dispatch({ type: USER_ID, payload: data.userid });
          dispatch({ type: USER_EMAIL, payload: data.email });
          dispatch({ type: USER_TYPE, payload: data.usertype });
          dispatch({ type: USER_NAME, payload: data.username });
          dispatch({ type: USER_PHONE, payload: data.phone });
          dispatch({ type: USER_PICTURE, payload: data.userimage });
          dispatch({ type: IS_APPROVE, payload: data.isapprove });
          dispatch({ type: IS_ACTIVE, payload: data.isactive });
          dispatch({
            type: CAN_RECEIVE_NOTIFICATION,
            payload: data.canreceivenotification,
          });
          dispatch({ type: LOGIN, payload: true });
          callback({ success: 1, userid: data.userid });
        }
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function logout(session) {
  // var url = `${INSTANCE_URL}/api/v2/user/session`;
  // var response = axios.delete(url, getHeaders(session));
  // console.log('response',response);
  // return (dispatch) => {
  //   response
  //     .then(({ data }) => {
  //       dispatch({ type: ACTION_LOGOUT, payload: false });
  //       dispatch({ type: "RESET" });
  //     })
  //     .catch((error) => {
  //       // nothing to do
  //       dispatch({ type: ACTION_LOGOUT, payload: false });
  //     });
  // };

  return (dispatch) => {
    dispatch({ type: ACTION_LOGOUT, payload: false });
    dispatch({ type: "RESET" });
  };
}

// FORGOT PASSWORD

/**** Sent reset link to superadmin****/
export function updatesource(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/updatesource`;

  return (dispatch) => {
    postRequest(
      url,
      object,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        // callback({
        //   status: 0,
        //   message: LocaleStrings.something_went_wrong,
        //   data: error,
        // });
      }
    );
  };
}

export function countryPhoneLength(length = "974") {
  return (dispatch) => {
    dispatch({ type: COUNTRY_PHONE_LENGTH, payload: length });
  };
}
