import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import {
  fetchCategory,
  subcategorymodal,
  clearsubcategorymodal,
} from "../actions/index";
import CategorylistItems from "./category/categorylist-items";
import _ from "lodash";
import AddCategory from "./addcategory";
import SubCategorylistItems from "./subcategory/subcategory-items";
import Addsubcategory from "./addsubcategory";
import toast, { Toaster } from "react-hot-toast";
import { NotificationContainer } from "react-notifications";
import CategoryOrder from "./categoryDisplayOrder/category_order";
import BaseLoader from "../../../../common/base-loader";
import SubCategoryOrder from "./subcategoryDisplayOrder/subcategory_order";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../common/base-component.js";
import { classNames } from "../../../../common/common-functions";

class CategoryManagement extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.category_management,
      categorySearchText: "",
      selectedcategory: 1,
      category_text: "Active Categories",
    };

    // All Binded Functions
  }

  componentDidMount() {
    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation.data, {
      href: answer,
    });

    if (singleNavigationRow == undefined) {
      this.props.history.push("/login");
    }
    this.props.fetchCategory(this.props.session, (CallBackResponse) => {
      this.setState({ loading: false });
    });
  }

  rendercategoryList() {
    let language = this.props.language;
    let searchText = this.state.categorySearchText;
    let selectedcategory = this.state.selectedcategory;
    let filtered = [];

    if (this.state.categorySearchText !== "") {
      // If Search Key Found
      let filteractiveorinactivecat = _.filter(
        this.props.categorylist.data,
        function (o) {
          return o.isactive == selectedcategory;
        }
      );
      let listing = filteractiveorinactivecat;

      filtered = _.filter(listing, function (o) {
        // console.log('o',o)
        return (
          o.categoryname_en.toLowerCase().indexOf(_.toLower(searchText)) > -1
        );
      });
    } else {
      // If Search Key Not Found Send Orginal Array
      let filteractiveorinactivecat = _.filter(
        this.props.categorylist.data,
        function (o) {
          return o.isactive == selectedcategory;
        }
      );
      filtered = filteractiveorinactivecat;
    }
    var items = _.map(filtered, (values) => (
      <CategorylistItems key={values.userid} values={values} />
    ));
    return items;
  }
  rendersubcategoryList() {
    // var item = <div><Addsubcategory/></div>
    var items = _.map(this.props.subcategorylist.data, (values) => (
      <SubCategorylistItems key={values.userid} values={values} />
    ));
    return items;
  }

  _handleselectionChange = (e) => {
    console.log("e", e.target.value);
    this.setState({ selectedcategory: e.target.value });
    if (e.target.value == "0") {
      this.setState({ category_text: "Inactive Categories" });
    } else {
      this.setState({ category_text: "Active Categories" });
    }
    this.props.subcategorymodal({});
    this.props.clearsubcategorymodal({});
  };

  render() {
    let { categoryDropdownarr, language } = this.props;

    let dropOptionarr = [];

    _.forEach(categoryDropdownarr, (val, i) => {
      var obj = {
        value: val.value,
        name: val.name,
      };
      dropOptionarr.push(obj);
    });

    return (
      <>
        {this.state.loading ? (
          <>
            {" "}
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div
              className="grid gap-4 grid-rows-1 px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1"
              style={{
                overflowX: "hidden",
                overflowY: "hidden",
                display: "block",
              }}>
              <div className="mb-4 text-2xl flex font-semibold text-secondary">
                {LocaleStrings.category_management_heading}
              </div>
              <div className="flex end-item">
                {this.props.categorylist.data.length > 0 ? (
                  <span style={{ marginRight: "10px", marginLeft: "10px" }}>
                    <CategoryOrder />
                  </span>
                ) : (
                  ""
                )}
                <AddCategory />
              </div>

              <div
                className="relative md:w-6/12 xs:w-full text-ternary focus-within:text-secondary smallscreen-search"
                style={{ marginTop: -35 }}>
                <div
                  className={classNames(
                    language == "en" ? "left-0 " : "right-0",
                    "absolute inset-y-0 flex items-center pointer-events-none px-1"
                  )}>
                  <SearchIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className={classNames(
                    language == "en" ? "pl-8 pr-3" : "pr-8 pl-3",
                    "block lg:w-6/12 xs:w-full sm:w-full h-full  py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                  )}
                  placeholder="Search Category"
                  type="search"
                  name="search"
                  onChange={(e) =>
                    this.setState({ categorySearchText: e.target.value })
                  }
                  // value={this.state.search}
                  // onChange={this.handleSearch}
                />
              </div>
              <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mt-8">
                <div className="box-border  w-full bg-custWhite mb-5 rounded-sm">
                  <div className=" grid grid-cols-1 grid-flow-col gap-4 pl-3 mt-1 border-b-2 border-gray-100 ">
                    <div className="text-ternary  group flex items-center px-3 py-2 text-sm font-medium rounded-md center-item">
                      {" "}
                      {this.state.category_text}
                    </div>
                  </div>
                  <div className="p-4 w-2/4">
                    <Field
                      name="select_category"
                      label="Choose"
                      placeholder={LocaleStrings.select}
                      component={this.renderSelect}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      opts={dropOptionarr}
                      className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                      classNameLabel="block text-box-lable"
                      onChange={this._handleselectionChange.bind(this)}
                      // value={this.props.initialValues.type}
                    />
                  </div>
                  <div
                    className="overflow-x-auto scrollbar-hide category-height"
                    // style={{ height: "530px" }}
                  >
                    {this.rendercategoryList()}
                  </div>
                </div>
                <div className="box-border  w-full bg-custWhite mb-5 rounded-sm">
                  <div className=" grid grid-cols-1 grid-flow-col gap-4 pl-3 mt-1 border-b-2 border-gray-100">
                    <div className="text-ternary  group flex items-center px-3 py-2 text-sm font-medium rounded-md center-item">
                      {" "}
                      {LocaleStrings.subcategory}
                    </div>
                  </div>
                  <div className="overflow-x-auto scrollbar-hide category-height">
                    {this.props.subcategorymodalstore == "undefined" ||
                    _.isEmpty(this.props.subcategorymodalstore) ? (
                      <>
                        <div className="center-item lg:pt-40 sm:pt-25 text-ternary">
                          {LocaleStrings.category_management_selectCategory}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex end-item">
                          <div className="px-3 py-3">
                            <SubCategoryOrder />
                          </div>
                          <div className="px-3 py-3">
                            <Addsubcategory
                              data={this.props.subcategorymodalstore}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {/* <div className="px-3 py-3"><Addsubcategory data={this.props.subcategorymodalstore} /></div> */}
                    {this.props.subcategorylist.data == 0 ? (
                      <>
                        {!_.isEmpty(this.props.subcategorymodalstore) ? (
                          <div className="center-item lg:pt-40 sm:pt-25 text-ternary">
                            {LocaleStrings.nosubadded}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        <div>{this.rendersubcategoryList()}</div>
                      </>
                    )}
                    {/* <div>{this.rendersubcategoryList()}</div>  */}
                  </div>
                </div>
              </div>
            </div>
            <Toaster />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log('subcategorymodalstore',state.subcategorymodalstore);

  let initVal = {
    select_category: 1,
  };
  return {
    session,
    user,
    categorylist: state.categorylist,
    subcategorylist: state.subcategorylist,
    language: state.language,
    subcategorymodalstore: state.subcategorymodalstore,
    navigation: state.sideBarMenus,
    categoryDropdownarr: state.categoryDropdownarr,
    initialValues: initVal,
  };
}

export default connect(mapStateToProps, {
  fetchCategory,
  subcategorymodal,
  clearsubcategorymodal,
})(
  reduxForm({
    form: "catindexform",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(CategoryManagement)
);
