import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../languages/index.js";
import { Fragment } from "react";
import { BaseComponent } from "../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import "react-phone-input-2/lib/material.css";
import _ from "lodash";
import {
  uploadMediaLargeRestrictedWord,
  addRestrictedWords,
  fetchRestrictedWords,
} from "../../actions/index";
import { NotificationManager } from "react-notifications";

var threeArr = [{}, {}, {}];

// Constant
let validateExtention = ["doc", "docm", "docx", "docx", "pdf"];

class AddRestrictedWords extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      enar: "en",
      open: false,
      setOpen: false,
      fileName: "",
      fileArr: [],
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
    this.props.reset();
  }

  onSubmitForm(values) {
    this.setState({ btnLoader: true });

    // if (
    //   Object.keys(threeArr[0]).length +
    //     Object.keys(threeArr[1]).length +
    //     Object.keys(threeArr[2]).length ===
    //   0
    // ) {
    //  toast.error(LocaleStrings.attachfile_to_update_error_text);

    let restrictedwords = {
      word_ar: values.word_ar,
      word_en: values.word_en,
    };

    // After file upload
    this.props.addRestrictedWords(
      this.props.session,
      restrictedwords,
      (callback) => {
        if (callback.success === 1) {
          // threeArr[0] = {};
          NotificationManager.success(LocaleStrings.addedsucessfully);
          this.setState({ open: false });
          // Fetch Vendor Details With LoginIdStore
          this.props.fetchRestrictedWords(
            this.props.session,
            (CallBackResponse) => {}
          );
        }
      }
    );
    // } else {
    //   // Upload Section
    //   let uploaderCounter = 0;
    //   let filetobeupload = [];
    //   let arrPromise = [];

    //   // Blank File Nmae array
    //   let word_file = "";

    //   _.forEach(threeArr, function (value, index) {
    //     if (Object.keys(value).length > 0) {
    //       if (index === 0) {
    //         word_file = value.name;
    //       }

    //       let coverJson = {
    //         type: value.type,
    //         name: value.name,
    //         media: value.url,
    //       };

    //       filetobeupload.push(coverJson); //
    //     }
    //   });

    //   // console.log('filetobeupload',filetobeupload);
    //   let counter = filetobeupload.length;
    //   // console.log('counter',counter);

    //   // Store File to server first
    //   filetobeupload.map((item, index) => {
    //     arrPromise.push(
    //       new Promise((resolve, reject) => {
    //         this.props.uploadMediaLargeRestrictedWord(
    //           this.props.session,
    //           item,
    //           (response) => {
    //             if (response.success === 0) {
    //               resolve();
    //             } else {
    //               uploaderCounter++;
    //               resolve();
    //             }
    //           }
    //         );
    //       })
    //     );
    //   });

    //   Promise.all(arrPromise).then(() => {
    //     if (uploaderCounter === counter) {
    //       // Store Object
    //       let restrictedwords = {
    //         word_file: word_file,
    //         word_en: values.word_en,
    //         word_ar: values.word_ar,
    //       };

    //       // After file upload
    //       this.props.addRestrictedWords(
    //         this.props.session,
    //         restrictedwords,
    //         (callback) => {
    //           if (callback.success === 1) {
    //             threeArr[0] = {};

    //             NotificationManager.success(LocaleStrings.addedsucessfully);
    //             this.setState({ open: false });

    //             this.props.fetchRestrictedWords(this.props.session, (CallBackResponse) => {

    //             });
    //           }
    //         }
    //       );
    //     } else {
    //       NotificationManager.error(LocaleStrings.addingfailed);
    //       this.setState({ open: false });
    //     }
    //   });
    // }
  }
  /**** INPUT LANGUAGE CHANGE en - ar ****/
  handleInputLanguageChange(e) {
    if (parseInt(e.target.value) === 1) {
      this.setState({ enar: "ar" });
    } else {
      this.setState({ enar: "en" });
    }
  }

  _handleSelect(event, typeindex) {
    const reader = new FileReader(); // Define reader
    let file = event.target.files[0]; // Take Selected file
    reader.readAsArrayBuffer(file); // Read the file

    // On File Load
    reader.onload = (e) => {
      file.url = e.target.result;
      // file.name   = file.name;
      // file.url = reader.result;
      //filearr.push(file);
    };

    let ext = file.name.split(".").pop(); // File extension

    if (_.indexOf(validateExtention, ext) === -1) {
      // Validate File Extensions
      NotificationManager.error(
        LocaleStrings.upload_only_doc_and_pdf_file_error_text
      );
    } else {
      this.props.autofill(typeindex, file.name);
      if (typeindex === "word_file") {
        threeArr[0] = file;
      } else if (typeindex === "agreement_template") {
        threeArr[1] = file;
      } else if (typeindex === "idproof") {
        threeArr[2] = file;
      }
    }
  }

  render() {
    // console.log('initialValues',this.props.initialValues);
    var { handleSubmit, pristine, reset, submitting, invalid } = this.props;
    let { enar, fileArr } = this.state;
    return (
      <>
        <div className=" mt-5" style={{ overflowX: "hidden" }}>
          <div className="flex flex-wrap content-center justify-end ">
            <button
              onClick={this.openModal}
              type="submit"
              className="lg:w-48 md:w-44 sm:w-44 xs:w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
              {LocaleStrings.global_settingAddRestrictedWord}
              <img
                src="../images/product_management_images/add.svg"
                className="h-5 ml-2"
              />
            </button>
            <Transition.Root show={this.state.open} as={Fragment}>
              <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                open={this.state.open}
                onClose={this.closeModal}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
                  </Transition.Child>
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div
                      className="inline-block align-bottom max-h-96 bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                      style={{ padding: 0 }}>
                      {/* MOdal Header */}
                      <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                        <div className="flex flex-wrap pl-6 mt-4">
                          <div className="text-md leading-6 font-medium text-secondary">
                            {LocaleStrings.global_settingAddRestrictedWord}
                          </div>
                        </div>
                        <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                          <button
                            type="button"
                            className="btn-closeModal"
                            onClick={this.closeModal}>
                            <span className="sr-only">
                              {LocaleStrings.close}
                            </span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <form
                        className=""
                        onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
                        {/* MOdal Header Ends*/}
                        <div className="p-6">
                          <div style={{ display: "flex" }}>
                            <label className="mr-5 text-box-lable">
                              {LocaleStrings.language}
                            </label>

                            <Field
                              name="language"
                              label={LocaleStrings.en}
                              component={this.renderFieldRadioNew}
                              type="radio"
                              value="0"
                              className="mx-2"
                              onChange={this.handleInputLanguageChange.bind(
                                this
                              )}
                            />

                            <Field
                              name="language"
                              label={LocaleStrings.ar}
                              component={this.renderFieldRadioNew}
                              type="radio"
                              value="1"
                              className="mx-2"
                              onChange={this.handleInputLanguageChange.bind(
                                this
                              )}
                            />
                          </div>
                          <div className="my-6">
                            <Field
                              name={"word_" + enar}
                              label={"Word_" + enar}
                              placeholder={"Word_" + enar}
                              type="text"
                              component={this.renderAllfields}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="w-full bottom-0 absolute mb-10">
                          <div className="flex justify-center">
                            <button type="submit" className="button">
                              {LocaleStrings.save}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        </div>
      </>
    );
  }
}
function validate(values) {
  var errors = {};

  var requiredFields = ["word_en", "word_ar"];
  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = "Required";
    }
  });

  return errors;
}
function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log("global_commissionlist", state.global_commissionlist);
  let newObj = {};
  // if (state.global_commissionlist)
  newObj = {
    language: "0",
  };
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    initialValues: newObj,
  };
}

export default connect(mapStateToProps, {
  uploadMediaLargeRestrictedWord,
  addRestrictedWords,
  fetchRestrictedWords,
})(
  reduxForm({
    validate,
    form: "ADDRESTRICTED_WORDS",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddRestrictedWords)
);
