import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import TicketsandReportslistTabs from "./ticketreporttabs";

class TicketReports extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }
  componentDidMount() {
    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation.data, {
      href: answer,
    });

    if (singleNavigationRow == undefined) {
      this.props.history.push("/login");
    }
    this.props.history.push("/dashboard/tickets_reports/tickets");
  }

  customerdetails = (e, data) => {
    //  console.log("this.props.data", this.props.data)
    this.setState({ showUploadSection: !this.state.showUploadSection });
    //   this.props.data();
  };

  render() {
    return (
      <TicketsandReportslistTabs
        showUploadSection={this.customerdetails}
        history={this.props.history}
      />
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  navigation: state.sideBarMenus,
});

export default connect(mapStateToProps, {})(TicketReports);
