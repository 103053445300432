import React from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../../../common/base-component";
import _ from "lodash";
import { currencyCode, currencyRate } from "../../../../currency/action/index";
import { getIntToFloat } from "../../../../../../common/common-functions";
import PayoutModal from "../payoutmodal";
import { confirmAlert } from "react-confirm-alert";
import LocaleStrings from "../../../../../languages/index";
import { classNames } from "../../../../../../common/common-functions";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { updateOrderPayout, fetchunpaidPayouts } from "../../../actions/index";

const limit = 10;

class UnpaidPayoutListitem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  _handlemarkApprove(e, values) {
    let updateOrderPayoutArr = [];
    if (values.data && values.data.length > 0) {
      _.forEach(values.data, function (val) {
        var obj = {
          id: val.orderid,
          payout: 1,
          // value: value.value,
        };

        updateOrderPayoutArr.push(obj);
      });
    }
    console.log("updateOrderPayoutArr", updateOrderPayoutArr);
    debugger;
    this.setState({ loading: true });
    confirmAlert({
      title: "Approve Payout",
      message: `Are you sure you want to approve payout for this vendor`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.updateOrderPayout(
              this.props.session,
              updateOrderPayoutArr,
              (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error == 1) {
                  this.setState({ loading: false });
                  NotificationManager.error("Something went wrong");
                } else {
                  this.props.fetchunpaidPayouts(
                    this.props.session,
                    limit,
                    this.props.currentPage, // page
                    this.props.startdate, // Start date
                    this.props.enddate, // End date
                    0,
                    (callBack) => {
                      this.setState({ loading: false });
                      NotificationManager.success("Marked as approved");
                    }
                  );
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {
            this.setState({ loading: false });
          },
        },
      ],
    });
  }

  render() {
    var { values, localCurrency, pageNumber, startdate, enddate } = this.props;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    let data = [];

    if (values.hasOwnProperty("data") && values.data.length > 0) {
      data = values.data;
    }

    // console.log('enddate',enddate)
    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 hover-all cursor-pointer">
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}>
            {_.startCase(values.storename)}
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}>
            {currency} {values.totalprice}
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}>
            {currency} {values.total_shopez_commision}
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}>
            {currency} {values.total_payout}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <button
              className="button flex"
              onClick={(e) => this._handlemarkApprove(e, values)}
              type="button">
              <svg
                className={classNames(
                  this.state.loading ? "" : "sr-only",
                  "animate-spin-medium h-5 w-5 rounded-full mx-2"
                )}
                viewBox="0 0 1024 1024"
                focusable="false"
                data-icon="loading"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true">
                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
              </svg>
              Mark Approve
            </button>
          </td>
        </tr>
        {this.state.isOpen ? (
          <PayoutModal
            isOpen={this.state.isOpen}
            onClose={this.closeModal}
            data={data}
            val={values}
            comesFrom="unpaidpayout"
            currentPage={pageNumber}
            startdate={startdate}
            enddate={enddate}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    localCurrency: state.currency,
    language: state.language,
  };
}

export default connect(mapStateToProps, {
  updateOrderPayout,
  fetchunpaidPayouts,
})(UnpaidPayoutListitem);
