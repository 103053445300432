import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages";
import { SearchIcon } from "@heroicons/react/solid";
import _ from "lodash";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { fetchVendorsMonth, downloadReports } from "../../../actions/index";
import VendorsDataStats from "./vendor-items";
import { classNames } from "../../../../../../common/common-functions";
class VendorsThismonth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.specs_management,
      SpecsSearchText: "",
      pageNumber: 1,
      btnLoader: false,
      isBtnDisable: false,
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchVendorsMonth(this.props.session, (CallBackResponse) => {
      this.setState({ loading: false });
    });
  }

  downloadDashBoardReport = (e) => {
    this.setState({ btnLoader: true, isBtnDisable: true });
    let type = "vendors";
    let tab = "thismonth";

    this.props.downloadReports(this.props.session, type, tab, (callBack) => {
      if (callBack.success == 0) {
        NotificationManager.error("Something went Wrong");
      } else {
        window.location.href = callBack.message;
      }
      this.setState({ btnLoader: false, isBtnDisable: false });
    });
  };

  renderList() {
    var items = _.map(this.props.allvendorsThismonth.data, (values) => (
      <VendorsDataStats key={values.userid} values={values} />
    ));
    return items;
  }

  render() {
    return (
      <>
        {this.props.allvendorsThismonth.data == 0 ? (
          <>
            <div className="center-item pt-24 text-lg text-ternary">
              {LocaleStrings.dashboard_Month_noData}
            </div>
          </>
        ) : (
          <>
            <div
              className="grid gap-4 grid-rows-1 px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 mt-5 dashboard-tablescroll scrollbar-hide"
              style={{
                overflowX: "hidden",
                display: "block",
              }}>
              <div className="flex flex-col pb-2">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full ">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-custWhite">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {LocaleStrings.name}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {LocaleStrings.email}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {LocaleStrings.registeredon}
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.renderList()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3">
                <div></div>
                <div>
                  <button
                    type="button"
                    disabled={this.state.isBtnDisable}
                    className={classNames(
                      this.state.isBtnDisable ? "cursor-not-allowed" : "",
                      "button"
                    )}
                    onClick={(e) => this.downloadDashBoardReport()}>
                    <svg
                      className={classNames(
                        this.state.btnLoader ? "" : "sr-only",
                        "animate-spin-medium h-5 w-5 rounded-full mx-2"
                      )}
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                    {LocaleStrings.common_downloadReport}
                  </button>
                </div>
                <div></div>
              </div>
            </div>

            <NotificationContainer />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    allvendorsThismonth: state.allvendorsThismonth,
  };
}

export default connect(mapStateToProps, {
  fetchVendorsMonth,
  downloadReports,
})(VendorsThismonth);
