import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import CharityListitem from "./charityeventlist-item";
import { fetchCharityEvents } from "../../../actions/index";
import _ from "lodash";
import Pagination from "react-js-pagination";
import { NotificationContainer } from "react-notifications";
import BaseLoader from "../../../../../../common/base-loader.js";
import Addnewcharity from "./addcharity";

class CharityEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // showUploadSection : false,
      load: true,
      loaderDesc: LocaleStrings.customer_management,
      pageNumber: 1,
      customerSearchText: "",
      search: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchCharityEvents(this.props.session, 1, (CallBackResponse) => {
      console.log("CallBackResponse", CallBackResponse);
      this.setState({ load: false });
    });
  }

  paginationCallback = (pageNumber) => {
    this.props.fetchCharityEvents(
      this.props.session,
      pageNumber,
      (response) => {}
    );

    this.setState({ ...this.state, pageNumber });
  };

  renderList() {
    let filtered = [];

    filtered = this.props.charityeventList.data;

    let pageDataLength = this.props.charityeventList.data.length;

    let { pageNumber } = this.state;
    // console.log('check');
    var items = _.map(filtered, (values, index) => (
      <CharityListitem
        key={index}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    return items;
  }

  render() {
    // console.log("this.state.load", this.state.load);
    return (
      <>
        {this.state.load ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div
              className="grid gap-4 grid-rows-1 px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1"
              style={{
                overflowX: "hidden",
                overflowY: "hidden",
                display: "block",
              }}>
              <Addnewcharity />

              {this.props.charityeventList.count > 0 ? (
                <div className="panel-body mt-6">
                  {/* <TableContent /> */}
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-custWhite">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Description
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Percentage
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>{this.renderList()}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.props.charityeventList.count > 7 ? (
                    <Pagination
                      activePage={this.state.pageNumber}
                      itemsCountPerPage={10}
                      totalItemsCount={this.props.charityeventList.count}
                      pageRangeDisplayed={10}
                      onChange={this.paginationCallback}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="mt-48 center-item text-ternary">
                  Charity(s) Not Found
                </div>
              )}

              <NotificationContainer />
            </div>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, charityeventList } = state;
  return {
    session,
    user,
    charityeventList,
  };
}

export default connect(mapStateToProps, {
  fetchCharityEvents,
})(CharityEvent);
