import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index";
import { Field, reduxForm } from "redux-form";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import TermsAndCondition from "./termsandcondition/termsandcondition";
import PrivacyPolicy from "./privacypolicy/privacypolicy";
import VendorsSalesPolicy from "./vendorssalespolicy/vendorssalespolicy";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import AllGlobalTabsList from "../globaltabs";
import { fetchglobalCommision } from "../../actions/index";

class GlobalLegalDocuments extends BaseComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.fetchglobalCommision(
      this.props.session,
      (CallBackResponse) => {}
    );
  }

  render() {
    var { data, initialValues, handleSubmit } = this.props;
    // console.log("data", data);
    return (
      <>
        <AllGlobalTabsList history={this.props.history} />
        <div className="m-6">
          <TermsAndCondition />
          <PrivacyPolicy />
          <VendorsSalesPolicy />
          <NotificationContainer />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  // console.log('ownProps.data', ownProps.data);
  return {
    session: state.session,
  };
}

export default connect(mapStateToProps, {
  fetchglobalCommision,
})(
  reduxForm({
    form: "LegalDocumentForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(GlobalLegalDocuments)
);
