import LocaleStrings from "../../../languages/index";
import {
  MASTERALL_TRANSACTION,
  PAYOUTS_DATA,
  UNPAID_PAYOUTS_DATA,
  CHARITY_PAYOUTS_DATA,
} from "../actions/index";

export var alltransactions = (state = {}, action) => {
  switch (action.type) {
    case MASTERALL_TRANSACTION:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var payoutDatalist = (state = {}, action) => {
  switch (action.type) {
    case PAYOUTS_DATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var unpaidpayoutDatalist = (state = {}, action) => {
  switch (action.type) {
    case UNPAID_PAYOUTS_DATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var charitypayoutDatalist = (state = {}, action) => {
  switch (action.type) {
    case CHARITY_PAYOUTS_DATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var transactionmanagementTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    {
      name: "All Transactions",
      href: "alltransaction",
      current: true,
      icon: "sales_management",
      position: 1,
    },
    {
      name: "Payouts",
      href: "payout",
      current: false,
      icon: "sales_management",
      position: 2,
    },
    {
      name: "Charity Payouts",
      href: "charitypayout",
      current: false,
      icon: "sales_management",
      position: 3,
    },
  ];

  return state;
};

export var payoutTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Unpaid Payouts", href: "#", current: true, tabindex: 1 },
    { name: "Paid Payouts", href: "#", current: false, tabindex: 2 },
  ];

  return state;
};
