import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import { BaseComponent } from "../../../../../common/base-component";
import TicketMedia from "../Tickets/ticket-media";
import { SuggestionReply } from "../../actions/index";
import SuggestionReplyModal from "./suggestionreplyModal";
import LocaleStrings from "../../../../languages";
class AppSuggestionitems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
      unbanopen: false,
    };
  }

  openModal = (e, obj) => {
    this.props.SuggestionReply(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  renderList() {
    var items = _.map(this.props.values.media, (values, index) => (
      <TicketMedia key={index} values={values} />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }

  render() {
    var { values } = this.props;
    // console.log('appsuggestion', values);
    let tabledate = "";
    tabledate = moment(values.createdat).format("D MMM YYYY HH:mm A");
    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{values.reportedby.username}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{values.email}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{tabledate}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {values.type == "" ? (
              <div>{LocaleStrings.ticketsReports_appFeaturetext}</div>
            ) : (
              <div>{_.startCase(values.type)}</div>
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {/* <div>{values.feedback}</div> */}
            {values.feedback.length > 35
              ? values.feedback.substring(0, 30) + "..."
              : values.feedback}
            <div>{this.renderList()}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div className="action-button-area" style={{ display: "flex" }}>
              <div className="">
                {values.replied == 0 ? (
                  <>
                    {" "}
                    <button
                      className="button"
                      onClick={(e) => this.openModal(e, values)}>
                      {LocaleStrings.ticketsReports_sendreplyText}
                    </button>{" "}
                    {this.state.isOpen ? (
                      <SuggestionReplyModal
                        isOpen={this.state.isOpen}
                        onClose={this.closeModal}
                        data={this.props.values}
                        currentPage={this.props.currentPage}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    <button className="button">
                      {LocaleStrings.ticketsReports_RepliedText}
                    </button>
                  </>
                )}
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapStateToProps, {
  SuggestionReply,
})(AppSuggestionitems);
