import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages";
import { Field, reduxForm } from "redux-form";
import { BaseComponent, number } from "../../../../../common/base-component";
import {
  fetchglobalCommision,
  updateGlobalCommission,
} from "../../actions/index";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { classNames } from "../../../../../common/common-functions";
import AllGlobalTabsList from "../globaltabs";
import BaseLoader from "../../../../../common/base-loader";

class GlobalCommision extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      setOpen: false,
      btnLoader: false,
      loading: true,
      loaderDesc: "Preparing Commision",
    };
  }

  componentDidMount() {
    this.props.fetchglobalCommision(this.props.session, (CallBackResponse) => {
      this.setState({ loading: false });
    });
  }

  onFormSubmit = (val) => {
    if (val.commission_perorder == "" || val.commission_perrefund == "") {
      NotificationManager.error(
        LocaleStrings.seller_management_errorcommission
      );
      return false;
    } else {
      if (val.commission_perorder < 1 || val.commission_perrefund < 1) {
        NotificationManager.error(
          LocaleStrings.seller_management_error0commission
        );
        return false;
      } else {
        var values = { ...val };
        this.setState({ btnLoader: true });
        this.props.updateGlobalCommission(
          this.props.session,
          values,
          (response) => {
            if (response.success === 0) {
              NotificationManager.error(LocaleStrings.common_cannotupdate);
              this.setState({ btnLoader: false });
            } else {
              NotificationManager.success(LocaleStrings.common_updated);
              this.props.fetchglobalCommision(
                this.props.session,
                (CallBackResponse) => {
                  this.setState({ btnLoader: false });
                }
              );
            }
          }
        );
      }
    }
  };

  render() {
    var { data, handleSubmit, initialValues } = this.props;
    // console.log("initialValues", initialValues);
    return (
      <>
        <AllGlobalTabsList history={this.props.history} />
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div className="m-6">
              <form className=" " onSubmit={handleSubmit(this.onFormSubmit)}>
                <div className="flow-root px-10 py-5 bg-custWhite rounded">
                  <p className="mb-3">{LocaleStrings.customcomission}</p>
                  <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 pr-10">
                    <div className="px-5 end-item">
                      {LocaleStrings.pernewoder}
                    </div>
                    <div className="mt-3">
                      <Field
                        name="commission_perorder"
                        placeholder={LocaleStrings.pernewoder}
                        type="text"
                        component={this.renderFieldcustomer}
                        className="appearance-none block w-half px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <p
                      className="text-gray-400"
                      style={{ marginLeft: -20, marginTop: 18 }}>
                      %
                    </p>
                    <div></div>
                  </div>
                  <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 pr-10">
                    <div className="px-5 end-item">
                      {LocaleStrings.perrefund}
                    </div>
                    <div className="mt-3">
                      <Field
                        name="commission_perrefund"
                        placeholder={LocaleStrings.perrefund}
                        type="text"
                        component={this.renderFieldcustomer}
                        className="appearance-none block w-half px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <p
                      className="text-gray-400"
                      style={{ marginLeft: -20, marginTop: 18 }}>
                      %
                    </p>
                    <div></div>
                  </div>
                  <div className="center-item my-16">
                    <button type="submit" className="bigbutton">
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "sr-only",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.updatecommision}
                    </button>
                  </div>
                </div>
              </form>
              <NotificationContainer />
            </div>
          </>
        )}
      </>
    );
  }
}

function validate(val) {
  // console.log("form data", values);
  var errors = {};
  var commission_perrefund = val["commission_perrefund"];
  var commission_perorder = val["commission_perorder"];
  if (commission_perrefund && number(commission_perrefund)) {
    errors["commission_perrefund"] = "Please enter a number";
  }
  if (commission_perorder && number(commission_perorder)) {
    errors["commission_perorder"] = "Please enter a number ";
  }
  return errors;
}

function mapStateToProps(state, ownProps) {
  return {
    session: state.session,
    initialValues: state.global_commissionlist.data,
  };
}

export default connect(mapStateToProps, {
  fetchglobalCommision,
  updateGlobalCommission,
})(
  reduxForm({
    validate,
    form: "GlobalCommissionForm1",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(GlobalCommision)
);
