import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import { reduxForm } from "redux-form";
import EditsellerModal from "../../editsellermodal";
import _ from "lodash";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
} from "../../../../../../common/constants";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import BaseLoader from "../../../../../../common/base-loader.js";
import { confirmAlert } from "react-confirm-alert";
import {
  banSeller,
  fetchsingleSeller,
  sellerTabid,
} from "../../../actions/index";

class AboutSeller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      setOpen: false,
      isactive: 1,
      loading: true,
    };

    // All Binded Functions
    // this.closeModal = this.closeModal.bind(this);
    // this.openModal = this.openModal.bind(this);
  }

  openModal = (e, obj) => {
    this.props.sellerTabid(obj);
    this.setState({ isOpen: true });
  };
  // cl
  closeModal = (e, obj) => {
    this.props.sellerTabid({});
    this.setState({ isOpen: false });
  };

  componentDidMount(data) {
    this.props.fetchsingleSeller(
      this.props.session,
      this.props.data.userid,
      (resource) => {
        this.setState({ loading: false });
      }
    );
    this.setState({ isactive: this.props.data.isactive });
  }
  banVendor(e, data) {
    // console.log("data", data);

    confirmAlert({
      title: LocaleStrings.seller_management_banSeller,
      message: LocaleStrings.seller_management_banSeller_message,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            let body = {};

            body = { userid: data.userid, isactive: 0 };

            //let body = { resource: { keyid:id,isactive:0} };
            // let body = { resource: { keyid:id,isactive:0} };

            this.props.banSeller(this.props.session, body, (resource) => {
              // console.log("showresource :- ", resource);
              if (resource.error) {
                NotificationManager.error(
                  LocaleStrings.seller_management_errorban
                );
              } else {
                this.props.fetchsingleSeller(
                  this.props.session,
                  data.userid,
                  (resource) => {
                    NotificationManager.success(
                      LocaleStrings.seller_management_successban
                    );
                    this.setState({ isactive: 0 });
                  }
                );
              }
            });
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }
  UnbanVendor(e, data) {
    // console.log("data", data);

    confirmAlert({
      title: LocaleStrings.seller_management_UnbanSeller,
      message: LocaleStrings.seller_management_UnbanSeller_message,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            let body = {};

            body = { userid: data.userid, isactive: 1 };

            //let body = { resource: { keyid:id,isactive:0} };
            // let body = { resource: { keyid:id,isactive:0} };

            this.props.banSeller(this.props.session, body, (resource) => {
              // console.log("showresource :- ", resource);
              if (resource.error) {
                NotificationManager.error(
                  LocaleStrings.seller_management_errorUnban
                );
              } else {
                this.props.fetchsingleSeller(
                  this.props.session,
                  data.userid,
                  (resource) => {
                    NotificationManager.success(
                      LocaleStrings.seller_management_successUnban
                    );
                    this.setState({ isactive: 1 });
                  }
                );
              }
            });
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { data, singleSellerData } = this.props;
    let dob = "";
    var img = "";
    var username = "";
    var email = "";
    var gender = "";
    var phone = "";
    var isactive = "";
    var businessname = "";
    var businesstype = "";

    if (singleSellerData && singleSellerData.length > 0) {
      dob =
        singleSellerData[0]?.dob !== null && singleSellerData[0]?.dob !== ""
          ? moment(singleSellerData[0]?.dob).format("D MMM YYYY")
          : null;
      img = `${BASE_IMAGES_URL}/users/${singleSellerData[0]?.userid}.jpg?api_key=${APP_API_KEY}`;
      username = singleSellerData[0]?.username;
      email = singleSellerData[0]?.email;
      businessname = singleSellerData[0]?.businessname;
      businesstype = singleSellerData[0]?.businesstype;
      gender = singleSellerData[0]?.gender;
      phone = singleSellerData[0]?.phone;
      isactive = singleSellerData[0]?.isactive;
    }

    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {singleSellerData && singleSellerData.length > 0 ? (
              <div
                className="grid gap-4  px-6 grid-cols-1 mt-5 flex flex-wrap content-center justify-center"
                style={{ overflowX: "hidden" }}>
                <div className="">
                  <img
                    className="w-32 h-32 flex-shrink-0 mx-auto rounded-full"
                    src={img}
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../images/user_1.png";
                    }}
                  />
                  {/* <img className="rounded-full" src={img}  /> */}
                </div>
                {/* </div> */}
                <div className="mt-1 flex items-center flex flex-wrap content-center justify-center">
                  {username}
                </div>
                <hr />
                <div className="mt-1 flex items-center flex flex-wrap content-center justify-center mt-4">
                  {businessname}
                </div>
                {businesstype !== null ? (
                  <div className="mt-1 flex items-center flex flex-wrap content-center justify-center mt-4">
                    {_.startCase(businesstype)} Business
                  </div>
                ) : (
                  ""
                )}
                {gender ? (
                  <div className="mt-1 flex items-center flex flex-wrap content-center justify-center mt-4">
                    <img
                      className="h-8 w-8 mx-2"
                      src="../images/about/gender.svg"
                    />
                    {_.startCase(gender)}
                  </div>
                ) : (
                  ""
                )}
                {dob !== null ? (
                  <div className="mt-1 flex items-center flex flex-wrap content-center justify-center mt-4">
                    <img
                      className="h-8 w-8 mx-2"
                      src="../images/about/calender.svg"
                    />
                    {dob}
                  </div>
                ) : (
                  ""
                )}
                <div className="mt-1 flex items-center flex flex-wrap content-center justify-center mt-4">
                  <img
                    className="h-8 w-8 mx-2"
                    src="../images/about/email.svg"
                  />
                  {email}
                </div>
                {phone ? (
                  <div className="mt-1 flex items-center flex flex-wrap content-center justify-center mt-4">
                    <img
                      className="h-8 w-8 mx-2"
                      src="../images/about/phone.svg"
                    />
                    {phone}
                  </div>
                ) : (
                  ""
                )}
                <div className="mt-1 flex items-center flex flex-wrap content-center justify-center mt-4">
                  {isactive == 1 ? (
                    <>
                      <button
                        type="button"
                        onClick={(e) => this.banVendor(e, singleSellerData[0])}
                        className="lg:w-48 md:w-44 sm:w-44 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-red-500">
                        {LocaleStrings.banseller}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={(e) =>
                          this.UnbanVendor(e, singleSellerData[0])
                        }
                        className="lg:w-48 md:w-44 sm:w-44 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-red-500">
                        {LocaleStrings.seller_management_UnbanSeller}
                      </button>
                    </>
                  )}

                  <button
                    type="button"
                    onClick={(e) => this.openModal(e, singleSellerData[0])}
                    className="lg:w-48 md:w-44 sm:w-44 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover ml-5 ">
                    {LocaleStrings.updateseller}
                  </button>
                  {this.state.isOpen ? (
                    <EditsellerModal
                      isOpen={this.state.isOpen}
                      onClose={this.closeModal}
                      history={this.props.history}
                      // data={this.props.data}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <NotificationContainer />
              </div>
            ) : (
              <div className="mt-48 center-item text-ternary">
                Seller Details Not Found
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // console.log('ownProps.data', ownProps.data);
  return {
    session: state.session,
    data: ownProps.data,
    singleSellerData: state.singleSellerData ? state.singleSellerData.data : [],
  };
}
export default reduxForm({
  // validate,
  form: "AboutSeller",
})(
  connect(mapStateToProps, {
    banSeller,
    fetchsingleSeller,
    sellerTabid,
  })(AboutSeller)
);
