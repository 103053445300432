import LocaleStrings from "../../../languages/index";
import {
  GLOBAL_COMMISSION,
  ALL_FAQ,
  EDIT_FAQ,
  DELIVERYAGENTS_LIST,
  ADMIN_LISTING,
  EDITADMIN_DATA,
  RESTRICTED_WORDS,
  EDIT_RESTRICTED_WORDS,
  EDIT_AGENTS,
  SHIPPING_CHARGES,
  EDIT_GLOBALCHARGES,
  ALL_VENDORFAQ,
  RESTRICTED_FILE,
  ZONE_MASTER,
  CHARITY_EVENTS,
  EDIT_CHARITYEVENTS,
  ALLMODERATOR_ROLE,
} from "../actions/index";

export var AllglobalTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    // { name: LocaleStrings.paymentkeys, href: 'paymentkeys', current: true,icon: "sales_management",tabindex:1 },
    {
      name: "Charges",
      href: "charges",
      current: false,
      icon: "sales_management",
      tabindex: 1,
    },
    {
      name: LocaleStrings.commissionmanagement,
      href: "commissionmanagement",
      current: false,
      icon: "sales_management",
      tabindex: 2,
    },
    {
      name: "Other Fees",
      href: "otherFee",
      current: false,
      icon: "sales_management",
      tabindex: 3,
    },
    {
      name: LocaleStrings.refund,
      href: "refund",
      current: false,
      icon: "sales_management",
      tabindex: 4,
    },
    {
      name: LocaleStrings.adminmanagement,
      href: "adminmanagement",
      current: false,
      icon: "sales_management",
      tabindex: 5,
    },
    {
      name: LocaleStrings.contentmanagement,
      href: "contentmanagement",
      current: false,
      icon: "sales_management",
      tabindex: 6,
    },
    {
      name: LocaleStrings.deliverymanagement,
      href: "deliverymanagement",
      current: false,
      icon: "sales_management",
      tabindex: 7,
    },
    {
      name: LocaleStrings.legaldocs,
      href: "legaldocs",
      current: false,
      icon: "sales_management",
      tabindex: 8,
    },
    {
      name: LocaleStrings.faqs,
      href: "faqs",
      current: false,
      icon: "sales_management",
      tabindex: 9,
    },
    {
      name: LocaleStrings.othersettings,
      href: "othersettings",
      icon: "sales_management",
      current: false,
      tabindex: 10,
    },
  ];

  return state;
};

export var Taxtablist = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.tax, href: "#", current: true, tabindex: 1 },
    { name: LocaleStrings.charges, href: "#", current: false, tabindex: 2 },
  ];

  return state;
};
export var Faqtablist = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.customer, href: "#", current: true, tabindex: 1 },
    { name: LocaleStrings.vendor, href: "#", current: false, tabindex: 2 },
  ];

  return state;
};

export var global_commissionlist = (state = {}, action) => {
  switch (action.type) {
    case GLOBAL_COMMISSION:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var allfaq_list = (state = {}, action) => {
  switch (action.type) {
    case ALL_FAQ:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var allvendorfaq_list = (state = {}, action) => {
  switch (action.type) {
    case ALL_VENDORFAQ:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var all_deliveryagentList = (state = {}, action) => {
  switch (action.type) {
    case DELIVERYAGENTS_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var edit_faqs = (state = {}, action) => {
  switch (action.type) {
    case EDIT_FAQ:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var Faqfor = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Consumer", value: "consumer", id: 0 },
    { name: "Vendor", value: "vendor", id: 1 },
  ];

  return state;
};

export var ServicesAvailable = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Economy", value: "economy", id: 1 },
    { name: "Standard", value: "standard", id: 2 },
    { name: "Expedited", value: "expedited", id: 3 },
    { name: "One day", value: "one day", id: 4 },
  ];

  return state;
};

export var allAdmin_List = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LISTING:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var Edit_superadmin = (state = {}, action) => {
  switch (action.type) {
    case EDITADMIN_DATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var globalRestrictedWords = (state = {}, action) => {
  switch (action.type) {
    case RESTRICTED_WORDS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var Edit_restricted_words = (state = {}, action) => {
  switch (action.type) {
    case EDIT_RESTRICTED_WORDS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var Edit_delivery_agents = (state = {}, action) => {
  switch (action.type) {
    case EDIT_AGENTS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var shippingCharges = (state = {}, action) => {
  switch (action.type) {
    case SHIPPING_CHARGES:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var editCharges = (state = {}, action) => {
  switch (action.type) {
    case EDIT_GLOBALCHARGES:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var editcharityevents = (state = {}, action) => {
  switch (action.type) {
    case EDIT_CHARITYEVENTS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var DummyDistricts = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Riyadh", value: "Riyadh", id: 1 },
    { name: "Jeddah", value: "Jeddah", id: 2 },
    { name: "Abha", value: "Abha", id: 3 },
    { name: "Hofuf", value: "Hofuf", id: 4 },
    { name: "Ad Dammām", value: "Ad Dammām", id: 5 },
    { name: "Haradh", value: "Haradh", id: 6 },
    { name: "Zulfi", value: "Zulfi", id: 7 },
    { name: "Hail", value: "Hail", id: 8 },
    { name: "Sakākā", value: "Sakākā", id: 9 },
    { name: "Yanbu", value: "Yanbu", id: 10 },
  ];

  return state;
};
export var DummyPin_codes = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "11564", value: "11564", id: 1 },
    { name: "21577", value: "21577", id: 2 },
    { name: "61321", value: "61321", id: 3 },
    { name: "36362", value: "36362", id: 4 },
    { name: "32241", value: "32241", id: 5 },
    { name: "36699", value: "36699", id: 6 },
    { name: "15941", value: "15941", id: 7 },
    { name: "55425", value: "55425", id: 8 },
    { name: "75471", value: "75471", id: 9 },
    { name: "46424", value: "46424", id: 10 },
  ];

  return state;
};

export var listngfeetype = (state = [], action) => {
  //iconName is optional
  var state = [
    // { name: "Percentage", value:"percentage" ,id:0 },
    { name: "Flat", value: "flat", id: 1 },
  ];

  return state;
};

export var ContentTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Words", href: "#", current: true, tabindex: 1 },
    { name: "File", href: "#", current: false, tabindex: 2 },
  ];

  return state;
};

export var globalRestrictedFile = (state = {}, action) => {
  switch (action.type) {
    case RESTRICTED_FILE:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var zonemasterList = (state = {}, action) => {
  switch (action.type) {
    case ZONE_MASTER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var charityeventList = (state = {}, action) => {
  switch (action.type) {
    case CHARITY_EVENTS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var allModeratorrolelist = (state = {}, action) => {
  switch (action.type) {
    case ALLMODERATOR_ROLE:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var otherfeeTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Listing Fees", href: "#", current: true, tabindex: 1 },
    { name: "Free Shipping Fee", href: "#", current: false, tabindex: 2 },
    { name: "Charity", href: "#", current: false, tabindex: 3 },
  ];

  return state;
};

export var zoneorPin = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Zone", value: "zone", id: 0 },
    { name: "Pincode", value: "pin_code", id: 1 },
    // { name: "Bottom", value:"bottom" ,id:2 },
  ];

  return state;
};
