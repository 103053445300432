import LocaleStrings from "../../../languages/index";
import {
  ALL_VARIATIONS,
  EDITVARIATIONDATA,
  MASTERVARIATION_PRODUCTCATEGORY,
  MASTERVARIATION_PRODUCTSUBCATEGORY,
} from "../actions/index";

export var allVariation = (state = {}, action) => {
  switch (action.type) {
    case ALL_VARIATIONS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var variationdata = (state = {}, action) => {
  switch (action.type) {
    case EDITVARIATIONDATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var mastervariationProductCat = (state = {}, action) => {
  switch (action.type) {
    case MASTERVARIATION_PRODUCTCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var mastervariationProductsubCat = (state = {}, action) => {
  switch (action.type) {
    case MASTERVARIATION_PRODUCTSUBCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
