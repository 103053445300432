import { INSTANCE_URL } from "../../../../common/constants";
import { postRequest, patchRequest } from "../../../../common/network-call";
import LocaleStrings from "../../../languages";

export var TICKET_LIST = "TICKET_LIST";
export var REPORTS_LIST = "REPORTS_LIST";
export var APPSUGGESTION_LIST = "APPSUGGESTION_LIST";
export var SENDING_REPORTANDSUGGESTION_REPLY =
  "SENDING_REPORTANDSUGGESTION_REPLY";
export var REPORT_VIEW_ALL = "REPORT_VIEW_ALL";
export var SUGGESTION_REPLY = "SUGGESTION_REPLY";

export function fetchTickets(
  session,
  type,
  limit,
  page,
  listingtype,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/ticketsnreports`;
  let values = {};
  if (listingtype === "listing") {
    // Paginated
    values.type = type;
    values.limit = limit;
    values.page = page;
    values.listingtype = listingtype;
  } else {
    // With out pagination [search result]
    values.type = type;
    values.listingtype = listingtype;
    values.searchterm = searchterm;
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('response',response.data)
        if (listingtype === "listing") {
          // Paginated
          dispatch({
            type: TICKET_LIST,
            payload: { data: response.data, count: response.count },
          });
        } else {
          // With out pagination [search result]
          dispatch({
            type: TICKET_LIST,
            payload: { data: response.data },
          });
        }
        // dispatch({
        //   type: TICKET_LIST,
        //   payload: { data: data.data,count:data.count },

        // });
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}

export function searchTickets(session, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/ticketsnreports`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        type: "tickets",
        listingtype: "search",
        searchterm: search,
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: TICKET_LIST,
          payload: { data: data.data },
        });
      },
      (error) => {}
    );
  };
}

export function fetchReports(session, pageNumber, callback) {
  var offset = pageNumber;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/ticketsnreports`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "reports",
        listingtype: "listing",
        limit: "10",
        page: offset,
      },
      session,
      dispatch,
      (data) => {
        //  console.log('data',data)
        dispatch({
          type: REPORTS_LIST,
          payload: { data: data.data, count: data.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchReports(session, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/ticketsnreports`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        type: "reports",
        listingtype: "search",
        searchterm: search,
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: REPORTS_LIST,
          payload: { data: data.data },
        });
      },
      (error) => {}
    );
  };
}

export function fetchAppsuggestion(
  session,
  type,
  limit,
  page,
  listingtype,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/ticketsnreports`;
  let values = {};
  if (listingtype === "listing") {
    // Paginated
    values.type = type;
    values.limit = limit;
    values.page = page;
    values.listingtype = listingtype;
  } else {
    // With out pagination [search result]
    values.type = type;
    values.listingtype = listingtype;
    values.searchterm = searchterm;
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('data1', data);
        if (listingtype === "listing") {
          // Paginated
          dispatch({
            type: APPSUGGESTION_LIST,
            payload: { data: response.data, count: response.count },
          });
        } else {
          // With out pagination [search result]
          dispatch({
            type: APPSUGGESTION_LIST,
            payload: { data: response.data },
          });
        }
        // dispatch({

        //   type: APPSUGGESTION_LIST,
        //   payload: { data: data.data,count: data.count },

        // });
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}
export function searchAppsuggestions(session, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/ticketsnreports`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        type: "suggestions",
        listingtype: "search",
        searchterm: search,
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: APPSUGGESTION_LIST,
          payload: { data: data.data },
        });
      },
      (error) => {}
    );
  };
}
export function Sendingreply(reply) {
  return (dispatch) => {
    dispatch({ type: SENDING_REPORTANDSUGGESTION_REPLY, payload: reply });
  };
}

export function postReply(session, reply, ticketid, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/replytouser`;
  return (dispatch) => {
    postRequest(
      url,
      {
        reply: reply,
        ticketid: ticketid,
        type: "ticket",
      },
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function ReoprtViewModal(reportview) {
  return (dispatch) => {
    dispatch({ type: REPORT_VIEW_ALL, payload: reportview });
  };
}

export function postReplySuggestion(session, reply, suggestionid, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/replytouser`;
  return (dispatch) => {
    postRequest(
      url,
      {
        reply: reply,
        suggestionid: suggestionid,
        type: "suggestions",
      },
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function SuggestionReply(suggestionreply) {
  return (dispatch) => {
    dispatch({ type: SUGGESTION_REPLY, payload: suggestionreply });
  };
}

export function updateReportedItems(session, body, tablename, callback) {
  // console.log('check all',body);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/${tablename}/`;
  //  console.log('curl',url);
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('response',response)
        callback({ status: 1, message: LocaleStrings.addedsucessfully });
      },
      (error) => {
        // console.log('response',error)
        callback({ status: 0, message: LocaleStrings.addingfailed });
      }
    );
  };
}

export function sendReportBanEmail(session, id, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;
  return (dispatch) => {
    postRequest(
      url,
      {
        id: id,
        emailtype: "banreporteditem",
      },
      session,
      dispatch,
      (data) => {
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
