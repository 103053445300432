import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { BaseComponent } from "../../../../common/base-component";
import _ from "lodash";
import EditVariations from "./editvariations";
import {
  onEditvariation,
  productcountbyvariant,
  fetchVariations,
  deleteVariant,
} from "../actions/index";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import LocaleStrings from "../../../languages";

class Variationlisting extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }
  openModal = (e, obj) => {
    this.props.onEditvariation(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  deletevariant = (e, obj) => {
    let { session } = this.props;

    this.props.productcountbyvariant(session, obj.id, (callbackResponse) => {
      if (
        callbackResponse &&
        callbackResponse.success &&
        callbackResponse.success == 1
      ) {
        let productCount = 0;
        if (callbackResponse.data && callbackResponse.data.product_count) {
          productCount = callbackResponse.data.product_count;
        }
        let messageText = "Are you sure you want to delete master variant?";
        if (productCount > 0) {
          messageText = `Are you sure you want to delete master variant? Doing this will remove  ${productCount} product(s) associated with it? This cannot be undone`;
        }
        confirmAlert({
          title: "Delete Master Variant",
          message: messageText,
          buttons: [
            {
              label: LocaleStrings.yes,
              onClick: () => {
                this.props.deleteVariant(
                  this.props.session,
                  obj.id,
                  (resource) => {
                    // console.log("showresource :- ", resource);
                    if (resource.success == 0) {
                      NotificationManager.error(
                        LocaleStrings.something_went_wrong
                      );
                    } else {
                      this.props.fetchVariations(
                        this.props.session,
                        10,
                        1,
                        "listing",
                        "",
                        (CallBackResponse) => {
                          NotificationManager.success("Successfully Deleted");
                        }
                      );
                    }
                  }
                );
              },
            },
            {
              label: LocaleStrings.no,
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    var { values, language } = this.props;
    // console.log('values133closeModal', values);
    let tabledate = "";
    tabledate = moment(values.createdon).format("D MMM YYYY HH:mm A");
    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{values.name_en !== null ? values.name_en : <div>-</div>}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{values.name_ar !== null ? values.name_ar : <div>-</div>}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{tabledate}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div className="action-button-area" style={{ display: "flex" }}>
              <div className="">
                <button
                  className="button"
                  onClick={(e) => this.openModal(e, values)}>
                  {LocaleStrings.edit}
                </button>

                <button
                  className="button ml-4"
                  onClick={(e) => this.deletevariant(e, values)}>
                  {LocaleStrings.delete}
                </button>
                {this.state.isOpen ? (
                  <EditVariations
                    isOpen={this.state.isOpen}
                    onClose={this.closeModal}
                    data={this.props.values}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
  };
}

export default connect(mapStateToProps, {
  onEditvariation,
  productcountbyvariant,
  fetchVariations,
  deleteVariant,
})(Variationlisting);
