import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  APP_API_KEY,
  BASE_IMAGES_URL,
  classNames,
} from "../../../../../../common/constants";

class DetailsImgCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showerror: false,
      currentImage: 0,
    };
  }

  getUrl(object) {
    var reader = new FileReader();
    let arrayBuffer = object.raw_thumbnail.url;
    var blob = new Blob([arrayBuffer], { type: "image/jpeg" });
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(blob);
    return imageUrl;
  }

  renderCarousel = () => {
    let {
      productObjectForFeeDetails,
      isProductFeeDefault,
      refkeyArr,
      selectedVariant,
    } = this.props;

    let { currentImage } = this.state;
    let images = [];

    images = selectedVariant[0].media;

    // We will start by storing the index of the current image in the state.
    // const [currentImage, setCurrentImage] = React.useState(0);

    // We are using react ref to 'tag' each of the images. Below will create an array of
    // objects with numbered keys. We will use those numbers (i) later to access a ref of a
    // specific image in this array.
    const refs = images.reduce((acc, val, i) => {
      acc[i] = React.createRef();
      return acc;
    }, {});

    const scrollToImage = (i) => {
      // First let's set the index of the image we want to see next
      // setCurrentImage(i);
      this.setState({ currentImage: i });
      // Now, this is where the magic happens. We 'tagged' each one of the images with a ref,
      // we can then use built-in scrollIntoView API to do eaxactly what it says on the box - scroll it into
      // your current view! To do so we pass an index of the image, which is then use to identify our current
      // image's ref in 'refs' array above.
      refs[i].current.scrollIntoView({
        //     Defines the transition animation.
        behavior: "smooth",
        //      Defines vertical alignment.
        block: "nearest",
        //      Defines horizontal alignment.
        inline: "start",
      });
    };

    // Some validation for checking the array length could be added if needed
    const totalImages = images.length;

    // Below functions will assure that after last image we'll scroll back to the start,
    // or another way round - first to last in previousImage method.
    const nextImage = () => {
      if (currentImage >= totalImages - 1) {
        scrollToImage(0);
      } else {
        scrollToImage(currentImage + 1);
      }
    };

    const previousImage = () => {
      if (currentImage === 0) {
        scrollToImage(totalImages - 1);
      } else {
        scrollToImage(currentImage - 1);
      }
    };

    // Tailwind styles. Most importantly notice position absolute, this will sit relative to the carousel's outer div.
    const arrowStyle =
      "fixed text-custWhite text-2xl bg-custblack h-10 w-10 rounded-full opacity-75 flex items-center justify-center";

    // Let's create dynamic buttons. It can be either left or right. Using
    // isLeft boolean we can determine which side we'll be rendering our button
    // as well as change its position and content.
    const sliderControl = (isLeft) => (
      <button
        type="button"
        onClick={isLeft ? previousImage : nextImage}
        className={`${arrowStyle} ${isLeft ? "left-2" : "right-2"}`}
        style={{ top: "27%" }}>
        <span role="img" aria-label={`Arrow ${isLeft ? "left" : "right"}`}>
          {isLeft ? "◀" : "▶"}
        </span>
      </button>
    );

    return (
      <div
        className="flex justify-center md:w-full items-center"
        align="center">
        <div className="relative w-full">
          <div className="carousel">
            {images.length > 1 ? sliderControl(true) : null}

            {images.map((img, i) => (
              <>
                <div className="w-full flex-shrink-0" key={img} ref={refs[i]}>
                  {img.type === "video" ? (
                    <video width="400" controls className="mt-14">
                      <source
                        src={`${BASE_IMAGES_URL}/vendor/variations/${img.mediafilename}?api_key=${APP_API_KEY}`}
                        type="video/mp4"
                      />
                      Your browser does not support HTML video.
                    </video>
                  ) : (
                    <img
                      loading="lazy"
                      src={`${BASE_IMAGES_URL}/vendor/variations/${img.thumbfilename}?api_key=${APP_API_KEY}`}
                      alt="variant-image"
                      className="h-60 w-full object-cover  rounded"
                    />
                  )}
                </div>
              </>
            ))}
            {images.length > 1 ? sliderControl() : null}
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { productObjectForFeeDetails, isProductFeeDefault, selectedVariant } =
      this.props;
    //let {} = this.state;

    return (
      <>
        <div className="grid justify-items-center ...">
          <div className="w-60 h-60 justify-center">
            {/* <Carousel data={productObjectForFeeDetails}  /> */}
            {this.renderCarousel()}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    productObjectForFeeDetails: state.productObjectForFeeDetails,
    isProductFeeDefault: state.isProductFeeDefault,
    selectedVariant: ownProps.selectedVariant, //Data array with refkeys
  };
}

export default connect(mapStateToProps, {})(DetailsImgCarousel);
