import React, { Component } from "react";
import { connect } from "react-redux";
import NegotiationTabs from "./negotiationtabs";

var Loader = require("react-loaders").Loader; // import "AwesomeLoaderCss";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class NegotiationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      open: false,
      setOpen: false,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }
  customerdetails = (e) => {
    this.setState({ showUploadSection: !this.state.showUploadSection });
  };

  render() {
    return (
      <>
        <div className="py-6">
          <NegotiationTabs
            data={this.props.data}
            showUploadSection={this.customerdetails}
          />
        </div>
      </>
    );
  }
}

var mapStateToProps = (state, ownProps) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  data: ownProps.data,
});

export default connect(mapStateToProps, {})(NegotiationList);
