import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index.js";
import _ from "lodash";
import TransactionmanagementTabs from "../transactiontabs";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { converDateIntoLocal } from "../../../../../common/common-functions";
import { fetchcharityPayouts } from "../../actions/index";
import CharityPayoutListitem from "./charitypayout-items";
import Pagination from "react-js-pagination";
import BaseLoader from "../../../../../common/base-loader";

const limit = 10;

class CharityPayouts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveredorderSearch: "",
      loaderDesc: LocaleStrings.preparing_transaction,
      loading: true,
      pageNumber: 1,
      search: "",
      startdate: "",
      enddate: "",
      initialStartdate: "",
      initialEnddate: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    let { pageNumber, initialStartdate, initialEnddate } = this.state;
    var date = new Date();
    date.setDate(date.getDate() - 7);
    let initialStartdates = converDateIntoLocal(date).format("YYYY-MM-DD");
    let today = new Date();
    let initialEnddates = converDateIntoLocal(today).format("YYYY-MM-DD");
    // document.getElementById("payout_startdate") = initialStartdates;
    // document.getElementById("payout_enddate") = initialEnddates;
    this.setState({
      initialStartdate: initialStartdates,
      initialEnddate: initialEnddates,
    });
    this.props.fetchcharityPayouts(
      this.props.session,
      limit,
      pageNumber, // page
      initialStartdates,
      initialEnddates,
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  paginationCallback = (pageNumber) => {
    let { startdate, enddate } = this.state;
    this.props.fetchcharityPayouts(
      this.props.session,
      limit,
      pageNumber, // page
      startdate,
      enddate,
      (callBack) => {}
    );

    this.setState({ ...this.state, pageNumber });
  };

  /* Handel date change */
  handleDates = (event) => {
    let {
      index,
      tab,
      searchText,
      pageNumber,
      initialStartdate,
      initialEnddate,
    } = this.state;
    this.setState({ loading: false });
    if (event.target.name === "payout_enddate") {
      this.setState({ enddate: event.target.value ? event.target.value : "" });
    }

    if (event.target.name === "payout_startdate") {
      this.setState({
        startdate: event.target.value ? event.target.value : "",
      });
    }

    let stdate = document.getElementById("payout_startdate").value;
    let enddate = document.getElementById("payout_enddate").value;
    /* 
    Date validation
    */
    if (_.size(stdate) === 10 && _.size(enddate) === 10) {
      let stdate_replaced = _.replace(stdate, "-", "");
      let enddate_replaced = _.replace(enddate, "-", "");

      stdate_replaced = _.replace(stdate_replaced, "-", "");
      enddate_replaced = _.replace(enddate_replaced, "-", "");

      if (parseInt(stdate_replaced) > parseInt(enddate_replaced)) {
        NotificationManager.error("Invalid Date Range");
      } else {
        this.props.fetchcharityPayouts(
          this.props.session,
          limit,
          pageNumber, // page
          stdate,
          enddate,
          (callBack) => {
            this.setState({ loading: false });
          }
        );
      }
    } else if (_.size(stdate) === 0 && _.size(enddate) === 0) {
      this.props.fetchcharityPayouts(
        this.props.session,
        limit,
        pageNumber, // page
        initialStartdate, // Start date
        initialEnddate,
        (callBack) => {
          this.setState({ loading: false });
        }
      );
    } else {
    }
  };

  renderpayoutList() {
    let pageDataLength = this.props.charitypayoutDatalist.data.length;

    let { pageNumber } = this.state;

    var items = _.map(
      this.props.charitypayoutDatalist.data,
      (values, index) => (
        <CharityPayoutListitem
          key={index}
          values={values}
          pagination={this.paginationCallback}
          pageDataLength={pageDataLength}
          currentPage={pageNumber}
        />
      )
    );
    return items;
  }

  render() {
    return (
      <>
        {" "}
        <TransactionmanagementTabs history={this.props.history} />
        <>
          {this.state.loading ? (
            <>
              <BaseLoader data={this.state.loaderDesc} />
            </>
          ) : (
            <>
              <div className="flex flex-wrap m-6 p-4 bg-white rounded">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    for="payout_startdate"
                    className="text-sm font-medium text-secondary px-2">
                    Start Date:-{""}
                  </label>
                  <input
                    className="appearance-none px-3 py-2 border border-ternary rounded-md shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primaryLight sm:text-sm w-40"
                    type="date"
                    id="payout_startdate"
                    name="payout_startdate"
                    onChange={this.handleDates}
                    // placeHolder="Start Date"
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="text-sm font-medium text-secondary px-2"
                    for="payout_enddate">
                    End Date:-{""}
                  </label>
                  <input
                    className="appearance-none  px-3 py-2 border border-ternary rounded-md shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primaryLight sm:text-sm w-40"
                    type="date"
                    id="payout_enddate"
                    name="payout_enddate"
                    onChange={this.handleDates}
                    // placeholder="End Date"
                  />
                </div>
              </div>

              <div
                className="grid gap-4 grid-rows-1 px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 "
                style={{
                  overflowX: "hidden",
                  overflowY: "hidden",
                  display: "block",
                }}></div>
              {_.isEmpty(this.props.charitypayoutDatalist.data) ? (
                <>
                  <div className="center-item lg:mt-48 text-lg text-ternary">
                    Select Dates To Find Charity Payouts
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col pt-6 pb-2 px-6">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-custWhite">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Charity Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Total Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>{this.renderpayoutList()}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Pagination
                    activePage={this.state.pageNumber}
                    itemsCountPerPage={10}
                    totalItemsCount={this.props.charitypayoutDatalist.count}
                    pageRangeDisplayed={10}
                    onChange={this.paginationCallback}
                  />
                </>
              )}
            </>
          )}
          <NotificationContainer />
        </>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    charitypayoutDatalist: state.charitypayoutDatalist,
  };
}

export default connect(mapStateToProps, {
  fetchcharityPayouts,
})(CharityPayouts);
