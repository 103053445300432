import Recat, { Component } from "react";
import { connect } from "react-redux";
import { XIcon } from "@heroicons/react/outline";
import LocaleStrings from "../../../languages/index";
import _ from "lodash";
import { newtabseller } from "../actions/index";
// import AboutSeller from "./tabs/about/aboutseller";
import Selleraboutabs from "./tabs/about/index";
import Storedetails from "./tabs/storeprofile/storedetails";
import Sellerproducttabs from "./tabs/product/index";
import Sellerodertabs from "./tabs/oders/index";
import Sellernegotiationtabs from "./tabs/negotiation/index";
import Sellertransaction from "./tabs/transaction/index";
import { fetchStoredetails } from "../actions/index";
import AuctionSellerList from "./tabs/auction/index.js";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Sellertabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndextoShow: 1,
      loading: true,
      loaderDesc: "Preparing Seller management Tabs",
    };
  }

  componentDidMount() {
    // this.props.fetchStoredetails(this.props.session,this.props.sellerlistingtabs.userid,(CallBackResponse) => {
    //     this.setState({ loading: false });
    //   });
  }

  componentDidUpdate() {}

  closetabs() {
    this.props.fetchStoredetails(
      this.props.session,
      this.props.sellerlistingtabs.userid,
      "close",
      (CallBackResponse) => {
        // console.log("CallBackResponse", CallBackResponse);
        this.setState({ loading: false });
      }
    );
    this.props.newtabseller();
  }

  // On Tabs change
  onTabsChange = (e, tabindex, callfrom) => {
    if (callfrom === "lg") {
      // For tabs
      this.setState({ tabIndextoShow: parseInt(tabindex) });
    } else {
      // For Select box
      this.setState({ tabIndextoShow: parseInt(e.target.value) });
    }
  };

  render() {
    var selectedTab = "";
    let tabs = this.props.tabs;
    if (this.props.sellerlistingtabs.usertype == "personal") {
      var remove = _.remove(tabs, function (c) {
        return c.tabindex == 6; //remove the usertype is personal remove negotiations
      });
    }

    return (
      <>
        <div className="py-3 px-6 flex">
          <img
            className="cursor-pointer"
            src="./images/arrow.svg"
            onClick={(e) => this.closetabs()}
          />
          <span className="px-2  text-xl text-ternary items-center">
            {_.startCase(this.props.sellerlistingtabs.username)}
          </span>
        </div>
        <div className="py-3 px-6">
          {/* Form Tabs */}
          <div>
            <div className="lg:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full focus:ring-primary focus:border-primary border-ternary rounded-md"
                defaultValue={this.props.tabs.find((tab) => tab.current).name}
                onChange={(e) => this.onTabsChange(e, 0, "sm")}>
                {this.props.tabs.map((tab) => (
                  <option
                    key={tab.name}
                    value={tab.tabindex}
                    selected={
                      this.state.tabIndextoShow === tab.tabindex
                        ? "selected"
                        : ""
                    }>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden lg:block">
              <nav className="relative z-0 rounded-lg flex  " aria-label="Tabs">
                {this.props.tabs.map((tab, tabIdx) => (
                  <span
                    style={{ marginRight: "20px", borderRadius: "6px" }}
                    key={tab.name}
                    className={classNames(
                      this.state.tabIndextoShow === tab.tabindex
                        ? "text-secondary"
                        : "text-ternary hover:text-secondary",
                      tabIdx === 0 ? "rounded-l-lg" : "",
                      tabIdx === this.props.tabs.length - 1
                        ? "rounded-r-lg"
                        : "",
                      "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10 "
                    )}
                    aria-current={
                      this.state.tabIndextoShow === tab.tabindex
                        ? "page"
                        : undefined
                    }
                    onClick={(e) => this.onTabsChange(e, tab.tabindex, "lg")}>
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        this.state.tabIndextoShow === tab.tabindex
                          ? "bg-primary"
                          : "bg-transparent",
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </span>
                ))}
              </nav>
            </div>
            <div className="">
              <div className="">
                {/* <form className="space-y-8 divide-y divide-gray-200"> */}
                <div
                  className={this.state.tabIndextoShow === 1 ? "" : "hidden"}>
                  <Selleraboutabs
                    data={this.props.sellerlistingtabs}
                    history={this.props.history}
                  />
                </div>
                <div
                  className={this.state.tabIndextoShow === 2 ? "" : "hidden"}>
                  <Storedetails
                    tabIndextoShow={this.state.tabIndextoShow}
                    data={this.props.sellerlistingtabs}
                    values={this.props.sellerstoredetails}
                    history={this.props.history}
                  />
                </div>
                <div
                  className={this.state.tabIndextoShow === 3 ? "" : "hidden"}>
                  <Sellerproducttabs
                    data={this.props.sellerlistingtabs}
                    history={this.props.history}
                  />
                </div>
                <div
                  className={this.state.tabIndextoShow === 4 ? "" : "hidden"}>
                  <Sellerodertabs
                    data={this.props.sellerlistingtabs}
                    history={this.props.history}
                  />
                </div>
                {/* <div
                  className={this.state.tabIndextoShow === 5 ? "" : "hidden"}
                >
                    <AuctionSellerList data={this.props.sellerlistingtabs} />
             
                </div> */}
                <div
                  className={this.state.tabIndextoShow === 5 ? "" : "hidden"}>
                  <Sellernegotiationtabs
                    data={this.props.sellerlistingtabs}
                    history={this.props.history}
                  />
                </div>
                <div
                  className={this.state.tabIndextoShow === 6 ? "" : "hidden"}>
                  <Sellertransaction
                    data={this.props.sellerlistingtabs}
                    history={this.props.history}
                  />
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </>
      //    )}
      //    </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.sellerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.sellermanagementTabs,
    sellerlistingtabs: state.sellerlistingtabs,
    newtabsellerlist: state.newtabsellerlist,
    data: ownProps.data,
    sellerstoredetails: state.sellerstoredetails,
  };
}

export default connect(mapStateToProps, { newtabseller, fetchStoredetails })(
  Sellertabs
);
