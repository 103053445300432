import React, { Component } from "react";
import { connect } from "react-redux";
import SellermanagementnegotiationTabs from "./negotiationtabs";

// import { myimg } from "/style/images/product_management_images/sort.png";

var Loader = require("react-loaders").Loader; // import "AwesomeLoaderCss";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Sellernegotiationtabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showUploadSection: false,
    };

    // All Binded Functions
  }
  componentDidMount() {
    // console.log('this.props.session', this.props.session)
    // this.props.fetchOderlist(this.props.session,(CallBackResponse) => {
    // });
  }

  customerdetails = (e) => {
    this.setState({ showUploadSection: !this.state.showUploadSection });
  };

  render() {
    return (
      <>
        <SellermanagementnegotiationTabs
          data={this.props.data}
          showUploadSection={this.customerdetails}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('oderlist',state.oderlist);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    data: ownProps.data,
  };
}

// var mapStateToProps = (state) => ({
//     isLoggedIn: state.isLoggedIn,
//     user: state.user,
//     session: state.session,
//     oderlist:state.oderlist
//   });

export default connect(mapStateToProps, {})(Sellernegotiationtabs);
