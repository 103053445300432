import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index";

// Import Actions
import {
  isCouponManageModalopen,
  selectedCouponsTab,
} from "../../actions/index";

// Common Functions

// Functions Import
import { classNames, isEmpty } from "../../../../../common/common-functions";

class CouponManagHead extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    // All Binds Functions
    this.state = {};
  }

  // Modal Open Fc
  openCouponsManagementModal() {
    this.props.isCouponManageModalopen(true);
  }

  // Change Tabs Selection
  _handleTab(e, tabPosition) {
    this.props.selectedCouponsTab(parseInt(tabPosition));
  }

  render() {
    return (
      <>
        {/* <div className="mr-6 mt-1 ">
          <nav
            className="relative z-0 rounded-lg flex w-96 nav-for-allscreen h-8 bg-custWhite cursor-pointer"
            aria-label="Tabs"
          >
            {this.props.couponsTabs.map((tab, tabIdx) => (
              <a
                key={tab.name}
                onClick={(e) => this._handleTab(e, tab.position)}
                className={classNames(
                  tab.position == this.props.storedCouponsTab
                    ? "text-primary"
                    : "text-ternary hover:text-secondary ml-20",
                  tabIdx === 0 ? "rounded-l-lg ml-20" : "",
                  tabIdx === this.props.couponsTabs.length - 1
                    ? "rounded-r-lg ml-20"
                    : "",
                  "saler-date-tabs"
                )}
                aria-current={
                  tab.position == this.props.storedCouponsTab
                    ? "page"
                    : undefined
                }
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.position == this.props.storedCouponsTab
                      ? "bg-primary"
                      : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div> */}
        <div className="end-item mt-5">
          <button
            onClick={(e) => this.openCouponsManagementModal(e)}
            type="button"
            className="lg:w-48 md:w-44 sm:w-44 xs:w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
            {LocaleStrings.add_new_coupons}
            <img
              src="../images/product_management_images/add.svg"
              className="h-5 ml-2"
            />
          </button>
        </div>
      </>
    );
  }
}
// isNegotiationopen

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    couponsTabs: state.couponsTabs,
    storedCouponsTab: state.storedCouponsTab,
  };
}

export default connect(mapStateToProps, {
  isCouponManageModalopen,
  selectedCouponsTab,
})(CouponManagHead);
