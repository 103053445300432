import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index.js";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { reduxForm } from "redux-form";
import { BaseComponent } from "../../../../../common/base-component";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import {
  addCategory,
  uploadMediaLarge,
  fetchCategory,
} from "../../actions/index";
import _ from "lodash";
import { reorderCategory } from "../../actions/index";
import CategoryorderItem from "./category_order-item";

const SortableItem = SortableElement(({ value, index }) => (
  <CategoryorderItem key={index} list={value} />
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul className="list-group">
      {items.map((value, index) => {
        if (value) {
          value.index = index;
          return (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          );
        }
      })}
    </ul>
  );
});

class CategoryOrder extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      showerror: false,
      btnLoader: false,
      alertMessage: "",
      btnLoader: false,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {}

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
    this.props.reset();
    this.setState({ fileArrNew: {} });
    this.setState({ fileUrl: {} });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    // var arrAvailable = this.props.availableFeatures;
    var arrAvailable = _.filter(this.props.catArr, (obj) => {
      return obj.display_order !== 99;
    });

    // console.log('arrAvailable before :- ', arrAvailable);
    arrAvailable = arrayMoveImmutable(arrAvailable, oldIndex, newIndex);

    var newArrAvailable = [];

    //Just map the array and reset the taborder as now taborder is same as the object position
    arrAvailable.map((value, index) => {
      newArrAvailable.push({ ...value, display_order: index + 1 });
    });
    this.props.reorderCategory(
      this.props.session,
      newArrAvailable,
      this.props.catArr,
      "sort"
    );
    // this.props.formEdited(true); // This is used for edit form
  };

  /***** Form Submit *****/
  onSubmitForm(values) {}

  render() {
    var { handleSubmit, initialValues } = this.props;
    return (
      <div className="flex flex-wrap content-center justify-end new-customer">
        <button
          onClick={this.openModal}
          type="submit"
          className="lg:w-48 md:w-44 sm:w-44 xs:w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
          Category Order
          <img
            src="../images/product_management_images/add.svg"
            className="h-5 ml-2"
          />
        </button>
        <Transition.Root show={this.state.open} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.state.open}
            onClose={this.closeModal}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 cat-bigmodal">
                  {" "}
                  Re Order Catgeory
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4 ">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      onClick={this.closeModal}>
                      <span className="sr-only">{LocaleStrings.close}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <form
                    className="space-y-8  divide-gray-200 mt-5"
                    onSubmit={handleSubmit(this.onSubmitForm)}
                    encType="multipart/form-data">
                    {this.props.catArr && this.props.catArr.length > 0 ? (
                      <div className="">
                        <SortableList
                          items={this.props.catArr}
                          onSortEnd={this.onSortEnd}
                          pressDelay={200}
                        />
                        <div ref={this.listRef}></div>
                      </div>
                    ) : (
                      1
                    )}
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    );
  }
}

function validate(values) {
  var errors = {};

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;

  let catArr = state.categorylist.data;

  // let newObj = {'resource':catArr}

  return {
    session,
    user,
    catArr: catArr,
  };
}

export default connect(mapStateToProps, {
  reorderCategory,
})(
  reduxForm({
    validate,
    form: "CategoryOrderForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(CategoryOrder)
);
