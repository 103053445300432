import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import LocaleStrings from "../../../../../languages/index";
import { DATE_FORMAT } from "../../../../../../common/constants";

// Import Actions
import {
  isOpenDiscManagModal,
  getSelectedDiscountObj,
  fetchProductsByCateSubCate,
} from "../../../actions/index";

// Common Functions
import { ProductTextLocalized } from "../../../../../../common/common-functions";

class DiscMasterTableList extends Component {
  constructor(props) {
    super(props);

    // All Binds Functions
  }

  openDiscountManagementModal(e, discountObj) {
    if (Object.keys(discountObj.discountarea_by_iddiscount).length > 0) {
      let idcategory = discountObj.discountarea_by_iddiscount[0].idcategory;
      let idsubcategory =
        discountObj.discountarea_by_iddiscount[0].idsubcategory;

      this.props.fetchProductsByCateSubCate(
        this.props.session,
        idcategory,
        idsubcategory,
        (callBack) => {}
      );
    }

    this.props.getSelectedDiscountObj(discountObj, this.props.session);
    this.props.isOpenDiscManagModal(true);
  }

  render() {
    let language = this.props.language;
    let values = this.props.dismasterObj;
    let discountArea = this.props.discountArea;
    let productCategory = this.props.productCategory;
    let productSubCategory = this.props.productSubCategory;
    let allProducts = this.props.allProducts;
    let area = "";
    let appliedOn = "";
    // console.log('values',values)
    if (values.fromproduct == false) {
      if (values.discountarea_by_iddiscount.length > 0) {
        // console.log(
        //   "test1",
        //   values.discountarea_by_iddiscount
        // );

        // console.log(
        //   "values.discountarea_by_iddiscount[0].discounareaid",
        //   values.discountarea_by_iddiscount[0].discounareaid
        // );

        if (
          values.discountarea_by_iddiscount[0].discounareaid != null &&
          values.discountarea_by_iddiscount[0].discounareaid != ""
        ) {
          // console.log("discountArea", discountArea);

          let afterFindObj = {};
          if (discountArea !== undefined && discountArea !== "undefined") {
            afterFindObj = _.find(discountArea, {
              id: values.discountarea_by_iddiscount[0].discounareaid,
            });

            appliedOn = ProductTextLocalized(
              afterFindObj.area_en,
              afterFindObj.area_ar,
              language
            );
          }
        }
      }
    } else {
      appliedOn = "Product";
    }

    if (appliedOn == "Category") {
      let productCategory_filter = [];
      if (productCategory.data && productCategory.data.length > 0) {
        productCategory_filter = _.filter(productCategory.data, function (o) {
          return (
            o.id === parseInt(values?.discountarea_by_iddiscount[0]?.idcategory)
          );
        });

        if (productCategory_filter.length > 0) {
          area = ProductTextLocalized(
            productCategory_filter[0].categoryname_en,
            productCategory_filter[0].categoryname_ar,
            language
          );
        }
        console.log("productCategory_filter", productCategory_filter);
        console.log("values", values);
      }
    } else if (appliedOn == "Subcategory") {
      let productsubCategory_filter = [];
      if (productSubCategory.data && productSubCategory.data.length > 0) {
        productsubCategory_filter = _.filter(
          productSubCategory.data,
          function (o) {
            return (
              o.id ===
              parseInt(values?.discountarea_by_iddiscount[0]?.idsubcategory)
            );
          }
        );

        if (productsubCategory_filter.length > 0) {
          area = ProductTextLocalized(
            productsubCategory_filter[0].subcategoryname_en,
            productsubCategory_filter[0].subcategoryname_ar,
            language
          );
        }
      }
    } else if (appliedOn == "Product") {
      let product_filter = [];
      if (allProducts.data && allProducts.data.length > 0) {
        product_filter = _.filter(allProducts.data, function (o) {
          return (
            o.id === parseInt(values?.discountarea_by_iddiscount[0]?.idproduct)
          );
        });

        if (product_filter.length > 0) {
          area = ProductTextLocalized(
            product_filter[0].productname_en,
            product_filter[0].productname_ar,
            language
          );
        }
      }
    }

    return (
      <>
        <tr
          key={this.props.position}
          className="bg-white border-b-2 border-gray-50 cursor-pointer hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.title}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {appliedOn}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {area.length > 30 ? area.substring(0, 30) + "..." : area}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {moment(values.validity_from).format(DATE_FORMAT)}
            {" - "}
            {moment(values.validity_end).format(DATE_FORMAT)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.discounttype == "fixed" ? (
              <div>Flat {values.discount_percentage} </div>
            ) : (
              <div>{values.discount_percentage}%</div>
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div className="sales-table-td-text ">
              <button
                type="button"
                className="button"
                onClick={(e) => this.openDiscountManagementModal(e, values)}>
                {LocaleStrings.edit}
              </button>
            </div>
          </td>
        </tr>
      </>
    );
  }
}
// isNegotiationopen

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    discountArea: state.discountArea ? state.discountArea.data : [],
    allProducts: state.allProducts,
    productSubCategory: state.productSubCategory,
    productCategory: state.productCategory,
  };
}

export default connect(mapStateToProps, {
  isOpenDiscManagModal,
  getSelectedDiscountObj,
  fetchProductsByCateSubCate,
})(DiscMasterTableList);
