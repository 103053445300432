import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Dropzone from "react-dropzone";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import { classNames, isEmpty } from "../../../../../../common/common-functions";
import { BaseComponent } from "../../../../../../common/base-component";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  addnewCharity,
  fetchCharityEvents,
  uploadcharityImage,
} from "../../../actions/index";

class Addnewcharity extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      btnLoader: false,
      show: false,
      show1: false,
      enar: "en",
      fileUrl: "",
      fileArr: {},
      fileArrNew: {},
    };

    // All Binded Functions

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }
  openModal() {
    this.setState({ open: true });
    this.props.initialize();
  }
  closeModal() {
    this.setState({ open: false });
    this.props.destroy();
  }

  /**** INPUT LANGUAGE CHANGE en - ar ****/
  handleInputLanguageChange(e) {
    if (parseInt(e.target.value) === 1) {
      this.setState({ enar: "ar" });
    } else {
      this.setState({ enar: "en" });
    }
  }

  onDropFiles = async (files) => {
    let file = files[0];
    let filearr = [];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e) => {
      file.url = e.target.result;
      filearr.push(file);
    };
    this.setState({ filetype: file.type });
    this.setState({ fileArrNew: filearr });
    this.setState({ fileUrl: URL.createObjectURL(file) });
  };

  onSubmitForm(values) {
    if (values.percentage && !values.percentage == "") {
      if (parseInt(values.percentage) < 0) {
        NotificationManager.error("Negative Percentage cannot be added");
        return false;
      }
      this.setState({ btnLoader: true });
      let resource_coverimage = "";
      let uploaderCounter = 0;
      let filetobeupload = [];
      let arrPromise = [];

      if (Object.keys(this.state.fileArrNew).length > 0) {
        // Image Exist
        let fileArrNew = this.state.fileArrNew[0];

        let ts = new Date().getTime(); // Timestamp
        let name = _.trim(values.name_en);

        let medianame = name + ts + "." + "jpg"; // Image Name Creation
        resource_coverimage = medianame;

        let ext = fileArrNew.name.split(".").pop(); // File extension

        let coverJson = {
          type: "image",
          name: medianame,
          media: fileArrNew.url,
        };
        filetobeupload.push(coverJson);
        let counter = filetobeupload.length;

        filetobeupload.map((item, index) => {
          arrPromise.push(
            new Promise((resolve, reject) => {
              this.props.uploadcharityImage(
                this.props.session,
                item,
                (response) => {
                  if (response.success === 0) {
                    resolve();
                  } else {
                    uploaderCounter++;
                    resolve();
                  }
                }
              );
            })
          );
        });
        values.image = "charity_events/" + medianame;
        Promise.all(arrPromise).then(() => {
          this.props.addnewCharity(this.props.session, values, (response) => {
            if (response.success === 0) {
              this.props.reset();
              NotificationManager.error(LocaleStrings.addingfailed);
              this.setState({ btnLoader: false });
            } else {
              this.props.fetchCharityEvents(
                this.props.session,
                0,
                (CallBackResponse) => {}
              );
              this.props.reset();
              this.setState({ btnLoader: false });
              this.setState({ open: false });
              // alert("done");
              NotificationManager.success(LocaleStrings.addedsucessfully);
            }
          });
        });
      } else {
        NotificationManager.error("Please add image to add charity");
      }
      // this.props.addnewCharity(this.props.session, values, (response) => {
      //   if (response.success === 0) {
      //     this.props.reset();
      //     NotificationManager.error(LocaleStrings.addingfailed);
      //     this.setState({ btnLoader: false });
      //   } else {
      //     this.props.fetchCharityEvents(
      //       this.props.session,
      //       0,
      //       (CallBackResponse) => {}
      //     );
      //     this.props.reset();
      //     this.setState({ btnLoader: false });
      //     this.setState({ open: false });
      //     // alert("done");
      //     NotificationManager.success(LocaleStrings.addedsucessfully);
      //   }
      // });
    }
  }

  render() {
    // console.log("in", this.props.initialValues);
    var { handleSubmit, initialValues } = this.props;
    var { enar, fileUrl } = this.state;
    let coverimage = "";
    if (isEmpty(fileUrl)) {
      coverimage = "";
    } else {
      coverimage = fileUrl;
    }
    // console.log('initialValues',initialValues);
    return (
      <div className=" mt-5" style={{ overflowX: "hidden" }}>
        <div className="flex flex-wrap content-center justify-end ">
          <button
            onClick={this.openModal}
            type="submit"
            className="lg:w-48 md:w-44 sm:w-44 xs:w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
            Add Charity
            <img
              src="../images/product_management_images/add.svg"
              className="h-5 ml-2"
            />
          </button>
          <Transition.Root show={this.state.open} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed z-10 inset-0 overflow-y-auto"
              open={this.state.open}
              onClose={this.closeModal}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                  <div className="inline-block align-bottom bg-white rounded-lg  py-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full modal-small">
                    {" "}
                    <div className="grid grid-cols-3 border-b-2 border-gray-200 pb-4 sticky-header z-50">
                      <div></div>
                      <div>Add Charity</div>
                      <div className=" sm:block absolute top-0 right-0 pr-4">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          onClick={this.closeModal}>
                          <span className="sr-only">{LocaleStrings.close}</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <form
                      className="sm:p-6"
                      onSubmit={handleSubmit(this.onSubmitForm)}
                      encType="multipart/form-data">
                      <div>
                        <div className="border-transparent mt-5">
                          <div className="grid lg:grid-cols-2  pt-5">
                            <div>Upload Charity Image</div>
                            <div class="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                              <div className="dropzonecover" key={`drop`}>
                                <div className="img_wrpcover relative dropimgcover">
                                  {coverimage != "" ? (
                                    <>
                                      {this.state.filetype == "image/jpeg" ? (
                                        <img
                                          src={coverimage}
                                          className="dropimgcover-"
                                        />
                                      ) : this.state.filetype == "video/mp4" ? (
                                        <video
                                          controls
                                          controlsList="nodownload"
                                          className="dropimgcover-">
                                          <source
                                            src={coverimage}
                                            type="video/mp4"
                                          />
                                        </video>
                                      ) : (
                                        <img src={coverimage} className="" />
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <Dropzone
                                  onDrop={(e) => this.onDropFiles(e)}
                                  accept="image/jpeg, image/png ,image.jpg ,video/mp4,image/gif"
                                  multiple={false}>
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps()}
                                      className="h-full w-full absolute top-0">
                                      <input {...getInputProps()} />
                                      <p className="paragraph-seconday text-xs pt-10">
                                        {LocaleStrings.imageupload}
                                      </p>
                                    </div>
                                  )}
                                </Dropzone>
                              </div>
                            </div>
                          </div>

                          <div className="mt-5">
                            <Field
                              name="iscustom"
                              label="Add Custom Value"
                              component={this.renderFieldCheckbox}
                              type="checkbox"
                              className="cust-check-box"
                              classNameLabel="text-box-lable"
                            />
                          </div>

                          <div className="mt-5">
                            <Field
                              name={"name_en"}
                              mandatory="true"
                              placeholder={LocaleStrings.name}
                              label="Name(En)"
                              type="text"
                              component={this.renderAllfields}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="mt-5">
                            <Field
                              name={"name_ar"}
                              mandatory="true"
                              placeholder={LocaleStrings.name}
                              label="Name(Ar)"
                              type="text"
                              component={this.renderAllfields}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="mt-5">
                            <Field
                              name="percentage"
                              placeholder="percentage"
                              label="Percentage"
                              type="number"
                              mandatory="true"
                              min="0"
                              component={this.renderAllfields}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="mt-5">
                            <Field
                              name="description_en"
                              placeholder="Add description(En)"
                              label="Description"
                              type="text"
                              component={this.renderFieldTextareaNew}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="mt-5">
                            <Field
                              name="description_ar"
                              placeholder="Add description(Ar)"
                              label="Description"
                              type="text"
                              component={this.renderFieldTextareaNew}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="pt-5">
                        <div className="flex justify-end">
                          <button
                            onClick={this.closeModal}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {LocaleStrings.cancel}
                          </button>
                          <button
                            type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <svg
                              className={classNames(
                                this.state.btnLoader ? "" : "sr-only",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    );
  }
}

function validate(values) {
  var errors = {};
  var name_en = values["name_en"];
  var percentage = values["percentage"];

  if (!percentage || percentage === "") {
    errors["percentage"] = "Required";
  }
  if (!name_en || name_en.trim() === "") {
    errors["name_en"] = "Required";
  }
  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  let initval = {};
  initval = {
    language: "0",
  };
  return {
    session,
    user,
    initialValues: initval,
  };
}

export default connect(mapStateToProps, {
  addnewCharity,
  fetchCharityEvents,
  uploadcharityImage,
})(
  reduxForm({
    validate,
    form: "Addnewcharityform",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Addnewcharity)
);
