import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import LocaleStrings from "../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import { fetchFeeds, searchFeeds } from "../actions/index";
import FeedListItems from "./feedlist-items";
import Pagination from "react-js-pagination";
import BaseLoader from "../../../../common/base-loader";
import { classNames } from "../../../../common/common-functions";

class FeedsManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaderDesc: LocaleStrings.feeds_management,
      loading: true,
      search: "",
      pageNumber: 1,
    };

    // All Binded Functions
  }

  componentDidMount() {
    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation.data, {
      href: answer,
    });

    if (singleNavigationRow == undefined) {
      this.props.history.push("/login");
    }
    this.props.fetchFeeds(
      this.props.session,
      this.state.pageNumber,
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }
  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchFeeds(
        this.props.session,
        pageNumber,
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchFeeds(this.props.session, search);
    }
    this.setState({ search, pageNumber });
  };

  paginationCallback = (pageNumber) => {
    // console.log('pageNumber',pageNumber)
    this.props.fetchFeeds(
      this.props.session,
      pageNumber,
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  renderfeeds() {
    let pageDataLength = this.props.Allfeeds_lists.data.length;

    let { pageNumber } = this.state;
    // console.log('pageNumber:-->',pageNumber)
    // console.log('feedlist--->',this.props.Allfeeds_lists.data)
    var items = _.map(this.props.Allfeeds_lists.data, (values, index) => (
      <FeedListItems
        key={index}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    return items;
  }

  render() {
    let { language } = this.props;
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <div
            className="grid gap-4 grid-rows-1 px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1"
            style={{
              overflowX: "hidden",
              overflowY: "hidden",
              display: "block",
            }}>
            <div className="mb-4 text-2xl font-semibold text-secondary">
              FEEDS MANAGEMENT
            </div>
            <div className="grid grid-cols-2">
              <div className="relative  text-ternary focus-within:text-secondary ">
                <div
                  className={classNames(
                    language == "en" ? "left-0 " : "right-0",
                    "absolute inset-y-0 flex items-center pointer-events-none px-1"
                  )}>
                  <SearchIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className={classNames(
                    language == "en" ? "pl-8 pr-3" : "pr-8 pl-3",
                    "block lg:w-6/12 xs:w-full sm:w-full h-full  py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                  )}
                  placeholder={LocaleStrings.search}
                  type="search"
                  name="search"
                  value={this.state.search}
                  onChange={this.handleSearch}
                />
              </div>
            </div>
            <div className="flex flex-col pt-10 pb-2">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  {this.renderfeeds()}
                </div>
              </div>
            </div>
            <div style={{ overflowX: "auto" }}>
              <Pagination
                activePage={this.state.pageNumber}
                itemsCountPerPage={10}
                totalItemsCount={this.props.Allfeeds_lists.count}
                pageRangeDisplayed={5}
                onChange={this.paginationCallback}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, language } = state;
  // console.log("state.Allfeeds_lists", state.Allfeeds_lists);
  return {
    session,
    user,
    language,
    Allfeeds_lists: state.Allfeeds_lists,
    navigation: state.sideBarMenus,
  };
}

export default connect(mapStateToProps, {
  fetchFeeds,
  searchFeeds,
})(FeedsManagement);
