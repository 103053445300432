import React, { Component } from "react";
import { connect } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { HashRouter, Route, Switch, hashHistory } from "react-router-dom";

import { createBrowserHistory } from "history";
import thunk from "redux-thunk";

import { loadingBarMiddleware } from "react-redux-loading-bar";
import { errorBarMiddleware } from "./common/errorbar/index";

import Login from "./components/starter/login.js";
import Sidebar from "./components/dashboard/sidebar/components/index";
import reducers from "./reducers";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import { loadState, saveState } from "./localStorage";

var persistedState = loadState();

var createStoreWithMiddleware = applyMiddleware(
  thunk,
  loadingBarMiddleware(),
  errorBarMiddleware()
)(createStore);

export var store = createStoreWithMiddleware(reducers, persistedState);

store.subscribe(() => {
  alert(8);
  saveState(store.getState());
  // console.log('store.getState',store.getState());
});

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL, // The base URL of the app (see below)
  hashType: "slash", // The hash type to use (see below)
  forceRefresh: true,
});

class App extends Component {
  constructor(props) {
    super(props);
    if (!this.props.isLoggedIn) {
    }
  }

  render() {
    if (!this.props.isLoggedIn) {
    }

    return (
      <HashRouter>
        <div className="full-height">
          <TransitionGroup>
            <CSSTransition
              transitionName="example"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}
              timeout={300}>
              <Switch>
                <Route
                  path="/dashboard"
                  component={(props) => <Sidebar {...props} />}
                />
                <Route path="/" component={(props) => <Login {...props} />} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </HashRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapStateToProps)(App);
