import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index";
import _ from "lodash";
import toast, { Toaster } from "react-hot-toast";
import { SearchIcon } from "@heroicons/react/solid";
import ExpiredDiscountList from "./expireddiscountlist-items";
// Import Components
import BaseLoader from "../../../../../../common/base-loader";

// Import Action
import { fetchDiscountMaster } from "../../../actions/index";

class ExpiredDiscount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.discount_management,
      search: "",
      pageNumber: 1,
    };

    // All Binded Functions
  }

  /*** Call Only When Componenet Mount 1 time) ***/
  componentDidMount() {
    this.props.fetchDiscountMaster(this.props.session, 0, (callBack) => {
      this.setState({ loading: false });
    });
  }

  // Render Discount master List
  renderDiscountMasterList() {
    return _.map(this.props.discountMasterStore, (dismasterObj, index) => {
      return (
        <ExpiredDiscountList
          key={dismasterObj.id}
          dismasterObj={dismasterObj}
          position={index}
        />
      );
    });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader className="mt-4" data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {_.isEmpty(this.props.discountMasterStore.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  No Inactive discount
                </div>
              </>
            ) : (
              <>
                {" "}
                <div
                  className="grid gap-4 grid-rows-1 px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 mt-5"
                  style={{
                    overflowX: "hidden",
                    overflowY: "hidden",
                    display: "block",
                  }}>
                  <div className="grid grid-cols-2">
                    <div className="relative  text-ternary focus-within:text-secondary ">
                      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                      </div>
                      <input
                        id="search-field"
                        className="block lg:w-6/12 sm:w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                        placeholder={LocaleStrings.search}
                        type="search"
                        name="search"
                        onChange={(e) =>
                          this.setState({ SpecsSearchText: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col pt-10 pb-2">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-custWhite">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  {LocaleStrings.discountname}
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  {LocaleStrings.appliedon}
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Area
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  {LocaleStrings.validity}
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  {/* {LocaleStrings.discount} */} Percentage
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  {LocaleStrings.action}
                                </th>
                              </tr>
                            </thead>
                            <tbody>{this.renderDiscountMasterList()}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  discountMasterStore: state.discountMasterStore
    ? state.discountMasterStore.data
    : [],
});

export default connect(mapStateToProps, {
  fetchDiscountMaster,
})(ExpiredDiscount);
