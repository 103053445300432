import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";
import ProductmanagementlistTabs from "./producttabs";
import _ from "lodash";
import { fetchallProductlist } from "../actions/index";
import BaseLoader from "../../../../common/base-loader.js";

class ProductManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.product_management,
      pageNumber: 1,
    };
  }

  componentDidMount() {
    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation.data, {
      href: answer,
    });

    if (singleNavigationRow == undefined) {
      this.props.history.push("/login");
    }

    this.props.history.push("/dashboard/productmanagement/allitems");
  }
  customerdetails = (e, data) => {
    this.setState({ showUploadSection: !this.state.showUploadSection });
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <ProductmanagementlistTabs
              showUploadSection={this.customerdetails}
              history={this.props.history}
            />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, allproductslist } = state;
  return {
    session,
    user,
    allproductslist,
    navigation: state.sideBarMenus,
  };
}

export default connect(mapStateToProps, {
  fetchallProductlist,
})(ProductManagement);
