import React from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../../common/base-component";
import EditAdmin from "./editadmin";
import { onEditadmin } from "../../actions/index";
class SuperAdminListItems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
    };
  }

  openModal = (e, obj) => {
    this.props.onEditadmin(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  render() {
    var { values, isLoggedIn } = this.props;
    // console.log('adminvalues1', values);
    return (
      <>
        {isLoggedIn == true ? (
          <>
            <tr className="bg-white border-b-2 border-gray-50 hover-all">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                <div>{values.username}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                <div>{values.email}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                <div>{values.phone}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="">
                    <button
                      className="button"
                      onClick={(e) => this.openModal(e, values)}
                      type="button">
                      Edit
                    </button>

                    {this.state.isOpen ? (
                      <EditAdmin
                        isOpen={this.state.isOpen}
                        onClose={this.closeModal}
                        // values={this.props.values}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </td>
            </tr>
          </>
        ) : (
          <>
            {" "}
            <tr className="bg-white border-b-2 border-gray-50 hover-all">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                <div>{values.username}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                <div>{values.email}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                <div>{values.phone}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="">
                    <button
                      className="button"
                      onClick={(e) => this.openModal(e, values)}
                      type="button">
                      Edit
                    </button>
                    {this.state.isOpen ? (
                      <EditAdmin
                        isOpen={this.state.isOpen}
                        onClose={this.closeModal}
                        // values={this.props.values}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ml-3">
                    <button className="button">Ban</button>
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log("customerlistingtabs", state.isLoggedIn);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapStateToProps, {
  onEditadmin,
})(SuperAdminListItems);
