import Recat, { Component } from "react";
import { connect } from "react-redux";
import { XIcon } from "@heroicons/react/outline";
import LocaleStrings from "../../../../../languages/index";
import NewoderList from "./newoder";
import DeliveredoderList from "./deliveredoder";
import DisputedorderList from "./disputedoder";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class OrderlistTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndextoShow: 1,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  // On Tabs change
  onTabsChange = (e, tabindex, callfrom) => {
    if (callfrom === "lg") {
      // For tabs
      this.setState({ tabIndextoShow: parseInt(tabindex) });
    } else {
      // For Select box
      this.setState({ tabIndextoShow: parseInt(e.target.value) });
    }
  };

  render() {
    var selectedTab = "";
    return (
      <>
        <div className="">
          {/* Form Tabs */}
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full focus:ring-primary focus:border-primary border-ternary rounded-md"
                defaultValue={this.props.tabs.find((tab) => tab.current).name}
                onChange={(e) => this.onTabsChange(e, 0, "sm")}>
                {this.props.tabs.map((tab) => (
                  <option
                    key={tab.name}
                    value={tab.tabindex}
                    selected={
                      this.state.tabIndextoShow === tab.tabindex
                        ? "selected"
                        : ""
                    }>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="relative z-0 rounded-lg flex  " aria-label="Tabs">
                {this.props.tabs.map((tab, tabIdx) => (
                  <span
                    style={{ marginRight: "20px", borderRadius: "6px" }}
                    key={tab.name}
                    className={classNames(
                      this.state.tabIndextoShow === tab.tabindex
                        ? "text-secondary"
                        : "text-ternary hover:text-secondary",
                      tabIdx === 0 ? "rounded-l-lg" : "",
                      tabIdx === this.props.tabs.length - 1
                        ? "rounded-r-lg"
                        : "",
                      "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10 "
                    )}
                    aria-current={
                      this.state.tabIndextoShow === tab.tabindex
                        ? "page"
                        : undefined
                    }
                    onClick={(e) => this.onTabsChange(e, tab.tabindex, "lg")}>
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        this.state.tabIndextoShow === tab.tabindex
                          ? "bg-primary"
                          : "bg-transparent",
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </span>
                ))}
              </nav>
            </div>
            <div className="">
              <div className="my-5">
                <form className="">
                  <div
                    className={this.state.tabIndextoShow === 1 ? "" : "hidden"}>
                    <NewoderList data={this.props.data} />
                  </div>
                  <div
                    className={this.state.tabIndextoShow === 2 ? "" : "hidden"}>
                    <DeliveredoderList data={this.props.data} />
                  </div>
                  <div
                    className={this.state.tabIndextoShow === 3 ? "" : "hidden"}>
                    <DisputedorderList data={this.props.data} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.OderTabs,
  };
}

export default connect(mapStateToProps, {})(OrderlistTabs);
