import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
  timeDiffCalc,
} from "../../../../../../common/constants";
import AllitemProductdetailmodal from "../../../../sellermanagement/components/tabs/product/allitems-productdetails";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../../currency/action/index";

class AuctionParticipatedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      timeText: "",
      timeInterval: 0,
      endDate: "",
    };
  }

  componentDidMount() {
    // console.log('abcd',this.props.data)
    setTimeout(() => {
      this.setState({ timeInterval: this.timeInterval + 1 });
    }, 2000);
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidUpdate() {
    if (this.state.endDate) {
      let timeText = timeDiffCalc(new Date(this.state.endDate), new Date());
      this.setState({ timeText: timeText });
    }
  }

  render() {
    var { values, language, localCurrency } = this.props;

    let tabledate = "";
    tabledate = moment(values.createdat).format("D MMM YYYY HH:mm A");
    var img = `${BASE_IMAGES_URL}/vendor/variations/${values.product.productimage}?api_key=${APP_API_KEY}`;

    var endDate = "";

    let currentDate = moment();
    if (
      values.product.auction_start_date &&
      values.product.auction_end_date &&
      currentDate.isAfter(moment(values.product.auction_start_date)) &&
      currentDate.isBefore(moment(values.product.auction_end_date))
    ) {
      endDate = values.product.auction_end_date;
    } else if (
      values.product.auction_start_date &&
      values.product.auction_end_date &&
      currentDate.isBefore(moment(values.product.auction_start_date)) &&
      currentDate.isBefore(moment(values.product.auction_end_date))
    ) {
      endDate = values.product.auction_start_date;
    } else if (
      values.product.auction_start_date &&
      values.product.auction_end_date &&
      currentDate.isAfter(moment(values.product.auction_start_date)) &&
      currentDate.isAfter(moment(values.product.auction_end_date))
    ) {
      endDate = "";
    }
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    let bidprice = 0.0;
    let maxbid = 0.0;
    let productCurrency = "QAR";
    if (values.currency && (values.currency != "" || values.currency != null)) {
      productCurrency = values.currency;
    }

    bidprice = values.bidprice;
    maxbid = values.maxbid;

    return (
      <>
        <tr
          className="bg-white border-b-2 border-gray-50 hover-all cursor-pointer"
          onClick={(e) => this.openModal(e, values.product)}>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ display: "flex" }}>
            <div className="mr-2">
              <img src={img} style={{ height: "40px", width: "40px" }} />
            </div>
            <div>
              {values.product.productname_en.length > 35
                ? values.product.productname_en.substring(0, 25) + "..."
                : values.product.productname_en}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
            {currency} {bidprice}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
            {values.status == "lost" ? (
              <span>
                {currency} {maxbid}
              </span>
            ) : values.status == "won" ? (
              <span>
                {currency} {bidprice}
              </span>
            ) : (
              ""
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
            {tabledate}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
            {endDate === ""
              ? "Auction Over"
              : timeDiffCalc(new Date(endDate), new Date())}
          </td>
        </tr>
        {this.state.isOpen ? (
          <AllitemProductdetailmodal
            isOpen={this.state.isOpen}
            onClose={this.closeModal}
            productDetails={this.props.values.product}
            qa={this.props.values.product.qa}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.customermanagementTabs,
    customereditmodal: state.customereditmodal,
    customerlistingtabs: state.customerlistingtabs,
    newtablist: state.newtablist,
    localCurrency: state.currency,
  };
}

export default connect(mapStateToProps, { isCurrencyRateApplicable })(
  AuctionParticipatedItems
);
