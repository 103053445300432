import {showError,showSuccess,hideError} from './actions';
// import ErrorBarContainer,{ErrorBar}  from '../errorbar/errorbar';
import ErrorBar from '../errorbar/errorbar';
import {ErrorBarReducer} from './reducers';
import errorBarMiddleware from './error_bar_middleware';


export {
  showError,
  showSuccess,
  hideError,
  ErrorBarReducer,
  ErrorBar,
  errorBarMiddleware
}

// export default ErrorBarContainer;
export default ErrorBar;