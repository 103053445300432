import React, { Component } from "react";
import { connect } from "react-redux";
import { RadioGroup } from "@headlessui/react";
import _ from "lodash";
import LocaleStrings from "../../../languages/index";
// Import Actions
import { localeLanguage } from "../../../../actions/index";
import { onSidebarMenuSelected } from "../../sidebar/actions";

const settings = [
  { name: "(en)", description: "English" },
  { name: "(ar)", description: "عربى" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Language extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: settings[0],
    };

    // All Binded Functions
  }

  componentDidMount() {
    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation.data, {
      href: answer,
    });

    if (singleNavigationRow == undefined) {
      this.props.history.push("/login");
    }
    if (this.props.language === "ar") {
      this.setState({ selected: settings[1] });
    }
  }

  componentDidUpdate() {
    // console.log(this.state.language);
  }

  // On Radio Chnage
  onRadioChange = (e) => {
    this.setState({ selected: e });

    if (e.name === "(en)") {
      this.props.localeLanguage("en");
    } else {
      this.props.localeLanguage("ar");
    }
  };

  render() {
    return (
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-secondary">
            {LocaleStrings.change_language}
          </h1>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Replace with your content */}
          <div className="py-4">
            <RadioGroup
              value={this.state.selected}
              onChange={(e) => this.onRadioChange(e)}>
              <RadioGroup.Label className="sr-only">
                Privacy setting
              </RadioGroup.Label>
              <div className="bg-custWhite rounded-md -space-y-px">
                {settings.map((setting, settingIdx) => (
                  <RadioGroup.Option
                    key={setting.name}
                    value={setting}
                    className={({ checked }) =>
                      classNames(
                        settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                        settingIdx === settings.length - 1
                          ? "rounded-bl-md rounded-br-md"
                          : "",
                        checked
                          ? "bg-primaryLight border-indigo-200 z-10"
                          : "border-gray-200",
                        "relative border p-4 flex cursor-pointer focus:outline-none"
                      )
                    }>
                    {({ active, checked }) => (
                      <>
                        <span
                          className={classNames(
                            checked
                              ? "bg-primary border-transparent"
                              : "bg-custWhite border-gray-300",
                            active ? "ring-2 ring-offset-2 ring-primaty" : "",
                            "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                          )}
                          aria-hidden="true">
                          <span className="rounded-full bg-custWhite w-1.5 h-1.5" />
                        </span>
                        <div className="mx-3 flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className={classNames(
                              checked ? "text-primary" : "text-secondary",
                              "block text-sm font-medium"
                            )}>
                            {setting.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={classNames(
                              checked ? "text-primary" : "text-ternary",
                              "block text-sm"
                            )}>
                            {setting.description}
                          </RadioGroup.Description>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
          {/* /End replace */}
        </div>
      </div>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  language: state.language,
  navigation: state.sideBarMenus,
  sidebarSelectedMenu: state.sidebarSelectedMenu,
});

export default connect(mapStateToProps, {
  localeLanguage,
  onSidebarMenuSelected,
})(Language);
