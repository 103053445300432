import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import { fetchAuctionList } from "../../../actions/index";
import AuctionParticipatedItems from "./participated-items";
import _ from "lodash";
import BaseLoader from "../../../../../../common/base-loader";
import Pagination from "react-js-pagination";
import { classNames } from "../../../../../../common/common-functions";

const limit = 10;

class AuctionParticipated extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      open: false,
      setOpen: false,
      participatedAuctionSearch: "",
      pageNumber: 1,
      searchText: "",
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    this.props.fetchAuctionList(
      this.props.session,
      this.props.data.userid,
      limit,
      this.state.pageNumber,
      "listing",
      "",
      (CallBackResponse) => {}
    );
  }

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }

  paginationCallback = (pageNumber) => {
    this.props.fetchAuctionList(
      this.props.session,
      this.props.data.userid,
      limit,
      pageNumber,
      "listing",
      "",
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    var searchText = event.target.value;
    var pageNumber = 1;

    if (searchText === "") {
      this.setState({ loading: true });
      // Paginated list
      /* 
        Fetch transaction history list [paginated]
        New Params : {foruserid:userid,"limit":<num of rows to show>,"page":curpage,type:"listing"}
        */
      this.props.fetchAuctionList(
        this.props.session,
        this.props.data.userid,
        limit,
        pageNumber,
        "listing",
        "",
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    } else {
      /* 
        search list without pagination
        Params: {foruserid:userid,type:"search",searchterm:<search text>}
        */
      this.props.fetchAuctionList(
        this.props.session,
        this.props.data.userid,
        limit,
        pageNumber,
        "search",
        searchText,
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    }
    this.setState({ searchText, pageNumber });
  };

  renderList() {
    let pageDataLength = this.props.auctionList.data.length;
    let { searchText, pageNumber } = this.state;
    var items = _.map(this.props.auctionList.data, (values, index) => (
      <AuctionParticipatedItems
        key={index}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));

    return items;
  }

  render() {
    let { language } = this.props;
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {" "}
            <div className="relative  text-ternary focus-within:text-secondary ">
              <div
                className={classNames(
                  language == "en" ? "left-0 " : "right-0",
                  "absolute inset-y-0 flex items-center pointer-events-none px-1"
                )}>
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className={classNames(
                  language == "en" ? "pl-8 pr-3" : "pr-8 pl-3",
                  "block lg:w-3/12 xs:w-full sm:w-full h-full  py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                )}
                placeholder={LocaleStrings.search}
                type="search"
                name="search"
                onChange={this.handleSearch}
              />
            </div>
            {_.isEmpty(this.props.auctionList.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  Not Participated
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col mt-5">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.product}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.placedbid}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.highestbid}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.placedon}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.timeleft}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.renderList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.searchText === "" ? (
                  <Pagination
                    activePage={this.state.pageNumber}
                    itemsCountPerPage={10}
                    totalItemsCount={this.props.auctionList.count}
                    pageRangeDisplayed={10}
                    onChange={this.paginationCallback}
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // console.log('auctionList', state.auctionList);
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    language: state.language,
    auctionList: state.auctionList,
  };
}

export default connect(mapStateToProps, {
  fetchAuctionList,
})(AuctionParticipated);
