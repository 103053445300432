import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "../../../../languages/index";
import _ from "lodash";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import {
  ProductTextLocalized,
  classNames,
} from "../../../../../common/common-functions";
import {
  updateDeliveryAgents,
  fetchAllDeliveryPartners,
  editagents,
} from "../../actions/index";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { NotificationManager } from "react-notifications";

class Editdeliveryagents extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      discounttype: "",
      specificationAreaType: "",
      selectedCateId: "",
      selectedSubCateId: "",
      selectedProductId: "",
      btnLoader: false,
      mandatoryspecs: "",
      specsProductCategory: "",
      btnLoader: false,
    };

    // All Binded Functions
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {}

  /**** Fake Close ****/
  closeModal1() {}

  /**** Close Modal  ****/
  closeModal() {
    // Clear Single Discount Object (Pointing to)
    this.props.editagents({}, this.props.session);
  }

  onSubmitForm(values) {
    let packages = [];
    if (
      !values.packagename ||
      values.packagename.length == 0 ||
      values.packagename == ""
    ) {
      // alert("done");
      NotificationManager.error("Please add Services");
      return false;
    }
    this.setState({ btnLoader: true });
    values.packagename.map((val, key) => {
      let obj = val.value;
      packages.push(obj);
    });

    var deliveryagentAddData = {
      packages: packages,
      companyname: values.companyname,
      keycode: values.keycode,
      toedit: 1,
      isactive: 1,
      idcompany: this.props.initialValues.id,
    };
    this.props.updateDeliveryAgents(
      this.props.session,
      deliveryagentAddData,
      (response) => {
        if (response.success === 0) {
          this.props.onClose();
          // alert("error");
          NotificationManager.error(LocaleStrings.updatederror);
        } else {
          this.props.fetchAllDeliveryPartners(
            this.props.session,
            1,
            (CallBackResponse) => {}
          );
          this.props.onClose();
          // alert("done");
          NotificationManager.success(LocaleStrings.updatedsuccessfully);
        }
      }
    );
  }

  render() {
    let { initialValues, language, handleSubmit } = this.props;

    var DeliveryPackagesOpts = [];

    _.forEach(this.props.ServicesAvailable, function (value) {
      var obj = {
        label: ProductTextLocalized(value.name),
        value: value.id.toString(),
        // value: value.value,
      };

      DeliveryPackagesOpts.push(obj);
    });

    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isOpen}
            onClose={this.closeModal1}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0 }}>
                  {/* MOdal Header */}
                  <div className="grid grid-cols-3 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center mt-4">
                      <div className="text-lg leading-6 font-medium text-secondary">
                        {LocaleStrings.global_setting_editAgents}
                      </div>
                    </div>
                    <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.props.onClose}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className=" p-6"
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
                    <Field
                      name="companyname"
                      label="Company Name"
                      placeholder="Company Name"
                      type="text"
                      component={this.renderFieldcustomer}
                      className="appearance-none block w-half px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                    />
                    <div className="mb-5">
                      <Field
                        name="keycode"
                        label="Key Code"
                        placeholder="Key Code"
                        mandatory="false"
                        component={this.renderFieldTextareaNew}
                        maxlength={200}
                        labelposition={LABEL_POSITION_TOP}
                        classNameLabel="text-box-lable"
                        className="py-3 px-4 block w-full shadow-sm text-secondary focus:ring-primary focus:border-teal-500 border border-warm-gray-300 rounded-md"
                      />
                    </div>
                    <div className="mb-5">
                      <Field
                        name="packagename"
                        label="Services Available"
                        placeholder={LocaleStrings.select}
                        options={DeliveryPackagesOpts}
                        component={this.renderAutoComplete}
                        labelposition={LABEL_POSITION_TOP}
                        labelKey="label"
                        valueKey="value"
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                        isMulti={true}
                      />
                    </div>

                    <div className="w-full bottom-0 absolute mb-10">
                      <div className="flex justify-center">
                        <button type="submit" className="button">
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "sr-only",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          {LocaleStrings.save}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = ["packagename", "keycode", "companyname"];

  requiredFields.forEach((field) => {
    // if (typeof values[field] != 'undefined'){
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
    // }
  });

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log('state.Edit_delivery_agents',state.Edit_delivery_agents)
  return {
    session,
    user,
    ServicesAvailable: state.ServicesAvailable,
    initialValues: state.Edit_delivery_agents,
  };
}

export default connect(mapStateToProps, {
  updateDeliveryAgents,
  fetchAllDeliveryPartners,
  editagents,
})(
  reduxForm({
    validate,
    form: "Editdeliveryagents",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Editdeliveryagents)
);
