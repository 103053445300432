import { INSTANCE_URL } from "../../../../common/constants";
import { postRequest } from "../../../../common/network-call";

export var ALL_DASHBOARD_STATS = "ALL_DASHBOARD_STATS";
export var USERS_TODAY_STATS = "USERS_TODAY_STATS";
export var USERS_WEEKS_STATS = "USERS_WEEKS_STATS";
export var USERS_MONTH_STATS = "USERS_MONTH_STATS";
export var VENDOR_DAY_STATS = "VENDOR_DAY_STATS";
export var VENDOR_WEEKS_STATS = "VENDOR_WEEKS_STATS";
export var VENDOR_MONTH_STATS = "VENDOR_MONTH_STATS";
export var PRODUCTBASE_DAY_STATS = "PRODUCTBASE_DAY_STATS";
export var PRODUCTBASE_WEEK_STATS = "PRODUCTBASE_WEEK_STATS";
export var PRODUCTBASE_MONTH_STATS = "PRODUCTBASE_MONTH_STATS";
export var NEWORDER_DAY_STATS = "NEWORDER_DAY_STATS";
export var NEWORDER_WEEK_STATS = "NEWORDER_WEEK_STATS";
export var NEWORDER_MONTH_STATS = "NEWORDER_MONTH_STATS";
export var ONGOINGORDER_DAY_STATS = "ONGOINGORDER_DAY_STATS";
export var ONGOINGORDER_WEEK_STATS = "ONGOINGORDER_WEEK_STATS";
export var ONGOINGORDER_MONTH_STATS = "ONGOINGORDER_MONTH_STATS";
export var COMPLETEDORDER_DAY_STATS = "COMPLETEDORDER_DAY_STATS";
export var COMPLETEDORDER_WEEK_STATS = "COMPLETEDORDER_WEEK_STATS";
export var COMPLETEDORDER_MONTH_STATS = "COMPLETEDORDER_MONTH_STATS";
export var CANCELLEDORDER_DAY_STATS = "CANCELLEDORDER_DAY_STATS";
export var CANCELLEDORDER_WEEK_STATS = "CANCELLEDORDER_WEEK_STATS";
export var CANCELLEDORDER_MONTH_STATS = "CANCELLEDORDER_MONTH_STATS";
export var REPLACEORDER_DAY_STATS = "REPLACEORDER_DAY_STATS";
export var REPLACEORDER_WEEK_STATS = "REPLACEORDER_WEEK_STATS";
export var REPLACEORDER_MONTH_STATS = "REPLACEORDER_MONTH_STATS";
export var PENDINGNEGOTIATION_DAY_STATS = "PENDINGNEGOTIATION_DAY_STATS";
export var PENDINGNEGOTIATION_WEEK_STATS = "PENDINGNEGOTIATION_WEEK_STATS";
export var PENDINGNEGOTIATION_MONTH_STATS = "PENDINGNEGOTIATION_MONTH_STATS";
export var ACCEPTEDNEGOTIATION_DAY_STATS = "ACCEPTEDNEGOTIATION_DAY_STATS";
export var ACCEPTEDNEGOTIATION_WEEK_STATS = "ACCEPTEDNEGOTIATION_WEEK_STATS";
export var ACCEPTEDNEGOTIATION_MONTH_STATS = "ACCEPTEDNEGOTIATION_MONTH_STATS";
export var REJECTEDNEGOTIATION_DAY_STATS = "REJECTEDNEGOTIATION_DAY_STATS";
export var REJECTEDNEGOTIATION_WEEK_STATS = "REJECTEDNEGOTIATION_WEEK_STATS";
export var REJECTEDNEGOTIATION_MONTH_STATS = "REJECTEDNEGOTIATION_MONTH_STATS";
export var ACTIVEAUCTION_DAY_STATS = "ACTIVEAUCTION_DAY_STATS";
export var ACTIVEAUCTION_WEEK_STATS = "ACTIVEAUCTION_WEEK_STATS";
export var ACTIVEAUCTION_MONTH_STATS = "ACTIVEAUCTION_MONTH_STATS";
export var COMPLETEDAUCTION_DAY_STATS = "COMPLETEDAUCTION_DAY_STATS";
export var COMPLETEDAUCTION_WEEK_STATS = "COMPLETEDAUCTION_WEEK_STATS";
export var COMPLETEDAUCTION_MONTH_STATS = "COMPLETEDAUCTION_MONTH_STATS";
export var PAYMENTS_DAY_STATS = "PAYMENTS_DAY_STATS";
export var PAYMENTS_WEEK_STATS = "PAYMENTS_WEEK_STATS";
export var PAYMENTS_MONTH_STATS = "PAYMENTS_MONTH_STATS";
export var STATISTICS_UPDATE_DATE_PROPS = "STATISTICS_UPDATE_DATE_PROPS";
export var CHART_VALUES = "CHART_VALUES";
export var MOSTACTIVE_USERS = "MOSTACTIVE_USERS";
export var MOSTACTIVE_VENDORS = "MOSTACTIVE_VENDORS";
export var MOSTACTIVE_PRODUCTS = "MOSTACTIVE_PRODUCTS";

export function fetchAllstats(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/allstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        session: session,
      },
      session,
      dispatch,
      (data) => {
        // console.log('data5', data.data);
        dispatch({
          type: ALL_DASHBOARD_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchUserstoday(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"users",
      //   tab:"today",
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('Userstoday', data.data);
        dispatch({
          type: USERS_TODAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchUsersWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "users",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('Userstoday', data.data);
        dispatch({
          type: USERS_WEEKS_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchUsersMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "users",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchUsersMonth', data.data);
        dispatch({
          type: USERS_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchVendorDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"vendors",
      //   tab:"today"
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchUsersMonth', data.data);
        dispatch({
          type: VENDOR_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchVendorsWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "vendors",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('Userstoday', data.data);
        dispatch({
          type: VENDOR_WEEKS_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchVendorsMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "vendors",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchUsersMonth', data.data);
        dispatch({
          type: VENDOR_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchProductBaseDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"products",
      //   tab:"today"
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchProductBaseDay', data.data);
        dispatch({
          type: PRODUCTBASE_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchProductBaseWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "products",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchProductBaseWeek', data);
        dispatch({
          type: PRODUCTBASE_WEEK_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchProductBaseMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "products",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchProductBaseMonth', data);
        dispatch({
          type: PRODUCTBASE_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchNewordersDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"neworders",
      //   tab:"today"
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchNewordersDay', data);
        dispatch({
          type: NEWORDER_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchNewordersWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "neworders",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchNewordersWeek', data);
        dispatch({
          type: NEWORDER_WEEK_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchNewordersMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "neworders",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchNewordersMonth', data);
        dispatch({
          type: NEWORDER_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchOngoingordersDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"activeorders",
      //   tab:"today"
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchOngoingordersDay', data);
        dispatch({
          type: ONGOINGORDER_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchOngoingordersWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "activeorders",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchOngoingordersWeek', data);
        dispatch({
          type: ONGOINGORDER_WEEK_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchOngoingordersMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "activeorders",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchOngoingordersMonth', data);
        dispatch({
          type: ONGOINGORDER_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchCompletedordersDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"completedorders",
      //   tab:"today"
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchCompletedordersDay', data);
        dispatch({
          type: COMPLETEDORDER_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchCompletedordersWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "completedorders",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchCompletedordersWeek', data);
        dispatch({
          type: COMPLETEDORDER_WEEK_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchCompletedordersMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "completedorders",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchCompletedordersMonth', data);
        dispatch({
          type: COMPLETEDORDER_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchCancelledordersDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"cancelledorders",
      //   tab:"today"
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchCancelledordersDay', data);
        dispatch({
          type: CANCELLEDORDER_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchCancelledordersWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "cancelledorders",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchCancelledordersWeek', data);
        dispatch({
          type: CANCELLEDORDER_WEEK_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchCancelledordersMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "cancelledorders",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchCancelledordersMonth', data);
        dispatch({
          type: CANCELLEDORDER_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchReplacedordersDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"replacedorders",
      //   tab:"today"
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchUsersMonth', data.data);
        dispatch({
          type: REPLACEORDER_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchReplacedordersWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "replacedorders",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('Userstoday', data.data);
        dispatch({
          type: REPLACEORDER_WEEK_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchReplacedordersMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "replacedorders",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchUsersMonth', data.data);
        dispatch({
          type: REPLACEORDER_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchPendingNegotiationDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"pendingnegotiations",
      //   tab:"today"
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchPendingNegotiationDay', data.data);
        dispatch({
          type: PENDINGNEGOTIATION_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchPendingNegotiationWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "pendingnegotiations",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('Userstoday', data.data);
        dispatch({
          type: PENDINGNEGOTIATION_WEEK_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchPendingNegotiationMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "pendingnegotiations",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchPendingNegotiationMonth', data.data);
        dispatch({
          type: PENDINGNEGOTIATION_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchAcceptedNegotiationDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchUsersMonth', data.data);
        dispatch({
          type: ACCEPTEDNEGOTIATION_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchAcceptedNegotiationWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "acceptednegotiations",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('Userstoday', data.data);
        dispatch({
          type: ACCEPTEDNEGOTIATION_WEEK_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchAcceptedNegotiationMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "acceptednegotiations",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchUsersMonth', data.data);
        dispatch({
          type: ACCEPTEDNEGOTIATION_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchRejectedNegotiationDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"rejectednegotiations",
      //   tab:"today"
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchUsersMonth', data.data);
        dispatch({
          type: REJECTEDNEGOTIATION_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchRejectedNegotiationWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "rejectednegotiations",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('Userstoday', data.data);
        dispatch({
          type: REJECTEDNEGOTIATION_WEEK_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchRejectedNegotiationMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "rejectednegotiations",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchRejectedNegotiationMonth', data.data);
        dispatch({
          type: REJECTEDNEGOTIATION_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchActiveauctionDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"activebids",
      //   tab:"today"
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('ACTIVEAUCTION_DAY_STATS', data);
        dispatch({
          type: ACTIVEAUCTION_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchActiveauctionWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "activebids",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('ACTIVEAUCTION_WEEK_STATS', data);
        dispatch({
          type: ACTIVEAUCTION_WEEK_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchActiveauctionMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "activebids",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('ACTIVEAUCTION_MONTH_STATS', data);
        dispatch({
          type: ACTIVEAUCTION_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchCompletedauctionDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"completedbids",
      //   tab:"today"
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchUsersMonth', data.data);
        dispatch({
          type: COMPLETEDAUCTION_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchCompletedauctionWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "completedbids",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('Userstoday', data.data);
        dispatch({
          type: COMPLETEDAUCTION_WEEK_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchCompletedauctionMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "completedbids",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchUsersMonth', data.data);
        dispatch({
          type: COMPLETEDAUCTION_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchPaymentsDay(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      // {
      //   type:"payments",
      //   tab:"today"
      // },
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchPaymentsDay', data.data);
        dispatch({
          type: PAYMENTS_DAY_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchPaymentsWeek(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "payments",
        tab: "thisweek",
      },
      session,
      dispatch,
      (data) => {
        // console.log('Userstoday', data.data);
        dispatch({
          type: PAYMENTS_WEEK_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchPaymentsMonth(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  return (dispatch) => {
    postRequest(
      url,
      {
        type: "payments",
        tab: "thismonth",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchPaymentsMonth', data);
        dispatch({
          type: PAYMENTS_MONTH_STATS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function statsUpdateDateProps(values) {
  return (dispatch) => {
    dispatch({ type: STATISTICS_UPDATE_DATE_PROPS, payload: values });
  };
}

export function fetchChartValues(session, range, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/graphical`;
  let values = {};
  values.range = range;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (data) => {
        // console.log('fetchChartValues', data.data);
        dispatch({
          type: CHART_VALUES,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

/* Exact same as avobe function with diffrent response */
export function downloadReports(session, obj, callback) {
  let values = {};

  var url = `${INSTANCE_URL}/api/v2/statsscript/tabstats`;
  // Listing

  // values.tab = tab;
  // values.type = type;
  // values.generate= true;

  return (dispatch) => {
    postRequest(
      url,
      obj,
      session,
      dispatch,
      (response) => {
        // console.log('response',response)
        callback({ success: 1, message: response.csv });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function fetchMostActiveusers(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/mostactiveusers`;
  return (dispatch) => {
    postRequest(
      url,
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchPaymentsDay', data.data);
        dispatch({
          type: MOSTACTIVE_USERS,
          payload: { data: data.users },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchMostActiveproducts(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/mostactiveproducts`;
  return (dispatch) => {
    postRequest(
      url,
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchPaymentsDay', data.data);
        dispatch({
          type: MOSTACTIVE_PRODUCTS,
          payload: { data: data.products },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchMostActivevendors(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/statsscript/mostactivevendors`;
  return (dispatch) => {
    postRequest(
      url,
      obj,
      session,
      dispatch,
      (data) => {
        // console.log('fetchPaymentsDay', data.data);
        dispatch({
          type: MOSTACTIVE_VENDORS,
          payload: { data: data.vendors },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
