import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import {
  fetchProductListSale,
  searchSaleProducts,
} from "../../../actions/index";
import ForsaleListing from "./forsale-items";
import _ from "lodash";
import Pagination from "react-js-pagination";
import BaseLoader from "../../../../../../common/base-loader";

class Forsaleproducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      pageNumber: 1,
      search: "",
    };

    // All Binded Functions
  }
  componentDidMount(data) {
    this.props.fetchProductListSale(
      this.props.session,
      this.props.data.userid,
      this.state.pageNumber,
      (CallBackResponse) => {}
    );
  }

  paginationCallback = (pageNumber) => {
    // alert(pageNumber)
    this.props.fetchProductListSale(
      this.props.session,
      this.props.data.userid,
      pageNumber,
      (CallBackResponse) => {}
    );

    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchProductListSale(
        this.props.session,
        this.props.data.userid,
        this.state.pageNumber,
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchSaleProducts(
        this.props.session,
        this.props.data.userid,
        search
      );
    }
    this.setState({ search, pageNumber });
  };

  renderList() {
    let pageDataLength = this.props.forSaleProductsList.count;

    let { pageNumber } = this.state;
    var items = _.map(this.props.forSaleProductsList.data, (values) => (
      <ForsaleListing
        key={values.userid}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
        userid={this.props.data.userid}
      />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div className="relative mb-5 text-ternary focus-within:text-secondary ">
              <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className="block lg:w-3/12 sm:w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                placeholder={LocaleStrings.search}
                type="search"
                name="search"
                value={this.state.search}
                onChange={this.handleSearch}
              />
            </div>
            {_.isEmpty(this.props.forSaleProductsList.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.seller_management_productNodata}
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.recommended}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.product}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.uploadedby}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.type}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.price}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.uploadedon}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Action
                              </th>
                              {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {LocaleStrings.action}
                          </th>                        */}
                            </tr>
                          </thead>
                          <tbody>{this.renderList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <Pagination
                  activePage={this.state.pageNumber}
                  itemsCountPerPage={7}
                  totalItemsCount={this.props.forSaleProductsList.count}
                  pageRangeDisplayed={10}
                  onChange={this.paginationCallback}
                />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

// var mapStateToProps = (state) => ({
//     isLoggedIn: state.isLoggedIn,
//     user: state.user,
//     session: state.session,
//     sellerproductlist:state.sellerproductlist,
//   });

function mapStateToProps(state) {
  var { isLoggedIn, user, session, forSaleProductsList } = state;
  return {
    isLoggedIn,
    session,
    user,
    forSaleProductsList,
  };
}

export default connect(mapStateToProps, {
  fetchProductListSale,
  searchSaleProducts,
})(Forsaleproducts);
