import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BaseComponent } from "../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { reduxForm } from "redux-form";
import _ from "lodash";
import { currencyCode, currencyRate } from "../../../currency/action/index";
import {
  getIntToFloat,
  classNames,
} from "../../../../../common/common-functions";
import { NotificationManager } from "react-notifications";
import {
  fetchunpaidPayouts,
  updateOrderPayout,
  fetchPayouts,
} from "../../actions/index";
import { isThisSecond } from "date-fns";
import { confirmAlert } from "react-confirm-alert";
import LocaleStrings from "../../../../languages";

const limit = 10;

class PayoutModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      phone: "",
      btnLoader: false,
    };

    // All Binded Functions
    // this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onSubmitForm() {
    let { data } = this.props;

    let updateOrderPayoutArr = [];
    if (data && data.length > 0) {
      _.forEach(data, function (val) {
        var obj = {
          id: val.orderid,
          payout: 1,
        };

        updateOrderPayoutArr.push(obj);
      });
    }
    if (updateOrderPayoutArr.length > 0) {
      this.setState({ btnLoader: true });
      this.props.updateOrderPayout(
        this.props.session,
        updateOrderPayoutArr,
        (resource) => {
          // console.log("showresource :- ", resource);
          if (resource.error == 1) {
          } else {
            this.props.fetchunpaidPayouts(
              this.props.session,
              limit,
              this.props.currentPage, // page
              this.props.startdate, // Start date
              this.props.enddate, // End date
              0,
              (callBack) => {
                NotificationManager.success("Successfully approved payout");
                this.setState({ btnLoader: false });
                this.setState({ loading: false });
                this.props.onClose();
              }
            );
          }
        }
      );
    }
  }

  renderList = (e) => {
    let { data } = this.props;

    let item = _.map(data, (val, index) => {
      var { localCurrency } = this.props;
      let currency = currencyCode(localCurrency);
      let currencyrate = currencyRate(localCurrency);
      return (
        <tr className="bg-white border-b-2 border-gray-50 hover-all cursor-pointer">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div>{val.invoice_no}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {currency} {val.sold_price}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {currency} {val.order_shopez_commision}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {currency} {val.shipping_price}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {currency} {val.donation_amount}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {currency} {val.order_payout}
          </td>
        </tr>
      );
    });

    return item;
  };

  _handlemarkApprove(e, values) {
    let updateOrderPayoutArr = [];
    if (values.data && values.data.length > 0) {
      _.forEach(values.data, function (val) {
        var obj = {
          id: val.orderid,
          payout: 1,
          // value: value.value,
        };

        updateOrderPayoutArr.push(obj);
      });
    }

    confirmAlert({
      title: "Approve Payout",
      message: `Are you sure you want to approve payout for this vendor`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.updateOrderPayout(
              this.props.session,
              updateOrderPayoutArr,
              (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error == 1) {
                } else {
                  this.props.fetchunpaidPayouts(
                    this.props.session,
                    limit,
                    1, // page
                    this.props.startdate, // Start date
                    this.props.enddate, // End date
                    0,
                    (callBack) => {
                      this.props.fetchPayouts(
                        this.props.session,
                        limit,
                        1, // page
                        this.props.startdate, // Start date
                        this.props.enddate,
                        1,
                        (CallBackResponse) => {
                          this.setState({ loading: false });
                          NotificationManager.success("Updated Successfully");
                          this.props.onClose();
                        }
                      );
                    }
                  );
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { handleSubmit, data, val } = this.props;
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.myRef}
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 bigmodal">
                  <div className="grid grid-cols-3 mb-10 border-b-2 border-gray-50 pb-4">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center">
                      {_.startCase(val.storename)} Payout
                    </div>
                    <div className="flex flex-wrap content-center justify-end">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}
                        ref={this.myRef}>
                        <span className="sr-only"></span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className="space-y-8 divide-y divide-gray-200 mt-5"
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                    encType="multipart/form-data">
                    <div>
                      <div className="border-transparent">
                        {data.length > 0 ? (
                          <div className="flex flex-col pt-6 pb-2 px-6">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-custWhite">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Invoice No
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Sold Price
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Shopez Commission
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Shipping Price
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Donation amount
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Payouts
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>{this.renderList()}</tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.props.comesFrom == "unpaidpayout" ? (
                          <div className="pt-5">
                            <div className="flex justify-center">
                              <button
                                onClick={(e) => this._handlemarkApprove(e, val)}
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 ">
                                <svg
                                  className={classNames(
                                    this.state.btnLoader ? "" : "sr-only",
                                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                  )}
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="loading"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true">
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                                Mark Approve
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}
function validate(values) {
  var errors = {};

  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    data: ownProps.data,
    val: ownProps.val,
    localCurrency: state.currency,
    enddate: ownProps.enddate,
    startdate: ownProps.startdate,
  };
}

export default connect(mapStateToProps, {
  currencyCode,
  currencyRate,
  fetchunpaidPayouts,
  updateOrderPayout,
  fetchPayouts,
})(
  reduxForm({
    validate,
    form: "PayoutModalForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(PayoutModal)
);
