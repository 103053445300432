import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, FieldArray } from "redux-form";
import LocaleStrings from "../../../languages/index";
import _ from "lodash";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../common/base-component";
import { ProductTextLocalized } from "../../../../common/common-functions";

import { NotificationManager } from "react-notifications";
// Import Actions
import {
  isOpenSpecsManagModal,
  fetchProductsByCateSubCate,
  updateSpecification,
  fetchSpecification,
  getSelectedSpecsObj,
} from "../actions/index";
import "react-notifications/lib/notifications.css";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon, TrashIcon } from "@heroicons/react/outline";
import { classNames } from "../../../../common/common-functions";

const limit = 10;

class EditspecificationModal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      discounttype: "",
      specificationAreaType: "",
      selectedCateId: "",
      selectedSubCateId: "",
      selectedProductId: "",
      btnLoader: false,
      mandatoryspecs: "",
      specsProductCategory: "",
      pageNumber: 1,
      btnLoader: false,
      enar: "en",
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {}

  /**** Fake Close ****/
  closeModal1() {}

  /**** Close Modal  ****/
  closeModal() {
    // Clear Single Discount Object (Pointing to)
    this.props.getSelectedSpecsObj({}, this.props.session);
    this.props.isOpenSpecsManagModal(false);
  }

  /**** INPUT LANGUAGE CHANGE en - ar ****/
  handleInputLanguageChange(e) {
    if (parseInt(e.target.value) === 1) {
      this.setState({ enar: "ar" });
    } else {
      this.setState({ enar: "en" });
    }
  }

  onFormSubmit(values) {
    this.setState({ btnLoader: true });
    let value_enArr = [];
    let value_arArr = [];
    let value_errorArr = [];

    if (
      _.trim(values.name_en) == "" ||
      _.trim(values.name_ar) == "" ||
      _.trim(values.value_en) == "" ||
      _.trim(values.value_ar) == ""
    ) {
      NotificationManager.error("Please Fill All Mandatory Fields");
      this.setState({ btnLoader: false });
      return false;
    }

    value_enArr = _.split(values.value_en, ",");
    value_arArr = _.split(values.value_ar, ",");

    if (value_enArr.length != value_arArr.length) {
      this.setState({ btnLoader: false });
      NotificationManager.error(
        "Please add same number of comma separted values for both languages"
      );
      return false;
    } else {
      let blankvalue = 0;
      _.forEach(value_enArr, (v, i) => {
        if (_.trim(v) == "") {
          blankvalue = 1;
        }
      });
      _.forEach(value_arArr, (v1, i1) => {
        if (_.trim(v1) == "") {
          blankvalue = 1;
        }
      });

      if (blankvalue != 0) {
        this.setState({ btnLoader: false });
        NotificationManager.error(
          "Please add same number of comma separted values for both languages"
        );
        return false;
      }
    }

    // if (values.value.length > 0) {
    //   values.value.map((val, key) => {
    //     let obj = val.value_en != "" ? val.value_en : null;
    //     let obj1 = val.value_ar != "" ? val.value_ar : null;
    //     if (obj == null || obj1 == null) {
    //       value_errorArr.push(1);
    //     }
    //     value_enArr.push(obj);
    //     value_arArr.push(obj1);
    //   });
    // } else {
    //   value_errorArr.push(1);
    // }
    // var value_en_String = value_enArr.join(",");
    // var value_ar_String = value_arArr.join(",");

    // if (value_errorArr.length > 0) {
    //   NotificationManager.error("Please add value for both languages");
    //   this.setState({ btnLoader: false });
    //   return false;
    // }
    // if (values.isglobal == 1) {
    //   var specificationadded = {
    //     id: values.id,
    //     isglobal: values.isglobal,
    //     name_en: values.name_en,
    //     name_ar: values.name_ar,
    //     ismandatory: values.ismandatory,
    //     value_en: value_en_String,
    //     value_ar: value_ar_String,
    //     isvariant: values.isvariant == true ? 1 : 0,
    //   };
    //   // console.log("specificationadded", specificationadded);
    //   this.props.updateSpecification(
    //     this.props.session,
    //     specificationadded,
    //     (response) => {
    //       if (response.success === 0) {
    //         this.setState({ btnLoader: false });
    //         NotificationManager.error(LocaleStrings.updatederror);
    //       } else {
    //         this.setState({ btnLoader: false });
    //         // toast.success(LocaleStrings.addedsucessfully);
    //         NotificationManager.success(LocaleStrings.updatedsuccessfully);
    //         // this.props.isOpenSpecsManagModal(false);
    //         this.props.onClose();
    //         this.props.fetchSpecification(
    //           this.props.session,
    //           limit,
    //           this.state.pageNumber,
    //           "listing",
    //           "",
    //           (CallBackResponse) => {}
    //         );
    //       }
    //     }
    //   );
    // } else {
    let specscategory = [];
    if (
      !values.idcategory ||
      values.idcategory.length == 0 ||
      values.idcategory == ""
    ) {
      NotificationManager.error("Please add category");
      this.setState({ btnLoader: false });
      return false;
    }
    values.idcategory.map((val, key) => {
      let obj = val.value;
      specscategory.push(obj);
    });
    var arrspecscategoryString = specscategory.join(",");
    let specssubcategory = [];
    values.idsubcategory.map((val, key) => {
      let obj = val.value;
      specssubcategory.push(obj);
    });
    var arrspecsSubcategoryString = specssubcategory.join(",");

    values.value_en =
      values.value_en + "," + this.props.initialValues.value_en1;
    values.value_ar =
      values.value_ar + "," + this.props.initialValues.value_ar1;

    var specificationadded = {
      id: values.id,
      // isglobal: values.isglobal,
      categoryids: arrspecscategoryString,
      subcategoryids: arrspecsSubcategoryString,
      name_en: values.name_en,
      name_ar: values.name_ar,
      ismandatory: values.ismandatory,
      // value_en: value_en_String,
      // value_ar: value_ar_String,
      value_en: values.value_en ? values.value_en : null,
      value_ar: values.value_ar ? values.value_ar : null,
      isvariant: values.isvariant == true ? 1 : 0,
    };
    this.props.updateSpecification(
      this.props.session,
      specificationadded,
      (response) => {
        if (response.success === 0) {
          this.setState({ btnLoader: false });
          NotificationManager.error(LocaleStrings.updatederror);
        } else {
          this.setState({ btnLoader: false });
          NotificationManager.success(LocaleStrings.updatedsuccessfully);
          // this.props.isOpenSpecsManagModal(false);
          this.props.onClose();
          this.props.fetchSpecification(
            this.props.session,
            limit,
            this.state.pageNumber,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        }
      }
    );
    // }
  }

  // Category / Subcategory  / Product Select
  _handleCategoryScreen(e) {
    let selectedFiledValue = _.map(e, (el) => parseInt(el.value));
    this.setState({ selectedCateId: selectedFiledValue });
    // this.props.dispatch(change("editspecificationModals", "idsubcategory", ""));
  }

  _handleSpecification(e) {
    let areaType = "";
    if (e.target.value != "" && e.target.value != null) {
      areaType = _.find(this.props.Golbalspecification, {
        id: parseInt(e.target.value),
      }).name;
    }
    this.setState({ specificationAreaType: areaType });
  }
  _handlemandatory(e) {
    let mandateType = "";
    if (e.target.value != "" && e.target.value != null) {
      mandateType = _.find(this.props.Golbalspecification, {
        id: parseInt(e.target.value),
      }).name;
    }
    this.setState({ mandatoryspecs: mandateType });
  }

  renderspecificationValue = ({ fields, meta: { error, submitFailed } }) => (
    <ul>
      <li style={{ padding: 0 }}>
        <a
          className="underline end-item text-primaryLight cursor-pointer"
          onClick={() => fields.push({})}>
          +Add Values
        </a>
        {submitFailed && error && <span>{error}</span>}
      </li>

      {fields.map((specsvalue, index) => {
        var currentInxedData = fields.get(index);
        let showDelBtn = fields.length === 1 ? " hidden" : "";
        if (fields.get(index).id) {
          showDelBtn = " hidden ";
        }
        //  imagePreviewUrl = currentInxedData?.image;
        return (
          <li key={index} style={{ padding: 0 }}>
            <div className={showDelBtn + "mt-2"} style={{ textAlign: "end" }}>
              <button
                type="button"
                title="Remove Member"
                className="text-end"
                onClick={() => fields.remove(index)}>
                <TrashIcon
                  className="h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
              </button>
            </div>
            <div className="mt-2">
              <Field
                name={`${specsvalue}.value_` + this.state.enar}
                type="text"
                component={this.renderAllfields}
                label={"Value_" + this.state.enar}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );

  render() {
    let { initialValues, language, handleSubmit } = this.props;
    let {
      specificationAreaType,
      selectedCateId,
      discounttype,
      mandatoryspecs,
      specsProductCategory,
      enar,
    } = this.state;
    let modalHeadText = "";
    let modalFooterText = "";
    let intialCheck = "";
    // if (initialValues.isglobal == 1) {
    //   intialCheck = "Yes";
    // } else if (initialValues.isglobal == 0) {
    intialCheck = "No";
    // }
    // specificationAreaType =
    // specificationAreaType === "" ? initialValues.isglobal : specificationAreaType;
    // specificationAreaType =
    // specificationAreaType === ""
    //   ? _.find(this.props.NewAddArea, {
    //       value:initialValues.isglobal,
    //     }).value
    //   : specificationAreaType;

    specificationAreaType =
      specificationAreaType === "" ? intialCheck : specificationAreaType;
    // specsProductCategory  = ((specsProductCategory === '') ? initialCateids : specsProductCategory);
    // selectedCateId =
    //   selectedCateId === "" ? initialValues.categoryids : selectedCateId;
    modalHeadText = LocaleStrings.edit_discount;
    modalFooterText = LocaleStrings.update_discount;
    mandatoryspecs =
      mandatoryspecs === "" ? initialValues.ismandatory : mandatoryspecs;

    modalHeadText = LocaleStrings.specs_edit_Header;
    modalFooterText = LocaleStrings.add_new_discount;

    if (selectedCateId === "" || selectedCateId === undefined) {
      if (initialValues?.categoryids) {
        let valuecat = _.split(initialValues.categoryids, ",");
        let arr = [];
        _.forEach(valuecat, (v, i) => {
          arr.push(parseInt(v));
        });
        selectedCateId = arr;
      }
    }
    // console.log("selectedCateId", selectedCateId);
    var specificationAreaOptions = []; // Specs Area
    var specificationCateOptions = []; // Category
    var specificationSubCateOptions = []; // Sub-Category
    var discountProductOptions = []; // Product
    var mandatoryOptions = []; // Product

    //Global
    _.forEach(this.props.Golbalspecification, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name),
        value: value.value,
      };

      specificationAreaOptions.push(obj);
    });

    // Mandatory
    _.forEach(this.props.Isspecificationmandatory, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name),
        value: value.value,
      };

      mandatoryOptions.push(obj);
    });

    // if (specificationAreaType == "No") {
    _.forEach(this.props.specsproductCategory, function (value) {
      var obj = {
        label: ProductTextLocalized(
          value.categoryname_en,
          value.categoryname_ar,
          language
        ),
        value: value.id.toString(),
      };

      specificationCateOptions.push(obj);
    });
    // }

    // if (specificationAreaType == "No") {
    //Discount Sub-Category Object Creation

    // let filterSubcateArr = _.filter(this.props.specsproductSubCategory, {
    //   categoryid: parseInt(selectedCateId),
    // });
    let filterSubcateArr = _.filter(
      this.props.specsproductCategory,
      function (object) {
        return _.includes(selectedCateId, object.id);
      }
    );
    // console.log("specsproductCategory", this.props.specsproductCategory);
    // console.log("filterSubcateArr", filterSubcateArr);
    if (filterSubcateArr.length > 0) {
      _.forEach(filterSubcateArr, function (value) {
        if (
          value.productsubcategory_by_categoryid &&
          value.productsubcategory_by_categoryid.length > 0
        ) {
          _.forEach(value.productsubcategory_by_categoryid, function (v) {
            var obj = {
              label: ProductTextLocalized(
                v.subcategoryname_en,
                v.subcategoryname_ar,
                language
              ),
              value: v.id,
            };

            specificationSubCateOptions.push(obj);
          });
        }
      });
    }
    // console.log("specificationCateOptions", specificationCateOptions);
    // _.forEach(filterSubcateArr, function (value) {
    //   var obj = {
    //     label: ProductTextLocalized(
    //       value.subcategoryname_en,
    //       value.subcategoryname_ar,
    //       language
    //     ),
    //     value: value.id,
    //   };

    //   specificationSubCateOptions.push(obj);
    // });
    // }

    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isOpen}
            // onClose={this.closeModal1}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0 }}>
                  {/* MOdal Header */}
                  <div className=" border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center mt-4">
                      <div className="text-lg leading-6 font-medium text-secondary">
                        {modalHeadText}
                      </div>
                    </div>
                    <div className="sm:block absolute top-0 right-0 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.props.onClose}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="font-semibold px-4">
                    *Specification values cannot be edited as there may be
                    products associated with these values. You can only add new
                    values. However you can deactivate the entire specification
                    and it will unlist all products associated with it.
                  </div>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    {/* MOdal Header Ends*/}
                    <div className="p-6 space-y-8">
                      <div style={{ display: "flex" }}>
                        <label className="mr-5 text-box-lable">
                          {LocaleStrings.language}
                        </label>

                        <Field
                          name="language"
                          label={LocaleStrings.en}
                          component={this.renderFieldRadioNew}
                          type="radio"
                          value="0"
                          className="mx-2"
                          onChange={this.handleInputLanguageChange.bind(this)}
                        />

                        <Field
                          name="language"
                          label={LocaleStrings.ar}
                          component={this.renderFieldRadioNew}
                          type="radio"
                          value="1"
                          className="mx-2"
                          onChange={this.handleInputLanguageChange.bind(this)}
                        />
                      </div>
                      <div>
                        {/* <Field
                          name="isglobal"
                          label={LocaleStrings.isspecificationglobal}
                          placeholder={LocaleStrings.select}
                          component={this.renderSelect}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          opts={specificationAreaOptions}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          onChange={this._handleSpecification.bind(this)}
                        // value={this.props.initialValues.type}
                        /> */}
                      </div>
                      <div>
                        <Field
                          name="idcategory"
                          label={LocaleStrings.category}
                          placeholder={LocaleStrings.select}
                          options={specificationCateOptions}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          onChange={this._handleCategoryScreen.bind(this)}
                          isMulti={true}
                        />
                      </div>
                      <div>
                        {/* <Field
                          name="idsubcategory"
                          label={LocaleStrings.subcategory}
                          placeholder={LocaleStrings.select}
                          component={this.renderSelect}
                          mandatory="false"
                          labelposition={LABEL_POSITION_TOP}
                          opts={specificationSubCateOptions}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          onChange={this._handleIdcategoryScreen.bind(this)}
                        /> */}
                        <Field
                          name="idsubcategory"
                          label={LocaleStrings.subcategory}
                          placeholder={LocaleStrings.select}
                          options={specificationSubCateOptions}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          // onChange={this._handleCategoryScreen.bind(this)}
                          isMulti={true}
                        />
                      </div>
                      <div>
                        <Field
                          name={"name_" + enar}
                          label={LocaleStrings.specificationame}
                          placeholder={LocaleStrings.specificationame}
                          type="text"
                          component={this.renderFieldNewText}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-medium text-secondary"
                        />
                      </div>
                      <div className="">
                        {/* <FieldArray
                          name={"value"}
                          component={this.renderspecificationValue}
                          values={this.state.enar}
                        /> */}
                        <Field
                          name={"value_" + enar}
                          label="Value"
                          placeholder="Add values"
                          mandatory="true"
                          component={this.renderFieldTextareaNew}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-box-lable"
                          className="py-3 px-4 block w-full shadow-sm text-secondary focus:ring-primary focus:border-teal-500 border border-warm-gray-300 rounded-md"
                        />
                        <div className="mt-2">
                          {this.state.enar == "en" ? (
                            <div>{initialValues.value_en1}</div>
                          ) : (
                            <div>{initialValues.value_ar1}</div>
                          )}
                        </div>
                      </div>
                      <Field
                        name="ismandatory"
                        label={LocaleStrings.isspecsmandatory}
                        placeholder={LocaleStrings.select}
                        component={this.renderSelect}
                        mandatory="true"
                        labelposition={LABEL_POSITION_TOP}
                        opts={specificationAreaOptions}
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                        onChange={this._handlemandatory.bind(this)}
                        // value={this.props.initialValues.type}
                      />

                      <div className="">
                        <Field
                          name="isvariant"
                          label="IS VARIANT"
                          component={this.renderFieldCheckbox1}
                          type="checkbox"
                          className="cust-check-box"
                          classNameLabel="text-box-lable"
                        />
                      </div>

                      <div className="p-6 center-item">
                        {/* Footer  */}
                        <button
                          type="submit"
                          className="lg:w-48 md:w-44 sm:w-44 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "sr-only",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          {LocaleStrings.save}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = ["ismandatory", "name_en", "", ""];

  requiredFields.forEach((field) => {
    // if (typeof values[field] != 'undefined'){
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
    // }
  });

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  // if (state.global_commissionlist)
  // console.log('state.initialValues',state.specssingleDiscountObject)
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isDiscManageOpen: state.isSpecsManageModalopen,
    specsproductCategory: state.specsproductCategory
      ? state.specsproductCategory.data != "undefined"
        ? state.specsproductCategory.data
        : []
      : [],
    specsproductSubCategory: state.specsproductSubCategory
      ? state.specsproductSubCategory.data != "undefined"
        ? state.specsproductSubCategory.data
        : []
      : [],
    initialValues: state.specssingleDiscountObject,
    Golbalspecification: state.Golbalspecification,
    Isspecificationmandatory: state.Isspecificationmandatory,
  };
}

export default connect(mapStateToProps, {
  isOpenSpecsManagModal,
  fetchProductsByCateSubCate,
  fetchSpecification,
  updateSpecification,
  getSelectedSpecsObj,
})(
  reduxForm({
    validate,
    form: "editspecificationModals",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EditspecificationModal)
);
