import React, { Component } from "react";
import { connect } from "react-redux";
import { Fragment } from "react";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import LocaleStrings from "../../../languages/index.js";
import { Dialog, Menu, Transition } from "@headlessui/react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import _ from "lodash";
import { logout, resetStore } from "../../../../actions/index.js";
import {
  onSidebarMenuSelected,
  fetchAllUsers,
  fetchNotificationlist,
  fetchAdminRoles,
} from "../actions/index";
import { localeLanguage } from "../../../../actions/index";
import { withRouter, Route, Switch } from "react-router-dom";
import { BrowserRouter as Router, Link } from "react-router-dom";
import GlobalNotification from "./notification";
import toast, { Toaster } from "react-hot-toast";

// Import pages
import Dashboardside from "../../dasboardside/components/index.js";
import CustomerManagement from "../../customermanagement/components/index.js";
import MainSellerManagement from "../../sellermanagement/components/main";
import SellerManagement from "../../sellermanagement/components/index.js";
import UnverifiedSellers from "../../sellermanagement/components/unverified.js";
import VerificationrequestedSellers from "../../sellermanagement/components/verificationrequested";
import ProductManagement from "../../productmanagements/components/index.js";
import OrderManagement from "../../ordermanagement/components/index.js";
import DisputeManagement from "../../disputemanagement/components/index.js";
import CategoryManagement from "../../categorymanagement/components/index.js";
import SpecsManagement from "../../specsmanagement/components/index.js";
import DiscountMangement from "../../discountmanagement/components/index.js";
import HomePageDesign from "../../homepagedesign/components/index.js";
import FeedsManagement from "../../feedsmanagement/components/index.js";
import GlobalSettings from "../../globalsettings/components/index.js";
import TicketReports from "../../ticketsreport/components/index.js";
import Language from "../../language/components/index.js";
import Currency from "../../currency/components/index";
import MasterVariations from "../../mastervariation/components/index";
import TransactionManagement from "../../transactionmanagement/components/index";
import MasterTransaction from "../../transactionmanagement/components/transaction/mastertransaction";
import CharityPayouts from "../../transactionmanagement/components/charitypayouts/index";
import { NotificationContainer } from "react-notifications";
import PayoutTabs from "../../transactionmanagement/components/payout/payouttabs";

// OrderManagement Child Route
import Allneworders from "../../ordermanagement/components/Allnewoders/index";
import Alldeliveredorders from "../../ordermanagement/components/Alldeliveredorders/index";
// Tickets And Reports
import Appsuggestions from "../../ticketsreport/components/AppSuggestions/index";
import Reports from "../../ticketsreport/components/Reports/index";
import Tickets from "../../ticketsreport/components/Tickets/index";

// Product Management
import AllPublishedItems from "../../productmanagements/components/Allpublisheditems/index";
import ForAuctionItems from "../../productmanagements/components/ForAuctionItems/index";
import ForSaleItems from "../../productmanagements/components/ForSaleItems/index";
import UnapprovedItems from "../../productmanagements/components/UnapprovedItems/index";
// Dispute Mangement
import Allreplaceorders from "../../disputemanagement/components/Allreplaceorders/index";
import Allcancelledorders from "../../disputemanagement/components/Allcancellededorders/index";
import Allreturnedorders from "../../disputemanagement/components/Allreturnedorders/index";
import OrdersnotProcessed from "../../disputemanagement/components/ordernotprocessed/index";
import Bidsitemnotbought from "../../disputemanagement/components/biditemsnotbought/index";
// Discount Management
import ActiveDiscount from "../../discountmanagement/components/discounts/active/activediscount";
import InsideCouponTabs from "../../discountmanagement/components/coupons/coupontabs";
// Global Settings child Route
import AdminManagement from "../../globalsettings/components/adminmanagement";
import GlobalCommision from "../../globalsettings/components/commission";
import ContentManagement from "../../globalsettings/components/contentmanagement";
import DeliveryAgent from "../../globalsettings/components/deliverymanagement";
import FaqTabs from "../../globalsettings/components/faqs/faqtabs";
import GlobalLegalDocuments from "../../globalsettings/components/legalDocs";
// import GlobalListingFee from "../../globalsettings/components/otherfee";
import OtherFeeTabs from "../../globalsettings/components/otherfee/otherfeetabs";
import SuperAdminProfileSettings from "../../globalsettings/components/othersettings";
import PaymentKeys from "../../globalsettings/components/paymentkeys";
import GlobalRefund from "../../globalsettings/components/refundmanagement";
import GlobalCharges from "../../globalsettings/components/taxmanagement/charges/index";
import AddsManagement from "../../addsmanagement/components/index";
import { BellIcon, MenuAlt2Icon, XIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      selectMenuNum: 1,
      textDiretion: "",
      isOpen: false,
    };

    // All Binded Functions
    this.setSidebarOpen = this.setSidebarOpen.bind(this);
    this.onLogoutClicked = this.onLogoutClicked.bind(this);
  }
  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    let { session } = this.props;

    if (this.props.user && this.props.user.userid) {
      this.props.fetchAdminRoles(
        this.props.session,
        this.props.user.userid,
        (response) => {
          var parts = this.props.history.location.pathname
            .substring(11)
            .split("/");
          var answer = parts[0];
          var singleNavigationRow = _.find(this.props.navigation.data, {
            href: answer,
          });

          if (singleNavigationRow == undefined) {
            // this.props.history.push("/login");
          } else {
            // this.props.history.push("/dashboard/" + singleNavigationRow.href);
            this.props.onSidebarMenuSelected(singleNavigationRow.position);
          }
        }
      );
    }

    if (this.props.language === "en") {
      this.props.localeLanguage("en");
    } else {
      this.props.localeLanguage("ar");
    }
    if (this.props.isLoggedIn === true) {
      // this.props.history.push('/dashboard');
    } else {
      this.props.history.push("/login");
    }
    this.props.fetchAllUsers(this.props.session, (CallBackResponse) => {});

    this.props.fetchNotificationlist(
      this.props.session,
      (CallBackResponse) => {}
    );
  }

  componentDidUpdate(prevState) {
    if (prevState.language !== this.props.language) {
      if (this.props.language === "en") {
        this.setState({ textDiretion: "" });
      } else {
        this.setState({ textDiretion: "rtl" });
      }
    }
  }
  // Menu Select (Route change)
  onMenuSelect = (item) => {
    this.props.onSidebarMenuSelected(item.position);
    this.props.history.push("/dashboard/" + item.href);
  };

  // Logout Button Click
  onLogoutClicked() {
    confirmAlert({
      title: LocaleStrings.button_logout,
      message: `${LocaleStrings.logout_want_to_logout}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.logout(this.props.session);
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  setSidebarOpen() {
    this.setState({ sidebarOpen: false });
  }

  /***** Sidebar header logo component *****/
  sidebarHeaderLogo = () => {
    let { language } = this.props;
    return (
      <div className="cc-sidebar-header">
        <img
          loading="lazy"
          src={
            language == "en"
              ? "../images/logo/logo_en.png"
              : "../images/logo/logo_ar.png"
          }
          className="cc-sidebar-logo"
        />
      </div>
    );
  };

  render() {
    var { textDiretion } = this.state;
    // console.log('user',this.props.user)
    return (
      <>
        {this.props.navigation?.data &&
        this.props.navigation?.data?.length > 0 ? (
          <>
            <div
              className={
                textDiretion + " h-screen flex overflow-hidden bg-gray-100"
              }>
              <Transition.Root show={this.state.sidebarOpen} as={Fragment}>
                <Dialog
                  as="div"
                  static
                  className="fixed inset-0 flex z-40 md:hidden"
                  open={this.state.sidebarOpen}
                  onClose={this.setSidebarOpen}>
                  <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                  </Transition.Child>
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full">
                    <div className="relative flex-1 flex flex-col max-w-xs w-full  pb-4 bg-custWhite">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                          <button
                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            onClick={() =>
                              this.setState({ sidebarOpen: false })
                            }>
                            <span className="sr-only">Close sidebar</span>
                            <XIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </Transition.Child>
                      {this.sidebarHeaderLogo()}
                      <div className="flex-shrink-0 flex items-center px-4">
                        <img
                          className="h-8 w-auto"
                          src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                          alt="Workflow"
                        />
                      </div>
                      <div className="mt-5 flex-1 h-0 overflow-y-auto">
                        <nav className="px-2 space-y-1 cursor-pointer">
                          {this.props.navigation.data.map((item) => (
                            <span
                              key={item.name}
                              onClick={(e) => this.onMenuSelect(item)}
                              className={classNames(
                                this.props.sidebarSelectedMenu === item.position
                                  ? "bg-gray-100 text-secondary"
                                  : "text-ternary hover:bg-gray-50 hover:text-secondary",
                                "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                              )}>
                              <img
                                className="h-4 px-1"
                                src={
                                  // "../images/product_management_images/" +
                                  "../images/sidebar/" + item.icon + ".svg"
                                }
                              />
                              {item.name}
                            </span>
                          ))}
                        </nav>
                      </div>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 w-14" aria-hidden="true">
                    {/* Dummy element to force sidebar to shrink to fit close icon */}
                  </div>
                </Dialog>
              </Transition.Root>

              {/* Static sidebar for desktop */}
              <div className="hidden md:flex md:flex-shrink-0">
                <div className="flex flex-col w-64">
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex flex-col flex-grow border-r border-gray-200  pb-4 bg-custWhite overflow-y-auto">
                    {this.sidebarHeaderLogo()}
                    {/* <div className="flex items-center flex-shrink-0 px-4"> */}
                    {/* <img
               className="h-8 w-auto"
               src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
               alt="Workflow"
             /> */}
                    {/* <p className="text-3xl">{LocaleStrings.superadmin}</p> */}
                    {/* </div> */}
                    <div className="mt-5 flex-grow flex flex-col overflow-y-auto scrollbar-hide">
                      <nav className="flex-1 px-2 bg-custWhite space-y-1 cursor-pointer">
                        {this.props.navigation.data.map((item) => (
                          <span
                            key={item.name}
                            onClick={(e) => this.onMenuSelect(item)}
                            className={classNames(
                              this.props.sidebarSelectedMenu === item.position
                                ? "bg-gray-100 text-secondary"
                                : "text-ternary hover:bg-gray-50 hover:text-secondary",
                              "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            )}>
                            <img
                              className="h-6 w-10 px-1"
                              src={
                                // "../images/product_management_images/" +
                                "../images/sidebar/" + item.icon + ".svg"
                              }
                            />
                            <span className="pl-5">{item.name}</span>
                          </span>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <div className="relative z-10 flex-shrink-0 flex h-16">
                  <button
                    className="px-4 border-r border-gray-200 text-ternary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
                    onClick={() => this.setState({ sidebarOpen: true })}>
                    <span className="sr-only">Open sidebar</span>
                    <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <div
                    className="flex-1 px-4 flex justify-items-end "
                    style={{ justifyContent: "flex-end" }}>
                    {/* <div className="flex-1 flex">
             <form className="w-full flex md:ml-0" action="#" method="GET">
               <label htmlFor="search-field" className="sr-only">
                 Search
               </label>
               <div className="relative w-full text-ternary focus-within:text-secondary">
                 <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                   <SearchIcon className="h-5 w-5" aria-hidden="true" />
                 </div>
                 <input
                   id="search-field"
                   className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                   placeholder="Search"
                   type="search"
                   name="search"
                 />
               </div>
             </form>
           </div> */}
                    <div className="ml-4 flex items-center md:ml-6 justify-between">
                      <button
                        onClick={(e) => this.openModal(e)}
                        className="bg-custWhite p-1 rounded-full text-ternary hover:text-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                      {this.state.isOpen ? (
                        <GlobalNotification
                          isOpen={this.state.isOpen}
                          onClose={this.closeModal}
                        />
                      ) : (
                        ""
                      )}
                      <div className="pl-2">
                        {this.props.user ? this.props.user.username : ""}
                      </div>
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button className="max-w-xs bg-custWhite flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                <span className="sr-only">Open user menu</span>
                                <img
                                  className="h-8 w-8 rounded-full"
                                  // src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                  src="../images/favicon.png"
                                  alt=""
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95">
                              <Menu.Items
                                static
                                className={classNames(
                                  this.props.language == "en"
                                    ? "right-0"
                                    : "left-0",
                                  "absolute origin-top-right mt-2 w-48 rounded-md shadow-lg py-1 bg-custWhite ring-1 ring-black ring-opacity-5 focus:outline-none"
                                )}>
                                <Menu.Item key="sign_out">
                                  <a
                                    onClick={this.onLogoutClicked}
                                    className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                                    {LocaleStrings.signout}
                                  </a>
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </div>
                  </div>
                </div>

                <main
                  className="flex-1 relative overflow-y-auto focus:outline-none scrollbar-hide"
                  style={{ overflowX: "hidden" }}>
                  <Switch>
                    <Route
                      component={Dashboardside}
                      path="/dashboard/dashboardside"
                      history={this.props.history}
                    />
                    <Route
                      component={CustomerManagement}
                      path="/dashboard/customermanagement"
                    />
                    <Route
                      component={VerificationrequestedSellers}
                      history={this.props.history}
                      path="/dashboard/sellermanagement/verficationrequested"
                    />
                    <Route
                      component={UnverifiedSellers}
                      history={this.props.history}
                      path="/dashboard/sellermanagement/unverified"
                    />
                    <Route
                      component={SellerManagement}
                      history={this.props.history}
                      path="/dashboard/sellermanagement/verified"
                    />
                    <Route
                      component={MainSellerManagement}
                      history={this.props.history}
                      path="/dashboard/sellermanagement"
                    />
                    <Route
                      component={AllPublishedItems}
                      history={this.props.history}
                      path="/dashboard/productmanagement/allitems"
                    />
                    <Route
                      component={ForAuctionItems}
                      history={this.props.history}
                      path="/dashboard/productmanagement/forauction"
                    />
                    <Route
                      component={ForSaleItems}
                      history={this.props.history}
                      path="/dashboard/productmanagement/forsale"
                    />
                    <Route
                      component={UnapprovedItems}
                      history={this.props.history}
                      path="/dashboard/productmanagement/unapproved"
                    />
                    <Route
                      component={ProductManagement}
                      history={this.props.history}
                      path="/dashboard/productmanagement"
                    />
                    <Route
                      component={Allneworders}
                      history={this.props.history}
                      path="/dashboard/ordermanagement/new"
                    />
                    <Route
                      component={Alldeliveredorders}
                      history={this.props.history}
                      path="/dashboard/ordermanagement/delivered"
                    />
                    <Route
                      component={OrderManagement}
                      history={this.props.history}
                      path="/dashboard/ordermanagement"
                    />
                    <Route
                      component={Allreplaceorders}
                      history={this.props.history}
                      path="/dashboard/disputemanagement/replace"
                    />
                    <Route
                      component={Allcancelledorders}
                      history={this.props.history}
                      path="/dashboard/disputemanagement/cancelled"
                    />
                    <Route
                      component={Allreturnedorders}
                      history={this.props.history}
                      path="/dashboard/disputemanagement/return"
                    />
                    <Route
                      component={OrdersnotProcessed}
                      history={this.props.history}
                      path="/dashboard/disputemanagement/ordersnotprocessed"
                    />
                    <Route
                      component={Bidsitemnotbought}
                      history={this.props.history}
                      path="/dashboard/disputemanagement/auctionitemsnotbought"
                    />
                    <Route
                      component={DisputeManagement}
                      history={this.props.history}
                      path="/dashboard/disputemanagement"
                    />
                    <Route
                      component={CategoryManagement}
                      path="/dashboard/categorymanagement"
                    />
                    <Route
                      component={SpecsManagement}
                      path="/dashboard/specsmanagement"
                    />
                    <Route
                      component={ActiveDiscount}
                      history={this.props.history}
                      path="/dashboard/discountmanagement/discount"
                    />
                    <Route
                      component={InsideCouponTabs}
                      history={this.props.history}
                      path="/dashboard/discountmanagement/coupon"
                    />
                    <Route
                      component={ActiveDiscount}
                      history={this.props.history}
                      path="/dashboard/discountmanagement"
                    />
                    <Route
                      component={HomePageDesign}
                      path="/dashboard/homepagebuilder"
                    />
                    <Route
                      component={FeedsManagement}
                      path="/dashboard/feedsmanagement"
                    />
                    {/* Global setting child */}
                    <Route
                      component={AdminManagement}
                      history={this.props.history}
                      path="/dashboard/globalsettings/adminmanagement"
                    />
                    <Route
                      component={GlobalCommision}
                      history={this.props.history}
                      path="/dashboard/globalsettings/commissionmanagement"
                    />
                    <Route
                      component={ContentManagement}
                      history={this.props.history}
                      path="/dashboard/globalsettings/contentmanagement"
                    />
                    <Route
                      component={DeliveryAgent}
                      history={this.props.history}
                      path="/dashboard/globalsettings/deliverymanagement"
                    />
                    <Route
                      component={FaqTabs}
                      history={this.props.history}
                      path="/dashboard/globalsettings/faqs"
                    />
                    <Route
                      component={GlobalLegalDocuments}
                      history={this.props.history}
                      path="/dashboard/globalsettings/legaldocs"
                    />
                    <Route
                      component={OtherFeeTabs}
                      history={this.props.history}
                      path="/dashboard/globalsettings/otherFee"
                    />
                    <Route
                      component={SuperAdminProfileSettings}
                      history={this.props.history}
                      path="/dashboard/globalsettings/othersettings"
                    />
                    <Route
                      component={PaymentKeys}
                      history={this.props.history}
                      path="/dashboard/globalsettings/paymentkeys"
                    />
                    <Route
                      component={GlobalRefund}
                      history={this.props.history}
                      path="/dashboard/globalsettings/refund"
                    />
                    <Route
                      component={GlobalCharges}
                      history={this.props.history}
                      path="/dashboard/globalsettings/charges"
                    />

                    <Route
                      component={GlobalSettings}
                      history={this.props.history}
                      path="/dashboard/globalsettings"
                    />
                    <Route
                      component={Appsuggestions}
                      history={this.props.history}
                      path="/dashboard/tickets_reports/appsuggestion"
                    />
                    <Route
                      component={Reports}
                      history={this.props.history}
                      path="/dashboard/tickets_reports/reports"
                    />
                    <Route
                      component={Tickets}
                      history={this.props.history}
                      path="/dashboard/tickets_reports/tickets"
                    />
                    <Route
                      component={TicketReports}
                      history={this.props.history}
                      path="/dashboard/tickets_reports"
                    />
                    <Route component={Language} path="/dashboard/language" />
                    <Route component={Currency} path="/dashboard/currency" />
                    <Route
                      component={MasterVariations}
                      path="/dashboard/mastervariation"
                    />
                    <Route
                      component={PayoutTabs}
                      history={this.props.history}
                      path="/dashboard/transaction/payout"
                    />
                    <Route
                      component={MasterTransaction}
                      history={this.props.history}
                      path="/dashboard/transaction/alltransaction"
                    />
                    <Route
                      component={CharityPayouts}
                      history={this.props.history}
                      path="/dashboard/transaction/charitypayout"
                    />
                    <Route
                      component={TransactionManagement}
                      history={this.props.history}
                      path="/dashboard/transaction"
                    />
                    <Route
                      component={AddsManagement}
                      history={this.props.history}
                      path="/dashboard/adsmanagement"
                    />
                  </Switch>
                </main>
              </div>
            </div>
            <NotificationContainer />
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log('state.sidebarSelectedMenu',state.sidebarSelectedMenu);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    navigation: state.sideBarMenus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    logout,
    onSidebarMenuSelected,
    localeLanguage,
    fetchAllUsers,
    fetchNotificationlist,
    fetchAdminRoles,
  })(SideBar)
);
