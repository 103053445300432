import React from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../../common/base-component";
import _ from "lodash";
import { currencyCode, currencyRate } from "../../../currency/action/index";
import { getIntToFloat } from "../../../../../common/common-functions";
import CharityPayoutModal from "./charitypayoutmodal";

class CharityPayoutListitem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  render() {
    var { values, localCurrency } = this.props;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    let data = [];

    if (values.hasOwnProperty("data") && values.data.length > 0) {
      data = values.data;
    }

    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 hover-all cursor-pointer">
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}>
            {_.startCase(values.name_en)}
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}>
            {currency} {values.totaldonation}
          </td>
        </tr>
        {this.state.isOpen ? (
          <CharityPayoutModal
            isOpen={this.state.isOpen}
            onClose={this.closeModal}
            data={data}
            val={values}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    localCurrency: state.currency,
    language: state.language,
  };
}

export default connect(mapStateToProps, {})(CharityPayoutListitem);
