import LocaleStrings from "../../../languages/index";
import {
  ALLPRODUCT_LIST,
  DIRECTSALERODUCT_LIST,
  AUCTIONPRODUCT_LIST,
  UNAPPROVEPRODUCT_LIST,
} from "../actions";

export var ProductManagementTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    {
      name: LocaleStrings.allpublisheditems,
      href: "allitems",
      current: true,
      tabindex: 1,
    },
    {
      name: LocaleStrings.forsale,
      href: "forsale",
      current: false,
      tabindex: 2,
    },
    {
      name: LocaleStrings.forauction,
      href: "forauction",
      current: false,
      tabindex: 3,
    },
    {
      name: LocaleStrings.unapproved,
      href: "unapproved",
      current: false,
      tabindex: 4,
    },
  ];

  return state;
};

export function allproductslist(state = {}, action) {
  switch (action.type) {
    case ALLPRODUCT_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function directSaleproductslist(state = {}, action) {
  switch (action.type) {
    case DIRECTSALERODUCT_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function auctionproductslist(state = {}, action) {
  switch (action.type) {
    case AUCTIONPRODUCT_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function unapprovedProducList(state = {}, action) {
  switch (action.type) {
    case UNAPPROVEPRODUCT_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
