import Recat, { Component } from "react";
import { connect } from "react-redux";
// import { XIcon } from "@heroicons/react/outline";
// import LocaleStrings from "../../../languages/index";
// import PaymentKeys from "./paymentkeys/index";
// import TaxManagementTabs from "./taxmanagement/taxmanagementtabs";
// import GlobalCommision from "./commission/index";
// import AdminManagement from "./adminmanagement/index";
// import ContentManagement from "./contentmanagement/index";
// import DeliveryAgent from "./deliverymanagement/index";
// import FaqTabs from "./faqs/faqtabs";
// import SuperAdminProfileSettings from "./othersettings/index";
// import GlobalLegalDocuments from "./legalDocs/index";
// import toast, { Toaster } from "react-hot-toast";
// import GlobalRefund from "./refundmanagement/index";

// import ContentMangementTabs from "./contentmanagement/contentmanagementtabs";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class AllGlobalTabsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndextoShow: 1,
    };
  }

  componentDidUpdate() {}

  // On Tabs change
  onTabsChange = (e, tabindex, callfrom) => {
    if (callfrom === "lg") {
      // For tabs
      this.setState({ tabIndextoShow: parseInt(tabindex) });
    } else {
      // For Select box
      this.setState({ tabIndextoShow: parseInt(e.target.value) });
    }
  };

  onLegalDocTabSelect(e, href, position, screen) {
    if (screen == "sm") {
      console.log("e.target.value ", e.target.value);
      if (e.target.value == "Payment keys") {
        this.props.history.push("/dashboard/globalsettings/" + "paymentkeys");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Tax management") {
        this.props.history.push("/dashboard/globalsettings/" + "charges");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Comission Mangement") {
        this.props.history.push(
          "/dashboard/globalsettings/" + "commissionmanagement"
        );
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Listing Fee") {
        this.props.history.push("/dashboard/globalsettings/" + "otherFee");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == " Global Refund") {
        this.props.history.push("/dashboard/globalsettings/" + "refund");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Admin Management") {
        this.props.history.push(
          "/dashboard/globalsettings/" + "adminmanagement"
        );
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Content Management") {
        this.props.history.push(
          "/dashboard/globalsettings/" + "contentmanagement"
        );
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Delivery Management") {
        this.props.history.push(
          "/dashboard/globalsettings/" + "deliverymanagement"
        );
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Legal Docs") {
        this.props.history.push("/dashboard/globalsettings/" + "legaldocs");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Faqs") {
        this.props.history.push("/dashboard/globalsettings/" + "faqs");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Other Settings") {
        this.props.history.push("/dashboard/globalsettings/" + "othersettings");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      }
    } else {
      this.setState({ tabIndextoShow: parseInt(position) });
      this.props.history.push("/dashboard/globalsettings/" + href);
    }
  }

  render() {
    var selectedTab = "";
    return (
      <>
        {/* Route wise code for global settings */}
        <div className="px-6 text-2xl font-semibold text-secondary pb-4">
          GLOBAL SETTINGS
        </div>

        <div className="xl:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-primary focus:border-primary border-ternary rounded-md"
            // defaultValue={this.props.tabs.find((tab) => tab.current).name}
            onChange={(e) => this.onLegalDocTabSelect(e, "", "", "sm")}>
            {this.props.tabs.map((tab) => (
              <option
                // key={tab.name} value={tab.tabindex}

                selected={
                  this.props.history.location.pathname ==
                    "/dashboard/globalsettings/paymentkeys" &&
                  tab.href == "paymentkeys"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/globalsettings/charges" &&
                      tab.href == "charges"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/globalsettings/commissionmanagement" &&
                      tab.href == "commissionmanagement"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/globalsettings/otherFee" &&
                      tab.href == "otherFee"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/globalsettings/refund" &&
                      tab.href == "refund"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/globalsettings/adminmanagement" &&
                      tab.href == "adminmanagement"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/globalsettings/contentmanagement" &&
                      tab.href == "contentmanagement"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/globalsettings/deliverymanagement" &&
                      tab.href == "deliverymanagement"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/globalsettings/legaldocs" &&
                      tab.href == "legaldocs"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/globalsettings/faqs" && tab.href == "faqs"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/globalsettings/othersettings" &&
                      tab.href == "othersettings"
                    ? "selected"
                    : ""
                }>
                {tab.name}
              </option>
            ))}
          </select>
        </div>

        <div className="hidden xl:block">
          <nav className="relative z-0 rounded-lg flex pl-3" aria-label="Tabs">
            {this.props.tabs.map((tab, tabIdx) => (
              <span
                style={{ marginRight: "15px", borderRadius: "6px" }}
                key={tab.name}
                className={classNames(
                  this.state.tabIndextoShow === tab.position
                    ? "text-secondary"
                    : "text-ternary hover:text-secondary",
                  // tabIdx === 0 ? 'rounded-l-lg' : '',
                  // tabIdx === this.props.tabs.length - 1 ? 'rounded-r-lg' : '',
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10 "
                )}
                aria-current={
                  this.state.tabIndextoShow === tab.position
                    ? "page"
                    : undefined
                }
                onClick={(e) =>
                  this.onLegalDocTabSelect(e, tab.href, tab.position, "lg")
                }>
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    this.props.history.location.pathname ==
                      "/dashboard/globalsettings/paymentkeys" &&
                      tab.href == "paymentkeys"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/globalsettings/charges" &&
                        tab.href == "charges"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/globalsettings/commissionmanagement" &&
                        tab.href == "commissionmanagement"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/globalsettings/otherFee" &&
                        tab.href == "otherFee"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/globalsettings/refund" &&
                        tab.href == "refund"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/globalsettings/adminmanagement" &&
                        tab.href == "adminmanagement"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/globalsettings/contentmanagement" &&
                        tab.href == "contentmanagement"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/globalsettings/deliverymanagement" &&
                        tab.href == "deliverymanagement"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/globalsettings/legaldocs" &&
                        tab.href == "legaldocs"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/globalsettings/faqs" && tab.href == "faqs"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/globalsettings/othersettings" &&
                        tab.href == "othersettings"
                      ? "bg-primary"
                      : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </span>
            ))}
          </nav>
        </div>
        {/* <div className="px-6 text-2xl font-semibold text-secondary">
          GLOBAL SETTINGS
        </div>
        <div className="py-6 px-6">
          <div>
            <div className="xl:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full focus:ring-primary focus:border-primary border-ternary rounded-md"
                defaultValue={this.props.tabs.find((tab) => tab.current).name}
                onChange={(e) => this.onTabsChange(e, 0, "sm")}
              >
                {this.props.tabs.map((tab) => (
                  <option
                    key={tab.name}
                    value={tab.tabindex}
                    selected={
                      this.state.tabIndextoShow === tab.tabindex
                        ? "selected"
                        : ""
                    }
                  >
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden xl:block">
              <nav className="relative z-0 rounded-lg flex  " aria-label="Tabs">
                {this.props.tabs.map((tab, tabIdx) => (
                  <span
                    style={{ marginRight: "10px", borderRadius: "6px" }}
                    key={tab.name}
                    className={classNames(
                      this.state.tabIndextoShow === tab.tabindex
                        ? "text-secondary"
                        : "text-ternary hover:text-secondary",
                      tabIdx === 0 ? "rounded-l-lg" : "",
                      tabIdx === this.props.tabs.length - 1
                        ? "rounded-r-lg"
                        : "",
                      "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10 "
                    )}
                    aria-current={
                      this.state.tabIndextoShow === tab.tabindex
                        ? "page"
                        : undefined
                    }
                    onClick={(e) => this.onTabsChange(e, tab.tabindex, "lg")}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        this.state.tabIndextoShow === tab.tabindex
                          ? "bg-primary"
                          : "bg-transparent",
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </span>
                ))}
              </nav>
            </div>
            <div className="">
              <div className="mt-5">
                <div
                  className={this.state.tabIndextoShow === 1 ? "" : "hidden"}
                >
                  <PaymentKeys />
                </div>
                <div
                  className={this.state.tabIndextoShow === 2 ? "" : "hidden"}
                >
                  <TaxManagementTabs />
                </div>
                <div
                  className={this.state.tabIndextoShow === 3 ? "" : "hidden"}
                >
                  <GlobalCommision />
                </div>
                <div
                  className={this.state.tabIndextoShow === 4 ? "" : "hidden"}
                >
                  <GlobalListingFee />
                </div>
                <div
                  className={this.state.tabIndextoShow === 5 ? "" : "hidden"}
                >
                  <GlobalRefund />
                </div>
                <div
                  className={this.state.tabIndextoShow === 6 ? "" : "hidden"}
                >
                  <AdminManagement />
                </div>
                <div
                  className={this.state.tabIndextoShow === 7 ? "" : "hidden"}
                >
                  <ContentMangementTabs />
                </div>

                <div
                  className={this.state.tabIndextoShow === 8 ? "" : "hidden"}
                >
                  <DeliveryAgent />
                </div>
                <div
                  className={this.state.tabIndextoShow === 9 ? "" : "hidden"}
                >
                  <GlobalLegalDocuments />
                </div>
                <div
                  className={this.state.tabIndextoShow === 10 ? "" : "hidden"}
                >
                  <FaqTabs />
                </div>
                <div
                  className={this.state.tabIndextoShow === 11 ? "" : "hidden"}
                >
                  <SuperAdminProfileSettings />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.AllglobalTabs,
  };
}

export default connect(mapStateToProps, {})(AllGlobalTabsList);
