import React, { Component } from "react";
import {
  EditorState,
  ContentState,
  RichUtils,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import {
  BASE_IMAGES_URL,
  BASE_INLINE_UPLOAD_BASE_URL,
  BASE_INLINE_IMAGE_URL,
} from "./constants";
import randomstring from "randomstring";
import axiosFileupload from "axios-fileupload";
import { connect } from "react-redux";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

var initialized = false;
var inputHadValue = false;

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    // here we create the empty state
    var editorState = EditorState.createEmpty();
    // if the redux-form field has a value
    if (props.input.value.length > 0) {
      const blocksFromHtml = htmlToDraft(props.input.value);
      const contentState = ContentState.createFromBlockArray(blocksFromHtml);
      editorState = EditorState.createWithContent(contentState);

      // convert the editorState to whatever you'd like
      /*var contentState = stateFromHTML(props.input.value);
      editorState = EditorState.createWithContent(contentState);*/
      inputHadValue = true;
    }

    this.state = { editorState: editorState };

    this.onChange = (editorState) => {
      var { input } = this.props;
      //console.log("Original ",editorState.getCurrentContent());
      //console.log("Raw ",convertToRaw(editorState.getCurrentContent()));
      // converting to the raw JSON on change
      let html = draftToHtml(convertToRaw(editorState.getCurrentContent())); //stateToHTML(editorState.getCurrentContent());
      //console.log("HTML ",html);

      // Vinay (This below line is commented as because with this aboute content menu is getting one issue)
      //input.onChange(html);

      // Set it on the state
      this.setState({ editorState });
    };
    /*this.focus = () => this.refs.editor.focus();
    this.handleKeyCommand = (command) => this._handleKeyCommand(command);
    this.onTab = (e) => this._onTab(e);
    this.toggleBlockType = (type) => this._toggleBlockType(type);
    this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);*/

    initialized = true;
    // console.log('field :- ', this.props)
  }

  _handleKeyCommand(command) {
    var { editorState } = this.state;
    var newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _onTab(e) {
    var maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }

  componentWillReceiveProps(newProps) {
    // Vinay !inputHadValue condition is delted from if section as because one issue was occuring in about content menu
    if (initialized && newProps.input.value.length > 0) {
      const blocksFromHtml = htmlToDraft(newProps.input.value);
      const contentState = ContentState.createFromBlockArray(blocksFromHtml);
      const editorNewState = EditorState.createWithContent(contentState);
      this.setState({ editorState: editorNewState });
      inputHadValue = true;
    }
  }

  handleEditorChange = (e) => {
    console.log(e.target.getContent());
  };

  render() {
    var { editorState } = this.state;

    var { input } = this.props;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    /*let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();

    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }*/
    //'fontFamily''colorPicker','fontSize','fontFamily'
    /*
    ,
    fontFamily: {
      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    }
    */
    var tooloptions = {};
    var height = 0;
    if (this.props.type === "minimal") {
      height = 120;
      tooloptions = {
        options: ["inline", "fontFamily"],
        inline: {
          options: ["bold", "italic", "underline"],
        },
        fontFamily: {
          options: [
            "Arial",
            "Georgia",
            "Impact",
            "Tahoma",
            "Times New Roman",
            "Verdana",
          ],
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
        },
      };
    } else if (this.props.type === "webview-editor") {
      height = 342;
      tooloptions = {
        options: ["inline", "fontFamily"],
        inline: {
          options: ["bold", "italic", "underline"],
        },
        fontFamily: {
          options: [
            "Arial",
            "Georgia",
            "Impact",
            "Tahoma",
            "Times New Roman",
            "Verdana",
          ],
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
        },
      };
    } else {
      height = 354;
      tooloptions = {
        options: ["inline", "fontSize", "fontFamily", "link", "colorPicker"],
        inline: {
          options: ["bold", "italic", "underline"],
        },
        image: {
          uploadCallback: uploadImageCallBack,
          alt: { present: true, mandatory: false },
          inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
          urlEnabled: true,
          uploadEnabled: true,
          defaultSize: {
            height: "auto",
            width: "auto",
          },
        },
        fontFamily: {
          options: [
            "Arial",
            "Georgia",
            "Impact",
            "Tahoma",
            "Times New Roman",
            "Verdana",
          ],
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
        },
      };
    }

    return (
      <div className="rdw-storybook-root">
        <Editor
          {...input}
          spellCheck
          placeholder={input.placeholder}
          ref="editor"
          editorState={editorState}
          toolbarClassName="rdw-storybook-toolbar"
          wrapperClassName="rdw-storybook-wrapper"
          editorClassName="rdw-storybook-editor"
          onEditorStateChange={this.onChange}
          editorStyle={{
            height: height,
            background: "white",
            paddingLeft: 10,
            paddingRight: 10,
          }}
          toolbar={tooloptions}
          stripPastedStyles={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

export default connect(mapStateToProps, { axiosFileupload })(RichTextEditor);

// Custom overrides for "code" style.
/*var styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: 'Lato-Regular',
    fontSize: 16,
    padding: 2,
  },
};*/

function uploadImageCallBack(file) {
  return new Promise((resolve, reject) => {
    axiosFileupload(BASE_INLINE_UPLOAD_BASE_URL, file)
      .then(({ data }) => {
        const response = `${BASE_INLINE_IMAGE_URL}/${data}`;
        resolve({ data: { link: data } });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*function uploadImageCallBack(file) {
  return new Promise(
    (resolve, reject) => {
      const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
      xhr.open('POST', 'https://api.imgur.com/3/image');
      xhr.setRequestHeader('Authorization', 'Client-ID 8d26ccd12712fca');
      const data = new FormData(); // eslint-disable-line no-undef
      let reader = new FileReader();
      reader.onloadend = () => {
        var filename=randomstring.generate();
        var imageData={imagefile:reader.result,filename:filename};

        data.append('image', file);
        xhr.send(data);
        xhr.addEventListener('load', () => {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        });
        xhr.addEventListener('error', () => {
          const error = JSON.parse(xhr.responseText);
          reject(error);
        });
      }
      reader.readAsDataURL(file);
    },
  );
}*/

/*function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}


var BLOCK_TYPES = [
  {label: 'H1', style: 'header-one'},
  {label: 'H2', style: 'header-two'},
  {label: 'H3', style: 'header-three'},
  {label: 'H4', style: 'header-four'},
  {label: 'H5', style: 'header-five'},
  {label: 'H6', style: 'header-six'},
  {label: 'Blockquote', style: 'blockquote'},
  {label: 'UL', style: 'unordered-list-item'},
  {label: 'OL', style: 'ordered-list-item'},
];

var BlockStyleControls = (props) => {
  var {editorState} = props;
  var selection = editorState.getSelection();
  var blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};


var INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD'},
  {label: 'Italic', style: 'ITALIC'},
  {label: 'Underline', style: 'UNDERLINE'},
  {label: 'Monospace', style: 'CODE'},
];

var InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};*/
