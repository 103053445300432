import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import LocaleStrings from "../../../../../languages/index";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
} from "../../../../../../common/constants";
import { confirmAlert } from "react-confirm-alert";
import { deleteWishlistitems, fetchWishlist } from "../../../actions/index";
import toast from "react-hot-toast";
import { ProductTextLocalized } from "../../../../../../common/common-functions";
import AllitemProductdetailmodal from "../../../../sellermanagement/components/tabs/product/allitems-productdetails";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../../currency/action/index";
import { updateMRP } from "../../../../../../common/common-functions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Itemswishlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
    };
  }
  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  _handleDelete(e, values, newdata) {
    // console.log('sss',this.props.newdata.userid);
    confirmAlert({
      title: "Delete Wishlist Items",
      message: `${LocaleStrings.delete_confirmation_suheader_text}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.deleteWishlistitems(
              this.props.session,
              values,
              (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error == 1) {
                  toast.error(LocaleStrings.errordelete);
                } else {
                  toast.success(LocaleStrings.watchlistdeleted);
                  this.props.fetchWishlist(
                    this.props.session,
                    this.props.newdata.userid,
                    (CallBackResponse) => {}
                  );
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { values, language, localCurrency } = this.props;
    // console.log("valueswishlist", values);
    var img = `${BASE_IMAGES_URL}/vendor/variations/${values.productdetails.productimage}?api_key=${APP_API_KEY}`;

    let all_variants = values.productdetails.all_variants;
    // console.log('all_variants',all_variants)
    let price = _.filter(all_variants, function (o) {
      return o.id == values.productdetails.variantid;
    });
    // console.log('price',price)

    let itemPrice = price.length > 0 ? price[0].price_unit : 0;
    // console.log('itemPrice',itemPrice)
    let priceObj = updateMRP(values, values.productdetails.variantid);
    let discountedPrice = priceObj.discountedPrice;
    let strProductDiscount = priceObj.strProductDiscount;

    let currency = currencyCode(localCurrency);

    let productCurrency = "QAR";
    if (values.currency && (values.currency != "" || values.currency != null)) {
      productCurrency = values.currency;
    }

    // let itemPrice_val =itemPrice;
    let itemPrice_val = isCurrencyRateApplicable(values.currency, itemPrice);

    let discountedPrice_val = discountedPrice;

    return (
      <>
        <div
          className="flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer"
          onClick={(e) => this.openModal(e, values.productdetails)}>
          <div className="flex-shrink-0 relative">
            <img className="   h-40 w-full object-cover " src={img} alt="" />
            {/* <StarIcon className="h-5 w-5 absolute top-1 left-1" aria-hidden="true" /> */}

            {/* <div className="absolute top-1 right-1">
              <Menu as="div" className="relative inline-block text-left">
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button className="bg-quaternary rounded-full flex items-center text-gray-400 hover:text-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary">
                        <span className="sr-only">Open options</span>
                        <DotsVerticalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        style={{display:'none'}}// hidden for timebeing
                      >
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <p
                                className={classNames(
                                  active
                                    ? "bg-quaternary text-secondary"
                                    : "text-secondary",
                                  "block px-4 py-2 text-sm"
                                )}
                                onClick={(e) =>
                                  this._handleDelete(
                                    e,
                                    values,
                                    this.props.newdata
                                  )
                                }
                              >
                                {LocaleStrings.removefromwishlist}
                              </p>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div> */}
          </div>
          <div className="flex-1 bg-white p-2 flex flex-col justify-between">
            <div className="flex-1">
              <span className="block mt-2">
                <p className="text-xs font-semibold text-secondary">
                  {ProductTextLocalized(
                    values.productdetails.productname_en,
                    values.productdetails.productname_ar,
                    language
                  )}
                </p>
                <p className="mt-1 text-xs text-ternary">
                  {values.productdetails.product_desc_en.length > 80
                    ? values.productdetails.product_desc_en.substring(1, 60) +
                      "..."
                    : values.productdetails.product_desc_en}
                </p>
              </span>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 mb-2 p-2">
            <div>
              <p className="text-xs font-semibold text-secondary">
                {currency}
                {itemPrice_val}
              </p>
            </div>
            <div>
              <p className="text-xs line-through ...">
                {currency}
                {discountedPrice_val}
              </p>
            </div>
            <div>
              <p className="text-xs text-danger">
                {" "}
                {values.productdetails.saletype == "auction" ? (
                  <div>{values.productdetails.numbids}Bids</div>
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
        </div>
        {this.state.isOpen ? (
          <AllitemProductdetailmodal
            isOpen={this.state.isOpen}
            onClose={this.closeModal}
            productDetails={this.props.values.productdetails}
            qa={this.props.values.productdetails.qa}
          />
        ) : (
          ""
        )}

        {/* ugukgukgl */}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  localCurrency: state.currency,
});

export default connect(mapStateToProps, {
  deleteWishlistitems,
  fetchWishlist,
  isCurrencyRateApplicable,
})(Itemswishlist);
