import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import { reduxForm } from "redux-form";
import EditcustomerModal from "../../editmodal";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
} from "../../../../../../common/constants";
import _ from "lodash";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import BaseLoader from "../../../../../../common/base-loader.js";
import {
  singleCustomerobj,
  fetchCustomer,
  fetchsingleCustomer,
} from "../../../actions/index";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { banCustomer } from "../../../actions/index";

class AboutCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      setOpen: false,
      open: false,
      isactive: 1,
      loading: true,
      loaderDesc: "Preparing Customer Details",
    };
  }

  openModal = (e, obj) => {
    this.props.singleCustomerobj(obj);
    this.setState({ isOpen: true });
  };
  // closeModal = () => this.setState({ isOpen: false });

  closeModal = (e, obj) => {
    this.props.singleCustomerobj({});
    this.setState({ isOpen: false });
  };

  componentDidMount(data) {
    this.props.fetchsingleCustomer(
      this.props.session,
      this.props.data.userid,
      (resource) => {
        this.setState({ loading: false });
      }
    );
    this.setState({ isactive: this.props.data.isactive });
  }
  banCustomer(e, data) {
    // console.log("data", data);
    var userOngoingOrder = _.filter(this.props.orderMasterList.data, {
      userid: data.userid,
    });
    if (userOngoingOrder.length > 0) {
      NotificationManager.error(
        "Cannot ban this user as they already have an ongoing order"
      );
    } else {
      confirmAlert({
        title: "Ban Consumer",
        message: "Are you sure you want Ban?",
        buttons: [
          {
            label: LocaleStrings.yes,
            onClick: () => {
              let body = {};

              body = { userid: data.userid, isactive: 0 };

              //let body = { resource: { keyid:id,isactive:0} };
              // let body = { resource: { keyid:id,isactive:0} };

              this.props.banCustomer(this.props.session, body, (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error) {
                  NotificationManager.error("Sorry Cannot Ban");
                } else {
                  this.props.fetchsingleCustomer(
                    this.props.session,
                    data.userid,
                    (resource) => {
                      NotificationManager.success("Successfully Banned");
                      this.setState({ isactive: 0 });
                    }
                  );
                }
              });
            },
          },
          {
            label: LocaleStrings.no,
            onClick: () => {},
          },
        ],
      });
    }
  }
  UnbanCustomer(e, data) {
    // console.log("data", data);

    confirmAlert({
      title: "Unban Consumer",
      message: "Are you sure you want Unban?",
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            let body = {};

            body = { userid: data.userid, isactive: 1 };

            //let body = { resource: { keyid:id,isactive:0} };
            // let body = { resource: { keyid:id,isactive:0} };

            this.props.banCustomer(this.props.session, body, (resource) => {
              // console.log("showresource :- ", resource);
              if (resource.error) {
                NotificationManager.error("Sorry Cannot Ban");
              } else {
                this.props.fetchsingleCustomer(
                  this.props.session,
                  data.userid,
                  (resource) => {
                    NotificationManager.success("Successfully Unbanned");
                    this.setState({ isactive: 1 });
                  }
                );
              }
            });
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { data, singleCustomerData } = this.props;
    // console.log('check',data)
    let dob = "";
    var img = "";
    var username = "";
    var email = "";
    var gender = "";
    var phone = "";
    var isactive = "";

    if (singleCustomerData && singleCustomerData.length > 0) {
      dob = moment(singleCustomerData[0]?.dob).format("D MMM YYYY");
      img = `${BASE_IMAGES_URL}/users/${singleCustomerData[0]?.userid}.jpg?api_key=${APP_API_KEY}`;
      username = singleCustomerData[0]?.username;
      email = singleCustomerData[0]?.email;
      gender = singleCustomerData[0]?.gender;
      phone = singleCustomerData[0]?.phone;
      isactive = singleCustomerData[0]?.isactive;
    }

    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {singleCustomerData && singleCustomerData.length > 0 ? (
              <div
                className="grid gap-4  px-6 grid-cols-1 mt-5 flex flex-wrap content-center justify-center"
                style={{ overflowX: "hidden" }}>
                <div className="mt-1 flex items-center flex flex-wrap content-center justify-center">
                  <div className="">
                    {/* <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                           <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                         </svg> */}
                    <img
                      className="w-32 h-32 flex-shrink-0 mx-auto rounded-full"
                      src={img}
                      alt=""
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "../images/user_1.png";
                      }}
                    />
                    {/* <img src={img}  /> */}
                  </div>
                </div>
                <div className="mt-1 flex items-center flex flex-wrap content-center justify-center">
                  {username}
                </div>
                <hr />
                {gender ? (
                  <div className="mt-1 flex items-center flex flex-wrap content-center justify-center mt-4">
                    <img
                      className="h-8 w-8 mx-2"
                      src="../images/about/gender.svg"
                    />
                    {_.startCase(gender)}
                  </div>
                ) : (
                  ""
                )}
                {dob ? (
                  <div className="mt-1 flex items-center flex flex-wrap content-center justify-center mt-4">
                    <img
                      className="h-8 w-8 mx-2"
                      src="../images/about/calender.svg"
                    />
                    {dob}
                  </div>
                ) : (
                  ""
                )}
                <div className="mt-1 flex items-center flex flex-wrap content-center justify-center mt-4">
                  <img
                    className="h-8 w-8 mx-2"
                    src="../images/about/email.svg"
                  />
                  {email}
                </div>
                {phone ? (
                  <div className="mt-1 flex items-center flex flex-wrap content-center justify-center mt-4">
                    <img
                      className="h-8 w-8 mx-2"
                      src="../images/about/phone.svg"
                    />
                    {phone}
                  </div>
                ) : (
                  ""
                )}
                <div className="mt-1 flex items-center flex flex-wrap content-center justify-center mt-4">
                  {isactive == 1 ? (
                    <>
                      <button
                        type="button"
                        onClick={(e) =>
                          this.banCustomer(e, singleCustomerData[0])
                        }
                        className="lg:w-48 md:w-44 sm:w-44 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-red-500">
                        Ban Customer
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={(e) =>
                          this.UnbanCustomer(e, singleCustomerData[0])
                        }
                        className="lg:w-48 md:w-44 sm:w-44 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-red-500">
                        Unban Consumer
                      </button>
                    </>
                  )}

                  <button
                    type="button"
                    onClick={(e) => this.openModal(e, singleCustomerData[0])}
                    className="lg:w-48  mb-4 md:w-44 sm:w-44 flex justify-center mx-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover ml-5 mt-4">
                    {LocaleStrings.updatecustomer}
                  </button>
                  {this.state.isOpen ? (
                    <EditcustomerModal
                      isOpen={this.state.isOpen}
                      onClose={this.closeModal}
                      // data={this.props.data}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <NotificationContainer />
              </div>
            ) : (
              <div className="mt-48 center-item text-ternary">
                Customer Details Not Found
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // console.log('ownProps.data', ownProps.data);
  return {
    session: state.session,
    data: ownProps.data,
    singleCustomerData: state.singleCustomerData
      ? state.singleCustomerData.data
      : [],
    orderMasterList: state.orderMasterList,
  };
}

export default reduxForm({
  // validate,
  form: "AboutCustomerForm",
})(
  connect(mapStateToProps, {
    singleCustomerobj,
    fetchCustomer,
    banCustomer,
    fetchsingleCustomer,
  })(AboutCustomer)
);
