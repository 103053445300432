import React, { Component } from "react";
import { connect } from "react-redux";
import { SearchIcon } from "@heroicons/react/solid";
import LocaleStrings from "../../../../../languages/index";
import _ from "lodash";
import AddGlobalCharges from "./addcharges";
import ChargesList from "./chargeslist-items";
import { fetchShippingCharges, fetchZoneMaster } from "../../../actions/index";
import { NotificationContainer } from "react-notifications";
import AllGlobalTabsList from "../../globaltabs";

class GlobalCharges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveredorderSearch: "",
      loaderDesc: LocaleStrings.adds_management,
      loading: true,
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchShippingCharges(this.props.session, (CallBackResponse) => {
      this.props.fetchZoneMaster(this.props.session, (CallBackResponse) => {
        this.setState({ loading: false });
      });
    });
  }

  renderchargeslist() {
    var items = _.map(this.props.shippingCharges.data, (values, index) => (
      <ChargesList key={index} values={values} />
    ));
    return items;
  }

  render() {
    return (
      <>
        <AllGlobalTabsList history={this.props.history} />
        <div
          className="grid gap-4 grid-rows-1 px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 mt-5"
          style={{
            overflowX: "hidden",
            overflowY: "hidden",
            display: "block",
          }}>
          <AddGlobalCharges />
          <div className="panel-body mt-6">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-custWhite">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Pin Code
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Zone
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {LocaleStrings.shippingCharges}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {LocaleStrings.action}
                          </th>
                        </tr>
                      </thead>
                      <tbody>{this.renderchargeslist()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NotificationContainer />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    shippingCharges: state.shippingCharges,
  };
}

export default connect(mapStateToProps, {
  fetchShippingCharges,
  fetchZoneMaster,
})(GlobalCharges);
