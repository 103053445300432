import React, { Component } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../languages/index";
import { Fragment, useState } from "react";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import { XIcon, TrashIcon } from "@heroicons/react/outline";
import { Field, FieldArray, reduxForm, reset } from "redux-form";
import {
  updateCategory,
  uploadMediaLarge,
  fetchCategory,
} from "../../actions/index";
import toast, { Toaster } from "react-hot-toast";
import Dropzone from "react-dropzone";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../../common/constants";
import { isEmpty } from "../../../../../common/common-functions";
import _ from "lodash";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import ImageCropper from "../imagecropper";
import { classNames } from "../../../../../common/common-functions";

const renderAllfields = (field) => {
  var isdisabled = field.isdisabled ? "disabled" : "";
  var {
    meta: { touched, error },
  } = field;

  var className = `form-group text-help  ${
    touched && error ? "text-danger text-xs" : ""
  }`;

  var labelposition = field.labelposition;
  var extraClasses = field.className ? field.className : "";
  var formdiv = "";
  if (field.formdiv !== undefined && field.formdiv === "false") {
    formdiv = "";
  }
  var divClassName = `form-group row ${formdiv} ${
    field.meta.touched && field.meta.error ? "has-danger" : ""
  }`;
  divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
  var labelClasses = field.classNameLabel;
  var inputClasses = "col-sm-9 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_TOP) {
    labelClasses = field.classNameLabel;
    inputClasses = "col-sm-12 col-sm-offset-0";
  }
  return (
    <div className={divClassName}>
      {field.label !== "" ? (
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}
      <div className={inputClasses}>
        {field.type === "password" ? (
          <div className="relative">
            <input
              className="appearance-none block w-full pl-3 pr-7 py-2 border border-ternary rounded-md shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              type={field.show ? "text" : "password"}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              autoComplete="new-password"
            />

            {/*  Pssword Icons */}
            <div className="absolute flex items-center text-sm top-3.5 right-3.5 cursor-pointer">
              {field.show ? (
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="eye"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  onClick={() => field.setShow(!field.show)}>
                  <path
                    d="M81.8 537.8a60.3 60.3 0 010-51.5C176.6 286.5 319.8 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c-192.1 0-335.4-100.5-430.2-300.2z"
                    fill="var(--theme-color)"></path>
                  <path
                    d="M512 258c-161.3 0-279.4 81.8-362.7 254C232.6 684.2 350.7 766 512 766c161.4 0 279.5-81.8 362.7-254C791.4 339.8 673.3 258 512 258zm-4 430c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z"
                    fill="var(--theme-color)"></path>
                  <path
                    d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258s279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766z"
                    fill="var(--theme-color)"></path>
                  <path
                    d="M508 336c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"
                    fill="var(--theme-color)"></path>
                </svg>
              ) : (
                <svg
                  onClick={() => field.setShow(!field.show)}
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="eye-invisible"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true">
                  <path
                    d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"
                    fill="var(--ternary-text-color)"></path>
                  <path
                    d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"
                    fill="var(--ternary-text-color)"></path>
                </svg>
              )}
            </div>
          </div>
        ) : field.type === "number" ? (
          <input
            className={"cust-input-field " + extraClasses}
            type={field.type}
            placeholder={field.placeholder}
            {...field.input}
            maxLength={field.maxlength ? field.maxlength : ""}
            min={field.min ? field.min : ""}
          />
        ) : (
          <input
            className={"cust-input-field " + extraClasses}
            type={field.type}
            placeholder={field.placeholder}
            {...field.input}
            maxLength={field.maxlength ? field.maxlength : ""}
            disabled={isdisabled}
          />
        )}
        <div className={className}>{touched ? error : ""}</div>
      </div>
    </div>
  );
};

class EditcategoryModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      phone: "",
      setPhone: "",
      btnLoader: false,
      fileUrl: "",
      fileArr: {},
      fileArrNew: {},
      newSubcategory: [],
      newproduct: [],
      enar: "en",
      btnLoader: false,
    };

    // All Binded Functions
    this.onDropFiles = this.onDropFiles.bind(this);
  }

  onDropFiles = async (files) => {
    // console.log('ondrop',files)
    let file = files[0];
    let filearr = [];

    // console.log("file", file);

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e) => {
      file.url = e.target.result;
      // file.name   = file.name;
      // file.url = reader.result;
      filearr.push(file);
    };
    this.setState({ fileArrNew: filearr });
    // console.log('fileData',fileData);
    // console.log('URL.createObjectURL(fileData)',URL.createObjectURL(fileData));
    this.setState({ fileUrl: URL.createObjectURL(file) });
  };

  //  onSubmitForm(data) {
  //     //  console.log("values", values);
  //      var values = { ...data };
  //       // this.setState({ loading: true });
  //       this.props.updateCategory(this.props.session,values,(response) => {
  //         // console.log("response :- ", response);
  //         if (response.success === 0) {
  //           this.props.onClose();
  //           toast.error(LocaleStrings.addingfailed);

  //         } else {
  //           this.props.onClose();
  //           toast.success('Successfully created!');
  //           // alert("success");
  //         }
  //       });
  //     }

  /***** Form Submit *****/
  onSubmitForm(values) {
    // console.log("allval", values);
    this.setState({ btnLoader: true });

    let resource_coverimage = "";
    let uploaderCounter = 0;
    let filetobeupload = [];
    let arrPromise = [];
    //let { fileArrNew } = this.state;

    // console.log("---------", this.state.fileArrNew);
    if (Object.keys(this.state.fileArrNew).length > 0) {
      // Image Exist
      let fileArrNew = this.state.fileArrNew[0];

      let ts = new Date().getTime(); // Timestamp
      let nameofImage = values.categoryname_en.replace(/(\r\n|\n|\r)/gm, "");
      // let medianame = "storecover_" + ts + "." + 'jpeg'; // Image Name Creation
      let medianame = nameofImage + ts + "." + "png"; // Image Name Creation
      resource_coverimage = medianame;

      let ext = fileArrNew.name.split(".").pop(); // File extension

      let coverJson = {
        // type: "cover",
        type: "image",
        name: medianame,
        media: fileArrNew.url,
      };
      filetobeupload.push(coverJson);
      let counter = filetobeupload.length;

      filetobeupload.map((item, index) => {
        arrPromise.push(
          new Promise((resolve, reject) => {
            this.props.uploadMediaLarge(
              this.props.session,
              item,
              (response) => {
                if (response.success === 0) {
                  resolve();
                } else {
                  uploaderCounter++;
                  resolve();
                }
              }
            );
          })
        );
      });

      let body = { ...values, image: medianame };
      Promise.all(arrPromise).then(() => {
        if (counter == uploaderCounter) {
          // After file upload
          this.props.updateCategory(this.props.session, body, (response) => {
            if (response.success === 0) {
              this.setState({ open: false });
              toast.error("Update Failed");
            } else {
              this.setState({ btnLoader: false });
              this.setState({ fileArr: {} });
              this.setState({ fileArrNew: {} });
              toast.success(LocaleStrings.addedsucessfully);
              this.props.onClose();
              this.props.fetchCategory(this.props.session, (callback) => {});
            }
          });
        }
      });
    } else {
      // Without Image

      let body = { ...values };

      this.props.updateCategory(this.props.session, body, (response) => {
        if (response.success === 0) {
          this.setState({ open: false });
          toast.error("Update Failed");
        } else {
          this.setState({ btnLoader: false });
          this.setState({ fileArr: {} });
          this.setState({ fileArrNew: {} });
          toast.success("Updated Successfully");
          this.props.onClose();
          this.props.fetchCategory(this.props.session, (callback) => {});
        }
      });
    }
  }

  renderSubsubcat = ({
    fields,
    meta: { error },
    updateImagesubsubcat,
    updateImageChangesubsubcat,
    parentIndex,
  }) => (
    <ul>
      <li style={{ paddingTop: 5 }}>
        <a
          className="underline end-item text-primaryLight cursor-pointer"
          onClick={() => fields.push()}>
          +Add Subsubcategory
        </a>
      </li>
      {fields.map((hobby, index) => {
        var currentInxedData = fields.get(index);
        var imagePreviewUrl = "";
        let showDelBtn = "";
        // Hiding delete button for prev
        let { categoryDropdownarr2 } = this.props;

        if (fields.get(index) != undefined) {
          if (fields.get(index).id) {
            showDelBtn = "hidden";
          }
        }
        if (
          currentInxedData?.image &&
          currentInxedData?.image.search(";base64,") > 0
        ) {
          imagePreviewUrl = currentInxedData?.image;
        } else if (currentInxedData?.image == null) {
          imagePreviewUrl = "";
        } else {
          imagePreviewUrl = `${BASE_IMAGES_URL}/vendor/productcategories/${currentInxedData?.image}?api_key=${APP_API_KEY}`;
        }

        let dropOptionarr = [];

        _.forEach(categoryDropdownarr2, (val, i) => {
          var obj = {
            value: val.value,
            name: val.name,
          };
          dropOptionarr.push(obj);
        });

        return (
          <li
            key={index}
            style={{
              border: "1px solid gray",
              borderRadius: "15px",
              marginTop: "15px",
            }}>
            <div
              //  className="end-item"
              className={classNames(
                showDelBtn.trim() == "hidden" ? "hidden" : "",
                "cat-subcat-delete"
              )}>
              <button
                type="button"
                title="Remove Member"
                className="end-item"
                onClick={() => fields.remove(index)}>
                <TrashIcon
                  className="h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
              </button>
            </div>
            <div className="grid grid-cols-2">
              <div>SubSub Category Image</div>
              <div className="end-item">
                <ImageCropper
                  displaySize={{ width: 100, height: 100 }} // For image display style
                  requiredSize={{ width: 200, height: 200 }} // For image size required validation
                  cropperSize={{ width: 50, height: 50 }} // Cropper display size. Note its add 50px for padding
                  onImageSave={(img) =>
                    updateImagesubsubcat(parentIndex, index, img)
                  }
                  onImageChange={(bool) =>
                    updateImageChangesubsubcat(index, bool)
                  }
                  imagepath={imagePreviewUrl}
                  isdipalyText={false}
                  imageType="jpg"
                  className="drop-zone-area-custom-image"
                />
                <Field
                  name={`${hobby}.image`}
                  type="text"
                  component={this.renderHiddenFieldText}
                />
              </div>
            </div>
            <div className="py-2">
              <Field
                name={`${hobby}.isactive`}
                label="Choose"
                placeholder={LocaleStrings.select}
                component={this.renderSelect}
                mandatory="true"
                labelposition={LABEL_POSITION_TOP}
                opts={dropOptionarr}
                className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                classNameLabel="block text-box-lable"
                // value={this.props.initialValues.type}
              />
            </div>
            <Field
              name={`${hobby}.subcategoryname_en`}
              mandatory="true"
              type="text"
              component={renderAllfields}
              label={"Subsubcategory(En)"}
            />
            <div className="py-2">
              <Field
                name={`${hobby}.subcategoryname_ar`}
                mandatory="true"
                type="text"
                component={renderAllfields}
                label={"Subsubcategory(Ar)"}
              />
            </div>
          </li>
        );
      })}
      {error && <li className="error">{error}</li>}
    </ul>
  );

  renderSubcat = ({
    fields,
    meta: { error, submitFailed },
    updateImage,
    updateImageChange,
  }) => (
    <ul>
      <li style={{ paddingTop: 0 }}>
        <a
          className="underline end-item text-primaryLight cursor-pointer"
          onClick={() => fields.push({})}>
          +Add Sub Category
        </a>
        {submitFailed && error && <span>{error}</span>}
      </li>
      {fields.map((member, index) => {
        var currentInxedData = fields.get(index);
        var imagePreviewUrl = "";
        let { categoryDropdownarr2 } = this.props;
        let showDelBtn = "";

        /*  */

        if (fields.get(index).id) {
          showDelBtn = "hidden";
        }

        // console.log("currentInxedData :- ", currentInxedData);
        if (
          currentInxedData?.image &&
          currentInxedData?.image.search(";base64,") > 0
        ) {
          imagePreviewUrl = currentInxedData?.image;
        } else if (currentInxedData?.image == null) {
          imagePreviewUrl = "";
        } else {
          imagePreviewUrl = `${BASE_IMAGES_URL}/vendor/productcategories/${currentInxedData?.image}?api_key=${APP_API_KEY}`;
        }
        let dropOptionarr = [];

        _.forEach(categoryDropdownarr2, (val, i) => {
          var obj = {
            value: val.value,
            name: val.name,
          };
          dropOptionarr.push(obj);
        });
        // console.log("imagePreviewUrl", imagePreviewUrl);
        return (
          <>
            <div
              // className={"end-item" + showDelBtn}
              className={classNames(
                showDelBtn.trim() == "hidden" ? "hidden" : "",
                "cat-subcat-delete"
              )}>
              <button
                type="button"
                title="Remove Member"
                className="end-item "
                onClick={() => fields.remove(index)}>
                <TrashIcon
                  className="h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
              </button>
            </div>
            <li
              key={index}
              style={{
                border: "1px solid gray",
                borderRadius: "15px",
                marginTop: "15px",
              }}>
              <div className="grid grid-cols-2">
                <div>Upload Image</div>
                <div className="end-item">
                  <ImageCropper
                    displaySize={{ width: 100, height: 100 }} // For image display style
                    requiredSize={{ width: 200, height: 200 }} // For image size required validation
                    cropperSize={{ width: 50, height: 50 }} // Cropper display size. Note its add 50px for padding
                    onImageSave={(img) => updateImage(index, img)}
                    onImageChange={(bool) => updateImageChange(index, bool)}
                    imagepath={imagePreviewUrl}
                    isdipalyText={false}
                    imageType="jpg"
                    className="drop-zone-area-custom-image"
                  />
                  <Field
                    name={`${member}.image`}
                    type="text"
                    component={this.renderHiddenFieldText}
                  />
                </div>
              </div>
              <div className="py-2">
                <Field
                  name={`${member}.isactive`}
                  label="Choose"
                  placeholder={LocaleStrings.select}
                  component={this.renderSelect}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  opts={dropOptionarr}
                  className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                  classNameLabel="block text-box-lable"
                />
              </div>
              <Field
                name={`${member}.subcategoryname_en`}
                type="text"
                mandatory="true"
                component={renderAllfields}
                label={"Sub Category(En)"}
              />
              <div className="py-2">
                <Field
                  name={`${member}.subcategoryname_ar`}
                  type="text"
                  mandatory="true"
                  component={renderAllfields}
                  label={"Sub Category(Ar)"}
                />
              </div>

              <FieldArray
                name={`${member}.productsubcategory_by_parentid`}
                component={this.renderSubsubcat}
                values={this.state.enar}
                updateImagesubsubcat={this.onProfileImageSavesubsubcat}
                updateImageChangesubsubcat={this.onProfileImageChangesubsubcat}
                parentIndex={index}
              />
            </li>
          </>
        );
      })}
    </ul>
  );

  handleInputLanguageChange(e) {
    if (parseInt(e.target.value) === 1) {
      this.setState({ enar: "ar" });
    } else {
      this.setState({ enar: "en" });
    }
  }

  onProfileImageSavesubsubcat = (parentIndex, index, img) => {
    // console.log("index :- ", index);
    // console.log("img :- ", img);
    this.props.autofill(
      `productsubcategory_by_categoryid[${parentIndex}].productsubcategory_by_parentid[${index}].image`,
      img == "" ? "" : img
    );
  };

  onProfileImageChangesubsubcat = (index, bool) => {};

  onProfileImageSave = (index, img) => {
    // console.log("index :- ", index);
    // console.log("img :- ", img);
    this.props.autofill(
      `productsubcategory_by_categoryid[${index}].image`,
      img == "" ? "" : img
    );
  };

  onProfileImageChange = (index, bool) => {};

  render() {
    // console.log('initialValues',this.props.initialValues);
    var { handleSubmit, pristine, reset, submitting, invalid, initialValues } =
      this.props;
    let { fileUrl, enar } = this.state;
    // console.log('initialValues',initialValues)
    let coverimage = "";
    if (isEmpty(fileUrl)) {
      coverimage = `${BASE_IMAGES_URL}/vendor/productcategories/${initialValues.image}?api_key=${APP_API_KEY}`;
      //coverimage = `${BASE_IMAGES_URL}/store/${initialValues.coverimage}?api_key=${APP_API_KEY}`;
    } else {
      coverimage = fileUrl;
    }
    return (
      <Transition.Root show={this.props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.props.isOpen}
          onClose={this.props.onClose}
          initialFocus={this.myRef}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className=" sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="grid gap-4  md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 mb-10 border-b-2 border-gray-50 pb-4">
                  <div></div>
                  <div className="flex flex-wrap content-center justify-center">
                    {LocaleStrings.editcategory}
                  </div>
                  <div className="flex flex-wrap content-center justify-end">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      onClick={this.props.onClose}
                      ref={this.myRef}>
                      <span className="sr-only"></span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <form
                  className="space-y-8 divide-y divide-gray-200 mt-5"
                  onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                  encType="multipart/form-data">
                  <div>
                    <div className="border-transparent">
                      {/* <Field
                        name="language"
                        label={LocaleStrings.en}
                        component={this.renderFieldRadioNew}
                        type="radio"
                        value="0"
                        className="mx-2"
                        onChange={this.handleInputLanguageChange.bind(this)}
                      />

                      <Field
                        name="language"
                        label={LocaleStrings.ar}
                        component={this.renderFieldRadioNew}
                        type="radio"
                        value="1"
                        className="mx-2"
                        onChange={this.handleInputLanguageChange.bind(this)}
                      /> */}
                      <div className="py-4">
                        <Field
                          name={"categoryname_en"}
                          label="Category(En)"
                          mandatory="true"
                          placeholder={LocaleStrings.entercategoryname}
                          type="text"
                          component={this.renderFieldTextarea}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm h-12"
                        />
                      </div>
                      <div className="py-4">
                        <Field
                          name={"categoryname_ar"}
                          label="Category(Ar)"
                          mandatory="true"
                          placeholder={LocaleStrings.entercategoryname}
                          type="text"
                          component={this.renderFieldTextarea}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm h-12"
                        />
                      </div>
                      <div className="grid grid-cols-2">
                        <div>Edit Image</div>
                        <div class="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                          <div className="dropzonecover" key={`drop`}>
                            <div className="img_wrpcover relative">
                              <img src={coverimage} className="dropimgcover" />
                            </div>

                            <Dropzone
                              onDrop={(e) => this.onDropFiles(e)}
                              accept="image/jpeg, image/png ,image.jpg"
                              multiple={false}>
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  {...getRootProps()}
                                  className="h-full w-full absolute top-0">
                                  <input {...getInputProps()} />
                                  <p className="paragraph-seconday text-xs pt-10">
                                    {LocaleStrings.imageupload}
                                  </p>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      </div>
                      {/* <FieldArray name="productsubcategory_by_categoryid" component={this.renderSubcat} values={this.state.enar} /> */}
                      <FieldArray
                        name="productsubcategory_by_categoryid"
                        component={this.renderSubcat}
                        updateImage={this.onProfileImageSave}
                        updateImageChange={this.onProfileImageChange}
                        values={this.state.enar}
                      />
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 ">
                        <svg
                          className={classNames(
                            this.state.btnLoader ? "" : "sr-only",
                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
function validate(values) {
  // console.log('values',values)
  // var errors = {};
  var errors = { productsubcategory_by_categoryid: [] };
  var requiredFields = ["categoryname_en", "subcategoryname_en"];

  var productsubcategory_by_categoryid =
    values["productsubcategory_by_categoryid"];

  productsubcategory_by_categoryid?.forEach((v, i) => {
    errors.productsubcategory_by_categoryid.push({});

    if (!v?.subcategoryname_en || v?.subcategoryname_en.trim() === "") {
      errors.productsubcategory_by_categoryid[i].subcategoryname_en =
        "Required";
    }
    if (
      v.productsubcategory_by_parentid &&
      v.productsubcategory_by_parentid.length
    ) {
      errors.productsubcategory_by_categoryid[i][
        "productsubcategory_by_parentid"
      ] = [];
      v.productsubcategory_by_parentid?.forEach((values, index) => {
        errors.productsubcategory_by_categoryid[
          i
        ].productsubcategory_by_parentid.push({});
        if (
          !values?.subcategoryname_en ||
          values?.subcategoryname_en.trim() === ""
        ) {
          console.log("subsuberrors", errors);

          errors.productsubcategory_by_categoryid[
            i
          ].productsubcategory_by_parentid[index].subcategoryname_en =
            "Required";
        }
      });
    }
  });

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
  });
  // console.log("errors :- ", errors);
  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log("editablecategorylist", state.editablecategorylist);
  var newObj = {
    ...state.editablecategorylist,
    language: "0",
  };
  console.log("newObj", newObj);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    editablecategorylist: state.editablecategorylist,
    initialValues: newObj,
    categoryDropdownarr2: state.categoryDropdownarr2,
  };
}

export default connect(mapStateToProps, {
  updateCategory,
  uploadMediaLarge,
  fetchCategory,
})(
  reduxForm({
    validate,
    form: "EditCategoryForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EditcategoryModal)
);
