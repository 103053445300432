import React, { Component } from "react";
import { connect } from "react-redux";
import SearchNewSeller from "./searchnewseller";
import LocaleStrings from "../../../languages/index.js";
import _ from "lodash";
import SellerlisItem from "./sellerlist-item";
import { fetchSeller, newtabseller, searchSeller } from "../actions";
import Pagination from "react-js-pagination";
import Sellertabs from "./sellertabs";
import { SearchIcon } from "@heroicons/react/solid";
import { NotificationContainer } from "react-notifications";
import { fetchOrderMaster } from "../../customermanagement/actions/index";
import Vendortabs from "./vendortabs";
import BaseLoader from "../../../../common/base-loader";
import { classNames } from "../../../../common/common-functions";

class VerificationrequestedSellers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.seller_management,
      pageNumber: 1,
      sellerSearchText: "",
      search: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchSeller(
      this.props.session,
      0,
      "verificationrequested",
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
    this.props.fetchOrderMaster(this.props.session, (CallBackResponse) => {});
    this.props.newtabseller();
  }

  handleSearch = (event) => {
    var search = _.trim(event.target.value);
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchSeller(
        this.props.session,
        0,
        "verificationrequested",
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchSeller(
        this.props.session,
        search,
        "verificationrequested"
      );
    }
    this.setState({ search, pageNumber });
  };

  paginationCallback = (pageNumber) => {
    console.log("pgno", pageNumber);
    if (this.state.search == "") {
      this.props.fetchSeller(
        this.props.session,
        pageNumber,
        "verificationrequested",
        (response) => {}
      );
      this.props.fetchOrderMaster(this.props.session, (CallBackResponse) => {});
    } else {
      this.props.searchSeller(
        this.props.session,
        this.state.search,
        "verificationrequested",
        pageNumber
      );
    }

    this.setState({ ...this.state, pageNumber });
  };

  renderList() {
    let language = this.props.language;
    let searchText = this.state.sellerSearchText;
    let filtered = [];

    if (this.state.sellerSearchText !== "") {
      // If Search Key Found
      let listing = this.props.sellerlist.data;

      filtered = _.filter(listing, function (o) {
        return o.username.toLowerCase().indexOf(_.toLower(searchText)) > -1;
      });
    } else {
      // If Search Key Not Found Send Orginal Array
      filtered = this.props.sellerlist.data;
    }
    let pageDataLength = this.props.sellerlist.data.length;

    let { pageNumber } = this.state;

    // console.log('check');
    var items = _.map(filtered, (values) => (
      <SellerlisItem
        key={values.userid}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
        history={this.props.history}
        callfrom="verificationrequested"
      />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }

  render() {
    return (
      <>
        {!this.props.newtabsellerlist ? (
          <Vendortabs history={this.props.history} />
        ) : (
          ""
        )}
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {!this.props.newtabsellerlist ? (
              <>
                <div
                  className="grid gap-4 grid-rows-1 px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1"
                  style={{
                    overflowX: "hidden",
                    overflowY: "hidden",
                    display: "block",
                  }}>
                  {/* <SearchNewSeller /> */}
                  <div
                    className="relative md:w-6/12 xs:w-full text-ternary focus-within:text-secondary smallscreen-search mt-5"
                    // style={{ marginTop: -35 }}
                  >
                    <div
                      className={classNames(
                        this.props.language == "en" ? "left-0 " : "right-0",
                        "absolute inset-y-0 flex items-center pointer-events-none px-1"
                      )}>
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className={classNames(
                        this.props.language == "en" ? "pl-8 pr-3" : "pr-8 pl-3",
                        "block lg:w-6/12 xs:w-full sm:w-full h-full  py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                      )}
                      placeholder={LocaleStrings.search}
                      type="search"
                      name="search"
                      // onChange={(e) =>
                      //   this.setState({ sellerSearchText: e.target.value })
                      // }
                      value={this.state.search}
                      onChange={this.handleSearch}
                    />
                  </div>
                  {this.props.sellerlist.count > 0 ? (
                    <div className="panel-body mt-6">
                      {/* <TableContent /> */}
                      <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-custWhite">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      {LocaleStrings.name}
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      {LocaleStrings.email}
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      {LocaleStrings.phonenumber}
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      {LocaleStrings.action}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{this.renderList()}</tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.props.sellerlist.count > 7 ? (
                        <Pagination
                          activePage={this.state.pageNumber}
                          itemsCountPerPage={7}
                          totalItemsCount={this.props.sellerlist.count}
                          pageRangeDisplayed={10}
                          onChange={this.paginationCallback}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="mt-48 center-item text-ternary">
                      Seller(s) Not Found
                    </div>
                  )}

                  <NotificationContainer />
                </div>
              </>
            ) : (
              // Upload
              // <Customertabs showUploadSection={this.customerdetails} />
              <Sellertabs
                newtab={this.props.newtabsellerlist}
                history={this.props.history}
                vr_pagi={this.paginationCallback}
              />
            )}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  var { isLoggedIn, user, session, sellerlist, newtabsellerlist, language } =
    state;
  return {
    isLoggedIn,
    session,
    user,
    language,
    sellerlist,
    newtabsellerlist,
  };
}

export default connect(mapStateToProps, {
  fetchSeller,
  newtabseller,
  searchSeller,
  fetchOrderMaster,
})(VerificationrequestedSellers);
