import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import LocaleStrings from "../../../../languages/index";
import { DATE_FORMAT } from "../../../../../common/constants";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";

// Import Actions
import {
  isCouponManageModalopen,
  fetchProductsByCateSubCate,
  getSelectedCouponsObj,
  deactivateCoupon,
  fetchCouponMaster,
  deleteCoupon,
} from "../../actions/index";

// Common Functions
import { ProductTextLocalized } from "../../../../../common/common-functions";

class CoupMasterTableList extends Component {
  constructor(props) {
    super(props);

    // All Binds Functions
  }

  /**** Coupon Delete Handel ****/
  _handleDelete(e, coupmasterObj) {
    confirmAlert({
      title: LocaleStrings.delete_confirmation_header_text,
      message: `${LocaleStrings.delete_confirmation_suheader_text}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.deleteCoupon(
              this.props.session,
              coupmasterObj,
              (callBack) => {
                toast.success(LocaleStrings.coupon_deleted_successfully);

                // Get Coupon Master Data
                this.props.fetchCouponMaster(
                  this.props.session,
                  (callBack) => {}
                );
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  /**** Coupon Deactivate Handle ****/
  _handleDeactivate(e, coupmasterObj) {
    let objBody = {
      id: coupmasterObj.id,
      active: 0,
    };

    confirmAlert({
      title: LocaleStrings.deactivate_confirmation_header_text,
      message: `${LocaleStrings.deactivate_confirmation_suheader_text}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.deactivateCoupon(
              this.props.session,
              objBody,
              (callBack) => {
                toast.success(LocaleStrings.coupon_deactivated_successfully);

                // Get Coupon Master Data
                this.props.fetchCouponMaster(
                  this.props.session,
                  (callBack) => {}
                );
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  /**** Modal Open Function ****/
  openCouponsManagementModal(e, coupmasterObj) {
    if (Object.keys(coupmasterObj.couponrule_by_couponid).length > 0) {
      let categoryid = coupmasterObj.couponrule_by_couponid[0].categoryid;
      let subcategoryid = coupmasterObj.couponrule_by_couponid[0].subcategoryid;

      this.props.fetchProductsByCateSubCate(
        this.props.session,
        categoryid,
        subcategoryid,
        (callBack) => {}
      );
    }

    this.props.getSelectedCouponsObj(coupmasterObj, this.props.session);
    this.props.isCouponManageModalopen(true);
  }

  render() {
    let language = this.props.language;
    let values = this.props.coupmasterObj;
    let discountArea = this.props.discountArea;
    let actionBtnText =
      Object.keys(values).length > 0
        ? values.active
          ? LocaleStrings.deactivate
          : LocaleStrings.delete
        : "";
    let minCartValText =
      Object.keys(values).length > 0
        ? values.min_cart_value != null && values.min_cart_value != ""
          ? " (" +
            LocaleStrings.coupons_thead_above +
            " $" +
            values.min_cart_value +
            ")"
          : ""
        : "";

    // console.log('Couponvalues',values);
    if (values.couponrule_by_couponid.length > 0) {
      if (
        values.couponrule_by_couponid[0].discounareaid != null &&
        values.couponrule_by_couponid[0].discounareaid != ""
      ) {
        let afterFindObj = _.find(discountArea, {
          id: values.couponrule_by_couponid[0].discounareaid,
        });
      }
    }
    return (
      <>
        {values.criteriaid == 2 ? (
          <tr
            key={this.props.position}
            className="bg-white border-b-2 border-gray-50 cursor-pointer hover-all">
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {values.title}
              {minCartValText}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {values.code}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {moment(values.validity_end).format(DATE_FORMAT)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              <div className="sales-table-td-text ">
                <button
                  type="button"
                  className="button mr-2"
                  onClick={(e) => this.openCouponsManagementModal(e, values)}>
                  {LocaleStrings.edit}
                </button>
                {values.active ? (
                  <button
                    type="button"
                    className="button"
                    onClick={(e) => this._handleDeactivate(e, values)}>
                    {actionBtnText}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button"
                    onClick={(e) => this._handleDelete(e, values)}>
                    {actionBtnText}
                  </button>
                )}
              </div>
            </td>
          </tr>
        ) : (
          <tr
            key={this.props.position}
            className="bg-white border-b-2 border-gray-50 cursor-pointer hover-all">
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {values.title}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {values.code}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {moment(values.validity_end).format(DATE_FORMAT)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              <div className="sales-table-td-text ">
                <button
                  type="button"
                  className="button mr-2"
                  onClick={(e) => this.openCouponsManagementModal(e, values)}>
                  {LocaleStrings.edit}
                </button>
                {values.active ? (
                  <button
                    type="button"
                    className="button"
                    onClick={(e) => this._handleDeactivate(e, values)}>
                    {actionBtnText}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button"
                    onClick={(e) => this._handleDelete(e, values)}>
                    {actionBtnText}
                  </button>
                )}
              </div>
            </td>
          </tr>
        )}
      </>
    );
  }
}
// isNegotiationopen

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    discountArea: state.discountArea ? state.discountArea.data : [],
  };
}

export default connect(mapStateToProps, {
  isCouponManageModalopen,
  getSelectedCouponsObj,
  fetchProductsByCateSubCate,
  deactivateCoupon,
  fetchCouponMaster,
  deleteCoupon,
})(CoupMasterTableList);
