import React from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../../common/base-component";
import _ from "lodash";
import LocaleStrings from "../../../../languages";
import Packages from "./packages";
import { editagents } from "../../actions/index";
import Editdeliveryagents from "./editdeliveryagents";
import DeactivateAgents from "./deactivate";
import ActivateAgents from "./activate";

class ListDeliveryAgents extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
      activateopen: false,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openEditagentmodals(e, editAgentdata) {
    this.props.editagents(editAgentdata, this.props.session);
    this.setState({ isOpen: true });
  }

  closeModal = () => this.setState({ isOpen: false });

  openDeactivateModal = (e, obj) => {
    this.props.editagents(obj);
    this.setState({ open: true });
  };
  closeDeactivateModal = () => this.setState({ open: false });

  openActivateModal = (e, obj) => {
    this.props.editagents(obj);
    this.setState({ activateopen: true });
  };
  closeActivateModal = () => this.setState({ activateopen: false });

  renderpackages() {
    var items = _.map(this.props.values.packages, (values) => (
      <Packages key={values.userid} values={values} />
    ));
    return items;
  }

  render() {
    var { values } = this.props;
    // console.log("abcd", values);

    return (
      <>
        {values.isactive == 1 ? (
          <>
            {" "}
            <tr className="bg-white border-b-2 border-gray-50">
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                style={{ cursor: "pointer" }}>
                <div>{_.startCase(values.companyname)}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                style={{ cursor: "pointer" }}>
                {this.renderpackages()}
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                style={{ cursor: "pointer" }}>
                <div>{_.startCase(values.keycode)}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="">
                    <button
                      className="button"
                      onClick={(e) => this.openEditagentmodals(e, values)}
                      type="button">
                      {LocaleStrings.edit}
                    </button>
                    {this.state.isOpen ? (
                      <Editdeliveryagents
                        isOpen={this.state.isOpen}
                        onClose={this.closeModal}
                        // values={this.props.values}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this.openDeactivateModal(e, values)}
                      type="button">
                      {LocaleStrings.deactivate}
                    </button>
                    {this.state.open ? (
                      <DeactivateAgents
                        isOpen={this.state.open}
                        onClose={this.closeDeactivateModal}
                        // values={this.props.values}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </td>
            </tr>
          </>
        ) : (
          <>
            {" "}
            <tr className="bg-white border-b-2 border-gray-50 bg-gray-200">
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                style={{ cursor: "pointer" }}>
                <div>{_.startCase(values.companyname)}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                style={{ cursor: "pointer" }}>
                {this.renderpackages()}
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                style={{ cursor: "pointer" }}>
                <div>{_.startCase(values.keycode)}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this.openActivateModal(e, values)}
                      type="button">
                      {LocaleStrings.specs_Activate}
                    </button>
                    {this.state.activateopen ? (
                      <ActivateAgents
                        isOpen={this.state.activateopen}
                        onClose={this.closeActivateModal}
                        // values={this.props.values}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
  };
}

export default connect(mapStateToProps, {
  editagents,
})(ListDeliveryAgents);
