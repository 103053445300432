import Recat, { Component } from "react";
import { connect } from "react-redux";
import { XIcon } from "@heroicons/react/outline";
import LocaleStrings from "../../../../languages";
import RejectedNegoToday from "./rejectednegotable/rejectednegodayindex";
import RejectedNegoThisweek from "./rejectednegotable/rejectednegoweekindex";
import RejectedNegoThismonth from "./rejectednegotable/rejectednegomonthindex";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class RejectedNegoTimeSpanTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndextoShow: 1,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  closetabs() {
    this.props.newtabs();
  }

  // On Tabs change
  onTabsChange = (e, tabindex, callfrom) => {
    if (callfrom === "lg") {
      // For tabs
      this.setState({ tabIndextoShow: parseInt(tabindex) });
    } else {
      // For Select box
      this.setState({ tabIndextoShow: parseInt(e.target.value) });
    }
  };

  render() {
    var selectedTab = "";
    return (
      <>
        <div className="py-6 px-6">
          {/* Form Tabs */}
          <div>
            <div className="sm:hidden ">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full focus:ring-primary focus:border-primary border-ternary rounded-md"
                defaultValue={this.props.tabs.find((tab) => tab.current).name}
                onChange={(e) => this.onTabsChange(e, 0, "sm")}>
                {this.props.tabs.map((tab) => (
                  <option
                    key={tab.name}
                    value={tab.tabindex}
                    selected={
                      this.state.tabIndextoShow === tab.tabindex
                        ? "selected"
                        : ""
                    }>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="hidden sm:block "
              style={{ float: "right", marginTop: -75 }}>
              <nav className="relative z-0 rounded-lg flex  " aria-label="Tabs">
                {this.props.tabs.map((tab, tabIdx) => (
                  <span
                    style={{ marginRight: "20px", borderRadius: "6px" }}
                    key={tab.name}
                    className={classNames(
                      this.state.tabIndextoShow === tab.tabindex
                        ? "text-primary cursor-pointer"
                        : "text-ternary hover:text-secondary cursor-pointer",
                      // tabIdx === 0 ? 'rounded-l-lg' : '',
                      // tabIdx === this.props.tabs.length - 1 ? 'rounded-r-lg' : '',
                      // 'cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10 '
                      "cursor-pointer"
                    )}
                    aria-current={
                      this.state.tabIndextoShow === tab.tabindex
                        ? "page"
                        : undefined
                    }
                    onClick={(e) => this.onTabsChange(e, tab.tabindex, "lg")}>
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        this.state.tabIndextoShow === tab.tabindex
                          ? "bg-primary"
                          : "bg-transparent",
                        ""
                      )}
                    />
                  </span>
                ))}
              </nav>
            </div>
            <div className="">
              <div className="mt-5">
                <div
                  className={this.state.tabIndextoShow === 1 ? "" : "hidden"}>
                  <RejectedNegoToday />
                </div>
                <div
                  className={this.state.tabIndextoShow === 2 ? "" : "hidden"}>
                  <RejectedNegoThisweek />
                </div>
                <div
                  className={this.state.tabIndextoShow === 3 ? "" : "hidden"}>
                  <RejectedNegoThismonth />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.TimeSpanTabs,
  };
}

export default connect(mapStateToProps, {})(RejectedNegoTimeSpanTabs);
