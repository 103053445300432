import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import "react-notifications/lib/notifications.css";
import { Field, FieldArray, reduxForm, reset, getFormValues } from "redux-form";
import { BaseComponent } from "../../../../common/base-component";
import {
  fetchAllproducts,
  fetchAllCategories,
  fetchAllsubCategories,
  fetchSettingMaster,
  reduxUpdateHomePagebody,
} from "../actions/index";
import BaseLoader from "../../../../common/base-loader";
import HompagFormBuilder from "./form-homepage";
import PreviewHomePageModal from "./preview/previewmodal";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
class HomePageDesign extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      selection_type: "",
      dragablebox: [],
      section: "",
      loading: true,
      update: false,
      loaderDesc: "Preparing HomePage",
    };

    // All Binded Functions
  }

  componentDidMount() {
    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation.data, {
      href: answer,
    });

    if (singleNavigationRow == undefined) {
      this.props.history.push("/login");
    }
    this.props.fetchSettingMaster(this.props.session, (CallBackResponse) => {
      this.setState({ loading: false });
    });
    this.props.fetchAllproducts(this.props.session);
    this.props.fetchAllCategories(this.props.session);
    this.props.fetchAllsubCategories(this.props.session);
  }

  componentWillReceiveProps(nextProps) {
    // console.log('nextProps ',nextProps )
    if (nextProps && nextProps.homePagebodyRedux.updated == true) {
      this.setState({ update: true });
      this.props.reduxUpdateHomePagebody(false);
    }
  }
  componentWillUnmount() {
    // console.log(this.props.history);
    // let pathname = this.props.history.location.pathname;
    // confirmAlert({
    //   title: 'Test1',
    //   message: 'LocaleStrings.prod_mangement_ban_message',
    //   buttons: [
    //     {
    //       label: 'yes',
    //       onClick: () => {
    //        this.props.history.push(pathname)
    //       },
    //     },
    //     {
    //       label: 'no',
    //       onClick: () => {},
    //     },
    //   ],
    // });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {/* <div className="lg:grid lg:grid-cols-12 py-2 px-6">

              <div className="lg:col-span-8 rounded text-2xl font-semibold text-secondary">
                Home Page Builder
              </div>
              <div className="lg:col-span-4 flex end-item">
                <div>
                 <PreviewHomePageModal/>
                </div>
              </div>
            </div> */}

            {this.props.homePageBody && this.props.homePageBody.length > 0 ? (
              <HompagFormBuilder />
            ) : (
              ""
            )}
          </>
        )}
        <NotificationContainer />
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    homePagebodyRedux: state.homePagebodyRedux,
    homePageBody: state.homePageBody ? state.homePageBody.data : [],
    navigation: state.sideBarMenus,
  };
}

// export default connect(mapStateToProps, {})(HomePageDesign);
export default connect(mapStateToProps, {
  fetchAllproducts,
  fetchAllCategories,
  fetchAllsubCategories,
  fetchSettingMaster,
  reduxUpdateHomePagebody,
})(
  reduxForm({
    form: "HomePageDesignForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(HomePageDesign)
);
