import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import { Field, reduxForm } from "redux-form";
import { BaseComponent, number } from "../../../../../../common/base-component";
import toast from "react-hot-toast";
import { updateCommisionSeller, fetchSeller } from "../../../actions/index";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { classNames } from "../../../../../../common/common-functions";

class Sellertax extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      setOpen: false,
      btnLoader: false,
    };
  }

  onFormSubmit = (val) => {
    val.callfrom = "updatetax";
    this.setState({ btnLoader: true });
    if (val.tax == "") {
      NotificationManager.error(
        LocaleStrings.seller_management_errorcommission
      );
    } else {
      if (val.tax < 1) {
        NotificationManager.error(
          LocaleStrings.seller_management_error0commission
        );
      } else {
        var values = { ...val };
        this.props.updateCommisionSeller(
          this.props.session,
          values,
          (response) => {
            if (response.success === 0) {
              toast.error(LocaleStrings.common_cannotupdate);
              this.setState({ btnLoader: false });
            } else {
              this.setState({ btnLoader: false });
              NotificationManager.success(LocaleStrings.common_updated);
              this.props.fetchSeller(
                this.props.session,
                0,
                (CallBackResponse) => {}
              );
            }
          }
        );
      }
    }
  };

  render() {
    var { data, handleSubmit } = this.props;
    // console.log("valuesdatarender", this.props.session);
    return (
      <>
        <div className="mt-10">
          <form className=" " onSubmit={handleSubmit(this.onFormSubmit)}>
            <div className="flow-root xs:px-3 md:px-10 py-5 bg-custWhite rounded">
              <p className="mb-3">Tax Percentage Per Seller</p>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 flex start-small">
                  Seller Tax
                </label>
                <div
                  className="mt-1 sm:mt-0 sm:col-span-2 input-forsmall"
                  style={{ width: "300px" }}>
                  <Field
                    name="tax"
                    placeholder="tax"
                    type="text"
                    component={this.renderFieldcustomer}
                    className="appearance-none block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                  />
                  <p className="text-gray-400  flex start-small-percentage">
                    %
                  </p>
                </div>
              </div>
              <div className="mb-20"></div>
              <div className="center-item mt-16">
                <button type="submit" className="bigbutton">
                  <svg
                    className={classNames(
                      this.state.btnLoader ? "" : "sr-only",
                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  Update Tax
                </button>
              </div>
            </div>
          </form>
        </div>
        <NotificationContainer />
      </>
    );
  }
}

function validate(val) {
  // console.log("form data", values);
  var errors = {};

  var tax = val["tax"];

  if (tax && number(tax)) {
    errors["tax"] = "Please enter a number ";
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  return {
    session: state.session,
    data: ownProps.data,
    initialValues: ownProps.data,
  };
}

export default connect(mapStateToProps, {
  updateCommisionSeller,
  fetchSeller,
})(
  reduxForm({
    validate,
    form: "SellertaxForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Sellertax)
);
