import React, { Component } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../languages/index.js";
import { Fragment } from "react";
import { BaseComponent } from "../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import { banAdvertisement } from "../actions/index";
import { Toaster } from "react-hot-toast";
import { validateEmail } from "../../../../common/constants";
import { classNames } from "../../../../common/common-functions";
import _ from "lodash";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

class BanAdvertisement extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      btnLoader: false,
    };

    // All Binded Functions
  }

  onSubmitForm(data) {
    this.setState({ btnLoader: true });
    var values = { id: this.props.EditAdds.id, isactive: 0 };
    this.props.banAdvertisement(this.props.session, values, (response) => {
      if (response.success === 0) {
        NotificationManager.error(LocaleStrings.common_banFail_message);
      } else {
        this.props.onClose();
        NotificationManager.success(LocaleStrings.common_banFail_success);
      }
    });
  }

  render() {
    // console.log('initialValues',this.props.initialValues);
    var { handleSubmit, pristine, reset, submitting, invalid } = this.props;
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.myRef}
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="grid gap-4  md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 mb-10 border-b-2 border-gray-50 pb-4">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center">
                      {LocaleStrings.ads_management_banAds}
                    </div>
                    <div className="flex flex-wrap content-center justify-end">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}
                        ref={this.myRef}>
                        <span className="sr-only"></span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className="space-y-8 divide-y divide-gray-200 mt-5"
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                    encType="multipart/form-data">
                    {LocaleStrings.ads_management_banAds_title}

                    <div className="pt-5">
                      <div className="">
                        <button
                          //   onClick={}
                          type="submit"
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 ">
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "sr-only",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          {LocaleStrings.yes}
                        </button>
                        <button
                          onClick={this.props.onClose}
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 ">
                          {LocaleStrings.no}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <Toaster limit={1} />
      </>
    );
  }
}
function validate(values) {
  // console.log("Validate ",values);
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.emailrequired;
  }

  if (!values.username) {
    errors.password = LocaleStrings.firstnamerequire;
  }

  if (!values.phone) {
    errors.passcode = LocaleStrings.phonenumberrequired;
  }

  if (values.email) {
    if (!validateEmail(values.email)) {
      errors.email = LocaleStrings.emailrequired;
    }
  }
  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('session',state.customerlistingtabs);
  return {
    session,
    user,
    EditAdds: state.EditAdds,
  };
}

export default connect(mapStateToProps, {
  banAdvertisement,
})(
  reduxForm({
    validate,
    form: "BanAdvertisementForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(BanAdvertisement)
);
