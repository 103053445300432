import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages";
import { Field, reduxForm } from "redux-form";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
  number,
} from "../../../../../common/base-component";
import toast, { Toaster } from "react-hot-toast";
import {
  fetchglobalCommision,
  updateGlobalCommission,
} from "../../actions/index";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import _ from "lodash";
import { classNames } from "../../../../../common/common-functions";
import AllGlobalTabsList from "../globaltabs";
import BaseLoader from "../../../../../common/base-loader";

class GlobalListingFee extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      setOpen: false,
      btnLoader: false,
      loading: true,
      loaderDesc: "Preparing Listing Fee",
    };
  }

  componentDidMount() {
    this.props.fetchglobalCommision(this.props.session, (CallBackResponse) => {
      this.setState({ loading: false });
    });
  }

  onFormSubmit = (val) => {
    if (val.listing_perproduct && parseInt(val.listing_perproduct) < 4) {
      NotificationManager.error("Listing Fee Should be 4 or above");
      return false;
    }
    this.setState({ btnLoader: true });
    if (
      val.listingfee_type == "" ||
      val.listing_perproduct == "" ||
      val.product_listing_limit == ""
    ) {
      NotificationManager.error(LocaleStrings.global_setting_ListingFee_Data);
    } else {
      var values = { ...val };

      this.props.updateGlobalCommission(
        this.props.session,
        values,
        (response) => {
          if (response.success === 0) {
            toast.error(LocaleStrings.common_cannotupdate);
            this.setState({ btnLoader: false });
          } else {
            NotificationManager.success(
              LocaleStrings.global_setting_ListingFee_updateSuccess
            );
            this.setState({ btnLoader: false });
            this.props.fetchglobalCommision(
              this.props.session,
              (CallBackResponse) => {}
            );
          }
        }
      );
    }
  };

  render() {
    var { data, handleSubmit, initialValues } = this.props;

    var listingfeeTypeArr = [];

    //Discount Area Object Creation
    _.forEach(this.props.listngfeetype, function (value) {
      var obj = {
        name: value.name,
        value: value.value,
      };

      listingfeeTypeArr.push(obj);
    });

    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div className="m-6">
              <form className=" " onSubmit={handleSubmit(this.onFormSubmit)}>
                <div className="flow-root px-10 py-5 bg-custWhite rounded">
                  <p className="mb-3">
                    {LocaleStrings.global_setting_ListingFee_forEachProduct}
                  </p>
                  <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 pr-10">
                    <div className="px-5 end-item">
                      {LocaleStrings.global_setting_ListingFee}
                    </div>
                    <div className="mt-3">
                      <Field
                        name="listing_perproduct"
                        placeholder="Listing Fee"
                        type="text"
                        component={this.renderFieldcustomer}
                        className="appearance-none block w-half px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div></div>
                  </div>
                  <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 pr-10">
                    <div className="px-5 end-item">Listing Fee Type</div>
                    <div className="mt-3">
                      <Field
                        name="listingfee_type"
                        // label="Listing Fee Type"
                        placeholder={LocaleStrings.select}
                        component={this.renderSelect}
                        // mandatory="true"
                        labelposition={LABEL_POSITION_TOP}
                        opts={listingfeeTypeArr}
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                      />
                    </div>
                    <div></div>
                  </div>
                  {/* <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 pr-10">
                <div className="px-5 end-item">Product Listing Limit In Home Screen</div>
                <div className="mt-3">
                  <Field
                    name="product_listing_limit"
                    placeholder="Product Listing Limit"
                    type="text"
                    component={this.renderFieldcustomer}
                    className="appearance-none block w-half px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div></div>
              </div> */}

                  <div className="center-item my-16">
                    <button type="submit" className="bigbutton">
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "sr-only",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.global_setting_ListingFee_update}
                    </button>
                  </div>
                </div>
              </form>
              <NotificationContainer />
            </div>
          </>
        )}
      </>
    );
  }
}

function validate(val) {
  // console.log("form data", values);
  var errors = {};
  var listing_perproduct = val["listing_perproduct"];
  var product_listing_limit = val["product_listing_limit"];

  if (listing_perproduct && number(listing_perproduct)) {
    errors["listing_perproduct"] = "Please enter a number";
  }

  if (product_listing_limit && number(product_listing_limit)) {
    errors["product_listing_limit"] = "Please enter a number";
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  return {
    session: state.session,
    initialValues: state.global_commissionlist.data,

    listngfeetype: state.listngfeetype,
  };
}

export default connect(mapStateToProps, {
  fetchglobalCommision,
  updateGlobalCommission,
})(
  reduxForm({
    validate,
    form: "GlobalCommissionForm3",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(GlobalListingFee)
);
