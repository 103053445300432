import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import _ from "lodash";
import { classNames } from "../../../../../common/common-functions";
import {
  addnewAdmin,
  fetchModeratorAdmin,
  emaildbCheck,
  addAdminRoles,
} from "../../actions/index";
import { NotificationManager } from "react-notifications";
import { validateEmail } from "../../../../../common/constants";

const gender = [
  { name: "Rather not say", value: "", index: 0 },
  { name: "MALE", value: "male", index: 1 },
  { name: "FEMALE", value: "female", index: 2 },
];

class AddSuperAdmin extends BaseComponent {
  constructor(props) {
    super(props);
    // var today = new Date(),
    // date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      genderOptions: gender,
      show: false,
      show1: false,
      btnLoader: false,
      phone: "",
      country: "",
    };

    // All Binded Functions
    this.changeShow = this.changeShow.bind(this);
    this.changeShow1 = this.changeShow1.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }
  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
    this.props.reset();
  }

  changeShow() {
    let { show } = this.state;
    this.setState({ show: !show });
  }
  changeShow1() {
    let { show1 } = this.state;
    this.setState({ show1: !show1 });
  }

  onSubmitForm(values) {
    this.setState({ btnLoader: true });
    if (Object.keys(values).length === 0) {
      NotificationManager.error(LocaleStrings.common_mandatory_required);
      this.setState({ btnLoader: false });
      return false;
    }
    if (_.trim(values.new_password) !== _.trim(values.password)) {
      NotificationManager.error(LocaleStrings.common_password_Error);
      // this.setState({ open: false });
      this.setState({ btnLoader: false });
      return false;
    }

    if (
      !values.hasOwnProperty("roles") ||
      (values.hasOwnProperty("roles") && values.roles.length == 0)
    ) {
      NotificationManager.error("Please add roles to add admin");
      this.setState({ btnLoader: false });
      return false;
    }

    this.props.emaildbCheck(values.email, (response) => {
      if (response.success === 1) {
        NotificationManager.error(LocaleStrings.common_email_Exists);
        return false;
      } else {
        var dfdata = {
          last_name: "",
          gender: values.gender,
          isvendortoo: 0,
          usertype: "moderator",
          phone: values.phone,
          first_name: values.username,
          display_name: values.username,
          new_password: values.password,
          email: values.email,
          name: values.username,
        };
        let rolesdataArr = [];
        this.props.addnewAdmin(this.props.session, dfdata, (response) => {
          if (response.success === 0) {
            this.setState({ btnLoader: false });
            // alert("error");
            NotificationManager.error(LocaleStrings.addingfailed);
          } else {
            if (values.roles.length > 0) {
              _.forEach(values.roles, function (value) {
                let obj = {
                  roleid: value.value,
                  useridfk: response?.result?.user?.userid,
                };
                rolesdataArr.push(obj);
              });
            }
            this.props.addAdminRoles(
              this.props.session,
              rolesdataArr,
              (response) => {
                if (response.success === 0) {
                  this.setState({ btnLoader: false });
                  // alert("error");
                  NotificationManager.error(LocaleStrings.addingfailed);
                } else {
                  this.props.fetchModeratorAdmin(
                    this.props.session,
                    0,
                    (CallBackResponse) => {
                      this.setState({ btnLoader: false });
                    }
                  );
                  this.setState({ open: false });
                  // alert("done");
                  NotificationManager.success(LocaleStrings.addedsucessfully);
                }
              }
            );
          }
        });
      }
    });
  }

  phoneChange = (value, country, e, formattedValue) => {
    this.setState({ phone: value, country: country.dialCode });
  };

  render() {
    var { handleSubmit, allModeratorrolelist } = this.props;
    let adminRoleopts = [];

    if (allModeratorrolelist && allModeratorrolelist.data.length > 0) {
      _.forEach(allModeratorrolelist.data, function (value) {
        var obj = {
          label: value.name,
          value: value.position.toString(),
        };

        adminRoleopts.push(obj);
      });
    }

    return (
      <div className=" mt-5" style={{ overflowX: "hidden" }}>
        <div className="flex flex-wrap content-center justify-end ">
          <button
            onClick={this.openModal}
            type="submit"
            className="lg:w-48 md:w-44 sm:w-44 xs:w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
            {LocaleStrings.superadmin_Add}
            <img
              src="../images/product_management_images/add.svg"
              className="h-5 ml-2"
            />
          </button>
          <Transition.Root show={this.state.open} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed z-10 inset-0 overflow-y-auto"
              open={this.state.open}
              onClose={this.closeModal}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                  <div
                    className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                    style={{ padding: 0 }}>
                    {/* MOdal Header */}
                    <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                      <div className="flex flex-wrap content-star justify-start ml-4 mt-4">
                        <div className="text-md leading-6 font-medium text-secondary">
                          {LocaleStrings.superadmin_Add}
                        </div>
                      </div>
                      <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                        <button
                          type="button"
                          className="btn-closeModal"
                          onClick={this.closeModal}>
                          <span className="sr-only">{LocaleStrings.close}</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <form
                      className=" p-6"
                      onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
                      <div>
                        <div className="border-transparent">
                          <div className="mb-5 mt-2">
                            <Field
                              name="roles"
                              label="Roles"
                              placeholder={LocaleStrings.select}
                              options={adminRoleopts}
                              component={this.renderAutoComplete}
                              labelposition={LABEL_POSITION_TOP}
                              labelKey="label"
                              valueKey="value"
                              className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                              classNameLabel="block text-box-lable"
                              isMulti={true}
                            />
                          </div>
                          <Field
                            name="email"
                            placeholder={LocaleStrings.email}
                            label="Email"
                            type="text"
                            mandatory="true"
                            component={this.renderAllfields}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                          />
                          <div className="mt-5">
                            <Field
                              name="username"
                              placeholder={LocaleStrings.name}
                              label="Name"
                              type="text"
                              mandatory="true"
                              component={this.renderAllfields}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="mb-5 mt-5">
                            <Field
                              name={"phone"}
                              label={"Phone Number"}
                              mandatory="true"
                              type="text"
                              component={this.renderPhonewithFlag}
                              className="cust-input-field"
                              classNameLabel="text-box-lable"
                              phone={this.state.phone}
                              phoneChange={this.phoneChange}
                            />
                          </div>

                          <div className="mb-5 mt-2">
                            <Field
                              name="password"
                              label="Password"
                              placeholder={LocaleStrings.password}
                              show={this.state.show}
                              mandatory="true"
                              setShow={this.changeShow}
                              component={this.renderAllfields}
                              type="password"
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="mb-5 mt-2">
                            <Field
                              name="new_password"
                              label="Confirm Password"
                              placeholder={LocaleStrings.confirm_password}
                              type="password"
                              mandatory="true"
                              show={this.state.show1}
                              setShow={this.changeShow1}
                              component={this.renderAllfields}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-5 mt-2">
                        <p>{LocaleStrings.gender}</p>
                        {_.map(this.state.genderOptions, (opt, index) => {
                          return (
                            <Field
                              name="gender"
                              label={opt.name}
                              value={opt.value}
                              lebelAlign="R"
                              type="radio"
                              component={this.renderFieldRadio1}
                            />
                          );
                        })}
                      </div>
                      <div className="mt-10">
                        <div className="flex justify-center">
                          <button type="submit" className="button">
                            <svg
                              className={classNames(
                                this.state.btnLoader ? "" : "sr-only",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.add}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    );
  }
}

function validate(values) {
  // console.log("values", values);
  var errors = {};

  var email = values["email"];
  var username = values["username"];
  var dob = values["dob"];
  var phone = values["phone"];
  var password = values["password"];
  var new_password = values["new_password"];

  if (!username || username.trim() === "") {
    errors["username"] = "Required";
  }
  if (!email || email.trim() === "") {
    errors["email"] = "Required";
  }

  if (values.email) {
    if (!validateEmail(values.email)) {
      errors.email = "Invalid email";
    }
  }

  if (!password || password.trim() === "") {
    errors["password"] = "Required";
  }
  if (!new_password || new_password.trim() === "") {
    errors["new_password"] = "Required";
  }

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  var initVals = { gender: "" };
  return {
    session,
    user,
    language: state.language,
    allModeratorrolelist: state.allModeratorrolelist,
    initialValues: initVals,
  };
}

export default connect(mapStateToProps, {
  addnewAdmin,
  fetchModeratorAdmin,
  emaildbCheck,
  addAdminRoles,
})(
  reduxForm({
    validate,
    form: "ADDSUPERADMIN",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddSuperAdmin)
);
