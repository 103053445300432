import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BaseComponent } from "../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { reduxForm } from "redux-form";
import _ from "lodash";
import { currencyCode, currencyRate } from "../../../currency/action/index";

const limit = 10;

class CharityPayoutModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      phone: "",
      btnLoader: false,
    };

    // All Binded Functions
    // this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onSubmitForm() {
    let { data } = this.props;
  }

  renderList = (e) => {
    let { data } = this.props;

    let item = _.map(data, (val, index) => {
      var { localCurrency } = this.props;
      let currency = currencyCode(localCurrency);
      console.log("val", val);
      return (
        <tr className="bg-white border-b-2 border-gray-50 hover-all cursor-pointer">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div>{val.invoice_no}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {val.donation_percentage}%
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {currency} {val.donation_amount}
          </td>
        </tr>
      );
    });

    return item;
  };

  render() {
    var { handleSubmit, data, val } = this.props;
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.myRef}
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 bigmodal">
                  <div className="grid grid-cols-3 mb-10 border-b-2 border-gray-50 pb-4">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center">
                      {_.startCase(val.name_en)} Payout
                    </div>
                    <div className="flex flex-wrap content-center justify-end">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}
                        ref={this.myRef}>
                        <span className="sr-only"></span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className="space-y-8 divide-y divide-gray-200 mt-5"
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                    encType="multipart/form-data">
                    <div>
                      <div className="border-transparent">
                        {data.length > 0 ? (
                          <div className="flex flex-col pt-6 pb-2 px-6">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-custWhite">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Invoice No
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Percentage
                                        </th>

                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Donation amount
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>{this.renderList()}</tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}
function validate(values) {
  var errors = {};

  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    data: ownProps.data,
    val: ownProps.val,
    localCurrency: state.currency,
    enddate: ownProps.enddate,
    startdate: ownProps.startdate,
  };
}

export default connect(mapStateToProps, {
  currencyCode,
  currencyRate,
})(
  reduxForm({
    validate,
    form: "CharityPayoutModalForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(CharityPayoutModal)
);
