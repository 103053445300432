import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import LocaleStrings from "../../../../languages/index";
import _ from "lodash";

const AllReasonComponent = (props) => {
  let { data, renderStatusMedia } = props;

  const returnReasonArr = _.filter(data.product.statuses, function (o) {
    return o.status === "return";
  });

  const replaceReasonArr = _.filter(data.product.statuses, function (o) {
    return o.status === "replace";
  });

  // console.log("data", data.product);
  // console.log("replaceReasonArr", replaceReasonArr);
  // console.log("returnReasonArr", returnReasonArr);
  return (
    <>
      {/* RETURN / REPLACE REASON */}
      <div>
        {data.product.statuses &&
        _.size(data.product.statuses) > 0 &&
        _.size(
          _.filter(data.product.statuses, function (o) {
            return o.status === "replace" || o.status === "return";
          })
        ) > 0 ? (
          <div className="bg-custWhite px-4 py-5 mt-4 shadow sm:rounded-lg sm:px-6">
            <h2 id="timeline-title" class="text-sm font-medium text-secondary">
              {LocaleStrings.return_reason}: :
            </h2>
            <p className="my-2 text-sm text-ternary">
              {returnReasonArr[0]?.returnreason}
              {replaceReasonArr[0]?.returnreason}
              {/* {replaceReasonArr[0]?.replacereason} */}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>

      {/* REPLACE REASON MEDIA  */}
      <div className="flex">
        {renderStatusMedia(data.product.currentstatus.status_media)}
      </div>

      {/* REFUND REJECT REASON */}
      {data.product.statuses &&
      _.size(data.product.statuses) > 0 &&
      _.size(
        _.filter(data.product.statuses, {
          status: "cancelrefundrejected",
          status: "refundrejected",
        })
      ) > 0 ? (
        <div className="bg-custWhite px-4 py-5 mt-4 shadow sm:rounded-lg sm:px-6">
          <h2 id="timeline-title" class="text-sm font-medium text-secondary">
            {LocaleStrings.refund_reject_reason} :
          </h2>
          <p className="text-xs text-ternary mt-2">
            {
              _.filter(data.product.statuses, {
                status: "cancelrefundrejected",
                status: "refundrejected",
              })[0].refundrejectreason
            }
          </p>
        </div>
      ) : (
        ""
      )}

      {/* If call from cancel Show the cacel reason */}
      {/* {orderCallFrom === "canceled" &&
        data.product.currentstatus.status === "cancelled" ? ( */}
      {data.product.statuses &&
      _.size(data.product.statuses) > 0 &&
      _.size(_.filter(data.product.statuses, ["status", "cancelled"])) > 0 ? (
        <div className="bg-custWhite px-4 py-5 mt-4 shadow sm:rounded-lg sm:px-6">
          <h2 id="timeline-title" class="text-sm font-medium text-secondary">
            {LocaleStrings.cancellation_reason} :
          </h2>
          <p className="text-xs text-ternary mt-2">
            {/* {data.product.currentstatus.cancelreason} */}
            {
              _.filter(data.product.statuses, ["status", "cancelled"])[0]
                .cancelreason
            }
          </p>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // #
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AllReasonComponent);
