import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index";
import _ from "lodash";
import {
  APP_API_KEY,
  BASE_IMAGES_URL,
} from "../../../../../../common/constants";
// Import Actions
import { isCommentsModalOpen, fetchCommentList } from "../../../actions/index";
import {
  replaceTags,
  timeAgo,
} from "../../../../../../common/common-functions";
import BaseLoader from "../../../../../../common/base-loader";
import { Fragment, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import renderHTML from "react-render-html";

class ViewComments extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_product_comments,
      giveAnswer: false,
      qaid: "",
      comment: "",
    };

    // All Binded Functions
    this.closeSlideOvers = this.closeSlideOvers.bind(this);
  }

  componentDidMount() {
    let { session, productID } = this.props;

    this._isMounted = true;

    if (this._isMounted) {
      setInterval(() => {
        if (this.props.isCommentsOpen) {
          this.props.fetchCommentList(session, productID, (callback) => {
            this.setState({ loading: false });
          });
        }
      }, 3000);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**** Close Slide Over  ****/
  closeSlideOvers() {
    this.props.isCommentsModalOpen(false);
  }

  // // Render Comment List
  renderCommentList = (e) => {
    let { commentList, reportedObject, reportedOn } = this.props;
    let commentText = "no text";
    let item = _.map(
      _.orderBy(commentList, ["updatedat"], ["desc"]),
      (commentObj, index) => {
        var productImageUrl = `${BASE_IMAGES_URL}/users/${commentObj.commentby.userid}.jpg?api_key=${APP_API_KEY}`;
        var commentStyle = {};
        if (!commentObj.comment == null || !commentObj.comment == "") {
          commentText = commentObj.comment.replace("href", "class");
        }
        if (
          reportedOn == "comment" &&
          reportedObject.comment.id == commentObj.id
        ) {
          commentStyle = {
            color: "#FF0000",
          };
        }

        return (
          <li
            key={index}
            className={"py-4 bg-custWhite px-4  table-hover-transition"}>
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={productImageUrl}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../images/user_1.png";
                  }}
                  alt="Image"
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-secondary truncate">
                  {commentObj.commentby.username}
                </p>
                <p className="text-sm  truncate" style={commentStyle}>
                  {" "}
                  {/* {commentObj.comment} */}
                  {renderHTML(commentText)}
                  {/* {_.map(
                    replaceTags(commentObj.comment, commentObj.tags),
                    (obj, pos) => {
                      return obj.indexOf("@") >= 0 ? (
                        <span className="text-primary pr-1">{obj}</span>
                      ) : (
                        <span className="text-ternary pr-1">{obj}</span>
                      );
                    }
                  )}{" "} */}
                </p>
              </div>

              <div class="text-ternary text-xs">
                {timeAgo(commentObj.createdat)}
              </div>
            </div>
          </li>
        );
      }
    );

    return item;
  };

  /**** Answer Related  ****/

  // Select Question To answer And show "Type your Answer Input Box"
  selectedQuestionForAnswer = (e, commentObj) => {};

  /*** Close COMMENT Footer***/
  closeAnswerSection() {
    this.setState({ giveAnswer: false });
    this.setState({ qaid: "" });
  }

  render() {
    let { isCommentsOpen, language, commentList, session } = this.props;

    // Price Functionality

    return (
      <>
        <Transition.Root show={isCommentsOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 overflow-hidden z-50"
            initialFocus={this.myRef}
            open={isCommentsOpen}
            onClose={this.closeSlideOvers}>
            <div className="absolute inset-0 overflow-hidden">
              <Dialog.Overlay className="absolute inset-0" />

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <div className="w-screen max-w-md">
                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                      <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="slider-modal-headtext">
                              {LocaleStrings.comments}
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                className="btn-closeModal"
                                onClick={this.closeSlideOvers}
                                ref={this.myRef}>
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        {this.state.loading ? (
                          <>
                            <BaseLoader data={this.state.loaderDesc} />
                          </>
                        ) : (
                          <>
                            {commentList.length > 0 ? (
                              <>
                                <div className="mt-6 relative flex-1 px-4 sm:px-6">
                                  <ul
                                    role="list"
                                    className="-my-5 divide-y divide-gray-200 mt-2 mb-2">
                                    {this.renderCommentList()}
                                  </ul>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="center-item lg:mt-48 text-lg text-ternary">
                                  No Comment Added
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('state.commentList',state.commentList);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isCommentsOpen: state.isCommentsOpen,
    commentList: !_.isEmpty(state.commentList)
      ? !_.isEmpty(state.commentList.data)
        ? state.commentList.data
        : []
      : [],
    productID: ownProps.productID,
  };
}

export default connect(mapStateToProps, {
  isCommentsModalOpen,
  fetchCommentList,
})(ViewComments);
