import React from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../../common/base-component";
import _ from "lodash";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../currency/action/index";
import {
  getIntToFloat,
  classNames,
} from "../../../../../common/common-functions";

class TransactionListing extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  render() {
    var { values, language, localCurrency } = this.props;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    let transaction_amount = 0.0;
    if (values && values.ordermaster_by_transactionid) {
      let productCurrency = values?.ordermaster_by_transactionid[0]?.currency;
      transaction_amount = isCurrencyRateApplicable(
        productCurrency,
        values.amount
      );
    }

    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.transactionid}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {_.startCase(values.paymentmode)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {_.startCase(values.paymentstatus)}
          </td>
          <td
            className={classNames(
              values.orderdetails[0]?.orderstatuses_by_orderdetailid[0]
                ?.status !== "refunded" ||
                values.orderdetails[0]?.orderstatuses_by_orderdetailid[0]
                  ?.status !== "cancelrefunded"
                ? "text-secondary"
                : "text-red-400",
              "px-6 py-4 whitespace-nowrap text-sm font-medium"
            )}>
            {currency} {transaction_amount}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {_.startCase(values.type)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {_.startCase(values.users_by_paymentby.username)}
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    localCurrency: state.currency,
    language: state.language,
  };
}

export default connect(mapStateToProps, { isCurrencyRateApplicable })(
  TransactionListing
);
