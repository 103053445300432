import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../../common/constants";
import _ from "lodash";
import {
  converDateIntoLocal,
  updateMRP,
  loadConfirmationButton,
} from "../../../../../common/common-functions";
import AllitemProductdetailmodal from "../../../sellermanagement/components/tabs/product/allitems-productdetails";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LocaleStrings from "../../../../languages/index";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import { fetchallProductlist, banProduct } from "../../actions/index";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../currency/action/index";

class AllproductItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };
    // this.confirmButtonOk = React.createRef();
  }

  // _handleBan = (e, values) => {
  //   // console.log(oldIndex, newIndex, "onsortend");
  //   let { user } = this.props;
  //   // console.log("values", values);
  //   var data = {
  //     id: values.id,
  //     isactive: 0,
  //     callfrom: "banproduct",
  //     bannedby: user.userid,
  //   };
  //   confirmAlert({
  //     customUI: ({ onClose }) => {
  //       return (
  //         <div className="react-confirm-alert-body">
  //           <h1>{LocaleStrings.prod_mangement_ban}</h1>
  //           <p>{LocaleStrings.prod_mangement_ban_message}</p>

  //           <div className="react-confirm-alert-button-group">
  //             <button
  //               type="button"
  //               onClick={() => {
  //                 this.loadConfirmation();
  //                 this.props.banProduct(
  //                   this.props.session,
  //                   data,
  //                   (resource) => {
  //                     this.props.fetchallProductlist(
  //                       this.props.session,
  //                       this.props.currentPage,
  //                       (CallBackResponse) => {
  //                         NotificationManager.success(
  //                           LocaleStrings.common_banFail_success
  //                         );
  //                         onClose();
  //                       }
  //                     );
  //                   }
  //                 );
  //               }}
  //               ref={this.confirmButtonOk}>
  //               OK
  //             </button>
  //             <button type="button" onClick={onClose}>
  //               Cancel
  //             </button>
  //           </div>
  //         </div>
  //       );
  //     },
  //   });
  // };

  // loadConfirmation = () => {
  //   loadConfirmationButton(this.confirmButtonOk);
  // };

  _handleBan(e, values) {
    let { user } = this.props;
    // console.log("values", values);
    var data = {
      id: values.id,
      isactive: 0,
      callfrom: "banproduct",
      bannedby: user.userid,
    };
    confirmAlert({
      title: LocaleStrings.prod_mangement_ban,
      message: LocaleStrings.prod_mangement_ban_message,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            NotificationManager.success(LocaleStrings.common_banFail_success);
            this.props.banProduct(this.props.session, data, (resource) => {
              // window.location.reload();
              this.props.pagination();
              // this.props.fetchallProductlist(
              //   this.props.session,
              //   this.props.currentPage,
              //   (CallBackResponse) => {}
              // );
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  _handleUnBan(e, values) {
    // console.log("values", values);
    var data = { id: values.id, isactive: 1, bannedby: null };
    confirmAlert({
      title: LocaleStrings.prod_mangement_unban_title,
      message: LocaleStrings.prod_mangement_unban_message,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            NotificationManager.success(LocaleStrings.common_unbanFail_success);
            this.props.banProduct(this.props.session, data, (resource) => {
              // window.location.reload();
              this.props.pagination();
              // this.props.fetchallProductlist(
              //   this.props.session,
              //   this.props.currentPage,
              //   (CallBackResponse) => {}
              // );
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }
  _isFeatured(e, values) {
    // console.log("isFeatured", isFeatured);
    var data = { id: values.id, isfeatured: 1, callfrom: "makefeatured" };
    confirmAlert({
      title: "Feature Product",
      message: "Are you sure you want to feature this product",
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.banProduct(this.props.session, data, (resource) => {
              NotificationManager.success("Product Featured Successfully");
              this.props.pagination();
              // this.props.fetchallProductlist(
              //   this.props.session,
              //   this.props.currentPage,
              //   (CallBackResponse) => {}
              // );
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }
  _UnFeatured(e, values) {
    // console.log("isFeatured", isFeatured);
    var data = { id: values.id, isfeatured: 0 };
    confirmAlert({
      title: "UnFeature Product",
      message: "Are you sure you want to unfeature this product",
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.banProduct(this.props.session, data, (resource) => {
              NotificationManager.success("Product UnFeatured Successfully");
              this.props.pagination();
              // this.props.fetchallProductlist(
              //   this.props.session,
              //   this.props.currentPage,
              //   (CallBackResponse) => {}
              // );
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  render() {
    var { values, localCurrency } = this.props;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    var img = `${BASE_IMAGES_URL}/vendor/variations/${values.productimage}?api_key=${APP_API_KEY}`;

    let variantId = values.variantid;
    let priceObj = updateMRP(values, variantId);
    let discountedPrice = priceObj.discountedPrice;
    let tabledate = "";
    tabledate = converDateIntoLocal(values.createdon).format(
      "D MMM YYYY HH:mm A"
    );

    // let product_discountedPrice     = 0.00;
    let productCurrency = values.currency;
    // console.log('productCurrency',productCurrency)
    // product_discountedPrice = discountedPrice;

    let product_discountedPrice = isCurrencyRateApplicable(
      productCurrency,
      discountedPrice
    );

    return (
      <>
        {values.isactive == true ? (
          <>
            <tr className="bg-white border-b-2 border-gray-50 cursor-pointer">
              {/* <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                // onClick={(e) => this.openModal(e, values)}
              >
                <div>
                  {values.isfeatured == 0 ? (
                    <img
                      className="h-4 w-4"
                      onClick={(e) => this._isFeatured(e, values)}
                      src="../images/product_management_images/star.png"
                    ></img>
                  ) : (
                    <img
                      className="h-4 w-4"
                      onClick={(e) => this._UnFeatured(e, values)}
                      src="../images/product_management_images/yellowstar.svg"
                      // className="w-5"
                    />
                  )}
                </div>
              </td> */}
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                style={{ display: "flex" }}
                onClick={(e) => this.openModal(e, values)}>
                <div className="mx-2">
                  <img
                    src={img}
                    style={{ height: "40px", width: "40px" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../images/default.jpeg";
                    }}
                  />
                </div>
                <div>
                  {values.productname_en.length > 35
                    ? values.productname_en.substring(0, 25) + "..."
                    : values.productname_en}
                </div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                onClick={(e) => this.openModal(e, values)}>
                {/* <div>
                  {values.store.username}
            </div> */}
                {values.store.hasOwnProperty("businessname") ? (
                  <div>{values.store.businessname}</div>
                ) : (
                  <div>{values.store.storename}</div>
                )}
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                onClick={(e) => this.openModal(e, values)}>
                <div>{_.startCase(values.saletype)}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                onClick={(e) => this.openModal(e, values)}>
                <div>
                  {" "}
                  {currency} {product_discountedPrice}
                </div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                onClick={(e) => this.openModal(e, values)}>
                <div>{tabledate}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this._handleBan(e, values)}>
                      {LocaleStrings.ban}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            {this.state.isOpen ? (
              <AllitemProductdetailmodal
                isOpen={this.state.isOpen}
                onClose={this.closeModal}
                productDetails={this.props.values}
                qa={this.props.values.qa}
                // all_variants={this.props.values.all_variants}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <tr className="bg-white border-b-2 bg-gray-100 border-gray-50 cursor-pointer">
              {/* <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                // onClick={(e) => this.openModal(e, values)}
              >
                <div>
                  {values.isfeatured == 0 ? (
                    <img
                      className="h-4 w-4"
                      onClick={(e) => this._isFeatured(e, values)}
                      src="../images/product_management_images/star.png"
                    ></img>
                  ) : (
                    <img
                      className="h-4 w-4"
                      onClick={(e) => this._UnFeatured(e, values)}
                      src="../images/product_management_images/yellowstar.svg"
                      // className="w-5"
                    />
                  )}
                </div>
              </td> */}
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                style={{ display: "flex" }}
                onClick={(e) => this.openModal(e, values)}>
                <div className="mx-2">
                  <img src={img} style={{ height: "40px", width: "40px" }} />
                </div>
                <div>
                  {values.productname_en.length > 35
                    ? values.productname_en.substring(0, 25) + "..."
                    : values.productname_en}
                </div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                onClick={(e) => this.openModal(e, values)}>
                {/* <div>
                  {values.store.username}
            </div> */}
                {values.store.hasOwnProperty("businessname") ? (
                  <div>{values.store.businessname}</div>
                ) : (
                  <div>{values.store.storename}</div>
                )}
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                onClick={(e) => this.openModal(e, values)}>
                <div>{_.startCase(values.saletype)}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                onClick={(e) => this.openModal(e, values)}>
                <div>
                  {currency} {product_discountedPrice}
                </div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                onClick={(e) => this.openModal(e, values)}>
                <div>{tabledate}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this._handleUnBan(e, values)}>
                      {LocaleStrings.unban}
                    </button>
                  </div>
                  {!_.isEmpty(values.bannedby) || !values.bannedby == null ? (
                    <div className="ml-3 mt-2">
                      <p>Banned by :- {values.bannedby.username}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </td>
            </tr>
            {this.state.isOpen ? (
              <AllitemProductdetailmodal
                isOpen={this.state.isOpen}
                onClose={this.closeModal}
                productDetails={this.props.values}
                qa={this.props.values.qa}
                // all_variants={this.props.values.all_variants}
              />
            ) : (
              ""
            )}
          </>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  localCurrency: state.currency,
});

export default connect(mapStateToProps, {
  fetchallProductlist,
  banProduct,
  isCurrencyRateApplicable,
})(AllproductItems);
