import LocaleStrings from "../../../languages/index";
import {
  ALLNEWORDER_LIST,
  ALLDELIVEREDORDER_LIST,
  POSITION_TAB,
  IS_MESSAGING_SLIDEOVERS_OPEN,
  ORDER_MESSAGING_DETAILS_LIST,
  ORDER_MESSAGING_STEP,
  ORDER_THREAD_BASICDATA,
  ORDER_THREAD_MESSAGES,
  STORING_SELLERID,
} from "../actions";

export var OrderMangementmainTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    {
      name: LocaleStrings.new,
      href: "new",
      current: true,
      icon: "sales_management",
      position: 1,
    },
    {
      name: LocaleStrings.delivered,
      href: "delivered",
      current: false,
      icon: "sales_management",
      position: 2,
    },
  ];

  return state;
};

export function allneworderlist(state = {}, action) {
  switch (action.type) {
    case ALLNEWORDER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function alldeliveredorderlist(state = {}, action) {
  switch (action.type) {
    case ALLDELIVEREDORDER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}

/**** Add MESSAGES SLIDE-OVERS Modal storage ****/
export var isMessageOpen = (state = false, action) => {
  if (action.type === IS_MESSAGING_SLIDEOVERS_OPEN) {
    state = action.payload;
  }
  return state;
};

// ORDER MESSAGES
export var orderMessageList = (state = {}, action) => {
  if (action.type === ORDER_MESSAGING_DETAILS_LIST) {
    state = action.payload;
  }

  return state;
};

// Initial selected order step is "1"
export var currentOrderStep = (state = 1, action) => {
  if (action.type === ORDER_MESSAGING_STEP) {
    state = action.payload;
  }
  return state;
};

/**** Storage Some basic order thread data [Order id and all as object ] ****/
export var basicOrderThreadData = (state = {}, action) => {
  if (action.type === ORDER_THREAD_BASICDATA) {
    state = action.payload;
  }
  return state;
};

/**** Storage Of Order Thread Messages With Thread id ****/
export var storeOrdMessageList = (state = {}, action) => {
  if (action.type === ORDER_THREAD_MESSAGES) {
    state = action.payload;
  }
  return state;
};
/**** Storage Of Order Thread Messages With Thread id ****/
export var storingsellerid = (state = {}, action) => {
  if (action.type === STORING_SELLERID) {
    state = action.payload;
  }
  return state;
};
