import LocaleStrings from "../../../languages/index";
// Store vendor Product list
import {
  ALL_DASHBOARD_STATS,
  USERS_TODAY_STATS,
  USERS_WEEKS_STATS,
  USERS_MONTH_STATS,
  VENDOR_DAY_STATS,
  VENDOR_WEEKS_STATS,
  VENDOR_MONTH_STATS,
  PRODUCTBASE_DAY_STATS,
  PRODUCTBASE_WEEK_STATS,
  PRODUCTBASE_MONTH_STATS,
  NEWORDER_DAY_STATS,
  NEWORDER_WEEK_STATS,
  NEWORDER_MONTH_STATS,
  ONGOINGORDER_DAY_STATS,
  ONGOINGORDER_WEEK_STATS,
  ONGOINGORDER_MONTH_STATS,
  COMPLETEDORDER_DAY_STATS,
  COMPLETEDORDER_WEEK_STATS,
  COMPLETEDORDER_MONTH_STATS,
  CANCELLEDORDER_DAY_STATS,
  CANCELLEDORDER_WEEK_STATS,
  CANCELLEDORDER_MONTH_STATS,
  REPLACEORDER_DAY_STATS,
  REPLACEORDER_WEEK_STATS,
  REPLACEORDER_MONTH_STATS,
  PENDINGNEGOTIATION_DAY_STATS,
  PENDINGNEGOTIATION_WEEK_STATS,
  PENDINGNEGOTIATION_MONTH_STATS,
  ACCEPTEDNEGOTIATION_DAY_STATS,
  ACCEPTEDNEGOTIATION_WEEK_STATS,
  ACCEPTEDNEGOTIATION_MONTH_STATS,
  REJECTEDNEGOTIATION_DAY_STATS,
  REJECTEDNEGOTIATION_WEEK_STATS,
  REJECTEDNEGOTIATION_MONTH_STATS,
  ACTIVEAUCTION_DAY_STATS,
  ACTIVEAUCTION_WEEK_STATS,
  ACTIVEAUCTION_MONTH_STATS,
  COMPLETEDAUCTION_DAY_STATS,
  COMPLETEDAUCTION_WEEK_STATS,
  COMPLETEDAUCTION_MONTH_STATS,
  PAYMENTS_DAY_STATS,
  PAYMENTS_WEEK_STATS,
  PAYMENTS_MONTH_STATS,
  STATISTICS_UPDATE_DATE_PROPS,
  CHART_VALUES,
  MOSTACTIVE_USERS,
  MOSTACTIVE_PRODUCTS,
  MOSTACTIVE_VENDORS,
} from "../actions";

export function allDasboardStats(state = {}, action) {
  switch (action.type) {
    case ALL_DASHBOARD_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function chartValues(state = {}, action) {
  switch (action.type) {
    case CHART_VALUES:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function mostactiveusers(state = {}, action) {
  switch (action.type) {
    case MOSTACTIVE_USERS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function mostactivevendors(state = {}, action) {
  switch (action.type) {
    case MOSTACTIVE_VENDORS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function mostactiveproducts(state = {}, action) {
  switch (action.type) {
    case MOSTACTIVE_PRODUCTS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allusersToday(state = {}, action) {
  switch (action.type) {
    case USERS_TODAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allusersThisweek(state = {}, action) {
  switch (action.type) {
    case USERS_WEEKS_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allusersThismonth(state = {}, action) {
  switch (action.type) {
    case USERS_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allvendorsToday(state = {}, action) {
  switch (action.type) {
    case VENDOR_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allvendorsThisweek(state = {}, action) {
  switch (action.type) {
    case VENDOR_WEEKS_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allvendorsThismonth(state = {}, action) {
  switch (action.type) {
    case VENDOR_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allproductbaseToday(state = {}, action) {
  switch (action.type) {
    case PRODUCTBASE_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allproductbaseThisweek(state = {}, action) {
  switch (action.type) {
    case PRODUCTBASE_WEEK_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allproductbaseThismonth(state = {}, action) {
  switch (action.type) {
    case PRODUCTBASE_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allnewordersToday(state = {}, action) {
  switch (action.type) {
    case NEWORDER_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allnewordersWeek(state = {}, action) {
  switch (action.type) {
    case NEWORDER_WEEK_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allnewordersMonth(state = {}, action) {
  switch (action.type) {
    case NEWORDER_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allongoingordersToday(state = {}, action) {
  switch (action.type) {
    case ONGOINGORDER_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allongoingordersWeek(state = {}, action) {
  switch (action.type) {
    case ONGOINGORDER_WEEK_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allongoingordersMonth(state = {}, action) {
  switch (action.type) {
    case ONGOINGORDER_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allcompletedordersToday(state = {}, action) {
  switch (action.type) {
    case COMPLETEDORDER_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allcompletedordersWeek(state = {}, action) {
  switch (action.type) {
    case COMPLETEDORDER_WEEK_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allcompletedordersMonth(state = {}, action) {
  switch (action.type) {
    case COMPLETEDORDER_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allcancelledordersToday(state = {}, action) {
  switch (action.type) {
    case CANCELLEDORDER_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allcancelledordersWeek(state = {}, action) {
  switch (action.type) {
    case CANCELLEDORDER_WEEK_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allcancelledordersMonth(state = {}, action) {
  switch (action.type) {
    case CANCELLEDORDER_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allreplacedordersToday(state = {}, action) {
  switch (action.type) {
    case REPLACEORDER_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allreplacedordersWeek(state = {}, action) {
  switch (action.type) {
    case REPLACEORDER_WEEK_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allreplacedordersMonth(state = {}, action) {
  switch (action.type) {
    case REPLACEORDER_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allpendingnegoToday(state = {}, action) {
  switch (action.type) {
    case PENDINGNEGOTIATION_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allpendingnegoWeek(state = {}, action) {
  switch (action.type) {
    case PENDINGNEGOTIATION_WEEK_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allpendingnegoMonth(state = {}, action) {
  switch (action.type) {
    case PENDINGNEGOTIATION_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allacceptednegoToday(state = {}, action) {
  switch (action.type) {
    case ACCEPTEDNEGOTIATION_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allacceptednegoWeek(state = {}, action) {
  switch (action.type) {
    case ACCEPTEDNEGOTIATION_WEEK_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allacceptednegoMonth(state = {}, action) {
  switch (action.type) {
    case ACCEPTEDNEGOTIATION_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allrejectednegoToday(state = {}, action) {
  switch (action.type) {
    case REJECTEDNEGOTIATION_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allrejectednegoWeek(state = {}, action) {
  switch (action.type) {
    case REJECTEDNEGOTIATION_WEEK_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allrejectednegoMonth(state = {}, action) {
  switch (action.type) {
    case REJECTEDNEGOTIATION_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allactiveauctionToday(state = {}, action) {
  switch (action.type) {
    case ACTIVEAUCTION_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allactiveauctionWeek(state = {}, action) {
  switch (action.type) {
    case ACTIVEAUCTION_WEEK_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allactiveauctionMonth(state = {}, action) {
  switch (action.type) {
    case ACTIVEAUCTION_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allcompletedauctionToday(state = {}, action) {
  switch (action.type) {
    case COMPLETEDAUCTION_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allcompletedauctionWeek(state = {}, action) {
  switch (action.type) {
    case COMPLETEDAUCTION_WEEK_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allcompletedauctionMonth(state = {}, action) {
  switch (action.type) {
    case COMPLETEDAUCTION_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allpaymentsToday(state = {}, action) {
  switch (action.type) {
    case PAYMENTS_DAY_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allpaymentsWeek(state = {}, action) {
  switch (action.type) {
    case PAYMENTS_WEEK_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allpaymentsMonth(state = {}, action) {
  switch (action.type) {
    case PAYMENTS_MONTH_STATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}

export var TimeSpanTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.today, href: "#", current: true, tabindex: 1 },
    { name: LocaleStrings.week, href: "#", current: false, tabindex: 2 },
    { name: LocaleStrings.month, href: "#", current: false, tabindex: 3 },

    // { name: LocaleStrings.transaction, href: '#', current: false,tabindex:7 },
  ];

  return state;
};
export var DashboardStatsTab = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.today, href: "#", current: true, tabindex: 1 },
    { name: LocaleStrings.selectdate, href: "#", current: false, tabindex: 2 },

    // { name: LocaleStrings.transaction, href: '#', current: false,tabindex:7 },
  ];

  return state;
};

export function StaticticscommonDatePropsReducer(
  state = { from: null, to: null, enteredTo: null },
  action
) {
  switch (action.type) {
    case STATISTICS_UPDATE_DATE_PROPS:
      return action.payload;
    default:
      return state;
  }
}

export var MonthOptions = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Current Month", value: 0, id: 0 },
    { name: "Last Month", value: 1, id: 1 },
    // { name: "December", value:2 ,id:2 },
    { name: "Last 3 months", value: 3, id: 3 },
    // { name: "October", value:4 ,id:4 },
    // { name: "September", value:5 ,id:5 },
    { name: "Last 6 months", value: 6, id: 6 },
    // { name: "July", value:7 ,id:7 },
    // { name: "June", value:8 ,id:8 },
    // { name: "May", value:9 ,id:9 },
    // { name: "April", value:10 ,id:10 },
    // { name: "March", value:11 ,id:11 },
    { name: "Last 1 year", value: 12, id: 12 },
  ];

  return state;
};
