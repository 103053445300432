import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";
import _ from "lodash";
import TransactionmanagementTabs from "./transactiontabs";

class TransactionManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.order_management,
      pageNumber: 1,
    };

    // All Binded Functions
  }

  componentDidMount() {
    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation.data, {
      href: answer,
    });

    if (singleNavigationRow == undefined) {
      this.props.history.push("/login");
    }
    this.props.history.push("/dashboard/transaction/alltransaction");
  }
  customerdetails = (e, data) => {
    //  console.log("this.props.data", this.props.data)
    this.setState({ showUploadSection: !this.state.showUploadSection });
    //   this.props.data();
  };

  render() {
    return (
      <>
        <TransactionmanagementTabs
          showUploadSection={this.customerdetails}
          history={this.props.history}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, allproductslist } = state;
  return {
    session,
    user,
    navigation: state.sideBarMenus,
  };
}

export default connect(mapStateToProps, {})(TransactionManagement);
