import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";

import { isOpenSpecsManagModal } from "../actions/index";

class SpecificationopenModal extends Component {
  constructor(props) {
    super(props);

    // All Binds Functions
  }

  openDiscountManagementModal() {
    this.props.isOpenSpecsManagModal(true);
  }

  render() {
    return (
      <>
        {/* Header Part */}

        <div className="end-item xs:mt-2 lg:mt-0">
          <button
            onClick={(e) => this.openDiscountManagementModal(e)}
            type="submit"
            className="lg:w-48  xs:w-full  flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
            {LocaleStrings.addnewspec}
            <img
              src="../images/product_management_images/add.svg"
              className="h-5 ml-2"
            />
          </button>
        </div>
      </>
    );
  }
}
// isNegotiationopen

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
  };
}

export default connect(mapStateToProps, {
  isOpenSpecsManagModal,
})(SpecificationopenModal);
