import React, { Component } from "react";
import { connect } from "react-redux";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../../currency/action/index";
import { converDateIntoLocal } from "../../../../../../common/common-functions";
import { statusarray } from "../../../../../../common/constants";
import _ from "lodash";
import { USER_EMAIL } from "../../../../../../actions";

class OngoinOrderitems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    var { values, localCurrency } = this.props;

    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    let tabledate = "";
    tabledate = converDateIntoLocal(values.createdon).format(
      "D MMM YYYY HH:mm A"
    );
    let status_array = statusarray();
    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 cursor-pointer hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.sku}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {currency} {values.ordertotal.toFixed(2)}
          </td>

          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {/* {_.startCase(values.status)} */}
            {_.find(status_array, { name: values.status }).nametoshow}
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    localCurrency: state.currency,
  };
}
export default connect(mapStateToProps, { isCurrencyRateApplicable })(
  OngoinOrderitems
);
