import React, { Component } from "react";
import { connect } from "react-redux";
import { SearchIcon } from "@heroicons/react/solid";
import LocaleStrings from "../../../../languages";
import _ from "lodash";
import BaseLoader from "../../../../../common/base-loader";
import AddSuperAdmin from "./addnewadmin";
import {
  fetchModeratorAdmin,
  searchModeratorAdmin,
  fetchallModeratorrole,
} from "../../actions/index";
import SuperAdminListItems from "./adminlist-items";
import { NotificationContainer } from "react-notifications";
import Pagination from "react-js-pagination";
import AllGlobalTabsList from "../globaltabs";
import { classNames } from "../../../../../common/common-functions";

class AdminManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveredorderSearch: "",
      loaderDesc: LocaleStrings.loaderDec_admin_management,
      loading: true,
      pageNumber: 1,
      search: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchModeratorAdmin(
      this.props.session,
      0,
      (CallBackResponse) => {
        this.props.fetchallModeratorrole(
          this.props.session,
          (CallBackResponse) => {
            this.setState({ loading: false });
          }
        );
      }
    );
  }
  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchModeratorAdmin(
        this.props.session,
        0,
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchModeratorAdmin(this.props.session, search);
    }
    this.setState({ search, pageNumber });
  };

  paginationCallback = (pageNumber) => {
    //  console.log("pgno", pageNumber)
    if (this.state.search == "") {
      this.props.fetchModeratorAdmin(
        this.props.session,
        pageNumber,
        (response) => {}
      );
    } else {
      this.props.searchModeratorAdmin(
        this.props.session,
        this.state.search,
        pageNumber
      );
    }

    this.setState({ ...this.state, pageNumber });
  };

  rendersuperadminlist() {
    var items = _.map(this.props.allAdmin_List.data, (values) => (
      <SuperAdminListItems key={values.userid} values={values} />
    ));
    return items;
  }

  render() {
    let { language } = this.props;
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <AllGlobalTabsList history={this.props.history} />
            <div
              className="grid gap-4 grid-rows-1 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 m-6"
              style={{
                overflowX: "hidden",
                overflowY: "hidden",
                display: "block",
              }}>
              <AddSuperAdmin />
              <div
                className="relative md:w-6/12 xs:w-full text-ternary focus-within:text-secondary smallscreen-search "
                style={{ marginTop: -35 }}>
                <div
                  className={classNames(
                    language == "en" ? "left-0 " : "right-0",
                    "absolute inset-y-0 flex items-center pointer-events-none px-1"
                  )}>
                  <SearchIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className={classNames(
                    language == "en" ? "pl-8 pr-3" : "pr-8 pl-3",
                    "block lg:w-6/12 xs:w-full sm:w-full h-full  py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                  )}
                  placeholder={LocaleStrings.search}
                  type="search"
                  name="search"
                  value={this.state.search}
                  onChange={this.handleSearch}
                />
              </div>
              <div className="panel-body mt-6">
                {/* <TableContent /> */}
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.name}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.email}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.phonenumber}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.action}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.rendersuperadminlist()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Pagination
                activePage={this.state.pageNumber}
                itemsCountPerPage={7}
                totalItemsCount={this.props.allAdmin_List.count}
                pageRangeDisplayed={10}
                onChange={this.paginationCallback}
              />
              <NotificationContainer />
            </div>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, language } = state;

  return {
    session,
    user,
    language,
    allAdmin_List: state.allAdmin_List,
  };
}

export default connect(mapStateToProps, {
  fetchModeratorAdmin,
  searchModeratorAdmin,
  fetchallModeratorrole,
})(AdminManagement);
