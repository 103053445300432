import React, { Component } from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../common/base-component";
import _ from "lodash";
import { timeAgo } from "../../../../common/common-functions";
import renderHTML from "react-render-html";

class FeedListItems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openModal = (e, obj) => {
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  render() {
    var { values } = this.props;
    // console.log("check", values);

    if (values.type == "purchased") {
      var feed_content = (
        <>
          <div>
            <span>{values.user.username}</span> {values.type}{" "}
            <span>{values.product.productname_en}</span>
          </div>
          <div className="text-ternary text-xs mt-2">
            {timeAgo(values.createdat)}
          </div>
        </>
      );
    } else if (values.type == "newupload") {
      var feed_content = (
        <>
          <div>
            <span>{values.user.username}</span> created new product{" "}
            <span>{values.product.productname_en}</span>
          </div>
          <div className="text-ternary text-xs mt-2">
            {timeAgo(values.createdat)}
          </div>
        </>
      );
    } else if (values.type == "following") {
      var feed_content = (
        <>
          <div>
            <span>{values.user.username}</span>started {values.type}{" "}
            <span>{values.followed.username}</span>
          </div>
          <div className="text-ternary text-xs mt-2">
            {timeAgo(values.createdat)}
          </div>
        </>
      );
    } else if (values.type == "comment") {
      var feed_content = (
        <>
          <div>
            <span>{values.user.username}</span> mentioned{" "}
            {renderHTML(values.commentdata.comment)} on{" "}
            <span>{values.commentdata.product.productname_en}</span>
          </div>
          <div className="text-ternary text-xs mt-2">
            {timeAgo(values.createdat)}
          </div>
        </>
      );
    } else if (values.type == "liked") {
      var feed_content = (
        <>
          <div>
            <span>{values.user.username}</span> {values.type}{" "}
            <span>{values.likedata.product.productname_en}</span>
          </div>
          <div className="text-ternary text-xs mt-2">
            {timeAgo(values.createdat)}
          </div>
        </>
      );
    } else if (values.type == "watchlist") {
      var feed_content = (
        <>
          <div>
            {/* {values.user.username} {values.type} a {values.likedata.product.productname_en} */}
            'watchlist'
          </div>
          <div className="text-ternary text-xs mt-2">
            {timeAgo(values.createdat)}
          </div>
        </>
      );
    } else if (values.type == "wishlist") {
      var feed_content = (
        <>
          <div>
            <span>{values.user.username}</span> created{" "}
            {values.wishlistdata.privacy} {values.type} named{" "}
            <span>{values.wishlistdata.name}</span>
          </div>
          <div className="text-ternary text-xs mt-2">
            {timeAgo(values.createdat)}
          </div>
        </>
      );
    } else if (values.type == "coupon") {
      var feed_content = (
        <>
          <div>
            <span>{values.user.username}</span> created coupon{" "}
            <span>{values.coupondata.title}</span>
          </div>
          <div className="text-ternary text-xs mt-2">
            {timeAgo(values.createdat)}
          </div>
        </>
      );
    } else if (values.type == "discount") {
      var feed_content = (
        <>
          <div>
            <span>{values.user.username}</span> created discount{" "}
            <span> {values.discountdata.title}</span>
          </div>
          <div className="text-ternary text-xs mt-2">
            {timeAgo(values.createdat)}
          </div>
        </>
      );
    }
    return (
      <>
        <div
          className="flow-root px-10 py-5 bg-custWhite rounded mb-5"
          id={this.props.key}>
          {feed_content}
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
  };
}

export default connect(mapStateToProps, {})(FeedListItems);
