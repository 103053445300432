import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index.js";
import { Field, reduxForm } from "redux-form";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import AllGlobalTabsList from "../globaltabs";

class PaymentKeys extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      setOpen: false,
    };
  }

  /***** Form Submit *****/
  onFormSubmit = (values) => {};

  render() {
    var { data, initialValues, handleSubmit } = this.props;
    // console.log("data", data);
    return (
      <>
        <AllGlobalTabsList history={this.props.history} />
        <div className="m-6">
          <form className=" " onSubmit={handleSubmit(this.onFormSubmit)}>
            <div className="flow-root px-10 py-5 bg-custWhite rounded">
              <div className="pb-10">
                <p>{LocaleStrings.gatewayname}</p>
              </div>
              <div className="pb-10">
                {" "}
                <Field
                  name="test"
                  label="Keys"
                  type="text"
                  component={this.renderFieldcustomer}
                  mandatory="false"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                />
              </div>
              <div className="pb-6">
                <p>{LocaleStrings.gatewayadminlink}</p>
              </div>
              <div className="pb-10">
                <p>{LocaleStrings.gatewaybigtext}</p>
              </div>
              <div className="center-item mt-5 pb-10">
                <button type="submit" className="button">
                  {LocaleStrings.updategateway}
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // console.log('ownProps.data', ownProps.data);
  return {
    session: state.session,
    data: ownProps.data,
    initialValues: ownProps.data,
  };
}

export default connect(
  mapStateToProps,
  {}
)(
  reduxForm({
    form: "LegalDocumentForm4",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(PaymentKeys)
);
