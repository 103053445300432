import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import {
  addnewSeller,
  fetchSeller,
  uploadMediaLargeSellerDocs,
  emaildbCheck,
} from "../actions/index";
// import { showError, showSuccess } from "ReduxErrorBar";
import {
  DATE_FORMAT_WITH_TIME_WITH_SECOND,
  validateEmail,
} from "../../../../common/constants";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../common/base-component";
import moment from "moment-timezone";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  classNames,
  isValidQatarPhoneNumber,
} from "../../../../common/common-functions";
import { countryPhoneLength } from "../../../../actions/index.js";

var threeArr = [{}, {}, {}];
// Constant
let validateExtention = ["doc", "docm", "docx", "docx", "pdf"];

const gender = [
  { name: "Rather NOT SAY", value: "", index: 0 },
  { name: "MALE", value: "male", index: 1 },
  { name: "FEMALE", value: "female", index: 2 },
];
const typeofbusiness = [
  { name: "Company", value: "company", index: 0 },
  { name: "Home Business", value: "homebiz", index: 1 },
  { name: "Personal Business", value: "personal", index: 2 },
];

class SearchNewSeller extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      genderOptions: gender,
      typeofbusiness: typeofbusiness,
      show: false,
      show1: false,
      business: "company",
      btnLoader: false,
      phone: "",
      country: "",
    };

    // All Binded Functions
    this.changeShow = this.changeShow.bind(this);
    this.changeShow1 = this.changeShow1.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }
  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
    this.props.reset();
  }

  changeShow() {
    let { show } = this.state;
    this.setState({ show: !show });
  }
  changeShow1() {
    let { show1 } = this.state;
    this.setState({ show1: !show1 });
  }

  onSubmitForm(values) {
    if (!values.hasOwnProperty("phone") || values.phone == "") {
      NotificationManager.error("Phone No is required");
      return false;
    }
    if (values.password.length < 6 || values.confirmpassword < 6) {
      NotificationManager.error("Minimum 6 character required for password");
      return false;
    }
    let callfrom = "verfied";
    if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/unverified"
    ) {
      callfrom = "unverfied";
    } else if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/verficationrequested"
    ) {
      callfrom = "verficationrequested";
    }
    this.setState({ btnLoader: true });
    if (Object.keys(values).length === 0) {
      NotificationManager.error(LocaleStrings.common_mandatory_required);
      return false;
    }
    if (_.trim(values.confirmpassword) !== _.trim(values.password)) {
      NotificationManager.error(LocaleStrings.common_password_Error);
      // this.setState({ open: false });
      return false;
    }
    this.props.emaildbCheck(values.email, (response) => {
      if (response.success === 1) {
        NotificationManager.error(LocaleStrings.common_email_Exists);
        return false;
      } else {
        if (Object.keys(threeArr[1]).length === 0) {
          let dob = null;
          if (
            this.state.business == "personal" &&
            values.hasOwnProperty("dob") &&
            (values.dob !== null || values.dob !== "")
          ) {
            dob = moment(values.dob).format(DATE_FORMAT_WITH_TIME_WITH_SECOND);
          }
          var sellerdata = {
            email: values.email,
            name: values.username,
            dob: dob,
            display_name: values.username,
            first_name: values.username,
            gender: values.gender,
            last_name: "",
            new_password: values.password,
            usertype:
              this.state.business == "company" ? "vendor" : this.state.business,
            phone: values.phone.replace(/[- )( +]/g, ""),
            businessname: values.businessname,
            businesstype: values.businesstype,
            source: "superadmin",
            sauser: true,
          };

          this.props.addnewSeller(
            this.props.session,
            sellerdata,
            (response) => {
              if (response.success === 0) {
                this.setState({ loading: false });

                NotificationManager.error(LocaleStrings.addingfailed);
              } else {
                this.setState({ open: false });
                this.props.fetchSeller(
                  this.props.session,
                  0,
                  callfrom,
                  (CallBackResponse) => {}
                );
                NotificationManager.success(LocaleStrings.addedsucessfully);
              }
            }
          );
        } else {
          // Upload Section
          let uploaderCounter = 0;
          let filetobeupload = [];
          let arrPromise = [];

          // Blank File Name array

          let commercial_reg_doc = "";
          let agreement_template = "";
          let idproof = "";

          _.forEach(threeArr, function (value, index) {
            if (Object.keys(value).length > 0) {
              if (index === 0) {
                commercial_reg_doc = value.name;
              }

              if (index === 1) {
                agreement_template = value.name;
              }
              if (index === 2) {
                idproof = value.name;
              }
              let coverJson = {
                type: value.type,
                name: value.name,
                media: value.url,
              };

              filetobeupload.push(coverJson);
            }
          });

          let counter = filetobeupload.length;

          filetobeupload.map((item, index) => {
            arrPromise.push(
              new Promise((resolve, reject) => {
                this.props.uploadMediaLargeSellerDocs(
                  this.props.session,
                  item,
                  (response) => {
                    if (response.success === 0) {
                      resolve();
                    } else {
                      uploaderCounter++;
                      resolve();
                    }
                  }
                );
              })
            );
          });
          Promise.all(arrPromise).then(() => {
            if (uploaderCounter === counter) {
              // Store Object
              if (this.state.business == "homebiz") {
                var sellerdata = {
                  email: values.email,
                  name: values.username,
                  dob: null,
                  first_name: values.username,
                  last_name: "",
                  new_password: values.password,
                  usertype:
                    this.state.business == "company"
                      ? "vendor"
                      : this.state.business,
                  gender: values.gender,
                  phone: values.phone.replace(/[- )( +]/g, ""),
                  idproof: idproof,
                  agreement_template: agreement_template,
                  businessname: values.businessname,
                  businesstype: values.businesstype,
                  sauser: true,
                };
              } else {
                var sellerdata = {
                  email: values.email,
                  name: values.username,
                  dob: null,
                  first_name: values.username,
                  last_name: "",
                  new_password: values.password,
                  usertype:
                    this.state.business == "company"
                      ? "vendor"
                      : this.state.business,
                  gender: values.gender,
                  phone: values.phone.replace(/[- )( +]/g, ""),
                  commercial_reg_doc: commercial_reg_doc,
                  agreement_template: agreement_template,
                  businessname: values.businessname,
                  businesstype: values.businesstype,
                  sauser: true,
                };
              }

              // console.log('sellerdata',sellerdata)
              // After file upload
              this.props.addnewSeller(
                this.props.session,
                sellerdata,
                (response) => {
                  if (response.success === 0) {
                    this.setState({ loading: false });
                    NotificationManager.error(LocaleStrings.addingfailed);
                  } else {
                    threeArr[0] = {};
                    this.setState({ open: false });
                    this.props.fetchSeller(
                      this.props.session,
                      0,
                      callfrom,
                      (CallBackResponse) => {}
                    );
                    NotificationManager.success(LocaleStrings.addedsucessfully);
                  }
                }
              );
            } else {
              NotificationManager.error(LocaleStrings.addingfailed);
              this.setState({ open: false });
            }
          });
        }
      }
    });
  }

  _handleSelect(event, typeindex) {
    const reader = new FileReader(); // Define reader
    let file = event.target.files[0]; // Take Selected file
    reader.readAsArrayBuffer(file); // Read the file

    // On File Load
    reader.onload = (e) => {
      file.url = e.target.result;
      // file.name   = file.name;
      // file.url = reader.result;
      //filearr.push(file);
    };

    let ext = file.name.split(".").pop(); // File extension

    if (_.indexOf(validateExtention, ext) === -1) {
      // Validate File Extensions
      NotificationManager.error(
        LocaleStrings.upload_only_doc_and_pdf_file_error_text
      );
    } else {
      this.props.autofill(typeindex, file.name);
      if (typeindex === "commercial_reg_doc") {
        threeArr[0] = file;
      } else if (typeindex === "agreement_template") {
        threeArr[1] = file;
      } else if (typeindex === "idproof") {
        threeArr[2] = file;
      }
    }
  }

  onbusinesstypeChange(e) {
    var newbusinesstype = e.target.value;
    this.setState({ business: newbusinesstype });
  }

  phoneChange = (value, country, e, formattedValue) => {
    this.setState({ phone: value, country: country.dialCode });
  };

  render() {
    var { handleSubmit } = this.props;
    // console.log('history',this.props.history)
    return (
      <div className="" style={{ overflowX: "hidden" }}>
        <div className="flex flex-wrap content-center justify-end ">
          <button
            onClick={this.openModal}
            type="submit"
            className="lg:w-48 md:w-44 sm:w-44 xs:w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
            {LocaleStrings.addseller}
            <img
              src="../images/product_management_images/add.svg"
              className="h-5 ml-2"
            />
          </button>
          <Transition.Root show={this.state.open} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed z-10 inset-0 overflow-y-auto"
              open={this.state.open}
              onClose={this.closeModal}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                  <div
                    className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                    style={{ padding: 0 }}>
                    <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                      <div className="flex flex-wrap content-center justify-start pl-4 mt-4">
                        <div className="text-md leading-6 font-medium text-secondary">
                          {LocaleStrings.seller_management_addSeller}
                        </div>
                      </div>
                      <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                        <button
                          type="button"
                          className="btn-closeModal"
                          onClick={this.closeModal}>
                          <span className="sr-only">{LocaleStrings.close}</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <form
                      className=" p-6"
                      onSubmit={handleSubmit(this.onSubmitForm)}
                      encType="multipart/form-data">
                      {/* <Addseller {...this.props} /> */}

                      <Field
                        name="email"
                        label="Email"
                        mandatory="true"
                        placeholder={LocaleStrings.email}
                        type="email"
                        component={this.renderAllfields}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                      <div className="mt-5">
                        <Field
                          name="username"
                          label="Name"
                          mandatory="true"
                          placeholder={LocaleStrings.name}
                          type="text"
                          component={this.renderAllfields}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                      <div className="mt-5">
                        <Field
                          name="businessname"
                          label={
                            this.state.business == "personal"
                              ? "Nickname"
                              : "Businessname"
                          }
                          placeholder={
                            this.state.business == "personal"
                              ? "Nickname"
                              : "Businessname"
                          }
                          type="text"
                          mandatory="true"
                          component={this.renderAllfields}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                      <div className="mt-5">
                        <Field
                          name={"phone"}
                          label={"Phone Number"}
                          mandatory="true"
                          type="text"
                          component={this.renderPhonewithFlag}
                          className="cust-input-field"
                          classNameLabel="text-box-lable"
                          phone={this.state.phone}
                          phoneChange={this.phoneChange}
                        />
                      </div>
                      <div className="mt-5">
                        <Field
                          name="password"
                          label="Password"
                          placeholder={LocaleStrings.password}
                          type="password"
                          mandatory="true"
                          show={this.state.show}
                          setShow={this.changeShow}
                          component={this.renderAllfields}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                      <div className="mt-5">
                        <Field
                          name="confirmpassword"
                          label="Confirm Password"
                          placeholder={LocaleStrings.confirm_password}
                          type="password"
                          mandatory="true"
                          show={this.state.show1}
                          setShow={this.changeShow1}
                          component={this.renderAllfields}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                      <div className="mt-5">
                        <p>Gender</p>
                        {_.map(this.state.genderOptions, (opt, index) => {
                          return (
                            <Field
                              name="gender"
                              label={opt.name}
                              value={opt.value}
                              //checked={index == 0 ? true : false}
                              //radioOnchange={radioOnchange}
                              lebelAlign="R"
                              type="radio"
                              component={this.renderFieldRadio1}
                            />
                          );
                        })}
                      </div>
                      <div className="mt-5">
                        <p>
                          {LocaleStrings.seller_management_add_businesstype}
                        </p>
                        {_.map(this.state.typeofbusiness, (opt, index) => {
                          return (
                            <Field
                              name="businesstype"
                              label={opt.name}
                              value={opt.value}
                              //checked={index == 0 ? true : false}
                              //radioOnchange={radioOnchange}
                              onChange={this.onbusinesstypeChange.bind(this)}
                              lebelAlign="R"
                              type="radio"
                              component={this.renderFieldRadio1}
                            />
                          );
                        })}
                      </div>
                      {this.state.business !== "personal" ? (
                        <div className="mt-5">
                          {this.state.business === "company" ? (
                            <div>
                              <Field
                                name="commercial_reg_doc"
                                label="Commercial Registration Document"
                                placeholder="Commercial Registration Document"
                                type="text"
                                component={this.renderFieldText}
                                mandatory="false"
                                isdisabled={true}
                                labelposition={LABEL_POSITION_TOP}
                                classNameLabel="text-sm font-medium text-secondary"
                              />

                              <div className="flex justify-end">
                                <label
                                  htmlFor="file-upload-commercial_reg_doc"
                                  className="relative btn-primary-outline mt-2 cursor-pointer flex border-2 border-gray-200 rounded p-2">
                                  <img
                                    className={"h-4 w-4 "}
                                    src={"../images/attachfile.svg"}
                                  />
                                  <span>{LocaleStrings.attach_files}</span>
                                  <input
                                    id="file-upload-commercial_reg_doc"
                                    name="file-upload-commercial_reg_doc"
                                    type="file"
                                    className="sr-only"
                                    onChange={(e) =>
                                      this._handleSelect(
                                        e,
                                        "commercial_reg_doc"
                                      )
                                    }
                                  />
                                </label>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <Field
                                name="idproof"
                                label="Valid Id Proof"
                                placeholder="Valid Id Proof"
                                type="text"
                                component={this.renderFieldText}
                                mandatory="false"
                                isdisabled={true}
                                labelposition={LABEL_POSITION_TOP}
                                classNameLabel="text-sm font-medium text-secondary"
                              />

                              <div className="flex justify-end">
                                <label
                                  htmlFor="file-upload-idproof"
                                  className="relative btn-primary-outline mt-2 cursor-pointer flex border-2 border-gray-200 rounded p-2">
                                  <img
                                    className={"h-4 w-4 "}
                                    src={"../images/attachfile.svg"}
                                  />
                                  <span>{LocaleStrings.attach_files}</span>
                                  <input
                                    id="file-upload-idproof"
                                    name="file-upload-idproof"
                                    type="file"
                                    className="sr-only"
                                    onChange={(e) =>
                                      this._handleSelect(e, "idproof")
                                    }
                                  />
                                </label>
                              </div>
                            </div>
                          )}

                          <div className="mt-5">
                            <Field
                              name="agreement_template"
                              label="Filled and signed agreement template"
                              placeholder="Filled and signed agreement template"
                              type="text"
                              component={this.renderFieldText}
                              mandatory="false"
                              isdisabled={true}
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-sm font-medium text-secondary"
                            />

                            <div className="flex justify-end">
                              <label
                                htmlFor="file-upload-agreement_template"
                                className="relative btn-primary-outline mt-2 cursor-pointer flex border-2 border-gray-200 rounded p-2">
                                <img
                                  className={"h-4 w-4 "}
                                  src={"../images/attachfile.svg"}
                                />
                                <span>{LocaleStrings.attach_files}</span>
                                <input
                                  id="file-upload-agreement_template"
                                  name="file-upload-agreement_template"
                                  type="file"
                                  className="sr-only"
                                  onChange={(e) =>
                                    this._handleSelect(e, "agreement_template")
                                  }
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-5  border-none">
                          <Field
                            name="dob"
                            label={LocaleStrings.dateofbirth}
                            component={this.renderDatePickerWithLabel1}
                            mandatory={false}
                            autoComplete="off"
                            // selected={moment()}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            classNameLabel=""
                          />
                        </div>
                      )}

                      <div className="pt-5">
                        <div className="flex justify-end">
                          <button
                            onClick={this.closeModal}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {LocaleStrings.cancel}
                          </button>
                          <button
                            type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <svg
                              className={classNames(
                                this.state.btnLoader ? "" : "sr-only",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.addseller}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    );
  }
}

function validate(values, ownProps) {
  // console.log("values", values);
  var errors = {};

  var email = values["email"];
  var username = values["username"];

  var password = values["password"];
  var new_password = values["new_password"];
  var businessname = values["businessname"];

  if (!username || username.trim() === "") {
    errors["username"] = "Required";
  }
  if (!businessname || businessname.trim() === "") {
    errors["businessname"] = "Required";
  }
  if (!email || email.trim() === "") {
    errors["email"] = "Required";
  }

  if (values.email) {
    if (!validateEmail(values.email)) {
      errors.email = "Invalid email";
    }
  }

  if (!password || password.trim() === "") {
    errors["password"] = "Required";
  }
  if (!new_password || new_password.trim() === "") {
    errors["new_password"] = "Required";
  }

  let countrycode = ownProps.phoneLengthCountry;

  if (countrycode == "974" && values.phone) {
    let phone = values.phone.replace(/[- )( +]/g, "");
    if (isValidQatarPhoneNumber("+" + phone) == false) {
      errors.phone = "Please enter valid phone number";
    }
  }

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log('check', session)
  var initVals = { gender: "", businesstype: "company" };
  return {
    session,
    user,
    initialValues: initVals,
    phoneLengthCountry: state.phoneLengthCountry,
  };
}

// export default reduxForm({
//   validate,
//   form: "SellerAddingform",
// })(
//   connect(mapStateToProps, {
//     addnewSeller,
//     uploadMediaLargeSellerDocs,
//     fetchSeller,
//     emaildbCheck
//   })(SearchNewSeller)
// );

export default connect(mapStateToProps, {
  addnewSeller,
  uploadMediaLargeSellerDocs,
  fetchSeller,
  emaildbCheck,
  countryPhoneLength,
})(
  reduxForm({
    validate,
    form: "SellerAddingform",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(SearchNewSeller)
);
