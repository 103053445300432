import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "./../../../../languages/index";
import MostactiveProduct from "./mostactiveproducts/index";
import MostactiveUser from "./mostactiveuser/index";
import MostactiveVendor from "./mostactivevendor/index";

class MostActiveandsold extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.dashboard,
    };

    // All Binded Functions
  }

  componentDidMount() {}

  render() {
    let { allDasboardStats } = this.props;
    return (
      <>
        <div className="grid lg:grid-cols-1 xs:grid-cols-1">
          <div className="flow-root px-6 py-3 h-96 bg-custWhite rounded ml-2 mb-4 boxes">
            {/* <p className=" text-lg font-medium ">
              {LocaleStrings.dashboard_mostActive_user}
              </p> */}
            <MostactiveUser />
            {/* <p className="text-ternary py-6  text-md font-medium">
               {this.props.allDasboardStats.most.users}
              </p> */}
          </div>
          <div className="flow-root px-6 py-3 h-96 bg-custWhite rounded ml-2 mb-4 boxes">
            <MostactiveVendor />
            {/* <p className=" text-lg text-lgfont-medium ">
              {LocaleStrings.dashboard_mostActive_vendor}
              </p>
              <p className="text-ternary py-6  text-md font-medium ">
              {this.props.allDasboardStats.most.vendors}
              </p> */}
          </div>
          <div className="flow-root px-6 py-3 h-96 bg-custWhite rounded ml-2 mb-4 boxes">
            <MostactiveProduct />
            {/* <p className="text-lg font-medium ">
                {LocaleStrings.dashboard_mostsold_products}
              </p>
              <p className="text-ternary py-6  text-md font-medium">
              {this.props.allDasboardStats.most.products}
              </p> */}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log("allDasboardStats", state.allDasboardStats);
  return {
    session,
    user,
    // allDasboardStats:state.allDasboardStats
    allDasboardStats: state.allDasboardStats
      ? state.allDasboardStats.data
        ? state.allDasboardStats.data
        : []
      : [],
  };
}

export default connect(mapStateToProps, {})(MostActiveandsold);
