import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "./../../../../languages/index";
import { Field, reduxForm } from "redux-form";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import _ from "lodash";
import { fetchChartValues } from "../../actions/index";
import BarChartJS from "./chart";
import StatisticLoader from "../../../../../common/statisticsLoader";
import { Chart } from "react-google-charts";

class DashboardStatistics extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaderDesc: "Preparing Statistics",
      selected: null,
    };

    // All Binded Functions
    // this.onSubmitForm = this.onSubmitForm.bind(this);
  }
  _handleMonthChange = (e) => {
    if (!e.target.value) {
      // console.log('hgi')
    } else {
      this.setState({ loading: true });
      this.setState({ selected: e.target.value });
      let range = e.target.value;

      this.props.fetchChartValues(
        this.props.session,
        range,
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    }
  };

  render() {
    var { handleSubmit } = this.props;

    var monthOptions = []; // Add Area

    //Discount Area Object Creation
    _.forEach(this.props.MonthOptions, function (value) {
      var obj = {
        name: value.name,
        value: value.value,
      };

      monthOptions.push(obj);
    });
    if (!_.isEmpty(this.props.chartValues.data)) {
      // var sold = this.props.chartValues.data.sold[0].count;
    }
    return (
      <div class="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 ">
        <div class="box-border  w-full bg-custWhite mb-5">
          <div class=" grid grid-cols-3 grid-flow-col gap-4 pl-3 mt-1">
            <div className="text-ternary  group flex items-center px-3 py-2 text-sm font-medium rounded-md">
              {" "}
              {LocaleStrings.statistics}
            </div>
            <div> </div>
            <div>
              <form
                className=" p-6"
                // onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
              >
                <div className="mb-5">
                  <Field
                    name="range"
                    placeholder="Select Month"
                    component={this.renderSelect}
                    labelposition={LABEL_POSITION_TOP}
                    opts={monthOptions}
                    className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                    classNameLabel="block text-box-lable"
                    onChange={this._handleMonthChange.bind(this)}
                    // value={this.props.initialValues.type}
                  />
                </div>
              </form>
            </div>
          </div>
          {this.state.loading ? (
            <>
              <StatisticLoader data={this.state.loaderDesc} />
            </>
          ) : (
            // <BarChartJS chartValues={this.props.chartValues}/>
            <>
              {_.isEmpty(this.props.chartValues.data) ? (
                <>
                  <div className="center-item lg:my-20 text-lg text-ternary">
                    {LocaleStrings.dashboard_stats_select}
                  </div>
                </>
              ) : _.isEmpty(this.props.chartValues.data.listed) &&
                _.isEmpty(this.props.chartValues.data.sold) ? (
                <div className="center-item lg:my-20 text-lg text-ternary">
                  {LocaleStrings.dashboard_stats_noData}
                </div>
              ) : (
                <>
                  <BarChartJS />
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log('chartValues',state.chartValues)
  // initVals = {};
  // var initval['range'] = 0;

  return {
    session,
    user,
    MonthOptions: state.MonthOptions,
    chartValues: state.chartValues,
  };
}

export default connect(mapStateToProps, {
  fetchChartValues,
})(
  reduxForm({
    // validate,
    form: "DashboardStatisticsForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(DashboardStatistics)
);
