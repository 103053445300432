import { INSTANCE_URL } from "../../../../common/constants";
import {
  getRequest,
  postRequest,
  patchRequest,
} from "../../../../common/network-call";
import LocaleStrings from "../../../languages/index";
import _ from "lodash";

export var SPECS_LIST = "SPECS_LIST";

// Data
export const SPECSMANAGEMENT_PRODUCTCATEGORY =
  "SPECSMANAGEMENT_PRODUCTCATEGORY";
export const SPECSMANAGEMENT_PRODUCTSUBCATEGORY =
  "SPECSMANAGEMENT_PRODUCTSUBCATEGORY";
export const SPECSMANAGEMENT_PRODUCTSUBSUBCATEGORY =
  "SPECSMANAGEMENT_PRODUCTSUBSUBCATEGORY";
export const SPECSMANAGEMENT_PRODUCTLIST = "SPECSMANAGEMENT_PRODUCTLIST";
export const SPECS_MASTER_SINGLE = "SPECS_MASTER_SINGLE";

// Modal
export const IS_SPECSMANAGEMENT_MODAL_OPEN = "IS_SPECSMANAGEMENT_MODAL_OPEN";
export const EDIT_SPECS = "EDIT_SPECS";

// export function fetchSpecification(session, pageCount = 0, callback) {
//   var perPage = 7;
//   var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
//   var offSetQuery = `&limit=${perPage}&offset=${offset}`;
//   var url = `${INSTANCE_URL}/api/v2/shopez/_table/specifications?fields=*&related=productcategory_by_categoryids,productsubcategory_by_subcategoryids&include_count=true&${offSetQuery}`;
//   // console.log(url)
//   return (dispatch) => {
//     getRequest(
//       url,
//       session,
//       dispatch,
//       (data) => {
//         console.log('fetchSpecification', data);
//         dispatch({
//           type: SPECS_LIST,
//           payload: { data: data.resource, count: data.meta.count },
//         });
//         callback({ success: 1, data: data });
//       },
//       (error) => {}
//     );
//   };
// }

/**** Fetch Product Category data  ****/
export function fetchProductCategory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productcategory?filter=(isactive=1)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SPECSMANAGEMENT_PRODUCTCATEGORY,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Product Subcategory Data ****/
export function fetchProductSubcategory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productsubcategory?filter=(isactive=1)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SPECSMANAGEMENT_PRODUCTSUBCATEGORY,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}
/**** Fetch Product Subcategory Data ****/
export function fetchSubSubcategory(session, callback) {
  var filter = encodeURI(`(isactive=1)AND(parentid is not null)`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productsubcategory?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // dispatch({
        //   type: SPECSMANAGEMENT_PRODUCTCATEGORY,
        //   payload: { data: response.resource },
        // });

        // if(response.res)

        // dispatch({
        //   type: SPECSMANAGEMENT_PRODUCTSUBCATEGORY,
        //   payload: { data: response.resource },
        // });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Cat & Subcat Data Together ****/
export function fetchspecsallCategory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/productmanagement/categorylist`;

  return (dispatch) => {
    postRequest(
      url,
      {},
      session,
      dispatch,
      (response) => {
        let subcateArr = [];

        _.forEach(response.resource, (val, index) => {
          if (
            val.productsubcategory_by_categoryid &&
            val.productsubcategory_by_categoryid.length > 0
          ) {
            _.forEach(val.productsubcategory_by_categoryid, (v, i) => {
              subcateArr.push(v);
            });
          }
        });
        // console.log("subcateArr", subcateArr);

        dispatch({
          type: SPECSMANAGEMENT_PRODUCTCATEGORY,
          payload: { data: response.resource },
        });
        dispatch({
          type: SPECSMANAGEMENT_PRODUCTSUBCATEGORY,
          payload: { data: subcateArr },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

export function fetchProductsByCateSubCate(
  session,
  selected_categoryid,
  selected_subcategoryid,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/listproductsbycategory`;

  if (
    (selected_categoryid == null || selected_categoryid == "") &&
    (selected_subcategoryid == null || selected_subcategoryid == "")
  ) {
    return (dispatch) => {
      dispatch({
        type: SPECSMANAGEMENT_PRODUCTLIST,
        payload: {},
      });
    };
  } else {
    if (selected_subcategoryid == null || selected_subcategoryid == "") {
      var object = {
        categoryid: selected_categoryid,
        discount: 1,
      };
    } else {
      var object = {
        categoryid: selected_categoryid,
        subcategoryid: selected_subcategoryid,
      };
    }

    // Actual Data Call
    return (dispatch) => {
      postRequest(
        url,
        object,
        session,
        dispatch,
        (response) => {
          // console.log("response,", response);
          dispatch({
            type: SPECSMANAGEMENT_PRODUCTLIST,
            payload: { data: response.data },
          });

          callback({ status: 1, message: LocaleStrings.api_success_status });
        },
        (error) => {
          callback({ status: 0, message: LocaleStrings.something_went_wrong });
        }
      );
    };
  }
}

/**** Is Open Discount management modal ****/
export function isOpenSpecsManagModal(isOpen) {
  return { type: IS_SPECSMANAGEMENT_MODAL_OPEN, payload: isOpen };
}

// Add New Specification

export function addnewSpecification(session, specificationadded, callback) {
  // console.log('specificationadded',specificationadded);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/specifications`;
  let body = { resource: { ...specificationadded } };
  // console.log("body", body);
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

export function Editspecification(editspecs) {
  return (dispatch) => {
    dispatch({ type: EDIT_SPECS, payload: editspecs });
  };
}

export function getSelectedSpecsObj(specsObj = {}, session) {
  if (Object.keys(specsObj).length > 0) {
    console.log("specsObj1", specsObj);
    debugger;
    specsObj["isglobal"] = specsObj.isglobal;
    specsObj["language"] = "0";
    // console.log('specsObj.isglobal',specsObj.isglobal)
    if (specsObj.ismandatory == "no") {
      specsObj["ismandatory"] = "Yes";
    } else if (specsObj.ismandatory == "yes") {
      specsObj["ismandatory"] = "No";
    }
    if (specsObj.isglobal == "no") {
      specsObj["isglobal"] = "Yes";
    } else if (specsObj.isglobal == "yes") {
      specsObj["isglobal"] = "No";
    }

    specsObj["idsubcategory"] = specsObj.subcategoryids;
    // console.log('specsObj',specsObj.categories)
    let categoryids = [];

    _.forEach(specsObj.categories, function (value) {
      var obj = {
        label: value.categoryname_en,
        value: value.id.toString(),
      };

      categoryids.push(obj);
    });

    specsObj["idcategory"] = categoryids;

    let subcategoryids = [];
    _.forEach(specsObj.subcategories, function (value) {
      var obj = {
        label: value.subcategoryname_en,
        value: value.id.toString(),
      };

      subcategoryids.push(obj);
    });
    specsObj["idsubcategory"] = subcategoryids;

    // value formation for renderautocomplete
    let value = [];
    if (
      specsObj.value_en !== "" &&
      specsObj.value_en !== null &&
      specsObj.value_ar !== "" &&
      specsObj.value_ar !== null
    ) {
      let value_enArr = _.split(specsObj.value_en, ",");
      let value_arArr = _.split(specsObj.value_ar, ",");
      let mainobj = {};

      value = value_enArr.map((val, index) => {
        // Use the Object.assign() method to create a new object
        // with the properties from the original arrays
        return Object.assign(
          {},
          {
            value_en: val,
            value_ar: value_arArr[index],
          }
        );
      });
    }
    specsObj["value"] = value;
    specsObj["value_en1"] = specsObj.value_en;
    specsObj["value_ar1"] = specsObj.value_ar;
    specsObj["value_en"] = "";
    specsObj["value_ar"] = "";
    specsObj["isvariant"] = parseInt(specsObj.isvariant);
  }
  console.log("specsObj", specsObj);
  return { type: SPECS_MASTER_SINGLE, payload: specsObj };
}

export function updateSpecification(session, specificationadded, callback) {
  var body = { resource: { ...specificationadded } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/specifications/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

// export function deleteSpecification(session,object,callback) {
//   var filter = encodeURI(`filter=(id=${object.id})`);
//   var url = `${INSTANCE_URL}/api/v2/shopez/_table/specifications?${filter}`;

//   return (dispatch) => {
//     deleteRequest(
//       url,
//       session,
//       dispatch,
//       (response) => {

//         callback({ status: 1 ,message: LocaleStrings.api_success_status});
//       },
//       (error) => {
//         callback({ status: 0 ,message: LocaleStrings.something_went_wrong});
//       }
//     );
//   };
// }

export function fetchSpecification(
  session,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/specifications`;
  let values = {};
  if (type === "listing") {
    // Paginated

    values.limit = limit;
    values.page = page;
    values.type = type;
  } else {
    // With out pagination [search result]

    values.type = type;
    values.searchterm = searchterm;
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (type === "listing") {
          // Paginated
          dispatch({
            type: SPECS_LIST,
            payload: { data: response.data, count: response.count },
          });
        } else {
          // With out pagination [search result]
          dispatch({
            type: SPECS_LIST,
            payload: { data: response.data },
          });
        }
        // console.log('negotiation', data);
        // dispatch({
        //   type: SPECS_LIST,
        //   payload: { data: data.data },

        // });
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}

export function updateSpecs(session, body, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/specifications/`;

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.addedsucessfully });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.addingfailed });
      }
    );
  };
}

/**** Fetch Cat & Subcat Data Together ****/
export function inactiveSpecsCount(session, specsid, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/productcountbyspecification`;

  let values = {};
  values.specificationid = specsid;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Cat & Subcat Data Together ****/
export function inactiveactiveSpecs(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/activatedeactivatespec`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}
