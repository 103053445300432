import { INSTANCE_URL } from "../../../../common/constants";
import { postRequest, patchRequest } from "../../../../common/network-call";

export var ALLPRODUCT_LIST = "ALLPRODUCT_LIST";
export var DIRECTSALERODUCT_LIST = "DIRECTSALERODUCT_LIST";
export var AUCTIONPRODUCT_LIST = "AUCTIONPRODUCT_LIST";
export var UNAPPROVEPRODUCT_LIST = "UNAPPROVEPRODUCT_LIST";

export function fetchallProductlist(session, pageNumber, callback) {
  var offset = pageNumber;
  // console.log('offset',offset)
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allproducts`;
  var sessiontoken = session.sessionToken;

  return (dispatch) => {
    postRequest(
      url,
      {
        limit: "10",
        page: offset,
        type: "listing",
        tab: "all",
      },
      session,
      dispatch,
      (data) => {
        // console.log('allproducts', data);
        dispatch({
          type: ALLPRODUCT_LIST,
          payload: { data: data.products, count: data.count },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchAllProducts(session, search, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allproducts`;

  return (dispatch) => {
    postRequest(
      url,
      {
        type: "search",
        searchterm: search,
        tab: "all",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: ALLPRODUCT_LIST,
          payload: { data: data.products },
        });
        callback({ success: 1, data: data.products });
      },

      (error) => {}
    );
  };
}

export function fetchdirectSaleProductlist(session, pageNumber, callback) {
  var offset = pageNumber;
  // console.log('offset',offset)
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allproducts`;
  var sessiontoken = session.sessionToken;

  return (dispatch) => {
    postRequest(
      url,
      {
        limit: "10",
        page: offset,
        type: "listing",
        tab: "directsale",
      },
      session,
      dispatch,
      (data) => {
        // console.log('allproducts', data);
        dispatch({
          type: DIRECTSALERODUCT_LIST,
          payload: { data: data.products, count: data.count },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchdirectSaleProducts(session, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allproducts`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        type: "search",
        searchterm: search,
        tab: "directsale",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: DIRECTSALERODUCT_LIST,
          payload: { data: data.products },
        });
      },
      (error) => {}
    );
  };
}

export function fetchauctionProductlist(session, pageNumber, callback) {
  var offset = pageNumber;
  // console.log('offset',offset)
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allproducts`;
  var sessiontoken = session.sessionToken;

  return (dispatch) => {
    postRequest(
      url,
      {
        limit: "10",
        page: offset,
        type: "listing",
        tab: "auction",
      },
      session,
      dispatch,
      (data) => {
        // console.log('allproducts', data);
        dispatch({
          type: AUCTIONPRODUCT_LIST,
          payload: { data: data.products, count: data.count },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchauctionProducts(session, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allproducts`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        type: "search",
        searchterm: search,
        tab: "auction",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: AUCTIONPRODUCT_LIST,
          payload: { data: data.products },
        });
      },
      (error) => {}
    );
  };
}

export function fetchunapproveProductlist(session, pageNumber, callback) {
  var offset = pageNumber;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allproducts`;

  return (dispatch) => {
    postRequest(
      url,
      {
        limit: "10",
        page: offset,
        type: "listing",
        tab: "unapproved",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: UNAPPROVEPRODUCT_LIST,
          payload: { data: data.products, count: data.count },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchunapproveProducts(session, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allproducts`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        type: "search",
        searchterm: search,
        tab: "unapproved",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: UNAPPROVEPRODUCT_LIST,
          payload: { data: data.products },
        });
      },
      (error) => {}
    );
  };
}

export function banProduct(session, data, callback) {
  var body = { resource: { ...data } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/products/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function markapproveProducts(session, productid, callback) {
  var body = { productid: productid };
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/productapproved`;
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function markrejectProducts(
  session,
  productid,
  reject_reason,
  callback
) {
  var body = { productid: productid, reject_reason: reject_reason };
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/productapproved`;
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
