import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { ReoprtViewModal } from "../../actions/index";
import { BaseComponent } from "../../../../../common/base-component";
import LocaleStrings from "../../../../languages";
import ReportViewModal from "./reportmodals";
import _ from "lodash";
import AllitemProductdetailmodal from "../../../sellermanagement/components/tabs/product/allitems-productdetails";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import {
  updateReportedItems,
  fetchReports,
  sendReportBanEmail,
} from "../../actions/index";
import renderHTML from "react-render-html";

class ReportListItems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
      unbanopen: false,
      pageNumber: 1,
      btnLoader: false,
    };
  }

  openModal = (e, obj) => {
    this.props.ReoprtViewModal(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  openModalProduct = (e) => this.setState({ setOpen: true });
  closeModalProduct = () => this.setState({ setOpen: false });

  banReporteditems(e, values, tablename) {
    var { user } = this.props;

    this.setState({ btnLoader: true });
    var id = "";
    if (tablename == "users") {
      var title = "Ban User";
      id = values.userid;
    } else if (tablename == "product_qa") {
      var title = "Ban Question & Answer";
      id = values.qaid;
    } else if (tablename == "product_comments") {
      var title = "Ban Comment";
      id = values.commentid;
    } else if (tablename == "products") {
      var title = "Ban Products";
      id = values.product.id;
    } else if (tablename == "product_reviews") {
      var title = "Ban Reviews";
      id = values.reviewid;
    }

    confirmAlert({
      title: title,
      message: "Are you sure you want Ban?",
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            let body = {};
            if (tablename == "users") {
              body = {
                resource: { userid: id, isactive: 0, bannedby: user.userid },
              };
            } else {
              body = {
                resource: { id: id, isactive: 0, bannedby: user.userid },
              };
            }
            //let body = { resource: { keyid:id,isactive:0} };
            // let body = { resource: { keyid:id,isactive:0} };

            this.props.updateReportedItems(
              this.props.session,
              body,
              tablename,
              (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error) {
                  NotificationManager.error("Error in banning");
                } else {
                  this.props.fetchReports(
                    this.props.session,
                    this.props.currentPage,
                    (CallBackResponse) => {}
                  );
                  NotificationManager.success("Successfully banned");
                  this.props.sendReportBanEmail(
                    this.props.session,
                    values.id,
                    (CallBackResponse) => {}
                  );
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  UnbanReporteditems(e, values, tablename) {
    this.setState({ btnLoader: true });
    var id = "";
    if (tablename == "users") {
      var title = "UnBan User";
      id = values.userid;
    } else if (tablename == "product_qa") {
      var title = "UnBan Question & Answer";
      id = values.qaid;
    } else if (tablename == "product_comments") {
      var title = "UnBan Comment";
      id = values.commentid;
    } else if (tablename == "products") {
      var title = "UnBan Products";
      id = values.product.id;
    } else if (tablename == "product_reviews") {
      var title = "UnBan Reviews";
      id = values.reviewid;
    }

    confirmAlert({
      title: title,
      message: "Are you sure you want UnBan?",
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            let body = {};
            if (tablename == "users") {
              body = { resource: { userid: id, isactive: 1, bannedby: null } };
            } else {
              body = { resource: { id: id, isactive: 1, bannedby: null } };
            }
            //let body = { resource: { keyid:id,isactive:0} };
            // let body = { resource: { keyid:id,isactive:0} };

            this.props.updateReportedItems(
              this.props.session,
              body,
              tablename,
              (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error) {
                  NotificationManager.error("Error in unbanning");
                } else {
                  NotificationManager.success("Successfully unbanned");
                  this.props.fetchReports(
                    this.props.session,

                    this.props.currentPage,
                    (CallBackResponse) => {}
                  );
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  replaceText = (text) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g; // regex for both http:// and https://
    return text.replace(urlRegex, function (url) {
      return true; // return the replaced text
    });
  };

  render() {
    var { values, user } = this.props;
    // console.log("user Product", user);
    let text = false;
    let tabledate = "";
    tabledate = moment(values.createdat).format("D MMM YYYY HH:mm A");
    if (values.hasOwnProperty("product")) {
      var reportedon = "Product";
      var reports = <div>{values.product.productname_en}</div>;
    } else if (values.hasOwnProperty("qa")) {
      var reportedon = "Question & Answer";
      var reports = <div>{values.qa.question}</div>;
    } else if (values.hasOwnProperty("profile")) {
      var reportedon = "Profile";
      var reports = <div>{values.profile.username}</div>;
    } else if (values.hasOwnProperty("comment")) {
      var reportedon = "Comment";
    } else if (values.hasOwnProperty("review")) {
      var reportedon = "Review";
    }

    var qa_table = "product_qa";
    var comment_table = "product_comments";
    var user_table = "users";
    var reviews_table = "product_reviews";
    var product_table = "products";

    text = this.replaceText(values.reporttext);

    return (
      <>
        <tr
          className="bg-white border-b-2 border-gray-50 hover-all"
          id={this.props.key}>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{values.reportedby.username}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{tabledate}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{_.startCase(values.type)}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {text == true || text == "true" ? (
              <a href={values.reporttext} target="_blank">
                {values.reporttext}
              </a>
            ) : (
              <>{values.reporttext}</>
            )}
            {/* <div>{renderHTML(values.reporttext)}</div> */}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div className="action-button-area" style={{ display: "flex" }}>
              <div className="">
                {values.type == "seller" || values.type == "consumer" ? (
                  <>
                    <button
                      className="button w-48"
                      onClick={(e) => this.openModal(e, values)}>
                      {LocaleStrings.view} {reportedon}
                    </button>
                    {this.state.isOpen ? (
                      <ReportViewModal
                        isOpen={this.state.isOpen}
                        onClose={this.closeModal}
                        data={values}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : values.type == "product" ? (
                  <>
                    {" "}
                    <button
                      className="button w-48"
                      onClick={(e) => this.openModalProduct(e, values)}>
                      View Details
                    </button>
                    {this.state.setOpen ? (
                      <AllitemProductdetailmodal
                        isOpen={this.state.setOpen}
                        onClose={this.closeModalProduct}
                        productDetails={this.props.values.product}
                        qa={this.props.values.product.qa}
                        reportedObject={values}
                        reportedOn="product"
                        // all_variants={this.props.values.all_variants}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : values.type == "review" ? (
                  <>
                    {" "}
                    <button
                      className="button w-48"
                      onClick={(e) => this.openModalProduct(e, values)}>
                      View Details
                    </button>
                    {this.state.setOpen ? (
                      <AllitemProductdetailmodal
                        isOpen={this.state.setOpen}
                        onClose={this.closeModalProduct}
                        productDetails={this.props.values.product}
                        qa={this.props.values.product.qa}
                        reportedObject={values}
                        reportedOn="review"
                        // all_variants={this.props.values.all_variants}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : values.type == "comment" ? (
                  <>
                    {" "}
                    <button
                      className="button w-48"
                      onClick={(e) => this.openModalProduct(e, values)}>
                      View Details
                    </button>
                    {this.state.setOpen ? (
                      <AllitemProductdetailmodal
                        isOpen={this.state.setOpen}
                        onClose={this.closeModalProduct}
                        productDetails={this.props.values.product}
                        qa={this.props.values.product.qa}
                        reportedObject={values}
                        reportedOn="comment"
                        // all_variants={this.props.values.all_variants}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : values.type == "question" ? (
                  <>
                    {" "}
                    <button
                      className="button w-48"
                      onClick={(e) => this.openModalProduct(e, values)}>
                      View Details
                    </button>
                    {this.state.setOpen ? (
                      <AllitemProductdetailmodal
                        isOpen={this.state.setOpen}
                        onClose={this.closeModalProduct}
                        productDetails={this.props.values.product}
                        qa={this.props.values.product.qa}
                        reportedObject={values}
                        reportedOn="question"
                        // all_variants={this.props.values.all_variants}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : values.type == "answer" ? (
                  <>
                    {" "}
                    <button
                      className="button w-48"
                      onClick={(e) => this.openModalProduct(e, values)}>
                      View Details
                    </button>
                    {this.state.setOpen ? (
                      <AllitemProductdetailmodal
                        isOpen={this.state.setOpen}
                        onClose={this.closeModalProduct}
                        productDetails={this.props.values.product}
                        qa={this.props.values.product.qa}
                        reportedObject={values}
                        reportedOn="answer"
                        // all_variants={this.props.values.all_variants}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="ml-2">
                <>
                  {(values.type == "consumer" || values.type == "seller") &&
                  values.profile.isactive == 0 ? (
                    <>
                      <div className="flex">
                        <button
                          className="button"
                          onClick={(e) =>
                            this.UnbanReporteditems(e, values, user_table)
                          }>
                          Unban
                        </button>
                        {values.bannedby ? (
                          <p className="ml-2 mt-2">
                            Banned by : -{values.bannedby.username}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : values.type == "product" &&
                    values.product.isactive == 0 ? (
                    <>
                      <div className="flex">
                        <button
                          className="button"
                          onClick={(e) =>
                            this.UnbanReporteditems(e, values, product_table)
                          }>
                          Unban
                        </button>
                        {values.bannedby ? (
                          <p className="ml-2 mt-2">
                            Banned by : -{values.bannedby.username}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (values.type == "question" || values.type == "answer") &&
                    values.qa.isactive == 0 ? (
                    <>
                      <div className="flex">
                        <button
                          className="button"
                          onClick={(e) =>
                            this.UnbanReporteditems(e, values, qa_table)
                          }>
                          Unban
                        </button>
                        {values.bannedby ? (
                          <p className="ml-2 mt-2">
                            Banned by : -{values.bannedby.username}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : values.type == "comment" &&
                    values.comment.isactive == 0 ? (
                    <>
                      <div className="flex">
                        <button
                          className="button"
                          onClick={(e) =>
                            this.UnbanReporteditems(e, values, comment_table)
                          }>
                          Unban
                        </button>
                        {values.bannedby ? (
                          <p className="ml-2 mt-2">
                            Banned by : -{values.bannedby.username}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : values.type == "review" && values.review.isactive == 0 ? (
                    <>
                      <div className="flex">
                        <button
                          className="button"
                          onClick={(e) =>
                            this.UnbanReporteditems(e, values, reviews_table)
                          }>
                          Unban
                        </button>
                        {values.bannedby ? (
                          <p className="ml-2 mt-2">
                            Banned by : -{values.bannedby.username}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : values.type == "product" ? (
                    <>
                      <div className="flex">
                        <button
                          className="button"
                          onClick={(e) =>
                            this.banReporteditems(e, values, product_table)
                          }>
                          Ban Product
                        </button>
                        {values.bannedby ? (
                          <p className="ml-2 mt-2">
                            Banned by : -{values.bannedby.username}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : values.type == "question" || values.type == "answer" ? (
                    <>
                      <div className="flex">
                        <button
                          className="button"
                          onClick={(e) =>
                            this.banReporteditems(e, values, qa_table)
                          }>
                          Ban Qa
                        </button>
                        {values.bannedby ? (
                          <p className="ml-2 mt-2">
                            Banned by : -{values.bannedby.username}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : values.type == "consumer" || values.type == "seller" ? (
                    <>
                      <div className="flex">
                        <button
                          className="button"
                          onClick={(e) =>
                            this.banReporteditems(e, values, user_table)
                          }>
                          Ban profile
                        </button>
                        {values.bannedby ? (
                          <p className="ml-2 mt-2">
                            Banned by : -{values.bannedby.username}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : values.type == "comment" ? (
                    <>
                      <div className="flex">
                        <button
                          className="button"
                          onClick={(e) =>
                            this.banReporteditems(e, values, comment_table)
                          }>
                          Ban Comment
                        </button>
                        {values.bannedby ? (
                          <p className="ml-2 mt-2">
                            Banned by : -{values.bannedby.username}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : values.type == "review" ? (
                    <>
                      <div className="flex">
                        <button
                          className="button"
                          onClick={(e) =>
                            this.banReporteditems(e, values, reviews_table)
                          }>
                          Ban Reviews
                        </button>
                        {values.bannedby ? (
                          <p className="ml-2 mt-2">
                            Banned by : -{values.bannedby.username}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              </div>
            </div>
          </td>
        </tr>
        <NotificationContainer />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapStateToProps, {
  ReoprtViewModal,
  updateReportedItems,
  fetchReports,
  sendReportBanEmail,
})(ReportListItems);
