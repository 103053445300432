import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import { Field, reduxForm } from "redux-form";
import { isForgotPasswordOpen } from "../../../../../../actions/index";
import _ from "lodash";
import {
  fetchNegotiationrejected,
  searchLostNegotiaton,
} from "../../../actions/index";
import NegotiationrejectedItems from "./negotiationrejected-items";
import Pagination from "react-js-pagination";
import BaseLoader from "../../../../../../common/base-loader";

var Loader = require("react-loaders").Loader; // import "AwesomeLoaderCss";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class NegotiationRejected extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      pageNumber: 1,
      search: "",
      loaderDesc: "Preparing Negotiation",
      open: false,
      setOpen: false,
      rejectedNegotiationSearch: "",
    };
  }

  componentDidMount(data) {
    this.props.fetchNegotiationrejected(
      this.props.session,
      this.props.data.userid,
      this.state.pageNumber,
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  paginationCallback = (pageNumber) => {
    // alert(pageNumber)
    this.props.fetchNegotiationrejected(
      this.props.session,
      this.props.data.userid,
      pageNumber,
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchNegotiationrejected(
        this.props.session,
        this.props.data.userid,
        0,
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchLostNegotiaton(
        this.props.session,
        this.props.data.userid,
        search
      );
    }
    this.setState({ search, pageNumber });
  };

  renderList() {
    let pageDataLength = this.props.rejectedNegotiation.data.length;

    let { pageNumber } = this.state;

    var items = _.map(this.props.rejectedNegotiation.data, (values) => (
      <NegotiationrejectedItems
        key={values.userid}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));

    return items;
  }

  render() {
    return (
      // <>
      //   {this.props.rejectedNegotiation.data ? (
      //     <>
      //       {" "}
      //       <div className="center-item lg:mt-48 text-lg text-ternary">
      //         {LocaleStrings.nonegotiationsrejected}
      //       </div>
      //     </>
      //   ) : (
      //     <>
      //       {" "}
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {_.isEmpty(this.props.rejectedNegotiation.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.nonegotiationsrejected}
                </div>
              </>
            ) : (
              <>
                <div className="relative  text-ternary focus-within:text-secondary ">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    className="block lg:w-3/12 sm:w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                    placeholder={LocaleStrings.search}
                    type="search"
                    name="search"
                    value={this.state.search}
                    onChange={this.handleSearch}
                  />
                </div>
                <div className="flex flex-col mt-5">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.product}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.actualprice}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.negotiatedprice}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Offers Left
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.negotiatedon}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.status}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.renderList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <Pagination
                  activePage={this.state.pageNumber}
                  itemsCountPerPage={10}
                  totalItemsCount={this.props.rejectedNegotiation.count}
                  pageRangeDisplayed={10}
                  onChange={this.paginationCallback}
                />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  // console.log('state.negotiationlist', state.rejectedNegotiation);
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    rejectedNegotiation: state.rejectedNegotiation,
    // data:ownProps.data,
  };
}
export default reduxForm({
  form: "NEGOTIATIONLOST",
})(
  connect(mapStateToProps, {
    isForgotPasswordOpen,
    fetchNegotiationrejected,
    searchLostNegotiaton,
  })(NegotiationRejected)
);
