import LocaleStrings from "../../../languages/index";
import {
  ADDSMANAGEMENT_PRODUCTCATEGORY,
  ADDSMANAGEMENT_PRODUCTSUBCATEGORY,
  ADDSMANAGEMENT_PRODUCTLIST,
  ALL_ADD_LIST,
  EDITADVERTISEMENT_DATA,
  ADDSMANAGEMENT_ALLPRODUCTLIST,
  OPEN_ADDMODAL,
} from "../actions/index";

export var allAddListing = (state = {}, action) => {
  switch (action.type) {
    case ALL_ADD_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var allproductforAds = (state = {}, action) => {
  switch (action.type) {
    case ADDSMANAGEMENT_ALLPRODUCTLIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var addProductByCateSubCate = (state = {}, action) => {
  switch (action.type) {
    case ADDSMANAGEMENT_PRODUCTLIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// PRODUCT CATEGORY
export var addProductCategory = (state = {}, action) => {
  switch (action.type) {
    case ADDSMANAGEMENT_PRODUCTCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// PRODUCT SUBCATEGORY
export var addProductSubCategory = (state = {}, action) => {
  switch (action.type) {
    case ADDSMANAGEMENT_PRODUCTSUBCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var NewAddArea = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Category", value: "category", id: 0 },
    { name: "Sub-Category", value: "subcategory", id: 1 },
    { name: "Product", value: "product", id: 2 },
    { name: "External", value: "external", id: 3 },
  ];

  return state;
};
export var positionAdd = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Random", value: "random", id: 0 },
    { name: "Top", value: "top", id: 1 },
    // { name: "Bottom", value:"bottom" ,id:2 },
  ];

  return state;
};

export var EditAdds = (state = {}, action) => {
  switch (action.type) {
    case EDITADVERTISEMENT_DATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var whereTo = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Category", value: "category", id: 0 },
    { name: "Product", value: "product", id: 1 },
  ];

  return state;
};

export var isaddModalopen = (state = false, action) => {
  switch (action.type) {
    case OPEN_ADDMODAL:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
