import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index";
import _ from "lodash";
import AllforsaleItems from "./forsale-items";
import {
  fetchdirectSaleProductlist,
  searchdirectSaleProducts,
} from "../../actions/index";
import { SearchIcon } from "@heroicons/react/solid";
import BaseLoader from "../../../../../common/base-loader";
import Pagination from "react-js-pagination";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ProductmanagementlistTabs from "../producttabs";
import { classNames } from "../../../../../common/common-functions";

class ForSaleItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      pageNumber: 1,
      search: "",
      loaderDesc: "Preparing Sale Products",
      forsaleSearchText: "",
    };

    // All Binded Functions
  }

  componentDidMount(data) {
    this.props.fetchdirectSaleProductlist(
      this.props.session,
      this.state.pageNumber,
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }
  paginationCallback = (pageNumber) => {
    let { search } = this.state;
    if (search == "") {
      this.props.fetchdirectSaleProductlist(
        this.props.session,
        pageNumber,
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchdirectSaleProducts(
        this.props.session,
        search,
        (callback) => {}
      );
    }
    this.setState({ ...this.state, pageNumber });
  };
  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchdirectSaleProductlist(
        this.props.session,
        this.state.pageNumber,
        (CallBackResponse) => {}
      );
    }
    // else {
    //   this.props.searchdirectSaleProducts(this.props.session, search);
    // }
    this.setState({ search, pageNumber });
  };

  searchProduct = (e) => {
    let { search } = this.state;
    if (search == "") {
      this.props.fetchdirectSaleProductlist(
        this.props.session,
        this.state.pageNumber,
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchdirectSaleProducts(
        this.props.session,
        search,
        (callback) => {}
      );
    }
  };

  clearSearch() {
    this.setState({ search: "" });
    this.props.fetchdirectSaleProductlist(
      this.props.session,
      this.state.pageNumber,
      (CallBackResponse) => {}
    );
  }
  renderallProductList() {
    let pageDataLength = this.props.directSaleproductslist.data.length;

    let { pageNumber } = this.state;
    var items = _.map(
      this.props.directSaleproductslist.data,
      (values, index) => (
        <AllforsaleItems
          key={index}
          values={values}
          pagination={this.paginationCallback}
          pageDataLength={pageDataLength}
          currentPage={pageNumber}
        />
      )
    );
    return items;
  }

  render() {
    let { language } = this.props;

    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <ProductmanagementlistTabs history={this.props.history} />
            <div className="pb-7 m-6 mb-1">
              {/* Header Part */}
              <div class="relative">
                {language == "en" ? (
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </div>
                ) : (
                  ""
                )}
                <input
                  type="search"
                  name="search"
                  id="search-field"
                  value={this.state.search}
                  onChange={this.handleSearch}
                  class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search Products"
                  required
                />
                {this.state.search.length > 0 ? (
                  <button
                    type="button"
                    onClick={(e) => this.clearSearch(e)}
                    class="absolute right-24 text-gray-500 bottom-3.5 hover:text-gray-700 focus:outline-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                ) : (
                  ""
                )}

                <button
                  type="button"
                  onClick={(e) => this.searchProduct(e)}
                  className={classNames(
                    language == "en" ? "right-2.5" : "left-2.5",
                    "text-white absolute bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  )}>
                  Search
                </button>
              </div>
              {/* <div class="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-3 ">
                <div className="justify-items-center">
                  <label htmlFor="search-field" className="sr-only">
                    {LocaleStrings.search}
                  </label>
                  <div className="relative w-full text-ternary focus-within:text-secondary ">
                    <input
                      id="search-field"
                      className="block w-full h-full pl-10 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                      placeholder={LocaleStrings.search}
                      type="search"
                      name="search"
                      value={this.state.search}
                      onChange={this.handleSearch}
                    />
                  </div>
                  <div className="cursor-pointer absolute" onClick={(e) => this.searchProduct(e)} style={{marginTop:'-30px',marginLeft:'5px'}}>
                      <SearchIcon className="h-8 w-8 cursor-pointer" aria-hidden="true" />
                    </div>
                </div>
              </div> */}
            </div>
            {_.isEmpty(this.props.directSaleproductslist.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.prod_mangementnoData}
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col p-6 pt-0">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              {/* <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.recommended}
                              </th> */}
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.product}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.uploadedby}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.type}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.price}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.uploadedon}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.action}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.renderallProductList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <Pagination
                  activePage={this.state.pageNumber}
                  itemsCountPerPage={10}
                  totalItemsCount={this.props.directSaleproductslist.count}
                  pageRangeDisplayed={10}
                  onChange={this.paginationCallback}
                />
                {/* <NotificationContainer /> */}
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    language: state.language,
    session: state.session,
    directSaleproductslist: state.directSaleproductslist,
  };
}

export default connect(mapStateToProps, {
  fetchdirectSaleProductlist,
  searchdirectSaleProducts,
})(ForSaleItems);
