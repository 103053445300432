import LocaleStrings from "../../../languages/index";
// Store vendor Product list
import {
  CUSTOMER_LIST,
  WISHLIST_LIST,
  CUSTOMER_TABID,
  CUSTOMER_NEWS,
  NEGOTIATION_LIST,
  EDIT_MODAL,
  ODER_LIST,
  ODER_LIST_DELIVERED,
  ODER_LIST_DISPUTED,
  WATCHLIST_LIST,
  AUCTION_LIST,
  CUSTOMER_AUCTION_LIST_WON,
  NEGOTIATION_LOST,
  TRANSACTION_LIST,
  ORDER_MASTER,
  SINGLECUSTOMER,
} from "../actions";

export var customermanagementTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.about, href: "#", current: true, tabindex: 1 },
    { name: LocaleStrings.watchlist, href: "#", current: false, tabindex: 2 },
    { name: LocaleStrings.wishlist, href: "#", current: false, tabindex: 3 },
    { name: LocaleStrings.oders, href: "#", current: false, tabindex: 4 },
    { name: LocaleStrings.auctions, href: "#", current: false, tabindex: 5 },
    { name: LocaleStrings.negotiation, href: "#", current: false, tabindex: 6 },
    { name: LocaleStrings.transaction, href: "#", current: false, tabindex: 7 },
  ];

  return state;
};
export var OderTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.new, href: "#", current: true, tabindex: 1 },
    { name: LocaleStrings.delivered, href: "#", current: false, tabindex: 2 },
    { name: LocaleStrings.disputed, href: "#", current: false, tabindex: 3 },
  ];

  return state;
};
export var NegotiationTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.accepted, href: "#", current: true, tabindex: 1 },
    { name: LocaleStrings.rejected, href: "#", current: false, tabindex: 2 },
  ];

  return state;
};
export var AuctionTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.participated, href: "#", current: true, tabindex: 1 },
    { name: LocaleStrings.won, href: "#", current: false, tabindex: 2 },
  ];

  return state;
};

export function customerlist(state = {}, action) {
  switch (action.type) {
    case CUSTOMER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function wishlistlist(state = {}, action) {
  // console.log('action55',action)
  switch (action.type) {
    case WISHLIST_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function watchlist(state = {}, action) {
  // console.log('action55',action)
  switch (action.type) {
    case WATCHLIST_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function auctionList(state = {}, action) {
  // console.log('action55',action)
  switch (action.type) {
    case AUCTION_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function customerauctionwonList(state = {}, action) {
  // console.log('action55',action)
  switch (action.type) {
    case CUSTOMER_AUCTION_LIST_WON:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function rejectedNegotiation(state = {}, action) {
  // console.log('action55',action)
  switch (action.type) {
    case NEGOTIATION_LOST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function oderlist(state = {}, action) {
  // console.log('action55',action)
  switch (action.type) {
    case ODER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function oderlistDelivered(state = {}, action) {
  // console.log('action55',action)
  switch (action.type) {
    case ODER_LIST_DELIVERED:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function oderlistDisputed(state = {}, action) {
  // console.log('action55',action)
  switch (action.type) {
    case ODER_LIST_DISPUTED:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function negotiationlist(state = {}, action) {
  switch (action.type) {
    case NEGOTIATION_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function customerlistingtabs(state = {}, action) {
  switch (action.type) {
    case CUSTOMER_TABID:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}

export function newtablist(state = {}, action) {
  switch (action.type) {
    case CUSTOMER_NEWS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function customereditmodal(state = {}, action) {
  switch (action.type) {
    case EDIT_MODAL:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function transactionList(state = {}, action) {
  switch (action.type) {
    case TRANSACTION_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function orderMasterList(state = {}, action) {
  switch (action.type) {
    case ORDER_MASTER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function singleCustomerData(state = {}, action) {
  switch (action.type) {
    case SINGLECUSTOMER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
