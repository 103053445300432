import Recat, { Component } from "react";
import { connect } from "react-redux";
import { XIcon } from "@heroicons/react/outline";
import LocaleStrings from "../../../languages/index";

import InsideDiscountTabs from "./discounts/discounttabs";
import CouponsManagement from "./coupons/index";
import ActiveDiscount from "./discounts/active/activediscount";
import InsideCouponTabs from "./coupons/coupontabs";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class DiscountCouponTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndextoShow: 1,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  // On Tabs change
  onTabsChange = (e, tabindex, callfrom) => {
    if (callfrom === "lg") {
      // For tabs
      this.setState({ tabIndextoShow: parseInt(tabindex) });
    } else {
      // For Select box
      this.setState({ tabIndextoShow: parseInt(e.target.value) });
    }
  };

  onDisCcoupnTabSelect(e, href, position, screen) {
    if (screen == "sm") {
      if (e.target.value == "Discounts") {
        this.props.history.push("/dashboard/discountmanagement/discount");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else {
        this.props.history.push("/dashboard/discountmanagement/coupon");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      }
    } else {
      this.setState({ tabIndextoShow: parseInt(position) });
      this.props.history.push("/dashboard/discountmanagement/" + href);
    }
  }

  render() {
    var selectedTab = "";
    return (
      <>
        <div className="px-6 text-2xl font-semibold text-secondary mb-6">
          DISCOUNT & COUPON MANAGEMNET
        </div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-primary focus:border-primary border-ternary rounded-md"
            // defaultValue={this.props.tabs.find((tab) => tab.current).name}
            onChange={(e) => this.onDisCcoupnTabSelect(e, "", "", "sm")}>
            {this.props.tabs.map((tab) => (
              <option
                // key={tab.name} value={tab.tabindex}

                selected={
                  this.props.history.location.pathname ==
                    "/dashboard/discountmanagement/discount" &&
                  tab.href == "discount"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/discountmanagement/coupon" &&
                      tab.href == "coupon"
                    ? "selected"
                    : ""
                }>
                {tab.name}
              </option>
            ))}
          </select>
        </div>

        <div className="hidden sm:block">
          {/* <div className="xs:block"> */}
          <nav className="relative z-0 rounded-lg flex  pl-5" aria-label="Tabs">
            {this.props.tabs.map((tab, tabIdx) => (
              <span
                style={{ marginRight: "20px", borderRadius: "6px" }}
                key={tab.name}
                className={classNames(
                  this.state.tabIndextoShow === tab.position
                    ? "text-secondary"
                    : "text-ternary hover:text-secondary",
                  // tabIdx === 0 ? 'rounded-l-lg' : '',
                  // tabIdx === this.props.tabs.length - 1 ? 'rounded-r-lg' : '',
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10 "
                )}
                aria-current={
                  this.state.tabIndextoShow === tab.position
                    ? "page"
                    : undefined
                }
                onClick={(e) =>
                  this.onDisCcoupnTabSelect(e, tab.href, tab.position, "lg")
                }>
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    this.props.history.location.pathname ==
                      "/dashboard/discountmanagement/discount" &&
                      tab.href == "discount"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/discountmanagement/coupon" &&
                        tab.href == "coupon"
                      ? "bg-primary"
                      : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </span>
            ))}
          </nav>
        </div>
        {/* <div className="py-6 px-6">
           
                    <div>
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                            Select a tab
                            </label>
                            <select
                            id="tabs"
                            name="tabs"
                            className="block w-full focus:ring-primary focus:border-primary border-ternary rounded-md"
                            defaultValue={this.props.tabs.find((tab) => tab.current).name}
                            onChange = {(e) =>
                                this.onTabsChange(e,0,'sm')
                            }
                            >
                            {this.props.tabs.map((tab) => (
                                
                                <option key={tab.name} value={tab.tabindex}  selected={((this.state.tabIndextoShow === tab.tabindex) ? 'selected' : '')} >{tab.name}</option>
                            ))}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <nav className="relative z-0 rounded-lg flex " aria-label="Tabs">
                            {this.props.tabs.map((tab, tabIdx) => (
                                <span
                                style={{marginRight:'20px', borderRadius:'6px'}}
                                key={tab.name}
                                className={classNames(
                                    (this.state.tabIndextoShow === tab.tabindex ) ? 'text-secondary' : 'text-ternary hover:text-secondary',
                                    tabIdx === 0 ? 'rounded-l-lg' : '',
                                    tabIdx === this.props.tabs.length - 1 ? 'rounded-r-lg' : '',
                                    'cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10 '
                                )}
                                aria-current={(this.state.tabIndextoShow === tab.tabindex ) ? 'page' : undefined}
                                onClick = {(e) =>
                                    this.onTabsChange(e,tab.tabindex,'lg')
                                }
                                >
                                <span>{tab.name}</span>
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        (this.state.tabIndextoShow === tab.tabindex ) ? 'bg-primary' : 'bg-transparent',
                                    'absolute inset-x-0 bottom-0 h-0.5'
                                    )}
                                />
                                </span>
                            ))}
                            </nav>
                        </div>
                        <div className="">
                            <div className="m-5 ml-0 mr-0 space-x-4 ">
                               
                                    <div className={this.state.tabIndextoShow === 1 ? '' : 'hidden'} ><ActiveDiscount/></div>
                                    <div className={this.state.tabIndextoShow === 2 ? '' : 'hidden'} ><InsideCouponTabs/></div>

                             
                            </div>
                        </div>
                    </div>
                </div> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.DiscountandCouponTabs,
  };
}

export default connect(mapStateToProps, {})(DiscountCouponTabs);
