import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
} from "../../../../../../common/constants";
import _ from "lodash";
import { BaseComponent } from "../../../../../../common/base-component";
import AllitemProductdetailmodal from "./allitems-productdetails";
import { ProductTextLocalized } from "../../../../../../common/common-functions";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../../currency/action/index";
import {
  featureUnfeatureproducts,
  fetchProductListSale,
} from "../../../actions/index";
import { confirmAlert } from "react-confirm-alert";
import { NotificationManager } from "react-notifications";
import LocaleStrings from "../../../../../languages/index";

class ForsaleListing extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };

    // // All Binded Functions
    // this.closeModal = this.closeModal.bind(this);
    // this.openModal = this.openModal.bind(this);
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  _isFeatured(e, values) {
    // console.log("isFeatured", isFeatured);
    var data = { id: values.id, isfeatured: 1 };
    confirmAlert({
      title: "Feature Product",
      message: "Are you sure you want to feature this product",
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.featureUnfeatureproducts(
              this.props.session,
              data,
              (resource) => {
                NotificationManager.success("Product Featured Successfully");
                this.props.fetchProductListSale(
                  this.props.session,
                  this.props.userid,
                  this.props.currentPage,
                  (CallBackResponse) => {}
                );
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }
  _UnFeatured(e, values) {
    // console.log("isFeatured", isFeatured);
    var data = { id: values.id, isfeatured: 0 };
    confirmAlert({
      title: "UnFeature Product",
      message: "Are you sure you want to unfeature this product",
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.featureUnfeatureproducts(
              this.props.session,
              data,
              (resource) => {
                NotificationManager.success("Product Featured Successfully");
                this.props.fetchProductListSale(
                  this.props.session,
                  this.props.userid,
                  this.props.currentPage,
                  (CallBackResponse) => {}
                );
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  banBtnClick(e, values) {
    let body = {
      id: parseInt(values.id),
      isactive: values.isactive == 1 ? 0 : 1,
    };
    let title = "Ban Product";
    let message = "Are you sure you want to Ban Product?";
    if (values.isactive == 0) {
      title = "Unban Product";
      message = "Are you sure you want to Unban Product?";
    }
    confirmAlert({
      title: `${title}`,
      message: `${message}`,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            this.props.featureUnfeatureproducts(
              this.props.session,
              body,
              (resource) => {
                NotificationManager.success(`${title} Successfully`);
                this.props.fetchProductListSale(
                  this.props.session,
                  this.props.userid,
                  this.props.currentPage,
                  (CallBackResponse) => {}
                );
              }
            );
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { values, localCurrency } = this.props;
    let local_lang = this.props.language;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    // console.log('working', values);
    var img = `${BASE_IMAGES_URL}/vendor/variations/${values.productimage}?api_key=${APP_API_KEY}`;
    var curObjVariant = _.head(
      _.filter(values.all_variants, { id: values.variantid })
    );
    let price_unit = curObjVariant.price_unit;
    let productname = ProductTextLocalized(
      values.productname_en,
      values.productname_ar,
      local_lang
    );

    let tabledate = "";
    tabledate = moment(values.createdon).format("D MMM YYYY HH:mm A");

    let bgStyle = {
      backgroundColor: "#fff",
    };
    if (values.isactive == 0) {
      bgStyle = {
        backgroundColor: "#fafafa",
      };
    }

    let product_price = 0.0;

    let productCurrency = "QAR";
    if (values.currency && (values.currency != "" || values.currency != null)) {
      productCurrency = values.currency;
    }

    product_price = price_unit;

    return (
      <>
        {values.saletype == "directsale" ? (
          <>
            <tr
              className="border-b-2 border-gray-50 cursor-pointer"
              style={bgStyle}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div>
                  {values.isfeatured == 0 ? (
                    <img
                      className="h-4 w-4"
                      onClick={(e) => this._isFeatured(e, values)}
                      src="../images/product_management_images/star.png"></img>
                  ) : (
                    <img
                      className="h-4 w-4"
                      onClick={(e) => this._UnFeatured(e, values)}
                      src="../images/product_management_images/yellowstar.svg"
                      // className="w-5"
                    />
                  )}
                </div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                style={{ display: "flex" }}
                onClick={(e) => this.openModal(e, values)}>
                <div className="mr-2">
                  <img
                    src={img}
                    style={{ height: "40px", width: "40px" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../images/default.jpeg";
                    }}
                  />
                </div>
                <div>
                  {values.productname_en.length > 35
                    ? values.productname_en.substring(0, 35) + "..."
                    : values.productname_en}
                </div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                onClick={(e) => this.openModal(e, values)}>
                <div>
                  {values.store.username
                    ? values.store.username
                    : values.store.storename}
                </div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                onClick={(e) => this.openModal(e, values)}>
                <div>{_.startCase(values.saletype)}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                onClick={(e) => this.openModal(e, values)}>
                <div>
                  {currency} {product_price}
                  {/* ${parseFloat(price_unit).toFixed(2)} */}
                </div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                onClick={(e) => this.openModal(e, values)}>
                <div>{tabledate}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this.banBtnClick(e, values)}>
                      {values.isactive == 1 ? "Ban" : "Unban"}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            {this.state.isOpen ? (
              <AllitemProductdetailmodal
                isOpen={this.state.isOpen}
                onClose={this.closeModal}
                productDetails={this.props.values}
                qa={this.props.values.qa}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.sellermanagementTabs,
    sellereditmodal: state.sellereditmodal,
    sellerlistingtabs: state.sellerlistingtabs,
    newtabsellerlist: state.newtabsellerlist,
    localCurrency: state.currency,
  };
}

export default connect(mapStateToProps, {
  featureUnfeatureproducts,
  fetchProductListSale,
  isCurrencyRateApplicable,
})(ForsaleListing);
