import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
// import { showError, showSuccess } from "ReduxErrorBar";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
  number,
} from "../../../../../../common/base-component";
import _ from "lodash";
import { classNames } from "../../../../../../common/common-functions";
import {
  postShippingCharges,
  fetchShippingCharges,
} from "../../../actions/index";
import { NotificationManager } from "react-notifications";

class AddGlobalCharges extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      btnLoader: false,
      selectedzoneorpin: "",
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }
  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
    this.props.reset();
  }

  onSubmitForm(values) {
    let { shippingCharges } = this.props;
    this.setState({ btnLoader: true });
    if (this.state.selectedzoneorpin == "pin_code") {
      if (!values.hasOwnProperty("pin_code") || values.pin_code == "") {
        NotificationManager.error(
          LocaleStrings.addcharge_errornotification_addpincode
        );
        this.setState({ btnLoader: false });
        return false;
      }
    } else {
      if (!values.hasOwnProperty("zone") || values.zone == "") {
        NotificationManager.error("Please Add Zone");
        this.setState({ btnLoader: false });
        return false;
      }
    }
    if (this.state.selectedzoneorpin == "pin_code") {
      var data = {
        district: values.district,
        charges: values.charges,
        pin_code: values.pin_code,
      };

      this.props.postShippingCharges(this.props.session, data, (response) => {
        if (response.success === 0) {
          this.setState({ loading: false });
          // alert("error");
          NotificationManager.error(LocaleStrings.addingfailed);
          this.setState({ btnLoader: false });
        } else {
          this.props.fetchShippingCharges(
            this.props.session,
            (CallBackResponse) => {}
          );
          this.setState({ open: false });
          this.setState({ btnLoader: false });
          // alert("done");
          this.props.reset();
          NotificationManager.success(LocaleStrings.addedsucessfully);
        }
      });
    } else {
      let zoneExists = [];
      if (shippingCharges && shippingCharges.data.length > 0) {
        zoneExists = _.filter(shippingCharges.data, function (o) {
          return o.zone == values.zone;
        });
      }
      var data = {
        district: values.district,
        charges: values.charges,
        zone: values.zone,
      };

      if (zoneExists.length == 0) {
        this.props.postShippingCharges(this.props.session, data, (response) => {
          if (response.success === 0) {
            this.setState({ loading: false });
            // alert("error");
            NotificationManager.error(LocaleStrings.addingfailed);
            this.setState({ btnLoader: false });
          } else {
            this.props.fetchShippingCharges(
              this.props.session,
              (CallBackResponse) => {}
            );
            this.setState({ btnLoader: false });
            this.setState({ open: false });
            this.props.reset();
            NotificationManager.success(LocaleStrings.addedsucessfully);
          }
        });
      } else {
        this.setState({ btnLoader: false });
        NotificationManager.error(
          LocaleStrings.addcharge_errornotification_zonexistalready
        );
        return false;
      }
    }
  }

  _handleposition(e) {
    let mandateType = "";
    if (e.target.value != "" && e.target.value != null) {
      mandateType = _.find(this.props.zoneorPin, {
        value: e.target.value,
      }).value;
    }
    this.setState({ selectedzoneorpin: mandateType });
  }

  render() {
    var { handleSubmit } = this.props;

    var districtsOpts = [];
    var zonecodesOpts = [];
    var zoneorPincodesOpts = [];
    _.forEach(this.props.DummyDistricts, function (value) {
      var obj = {
        name: value.name,
        // value: value.id.toString(),
        value: value.value,
      };

      districtsOpts.push(obj);
    });

    _.forEach(this.props.zonemasterList.data, function (value) {
      var obj = {
        name: value.zone,
        value: value.id,
      };

      zonecodesOpts.push(obj);
    });

    _.forEach(this.props.zoneorPin, function (value) {
      var obj = {
        name: value.name,
        value: value.value,
      };

      zoneorPincodesOpts.push(obj);
    });

    return (
      <div className=" mt-5" style={{ overflowX: "hidden" }}>
        <div className="flex flex-wrap content-center justify-end ">
          <button
            onClick={this.openModal}
            type="submit"
            className="lg:w-48 md:w-44 sm:w-44 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
            Add New Location
            <img
              src="../images/product_management_images/add.svg"
              className="h-5 ml-2"
            />
          </button>
          <Transition.Root show={this.state.open} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed z-10 inset-0 overflow-y-auto"
              open={this.state.open}
              onClose={this.closeModal}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                  <div
                    className="inline-block max-h-screen-60 align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                    style={{ padding: 0 }}>
                    {/* MOdal Header */}
                    <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                      <div className="flex flex-wrap pl-6 mt-4">
                        <div className="text-md leading-6 font-medium text-secondary">
                          Add New Location
                        </div>
                      </div>
                      <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                        <button
                          type="button"
                          className="btn-closeModal"
                          onClick={this.closeModal}>
                          <span className="sr-only">{LocaleStrings.close}</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div></div>
                    <form
                      className=""
                      onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
                      <div className=" p-6">
                        {/* <Field
                          name="district"
                          label="Select District"
                          placeholder={LocaleStrings.select}
                          component={this.renderSelect}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          opts={districtsOpts}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                        /> */}

                        <div className="mb-5">
                          <Field
                            name="zoneorpin"
                            label="Select Zone / Pin"
                            placeholder={LocaleStrings.select}
                            component={this.renderSelect}
                            mandatory="true"
                            labelposition={LABEL_POSITION_TOP}
                            onChange={this._handleposition.bind(this)}
                            opts={zoneorPincodesOpts}
                            className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                            classNameLabel="block text-box-lable"
                          />
                        </div>
                        {this.state.selectedzoneorpin == "zone" ? (
                          <div className="mb-5">
                            <Field
                              name="zone"
                              label="Select Zone"
                              placeholder={LocaleStrings.select}
                              component={this.renderSelect}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              opts={zonecodesOpts}
                              className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                              classNameLabel="block text-box-lable"
                            />
                          </div>
                        ) : this.state.selectedzoneorpin == "pin_code" ? (
                          <div className="mb-5">
                            <Field
                              name="pin_code"
                              placeholder="Pincodes"
                              label="Pincodes"
                              type="text"
                              component={this.renderAllfields}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        <div>
                          <Field
                            name="charges"
                            label="Set Price (QAR)"
                            placeholder="Set Price (QAR)"
                            type="number"
                            mandatory="true"
                            component={this.renderAllfields}
                            className="appearance-none block w-half px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      {/* <div className="w-full bottom-0 absolute mb-10">
                        <div className="flex justify-center">
                          <button type="submit" className="button">
                            Add Delivery Agent
                          </button>
                        </div>
                      </div> */}

                      <div className="w-full bottom-0 absolute mb-10">
                        <div className="flex justify-center">
                          <button type="submit" className="button">
                            <svg
                              className={classNames(
                                this.state.btnLoader ? "" : "sr-only",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            Set Charge
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = ["district", "charges", "zoneorpin"];
  var pin_code = values["pin_code"];
  if (pin_code && number(pin_code)) {
    errors["pin_code"] = "Please enter a number";
  }

  requiredFields.forEach((field) => {
    // if (typeof values[field] != 'undefined'){
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
    // }
  });

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    language: state.language,
    DummyDistricts: state.DummyDistricts,
    DummyPin_codes: state.DummyPin_codes,
    zonemasterList: state.zonemasterList,
    zoneorPin: state.zoneorPin,
    shippingCharges: state.shippingCharges,
  };
}

export default reduxForm({
  validate,
  form: "ADDGLOBALCHARGES",
})(
  connect(mapStateToProps, {
    postShippingCharges,
    fetchShippingCharges,
  })(AddGlobalCharges)
);
