import React, { Component } from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../../common/base-component";
import AllneworderdetailsModals from "../Allnewoders/allnewordermodal";
import _ from "lodash";
import { converDateIntoLocal } from "../../../../../common/common-functions";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../currency/action/index";

class AllDeliveredoderitemslist extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      linkedorderisOpen: false,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  openLinkedaccountModal = (e) => this.setState({ linkedorderisOpen: true });
  closLinkedaccountModal = () => this.setState({ linkedorderisOpen: false });

  render() {
    var { values, localCurrency } = this.props;
    // console.log('values',values)
    let grand_total = 0.0;
    let productCurrency = values.product?.odcurrency;

    grand_total = values?.summary.grand_total;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    let tabledate = "";
    tabledate = converDateIntoLocal(
      values.product.currentstatus.updatedat
    ).format("D MMM YYYY");

    // let grand_total     = 0.00;
    // grand_total = (parseFloat(values.summary.grand_total));
    return (
      <>
        {values.product.currentstatus.status != "reshipdelivered" ? (
          <>
            <tr className="bg-white border-b-2 border-gray-50">
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openModal(e, values)}>
                <div>{values.product.invoice_no}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openModal(e, values)}>
                <div>{values.product.sku}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openModal(e, values)}>
                {values.product && values.product.seller
                  ? values.product.seller.username
                  : ""}
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openModal(e, values)}>
                {/* <div>${getIntToFloat(grand_total)}</div> */}
                {currency} {grand_total.toFixed(2)}
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openModal(e, values)}>
                {/* <div>{values.delivery_address.username}</div> */}
                <div>{values.purchasedby.username}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openModal(e, values)}>
                <div>{tabledate}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openModal(e, values)}>
                {_.startCase(values.product.currentstatus.status)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                {/* <div>{values.product.productname_en}</div> */}
                <div>
                  {values.product.productname_en.length > 35
                    ? values.product.productname_en.substring(0, 35) + "..."
                    : values.product.productname_en}
                </div>
                {values && values.hasOwnProperty("linkedorder") ? (
                  <button
                    className="btn-primary mt-2"
                    onClick={(e) => this.openLinkedaccountModal(e, values)}>
                    View Linked Order
                  </button>
                ) : (
                  ""
                )}
              </td>
            </tr>
            {this.state.isOpen ? (
              <AllneworderdetailsModals
                isOpen={this.state.isOpen}
                onClose={this.closeModal}
                // data={values.items}
                data={this.props.values}
                newKey="delivered"
              />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {this.state.linkedorderisOpen ? (
          <AllneworderdetailsModals
            isOpen={this.state.linkedorderisOpen}
            onClose={this.closLinkedaccountModal}
            data={this.props.values.linkedorder?.[0]}
            newKey="delivered"
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    localCurrency: state.currency,
  };
}

export default connect(mapStateToProps, {
  isCurrencyRateApplicable,
})(AllDeliveredoderitemslist);
