import {
  ArrowCircleUpIcon,
  ArrowCircleDownIcon,
} from "@heroicons/react/outline";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../../languages/index";
// Common function
import { classNames } from "../../../../../common/common-functions";

// Constants
import {
  APP_API_KEY,
  BASE_IMAGES_URL,
  DATE_FORMAT_TO_SHOW_SHORT,
} from "../../../../../common/constants";

// Import Component
import SingleImageModal from "./single-image-modal";

const contentStyle = {
  // height: "250px",
  // width: "250px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
  borderRadius: "18px",
};

function ReviewAndRatingLimited(props) {
  const [showReviewList, setShowReviewList] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState("");

  let { productDetails, limit, callFrom, wholeObject } = props;
  let sortedData = [];

  let purchasedbyId = "";
  if (callFrom === "order") {
    purchasedbyId = wholeObject.purchasedby.userid;
  }

  useEffect(() => {
    return () => {
      setShowReviewList(false);
      setisOpen(false);
      setCurrentImg("");
    };
  }, []);

  // Average rating
  let avgRating =
    productDetails && productDetails.ratings && productDetails.ratings.avgrating
      ? productDetails.ratings.avgrating
      : 0;
  let remRating = 5 - avgRating;

  if (
    productDetails &&
    productDetails.reviews &&
    productDetails.reviews.length > 0
  ) {
    // Short data by created date

    if (callFrom === "order") {
      // Order details page
      sortedData = _.filter(
        _.orderBy(productDetails.reviews, ["createdat"], ["desc"]),
        { ratedby: parseInt(purchasedbyId) }
      );
    } else {
      // Product details page
      sortedData = _.orderBy(productDetails.reviews, ["createdat"], ["desc"]);
    }
  }

  const _handelImgeClick = (imgUrl) => {
    setCurrentImg(imgUrl);
    setisOpen(true);
  };

  const renderReviewImages = (item, actindex) => {
    let mediaItem = item?.media?.map((media, index) => {
      return (
        <img
          style={contentStyle}
          className="h-24 w-24 mb-6"
          src={
            `${BASE_IMAGES_URL + "/reviews/" + media}?api_key=` + APP_API_KEY
          }
          alt={`image_${actindex}_${index}`}
          onClick={(e) =>
            _handelImgeClick(
              `${BASE_IMAGES_URL + "/reviews/" + media}?api_key=` + APP_API_KEY
            )
          }></img>
      );
    });

    return mediaItem;
  };

  /* Review list */
  const ReviewList = (item, index) => {
    var userimg = `${BASE_IMAGES_URL}/users/${item.ratedby.userid}.jpg?api_key=${APP_API_KEY}`;
    return (
      <>
        <div className="pb-6">
          <div className="flex">
            {item.ratedby ? (
              <div>
                <img
                  // src={`${BASE_IMAGES_URL}/users/${item.ratedby.userimage}?api_key=${APP_API_KEY}`}
                  src={userimg}
                  className="h-10 w-10 rounded-full"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../images/user_1.png";
                  }}
                />
              </div>
            ) : (
              <div className="bg-delivery rounded-full w-46 h-46"></div>
            )}
            <div className="pl-2 align-top">
              {/* If "feedback_anonymous" => true show "anonymous" otherways show "username" */}
              <div className="text-sm font-medium text-secondary truncate">
                {item.ratedby && item.ratedby.username
                  ? item.feedback_anonymous === 1
                    ? LocaleStrings.anonymous
                    : item.ratedby.username
                  : ""}
              </div>
              <div className="text-sm text-ternary truncate">
                {moment(item.createdat).format(DATE_FORMAT_TO_SHOW_SHORT)}
              </div>
            </div>
          </div>
          <div className="flex pt-2">
            {item.productrating && item.productrating > 0
              ? _.times(item.productrating, (i) => {
                  return (
                    <div className="pr-3">
                      <img
                        src="../images/product_management_images/star.svg"
                        className="w-5"
                      />
                    </div>
                  );
                })
              : ""}

            {item.productrating &&
            item.productrating > 0 &&
            5 - item.productrating > 0
              ? _.times(5 - item.productrating, (i) => {
                  return (
                    <div className="pr-3">
                      <img
                        src="../images/product_management_images/graystar.svg"
                        className="w-5"
                      />
                    </div>
                  );
                })
              : ""}
          </div>
          <div className="text-sm font-semibold text-secondary truncate pt-2">
            {LocaleStrings.reviews}
          </div>
          <div className="text-xs font-medium text-secondary ">
            {item.feedback}
          </div>
        </div>

        <div
          key={index}
          style={{ cursor: "pointer" }}
          onClick={() => setisOpen(true)}>
          {renderReviewImages(item, index)}
        </div>
      </>
    );
  };

  /* Progress Bar */
  const ProgressBar = (rating, total) => {
    let progressPercentage = (rating / total) * 100;

    return (
      <div className="h-2 w-full bg-ternaryHover rounded-md">
        {/* {progressPercentage > 0 ? ( */}
        <div
          style={{ width: "150px" }}
          className={`h-full rounded-md ${
            progressPercentage > 30
              ? "bg-green-500"
              : progressPercentage > 20 && progressPercentage < 30
              ? "bg-red-500"
              : "bg-orange-500"
          }`}></div>
        {/* ) : (
              ""
            )} */}
      </div>
    );
  };

  /* Toggle Less & more review box list */
  const _reviewListStateBoxToggle = () => {
    setShowReviewList(!showReviewList);
  };

  return (
    <>
      {/* ACTUAL REVIEW GRAPH */}
      <div className="text-base font-semibold text-primary mt-4">
        {LocaleStrings.reviews}
      </div>
      <div className="text-left pt-2.5 flex">
        {avgRating && avgRating > 0
          ? _.times(avgRating, (i) => {
              return (
                <div className="pr-3">
                  <img src="../images/product_management_images/star.svg" />
                </div>
              );
            })
          : ""}

        {avgRating && avgRating > 0 && remRating > 0
          ? _.times(remRating, (i) => {
              return (
                <div className="pr-3">
                  <img src="../images/product_management_images/graystar.svg" />
                </div>
              );
            })
          : ""}

        {avgRating && avgRating > 0 ? (
          <div className="pl-lg text-primary text-base font-medium">
            {avgRating} out of 5
          </div>
        ) : (
          ""
        )}
      </div>
      {productDetails &&
      productDetails.ratings &&
      productDetails.ratings.totalratings &&
      productDetails.ratings.totalreviews ? (
        <div className="text-left pt-3.5 flex text-sm text-cardfooter">
          {productDetails.ratings.totalratings} Ratings and{" "}
          {productDetails.ratings.totalreviews} {LocaleStrings.reviews}
        </div>
      ) : (
        ""
      )}
      {productDetails &&
      productDetails.ratings &&
      productDetails.ratings.totalratings > 0
        ? _.times(5, (i) => {
            return (
              <div className="text-left pt-3.5 flex">
                <div>
                  <img
                    src="../images/product_management_images/bluestar.svg"
                    className="w-6"
                  />
                </div>
                <div className="pl-2 text-primary text-sm">{5 - i} Star</div>
                <div className="pl-2 w-240 xl:w-292 lg:w-292 md:w-292 flex items-center">
                  {productDetails.ratings
                    ? ProgressBar(
                        productDetails.ratings[`rating${5 - i}`],
                        productDetails.ratings.totalratings
                      )
                    : "rrrr"}
                </div>
                <div className="pl-2 text-cardfooter text-sm">
                  {productDetails.ratings
                    ? productDetails.ratings[`rating${5 - i}`]
                    : 0}
                </div>
              </div>
            );
          })
        : ""}

      {/* REVIEW COMMENT LIST WITH STARTS   */}
      {sortedData.length > 0 ? (
        <>
          {/* Show limited Review */}
          <div className="text-left pt-10">
            {sortedData.slice(0, limit).map((item, index) => {
              return ReviewList(item);
            })}
          </div>

          {/* Show data if review list length > limit   */}
          {sortedData.length > limit ? (
            <>
              {/* More and less for review  Button*/}
              <div className="grid grid-cols-2 mt-2">
                <div>{LocaleStrings.prooduct_reviews_rating}</div>
                <div className="end-item ">
                  {showReviewList ? (
                    <ArrowCircleUpIcon
                      className="h-6 w-6 cursor-pointer mt-1"
                      aria-hidden="true"
                      onClick={_reviewListStateBoxToggle}
                    />
                  ) : (
                    <ArrowCircleDownIcon
                      className="h-6 w-6 cursor-pointer mt-1"
                      aria-hidden="true"
                      onClick={_reviewListStateBoxToggle}
                    />
                  )}
                </div>
              </div>

              {/* Show full Review  List if show button open*/}
              {sortedData.length > 0 ? (
                <div
                  className={classNames(
                    showReviewList ? "" : "hidden",
                    "text-left pt-10"
                  )}>
                  {/* Show full review list */}
                  {sortedData.map((item, index) => {
                    return ReviewList(item);
                  })}
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      <SingleImageModal
        isOpen={isOpen}
        setisOpen={setisOpen}
        currentImg={currentImg}
        setCurrentImg={setCurrentImg}
      />
    </>
  );
}

function mapStateToProps(state) {
  var { session } = state;

  return {
    session,
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapStateToProps, {})(ReviewAndRatingLimited);
