import { INSTANCE_URL } from "../../../../common/constants";
import {
  getRequest,
  postRequest,
  deleteRequest,
} from "../../../../common/network-call";
import _ from "lodash";

export var SELECTED_SIDEBAR_MENU = "SELECTED_SIDEBAR_MENU";
export var NOTIFICATION_LIST = "NOTIFICATION_LIST";
export var ALl_USERS = "ALl_USERS";
export var CUSTOMNOTIFICATIOSLIDER = "CUSTOMNOTIFICATIOSLIDER";
export var ADMIN_ROLES = "ADMIN_ROLES";

export function onSidebarMenuSelected(selectedMenu) {
  // console.log('selectedMenu',selectedMenu);
  return (dispatch) => {
    dispatch({ type: SELECTED_SIDEBAR_MENU, payload: selectedMenu });
  };
}

// export function fetchCustomer(session, pageCount = 0,callback,) {
export function fetchNotificationlist(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendpush`;
  return (dispatch) => {
    postRequest(
      url,
      {
        pushtype: "fetchnotification",
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchnotification', data);
        dispatch({
          type: NOTIFICATION_LIST,
          payload: { data: data.data },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function addNotifications(session, body, callback) {
  body["pushtype"] = "broadcast_notification";

  var url = `${INSTANCE_URL}/api/v2/sendpush`;
  // console.log('broadcast_notification',body);
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

export function fetchAllUsers(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users/`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data.meta.count);
        dispatch({
          type: ALl_USERS,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function isCutomnotificationOpen(isOpen) {
  return { type: CUSTOMNOTIFICATIOSLIDER, payload: isOpen };
}

export function deleteNotifications(session, values, callback) {
  var filter = encodeURI(`filter=(id=${values.id})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/notifications?${filter}`;
  // console.log('url',url)
  // console.log('values',values)
  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (data) => {
        callback({ error: 0, result: data });
      },
      (error) => {
        callback({ error: 1, message: error });
      }
    );
  };
}

export function fetchAdminRoles(session, userid, callback) {
  var filter = encodeURI(`filter=(useridfk=${userid})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/admin?related=admin_role_by_roleid&${filter}`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        let sidebarMenus = [];
        _.forEach(data.resource, function (value) {
          sidebarMenus.push(value.admin_role_by_roleid);
        });

        dispatch({
          type: ADMIN_ROLES,
          payload: { data: sidebarMenus },
        });
        callback({ success: 1, data: sidebarMenus[0] });
      },
      (error) => {}
    );
  };
}

//
