import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
// import { showError, showSuccess } from "ReduxErrorBar";
import { parse } from "date-fns";

import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../common/base-component";
import moment from "moment-timezone";
import _ from "lodash";
import Dropzone from "react-dropzone";
import { isEmpty } from "../../../../common/common-functions";
import {
  uploadNewBanner,
  updateAdvertisement,
  fetchProductsByCateSubCate,
  fetchAllAdds,
  fetchProductCategory,
  fetchProductSubcategory,
  fetchallProducts,
  searchAdds,
} from "../actions/index";
import {
  ProductTextLocalized,
  classNames,
} from "../../../../common/common-functions";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../common/constants";
import { NotificationManager } from "react-notifications";

const adds_on = [
  { name: "Category", value: "category", index: 0 },
  { name: "Sub-category", value: "sub-category", index: 1 },
  { name: "Product", value: "product", index: 2 },
];

class EditAdvertisement extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      showerror: false,
      adds_on: adds_on,
      btnLoader: false,
      alertMessage: "",
      fileUrl: "",
      fileArr: {},
      fileArrNew: {},
      newAddAreaType: this.props.initialValues.add_area,
      selectedCateId: "",
      forPositioning: "",
      wheretoareaType: "",
      btnLoader: false,
      filetype: "",
    };

    // All Binded Functions

    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onDropFiles = this.onDropFiles.bind(this);
  }

  componentDidMount() {
    this.props.fetchProductCategory(this.props.session, (callBack) => {});
    // Get ALL ProductList
    this.props.fetchallProducts(this.props.session, (callBack) => {});

    // Subcategories
    this.props.fetchProductSubcategory(this.props.session, (callBack) => {});
  }

  onDropFiles = async (files) => {
    // console.log('ondrop',files)
    let file = files[0];
    let filearr = [];

    // console.log("file", file);

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e) => {
      file.url = e.target.result;
      // file.name   = file.name;
      // file.url = reader.result;
      filearr.push(file);
    };
    // console.log('filearr',filearr);
    this.setState({ filetype: file.type });
    this.setState({ fileArrNew: filearr });
    // console.log('fileData',fileData);
    // console.log('URL.createObjectURL(fileData)',URL.createObjectURL(fileData));
    this.setState({ fileUrl: URL.createObjectURL(file) });
  };

  onSubmitForm(values) {
    let type = "image";
    this.setState({ btnLoader: true });
    // console.log('this.state.newAddAreaType',this.state.newAddAreaType)

    if (_.isEmpty(values)) {
      NotificationManager.error(LocaleStrings.ads_management_addDataText);
    }
    // console.log("allpros", allproducts);
    if (
      values.add_area == "category" &&
      (values.categoryids == "" || values.categoryids == null)
    ) {
      NotificationManager.error(LocaleStrings.ads_management_requiredCategory);

      return false;
    } else if (
      values.add_area == "subcategory" &&
      (values.subcategoryids == "" || values.subcategoryids == null)
    ) {
      NotificationManager.error(
        LocaleStrings.ads_management_requiredsubCategory
      );

      return false;
    } else if (
      values.add_area == "product" &&
      (values.productid == "" || values.productid == null)
    ) {
      NotificationManager.error(LocaleStrings.ads_management_requiredProduct);
      return false;
    } else if (
      values.add_area == "external" &&
      (values.externallink == "" || values.externallink == null)
    ) {
      NotificationManager.error("External Link is required");
      return false;
    }

    if (
      (values.whereto == "product" && values.whereto_productid == null) ||
      values.whereto_productid == ""
    ) {
      NotificationManager.error("Please choose showing productid");
      return false;
    } else if (
      (values.whereto == "category" && values.whereto_categoryid == null) ||
      values.whereto_categoryid == ""
    ) {
      NotificationManager.error("Please choose showing categorytid");
      return false;
    }
    // console.log("newAddAreaType", this.state.newAddAreaType);
    let validitytill = "";
    if (typeof values.validitytill === "string") {
      validitytill = parse(
        moment(values.validitytill).format("DD-MM-YYYY HH:mm:ss"),
        "dd-MM-yyyy HH:mm:ss",
        new Date()
      );
    } else {
      validitytill = values.validitytill;
    }

    this.setState({ btnLoader: true });

    let resource_coverimage = "";
    let uploaderCounter = 0;
    let filetobeupload = [];
    let arrPromise = [];
    //let { fileArrNew } = this.state;

    // console.log("---------", this.state.fileArrNew);
    if (Object.keys(this.state.fileArrNew).length > 0) {
      // Image Exist
      let fileArrNew = this.state.fileArrNew[0];

      let ts = new Date().getTime(); // Timestamp

      // let medianame = "storecover_" + ts + "." + 'jpeg'; // Image Name Creation
      let medianame = "ads" + ts + "." + "jpg"; // Image Name Creation
      if (this.state.filetype == "video/mp4") {
        type = "video";
        medianame = "ads" + ts + "." + "mp4"; // Image Name Creation
      }
      resource_coverimage = medianame;

      let ext = fileArrNew.name.split(".").pop(); // File extension

      let coverJson = {
        // type: "cover",
        type: "image",
        name: medianame,
        media: fileArrNew.url,
      };
      filetobeupload.push(coverJson);
      let counter = filetobeupload.length;

      filetobeupload.map((item, index) => {
        arrPromise.push(
          new Promise((resolve, reject) => {
            this.props.uploadNewBanner(this.props.session, item, (response) => {
              if (response.success === 0) {
                resolve();
              } else {
                uploaderCounter++;
                resolve();
              }
            });
          })
        );
      });
      // if (this.state.newAddAreaType == "category") {
      let newAddArrdata = {
        id: values.id,
        banner: medianame,
        add_area: this.state.newAddAreaType,
        categoryids:
          this.state.newAddAreaType == "category"
            ? values.categoryids.value
            : null,
        subcategoryids:
          this.state.newAddAreaType == "subcategory"
            ? values.subcategoryids.value
            : null,
        productid:
          this.state.newAddAreaType == "product"
            ? values.productid.value
            : null,
        externallink:
          this.state.newAddAreaType == "external" ? values.externallink : null,
        validitytill: values.validitytill,
        whereto: values.whereto,
        whereto_categoryid:
          values.whereto == "category" ? values.whereto_categoryid.value : null,
        whereto_productid:
          values.whereto == "product" ? values.whereto_productid.value : null,
        position: values.position,
      };

      Promise.all(arrPromise).then(() => {
        this.props.updateAdvertisement(
          this.props.session,
          newAddArrdata,
          (response) => {
            if (response.success === 0) {
              this.props.onClose();
              NotificationManager.error(LocaleStrings.updatederror);
            } else {
              this.props.onClose();
              // alert('done');
              NotificationManager.success(LocaleStrings.updatedsuccessfully);
              if (this.props.search == "") {
                this.props.fetchAllAdds(
                  this.props.session,
                  0,
                  (CallBackResponse) => {}
                );
              } else {
                this.props.searchAdds(this.props.session, this.props.search);
              }
            }
          }
        );
      });
    } else {
      // if (this.state.newAddAreaType == "category") {
      // let newAddArrdata = {
      //   id: values.id,
      //   categoryids: values.categoryids,
      //   subcategoryids: values.subcategoryids,
      //   productid: values.productid,
      //   categoryids:
      //     this.state.newAddAreaType == "category" ? values.categoryids : null,
      //   subcategoryids:
      //     this.state.newAddAreaType == "subcategory"
      //       ? values.subcategoryids
      //       : null,
      //   productid:
      //     this.state.newAddAreaType == "product" ? values.productid : null,
      //   externallink:
      //     this.state.newAddAreaType == "external" ? values.externallink : null,
      //   whereto: values.whereto,
      //   whereto_categoryid:
      //     values.whereto == "category" ? values.whereto_categoryid : null,
      //   whereto_productid:
      //     values.whereto == "product" ? values.whereto_productid : null,
      //   validitytill: values.validitytill,
      //   add_area: this.state.newAddAreaType,
      // };

      let newAddArrdata = {
        id: values.id,
        add_area: this.state.newAddAreaType,
        categoryids:
          this.state.newAddAreaType == "category"
            ? values.categoryids.value
            : null,
        subcategoryids:
          this.state.newAddAreaType == "subcategory"
            ? values.subcategoryids.value
            : null,
        productid:
          this.state.newAddAreaType == "product"
            ? values.productid.value
            : null,
        externallink:
          this.state.newAddAreaType == "external" ? values.externallink : null,
        validitytill: values.validitytill,
        whereto: values.whereto,
        whereto_categoryid:
          values.whereto == "category" ? values.whereto_categoryid.value : null,
        whereto_productid:
          values.whereto == "product" ? values.whereto_productid.value : null,
        position: values.position,
      };
      // console.log("values", values);
      // console.log("newAddArrdata", newAddArrdata);
      // return false;
      // Promise.all(arrPromise).then(() => {
      this.props.updateAdvertisement(
        this.props.session,
        newAddArrdata,
        (response) => {
          if (response.success === 0) {
            // alert('error');
            this.props.onClose();
            NotificationManager.error(LocaleStrings.updatederror);
          } else {
            this.props.onClose();
            // alert('done');
            NotificationManager.success(LocaleStrings.updatedsuccessfully);
            if (this.props.search == "") {
              this.props.fetchAllAdds(
                this.props.session,
                0,
                (CallBackResponse) => {}
              );
            } else {
              this.props.searchAdds(this.props.session, this.props.search);
            }
          }
        }
      );
      // });
    }
  }

  _handleNewAdd(e) {
    let areaType = "";
    if (e.target.value != "" && e.target.value != null) {
      areaType = _.find(this.props.NewAddArea, {
        value: e.target.value,
      }).value;
    }
    this.setState({ newAddAreaType: areaType });
  }

  _handleposition(e) {
    // console.log("this.state.newAddAreaType---:", this.state.newAddAreaType);
    let mandateType = "";
    if (e.target.value != "" && e.target.value != null) {
      mandateType = _.find(this.props.positionAdd, {
        value: e.target.value,
      }).value;
    }
    this.setState({ forPositioning: mandateType });

    let areaType = "";

    areaType = this.state.newAddAreaType;

    this.setState({ newAddAreaType: areaType });
  }

  _handlewhereToshow(e) {
    let wheretoareaType = "";
    if (e.target.value != "" && e.target.value != null) {
      wheretoareaType = _.find(this.props.whereTo, {
        value: e.target.value,
      }).value;
    }
    this.setState({ wheretoareaType: wheretoareaType });
  }

  _updatenewAddAreaType(e) {
    let areaType = "";

    areaType = this.state.newAddAreaType;

    this.setState({ newAddAreaType: areaType });
  }

  // Category / Subcategory  / Product Select
  _handleIdcategoryScreen(e) {
    // let selectedFiledName = e.target.name;
    // let selectedFiledValue = e.target.value;
    // if (selectedFiledName === "categoryids") {
    //   // Category
    //   this.setState({ selectedCateId: selectedFiledValue });
    //   let subCateID = this.state.selectedSubCateId;
    //   // If category || Sub-category present fetch Product
    //   this.props.fetchProductsByCateSubCate(
    //     this.props.session,
    //     selectedFiledValue,
    //     "",
    //     (callBack) => { }
    //   );
    // } else if (selectedFiledName === "subcategoryids") {
    //   // Sub-category
    //   this.setState({ selectedSubCateId: selectedFiledValue });
    //   let cateID = this.state.selectedCateId;
    //   // If category || Sub-category present fetch Product
    //   this.props.fetchProductsByCateSubCate(
    //     this.props.session,
    //     cateID,
    //     selectedFiledValue,
    //     (callBack) => { }
    //   );
    // } else if (selectedFiledName === "productid") {
    //   // product
    //   this.setState({ selectedProductId: selectedFiledValue });
    // } else {
    // }
  }

  render() {
    var { handleSubmit, language, initialValues, productSubCategory } =
      this.props;
    let {
      fileUrl,
      newAddAreaType,
      selectedCateId,
      forPositioning,
      wheretoareaType,
      filetype,
    } = this.state;
    let coverimage = "";
    if (isEmpty(fileUrl)) {
      let text = initialValues.banner;
      const myArray = text.split(".");

      let type = "";
      let initialFiletype = "";
      if (myArray.length > 0) {
        type = myArray["1"];
      }

      if (type == "jpg") {
        initialFiletype = "image/jpeg";
      } else {
        initialFiletype = "video/mp4";
      }

      filetype = filetype === "" ? initialFiletype : filetype;
      coverimage = `${BASE_IMAGES_URL}/adbanners/${initialValues.banner}?api_key=${APP_API_KEY}`;
    } else {
      coverimage = fileUrl;
    }

    // console.log('initialValues', initialValues)
    forPositioning =
      forPositioning === "" ? initialValues.position : forPositioning;
    newAddAreaType =
      newAddAreaType === "" ? initialValues.add_area : newAddAreaType;
    wheretoareaType =
      wheretoareaType === "" ? initialValues.whereto : wheretoareaType;
    newAddAreaType =
      newAddAreaType === ""
        ? _.find(this.props.NewAddArea, {
            value: initialValues.add_area,
          }).value
        : newAddAreaType;
    // console.log('initialValues.categoryids',initialValues)
    selectedCateId =
      selectedCateId === "" ? initialValues.categoryids : selectedCateId;

    var newaddAreaOptions = []; // Add Area
    var AddCateOptions = []; // Category
    var AddSubCateOptions = []; // Sub-Category
    var AddProductOptions = []; // Product
    var placeArrOptions = [];
    var whereToArrOptions = [];
    var categoryfor_banerrShowArr = [];
    var productfor_banerrShowArr = [];

    //Discount Area Object Creation
    _.forEach(this.props.NewAddArea, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name),
        value: value.value,
      };

      newaddAreaOptions.push(obj);
    });
    _.forEach(this.props.whereTo, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name),
        value: value.value,
      };

      whereToArrOptions.push(obj);
    });

    //Add Category for whereto Object Creation
    _.forEach(this.props.productCategory, function (value) {
      var obj = {
        label: ProductTextLocalized(
          value.categoryname_en,
          value.categoryname_ar,
          language
        ),
        value: value.id.toString(),
      };

      categoryfor_banerrShowArr.push(obj);
    });
    //Add PRoduct for whereto Object Creation
    _.forEach(this.props.allproductforAds, function (value) {
      var obj = {
        label: ProductTextLocalized(
          value.productname_en,
          value.productname_en,
          language
        ),
        value: value.id.toString(),
      };

      productfor_banerrShowArr.push(obj);
    });

    _.forEach(this.props.positionAdd, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name),
        value: value.value,
      };

      placeArrOptions.push(obj);
    });

    //Add Category Object Creation
    _.forEach(this.props.productCategory, function (value) {
      var obj = {
        label: ProductTextLocalized(
          value.categoryname_en,
          value.categoryname_ar,
          language
        ),
        value: value.id.toString(),
      };

      AddCateOptions.push(obj);
    });

    _.forEach(this.props.productSubCategory, function (value) {
      // Filter sub sub category list whose parent id
      let subSubCategory = _.filter(productSubCategory, {
        parentid: parseInt(value.id),
      });

      if (subSubCategory.length > 0) {
        // If sub sub category is present
        _.forEach(subSubCategory, function (subSubObject, subSubIndex) {
          // loop through Sub sub category

          var obj = {
            label: ProductTextLocalized(
              value.subcategoryname_en +
                " - " +
                subSubObject.subcategoryname_en,
              value.subcategoryname_ar +
                " - " +
                subSubObject.subcategoryname_ar,
              language
            ),
            value: subSubObject.id.toString(),
          };

          AddSubCateOptions.push(obj);
        });
      } else {
        // if sub sub category is not there for current subcategory

        var obj = {
          label: ProductTextLocalized(
            value.subcategoryname_en,
            value.subcategoryname_ar,
            language
          ),
          value: value.id.toString(),
        };

        AddSubCateOptions.push(obj);
      }

      // var obj = {
      //   name: ProductTextLocalized(
      //     value.subcategoryname_en,
      //     value.subcategoryname_ar,
      //     language
      //   ),
      //   value: value.id,
      // };

      // AddSubCateOptions.push(obj);
    });

    //Add Product (On select Cate | Subcate) Object Creation
    _.forEach(this.props.allproductforAds, function (value) {
      var obj = {
        label: ProductTextLocalized(
          value.productname_en,
          value.productname_ar,
          language
        ),
        value: value.id.toString(),
      };

      AddProductOptions.push(obj);
    });

    return (
      <Transition.Root show={this.props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={this.myRef}
          open={this.props.isOpen}
          onClose={this.props.onClose}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block align-bottom bg-white rounded-lg  pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ">
                <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                  <div className="mt-4">
                    <div className="text-md leading-6 font-medium text-secondary pl-3">
                      {LocaleStrings.ads_management_editads}
                    </div>
                  </div>
                  <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                    <button
                      type="button"
                      className="btn-closeModal"
                      onClick={this.props.onClose}>
                      <span className="sr-only">{LocaleStrings.close}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <form
                  className=" p-6"
                  onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
                  {/* MOdal Header Ends*/}
                  <div className="grid grid-cols-2">
                    <div>Upload Add Banner</div>
                    <div class="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                      <div className="dropzonecover" key={`drop`}>
                        <div className="img_wrpcover relative">
                          {this.state.filetype == "image/jpeg" ? (
                            <img src={coverimage} className="dropimgcover" />
                          ) : this.state.filetype == "video/mp4" ? (
                            <video
                              controls
                              controlsList="nodownload"
                              className="dropimgcover">
                              <source src={coverimage} type="video/mp4" />
                            </video>
                          ) : (
                            <img src={coverimage} className="dropimgcover" />
                          )}
                        </div>

                        <Dropzone
                          onDrop={(e) => this.onDropFiles(e)}
                          accept="image/jpeg, image/png ,image.jpg ,video/mp4,image/gif"
                          multiple={false}>
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps()}
                              className="h-full w-full absolute top-0">
                              <input {...getInputProps()} />
                              <p className="paragraph-seconday text-xs pt-10">
                                {/* {LocaleStrings.imageupload} */}
                              </p>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </div>

                  <div className="my-5">
                    <p>{LocaleStrings.ads_management_validity}</p>
                    <p className="mb-2 text-xs text-gray-500">
                      {LocaleStrings.ads_management_dateText}
                    </p>
                    <Field
                      name="validitytill"
                      // component={this.renderDateTimePickerWithLabel_New}
                      component={this.renderDateTimePickerWithLabel1}
                      mandatory={true}
                      autoComplete="off"
                      // selected={moment()}
                      minDate={
                        Object.keys(initialValues).length > 0
                          ? ""
                          : new Date(moment().format("YYYY,MM,DD"))
                      }
                      className="cust-input-field"
                      classNameLabel="text-box-lable"
                      onChange={this._updatenewAddAreaType.bind(this)}
                    />
                  </div>
                  <div className="mb-5">
                    <Field
                      name="add_area"
                      label={LocaleStrings.link_to}
                      placeholder={LocaleStrings.select}
                      component={this.renderSelect}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      opts={newaddAreaOptions}
                      className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                      classNameLabel="block text-box-lable"
                      onChange={this._handleNewAdd.bind(this)}
                      // value={this.props.initialValues.type}
                    />
                  </div>
                  {newAddAreaType == "category" ? (
                    <div className="mb-5">
                      <Field
                        // name="categoryids"
                        // label={LocaleStrings.discount_product_category}
                        // placeholder={LocaleStrings.select}
                        // component={this.renderSelect}
                        // mandatory="false"
                        // labelposition={LABEL_POSITION_TOP}
                        // opts={AddCateOptions}
                        // className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        // classNameLabel="block text-box-lable"
                        // onChange={this._handleIdcategoryScreen.bind(this)}

                        name="categoryids"
                        label={LocaleStrings.discount_product_category}
                        placeholder={LocaleStrings.select}
                        options={AddCateOptions}
                        component={this.renderAutoComplete}
                        labelposition={LABEL_POSITION_TOP}
                        labelKey="label"
                        valueKey="value"
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                        onChange={this._handleIdcategoryScreen.bind(this)}
                        isMulti={false}
                        mandatory="true"
                      />
                    </div>
                  ) : newAddAreaType == "subcategory" ? (
                    <div className="mb-5">
                      <Field
                        // name="subcategoryids"
                        // label={LocaleStrings.discount_product_subcategory}
                        // placeholder={LocaleStrings.select}
                        // component={this.renderSelect}
                        // mandatory="false"
                        // labelposition={LABEL_POSITION_TOP}
                        // opts={AddSubCateOptions}
                        // className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        // classNameLabel="block text-box-lable"
                        // onChange={this._handleIdcategoryScreen.bind(this)}
                        name="subcategoryids"
                        label={LocaleStrings.discount_product_subcategory}
                        placeholder={LocaleStrings.select}
                        options={AddSubCateOptions}
                        component={this.renderAutoComplete}
                        labelposition={LABEL_POSITION_TOP}
                        labelKey="label"
                        valueKey="value"
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                        onChange={this._handleIdcategoryScreen.bind(this)}
                        isMulti={false}
                        mandatory="true"
                      />
                    </div>
                  ) : newAddAreaType == "product" ? (
                    <div className="mb-5">
                      <Field
                        // name="productid"
                        // label={LocaleStrings.discount_product}
                        // placeholder={LocaleStrings.select}
                        // component={this.renderSelect}
                        // mandatory="false"
                        // labelposition={LABEL_POSITION_TOP}
                        // opts={AddProductOptions}
                        // className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        // classNameLabel="block text-box-lable"
                        // onChange={this._handleIdcategoryScreen.bind(this)}

                        name="productid"
                        label={LocaleStrings.discount_product}
                        placeholder={LocaleStrings.select}
                        options={AddProductOptions}
                        component={this.renderAutoComplete}
                        labelposition={LABEL_POSITION_TOP}
                        labelKey="label"
                        valueKey="value"
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                        onChange={this._handleIdcategoryScreen.bind(this)}
                        isMulti={false}
                        mandatory="true"
                      />
                    </div>
                  ) : newAddAreaType == "external" ? (
                    <div className="mb-5">
                      <Field
                        name="externallink"
                        placeholder="External Link"
                        label="External Link"
                        type="text"
                        component={this.renderAllfields}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="mb-5">
                    <Field
                      name="whereto"
                      label="Show Banner To"
                      placeholder={LocaleStrings.select}
                      component={this.renderSelect}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      opts={whereToArrOptions}
                      className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                      classNameLabel="block text-box-lable"
                      onChange={this._handlewhereToshow.bind(this)}
                    />
                  </div>

                  {wheretoareaType == "category" ? (
                    <div className="mb-5">
                      <Field
                        // name="whereto_categoryid"
                        // label="Showing Categoryid"
                        // placeholder={LocaleStrings.select}
                        // component={this.renderSelect}
                        // mandatory="false"
                        // labelposition={LABEL_POSITION_TOP}
                        // opts={categoryfor_banerrShowArr}
                        // className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        // classNameLabel="block text-box-lable"
                        name="whereto_categoryid"
                        label="Showing Categoryid"
                        placeholder={LocaleStrings.select}
                        options={categoryfor_banerrShowArr}
                        component={this.renderAutoComplete}
                        labelposition={LABEL_POSITION_TOP}
                        labelKey="label"
                        valueKey="value"
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                        isMulti={false}
                        mandatory="true"
                      />
                    </div>
                  ) : wheretoareaType == "product" ? (
                    <div className="mb-5">
                      <Field
                        // name="whereto_productid"
                        // label="Showing Productid"
                        // placeholder={LocaleStrings.select}
                        // component={this.renderSelect}
                        // mandatory="false"
                        // labelposition={LABEL_POSITION_TOP}
                        // opts={productfor_banerrShowArr}
                        // className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        // classNameLabel="block text-box-lable"

                        name="whereto_productid"
                        label="Showing Productid"
                        placeholder={LocaleStrings.select}
                        options={productfor_banerrShowArr}
                        component={this.renderAutoComplete}
                        labelposition={LABEL_POSITION_TOP}
                        labelKey="label"
                        valueKey="value"
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                        onChange={this._handleIdcategoryScreen.bind(this)}
                        isMulti={false}
                        mandatory="true"
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {/* <div className="mb-5">
                    <Field
                      name="position"
                      label="Position"
                      placeholder={LocaleStrings.select}
                      component={this.renderSelect}
                      labelposition={LABEL_POSITION_TOP}
                      opts={placeArrOptions}
                      className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                      classNameLabel="block text-box-lable"
                      onChange={this._handleposition.bind(this)}
                      // value={this.props.initialValues.type}
                    />
                  </div> */}
                  {/* {forPositioning == "random" ? (
                    <div className="lg:flex mb-5 pl-6">
                      <div>
                        <p className="text-xs pt-3 pr-2">{LocaleStrings.ads_management_repeat}</p>
                      </div>
                      <div>
                        {" "}
                        <Field
                          name="repeattimes"
                          type="text"
                          placeholder="No. of times"
                          component={this.renderFieldAds}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                      <div>
                        <p className="text-xs pt-3 px-3">{LocaleStrings.ads_management_afterevery}</p>
                      </div>
                      <div>
                        {" "}
                        <Field
                          name="rowsno"
                          placeholder="No. of rows"
                          type="text"
                          component={this.renderFieldAds}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                  <div className="mt-10">
                    <div className="flex justify-center">
                      <button type="submit" className="button flex">
                        <svg
                          className={classNames(
                            this.state.btnLoader ? "" : "sr-only",
                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function validate(values) {
  var errors = {};

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log('addProductByCateSubCate',state.EditAdds)
  return {
    session,
    user,
    whereTo: state.whereTo,
    NewAddArea: state.NewAddArea,
    productCategory: state.addProductCategory
      ? state.addProductCategory.data != "undefined"
        ? state.addProductCategory.data
        : []
      : [],
    positionAdd: state.positionAdd,
    productSubCategory: state.addProductSubCategory
      ? state.addProductSubCategory.data != "undefined"
        ? state.addProductSubCategory.data
        : []
      : [],
    productByCateSubCate: state.addProductByCateSubCate
      ? state.addProductByCateSubCate.data !== "undefined"
        ? state.addProductByCateSubCate.data
        : []
      : [],
    allproductforAds: state.allproductforAds
      ? state.allproductforAds.data !== "undefined"
        ? state.allproductforAds.data
        : []
      : [],
    initialValues: state.EditAdds,
  };
}

// export default reduxForm({
//   validate,
//   form: "Editadds",
// })(
//   connect(mapStateToProps, {
//     uploadNewBanner,
//     addAdvertisement,
//     fetchProductsByCateSubCate,
//   })(EditAdvertisement)
// );

export default connect(mapStateToProps, {
  uploadNewBanner,
  updateAdvertisement,
  fetchProductsByCateSubCate,
  fetchAllAdds,
  fetchProductCategory,
  fetchProductSubcategory,
  fetchallProducts,
  searchAdds,
})(
  reduxForm({
    validate,
    form: "Editadds",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EditAdvertisement)
);
