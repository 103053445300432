import LocaleStrings from "../../../languages/index";
import {
  SPECS_LIST,
  SPECSMANAGEMENT_PRODUCTCATEGORY,
  SPECSMANAGEMENT_PRODUCTSUBCATEGORY,
  SPECSMANAGEMENT_PRODUCTSUBSUBCATEGORY,
  SPECSMANAGEMENT_PRODUCTLIST,
  IS_SPECSMANAGEMENT_MODAL_OPEN,
  EDIT_SPECS,
  SPECS_MASTER_SINGLE,
} from "../actions/index";

export function specificationlisting(state = {}, action) {
  switch (action.type) {
    case SPECS_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}

// /**** Get Categories, Subcategories, Criteria,  Discount Area ****/

// PRODUCT BY CATEGORY SUBCATEGORY
export var specificationProductByCateSubCate = (state = {}, action) => {
  switch (action.type) {
    case SPECSMANAGEMENT_PRODUCTLIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// PRODUCT CATEGORY
export var specificationProductCategory = (state = {}, action) => {
  switch (action.type) {
    case SPECSMANAGEMENT_PRODUCTCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// PRODUCT SUBCATEGORY
export var specificationProductSubCategory = (state = {}, action) => {
  switch (action.type) {
    case SPECSMANAGEMENT_PRODUCTSUBCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
// PRODUCT SUBCATEGORY
export var specificationProductSubSubCategory = (state = {}, action) => {
  switch (action.type) {
    case SPECSMANAGEMENT_PRODUCTSUBSUBCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var dataforeditspecs = (state = {}, action) => {
  switch (action.type) {
    case EDIT_SPECS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** Store IS modal Open Or not As Discount Management  ****/
export var isSpecsManageModalopen = (state = false, action) => {
  if (action.type === IS_SPECSMANAGEMENT_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

export var Golbalspecification = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Yes", value: 1, id: 1 },
    { name: "No", value: 0, id: 0 },
  ];

  return state;
};
export var Isspecificationmandatory = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Yes", value: "yes", id: 1 },
    { name: "No", value: "no", id: 0 },
  ];

  return state;
};

/**** Store Single Discount Object ****/
export var specssingleDiscountObject = (state = false, action) => {
  if (action.type === SPECS_MASTER_SINGLE) {
    state = action.payload;
  }

  return state;
};
