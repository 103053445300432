import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages";
import _ from "lodash";
import {
  ProductTextLocalized,
  timeAgo,
  classNames,
  isEmpty,
} from "../../../../common/common-functions";
import { APP_API_KEY, BASE_IMAGES_URL } from "../../../../common/constants";
// Import Actions
import {
  isSendMessageModalOpen,
  fetchOrderMessageList, // orders threads
  updateOrderStep, // Fc to change order step
  fetchOrderThreadMsgList,
  updateBasicOrderthreadData,
  sendOrderMessageToStore,
  selleridStore,
} from "../actions/index";
// Component Import
import NoContentSlideOver from "../../../../common/no-content-slideover"; // Common No Content For Slide Over Modals
// Import BaseLoader
import BaseLoader from "../../../../common/base-loader";

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";

class Message extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      messageText: "",
      stateThreadid: "",
      loading: true,
      btnLoader: false,
      loaderDesc: LocaleStrings.preparing_messages,
      receiverName: "",
      productName: "",
      update: false,
    };

    // All Binded Functions
    this.closeSlideOvers = this.closeSlideOvers.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      setInterval(() => {
        if (this.props.isMessageOpen) {
          // Orders
          this.props.fetchOrderMessageList(
            this.props.session,
            this.props.storingsellerid.sellerid,
            (callBack) => {
              this.setState({ loading: false });
            }
          );
        }
      }, 3000);
    }
  }

  componentDidUpdate() {
    // let { notifiSingleObjectStore } = this.props; // For Notification
    // if (notifiSingleObjectStore && !_.isEmpty(notifiSingleObjectStore)) {
    //   if (
    //     notifiSingleObjectStore.notifications_by_notificationid
    //       .notificationtype === "newmessage"
    //   ) {
    //     let threadid =
    //       notifiSingleObjectStore.notifications_by_notificationid.threadid;
    //     this.viewMessage(threadid);
    //     this.props.NotificationSingleObject({}); // When Close Modal Blank Already selected Notification Object
    //   }
    // }
  }
  componentWillReceiveProps(newProps) {
    // console.log('newProps',newProps)
    if (this.state.update == false) {
      this.setState({ update: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      step: 1,
      messageText: "",
      stateThreadid: "",
      loading: true,
      btnLoader: "",
      receiverName: "",
      productName: "",
    });
    // this.props.updateOrderStep(1);
  }

  /**** Close Slide Over  ****/
  closeSlideOvers() {
    this.props.isSendMessageModalOpen(false);
    // this.props.NotificationSingleObject({}); // When Close Modal Blank Already selected Notification Object
    // this.props.makeNotificationDetailsEmpty({}); // When Close Modal Blank Already selected Notification Object
    // this.props.makeThreadMessagesBlank((res) => {});
    this.setState({ step: 1 });
    this.props.updateOrderStep(1);
    this.props.selleridStore("");
    // this.props.changeMessageTabName("product");
    this.props.updateBasicOrderthreadData({});
  }

  /* Render order message thread list */
  renderOrderList = (odDataList) => {
    let { language } = this.props;

    let itemod = _.map(odDataList, (item, index) => {
      let orddetails = item?.orderdata[0];
      let productImageUrl = `${BASE_IMAGES_URL}/vendor/variations/${item?.orderdata[0]?.productimage}?api_key=${APP_API_KEY}`;
      return (
        <li
          key={index}
          className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition">
          <div
            className="grid grid-cols-5 mb-md pt-3 cursor-pointer"
            onClick={() => this.viewOrdMessage(item)}>
            <div className="">
              <img
                loading="lazy"
                className="user-img-rounded"
                src={productImageUrl}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/images2/common/user.png";
                }}
                alt="Image"
              />
            </div>
            <div className="col-span-3">
              <div className="flex">
                <div className="w-48">
                  <div className="text-sm font-medium text-primary">
                    {ProductTextLocalized(
                      orddetails.productname_en,
                      orddetails.productname_ar,
                      language
                    )}
                  </div>
                  {item.lastmessage ? (
                    <div className="text-sm text-ternary truncate">
                      {item.lastmessage}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* Unread count message circel style */}
                <div
                  className={classNames(
                    item.unreadcount === 1 || item.unreadcount === "1"
                      ? ""
                      : "hidden",
                    "flex-shrink-0 w-2.5 h-2.5 rounded-full bg-yellow-500 mx-2 mt-1.5"
                  )}
                  aria-hidden="true"></div>
              </div>
            </div>
            <div className="text-xs text-ternary  text-right pt-0.5">
              {item.lastmessagesentat ? timeAgo(item.lastmessagesentat) : ""}
            </div>
          </div>
        </li>
      );
    });

    return itemod;
  };

  /* View order messages */
  viewOrdMessage = (thread) => {
    this.setState({ loading: true });
    let { session, language } = this.props;

    this.props.fetchOrderThreadMsgList(
      session,
      parseInt(thread.id),
      (res) => {}
    );
    this.props.updateBasicOrderthreadData({
      threadid: parseInt(thread?.id),
      sku: thread?.orderdata[0]?.sku,
      firstuserid: thread?.firstuserid,
      seconduserid: thread?.seconduserid,
      invoice_no: thread?.orderdata[0]?.invoice_no,
      productname_en: thread?.orderdata[0]?.productname_en,
      productname_ar: thread?.orderdata[0]?.productname_ar,
    });
    this.props.updateOrderStep(2);
  };

  handleMessage = (e) => {
    let msg = e.target.value;
    this.setState({ messageText: msg });
  };

  sendMessage = () => {
    this.setState({ btnLoader: true });
    let { session, basicOrderThreadData } = this.props;
    let { messageText, stateThreadid } = this.state;

    messageText = _.trim(messageText);

    if (isEmpty(messageText)) {
      toast.error("Type your message");
      this.setState({ btnLoader: false });
      return false;
    }

    let values = {};

    values.threadid = basicOrderThreadData.threadid;
    values.senderid = basicOrderThreadData.firstuserid;
    values.type = "text";
    values.text = messageText;

    this.props.sendOrderMessageToStore(session, values, (res) => {
      this.setState({ messageText: "" });

      if (res.success == 1) {
        this.props.fetchOrderThreadMsgList(
          session,
          parseInt(basicOrderThreadData.threadid),
          (res) => {
            this.setState({ btnLoader: false });
          }
        );
      } else {
      }
    });
  };

  /**** Back To Step 1 [Variant List] ****/
  goBack = () => {
    // props.fetchMessageThread(session);
    this.setState({ step: 1 });
    this.props.updateOrderStep(1);
    this.setState({ stateThreadid: "" });
  };

  /*** Render ThreadMessageList ***/
  renderOrdThreadMegList = (item) => {
    // console.log('item',this.props.storingsellerid);
    let { session } = this.props;
    return parseInt(item.senderid) ==
      parseInt(this.props.storingsellerid.sellerid) ? (
      <div className="flex justify-end pt-3.5">
        <div>
          <div
            className="bg-primary text-custWhite px-3 py-2"
            style={{ borderRadius: "22px 22px 5px 22px" }}>
            {item.text}
          </div>
          <div className="text-ternary text-xs">{timeAgo(item.createdat)}</div>
        </div>
      </div>
    ) : (
      <div className="flex justify-start">
        <div className="pr-3">
          <img
            loading="lazy"
            src={`${BASE_IMAGES_URL}/users/${item.senderid}.jpg?api_key=${APP_API_KEY}`}
            className="user-img-rounded"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images2/common/user.png";
            }}
          />
          <div className="text-ternary text-xs mt-2">
            {this.state.receiverName}
          </div>
        </div>
        <div>
          <div
            className="bg-chatbg text-secondary px-3 py-2"
            style={{ borderRadius: "22px 22px 22px 5px" }}>
            {item.text}
          </div>
          <div className="text-ternary text-xs mt-2">
            {timeAgo(item.createdat)}
          </div>
        </div>
      </div>
    );
  };

  render() {
    let {
      isMessageOpen,
      language,
      //   messageList,
      orderMessageList,
      //   storeMessageList,
      storeOrdMessageList,
      //   selectedMessageTab,
      currentOrderStep,
      basicOrderThreadData,
    } = this.props;
    let { loading, step, btnLoader } = this.state;
    let customDirectionstyle =
      language === "ar" ? { direction: "rtl" } : { direction: "ltl" };

    let footerStep = currentOrderStep;
    // console.log('currentOrderStep',currentOrderStep);
    // console.log('basicOrderThreadData',basicOrderThreadData);
    return (
      <>
        <Transition.Root show={isMessageOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 overflow-hidden z-50"
            open={isMessageOpen}
            onClose={this.closeSlideOvers}>
            <div className="absolute inset-0 overflow-hidden">
              <Dialog.Overlay className="absolute inset-0" />

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <div className="w-screen max-w-md">
                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                      <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                        <div className="px-4 sm:px-6">
                          {/* Header part [Starts] */}
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="slider-modal-headtext">
                              {footerStep === 1 ? (
                                "Messages"
                              ) : (
                                <>
                                  <div className="flex items-center">
                                    <ArrowLeftIcon
                                      className="h-6 w-6 cursor-pointer text-link"
                                      onClick={() => this.goBack()}
                                      aria-hidden="true"
                                    />
                                    <h2
                                      id="slide-over-heading"
                                      className="pl-4 text-xl font-normal text-link">
                                      Send Message
                                    </h2>
                                  </div>
                                  <div className="items-center">
                                    <div className="text-sm font-medium text-secondary --truncate mt-4">
                                      <p>
                                        {ProductTextLocalized(
                                          basicOrderThreadData?.productname_en,
                                          basicOrderThreadData?.productname_ar,
                                          language
                                        )}
                                      </p>
                                      <p>{basicOrderThreadData?.invoice_no}</p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                className="btn-closeModal"
                                onClick={this.closeSlideOvers}>
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Order thread list */}
                        <div className="mt-2 relative flex-1 px-4 sm:px-6 ">
                          {currentOrderStep === 1 ? (
                            <ul>
                              {!loading ? (
                                <>
                                  {_.isEmpty(orderMessageList) ? (
                                    <NoContentSlideOver
                                      imageprop={"../images/message.svg"}
                                      headerprop={"No Message(s)"}
                                      subheaderprop={"No Message received yet"}
                                    />
                                  ) : (
                                    this.renderOrderList(orderMessageList)
                                  )}
                                </>
                              ) : (
                                <BaseLoader data={this.state.loaderDesc} />
                                // 2
                              )}
                            </ul>
                          ) : !loading ? (
                            !_.isEmpty(storeOrdMessageList) ? (
                              _.map(storeOrdMessageList, (item, index) => {
                                {
                                  /* Message Thread List Section [step - 2] */
                                }
                                return this.renderOrdThreadMegList(item);
                              })
                            ) : (
                              <NoContentSlideOver
                                imageprop={"../images/message.svg"}
                                headerprop={"No Message(s)"}
                                subheaderprop={"No Message received yet"}
                              />
                            )
                          ) : (
                            <BaseLoader data={this.state.loaderDesc} />
                            // 1
                          )}
                        </div>
                      </div>
                      {/* Footer */}
                      <div
                        className={classNames(
                          footerStep === 1 ? "hidden" : "",
                          "flex-shrink-0 px-4 py-4  justify-center"
                        )}>
                        {footerStep === 1 ? (
                          ""
                        ) : (
                          //   Question Section
                          <div style={customDirectionstyle}>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <input
                                type="text"
                                name="comment_of_question_input"
                                id="comment_of_question_input"
                                className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-200 px-4 py-4 rounded-full"
                                placeholder={LocaleStrings.type_your_message}
                                value={this.state.messageText}
                                style={{ border: "1px solid" }}
                                onChange={(e) => this.handleMessage(e)}
                              />
                              <div
                                className={classNames(
                                  language === "ar" ? "left-0" : "right-0",
                                  "absolute inset-y-0  px-3 flex items-center"
                                )}>
                                {btnLoader ? (
                                  <svg
                                    className="animate-spin-slow h-5 w-5 rounded-full mr-3"
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                ) : (
                                  <img
                                    loading="lazy"
                                    src="../images/send_btn.svg"
                                    className="w-5 cursor-pointer"
                                    onClick={(e) => this.sendMessage(e)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isMessageOpen: state.isMessageOpen,
    // storeMessageList: state.storeMessageList
    //   ? state.storeMessageList.data
    //     ? state.storeMessageList.data
    //     : {}
    //   : {},
    // messageList: state.messageList
    //   ? state.messageList.data
    //     ? state.messageList.data
    //     : {}
    //   : {},
    orderMessageList: state.orderMessageList
      ? state.orderMessageList.data
        ? state.orderMessageList.data
        : {}
      : {},
    storeOrdMessageList: state.storeOrdMessageList
      ? state.storeOrdMessageList.data
        ? state.storeOrdMessageList.data
        : {}
      : {},
    notifiSingleObjectStore: _.isEmpty(state.notifiSingleObjectStore)
      ? []
      : state.notifiSingleObjectStore,
    // selectedMessageTab: state.selectedMessageTab,
    currentOrderStep: state.currentOrderStep,
    basicOrderThreadData: state.basicOrderThreadData,
    storingsellerid: state.storingsellerid,
  };
}

export default connect(mapStateToProps, {
  isSendMessageModalOpen,
  fetchOrderMessageList, // orders threads
  updateOrderStep, // Fc to change order step
  fetchOrderThreadMsgList,
  updateBasicOrderthreadData,
  sendOrderMessageToStore,
  selleridStore,
  //   isSendMessageModalOpen,
  //   fetchOrderMessageList, //Orders
  //   fetchThreadMessagesList,
  //   makeThreadMessagesBlank, // Use make thread messages blank [when unmount]
  //   makeThreadMessagesBlank,
  //   sendMessageStore,
  //   NotificationSingleObject,
  //   makeNotificationDetailsEmpty,
  //   changeMessageTabName,
  //   updateOrderStep, // Fc to change order step
  //   fetchOrderThreadMsgList,
  //   updateBasicOrderthreadData,
  //   sendOrderMessageToStore, // Store message to db
})(Message);
