import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "../../../../../languages/index";
import moment from "moment-timezone";
import _ from "lodash";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../../common/base-component";
import { ProductTextLocalized } from "../../../../../../common/common-functions";
import toast, { Toaster } from "react-hot-toast";
import { reset } from "redux-form";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { parse } from "date-fns";
// Import Actions
import {
  isOpenDiscManagModal,
  fetchProductsByCateSubCate,
  addDiscountMaster,
  addDiscountArea,
  fetchDiscountMaster,
  validatedDiscount,
  getSelectedDiscountObj,
  updateDiscountMaster,
  updateDiscountArea,
} from "../../../actions/index";

// Functions Import
import { classNames, isEmpty } from "../../../../../../common/common-functions";

// Component Import

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { DATE_FORMAT_WITH_TIME_WITH_SECOND } from "../../../../../../common/constants";

class DiscountManagementModal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      discounttype: "percentage",
      discountAreaType: "",
      selectedCateId: "",
      selectedSubCateId: "",
      selectedProductId: "",
      btnLoader: false,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.handleClickDiscountType = this.handleClickDiscountType.bind(this);
  }

  componentDidMount() {}

  /**** Fake Close ****/
  closeModal1() {}

  /**** Close Modal  ****/
  closeModal() {
    // Clear Single Discount Object (Pointing to)
    this.props.getSelectedDiscountObj({}, this.props.session);
    this.props.isOpenDiscManagModal(false);
    this.props.reset();
  }

  /**** OnClick Change ****/
  handleClickDiscountType(e, param) {
    this.setState({ discounttype: param });
  }

  /***** Form Submit *****/
  onFormSubmit(values, dispatch) {
    // console.log("valuesaddDiscount", values);
    if (Object.keys(values).length === 0) {
      NotificationManager.error("Mandatory Fields are required");
      return false;
    }
    if (values.discount_percentage === "0") {
      NotificationManager.error("Failed!! Discount cannot be zero");
      return false;
    }
    this.setState({ btnLoader: true }); // Start The button Loader
    let { discounttype } = this.state; // Get State Discount Type Value

    // console.log("discounttype", discounttype);
    let { initialValues } = this.props; // Get initialValues Object
    if (Object.keys(initialValues).length > 0) {
      // Checking if it's edit
      discounttype =
        discounttype === "" ? initialValues.discounttype : discounttype;
    }

    // Discount Type Not Selected Then Show alert
    if (isEmpty(discounttype)) {
      this.setState({ btnLoader: false });
      NotificationManager.error(LocaleStrings.discount_type_require);
      return false;
    }
    if (
      values.discounareaid == 2 &&
      (!values.hasOwnProperty("idcategory") ||
        values.idcategory == null ||
        values.idcategory == "")
    ) {
      NotificationManager.error("Please add selected discount area");
      // this.setState({ open: false });
      return false;
    } else if (
      values.discounareaid == 3 &&
      (!values.hasOwnProperty("idsubcategory") ||
        values.idsubcategory == null ||
        values.idsubcategory == "")
    ) {
      NotificationManager.error("Please add selected discount area");
      // this.setState({ open: false });
      return false;
    } else if (
      values.discounareaid == 4 &&
      (!values.hasOwnProperty("idproduct") ||
        values.idproduct == null ||
        values.idproduct == "")
    ) {
      NotificationManager.error("Please add selected discount area");
      // this.setState({ open: false });
      return false;
    }
    /* Date validation */
    let validity_from = "";
    let validity_end = "";

    if (typeof values.validity_from === "string") {
      validity_from = parse(
        moment(values.validity_from).format("DD-MM-YYYY HH:mm:ss"),
        "dd-MM-yyyy HH:mm:ss",
        new Date()
      );
    } else {
      validity_from = values.validity_from;
    }

    if (typeof values.validity_end === "string") {
      validity_end = parse(
        moment(values.validity_end).format("DD-MM-YYYY HH:mm:ss"),
        "dd-MM-yyyy HH:mm:ss",
        new Date()
      );
    } else {
      validity_end = values.validity_end;
    }

    if (validity_end.getTime() < validity_from.getTime()) {
      NotificationManager.error(
        LocaleStrings.validity_end_date_and_greater_date_validation_text
      );
      return false;
    }

    /* Discount Validation */
    if (!_.isNaN(parseFloat(values.discount_percentage))) {
      if (parseFloat(values.discount_percentage) < 0) {
        NotificationManager.error(LocaleStrings.discount_int_validation);
        return false;
      }
    } else {
      NotificationManager.error(LocaleStrings.discount_invalid);
      return false;
    }
    /**** Validate Discount Object Creation ****/
    let rulesobj = {
      idcategory:
        typeof values.idcategory != "undefined"
          ? parseInt(values.idcategory)
          : "",
      idsubcategory:
        typeof values.idsubcategory != "undefined"
          ? parseInt(values.idsubcategory)
          : "",
      idproduct:
        typeof values.idproduct != "undefined"
          ? parseInt(values.idproduct)
          : "",
      discounareaid:
        typeof values.discounareaid != "undefined"
          ? parseInt(values.discounareaid)
          : "",
    };

    if (values.id) {
      // If its edit Then Send Avobe(Validation) object with Discount Id
      rulesobj["iddiscount"] = values.id;
      rulesobj["id"] = initialValues.discountarea_by_iddiscount[0].id;
    }
    this.props.validatedDiscount(this.props.session, rulesobj, (callBack) => {
      // Validation checks for superadmin
      if (callBack.data.valid) {
        // Valide
        var obj = {
          discounttype: discounttype,
          title: values.title,
          validity_from: moment(values.validity_from).format(
            DATE_FORMAT_WITH_TIME_WITH_SECOND
          ),
          validity_end: moment(values.validity_end).format(
            DATE_FORMAT_WITH_TIME_WITH_SECOND
          ),
          discount_percentage: values.discount_percentage,
          createdby: this.props.session.userid,
        };

        if (values.id) {
          // Edit Function call [ Edit Discount / Discount Area ]
          obj["id"] = values.id;
          delete obj["createdby"];
          this.props.updateDiscountMaster(
            this.props.session,
            obj,
            (callBack) => {
              var obj2 = {
                iddiscount: values.id,
                id: initialValues.discountarea_by_iddiscount[0].id,
                idcategory: values.idcategory,
                idsubcategory: values.idsubcategory,
                idproduct: values.idproduct,
                discounareaid: values.discounareaid,
              };

              // Update rules
              this.props.updateDiscountArea(
                this.props.session,
                obj2,
                (callBack) => {
                  NotificationManager.success(
                    LocaleStrings.discount_updated_succesfully
                  );
                  // alert("done");
                  this.closeModal();
                  this.setState({ btnLoader: false });

                  // Clear Single Discount Object (Pointing to)
                  this.props.getSelectedDiscountObj({}, this.props.session);

                  // Refresh Discount Master List
                  this.props.fetchDiscountMaster(
                    this.props.session,
                    (callBack) => {
                      // Close Modal
                    }
                  );
                }
              );
              this.props.reset();
            }
          );
        } else {
          /**** Add function call ****/
          // Add Discount
          this.props.addDiscountMaster(this.props.session, obj, (callBack) => {
            var obj2 = {
              iddiscount: callBack.data,
              idcategory: values.idcategory,
              idsubcategory: values.idsubcategory,
              idproduct: values.idproduct,
              discounareaid: values.discounareaid,
              callfrom: "makediscount",
            };
            // Add riles
            this.props.addDiscountArea(this.props.session, obj2, (callBack) => {
              NotificationManager.success(
                LocaleStrings.discount_added_successfully
              );
              this.closeModal();
              this.setState({ btnLoader: false });

              // Clear Single Discount Object (Pointing to)
              this.props.getSelectedDiscountObj({}, this.props.session);

              // Refresh Discount Master List
              this.props.fetchDiscountMaster(this.props.session, (callBack) => {
                // Close Modal
              });
            });
          });
          this.props.reset();
        }
      } else {
        //     // Invalide
        this.setState({ btnLoader: false });
        NotificationManager.error(
          LocaleStrings.similar_discount_already_available
        );
        return false;
      }
    });
  }

  // Discount Area On Ochange
  _handleDiscounareaScreen(e) {
    let areaType = "";
    if (e.target.value != "" && e.target.value != null) {
      areaType = _.find(this.props.discountArea, {
        id: parseInt(e.target.value),
      }).type;
    }
    this.setState({ discountAreaType: areaType });
  }

  // Category / Subcategory  / Product Select
  _handleIdcategoryScreen(e) {
    let selectedFiledName = e.target.name;
    let selectedFiledValue = e.target.value;

    if (selectedFiledName === "idcategory") {
      // Category
      this.setState({ selectedCateId: selectedFiledValue });

      let subCateID = this.state.selectedSubCateId;

      // If category || Sub-category present fetch Product
      this.props.fetchProductsByCateSubCate(
        this.props.session,
        selectedFiledValue,
        "",
        (callBack) => {}
      );
    } else if (selectedFiledName === "idsubcategory") {
      // Sub-category
      this.setState({ selectedSubCateId: selectedFiledValue });
      let cateID = this.state.selectedCateId;
      // If category || Sub-category present fetch Product
      this.props.fetchProductsByCateSubCate(
        this.props.session,
        cateID,
        selectedFiledValue,
        (callBack) => {}
      );
    } else if (selectedFiledName === "idproduct") {
      // product
      this.setState({ selectedProductId: selectedFiledValue });
    } else {
    }
  }

  render() {
    let {
      initialValues,
      language,
      handleSubmit,
      updatePageNumber,
      productSubCategory,
      allProducts,
    } = this.props;
    let { discountAreaType, selectedCateId, discounttype } = this.state;
    let modalHeadText = "";
    let modalFooterText = "";

    if (Object.keys(initialValues).length > 0) {
      //EDIT
      discounttype =
        discounttype === "" ? initialValues.discounttype : discounttype;
      discountAreaType =
        discountAreaType === ""
          ? _.find(this.props.discountArea, {
              id: parseInt(initialValues.discounareaid),
            }).type
          : discountAreaType;
      selectedCateId =
        selectedCateId === "" ? initialValues.idcategory : selectedCateId;
      modalHeadText = LocaleStrings.edit_discount;
      modalFooterText = LocaleStrings.update_discount;
    } else {
      //ADD
      modalHeadText = LocaleStrings.add_new_discount;
      modalFooterText = LocaleStrings.add_new_discount;
    }

    var discountAreaOptions = []; // Discount Area
    var discountCateOptions = []; // Category
    var discountSubCateOptions = []; // Sub-Category
    var discountProductOptions = []; // Product

    console.log("this.props.productCategory", this.props.productCategory);

    //Discount Area Object Creation
    _.forEach(this.props.discountArea, function (value) {
      var obj = {
        name: ProductTextLocalized(value.area_en, value.area_ar, language),
        value: value.id,
      };

      discountAreaOptions.push(obj);
    });

    if (
      discountAreaType === "category" ||
      discountAreaType === "subcategory" ||
      discountAreaType === "product"
    ) {
      //Discount Category Object Creation
      _.forEach(this.props.productCategory, function (value) {
        var obj = {
          name: ProductTextLocalized(
            value.categoryname_en,
            value.categoryname_ar,
            language
          ),
          value: value.id,
        };

        discountCateOptions.push(obj);
      });
    }

    if (discountAreaType === "subcategory" || discountAreaType === "product") {
      //Discount Sub-Category Object Creation

      let filterSubcateArr = _.filter(this.props.productSubCategory, {
        categoryid: parseInt(selectedCateId),
      });

      _.forEach(filterSubcateArr, function (value) {
        // Filter sub sub category list whose parent id
        let subSubCategory = _.filter(productSubCategory, {
          parentid: parseInt(value.id),
        });

        if (subSubCategory.length > 0) {
          // If sub sub category is present
          _.forEach(subSubCategory, function (subSubObject, subSubIndex) {
            // loop through Sub sub category

            var obj = {
              name: ProductTextLocalized(
                value.subcategoryname_en +
                  " - " +
                  subSubObject.subcategoryname_en,
                value.subcategoryname_ar +
                  " - " +
                  subSubObject.subcategoryname_ar,
                language
              ),
              value: subSubObject.id,
            };

            discountSubCateOptions.push(obj);
          });
        } else {
          // if sub sub category is not there for current subcategory

          var obj = {
            name: ProductTextLocalized(
              value.subcategoryname_en,
              value.subcategoryname_ar,
              language
            ),
            value: value.id,
          };

          discountSubCateOptions.push(obj);
        }

        // var obj = {
        //   name: ProductTextLocalized(
        //     value.subcategoryname_en,
        //     value.subcategoryname_ar,
        //     language
        //   ),
        //   value: value.id,
        // };

        // discountSubCateOptions.push(obj);
      });
    }

    if (discountAreaType === "product") {
      //Discount Product (On select Cate | Subcate) Object Creation
      _.forEach(this.props.productByCateSubCate, function (value) {
        var obj = {
          name: ProductTextLocalized(
            value.productname_en,
            value.productname_ar,
            language
          ),
          value: value.id,
        };

        discountProductOptions.push(obj);
      });
    }

    var productName = "";
    var filterProduct = _.filter(allProducts, {
      id: initialValues.idproduct,
    });

    if (filterProduct.length > 0) {
      productName = filterProduct[0].productname_en;
    }

    return (
      <>
        <Transition.Root show={this.props.isDiscManageOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isDiscManageOpen}
            onClose={this.closeModal1}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0 }}>
                  {/* MOdal Header */}
                  <div className="grid grid-cols-3 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center mt-4">
                      <div className="text-lg leading-6 font-medium text-secondary">
                        {modalHeadText}
                      </div>
                    </div>
                    <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.closeModal}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    {/* MOdal Header Ends*/}
                    <div className="p-6 space-y-8">
                      <div>
                        <Field
                          name="title"
                          label={LocaleStrings.discount_title}
                          placeholder={LocaleStrings.enter_discount_title}
                          type="text"
                          component={this.rendernewFieldNewText}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-medium text-secondary"
                        />
                      </div>
                      <div>
                        <Field
                          name="validity_from"
                          label={LocaleStrings.validity_starting_date}
                          component={this.renderDateTimePickerWithLabel1}
                          mandatory="true"
                          autoComplete="off"
                          // selected={moment()}
                          minDate={
                            Object.keys(initialValues).length > 0
                              ? new Date(moment().format("YYYY,MM,DD"))
                              : new Date(moment().format("YYYY,MM,DD"))
                          }
                          className=" cust-input-field"
                          classNameLabel="text-box-lable"
                        />
                      </div>
                      <div>
                        <Field
                          name="validity_end"
                          label={LocaleStrings.validity_end_date}
                          component={this.renderDateTimePickerWithLabel1}
                          mandatory="true"
                          autoComplete="off"
                          // selected={moment()}
                          minDate={
                            Object.keys(initialValues).length > 0
                              ? new Date(moment().format("YYYY,MM,DD"))
                              : new Date(moment().format("YYYY,MM,DD"))
                          }
                          className=" cust-input-field"
                          classNameLabel="text-box-lable"
                        />
                      </div>
                      {/* renderField */}
                      <div className="relative mt-1 rounded-md ">
                        <Field
                          name="discount_percentage"
                          label={LocaleStrings.discount}
                          type="text"
                          mandatory="true"
                          component={this.rendernewFieldNewText}
                          className="cust-input-field"
                          classNameLabel="text-box-lable"
                        />
                        <div className="absolute inset-y-2 right-0 pr-2 flex items-center top-6 ">
                          {/* <span
                            className={classNames(
                              discounttype === "fixed" ? "text-primary" : "",
                              "cursor-pointer hover:text-primary"
                            )}
                            onClick={(e) =>
                              this.handleClickDiscountType(e, "fixed")
                            }
                          >
                            {LocaleStrings.flat}
                          </span>
                          <span className="px-2 text-ternary">|</span> */}
                          <span
                            className={classNames(
                              discounttype === "percentage"
                                ? "text-primary"
                                : "",
                              "cursor-pointer hover:text-primary"
                            )}
                            // onClick={(e) =>
                            //   this.handleClickDiscountType(e, "percentage")
                            // }
                          >
                            %
                          </span>
                        </div>
                      </div>
                      {Object.keys(initialValues).length > 0 &&
                      initialValues.fromproduct == true ? (
                        <>
                          {" "}
                          <div className="text-2xl font-semibold text-secondary">
                            Applied On: {productName}
                          </div>
                          {/* {to clear discounareaid issue for add} */}
                          <div className="hidden">
                            <Field
                              name="discounareaid"
                              label={LocaleStrings.select_discount_area}
                              placeholder={LocaleStrings.select}
                              component={this.renderSelect}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              opts={discountAreaOptions}
                              isdisabled={
                                Object.keys(initialValues).length > 0
                                  ? "true"
                                  : ""
                              }
                              className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                              classNameLabel="block text-box-lable"
                              onChange={this._handleDiscounareaScreen.bind(
                                this
                              )}
                              // value={this.props.initialValues.type}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <p className="text-xs font-semibold text-secondary">
                              {LocaleStrings.discount_applicable_rule}
                            </p>
                            <p className="mt-1 text-xs text-ternary">
                              {LocaleStrings.discount_applicable_rule_subtext}
                            </p>
                          </div>
                          <div>
                            <Field
                              name="discounareaid"
                              label={LocaleStrings.select_discount_area}
                              placeholder={LocaleStrings.select}
                              component={this.renderSelect}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              opts={discountAreaOptions}
                              isdisabled={
                                Object.keys(initialValues).length > 0
                                  ? "true"
                                  : ""
                              }
                              className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                              classNameLabel="block text-box-lable"
                              onChange={this._handleDiscounareaScreen.bind(
                                this
                              )}
                              // value={this.props.initialValues.type}
                            />
                          </div>
                          <div>
                            <Field
                              name="idcategory"
                              label={LocaleStrings.discount_product_category}
                              placeholder={LocaleStrings.select}
                              component={this.renderSelect}
                              mandatory="false"
                              labelposition={LABEL_POSITION_TOP}
                              opts={discountCateOptions}
                              isdisabled={
                                Object.keys(initialValues).length > 0
                                  ? "true"
                                  : ""
                              }
                              className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                              classNameLabel="block text-box-lable"
                              onChange={this._handleIdcategoryScreen.bind(this)}
                            />
                          </div>
                          <div>
                            <Field
                              name="idsubcategory"
                              label={LocaleStrings.discount_product_subcategory}
                              placeholder={LocaleStrings.select}
                              component={this.renderSelect}
                              mandatory="false"
                              labelposition={LABEL_POSITION_TOP}
                              isdisabled={
                                Object.keys(initialValues).length > 0
                                  ? "true"
                                  : ""
                              }
                              opts={discountSubCateOptions}
                              className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                              classNameLabel="block text-box-lable"
                              onChange={this._handleIdcategoryScreen.bind(this)}
                            />
                          </div>
                          <div>
                            <Field
                              name="idproduct"
                              label={LocaleStrings.discount_product}
                              placeholder={LocaleStrings.select}
                              component={this.renderSelect}
                              isdisabled={
                                Object.keys(initialValues).length > 0
                                  ? "true"
                                  : ""
                              }
                              mandatory="false"
                              labelposition={LABEL_POSITION_TOP}
                              opts={discountProductOptions}
                              className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                              classNameLabel="block text-box-lable"
                              onChange={this._handleIdcategoryScreen.bind(this)}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="p-6 border-t-2 border-quaternary">
                      {/* Footer */}
                      <button className="btn-primary" type="submit">
                        {modalFooterText}
                        <svg
                          className={classNames(
                            this.state.btnLoader ? "" : "hidden",
                            "animate-spin-medium h-5 w-5 rounded-full mrl-2"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = [
    "title",
    "validity_from",
    "validity_end",
    "discount_percentage",
    "discounareaid",
  ];

  requiredFields.forEach((field) => {
    // if (typeof values[field] != 'undefined'){
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
    // }
  });

  // if (!values.email) {
  //   errors.email = LocaleStrings.login_form_validation_email_required;
  // }

  // if (!values.password) {
  //   errors.password = LocaleStrings.login_form_validation_password_required;
  // }
  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isDiscManageOpen: state.isDiscManageModalopen,
    discountArea: state.discountArea
      ? state.discountArea.data != "undefined"
        ? state.discountArea.data
        : []
      : [],
    productCategory: state.productCategory
      ? state.productCategory.data != "undefined"
        ? state.productCategory.data
        : []
      : [],
    productSubCategory: state.productSubCategory
      ? state.productSubCategory.data != "undefined"
        ? state.productSubCategory.data
        : []
      : [],
    criteriaMaster: state.criteriaMaster
      ? state.criteriaMaster.data != "undefined"
        ? state.criteriaMaster.data
        : []
      : [],
    productByCateSubCate: state.productByCateSubCate
      ? state.productByCateSubCate.data !== "undefined"
        ? state.productByCateSubCate.data
        : []
      : [],
    allProducts: state.allProducts
      ? state.allProducts.data !== "undefined"
        ? state.allProducts.data
        : []
      : [],

    initialValues: state.singleDiscountObject,
  };
}

export default connect(mapStateToProps, {
  isOpenDiscManagModal,
  fetchProductsByCateSubCate,
  addDiscountMaster,
  addDiscountArea,
  fetchDiscountMaster,
  validatedDiscount,
  getSelectedDiscountObj,
  updateDiscountMaster,
  updateDiscountArea,
})(
  reduxForm({
    validate,
    form: "addDiscountForm",
    //  onSubmitSuccess: onFormSubmit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(DiscountManagementModal)
);
