import React, { Component } from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../../common/base-component";
import _ from "lodash";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
  loadConfirmationButton,
} from "../../../../../common/constants";
import {
  subcategorymodal,
  editcategory,
  fetchsubCategory,
  fetchCategory,
  updateCategory,
  inactiveCategory,
  checkifProductUnderCategory,
  getrelatedCountcatsubcat,
  activatedeactiveCatsubcat,
} from "../../actions/index";
import EditcategoryModal from "./editcategory";
import LocaleStrings from "../../../../languages/index.js";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";

class CategorylistItems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };
    this._handlechange = this._handlechange.bind(this);
    this.confirmButtonOk = React.createRef();
  }
  componentWillReceiveProps() {}

  InactiveCategoryBtnClick(e, values) {
    let { session } = this.props;
    let countParams = {
      categoryid: values.id,
      isactive: 0,
    };

    this.props.getrelatedCountcatsubcat(session, countParams, (callback) => {
      if (callback.success != 0) {
        let counponcount = callback?.data?.coupon_count;
        let discountcount = callback?.data?.discount_count;
        let product_count = callback?.data?.product_count;
        let subcategory_count = callback?.data?.subcategory_count;
        let subsubcategory_count = callback?.data?.subsubcategory_count;
        let specification_count = callback?.data?.specification_count;

        let title = "Are you sure you want to deactivate this category?";

        if (
          parseInt(counponcount) > 0 ||
          parseInt(discountcount) > 0 ||
          parseInt(product_count) > 0 ||
          parseInt(subcategory_count) > 0 ||
          parseInt(subsubcategory_count) > 0 ||
          parseInt(specification_count) > 0
        ) {
          title = title + "This category is already associated with ";
        }

        if (parseInt(counponcount) > 0) {
          title = title + `${counponcount} Coupon(s)`;
        }
        if (parseInt(discountcount) > 0) {
          title = title + `, ${discountcount} Discount(s)`;
        }
        if (parseInt(product_count) > 0) {
          title = title + `, ${product_count} Product(s)`;
        }
        if (parseInt(subcategory_count) > 0) {
          title = title + `, ${subcategory_count} Subcategory(s)`;
        }
        if (parseInt(subsubcategory_count) > 0) {
          title = title + `, ${subsubcategory_count} Sub-Subcategory(s)`;
        }
        if (parseInt(specification_count) > 0) {
          title = title + `, ${specification_count} Specification(s)`;
        }

        confirmAlert({
          title: "Deactivate Category",
          message: title,
          buttons: [
            {
              label: LocaleStrings.yes,
              onClick: () => {
                //  var value ={id:values.id,isactive:0}
                let body = { categoryid: values.id, isactive: 0 };

                this.props.activatedeactiveCatsubcat(
                  this.props.session,
                  body,
                  (response) => {
                    // console.log("showresource :- ", resource);
                    if (response.success === 0) {
                      toast.error("Error in deactivating");
                    } else {
                      toast.success("Successfully Deactivated");
                      this.props.fetchCategory(
                        this.props.session,
                        (CallBackResponse) => {}
                      );
                    }
                  }
                );
              },
            },
            {
              label: LocaleStrings.no,
              onClick: () => {},
            },
          ],
        });
      }
    });
  }
  ActiveCategoryBtnClick(e, values) {
    confirmAlert({
      title: "Activate Category",
      message: "Are you sure you want to activate this category?",
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            //  var value ={id:values.id,isactive:0}
            let body = { categoryid: values.id, isactive: 1 };
            // console.log('id',id)
            this.props.activatedeactiveCatsubcat(
              this.props.session,
              body,
              (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error) {
                  toast.error("Error in activating");
                } else {
                  toast.success("Successfully activated");
                  this.props.fetchCategory(
                    this.props.session,
                    (CallBackResponse) => {}
                  );
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  loadConfirmation = () => {
    loadConfirmationButton(this.confirmButtonOk);
  };

  activateDeactivateBtn = (e, values, comesfrom) => {
    let { session } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (comesfrom == "activate") {
      let body = { categoryid: values.id, isactive: 1 };
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <h1>Activate Category</h1>
              <p>Are you sure you want to activate this category?</p>

              <div className="react-confirm-alert-button-group">
                <button
                  type="button"
                  onClick={() => {
                    this.loadConfirmation();
                    this.props.activatedeactiveCatsubcat(
                      session,
                      body,
                      (resource) => {
                        if (resource.error) {
                          toast.error("Something Went Wrong");
                        } else {
                          this.props.fetchCategory(
                            this.props.session,
                            (CallBackResponse) => {
                              toast.success("Successfully Updated");
                              onClose();
                            }
                          );
                        }
                      }
                    );
                  }}
                  ref={this.confirmButtonOk}>
                  Activate
                </button>
                <button onClick={onClose}>Cancel</button>
              </div>
            </div>
          );
        },
      });
    } else {
      let countParams = {
        categoryid: values.id,
        isactive: 0,
      };
      this.props.getrelatedCountcatsubcat(session, countParams, (callback) => {
        if (callback.success != 0) {
          let counponcount = callback?.data?.coupon_count;
          let discountcount = callback?.data?.discount_count;
          let product_count = callback?.data?.product_count;
          let subcategory_count = callback?.data?.subcategory_count;
          let subsubcategory_count = callback?.data?.subsubcategory_count;
          let specification_count = callback?.data?.specification_count;
          let body1 = { categoryid: values.id, isactive: 0 };
          let title = "Are you sure you want to deactivate this category?";

          if (
            parseInt(counponcount) > 0 ||
            parseInt(discountcount) > 0 ||
            parseInt(product_count) > 0 ||
            parseInt(subcategory_count) > 0 ||
            parseInt(subsubcategory_count) > 0 ||
            parseInt(specification_count) > 0
          ) {
            title = title + "This category is already associated with ";
          }

          if (parseInt(counponcount) > 0) {
            title = title + `${counponcount} Coupon(s)`;
          }
          if (parseInt(discountcount) > 0) {
            title = title + `, ${discountcount} Discount(s)`;
          }
          if (parseInt(product_count) > 0) {
            title = title + `, ${product_count} Product(s)`;
          }
          if (parseInt(subcategory_count) > 0) {
            title = title + `, ${subcategory_count} Subcategory(s)`;
          }
          if (parseInt(subsubcategory_count) > 0) {
            title = title + `, ${subsubcategory_count} Sub-Subcategory(s)`;
          }
          if (parseInt(specification_count) > 0) {
            title = title + `, ${specification_count} Specification(s)`;
          }

          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="react-confirm-alert-body">
                  <h1>Deactivate Category</h1>
                  <p>{title}</p>

                  <div className="react-confirm-alert-button-group">
                    <button
                      type="button"
                      onClick={() => {
                        this.loadConfirmation();
                        this.props.activatedeactiveCatsubcat(
                          session,
                          body1,
                          (resource) => {
                            if (resource.error) {
                              toast.error("Something Went Wrong");
                            } else {
                              this.props.fetchCategory(
                                this.props.session,
                                (CallBackResponse) => {
                                  toast.success("Successfully Updated");
                                  onClose();
                                }
                              );
                            }
                          }
                        );
                      }}
                      ref={this.confirmButtonOk}>
                      Deactivate
                    </button>
                    <button onClick={onClose}>Cancel</button>
                  </div>
                </div>
              );
            },
          });

          // confirmAlert({
          //   title: "Deactivate Category",
          //   message: title,
          //   buttons: [
          //     {
          //       label: LocaleStrings.yes,
          //       onClick: () => {
          //         //  var value ={id:values.id,isactive:0}
          //         let body = { categoryid: values.id, isactive: 0 };

          //         this.props.activatedeactiveCatsubcat(
          //           this.props.session,
          //           body,
          //           (response) => {
          //             // console.log("showresource :- ", resource);
          //             if (response.success === 0) {
          //               toast.error("Error in deactivating");
          //             } else {
          //               toast.success("Successfully Deactivated");
          //               this.props.fetchCategory(
          //                 this.props.session,
          //                 (CallBackResponse) => {}
          //               );
          //             }
          //           }
          //         );
          //       },
          //     },
          //     {
          //       label: LocaleStrings.no,
          //       onClick: () => {},
          //     },
          //   ],
          // });
        }
      });
    }
  };

  openModal = (e, obj) => {
    this.props.editcategory(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  _handlechange(e, values) {
    this.props.fetchsubCategory(
      this.props.session,
      values.id,
      (CallBackResponse) => {
        // this.setState({ loading: false });
      }
    );
    this.props.subcategorymodal(values);
  }

  _handlefeature(e, values) {
    let title = "Feature Category";
    let description = "Are you sure you want to feature this category";
    let isfeatured = true;

    if (values.isfeatured == true) {
      title = "UnFeature Category";
      description = "Are you sure you want to unfeature this category";
      isfeatured = false;
    }

    confirmAlert({
      title: title,
      message: description,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            //  var value ={id:values.id,isactive:0}
            let body = { id: values.id, isfeatured: isfeatured };

            this.props.updateCategory(this.props.session, body, (response) => {
              // console.log("showresource :- ", resource);
              if (response.success === 0) {
                toast.error("Something Went Wrong");
              } else {
                toast.success("Successfully updated");
                this.props.fetchCategory(
                  this.props.session,
                  (CallBackResponse) => {}
                );
              }
            });
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { values } = this.props;

    var img = `${BASE_IMAGES_URL}/vendor/productcategories/${values.image}?api_key=${APP_API_KEY}`;
    var starimg = `../images/product_management_images/star.png`;

    if (values.isfeatured == true) {
      starimg = `../images/product_management_images/yellowstar.svg`;
    }

    return (
      <>
        {values.isactive == true ? (
          <>
            {values.id == this.props.subcategorymodalstore.id ? (
              <>
                <div className="grid grid-cols-4 border-b-2 border-primary px-5 py-4 cursor-pointer bg-gray-50">
                  <div className="flex col-span-2">
                    <img
                      className="h-6 w-6"
                      onClick={(e) => this._handlefeature(e, values)}
                      src={starimg}></img>

                    <div
                      style={{ display: "flex" }}
                      className="ml-4"
                      onClick={(e) => this._handlechange(e, values)}>
                      <div>
                        {" "}
                        <img
                          src={img}
                          style={{ height: "40px", width: "40px" }}
                        />
                      </div>
                      <div className="px-4">
                        <p>{values.categoryname_en}</p>
                        <span style={{ display: "flex" }}>
                          <p className="text-xs text-ternary pl-1">
                            {values.productsubcategory_by_categoryid.length}
                          </p>
                          <p className="text-xs text-ternary pl-1">
                            Subcategory
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 ml-8" style={{ display: "flex" }}>
                    <div className="">
                      <button
                        className="button"
                        type="button"
                        onClick={(e) => this.openModal(e, values)}>
                        Edit
                      </button>
                      {this.state.isOpen ? (
                        <EditcategoryModal
                          isOpen={this.state.isOpen}
                          onClose={this.closeModal}
                          // data={this.props.values}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="ml-3">
                      <button
                        className="button"
                        onClick={(e) =>
                          this.activateDeactivateBtn(e, values, "deactivate")
                        }>
                        Deactivate
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="grid grid-cols-4 border-b-2 border-gray-50 px-5 py-4  cursor-pointer">
                  <div className="flex col-span-2">
                    <img
                      className="h-6 w-6"
                      onClick={(e) => this._handlefeature(e, values)}
                      src={starimg}></img>

                    <div
                      style={{ display: "flex" }}
                      className="ml-4"
                      onClick={(e) => this._handlechange(e, values)}>
                      <div>
                        {" "}
                        <img
                          src={img}
                          style={{ height: "40px", width: "40px" }}
                        />
                      </div>
                      <div className="px-4">
                        <p>{values.categoryname_en}</p>
                        <span style={{ display: "flex" }}>
                          <p className="text-xs text-ternary pl-1">
                            {values.productsubcategory_by_categoryid.length}
                          </p>
                          <p className="text-xs text-ternary pl-1">
                            Subcategory
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 ml-8" style={{ display: "flex" }}>
                    <div className="">
                      <button
                        className="button"
                        type="button"
                        onClick={(e) => this.openModal(e, values)}>
                        Edit
                      </button>
                      {this.state.isOpen ? (
                        <EditcategoryModal
                          isOpen={this.state.isOpen}
                          onClose={this.closeModal}
                          // data={this.props.values}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="ml-3">
                      <button
                        className="button"
                        onClick={(e) =>
                          this.activateDeactivateBtn(e, values, "deactivate")
                        }>
                        Deactivate
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="grid grid-cols-3 border-b-2 border-black-50 px-5 py-4 bg-gray-50">
            <div className="w-16">
              <img
                className="h-6 w-6"
                onClick={(e) => this._handlefeature(e, values)}
                src={starimg}></img>
            </div>
            <div style={{ display: "flex", marginLeft: -20 }}>
              <div>
                {" "}
                <img src={img} style={{ height: "40px", width: "40px" }} />
              </div>
              <div className="px-4">
                <p>{values.categoryname_en}</p>
                <span style={{ display: "flex" }}>
                  <p className="text-xs text-ternary pl-1">
                    {values.productsubcategory_by_categoryid.length}
                  </p>
                  <p className="text-xs text-ternary pl-1">Subcategory</p>
                </span>
              </div>
            </div>
            <div className="pl-4 " style={{ display: "flex" }}>
              <div className="ml-3">
                <button
                  className="button"
                  onClick={(e) =>
                    this.activateDeactivateBtn(e, values, "activate")
                  }>
                  Activate
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    subcategorylist: state.subcategorylist,
    subcategorymodalstore: state.subcategorymodalstore,
  };
}

export default connect(mapStateToProps, {
  subcategorymodal,
  editcategory,
  fetchsubCategory,
  fetchCategory,
  updateCategory,
  inactiveCategory,
  checkifProductUnderCategory,
  getrelatedCountcatsubcat,
  activatedeactiveCatsubcat,
})(CategorylistItems);
