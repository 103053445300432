import React, { Component } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../languages/index.js";
import { Fragment, useState } from "react";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import moment from "moment-timezone";
import { postReply, fetchTickets } from "../../actions/index";
import toast, { Toaster } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import pt from "react-phone-input-2/lang/pt.json";
import _ from "lodash";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import TicketModalMedia from "./ticket-modalmedia";

const limit = 10;
class RepliedModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      pageNumber: 1,
    };

    // All Binded Functions
  }

  onSubmitForm(data) {
    var reply = data.reply;
    var ticketid = this.props.ticketandsuggestionReply.id;
    var type = "tickets";
    this.props.postReply(this.props.session, reply, ticketid, (response) => {
      if (response.success === 0) {
        NotificationManager.error(LocaleStrings.common_wentWrong);
      } else {
        this.props.onClose();
        this.props.fetchTickets(
          this.props.session,
          type,
          limit,
          this.state.pageNumber,
          "listing",
          "",
          (CallBackResponse) => {}
        );

        NotificationManager.success(LocaleStrings.common_repliedSucess);
      }
    });
  }

  renderList() {
    var items = _.map(this.props.data.media, (values, index) => (
      <TicketModalMedia key={index} values={values} />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }

  render() {
    var { data } = this.props;
    let tabledate = "";
    tabledate = moment(data.ticketby.createdon).format("D MMM YYYY HH:mm A");
    var { handleSubmit, pristine, reset, submitting, invalid } = this.props;
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.myRef}
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="grid gap-4  md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3  border-b-2 border-gray-50 pb-4">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center">
                      {LocaleStrings.ticketsReports_ticketHeading}
                    </div>
                    <div className="flex flex-wrap content-center justify-end">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}
                        ref={this.myRef}>
                        <span className="sr-only"></span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className="space-y-8 divide-y divide-gray-200 mt-5"
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                    encType="multipart/form-data">
                    <div>
                      <div className="flex">
                        <p>{LocaleStrings.ticketsReports_Ticketedby}</p>
                        <p className="px-2">{data.ticketby.username}</p>
                      </div>
                      <div className="text-ternary my-2">{tabledate}</div>
                      <div className="flex">{this.renderList()}</div>
                      {data.question.length == 0 ? (
                        ""
                      ) : (
                        <>
                          <p className="my-4">{_.startCase(data.question)}</p>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <Toaster limit={1} />
      </>
    );
  }
}
function validate(values) {
  var errors = {};
  var requiredFields = ["reply"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
  });

  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log("session", state.ticketandsuggestionReply);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    ticketandsuggestionReply: state.ticketandsuggestionReply,
    data: ownProps.data,
  };
}

export default connect(mapStateToProps, {
  postReply,
  fetchTickets,
})(
  reduxForm({
    validate,
    form: "ReplyModal",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(RepliedModal)
);
