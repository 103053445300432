import React, { Component } from "react";
import { connect } from "react-redux";
// import Customertabs from './customertabs';
import OrderlistTabs from "./odertabs";
import { fetchOderlist } from "../../../actions/index";
// import { myimg } from "/style/images/product_management_images/sort.png";

var Loader = require("react-loaders").Loader; // import "AwesomeLoaderCss";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class OdersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showUploadSection: false,
    };

    // All Binded Functions
  }
  componentDidMount(data) {
    // console.log('this.props.session', this.props.session)
    // this.props.fetchOderlist(this.props.session,(CallBackResponse) => {
    // });
    // this.props.fetchOderlist(this.props.session,this.props.data.userid,(CallBackResponse) => {
    // });
  }

  customerdetails = (e, data) => {
    //  console.log("this.props.data", this.props.data)
    this.setState({ showUploadSection: !this.state.showUploadSection });
    //   this.props.data();
  };

  render() {
    return (
      <>
        <div className="pt-6">
          <div class="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-3 ">
            {/* <div className="justify-items-center">                       
                        <label htmlFor="search-field" className="sr-only">
                            {LocaleStrings.search}
                        </label>
                        <div className="relative w-full text-ternary focus-within:text-secondary ">
                            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                                <SearchIcon className="h-5 w-5" aria-hidden="true" />
                            </div>
                            <input
                                id="search-field"
                                className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                                placeholder={LocaleStrings.search}
                                type="search"
                                name="search"
                            />
                        </div>
                    </div> */}
            {/* <button
                    onClick={(e) =>
                    this.customerdetails(e)
                    }
                    type="button"
                    className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary hover:bg-primary-700 flex flex-wrap content-center justify-center"
                    >
                    Tabs
                    </button> */}
          </div>
        </div>
        <OrderlistTabs
          data={this.props.data}
          showUploadSection={this.customerdetails}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user, oderlist } = state;
  // console.log('oderlist',state.oderlist);
  return {
    session,
    user,
    oderlist,
    isLoggedIn: state.isLoggedIn,
    data: ownProps.data,
  };
}

// var mapStateToProps = (state) => ({
//     isLoggedIn: state.isLoggedIn,
//     user: state.user,
//     session: state.session,
//     oderlist:state.oderlist
//   });

export default connect(mapStateToProps, { fetchOderlist })(OdersList);
