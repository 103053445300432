import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages";
import { Field, reduxForm } from "redux-form";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
  number,
} from "../../../../../common/base-component";
import toast, { Toaster } from "react-hot-toast";
import {
  fetchglobalCommision,
  updateGlobalCommission,
} from "../../actions/index";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import _ from "lodash";
import { classNames } from "../../../../../common/common-functions";
import BaseLoader from "../../../../../common/base-loader";

class FreeShippingFee extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      setOpen: false,
      btnLoader: false,
      loading: true,
      loaderDesc: "Preparing Free Shipping Fee",
    };
  }

  componentDidMount() {
    this.props.fetchglobalCommision(this.props.session, (CallBackResponse) => {
      this.setState({ loading: false });
    });
  }

  onFormSubmit = (val) => {
    this.setState({ btnLoader: true });

    let minfee = parseInt(val.min_order_amount_for_shipping_free);
    if (val.min_order_amount_for_shipping_free == "" || minfee < 0) {
      NotificationManager.error("Add proper shipping fee");
      this.setState({ btnLoader: false });
    } else {
      var values = { ...val };

      this.props.updateGlobalCommission(
        this.props.session,
        values,
        (response) => {
          if (response.success === 0) {
            toast.error(LocaleStrings.common_cannotupdate);
            this.setState({ btnLoader: false });
          } else {
            NotificationManager.success("Free Shipping Fee Updated");
            this.setState({ btnLoader: false });
            this.props.fetchglobalCommision(
              this.props.session,
              (CallBackResponse) => {}
            );
          }
        }
      );
    }
  };

  render() {
    var { data, handleSubmit, initialValues } = this.props;

    var listingfeeTypeArr = [];

    //Discount Area Object Creation
    _.forEach(this.props.listngfeetype, function (value) {
      var obj = {
        name: value.name,
        value: value.value,
      };

      listingfeeTypeArr.push(obj);
    });

    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div className="m-6">
              <form className=" " onSubmit={handleSubmit(this.onFormSubmit)}>
                <div className="flow-root px-10 py-5 bg-custWhite rounded">
                  <p className="mb-3">Free Shipping Fee</p>
                  <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 pr-10">
                    <div className="px-5 end-item">Shipping Free After</div>
                    <div className="mt-3">
                      <Field
                        name="min_order_amount_for_shipping_free"
                        placeholder="Min Order Amount"
                        type="text"
                        component={this.renderFieldcustomer}
                        className="appearance-none block w-half px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div></div>
                  </div>
                  <div className="center-item my-16">
                    <button type="submit" className="bigbutton">
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "sr-only",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      Update Free Shipping Fee
                    </button>
                  </div>
                </div>
              </form>
              <NotificationContainer />
            </div>
          </>
        )}
      </>
    );
  }
}

function validate(val) {
  // console.log("form data", values);
  var errors = {};
  var min_order_amount_for_shipping_free =
    val["min_order_amount_for_shipping_free"];

  if (
    min_order_amount_for_shipping_free &&
    number(min_order_amount_for_shipping_free)
  ) {
    errors["min_order_amount_for_shipping_free"] = "Please enter a number";
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  return {
    session: state.session,
    initialValues: state.global_commissionlist.data,

    listngfeetype: state.listngfeetype,
  };
}

export default connect(mapStateToProps, {
  fetchglobalCommision,
  updateGlobalCommission,
})(
  reduxForm({
    validate,
    form: "FreeShippingFeeForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(FreeShippingFee)
);
