import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import { fetchTickets, searchTickets } from "../../actions/index";
import TicketListitems from "./ticketlist-item";
import BaseLoader from "../../../../../common/base-loader";
import _ from "lodash";
import Pagination from "react-js-pagination";
import { NotificationContainer } from "react-notifications";
import TicketsandReportslistTabs from "../ticketreporttabs";
import { classNames } from "../../../../../common/common-functions";

const limit = 10;
class Tickets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // showUploadSection : false,
      loading: true,
      loaderDesc: "Preparing Tickets",
      pageNumber: 1,
      searchText: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    var type = "tickets";
    this.props.fetchTickets(
      this.props.session,
      type,
      limit,
      this.state.pageNumber,
      "listing",
      "",
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  paginationCallback = (pageNumber) => {
    var type = "tickets";

    this.props.fetchTickets(
      this.props.session,
      type,
      limit,
      pageNumber,
      "listing",
      "",
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    var type = "tickets";
    var searchText = event.target.value;
    var pageNumber = 1;

    if (searchText === "") {
      this.setState({ loading: true });
      // Paginated list
      /* 
        Fetch transaction history list [paginated]
        New Params : {foruserid:userid,"limit":<num of rows to show>,"page":curpage,type:"listing"}
        */
      this.props.fetchTickets(
        this.props.session,
        type,
        limit,
        pageNumber,
        "listing",
        "",
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    } else {
      /* 
        search list without pagination
        Params: {foruserid:userid,type:"search",searchterm:<search text>}
        */
      this.props.fetchTickets(
        this.props.session,
        type,
        limit,
        pageNumber,
        "search",
        searchText,
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    }
    this.setState({ searchText, pageNumber });
  };

  renderList() {
    let pageDataLength = this.props.allTickets_listing.data.length;

    let { pageNumber } = this.state;

    // console.log('this.props.allTickets_listing.data',this.props.allTickets_listing.data);
    var items = _.map(this.props.allTickets_listing.data, (values, index) => (
      <TicketListitems
        key={index}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }

  render() {
    let { language } = this.props;
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <TicketsandReportslistTabs history={this.props.history} />
            <div className="relative w-6/12 text-ternary focus-within:text-secondary m-6 mb-0">
              <div
                className={classNames(
                  language == "en" ? "left-0 " : "right-0",
                  "absolute inset-y-0 flex items-center pointer-events-none px-1"
                )}>
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className={classNames(
                  language == "en" ? "pl-8 pr-3" : "pr-8 pl-3",
                  "block lg:w-6/12 xs:w-full sm:w-full h-full  py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                )}
                placeholder={LocaleStrings.search}
                type="search"
                name="search"
                value={this.state.search}
                onChange={this.handleSearch}
              />
            </div>
            {_.isEmpty(this.props.allTickets_listing.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.ticketsReports_noTickets}
                </div>
              </>
            ) : (
              <>
                <div
                  className="grid gap-4 grid-rows-1  md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 px-6"
                  style={{
                    overflowX: "hidden",
                    overflowY: "hidden",
                    display: "block",
                  }}>
                  {/* <SearchNewCustomer /> */}

                  <div className="panel-body mt-6">
                    {/* <TableContent /> */}
                    <div className="flex flex-col">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-custWhite">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {LocaleStrings.ticketedby}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {LocaleStrings.raisedDate}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {LocaleStrings.ticketContent}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {LocaleStrings.action}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{this.renderList()}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Pagination
                      activePage={this.state.pageNumber}
                      itemsCountPerPage={10}
                      totalItemsCount={this.props.allTickets_listing.count}
                      pageRangeDisplayed={10}
                      onChange={this.paginationCallback}
                    />
                  </div>
                  <NotificationContainer />
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, allTickets_listing, language } = state;
  // console.log("allTickets", state.allTickets_listing);
  return {
    session,
    user,
    language,
    allTickets_listing,
  };
}

export default connect(mapStateToProps, {
  fetchTickets,
  searchTickets,
})(Tickets);
