import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../../languages/index";
import { Fragment } from "react";
import { BaseComponent } from "../../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { reduxForm } from "redux-form";
import _ from "lodash";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
} from "../../../../../../common/constants";
import CustomerShippingStatus from "./customer-shippingstatus";
import { converDateIntoLocal } from "../../../../../../common/common-functions";
import { ProductTextLocalized } from "../../../../../../common/common-functions";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../../currency/action/index";

class Oderdetailmodal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }
  componentDidMount() {}

  render() {
    var { data, language, localCurrency } = this.props;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    //  console.log('detailings', data);
    var img = `${BASE_IMAGES_URL}/vendor/variations/${data.productimage}?api_key=${APP_API_KEY}`;

    let tabledate = "";
    tabledate = converDateIntoLocal(data.createdon).format(
      "D MMM YYYY HH:mm A"
    );

    let grandtotal = 0.0;
    let discount = 0.0;
    let tax = 0.0;
    let shipping = 0.0;
    let product_total = 0.0;
    let productCurrency = "QAR";
    if (data.currency && (data.currency != "" || data.currency != null)) {
      productCurrency = data.currency;
    }

    grandtotal = data?.order_details.summary.grand_total;
    discount = data?.order_details.summary.discount;
    tax = data?.order_details.summary.tax;
    shipping = data?.order_details.summary.shipping;
    product_total = data?.order_details.summary.product_total;
    // console.log("data", data);
    // let mailToLink = "";
    // if (!_.isEmpty(data)) {
    //   mailToLink = `mailto:${data?.purchasedby?.email}?subject=Shopez - ${LocaleStrings.order_id} : ${data?.product?.invoice_no}`;
    // }

    return (
      <Transition.Root show={this.props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.props.isOpen}
          onClose={this.props.onClose}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                style={{ padding: 0 }}>
                <div className="grid grid-cols-3 border-b-2 border-gray-200 pb-4 sticky-header z-50">
                  <div></div>
                  <div className="flex flex-wrap content-center justify-center mt-4">
                    {LocaleStrings.oderdetails}
                  </div>
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4 ">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      onClick={this.props.onClose}>
                      <span className="sr-only">{LocaleStrings.close}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-2 mt-5 pb-3 border-b-2 border-gray-50">
                    <div>
                      {LocaleStrings.oderid}: {data.invoice_no}
                    </div>
                    <div className="end-item text-xs text-ternary">
                      {tabledate}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 mt-5 pb-3 border-b-2 border-gray-50">
                    <div>
                      <img src={img} />
                    </div>
                    <div className="text-xs text-ternary pl-4">
                      <p className="mt-5">
                        {ProductTextLocalized(
                          data.productname_en,
                          data.productname_ar,
                          language
                        )}
                      </p>
                      <p className="mt-5">
                        {LocaleStrings.odererby}:{" "}
                        {data.order_details.delivery_address.username}
                      </p>
                      <p className="mt-5">
                        {LocaleStrings.dateofoder}: {tabledate}
                      </p>
                      <p className="mt-5">{LocaleStrings.quantity}: 1</p>
                      <p className="mt-5">
                        {LocaleStrings.odertotal}:{/* ${data.originalprice} */}
                        {currency} {grandtotal.toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <CustomerShippingStatus data={data.statuses} />
                  <div className="mt-5 pb-3 border-b-2 border-gray-50">
                    Delivery To
                    <div className="grid lg:grid-cols-3 grid-cols-3 mt-5">
                      <div>
                        <img
                          src="../images/product_management_images/delivery.svg "
                          className="h-24 w-24"
                        />
                      </div>
                      <div className="">
                        <p>
                          {_.startCase(
                            data.order_details.delivery_address.addresstype
                          )}
                          : {data.order_details.delivery_address.username}
                        </p>
                        <p className="text-xs text-ternary mt-2">
                          {data.order_details.delivery_address.address}
                        </p>
                        <p className="text-xs text-ternary mt-2">
                          {LocaleStrings.contactnumber}:{" "}
                          {data.order_details.delivery_address.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="center-item">
                    <div className=" ">
                      <a
                        className="btn-primary"
                        href={mailToLink}
                        target="_top">
                        {LocaleStrings.emailbuyer}
                      </a>
                    </div>
                  </div> */}
                  <div className="mt-5 pb-3 border-b-2 border-gray-50">
                    {" "}
                    {LocaleStrings.pricedetails}
                    <div className="grid grid-cols-2 mt-5">
                      <div>{LocaleStrings.productprice}</div>
                      <div className="end-item ">
                        {/* $
                        {parseFloat(
                          data.order_details.summary.product_total
                        ).toFixed(2)} */}
                        {currency} {product_total.toFixed(2)}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mt-1">
                      <div>{LocaleStrings.discount}</div>
                      <div className="end-item ">
                        {/* -$
                        {parseFloat(
                          data.order_details.summary.discount
                        ).toFixed(2)} */}
                        -{currency} {discount.toFixed(2)}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mt-1">
                      <div>{LocaleStrings.shippingfee}</div>
                      <div className="end-item ">
                        {/* $
                        {parseFloat(
                          data.order_details.summary.shipping
                        ).toFixed(2)} */}
                        {currency} {shipping.toFixed(2)}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mt-1">
                      <div>{LocaleStrings.taxcharges}</div>
                      <div className="end-item ">
                        {/* ${parseFloat(data.order_details.summary.tax).toFixed(2)} */}
                        {currency} {tax.toFixed(2)}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mt-5">
                      <div>{LocaleStrings.total}</div>
                      <div className="end-item ">
                        {/* $
                        {parseFloat(
                          data.order_details.summary.grand_total
                        ).toFixed(2)} */}
                        {currency} {grandtotal.toFixed(2)}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mt-3">
                      <div>{LocaleStrings.paymentmethod}</div>
                      <div className="end-item ">
                        {_.startCase(data.order_details.payment_method)}
                      </div>
                    </div>
                  </div>
                  {/* <div className="pt-5">
                      <div className="flex justify-center">
                      <button
                    
                          type="button"
                          className="button"
                      >
                            {LocaleStrings.canceloder}
                        </button>
                      </div>
                      </div> */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('session',state.wishlistlist);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    data: ownProps.data,
    localCurrency: state.currency,
  };
}

export default connect(mapStateToProps, {
  isCurrencyRateApplicable,
})(
  reduxForm({
    // validate,
    form: "EditcustomersForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Oderdetailmodal)
);
