import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import _ from "lodash";
import OrderManagementTabs from "./ordermanagementtabs";
import { fetchallnewoderlist } from "../actions/index";

class OrderManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // showUploadSection : false,
      loading: true,
      loaderDesc: LocaleStrings.order_management,
      pageNumber: 1,
    };

    // All Binded Functions
  }

  componentDidMount() {
    // this.props.fetchallnewoderlist(this.props.session,(CallBackResponse) => {
    //     this.setState({loading:false});
    // });
    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation.data, {
      href: answer,
    });

    if (singleNavigationRow == undefined) {
      this.props.history.push("/login");
    }

    this.props.history.push("/dashboard/ordermanagement/new");
  }
  customerdetails = (e, data) => {
    //  console.log("this.props.data", this.props.data)
    this.setState({ showUploadSection: !this.state.showUploadSection });
    //   this.props.data();
  };

  render() {
    return (
      <>
        <OrderManagementTabs
          showUploadSection={this.customerdetails}
          history={this.props.history}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, allproductslist } = state;
  return {
    session,
    user,
    allproductslist,
    navigation: state.sideBarMenus,
  };
}

export default connect(mapStateToProps, {
  fetchallnewoderlist,
})(OrderManagement);
