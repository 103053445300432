import React from "react";
import { connect } from "react-redux";
// import Sellerdeliveroderdetailmodal from './deliverodermodal';
import {
  BaseComponent,
} from "../../../../../../common/base-component";
import Sellernewoderdetailmodal from "./newodermodal";
import moment from "moment-timezone";
import _ from "lodash";
import { currencyCode, currencyRate ,isCurrencyRateApplicable} from "../../../../currency/action/index";

class Sellerdeliveroderitems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  render() {
    var { values, localCurrency } = this.props;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    let tabledate = "";
    tabledate = moment(values.product.createdon).format("D MMM YYYY HH:mm A");
    let grand_total_val = 0.00;
    let productCurrency = 'QAR';
    if (values.currency && (values.currency != '' || values.currency != null)) {
      productCurrency = values.currency;
    }

    grand_total_val = values.summary?.grand_total
    return (
      <>
        <tr
          className="bg-white border-b-2 border-gray-50 cursor-pointer"
          onClick={(e) => this.openModal(e, values)}
        >
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div>{values.product.invoice_no}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div>
              {/* ${getIntToFloat(grand_total)} */}
              {currency} {grand_total_val}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div>{values.purchasedby.username}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div>
              {/* direct */}
              {tabledate}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div>{_.startCase(values.product.currentstatus.status)}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.product.productname_en}
          </td>
        </tr>
        {this.state.isOpen ? (
          <Sellernewoderdetailmodal
            isOpen={this.state.isOpen}
            onClose={this.closeModal}
            // data={values.items}
            data={this.props.values}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.sellermanagementTabs,
    sellereditmodal: state.sellereditmodal,
    sellerlistingtabs: state.sellerlistingtabs,
    newtabsellerlist: state.newtabsellerlist,
    localCurrency: state.currency,
  };
}
// function mapStateToProps(state) {
//   return {
//     session: state.session,
//     newtablist:state.newtablist,
//     customereditmodal:state.customereditmodal,
//   };
// }
export default connect(mapStateToProps, {isCurrencyRateApplicable})(Sellerdeliveroderitems);
