import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index";
import _ from "lodash";
import toast, { Toaster } from "react-hot-toast";
import { SearchIcon } from "@heroicons/react/solid";

// Import Components
import BaseLoader from "../../../../../../common/base-loader";
import DiscMasterTableList from "./discmastertablelist";
import DiscountManagHead from "./discountmanaghead";
import DiscountManagementModal from "./addeditdiscmana";
import Pagination from "react-js-pagination";
// Import Action
import {
  fetchProductCategory,
  fetchProductSubcategory,
  fetchCriteriaMaster,
  fetchDiscountArea,
  fetchDiscountMaster,
  searchDiscountMaster,
  fetchProducts,
} from "../../../actions/index";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import DiscountCouponTabs from "../../discountcoupontabs";
import { classNames } from "../../../../../../common/common-functions";

class ActiveDiscount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.discount_management,
      DiscounSearch: "",
      search: "",
      pageNumber: 1,
    };

    // All Binded Functions
  }

  /*** Call Only When Componenet Mount 1 time) ***/
  componentDidMount() {
    // Get Categories
    this.props.fetchProducts(this.props.session, (callBack) => {});
    this.props.fetchProductCategory(this.props.session, (callBack) => {});

    // Subcategories
    this.props.fetchProductSubcategory(this.props.session, (callBack) => {});

    // Criteria
    this.props.fetchCriteriaMaster(this.props.session, (callBack) => {});

    // Discount Area
    this.props.fetchDiscountArea(this.props.session, (callBack) => {});

    this.props.fetchDiscountMaster(this.props.session, 0, (callBack) => {
      this.setState({ loading: false });
    });
  }

  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchDiscountMaster(this.props.session, 0, (callBack) => {});
    } else {
      this.props.searchDiscountMaster(this.props.session, search);
    }
    this.setState({ search, pageNumber });
  };

  paginationCallback = (pageNumber) => {
    //  console.log("pgno", pageNumber)
    if (this.state.search == "") {
      this.props.fetchDiscountMaster(
        this.props.session,
        pageNumber,
        (response) => {}
      );
    } else {
      this.props.searchDiscountMaster(
        this.props.session,
        this.state.search,
        pageNumber
      );
    }

    this.setState({ ...this.state, pageNumber });
  };

  // Render Discount master List
  renderDiscountMasterList() {
    let pageDataLength = this.props.discountMasterStore.data.length;

    let { pageNumber } = this.state;

    let searchText = this.state.DiscounSearch;
    let filtered = [];

    if (this.state.DiscounSearch !== "") {
      // If Search Key Found
      let listing = this.props.discountMasterStore;

      filtered = _.filter(listing, function (o) {
        return o.title.toLowerCase().indexOf(_.toLower(searchText)) > -1;
      });
    } else {
      filtered = this.props.discountMasterStore;
    }

    return _.map(this.props.discountMasterStore.data, (dismasterObj, index) => {
      return (
        <DiscMasterTableList
          key={dismasterObj.id}
          dismasterObj={dismasterObj}
          position={index}
          pagination={this.paginationCallback}
          pageDataLength={pageDataLength}
          currentPage={pageNumber}
        />
      );
    });
  }

  render() {
    var discountAvailable =
      this.props.discountMasterStore.data &&
      this.props.discountMasterStore.data.length > 0;
    let { language } = this.props;
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader className="mt-4" data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {/* <DiscountCouponTabs history={this.props.history}/> */}
            <div className="px-6 text-2xl font-semibold text-secondary mb-6">
              DISCOUNT MANAGEMENT
            </div>
            <div
              className="grid gap-4 grid-rows-1 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 mt-5 mx-6"
              style={{
                overflowX: "hidden",
                overflowY: "hidden",
                display: "block",
              }}>
              <DiscountManagHead />
              <div className="grid grid-cols-2 xs:grid-cols-1">
                <div
                  className="relative md:w-6/12 xs:w-full text-ternary focus-within:text-secondary smallscreen-search"
                  style={{ marginTop: -35 }}>
                  <div
                    className={classNames(
                      language == "en" ? "left-0 " : "right-0",
                      "absolute inset-y-0 flex items-center pointer-events-none px-1"
                    )}>
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    className={classNames(
                      language == "en" ? "pl-8 pr-3" : "pr-8 pl-3",
                      "block lg:w-6/12 xs:w-full sm:w-full h-full  py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                    )}
                    placeholder={LocaleStrings.search}
                    type="search"
                    name="search"
                    // onChange={(e) =>
                    //   this.setState({ DiscounSearch: e.target.value })
                    // }
                    value={this.state.search}
                    onChange={this.handleSearch}
                  />
                </div>
              </div>
            </div>
            {_.isEmpty(this.props.discountMasterStore.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  No Discount
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col pt-10 pb-2 px-6">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.discountname}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.appliedon}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Area
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.validity}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {/* {LocaleStrings.discount} */} Percentage
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.action}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.renderDiscountMasterList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <Pagination
                  activePage={this.state.pageNumber}
                  itemsCountPerPage={7}
                  totalItemsCount={this.props.discountMasterStore.count}
                  pageRangeDisplayed={10}
                  onChange={this.paginationCallback}
                />

                <DiscountManagementModal />
                <NotificationContainer />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, allReports_listing, language } = state;

  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    language,
    discountMasterStore: state.discountMasterStore,
  };
}

export default connect(mapStateToProps, {
  fetchProductCategory,
  fetchProductSubcategory,
  fetchCriteriaMaster,
  fetchDiscountArea,
  fetchDiscountMaster,
  searchDiscountMaster,
  fetchProducts,
})(ActiveDiscount);
