import LocaleStrings from "../../../languages/index";
import { ALL_FEEDS } from "../actions/index";

export var Allfeeds_lists = (state = {}, action) => {
  switch (action.type) {
    case ALL_FEEDS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
