import React, { Component } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from '@headlessui/react';
import LocaleStrings from "../../../../languages/index";
import { Fragment, useState } from 'react';
import { BaseComponent, LABEL_POSITION_TOP } from "../../../../../common/base-component";
import {  XIcon } from '@heroicons/react/outline';
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import {BASE_IMAGES_URL,APP_API_KEY} from '../../../../../common/constants';
import {rejectRefund,fetchallreturnorderList,fetchallcancelledorderlist,fetchallreplaceorderlist} from "../../actions/index";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {classNames} from "../../../../../common/common-functions";


class RejectOrders extends BaseComponent {
    constructor(props) {
        super(props);
    
        this.state = {
          setOpen:false,
          btnLoader: false,
          
        };
        
        // All Binded Functions  
      
        this.onSubmitForm = this.onSubmitForm.bind(this);

    }
   componentDidMount (){
       
   }


onSubmitForm(values) {
  if( Object.keys(values).length === 0){
      NotificationManager.error("Mandatory Fields are required");
      return false;
    }
    let status = 'refundrejected';
    
    if(this.props.newKey == 'cancelled'){
      status = 'cancelrefundrejected';
    }
  var data = this.props.data;
  var rejectedvalues = {
    status:status,
    orderid:data.orderid,
    orderdetailsid:data.product.currentstatus.orderdetailid,
    reason:values.reason

  };
  this.setState({btnLoader:true});
  this.props.rejectRefund(this.props.session,
    rejectedvalues,
    (callback) => {
      if (callback.success == 1) {
        NotificationManager.success("Successfully rejected");
        if(this.props.newKey == "return"){
          this.props.fetchallreturnorderList(
            this.props.session,
            "return",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {
              
            }
          );
        } else if(this.props.newKey == "replace"){
          this.props.fetchallreplaceorderlist(
            this.props.session,
            "replace",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {
              
            }
          );
        }else{
          this.props.fetchallcancelledorderlist(
            this.props.session,
            "cancelled",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {
             
            }
          );
        }
        this.props.onClose();
      } else {
        NotificationManager.error("Sorry cannot reject");
        this.props.onClose();
      }
    }
  );
  
}

  /*** Render Status Media ***/
  renderStatusMedia = (status_media) => {
    var item = _.map(status_media, (media, index) => {
      var imageUrl = `${BASE_IMAGES_URL}/orders/${media.medianame}?api_key=${APP_API_KEY}`;
      return (
        <div className="pt-6">
          <img
            src={imageUrl}
            className="border-2 border-quaternary h-20 w-20 "
            alt="image"
          />
        </div>
      );
    });

    return item;
  };

    render () {
       var {data} =this.props;
      //  console.log('datadatadata:--',data)
       var { handleSubmit } = this.props;
        var modalheadText='';
        if(this.props.newKey == "return"){
          modalheadText ="Reject Returned Orders";
        } else if(this.props.newKey == "replace"){
          modalheadText ="Reject Replace Orders";
        }else{
          modalheadText ="Reject Cancelled Orders";
        }
        var reason = '';
    var statusMedia =[];
     if(this.props.newKey == "return"){
       var statusArr = this.props.data.product.statuses
       
       var cancelReason = _.filter(statusArr, {
        status: "return"
       })
       
       if(cancelReason.length > 0 ){
        reason = cancelReason[0].returnreason;
       }
       if(cancelReason.length > 0 && cancelReason[0].status_media.length > 0){
        statusMedia = cancelReason[0].status_media;
       }
    
     }else if(this.props.newKey == "cancelled"){
      var statusArr = this.props.data.product.statuses
       
      var cancelReason = _.filter(statusArr, {
       status: "cancelled"
      })
      
      if(cancelReason.length > 0  ){
   
       reason = cancelReason[0].cancelreason;
      }
      if(cancelReason.length > 0 && cancelReason[0].status_media.length > 0){
        statusMedia = cancelReason[0].status_media;
       }
     }
        return (
          <>
          {/* <div className="transitionmodal"> */}
                <Transition.Root show={this.props.isOpen} as={Fragment}>
                <Dialog as="div" static  className="fixed z-10 inset-0 overflow-y-auto" open={this.props.isOpen} onClose={this.props.onClose}  > 
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"  
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide" style={{padding:'0'}}> 
                    <div className="grid grid-cols-2 border-b-2 border-gray-200 pb-4 sticky-header z-50">
                      
                      <div className="flex flex-wrap content-center justify-start mt-4 ml-4">{modalheadText}</div>
                    <div className=" sm:block absolute top-0 right-0 pt-4 pr-4 ">
                        <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}
                        >
                        <span className="sr-only">{LocaleStrings.close}</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    </div>
                    <form
                      className=""
                      onSubmit={handleSubmit(this.onSubmitForm)}
                      encType="multipart/form-data"
                    >
                    <div className="p-6">
                        <div clasName="py-6">
                            Purchased By : {_.trim(data.purchasedby.username)}
                        </div>
                        <div className="pt-6">
                      <div clasName="">
                        Reason : {_.startCase(reason)}
                      </div>
                     
                    </div>
                    {!_.isEmpty(statusMedia) ? ( <div className="flex my-2">
                      {this.renderStatusMedia(statusMedia)}
                    </div>):('')}
                        <div className="my-5">
                            <Field
                              name="reason"
                              mandatory="true"
                              placeholder="Reject Reason"
                              label="Reject Reason"
                              type="text"
                              component={this.renderAllfields}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                        <div>
                  
                  </div>
                  </div>
                  <div className="pt-5">
                        <div className="flex justify-center">
                          <button
                             onClick={this.props.onClose}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            {LocaleStrings.cancel}
                          </button>
                          <button
                            type="submit"
                            
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                              <svg
                            className={classNames(
                                this.state.btnLoader ? '' : 'sr-only',
                                'animate-spin-medium h-5 w-5 rounded-full mx-2'
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                            
                            Reject
                          </button>

                        </div>
                      </div>
                      </form>
                  </div>
                </Transition.Child>
              </div>
              </Dialog>
            </Transition.Root> 
            {/* </div> */}
</>
        );
    }

}


function mapStateToProps(state,ownProps) {
    var { session, user } = state;
    // console.log('session',state.wishlistlist);
    return {
        session,
        user,
        isLoggedIn: state.isLoggedIn,
        sidebarSelectedMenu: state.sidebarSelectedMenu,
        data:ownProps.data
    };
}

var validate =(values) => {
  // console.log("values", values);
  var errors = {};

  var reason = values["reason"];
  

  if (!reason || reason.trim() === "") {
    errors["reason"] = "Required";
  }
  

  return errors;
}

export default connect(mapStateToProps, {
  rejectRefund,fetchallreturnorderList,fetchallcancelledorderlist,fetchallreplaceorderlist
}) (reduxForm({
    validate,
    form:'RejectOrdersForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }) (RejectOrders));

  // export default connect(mapStateToProps, {approveRefund})(ApproveRejectOrders);
