import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../languages/index.js";
import { Fragment } from "react";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import {
  updateRestrictedWords,
  uploadMediaLargeRestrictedWord,
  fetchRestrictedWords,
} from "../../actions/index";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { APP_API_KEY, BASE_IMAGES_URL } from "../../../../../common/constants";

var threeArr = [{}, {}, {}];

// Constant
// let validateExtention = ["doc", "docm", "docx", "docx", "pdf"];
let validateExtention = ["csv"];
class EditRestrictedWordss extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      enar: "en",
    };

    // All Binded Functions
  }

  _handleSelect(event) {
    let { fileArr } = this.state;
    const reader = new FileReader(); // Define reader
    let file = event.target.files[0]; // Take Selected file
    reader.readAsArrayBuffer(file); // Read the file

    // On File Load
    reader.onload = (e) => {
      file.url = e.target.result;
    };

    let ext = file.name.split(".").pop(); // File extension

    // if(_.indexOf(validateExtention, ext) === -1) { // Validate File Extensions
    //     toast.error(LocaleStrings.upload_only_doc_and_pdf_file_error_text);
    // }else {

    // }
    this.setState({ fileName: file.name });
    //this.props.autofill(typeindex,file.name);

    fileArr.push(file);
    this.setState({ fileArr: fileArr });
  }

  onSubmitForm(values) {
    // console.log('this.props.Edit_restricted_words.id',this.props.initialValues.id)
    if (
      Object.keys(threeArr[0]).length +
        Object.keys(threeArr[1]).length +
        Object.keys(threeArr[2]).length ===
      0
    ) {
      //  toast.error(LocaleStrings.attachfile_to_update_error_text);

      let restrictedwords = {
        id: this.props.initialValues.id,
        word_ar: values.word_ar,
        word_en: values.word_en,
      };

      // After file upload
      this.props.updateRestrictedWords(
        this.props.session,
        restrictedwords,
        (callback) => {
          if (callback.success === 1) {
            threeArr[0] = {};

            NotificationManager.success(LocaleStrings.updatedsuccessfully);
            this.props.onClose();
            // Fetch Vendor Details With LoginIdStore
            this.props.fetchRestrictedWords(
              this.props.session,
              0,
              (CallBackResponse) => {}
            );
          }
        }
      );
    } else {
      // Upload Section
      let uploaderCounter = 0;
      let filetobeupload = [];
      let arrPromise = [];

      // Blank File Nmae array
      let word_file = "";

      _.forEach(threeArr, function (value, index) {
        if (Object.keys(value).length > 0) {
          if (index === 0) {
            word_file = value.name;
          }

          let coverJson = {
            type: value.type,
            name: value.name,
            media: value.url,
          };

          filetobeupload.push(coverJson); //
        }
      });

      // console.log('filetobeupload',filetobeupload);
      let counter = filetobeupload.length;
      // console.log('counter',counter);

      // Store File to server first
      filetobeupload.map((item, index) => {
        arrPromise.push(
          new Promise((resolve, reject) => {
            this.props.uploadMediaLargeRestrictedWord(
              this.props.session,
              item,
              (response) => {
                if (response.success === 0) {
                  resolve();
                } else {
                  uploaderCounter++;
                  resolve();
                }
              }
            );
          })
        );
      });

      Promise.all(arrPromise).then(() => {
        if (uploaderCounter === counter) {
          // Store Object
          let restrictedwords = {
            id: this.props.initialValues.id,
            word_file_en: values.word_file_en,
            word_file_ar: values.word_file_ar,
          };

          // After file upload
          this.props.updateRestrictedWords(
            this.props.session,
            restrictedwords,
            (callback) => {
              if (callback.success === 1) {
                threeArr[0] = {};

                NotificationManager.success(LocaleStrings.updatedsuccessfully);
                this.props.onClose();
                // Fetch Vendor Details With LoginIdStore
                this.props.fetchRestrictedFile(
                  this.props.session,
                  (CallBackResponse) => {}
                );
              }
            }
          );
        } else {
          this.props.onClose();
          NotificationManager.error(LocaleStrings.updatederror);
        }
      });
    }
  }
  /**** INPUT LANGUAGE CHANGE en - ar ****/
  handleInputLanguageChange(e) {
    if (parseInt(e.target.value) === 1) {
      this.setState({ enar: "ar" });
    } else {
      this.setState({ enar: "en" });
    }
  }

  _handleSelect(event, typeindex) {
    const reader = new FileReader(); // Define reader
    let file = event.target.files[0]; // Take Selected file
    reader.readAsArrayBuffer(file); // Read the file

    // On File Load
    reader.onload = (e) => {
      file.url = e.target.result;
      // file.name   = file.name;
      // file.url = reader.result;
      //filearr.push(file);
    };

    let ext = file.name.split(".").pop(); // File extension

    if (_.indexOf(validateExtention, ext) === -1) {
      // Validate File Extensions
      toast.error('Please Upload Csv file only"');
    } else {
      this.props.autofill(typeindex, file.name);
      if (typeindex === "word_file_" + this.state.enar) {
        threeArr[0] = file;
      } else if (typeindex === "agreement_template") {
        threeArr[1] = file;
      } else if (typeindex === "idproof") {
        threeArr[2] = file;
      }
    }
  }

  _handleDownload(event, filename) {
    if (
      filename !== null &&
      filename !== "null" &&
      filename !== undefined &&
      filename !== ""
    ) {
      let filepath = `${BASE_IMAGES_URL}/contents/${filename}?api_key=${APP_API_KEY}`;
      window.open(filepath);
    }
  }

  render() {
    // console.log('initialValues',this.props.data);
    var { handleSubmit, pristine, reset, submitting, invalid, initialValues } =
      this.props;
    var { enar } = this.state;
    let modalHeadText = "";
    if (
      this.props.data.word_file_ar != null ||
      this.props.data.word_file_en != null
    ) {
      modalHeadText = "Edit File";
    } else {
      modalHeadText = "Edit Word";
    }
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.myRef}
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div className="text-md leading-6 font-medium text-secondary pl-3">
                      {modalHeadText}
                    </div>
                    <div className="flex flex-wrap content-center justify-end">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}
                        ref={this.myRef}>
                        <span className="sr-only"></span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className=" p-6"
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
                    {/* MOdal Header Ends*/}
                    <div>
                      {initialValues.word_en || initialValues.word_ar ? (
                        <div style={{ display: "flex" }}>
                          <label className="mr-5 text-box-lable">
                            {LocaleStrings.language}
                          </label>

                          <Field
                            name="language"
                            label={LocaleStrings.en}
                            component={this.renderFieldRadioNew}
                            type="radio"
                            value="0"
                            className="mx-2"
                            onChange={this.handleInputLanguageChange.bind(this)}
                          />

                          <Field
                            name="language"
                            label={LocaleStrings.ar}
                            component={this.renderFieldRadioNew}
                            type="radio"
                            value="1"
                            className="mx-2"
                            onChange={this.handleInputLanguageChange.bind(this)}
                          />
                        </div>
                      ) : (
                        <>
                          <div>
                            Note: Download the file and add restricted words and
                            reupload.
                          </div>
                          <div className="end-item">
                            <button
                              className="button"
                              type="button"
                              onClick={(e) =>
                                this._handleDownload(
                                  e,
                                  initialValues.word_file_en
                                )
                              }>
                              Download File
                            </button>
                          </div>
                        </>
                      )}
                      {initialValues.word_en || initialValues.word_ar ? (
                        <div className="mt-6">
                          <Field
                            name={"word_" + enar}
                            label={"Word_" + enar}
                            placeholder={"Word_" + enar}
                            type="text"
                            component={this.renderAllfields}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                          />
                        </div>
                      ) : (
                        <div>
                          <Field
                            name={"word_file_" + enar}
                            label="File"
                            placeholder={LocaleStrings.attach_document}
                            type="text"
                            component={this.renderFileuploadfield}
                            mandatory="false"
                            isdisabled={true}
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-sm font-medium text-secondary"
                            maxlength="0"
                          />

                          <div className="flex justify-end mt-3">
                            <label
                              htmlFor="file-upload-word_file"
                              className="relative btn-primary-outline mt-2 cursor-pointer flex border-2 border-gray-200 rounded p-2">
                              <img
                                className={"h-4 w-4 mt-1"}
                                src={"../images/attachfile.svg"}
                              />
                              <span>Upload File</span>
                              <input
                                id="file-upload-word_file"
                                name="file-upload-word_file"
                                type="file"
                                className="sr-only"
                                onChange={(e) =>
                                  this._handleSelect(e, "word_file_" + enar)
                                }
                              />
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="">
                      <div className="mt-10">
                        <div className="flex justify-center">
                          <button type="submit" className="button">
                            {LocaleStrings.save}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <Toaster limit={1} />
      </>
    );
  }
}
function validate(values) {
  var errors = {};

  var requiredFields = ["word_en"];
  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = "Required";
    }
  });

  return errors;
}
function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log("Edit_restricted_words", state.Edit_restricted_words);

  var newObj = {
    ...state.Edit_restricted_words,
    language: "0",
  };
  // console.log('newObj',newObj)
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    initialValues: newObj,
    data: ownProps.data,
  };
}

export default connect(mapStateToProps, {
  updateRestrictedWords,
  fetchRestrictedWords,
  uploadMediaLargeRestrictedWord,
})(
  reduxForm({
    validate,
    form: "EditRestrictedWords",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EditRestrictedWordss)
);
