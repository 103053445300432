import {
  CURRENCY_RATE_USD,
  CURRENCY_RATE_QR,
  CURRENCY_USD,
  CURRENCY_QR,
} from "../../../../common/constants";

export function currencyRate(currency) {
  let returnText = currency == "QAR" ? CURRENCY_RATE_QR : CURRENCY_RATE_USD;
  return returnText;
}

export function currencyCode(currency) {
  let returnText = currency == "QAR" ? CURRENCY_QR : CURRENCY_USD;
  return returnText;
}

export function isCurrencyRateApplicable(productCurrency, amount) {
  let userCurrency = "QAR";
  amount = parseFloat(amount);

  let newAmount = 0;
  if (userCurrency !== productCurrency) {
    newAmount = amount * CURRENCY_RATE_QR;
    // newAmount = amount / CURRENCY_RATE_USD; //old code
  } else {
    console.log("1");
    newAmount = amount;
  }

  return newAmount;
}
