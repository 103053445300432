import React, { Component } from "react";
import { connect } from "react-redux";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";
import LocaleStrings from "../languages/index.js";
import { Field, reduxForm } from "redux-form";
import { LABEL_POSITION_TOP } from "../../common/base-component";
import { classNames } from "../../common/common-functions";
import { isForgotPasswordOpen } from "../../actions";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { updatesource } from "../../actions/index";
class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      headerText: "",
      btnLoader: false,
      isBtnDisable: false,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  renderField(field) {
    var {
      meta: { touched, error },
    } = field;
    var className = `form-group login-element ${
      touched && error ? "text-red-600 text-xs" : ""
    }`;
    return (
      <div className={className}>
        <label>{field.label}</label>
        <input
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />
        <div className="text-help h-4">{touched ? error : ""}</div>
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ open: true });
    }
    if (nextProps.hedaer) {
      this.setState({ headerText: nextProps.hedaer });
    }
  }

  closeModal() {
    this.setState({ open: false });
    this.props.isForgotPasswordOpen(false);
  }

  // Form Submit
  onFormSubmit(values) {
    this.setState({ isBtnDisable: true, btnLoader: true });
    let newObject = {
      email: values.email,
      source: "superadmin",
    };

    // Common Forgot Password
    this.props.updatesource(this.props.session, newObject, (callback) => {
      if (callback.status === 1) {
        this.setState({ isBtnDisable: false, btnLoader: false });
        NotificationManager.success(
          LocaleStrings.password_reset_link_alert_text
        );
        this.props.reset();
        this.closeModal();
      } else {
        this.setState({ isBtnDisable: false, btnLoader: false });
        NotificationManager.error(LocaleStrings.email_id_not_register_alert);
      }
    });
  }

  render() {
    var { handleSubmit } = this.props;
    return (
      <Transition.Root show={this.state.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.state.open}
          onClose={this.closeModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    onClick={this.closeModal}>
                    <span className="sr-only">{LocaleStrings.close}</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900">
                      {this.state.headerText}
                    </Dialog.Title>
                    <div className="mt-2 mr-14">
                      <p className="text-sm text-gray-500">
                        {LocaleStrings.password_reset_link_text}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mx-14 my-4 sm:flex-row-reverse">
                  <form
                    className="space-y-6"
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-ternary">
                        {LocaleStrings.email_address}
                      </label>
                      <div className="mt-1">
                        <Field
                          name="email"
                          placeholder={LocaleStrings.email}
                          type="email"
                          label={LocaleStrings.email}
                          placeholder={LocaleStrings.email}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          component={this.renderField}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:flex sm:flex-row-reverse">
                      <button
                        disabled={this.state.isBtnDisable}
                        className={classNames(
                          this.state.isBtnDisable ? "cursor-not-allowed" : "",
                          "btn-primary"
                        )}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                        <svg
                          className={classNames(
                            this.state.btnLoader ? "" : "sr-only",
                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        {LocaleStrings.send_reset_link}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function validate(values) {
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.login_form_validation_email_required;
  }

  return errors;
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  isforgotfasswordopen: state.isForgotPasswordOpen,
});

// export default connect(mapStateToProps,{
//     isForgotPasswordOpen
// })(ForgotPassword);

export default reduxForm({
  validate,
  form: "ForgotPasswordForm",
})(
  connect(mapStateToProps, {
    isForgotPasswordOpen,
    updatesource,
  })(ForgotPassword)
);
