import _ from "lodash";
import randomstring from "randomstring";
import axios from "axios";
import { INSTANCE_URL, BASE_IMAGES_URL } from "../../../../common/constants";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  uploadEncodedFileLarge,
  uploadEncodedFile,
} from "../../../../common/network-call";

export var CATEGORY_LIST = "CATEGORY_LIST";
export var SUBCATEGORY_LIST = "SUBCATEGORY_LIST";
export var EDITABLE_CATEGORY = "EDITABLE_CATEGORY";
export var EDITABLE_SUBCATEGORY = "EDITABLE_SUBCATEGORY";
export var SUBCATEGORY_MODAL = "SUBCATEGORY_MODAL";
export var CATEGORY_RELATED_PRODUCT = "CATEGORY_RELATED_PRODUCT";

export function fetchCategory(session, callback) {
  var orderby = `display_order%20ASC`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productcategory?related=productsubcategory_by_categoryid&order=${orderby}`;
  // console.log(url)
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: CATEGORY_LIST,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchsubCategory(session, id, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/getsubcategories`;
  // console.log(url)
  return (dispatch) => {
    postRequest(
      url,
      {
        categoryid: id,
      },
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: SUBCATEGORY_LIST,
          payload: { data: data.data },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function getrelatedCountcatsubcat(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/resourcecountbycatsubcat`;
  // console.log(url)
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function activatedeactiveCatsubcat(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/activateinactivatecatsubcat`;
  // console.log(url)
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function uploadMediaLarge(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else {
    var blob = new Blob([filecontent], { type: "image/jpeg" });
  }

  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${BASE_IMAGES_URL}/vendor/productcategories/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        // console.log('done?',data)
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function addCategory(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/shopez/_table/productcategory`;
  return (dispatch) => {
    _handleMultipleImageUpload(
      session,
      values?.productsubcategory_by_categoryid,
      dispatch,
      (image) => {
        // console.log("image :- ", image);
        if (image.success && image.success == 1) {
          values.productsubcategory_by_categoryid = image.data;
          var body = { resource: [{ ...values }] };
          console.log("body", body);
          postRequest(
            url,
            body,
            session,
            dispatch,
            (response) => {
              // console.log("response :- ", response);

              callback({ success: 1, data: response });
            },
            (error) => {
              callback({ success: 0, message: error });
            }
          );
        } else {
          callback({ success: 2 });
        }
      }
    );
  };
}

export function _handleMultipleImageUpload(
  session,
  uploadFiles,
  dispatch,
  callback
) {
  var promiseArray = [];
  _.map(uploadFiles, (item, index) => {
    promiseArray.push(
      new Promise((resolve, reject) => {
        var file = item?.image ? item.image : "";
        var n = file.search(";base64,");
        if (n > 0) {
          var content = file.split(";base64,")[1];
          var filename = `vendor/productcategories/${randomstring.generate()}.jpg`;
          var imageJson = {
            resource: [
              {
                name: filename,
                type: "file",
                is_base64: true,
                content: content,
              },
            ],
          };
          uploadEncodedFile(
            `${BASE_IMAGES_URL}?check_exist=false`,
            session,
            imageJson,
            dispatch,
            (data) => {
              let imagename = data.name.slice(25);
              uploadFiles[index].image = imagename;
              _handleMultipleImageUploadsubsubcat(
                session,
                item?.productsubcategory_by_parentid,
                dispatch, // Category
                // Sub category
                (returnimage) => {
                  if (returnimage.subsubsuccess == 1) {
                    uploadFiles[index].productsubcategory_by_parentid =
                      returnimage.data;
                    resolve();
                  }
                }
              );
            },
            (error) => {
              // callback({success:2, message:error});
            }
          );
        } else if (file == "" || file == "removed") {
          uploadFiles[index].image = null;
          _handleMultipleImageUploadsubsubcat(
            session,
            item?.productsubcategory_by_parentid,
            dispatch, // Category
            // Sub category
            (returnimage) => {
              if (returnimage.subsubsuccess == 1) {
                uploadFiles[index].productsubcategory_by_parentid =
                  returnimage.data;
                resolve();
              }
            }
          );
          // resolve();
        } else {
          uploadFiles[index].image = file;
          _handleMultipleImageUploadsubsubcat(
            session,
            item?.productsubcategory_by_parentid,
            dispatch, // Category
            // Sub category
            (returnimage) => {
              if (returnimage.subsubsuccess == 1) {
                uploadFiles[index].productsubcategory_by_parentid =
                  returnimage.data;
                resolve();
              }
            }
          );
        }
      })
    );
  });
  //  console.log('promiseArray',promiseArray)
  //  console.log('uploadFiles',uploadFiles)
  Promise.all(promiseArray).then(() => {
    // debugger;
    callback({ success: 1, data: uploadFiles });
  });
}
export function _handleMultipleImageUploadsubsubcat(
  session,
  uploadFiles,
  dispatch,
  callback
) {
  var promiseArray = [];
  _.map(uploadFiles, (item, index) => {
    promiseArray.push(
      new Promise((resolve, reject) => {
        var file = item?.image ? item.image : "";
        var n = file.search(";base64,");

        if (n > 0) {
          var content = file.split(";base64,")[1];
          var filename = `vendor/productcategories/${randomstring.generate()}.jpg`;
          var imageJson = {
            resource: [
              {
                name: filename,
                type: "file",
                is_base64: true,
                content: content,
              },
            ],
          };
          uploadEncodedFile(
            `${BASE_IMAGES_URL}?check_exist=false`,
            session,
            imageJson,
            dispatch,
            (data) => {
              let imagename = data.name.slice(25);
              uploadFiles[index].image = imagename;
              // uploadFiles[index].image = data.name;//name changes for uploaded image in db
              resolve();
            },
            (error) => {
              // callback({success:2, message:error});
            }
          );
        } else if (file == "" || file == "removed") {
          uploadFiles[index].image = null;
          resolve();
        } else {
          uploadFiles[index].image = file;
          resolve();
        }
      })
    );
  });

  Promise.all(promiseArray).then(() => {
    callback({ subsubsuccess: 1, data: uploadFiles });
  });
}

export function addsubCategory(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/shopez/_table/productsubcategory`;
  // console.log("post values :- ", values);

  return (dispatch) => {
    _handleMultipleImageUpload(session, values, dispatch, (image) => {
      // console.log("image :- ", image);

      if (image.success == 1) {
        values = image.data;

        var body = { resource: { ...values } };
        // console.log("body :- ", body);

        postRequest(
          url,
          body,
          session,
          dispatch,
          (response) => {
            // console.log("response :- ", response);

            callback({ success: 1, data: response });
          },
          (error) => {
            callback({ success: 0, message: error });
          }
        );
      } else {
        callback({ success: 2 });
      }
    });
  };
}

export function editcategory(editcategory) {
  // console.log('editcategory',editcategory)
  return (dispatch) => {
    dispatch({ type: EDITABLE_CATEGORY, payload: editcategory });
  };
}

export function editsubcategory(editsubcategory) {
  let editablesubcat = { productsubcategory_by_categoryid: [editsubcategory] };
  editablesubcat.productsubcategory_by_categoryid[0][
    "productsubcategory_by_parentid"
  ] = editablesubcat.productsubcategory_by_categoryid[0]["subcategories"];

  return (dispatch) => {
    dispatch({ type: EDITABLE_SUBCATEGORY, payload: editablesubcat });
  };
}

export function updateCategory(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productcategory/`;
  // console.log("post values :- ", values);

  return (dispatch) => {
    _handleMultipleImageUpload(
      session,
      values?.productsubcategory_by_categoryid,
      dispatch,
      (image) => {
        // console.log("image :- ", image);

        if (image.success == 1) {
          values.productsubcategory_by_categoryid = image.data;

          var body = { resource: [{ ...values }] };
          // console.log("body :- ", body);

          patchRequest(
            url,
            body,
            session,
            dispatch,
            (response) => {
              // console.log("response :- ", response);

              callback({ success: 1, data: response });
            },
            (error) => {
              callback({ success: 0, message: error });
            }
          );
        } else {
          callback({ success: 2, message: image?.message });
        }
      }
    );
  };
}

export function updatesubCategory(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productcategory/`;
  return (dispatch) => {
    _handleMultipleImageUpload(
      session,
      values?.productsubcategory_by_categoryid,
      dispatch,
      (image) => {
        if (image.success == 1) {
          values.productsubcategory_by_categoryid = image.data;
          var body = { resource: [{ ...values }] };
          patchRequest(
            url,
            body,
            session,
            dispatch,
            (response) => {
              callback({ success: 1, data: response });
            },
            (error) => {
              callback({ success: 0, message: error });
            }
          );
        } else {
          callback({ success: 2, message: image?.message });
        }
      }
    );
  };
}

export function activeInactivesubCategory(session, body, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productsubcategory/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        dispatch(fetchCategory(session));
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function subcategorymodal(subcategorymodal) {
  return (dispatch) => {
    dispatch({ type: SUBCATEGORY_MODAL, payload: subcategorymodal });
  };
}

export function inactiveCategory(session, body, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productcategory/`;
  return (dispatch) => {
    patchRequest(
      url,
      session,
      body,
      dispatch,
      (data) => {
        callback({ error: 0, result: data });
        dispatch(fetchCategory(session));
      },
      (error) => {
        callback({ error: 1, message: error });
      }
    );
  };
}
export function deletesubCategory(session, values, callback) {
  var filter = encodeURI(`filter=(id=${values.id})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productsubcategory?${filter}`;
  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (data) => {
        callback({ error: 0, result: data });
      },
      (error) => {
        callback({ error: 1, message: error });
      }
    );
  };
}

export function checkifProductUnderCategory(session, id, callfrom, callback) {
  let body = {};
  if (callfrom == "category") {
    body.catid = id;
  } else {
    body.subcatid = id;
  }
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/catenbledisable`;

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function reorderCategory(session, newKey, oldKeys, action) {
  // console.log('newKeyp',newKey);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productcategory/`;
  let oldRecord = [];
  let updateCatArr = [];

  if (oldKeys.length > 0) {
    oldRecord = oldKeys;
  }

  if (action === "new") {
    oldRecord = newKey;
  }

  if (action === "add") {
    oldRecord.push(newKey);
  } else if (action === "remove") {
    _.remove(oldKeys, {
      id: newKey.id,
    });
  } else if (action === "sort") {
    oldRecord = newKey;
  }

  _.forEach(oldRecord, function (val) {
    var obj = {
      id: val.id,
      display_order: val.display_order,
    };

    updateCatArr.push(obj);
  });

  let body = {
    resource: {
      ...updateCatArr,
    },
  };
  // console.log('old',body)

  return (dispatch) => {
    dispatch({ type: CATEGORY_LIST, payload: { data: oldRecord } });
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {},
      (error) => {}
    );
  };
}

export function reorderSubCategory(session, newKey, oldKeys, action) {
  // console.log('newKeyp',newKey);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productsubcategory/`;
  let oldRecord = [];
  let updateCatArr = [];

  if (oldKeys.length > 0) {
    oldRecord = oldKeys;
  }

  if (action === "new") {
    oldRecord = newKey;
  }

  if (action === "add") {
    oldRecord.push(newKey);
  } else if (action === "remove") {
    _.remove(oldKeys, {
      id: newKey.id,
    });
  } else if (action === "sort") {
    oldRecord = newKey;
  }

  _.forEach(oldRecord, function (val) {
    var obj = {
      id: val.id,
      display_order: val.display_order,
    };

    updateCatArr.push(obj);
  });

  let body = {
    resource: {
      ...updateCatArr,
    },
  };
  console.log("old", body);

  return (dispatch) => {
    dispatch({ type: SUBCATEGORY_LIST, payload: { data: oldRecord } });
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {},
      (error) => {}
    );
  };
}

export function clearsubcategorymodal(subcategorymodal) {
  return (dispatch) => {
    dispatch({ type: SUBCATEGORY_LIST, payload: { data: "" } });
  };
}
