import LocaleStrings from "../../../languages/index";
import {
  TICKET_LIST,
  REPORTS_LIST,
  APPSUGGESTION_LIST,
  SENDING_REPORTANDSUGGESTION_REPLY,
  REPORT_VIEW_ALL,
  SUGGESTION_REPLY,
} from "../actions";

export var TicketReportTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    {
      name: LocaleStrings.tickets,
      href: "tickets",
      current: true,
      tabindex: 1,
    },
    {
      name: LocaleStrings.reports,
      href: "reports",
      current: false,
      tabindex: 2,
    },
    {
      name: LocaleStrings.appsuggestions,
      href: "appsuggestion",
      current: false,
      tabindex: 3,
    },
  ];

  return state;
};

export function allTickets_listing(state = {}, action) {
  switch (action.type) {
    case TICKET_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allReports_listing(state = {}, action) {
  switch (action.type) {
    case REPORTS_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function Appsuggestion_list(state = {}, action) {
  switch (action.type) {
    case APPSUGGESTION_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function ticketandsuggestionReply(state = {}, action) {
  switch (action.type) {
    case SENDING_REPORTANDSUGGESTION_REPLY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function Reports_allview(state = {}, action) {
  switch (action.type) {
    case REPORT_VIEW_ALL:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function Reply_tosuggestion(state = {}, action) {
  switch (action.type) {
    case SUGGESTION_REPLY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
