import React from "react";
import { connect } from "react-redux";
import { singleCustomerobj, newtabs } from "../actions/index";
import EditcustomerModal from "./editmodal";
import { editmodal, updateCustomer } from "../actions/index";
import { BaseComponent } from "../../../../common/base-component";
import BanCustomer from "./bancustomer";
import UnBanCustomer from "./unbancustomer";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import LocaleStrings from "../../../languages";

class CustomerListitem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
      unbanopen: false,
    };
  }
  openBanModal = (e, obj) => {
    var userOngoingOrder = _.filter(this.props.orderMasterList.data, {
      userid: obj.userid,
    });

    if (userOngoingOrder.length > 0) {
      NotificationManager.error(
        "Cannot ban this user as they already have an ongoing order"
      );
    } else {
      this.props.singleCustomerobj(obj);
      this.setState({ open: true });
    }
  };
  closebanModal = () => this.setState({ open: false });

  openUnBanModal = (e, obj) => {
    this.props.singleCustomerobj(obj);
    this.setState({ unbanopen: true });
  };
  closeUnbanModal = () => this.setState({ unbanopen: false });

  openModal = (e, obj) => {
    this.props.singleCustomerobj(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  openTabs(obj) {
    // console.log('obj',obj)
    this.props.singleCustomerobj(obj);
    this.props.newtabs(obj);
  }
  _banFromBiding(e, values) {
    // console.log('deleteNewsCallback', values)
    let obj = {};
    obj.userid = values.userid;
    obj.banfrom_auction = 1;
    confirmAlert({
      title: "Ban Customer from biding",
      message: `Are you sure you want to ban this customer from biding?`,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            var id = values;
            // console.log('id',id)
            this.props.updateCustomer(this.props.session, obj, (resource) => {
              // console.log("showresource :- ", resource);
              if (resource.error) {
                NotificationManager.error("Successfully banned from auction");
              } else {
                NotificationManager.success("Banned from auction");
              }
            });
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  _unbanFromBiding(e, values) {
    // console.log('deleteNewsCallback', values)
    let obj = {};
    obj.userid = values.userid;
    obj.banfrom_auction = 0;
    confirmAlert({
      title: "Unban Customer from biding",
      message: `Are you sure you want to unban this customer from biding?`,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            var id = values;
            // console.log('id',id)
            this.props.updateCustomer(this.props.session, obj, (resource) => {
              // console.log("showresource :- ", resource);
              if (resource.error) {
                NotificationManager.error("Cannot unban customer from bidding");
              } else {
                NotificationManager.success("Unbanned from auction");
              }
            });
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { values } = this.props;
    // console.log('values1', values);
    return (
      <>
        {values.isactive == true ? (
          <>
            {" "}
            <tr className="bg-white border-b-2 border-gray-50 hover-all">
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openTabs(values)}>
                <div>{values.username}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openTabs(values)}>
                <div>{values.email}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openTabs(values)}>
                <div>{values.phone}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  {values.banfrom_auction == 0 ? (
                    <div className="">
                      <button
                        className="button"
                        onClick={(e) => this._banFromBiding(e, values)}>
                        Ban From Biding
                      </button>
                    </div>
                  ) : (
                    <div className="">
                      <button
                        className="button"
                        onClick={(e) => this._unbanFromBiding(e, values)}>
                        UnBan From Biding
                      </button>
                    </div>
                  )}

                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this.openModal(e, values)}
                      type="button">
                      Edit
                    </button>
                    {this.state.isOpen ? (
                      <EditcustomerModal
                        isOpen={this.state.isOpen}
                        onClose={this.closeModal}
                        currentPage={this.props.currentPage}
                        pagination={this.props.pagination}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this.openBanModal(e, values)}>
                      Ban
                    </button>
                    {this.state.open ? (
                      <BanCustomer
                        isOpen={this.state.open}
                        onClose={this.closebanModal}
                        currentPage={this.props.currentPage}
                        pagination={this.props.pagination}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr className="bg-gray-300 border-b-2 border-gray-50">
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openTabs(values)}>
                <div>{values.username}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openTabs(values)}>
                <div>{values.email}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openTabs(values)}>
                <div>{values.phone}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this.openUnBanModal(e, values)}>
                      Unban
                    </button>
                    {this.state.unbanopen ? (
                      <UnBanCustomer
                        isOpen={this.state.unbanopen}
                        onClose={this.closeUnbanModal}
                        currentPage={this.props.currentPage}
                        pagination={this.props.pagination}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {values.users_by_bannedby.length > 0 ? (
                    <div>Banned By: {values.users_by_bannedby.username}</div>
                  ) : (
                    ""
                  )}
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.customermanagementTabs,
    customereditmodal: state.customereditmodal,
    customerlistingtabs: state.customerlistingtabs,
    newtablist: state.newtablist,
    orderMasterList: state.orderMasterList,
  };
}

export default connect(mapStateToProps, {
  singleCustomerobj,
  newtabs,
  editmodal,
  updateCustomer,
})(CustomerListitem);
