import React, { Component } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../languages/index";
import { Fragment, useState } from "react";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import {
  XIcon,
  ArrowCircleUpIcon,
  ArrowCircleDownIcon,
  ClipboardCopyIcon,
} from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../../common/constants";
import ShippingStatus from "../../../sellermanagement/components/tabs/oders/shippingstatus";
import moment from "moment-timezone";
import {
  getIntToFloat,
  classNames,
  getBilangualeText,
} from "../../../../../common/common-functions";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  updateorder,
  fetchallnewoderlist,
  fetchalldeliveredorderlist,
  // message
  createOrOpenOrderThread,
  fetchOrderThreadMsgList,
  getOrderThreadWithId,
  isSendMessageModalOpen,
  updateBasicOrderthreadData,
  updateOrderStep,
  selleridStore,
  updateinitiateRefund,
  partnerScriptorder,
  downloadorderInvoice,
} from "../../actions/index";
import {
  fetchallcancelledorderlist,
  fetchallreturnorderList,
  fetchallreplaceorderlist,
} from "../../../disputemanagement/actions/index";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../currency/action/index";
import ReviewAndRatingLimited from "./review-and-rating-limited.js";
import AllReasonComponent from "./allreasoncomponent";
import Message from "../message";

const limit = 10;

class AllneworderdetailsModals extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      updateordStateBox: true,
      /* Button Loader */
      markAsShiptedBtnLoader: false,
      cancelOrderBtnLoader: false,
      inTransitBtnLoader: false,
      markAsDeliveredBtnLoader: false,
      initiatPickUpBtnLoader: false,
      confirmPickupBtnLoader: false,
      markReceivedBtnLoader: false,
      downloadInvoiceBtnLoader: false,
      copedLoader: false,
      firstTimeOpeing: true,
      selectedThreadId: "",
    };

    // All Binded Functions

    this.updateordStateBoxToggle = this.updateordStateBoxToggle.bind(this);
    this._handelCancelOrder = this._handelCancelOrder.bind(this);
    this._handelInitiateRefund = this._handelInitiateRefund.bind(this);
    this._handelMarkAsShipted = this._handelMarkAsShipted.bind(this);
    this._handelInTransit = this._handelInTransit.bind(this);
    this._handelMarkDelivered = this._handelMarkDelivered.bind(this); //
    this._handelInitiatePickup = this._handelInitiatePickup.bind(this);
    this._handelReceived = this._handelReceived.bind(this);
    this._handelConfirmPicup = this._handelConfirmPicup.bind(this);
    this.openImagesInLightBox = this.openImagesInLightBox.bind(this);
    this._handelDownloadInvoice = this._handelDownloadInvoice.bind(this);
  }
  componentDidMount() {}

  componentWillUnmount() {
    this.setState({
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_negotiationsoffers,
      negoSearchText: "",
      updateordStateBox: true,

      markAsShiptedBtnLoader: false,
      cancelOrderBtnLoader: false,
      inTransitBtnLoader: false,
      markAsDeliveredBtnLoader: false,
      initiatPickUpBtnLoader: false,
      confirmPickupBtnLoader: false,
      markReceivedBtnLoader: false,
      downloadInvoiceBtnLoader: false,
      copedLoader: false,
    });
  }

  /*** Copy email to clicpboard ****/
  copyEmailToClipboard = (evant, emailid) => {
    this.setState({ copedLoader: true });
    navigator.clipboard.writeText(emailid);

    setTimeout(() => {
      this.setState({ copedLoader: false });
    }, 1000);
  };

  /**** Toggle Update Order Status Box  ****/
  updateordStateBoxToggle() {
    let updateordStateBox = this.state.updateordStateBox;
    this.setState({ updateordStateBox: !updateordStateBox });
  }
  _handelCancelOrder() {
    this.setState({ cancelOrderBtnLoader: true });
    let cancelReasonVal = _.trim(
      document.querySelector("#cancel_reason_inputbox").value
    );
    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    let dataObj = {
      orderid: orderidVal,
      orderdetailsid: orderdetailsidVal,
      status: "cancelled",
      cancelreason: cancelReasonVal,
    };

    if (
      cancelReasonVal === "" ||
      cancelReasonVal === "null" ||
      cancelReasonVal === null
    ) {
      NotificationManager.error(LocaleStrings.cancel_reason_error_text);
      this.setState({ cancelOrderBtnLoader: false });
    } else {
      // Order Cancel Confirmation from Vendor
      confirmAlert({
        title: LocaleStrings.alert_head_cancel,
        message: `${LocaleStrings.alert_order_cancel_subtext}`,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              // Yes
              this.props.updateorder(
                this.props.session,
                dataObj,
                (callBack) => {
                  if (
                    callBack.status === true ||
                    callBack.status === 1 ||
                    callBack.status
                  ) {
                    this.setState({ cancelOrderBtnLoader: true });
                    NotificationManager.success("Order Cancelled Successfully");
                    // Refresh Order List
                    if (this.props.newKey == "return") {
                      this.props.fetchallreturnorderList(
                        this.props.session,
                        "return",
                        10,
                        1,
                        "listing",
                        "",
                        (CallBackResponse) => {}
                      );
                    } else if (this.props.newKey == "replace") {
                      this.props.fetchallreplaceorderlist(
                        this.props.session,
                        "replace",
                        10,
                        1,
                        "listing",
                        "",
                        (CallBackResponse) => {}
                      );
                    } else if (this.props.newKey == "cancelled") {
                      this.props.fetchallcancelledorderlist(
                        this.props.session,
                        "cancelled",
                        10,
                        1,
                        "listing",
                        "",
                        (CallBackResponse) => {}
                      );
                    } else if (this.props.newKey == "new") {
                      this.props.fetchallnewoderlist(
                        this.props.session,
                        "new",
                        10,
                        1,
                        "listing",
                        "",
                        (CallBackResponse) => {}
                      );
                    }
                    // this.props.fetchallnewoderlist(this.props.session,"new",limit,1,"listing","",(CallBackResponse) => {});
                    // Close Modal
                    this.props.onClose();
                  }
                }
              );
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      }); // Confirmation End
    }
  }

  /***** Mark Delivered *****/
  _handelMarkDelivered() {
    this.setState({ markAsDeliveredBtnLoader: true });

    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    let dataObj = {
      orderid: orderidVal,
      orderdetailsid: orderdetailsidVal,
      status: "delivered",
    };

    this.props.updateorder(this.props.session, dataObj, (callBack) => {
      if (
        callBack.status === true ||
        callBack.status === 1 ||
        callBack.status
      ) {
        this.setState({ markAsDeliveredBtnLoader: false });
        NotificationManager.success(LocaleStrings.mark_delivered_successfully);
        // Refresh Order List
        if (this.props.newKey == "return") {
          this.props.fetchallreturnorderList(
            this.props.session,
            "return",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "replace") {
          this.props.fetchallreplaceorderlist(
            this.props.session,
            "replace",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "cancelled") {
          this.props.fetchallcancelledorderlist(
            this.props.session,
            "cancelled",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "new") {
          this.props.fetchallnewoderlist(
            this.props.session,
            "new",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        }
        // this.props.fetchallnewoderlist(this.props.session,"new",limit,1,"listing","",(CallBackResponse) => {});
        // Close Modal
        this.props.onClose();
      } else {
        this.setState({ markAsDeliveredBtnLoader: false });
      }
    });
  }

  /**** Download invoice button old  ****/
  // _handelDownloadInvoice() {
  //   let { downloadInvoiceBtnLoader } = this.state;
  //   this.setState({ downloadInvoiceBtnLoader: true });
  //   let path = this.props.data?.product?.invoice_path;

  //   setTimeout(() => {
  //     let invoiceFilePath = `${BASE_IMAGES_URL}/${path}?api_key=${APP_API_KEY}`;
  //     window.open(invoiceFilePath);
  //     this.setState({ downloadInvoiceBtnLoader: false });
  //   }, 500);
  // }
  /**** Download invoice button  ****/
  _handelDownloadInvoice() {
    let { downloadInvoiceBtnLoader } = this.state;
    this.setState({ downloadInvoiceBtnLoader: true });
    // let path = this.props.data?.product?.invoice_path;

    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );

    let val = {
      orderdetailid: orderdetailsidVal,
    };
    // console.log("val", val);

    this.props.downloadorderInvoice(this.props.session, val, (callBack) => {
      if (callBack.success == 1) {
        // console.log("callBack", callBack);
        let path = callBack?.data?.invoice;
        setTimeout(() => {
          // let invoiceFilePath = "http://www.africau.edu/images/default/sample.pdf";
          let invoiceFilePath = `${BASE_IMAGES_URL}/${path}?api_key=${APP_API_KEY}`;
          window.open(invoiceFilePath);
          this.setState({ downloadInvoiceBtnLoader: false });
        }, 500);
      } else {
        this.setState({ downloadInvoiceBtnLoader: false });
        NotificationManager.error(LocaleStrings.something_went_wrong);
      }
    });
  }

  _handelInitiateRefund() {
    let { data } = this.props;
    let status = "refund";
    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    if (this.props.newKey == "cancelled") {
      status = "cancelrefund";
    }
    // let dataObj = {
    //   orderid: orderidVal,
    //   orderdetailsid: orderdetailsidVal,
    //   status: status,
    // };
    let dataObj = {
      paytype: "refund",
      status: data?.product?.currentstatus?.status,
      orderdetailsid: orderdetailsidVal,
    };
    // Refund Initiate Confirmation from Vendor
    confirmAlert({
      title: LocaleStrings.initiate_refund,
      message: `${LocaleStrings.alert_refund_initiate_subtext}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            // Yes
            this.props.updateinitiateRefund(
              this.props.session,
              dataObj,
              (callBack) => {
                if (
                  callBack.status === true ||
                  callBack.status === 1 ||
                  callBack.status
                ) {
                  NotificationManager.success("Refund Initiated Successfully");
                  // Refresh Order List
                  if (this.props.newKey == "return") {
                    this.props.fetchallreturnorderList(
                      this.props.session,
                      "return",
                      10,
                      1,
                      "listing",
                      "",
                      (CallBackResponse) => {}
                    );
                  } else if (this.props.newKey == "replace") {
                    this.props.fetchallreplaceorderlist(
                      this.props.session,
                      "replace",
                      10,
                      1,
                      "listing",
                      "",
                      (CallBackResponse) => {}
                    );
                  } else if (this.props.newKey == "cancelled") {
                    this.props.fetchallcancelledorderlist(
                      this.props.session,
                      "cancelled",
                      10,
                      1,
                      "listing",
                      "",
                      (CallBackResponse) => {}
                    );
                  } else if (this.props.newKey == "new") {
                    this.props.fetchallnewoderlist(
                      this.props.session,
                      "new",
                      10,
                      1,
                      "listing",
                      "",
                      (CallBackResponse) => {}
                    );
                  }
                  // this.props.fetchallcancelledorderlist(this.props.session,"cancelled",limit,1,"listing","",(CallBackResponse) => {});

                  // Close Modal
                  this.props.onClose();
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    }); // Confirmation End
  }

  /**** Initiate Pickup ****/
  _handelInitiatePickup() {
    this.setState({ initiatPickUpBtnLoader: true });
    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    let dataObj = {
      orderid: orderidVal,
      orderdetailsid: orderdetailsidVal,
      status: "initiatepickup",
    };

    this.props.updateorder(this.props.session, dataObj, (callBack) => {
      if (
        callBack.status === true ||
        callBack.status === 1 ||
        callBack.status
      ) {
        this.setState({ initiatPickUpBtnLoader: true });
        NotificationManager.success("Successfully Initiated Pick up");
        // Refresh Order List
        if (this.props.newKey == "return") {
          this.props.fetchallreturnorderList(
            this.props.session,
            "return",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "replace") {
          this.props.fetchallreplaceorderlist(
            this.props.session,
            "replace",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "cancelled") {
          this.props.fetchallcancelledorderlist(
            this.props.session,
            "cancelled",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "new") {
          this.props.fetchallnewoderlist(
            this.props.session,
            "new",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        }
        // this.props.fetchallnewoderlist(this.props.session,"new",limit,1,"listing","",(CallBackResponse) => {});
        // Close Modal
        this.props.onClose();
      }
    });
  }

  /**** make In Transit *****/
  _handelInTransit() {
    this.setState({ inTransitBtnLoader: true });
    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    let dataObj = {
      orderid: orderidVal,
      orderdetailsid: orderdetailsidVal,
      status: "intransit",
    };
    this.props.updateorder(this.props.session, dataObj, (callBack) => {
      if (
        callBack.status === true ||
        callBack.status === 1 ||
        callBack.status
      ) {
        this.setState({ inTransitBtnLoader: true });
        NotificationManager.success("In Transitted");
        // Refresh Order List
        // this.props.fetchallnewoderlist(this.props.session,"new",limit,1,"listing","",(CallBackResponse) => {});
        if (this.props.newKey == "return") {
          this.props.fetchallreturnorderList(
            this.props.session,
            "return",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "replace") {
          this.props.fetchallreplaceorderlist(
            this.props.session,
            "replace",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "cancelled") {
          this.props.fetchallcancelledorderlist(
            this.props.session,
            "cancelled",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "new") {
          this.props.fetchallnewoderlist(
            this.props.session,
            "new",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        }
        // Close Modal
        this.props.onClose();
      }
    });
  }

  /**** From Return Pickup -> Return Received ****/
  _handelReceived() {
    this.setState({ markReceivedBtnLoader: true });
    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    let dataObj = {
      orderid: orderidVal,
      orderdetailsid: orderdetailsidVal,
      status: "returnreceived",
    };

    this.props.updateorder(this.props.session, dataObj, (callBack) => {
      if (
        callBack.status === true ||
        callBack.status === 1 ||
        callBack.status
      ) {
        if (
          this.props.data.hasOwnProperty("relatedorder") &&
          this.props.data?.summary?.refund_amount > 0
        ) {
          this._handelInitiateRefund_fromMarkreceived("returnreceived");
        } else {
          this.setState({ markReceivedBtnLoader: true });
          NotificationManager.success(
            LocaleStrings.marked_received_successfully
          );
          // Refresh Order List
          this.props.fetchallreturnorderList(
            this.props.session,
            "return",
            limit,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
          // commentedon 27.02.2024
          if (this.props.newKey == "return") {
            this.props.fetchallreturnorderList(
              this.props.session,
              "return",
              10,
              1,
              "listing",
              "",
              (CallBackResponse) => {}
            );
          } else if (this.props.newKey == "replace") {
            this.props.fetchallreplaceorderlist(
              this.props.session,
              "replace",
              10,
              1,
              "listing",
              "",
              (CallBackResponse) => {}
            );
          } else if (this.props.newKey == "cancelled") {
            this.props.fetchallcancelledorderlist(
              this.props.session,
              "cancelled",
              10,
              1,
              "listing",
              "",
              (CallBackResponse) => {}
            );
          } else if (this.props.newKey == "new") {
            this.props.fetchallnewoderlist(
              this.props.session,
              "new",
              10,
              1,
              "listing",
              "",
              (CallBackResponse) => {}
            );
          }
          // Close Modal
          this.props.onClose();
        }
      }
    });
  }

  _handelInitiateRefund_fromMarkreceived(status_sent) {
    let { data } = this.props;
    let status = "refund";
    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    if (this.props.newKey == "cancelled") {
      status = "cancelrefund";
    }
    // let dataObj = {
    //   orderid: orderidVal,
    //   orderdetailsid: orderdetailsidVal,
    //   status: status,
    // };
    let dataObj = {
      paytype: "refund",
      status: status_sent,
      orderdetailsid: orderdetailsidVal,
    };

    // Yes
    this.props.updateinitiateRefund(this.props.session, dataObj, (callBack) => {
      if (
        callBack.status === true ||
        callBack.status === 1 ||
        callBack.status
      ) {
        NotificationManager.success("Refund Initiated Successfully");
        this.setState({ markReceivedBtnLoader: false });
        // Refresh Order List
        if (this.props.newKey == "return") {
          this.props.fetchallreturnorderList(
            this.props.session,
            "return",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "replace") {
          this.props.fetchallreplaceorderlist(
            this.props.session,
            "replace",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "cancelled") {
          this.props.fetchallcancelledorderlist(
            this.props.session,
            "cancelled",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "new") {
          this.props.fetchallnewoderlist(
            this.props.session,
            "new",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        }
        // this.props.fetchallcancelledorderlist(this.props.session,"cancelled",limit,1,"listing","",(CallBackResponse) => {});

        // Close Modal
        this.props.onClose();
      }
    });
  }

  /**** Mark as shipted with tracking number ****/
  _handelMarkAsShipted() {
    let { vendorOrderListStore, currentOrderID, currentOrderObj } = this.props;

    let data = this.props.data;

    let currentstatus = data.product.currentstatus.status;

    this.setState({ markAsShiptedBtnLoader: true });

    let trackingNumberVal = _.trim(
      document.querySelector("#tracking_number").value
    );

    if (
      trackingNumberVal === "" ||
      trackingNumberVal === "null" ||
      trackingNumberVal === null
    ) {
      NotificationManager.error(LocaleStrings.tracking_number_error_text);
      this.setState({ markAsShiptedBtnLoader: false });
    } else {
      let orderidVal = _.trim(document.querySelector("#orderid").value);
      let orderdetailsidVal = _.trim(
        document.querySelector("#orderdetailsid").value
      );
      let dataObj = {
        orderid: orderidVal,
        orderdetailsid: orderdetailsidVal,
        trackingnumber: trackingNumberVal,
        status: currentstatus === "returnreceived" ? "reship" : "dispatched",
      };

      let vendorcountry = _.startCase(data?.product?.seller.country);
      let deliverycountry = _.startCase(data?.delivery_address?.country);

      if (
        dataObj.status == "dispatched" &&
        vendorcountry == "Qatar" &&
        deliverycountry == "Qatar"
      ) {
        let val1 = {
          orderdetailsid: orderdetailsidVal,
        };
        this.props.partnerScriptorder(this.props.session, val1, (callBack) => {
          if (this.props.newKey == "return") {
            this.props.fetchallreturnorderList(
              this.props.session,
              "return",
              10,
              1,
              "listing",
              "",
              (CallBackResponse) => {}
            );
          } else if (this.props.newKey == "replace") {
            this.props.fetchallreplaceorderlist(
              this.props.session,
              "replace",
              10,
              1,
              "listing",
              "",
              (CallBackResponse) => {}
            );
          } else if (this.props.newKey == "cancelled") {
            this.props.fetchallcancelledorderlist(
              this.props.session,
              "cancelled",
              10,
              1,
              "listing",
              "",
              (CallBackResponse) => {}
            );
          } else if (this.props.newKey == "new") {
            this.props.fetchallnewoderlist(
              this.props.session,
              "new",
              10,
              1,
              "listing",
              "",
              (CallBackResponse) => {}
            );
          }
          // Close Modal
          this.setState({ markAsShiptedBtnLoader: false });
          this.props.onClose();
        });
      } else {
        this.props.updateorder(this.props.session, dataObj, (callBack) => {
          if (
            callBack.status === true ||
            callBack.status === 1 ||
            callBack.status
          ) {
            this.setState({ markAsShiptedBtnLoader: false });

            if (currentstatus === "returnreceived") {
              NotificationManager.success(
                LocaleStrings.marked_asreshipped_successfully
              );
            } else {
              NotificationManager.success(
                LocaleStrings.marked_asshipped_successfully
              );
            }

            // Refresh Order List
            // this.props.fetchallnewoderlist(this.props.session,"new",limit,1,"listing","",(CallBackResponse) => {});
            if (this.props.newKey == "return") {
              this.props.fetchallreturnorderList(
                this.props.session,
                "return",
                10,
                1,
                "listing",
                "",
                (CallBackResponse) => {}
              );
            } else if (this.props.newKey == "replace") {
              this.props.fetchallreplaceorderlist(
                this.props.session,
                "replace",
                10,
                1,
                "listing",
                "",
                (CallBackResponse) => {}
              );
            } else if (this.props.newKey == "cancelled") {
              this.props.fetchallcancelledorderlist(
                this.props.session,
                "cancelled",
                10,
                1,
                "listing",
                "",
                (CallBackResponse) => {}
              );
            } else if (this.props.newKey == "new") {
              this.props.fetchallnewoderlist(
                this.props.session,
                "new",
                10,
                1,
                "listing",
                "",
                (CallBackResponse) => {}
              );
            }
            // Close Modal
            this.props.onClose();
          } else {
            this.setState({ markAsShiptedBtnLoader: false });
          }
        });
      }
    }
  }

  _handelConfirmPicup() {
    this.setState({ confirmPickupBtnLoader: true });

    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    let dataObj = {
      orderid: orderidVal,
      orderdetailsid: orderdetailsidVal,
      status: "returnpicked",
    };

    this.props.updateorder(this.props.session, dataObj, (callBack) => {
      if (
        callBack.status === true ||
        callBack.status === 1 ||
        callBack.status
      ) {
        this.setState({ confirmPickupBtnLoader: false });

        NotificationManager.success("Pickup confirmed successfully");

        // Refresh Order List
        // this.props.fetchallreturnorderList(this.props.session,"return",limit,1,"listing","",(CallBackResponse) => {});
        if (this.props.newKey == "return") {
          this.props.fetchallreturnorderList(
            this.props.session,
            "return",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "replace") {
          this.props.fetchallreplaceorderlist(
            this.props.session,
            "replace",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "cancelled") {
          this.props.fetchallcancelledorderlist(
            this.props.session,
            "cancelled",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "new") {
          this.props.fetchallnewoderlist(
            this.props.session,
            "new",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        }

        // Close Modal
        this.props.onClose();
      } else {
        this.setState({ confirmPickupBtnLoader: false });
      }
    });
  }

  // Call a fc which will close or open light box
  openImagesInLightBox(index) {
    this.props.setPhotoIndex(index);
    this.props.setIsLightBoxOpen();
  }

  /*** Render Status Media ***/
  renderStatusMedia = (status_media) => {
    let { firstTimeOpeing } = this.state;

    let feedbackImageArr = [];
    var item = _.map(status_media, (media, index) => {
      var imageUrl = `${BASE_IMAGES_URL}/orders/${media.medianame}?api_key=${APP_API_KEY}`;
      feedbackImageArr.push(imageUrl); // Pushing
      return (
        <div className=" mr-1">
          <img
            loading="lazy"
            src={imageUrl}
            className="border-2 border-quaternary h-10 w-10 cursor-pointer"
            alt="image"
            onClick={(e) => this.openImagesInLightBox(index)}
          />
        </div>
      );
    });

    if (_.size(status_media) !== 0 && firstTimeOpeing) {
      if (this.props.setImagesForLightBOx) {
        this.props.setImagesForLightBOx(feedbackImageArr);
      }

      this.setState({ firstTimeOpeing: false });
    }

    return item;
  };

  /* Open or create Order thread */
  _createOrOpenOrderThread = (data) => {
    let { session } = this.props;
    let orderId = data.orderid;
    let buyerId = data.purchasedby.userid;
    let sellerid = data.product.seller.userid;

    this.props.createOrOpenOrderThread(
      session,
      orderId,
      buyerId,
      sellerid,
      (callBack) => {
        if (callBack.status === 1) {
          // Callback success
          if (callBack.threadavailable) {
            // We need thread details before opeing the chat list
            this.props.getOrderThreadWithId(
              session,
              callBack.message.id,
              (callback3) => {
                if (callback3.status === 1) {
                  // console.log('callback3',callback3);
                  let thread = callback3.message.thread;
                  // Fetch the chat list with thread id
                  this.props.fetchOrderThreadMsgList(
                    session,
                    parseInt(thread?.id),
                    (res) => {
                      // Thread is already created
                      // Close Modal
                      this.props.onClose();
                      // Now Open the message modal
                      this.props.isSendMessageModalOpen(true);
                      // this.props.changeMessageTabName("order"); // Chnage the tab to product
                    }
                  );
                  this.props.selleridStore(sellerid);
                  // Update basic status
                  this.props.updateBasicOrderthreadData({
                    threadid: parseInt(thread?.id),
                    sku: thread?.orderdata[0]?.sku,
                    firstuserid: thread?.firstuserid,
                    seconduserid: thread?.seconduserid,
                    invoice_no: thread?.orderdata[0]?.invoice_no,
                    productname_en: thread?.orderdata[0]?.productname_en,
                    productname_ar: thread?.orderdata[0]?.productname_ar,
                  });
                  this.props.updateOrderStep(2);
                } else {
                  NotificationManager.error(callback3.message);
                }
              }
            );
          } else {
            // Here thread is not there so we created the tread and back with thread id
            // We need thread details before opeing the chat list
            this.props.getOrderThreadWithId(
              session,
              callBack.message.id,
              (callback2) => {
                if (callback2.status === 1) {
                  let thread = callback2.message.thread;
                  // Fetch the chat list with thread id
                  this.props.fetchOrderThreadMsgList(
                    session,
                    parseInt(callBack.message.id),
                    (res) => {}
                  );
                  this.props.selleridStore(sellerid);
                  // Update basic status
                  this.props.updateBasicOrderthreadData({
                    threadid: parseInt(thread?.id),
                    sku: thread?.orderdata[0]?.sku,
                    firstuserid: thread?.firstuserid,
                    seconduserid: thread?.seconduserid,
                    invoice_no: thread?.orderdata[0]?.invoice_no,
                    productname_en: thread?.orderdata[0]?.productname_en,
                    productname_ar: thread?.orderdata[0]?.productname_ar,
                  });
                  this.props.updateOrderStep(2);
                } else {
                  NotificationManager.error(callback2.message);
                }
              }
            );

            // Close Modal
            this.props.onClose();
            // Now Open the message modal
            this.props.isSendMessageModalOpen(true);
            // this.props.changeMessageTabName("order"); // Chnage the tab to product
          }
        } else {
          NotificationManager.error(callBack.message);
        }
      }
    );
  };

  render() {
    var { data, localCurrency } = this.props;
    // console.log('detailingseller', data);
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    let returnOrreplace = "";
    var img = "";
    let productname = "";
    let date = "";
    let quantity = 0;
    let grand_total = 0.0;
    let orderCurrency = data?.product?.odcurrency;

    if (!_.isEmpty(data)) {
      let initiatepickupPosition = _.findIndex(
        data.product.statuses,
        function (o) {
          return o.status == "initiatepickup";
        }
      );

      if (initiatepickupPosition > -1) {
        returnOrreplace =
          data.product.statuses[initiatepickupPosition + 1].status;
      }
      var img = `${BASE_IMAGES_URL}/vendor/variations/${data.product.productimage}?api_key=${APP_API_KEY}`;
      productname = data.product.productname_en;
      // language
      quantity = data.product.quantity;
      //  date  = moment(data.product.currentstatus.updatedat).format("D MMM YYYY HH:mm A");
      date = moment(data.product.currentstatus.updatedat).format("D MMM YYYY");

      grand_total = parseFloat(data.summary.grand_total);
    }
    let cancelstatus = "";

    let filtercancelstatus = _.filter(data.product.statuses, {
      status: "cancelled",
    });
    let filterreturnRejectedStatus = _.filter(data.product.statuses, {
      status: "refundrejected",
    });
    // console.log('filtercancelstatus',filtercancelstatus)

    let mailToLink = "";
    let vendorEmailId = data?.purchasedby?.email;

    if (!_.isEmpty(data)) {
      mailToLink = `mailto:${data?.purchasedby?.email}?subject=Shopez - ${LocaleStrings.order_id} : ${data?.product?.invoice_no}`;
    }

    let { copedLoader } = this.state;
    grand_total = data?.summary?.grand_total;
    grand_total = parseFloat(grand_total).toFixed(2);

    let product_total = data?.summary?.product_total;

    let discount = data?.summary?.discount;

    let shipping = data?.summary?.shipping;

    let tax = data?.summary?.tax;

    let process_orderby = data?.product?.order_processedby;
    let deliverycountry = _.startCase(data?.delivery_address?.country);
    let vendorcountry = _.startCase(data?.product?.seller.country);

    let qatarcountry = false;
    if (deliverycountry == "Qatar" && vendorcountry == "Qatar") {
      qatarcountry = true;
    }

    // console.log(
    //   "data.product.currentstatus.status",
    //   data.product.currentstatus.status
    // );
    // console.log("qatarcountry", qatarcountry);
    // console.log("process_orderby", process_orderby);
    // console.log("returnOrreplace", returnOrreplace);

    return (
      <>
        {/* <div className="transitionmodal"> */}
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: "0" }}>
                  <div className="grid grid-cols-3 border-b-2 border-gray-200 pb-4 sticky-header z-50">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center mt-4">
                      {LocaleStrings.oderdetails}
                    </div>
                    <div className=" sm:block absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="pt-6 px-6">
                    <div className="grid grid-cols-2 mt-5 pb-3 border-b-2 border-gray-50">
                      <div>
                        {LocaleStrings.oderid}: {data.product.invoice_no}
                      </div>
                      <div className="end-item text-xs text-ternary">
                        {date}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mt-5 pb-3 border-b-2 border-gray-50">
                      <div>
                        <img src={img} />
                      </div>
                      <div className="text-xs text-ternary pl-5">
                        <p className="mt-5">{productname}</p>
                        <p className="mt-5">
                          {LocaleStrings.odererby}:{" "}
                          {data.delivery_address.username}
                        </p>
                        <p className="mt-5">
                          {LocaleStrings.dateofoder}: {date}
                        </p>
                        <p className="mt-5">
                          {LocaleStrings.quantity}: {quantity}
                        </p>
                        <p className="mt-5">
                          {LocaleStrings.odertotal}: {currency} {grand_total}
                        </p>
                      </div>
                      <input
                        type="hidden"
                        name="orderdetailsid"
                        id="orderdetailsid"
                        autoComplete="given-name"
                        className="cust-input-field"
                        value={data.product.orderdetailsid}
                      />
                      <input
                        type="hidden"
                        name="orderid"
                        id="orderid"
                        autoComplete="given-name"
                        className="cust-input-field"
                        value={data.orderid}
                      />
                    </div>
                    {/* commented on 10 june 2024 as per discussion with sanowar sir*/}
                    {/* {(data.product.currentstatus.status == "placed" ||
                      (data.product.currentstatus.status === "returnreceived" &&
                        returnOrreplace === "replace")) && //latest code
                    this.props.newKey != "ordernotprocessed" &&
                    process_orderby == "sa" ? ( */}
                    {data?.product?.currentstatus?.status === "placed" &&
                    this.props.newKey != "ordernotprocessed" &&
                    process_orderby == "sa" ? (
                      <>
                        <div className="grid grid-cols-2 mt-2">
                          <div>Update Order Status</div>
                          <div className="end-item ">
                            {this.state.updateordStateBox ? (
                              <ArrowCircleUpIcon
                                className="h-6 w-6 cursor-pointer mt-1"
                                aria-hidden="true"
                                onClick={this.updateordStateBoxToggle}
                              />
                            ) : (
                              <ArrowCircleDownIcon
                                className="h-6 w-6 cursor-pointer mt-1"
                                aria-hidden="true"
                                onClick={this.updateordStateBoxToggle}
                              />
                            )}
                          </div>
                        </div>
                        {this.state.updateordStateBox ? (
                          <div className="grid grid-cols-2">
                            <div className="col-span-2 mt-2">
                              {data.product.currentstatus.status ===
                              "returnreceived" ? (
                                <p className="my-2 text-sm text-secondary">
                                  Re-ship item
                                </p>
                              ) : (
                                ""
                              )}
                              <p className="my-2 text-sm text-ternary">
                                Product Shipping (Add tracking number before
                                updating)
                              </p>
                              <input
                                type="text"
                                name="tracking_number"
                                id="tracking_number"
                                placeholder="Tracking No"
                                autoComplete="given-name"
                                className="cust-input-field"
                              />

                              <button
                                // className="btn-primary mt-2"
                                onClick={this._handelMarkAsShipted}
                                disabled={this.state.markAsShiptedBtnLoader}
                                className={classNames(
                                  this.state.markAsShiptedBtnLoader
                                    ? "cursor-not-allowed"
                                    : "",
                                  "btn-primary mt-2"
                                )}>
                                <svg
                                  className={classNames(
                                    this.state.markAsShiptedBtnLoader
                                      ? ""
                                      : "hidden",
                                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                  )}
                                  disabled={this.state.markAsShiptedBtnLoader}
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="loading"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true">
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                                Mark as Shipped
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                      // <ShippingStatus data={data.product.statuses} />
                    )}
                    <ShippingStatus data={data.product.statuses} />

                    {/* REVIEW & RETINGS as feedback if deliverded only then show */}
                    {data.product.currentstatus.status === "delivered" ? (
                      <ReviewAndRatingLimited
                        productDetails={data.product}
                        wholeObject={data}
                        limit={1}
                        callFrom={"order"}
                      />
                    ) : (
                      ""
                    )}

                    <AllReasonComponent
                      data={data}
                      renderStatusMedia={this.renderStatusMedia}
                    />
                    <div className="mt-5 pb-3 border-b-2 border-gray-50">
                      Delivery To
                      <div className="grid lg:grid-cols-3 grid-cols-2 mt-5">
                        <div>
                          <img
                            src="../images/product_management_images/delivery.svg "
                            className="h-24 w-24"
                          />
                        </div>
                        <div className="">
                          <p>
                            {_.startCase(data.delivery_address.addresstype)}:{" "}
                            {data.delivery_address.username}
                          </p>
                          <p className="text-xs text-ternary mt-2">
                            {data.delivery_address.address}
                          </p>
                          <p className="text-xs text-ternary mt-2">
                            {LocaleStrings.contactnumber}:{" "}
                            {data.delivery_address.phone}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="center-item"><button className="button  mt-5">{LocaleStrings.emailbuyer}</button></div> */}
                    <div className="grid lg:grid-cols-3 grid-cols-3 gap-1 mt-5">
                      {/* EMAIL A BUYER */}
                      <div className=" ">
                        <a
                          className="btn-primary"
                          href={mailToLink}
                          target="_top">
                          {LocaleStrings.emailbuyer}
                        </a>
                      </div>
                      {/* Message User */}
                      <div className=" ">
                        <a
                          className="btn-primary cursor-pointer"
                          onClick={() => this._createOrOpenOrderThread(data)}
                          target="_top">
                          {LocaleStrings.message_buyer}
                        </a>
                      </div>
                      {/* Copy Email */}
                      <div
                        className={classNames(
                          vendorEmailId ? "" : "hidden",
                          ""
                        )}>
                        <span
                          className="btn-primary cursor-pointer"
                          onClick={(e) =>
                            this.copyEmailToClipboard(e, vendorEmailId)
                          }
                          target="_top">
                          {copedLoader
                            ? LocaleStrings.copied
                            : LocaleStrings.copy_email}
                          <ClipboardCopyIcon className="w-4 mx-1 icon-hover-transition" />
                        </span>
                      </div>
                    </div>
                    {data.hasOwnProperty("relatedorder") ? (
                      <>
                        {data?.summary?.actionkey &&
                        (data?.summary?.amount_paid > 0 ||
                          data?.summary?.refund_amount > 0) ? (
                          <>
                            {/* new  process  for refund*/}
                            <div className="mt-5 pb-3 border-b-2 border-gray-50">
                              {" "}
                              {LocaleStrings.price_details}
                              <div className="grid grid-cols-2 mt-5 text-ternary">
                                <div>{LocaleStrings.original_product}</div>
                                <div className="end-item ">
                                  {currency}
                                  {data.summary.previous_order_price}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.exchanged_product}</div>
                                <div className="end-item ">
                                  {currency}
                                  {data.summary.current_order_price}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.discount}</div>
                                <div className="end-item ">
                                  -{currency}
                                  {getIntToFloat(discount)}
                                </div>
                              </div>
                              {data.summary.coupon_discount ? (
                                <div className="grid grid-cols-2 mt-1 text-ternary">
                                  <div>{LocaleStrings.coupon_discount}</div>
                                  <div className="end-item ">
                                    -{currency}
                                    {getIntToFloat(
                                      data.summary.coupon_discount
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.shipping_fee}</div>
                                <div className="end-item ">
                                  {currency}
                                  {getIntToFloat(shipping)}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.tax_charges}</div>
                                <div className="end-item ">
                                  {currency}
                                  {getIntToFloat(tax)}
                                </div>
                              </div>
                              {/* Divider */}
                              <div className="relative mt-2">
                                <div
                                  className="absolute inset-0 flex items-center"
                                  aria-hidden="true">
                                  <div className="w-full border-t border-quternary" />
                                </div>
                              </div>
                              {/* Total */}
                              {data?.summary?.actionkey &&
                              (data?.summary?.amount_paid > 0 ||
                                data?.summary?.refund_amount > 0) ? (
                                data?.summary?.amount_paid > 0 ? (
                                  <div className="grid grid-cols-2 mt-5">
                                    <div>{LocaleStrings.paid_amount}</div>
                                    <div className="end-item ">
                                      {currency}{" "}
                                      {getIntToFloat(
                                        data?.summary?.amount_paid
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="grid grid-cols-2 mt-5">
                                    <div>{LocaleStrings.refund_Amount}</div>
                                    <div className="end-item ">
                                      {currency}{" "}
                                      {getIntToFloat(
                                        data?.summary?.refund_amount
                                      )}
                                    </div>
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                              {/* Payment Method */}
                              <div className="grid grid-cols-2 my-3">
                                <div>{LocaleStrings.payment_method}</div>
                                <div className="end-item ">
                                  {getBilangualeText(data.payment_method)}
                                </div>
                              </div>
                              {/* Divider */}
                              {/* Cancel Reason for exhange */}
                              {data?.relatedorder &&
                              data?.relatedorder?.currentstatus == "return" ? (
                                <>
                                  {data.product.currentstatus.status ===
                                    "placed" && process_orderby == "sa" ? (
                                    <>
                                      <div className="relative mt-2">
                                        <div
                                          className="absolute inset-0 flex items-center"
                                          aria-hidden="true">
                                          <div className="w-full border-t border-quternary" />
                                        </div>
                                      </div>
                                      <div className="grid grid-cols-2">
                                        <div className="col-span-2 mt-2">
                                          <p className="my-2 text-sm text-secondary">
                                            {LocaleStrings.cancel_reason}:
                                          </p>
                                          <p className="my-2 text-sm text-ternary">
                                            {LocaleStrings.cancel_reason_desc}
                                          </p>
                                          <textarea
                                            id="cancel_reason_inputbox"
                                            name="cancel_reason_inputbox"
                                            placeholder={
                                              LocaleStrings.enter_reason
                                            }
                                            rows={3}
                                            className="cust-input-field"
                                            defaultValue={""}
                                          />
                                        </div>
                                      </div>
                                      <button
                                        onClick={this._handelCancelOrder}
                                        disabled={
                                          this.state.cancelOrderBtnLoader
                                        }
                                        className={classNames(
                                          this.state.cancelOrderBtnLoader
                                            ? "cursor-not-allowed"
                                            : "",
                                          "btn-primary mt-2"
                                        )}>
                                        <svg
                                          className={classNames(
                                            this.state.cancelOrderBtnLoader
                                              ? ""
                                              : "hidden",
                                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                          )}
                                          disabled={
                                            this.state.cancelOrderBtnLoader
                                          }
                                          viewBox="0 0 1024 1024"
                                          focusable="false"
                                          data-icon="loading"
                                          width="1em"
                                          height="1em"
                                          fill="currentColor"
                                          aria-hidden="true">
                                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                        </svg>
                                        {LocaleStrings.cancel_order}
                                      </button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {data.product.currentstatus.status ===
                                "dispatched" &&
                              process_orderby == "sa" &&
                              !qatarcountry ? (
                                <button
                                  disabled={this.state.inTransitBtnLoader}
                                  className={classNames(
                                    this.state.inTransitBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelInTransit}>
                                  <svg
                                    className={classNames(
                                      this.state.inTransitBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.inTransitBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.in_transit}
                                </button>
                              ) : (
                                ""
                              )}
                              {(data.product.currentstatus.status ===
                                "intransit" ||
                                data.product.currentstatus.status ===
                                  "reship") &&
                              process_orderby == "sa" &&
                              !qatarcountry ? (
                                // <>
                                <button
                                  disabled={this.state.markAsDeliveredBtnLoader}
                                  className={classNames(
                                    this.state.markAsDeliveredBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-2"
                                  )}
                                  onClick={this._handelMarkDelivered}>
                                  <svg
                                    className={classNames(
                                      this.state.markAsDeliveredBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={
                                      this.state.markAsDeliveredBtnLoader
                                    }
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.mark_delivered}
                                </button>
                              ) : (
                                ""
                              )}
                              {(data.product.currentstatus.status ===
                                "replace" ||
                                data.product.currentstatus.status ===
                                  "return") &&
                              process_orderby == "sa" &&
                              !qatarcountry ? (
                                <button
                                  disabled={this.state.initiatPickUpBtnLoader}
                                  className={classNames(
                                    this.state.initiatPickUpBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelInitiatePickup}>
                                  <svg
                                    className={classNames(
                                      this.state.initiatPickUpBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.initiatPickUpBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.initiate_pickup}
                                </button>
                              ) : data.product.currentstatus.status ===
                                  "initiatepickup" &&
                                process_orderby == "sa" ? (
                                <button
                                  disabled={this.state.confirmPickupBtnLoader}
                                  className={classNames(
                                    this.state.confirmPickupBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-2"
                                  )}
                                  onClick={this._handelConfirmPicup}>
                                  <svg
                                    className={classNames(
                                      this.state.confirmPickupBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.confirmPickupBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.confirm_pickeup}
                                </button>
                              ) : data.product.currentstatus.status ===
                                  "returnpicked" && process_orderby == "sa" ? (
                                <button
                                  disabled={this.state.markReceivedBtnLoader}
                                  className={classNames(
                                    this.state.markReceivedBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelReceived}>
                                  <svg
                                    className={classNames(
                                      this.state.markReceivedBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.markReceivedBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.mark_received}
                                </button>
                              ) : data.summary?.actionkey &&
                                data.summary?.actionkey === "refund" &&
                                data.summary.refund_amount > 0 &&
                                process_orderby == "sa" ? (
                                <button
                                  disabled={this.state.initiateRefundBtnLoader}
                                  style={{ display: "none" }} // added on 14/3/2024 as initiate refund is automated
                                  className={classNames(
                                    this.state.initiateRefundBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-5"
                                  )}
                                  onClick={this._handelInitiateRefund}>
                                  <svg
                                    className={classNames(
                                      this.state.initiateRefundBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.initiatPickUpBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.initiate_refund}
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {/* third process */}
                            <div className="mt-5 pb-3 border-b-2 border-gray-50">
                              {" "}
                              {LocaleStrings.price_details}
                              <div className="grid grid-cols-2 mt-5 text-ternary">
                                <div>{LocaleStrings.product_price}</div>
                                <div className="end-item ">
                                  {currency}
                                  {getIntToFloat(product_total)}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.discount}</div>
                                <div className="end-item ">
                                  -{currency}
                                  {getIntToFloat(discount)}
                                </div>
                              </div>
                              {data?.summary?.coupon_discount ? (
                                <div className="grid grid-cols-2 mt-1 text-ternary">
                                  <div>{LocaleStrings.coupon_discount}</div>
                                  <div className="end-item ">
                                    -{currency}
                                    {getIntToFloat(
                                      data?.summary?.coupon_discount
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.shipping_fee}</div>
                                <div className="end-item ">
                                  {currency}
                                  {getIntToFloat(shipping)}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.tax_charges}</div>
                                <div className="end-item ">
                                  {currency}
                                  {getIntToFloat(tax)}
                                </div>
                              </div>
                              {/* Divider */}
                              <div className="relative mt-2">
                                <div
                                  className="absolute inset-0 flex items-center"
                                  aria-hidden="true">
                                  <div className="w-full border-t border-quternary" />
                                </div>
                              </div>
                              {/* Total */}
                              <div className="grid grid-cols-2 mt-5">
                                <div>{LocaleStrings.total}</div>
                                <div className="end-item ">
                                  {currency} {getIntToFloat(grand_total)}
                                </div>
                              </div>
                              {/* Payment Method */}
                              <div className="grid grid-cols-2 my-3">
                                <div>{LocaleStrings.payment_method}</div>
                                <div className="end-item ">
                                  {getBilangualeText(data.payment_method)}
                                </div>
                              </div>
                              {data?.relatedorder &&
                              data?.relatedorder?.currentstatus == "return" ? (
                                <>
                                  {data.product.currentstatus.status ===
                                    "placed" && process_orderby == "sa" ? (
                                    <>
                                      <div className="relative mt-2">
                                        <div
                                          className="absolute inset-0 flex items-center"
                                          aria-hidden="true">
                                          <div className="w-full border-t border-quternary" />
                                        </div>
                                      </div>
                                      <div className="grid grid-cols-2">
                                        <div className="col-span-2 mt-2">
                                          <p className="my-2 text-sm text-secondary">
                                            {LocaleStrings.cancel_reason}:
                                          </p>
                                          <p className="my-2 text-sm text-ternary">
                                            {LocaleStrings.cancel_reason_desc}
                                          </p>
                                          <textarea
                                            id="cancel_reason_inputbox"
                                            name="cancel_reason_inputbox"
                                            placeholder={
                                              LocaleStrings.enter_reason
                                            }
                                            rows={3}
                                            className="cust-input-field"
                                            defaultValue={""}
                                          />
                                        </div>
                                      </div>
                                      <button
                                        onClick={this._handelCancelOrder}
                                        disabled={
                                          this.state.cancelOrderBtnLoader
                                        }
                                        className={classNames(
                                          this.state.cancelOrderBtnLoader
                                            ? "cursor-not-allowed"
                                            : "",
                                          "btn-primary mt-2"
                                        )}>
                                        <svg
                                          className={classNames(
                                            this.state.cancelOrderBtnLoader
                                              ? ""
                                              : "hidden",
                                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                          )}
                                          disabled={
                                            this.state.cancelOrderBtnLoader
                                          }
                                          viewBox="0 0 1024 1024"
                                          focusable="false"
                                          data-icon="loading"
                                          width="1em"
                                          height="1em"
                                          fill="currentColor"
                                          aria-hidden="true">
                                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                        </svg>
                                        {LocaleStrings.cancel_order}
                                      </button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {data.product.currentstatus.status ===
                                "dispatched" &&
                              process_orderby == "sa" &&
                              !qatarcountry ? (
                                <button
                                  disabled={this.state.inTransitBtnLoader}
                                  className={classNames(
                                    this.state.inTransitBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelInTransit}>
                                  <svg
                                    className={classNames(
                                      this.state.inTransitBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.inTransitBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.in_transit}
                                </button>
                              ) : (
                                ""
                              )}
                              {(data.product.currentstatus.status ===
                                "intransit" ||
                                data.product.currentstatus.status ===
                                  "reship") &&
                              process_orderby == "sa" &&
                              !qatarcountry ? (
                                // <>
                                <button
                                  disabled={this.state.markAsDeliveredBtnLoader}
                                  className={classNames(
                                    this.state.markAsDeliveredBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-2"
                                  )}
                                  onClick={this._handelMarkDelivered}>
                                  <svg
                                    className={classNames(
                                      this.state.markAsDeliveredBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={
                                      this.state.markAsDeliveredBtnLoader
                                    }
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.mark_delivered}
                                </button>
                              ) : (
                                ""
                              )}
                              {(data.product.currentstatus.status ===
                                "replace" ||
                                data.product.currentstatus.status ===
                                  "return") &&
                              process_orderby == "sa" &&
                              !qatarcountry ? (
                                <button
                                  disabled={this.state.initiatPickUpBtnLoader}
                                  className={classNames(
                                    this.state.initiatPickUpBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelInitiatePickup}>
                                  <svg
                                    className={classNames(
                                      this.state.initiatPickUpBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.initiatPickUpBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.initiate_pickup}
                                </button>
                              ) : data.product.currentstatus.status ===
                                  "initiatepickup" &&
                                process_orderby == "sa" ? (
                                <button
                                  disabled={this.state.confirmPickupBtnLoader}
                                  className={classNames(
                                    this.state.confirmPickupBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-2"
                                  )}
                                  onClick={this._handelConfirmPicup}>
                                  <svg
                                    className={classNames(
                                      this.state.confirmPickupBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.confirmPickupBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.confirm_pickeup}
                                </button>
                              ) : data.product.currentstatus.status ===
                                  "returnpicked" && process_orderby == "sa" ? (
                                <button
                                  disabled={this.state.markReceivedBtnLoader}
                                  className={classNames(
                                    this.state.markReceivedBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelReceived}>
                                  <svg
                                    className={classNames(
                                      this.state.markReceivedBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.markReceivedBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.mark_received}
                                </button>
                              ) : data.summary?.actionkey &&
                                data.summary?.actionkey === "refund" &&
                                data.summary.refund_amount > 0 &&
                                process_orderby == "sa" ? (
                                <button
                                  disabled={this.state.initiateRefundBtnLoader}
                                  style={{ display: "none" }} // added on 14/3/2024 as initiate refund is automated
                                  className={classNames(
                                    this.state.initiateRefundBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-5"
                                  )}
                                  onClick={this._handelInitiateRefund}>
                                  <svg
                                    className={classNames(
                                      this.state.initiateRefundBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.initiatPickUpBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.initiate_refund}
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/* old process */}
                        <div className="mt-5 pb-3 border-b-2 border-gray-50">
                          {" "}
                          {LocaleStrings.price_details}
                          <div className="grid grid-cols-2 mt-5 text-ternary">
                            <div>{LocaleStrings.product_price}</div>
                            <div className="end-item ">
                              {currency}
                              {getIntToFloat(product_total)}
                            </div>
                          </div>
                          <div className="grid grid-cols-2 mt-1 text-ternary">
                            <div>{LocaleStrings.discount}</div>
                            <div className="end-item ">
                              -{currency}
                              {getIntToFloat(discount)}
                            </div>
                          </div>
                          {data.summary.coupon_discount ? (
                            <div className="grid grid-cols-2 mt-1 text-ternary">
                              <div>{LocaleStrings.coupon_discount}</div>
                              <div className="end-item ">
                                -{currency}
                                {getIntToFloat(data.summary.coupon_discount)}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="grid grid-cols-2 mt-1 text-ternary">
                            <div>{LocaleStrings.shipping_fee}</div>
                            <div className="end-item ">
                              {currency}
                              {getIntToFloat(shipping)}
                            </div>
                          </div>
                          <div className="grid grid-cols-2 mt-1 text-ternary">
                            <div>{LocaleStrings.tax_charges}</div>
                            <div className="end-item ">
                              {currency}
                              {getIntToFloat(tax)}
                            </div>
                          </div>
                          {/* Divider */}
                          <div className="relative mt-2">
                            <div
                              className="absolute inset-0 flex items-center"
                              aria-hidden="true">
                              <div className="w-full border-t border-quternary" />
                            </div>
                          </div>
                          {/* Total */}
                          <div className="grid grid-cols-2 mt-5">
                            <div>{LocaleStrings.total}</div>
                            <div className="end-item ">
                              {currency} {getIntToFloat(grand_total)}
                            </div>
                          </div>
                          {/* Payment Method */}
                          <div className="grid grid-cols-2 mt-3">
                            <div>{LocaleStrings.payment_method}</div>
                            <div className="end-item ">
                              {getBilangualeText(data.payment_method)}
                            </div>
                          </div>
                          {/* Divider */}
                          {/* Cancel Reason [Note : cancel only before it is dispatched]*/}
                          {data.product.currentstatus.status === "placed" &&
                          process_orderby == "sa" ? (
                            <>
                              <div className="relative mt-2">
                                <div
                                  className="absolute inset-0 flex items-center"
                                  aria-hidden="true">
                                  <div className="w-full border-t border-quternary" />
                                </div>
                              </div>
                              <div className="grid grid-cols-2">
                                <div className="col-span-2 mt-2">
                                  <p className="my-2 text-sm text-secondary">
                                    {LocaleStrings.cancel_reason}:
                                  </p>
                                  <p className="my-2 text-sm text-ternary">
                                    {LocaleStrings.cancel_reason_desc}
                                  </p>
                                  <textarea
                                    id="cancel_reason_inputbox"
                                    name="cancel_reason_inputbox"
                                    placeholder={LocaleStrings.enter_reason}
                                    rows={3}
                                    className="cust-input-field"
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        {data.product.currentstatus.status === "placed" &&
                        process_orderby == "sa" ? (
                          <button
                            onClick={this._handelCancelOrder}
                            disabled={this.state.cancelOrderBtnLoader}
                            className={classNames(
                              this.state.cancelOrderBtnLoader
                                ? "cursor-not-allowed"
                                : "",
                              "btn-primary mt-2"
                            )}>
                            <svg
                              className={classNames(
                                this.state.cancelOrderBtnLoader ? "" : "hidden",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              disabled={this.state.cancelOrderBtnLoader}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.cancel_order}
                          </button>
                        ) : data.product.currentstatus.status === "cancelled" &&
                          // || //latest updated on 27.02.2024
                          // (data.product.currentstatus.status ===
                          //   "returnreceived" &&
                          //   returnOrreplace === "return")
                          process_orderby == "sa" ? (
                          // && !data.hasOwnProperty('linkedorder') //latest code
                          <button
                            disabled={this.state.initiateRefundBtnLoader}
                            className={classNames(
                              this.state.initiateRefundBtnLoader
                                ? "cursor-not-allowed"
                                : "",
                              "btn-primary"
                            )}
                            onClick={this._handelInitiateRefund}>
                            <svg
                              className={classNames(
                                this.state.initiateRefundBtnLoader
                                  ? ""
                                  : "hidden",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              disabled={this.state.initiatPickUpBtnLoader}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.initiate_refund}
                          </button>
                        ) : (data.product.currentstatus.status === "replace" ||
                            data.product.currentstatus.status === "return") &&
                          process_orderby == "sa" &&
                          !qatarcountry ? (
                          <button
                            disabled={this.state.initiatPickUpBtnLoader}
                            className={classNames(
                              this.state.initiatPickUpBtnLoader
                                ? "cursor-not-allowed"
                                : "",
                              "btn-primary"
                            )}
                            onClick={this._handelInitiatePickup}>
                            <svg
                              className={classNames(
                                this.state.initiatPickUpBtnLoader
                                  ? ""
                                  : "hidden",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              disabled={this.state.initiatPickUpBtnLoader}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.initiate_pickup}
                          </button>
                        ) : data.product.currentstatus.status ===
                            "dispatched" &&
                          process_orderby == "sa" &&
                          !qatarcountry ? (
                          <>
                            <button
                              disabled={this.state.inTransitBtnLoader}
                              className={classNames(
                                this.state.inTransitBtnLoader
                                  ? "cursor-not-allowed"
                                  : "",
                                "btn-primary"
                              )}
                              onClick={this._handelInTransit}>
                              <svg
                                className={classNames(
                                  this.state.inTransitBtnLoader ? "" : "hidden",
                                  "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                )}
                                disabled={this.state.inTransitBtnLoader}
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="loading"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true">
                                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                              </svg>
                              {LocaleStrings.in_transit}
                            </button>
                          </>
                        ) : data.product.currentstatus.status ===
                            "returnpicked" && process_orderby == "sa" ? (
                          /*
                                    The button will show Initiate Pickup  after  to the Vendor when currentstatus = return
                                    When user taps on Initiate Pickup button the status will become initiatepickup
                                    and The button will show Confirm Pickup tapping on which the status will become returnpicked
                                    The button becomes Mark Received tapping on it the status will become returnreceived
                                    Now the Button will change to Initiate Refund, tapping on button it will change status to refund
                                    */
                          <button
                            disabled={this.state.markReceivedBtnLoader}
                            className={classNames(
                              this.state.markReceivedBtnLoader
                                ? "cursor-not-allowed"
                                : "",
                              "btn-primary"
                            )}
                            onClick={this._handelReceived}>
                            <svg
                              className={classNames(
                                this.state.markReceivedBtnLoader
                                  ? ""
                                  : "hidden",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              disabled={this.state.markReceivedBtnLoader}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.mark_received}
                          </button>
                        ) : (data.product.currentstatus.status ===
                            "intransit" ||
                            data.product.currentstatus.status === "reship") &&
                          process_orderby == "sa" &&
                          !qatarcountry ? (
                          <>
                            <button
                              disabled={this.state.markAsDeliveredBtnLoader}
                              className={classNames(
                                this.state.markAsDeliveredBtnLoader
                                  ? "cursor-not-allowed"
                                  : "",
                                "btn-primary mt-2"
                              )}
                              onClick={this._handelMarkDelivered}>
                              <svg
                                className={classNames(
                                  this.state.markAsDeliveredBtnLoader
                                    ? ""
                                    : "hidden",
                                  "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                )}
                                disabled={this.state.markAsDeliveredBtnLoader}
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="loading"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true">
                                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                              </svg>
                              {LocaleStrings.mark_delivered}
                            </button>
                          </>
                        ) : data.product.currentstatus.status ===
                            "initiatepickup" && process_orderby == "sa" ? (
                          <button
                            disabled={this.state.confirmPickupBtnLoader}
                            className={classNames(
                              this.state.confirmPickupBtnLoader
                                ? "cursor-not-allowed"
                                : "",
                              "btn-primary mt-2"
                            )}
                            onClick={this._handelConfirmPicup}>
                            <svg
                              className={classNames(
                                this.state.confirmPickupBtnLoader
                                  ? ""
                                  : "hidden",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              disabled={this.state.confirmPickupBtnLoader}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.confirm_pickeup}
                          </button>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>

                  {/* Common button for downalod invoice button */}
                  <div className="px-6">
                    <button
                      onClick={this._handelDownloadInvoice}
                      disabled={this.state.downloadInvoiceBtnLoader}
                      type="button"
                      className={classNames(
                        this.state.downloadInvoiceBtnLoader
                          ? "cursor-not-allowed"
                          : "",
                        "btn-primary mt-2"
                      )}>
                      <svg
                        className={classNames(
                          this.state.downloadInvoiceBtnLoader ? "" : "hidden",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        disabled={this.state.downloadInvoiceBtnLoader}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.download_invoice}
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* </div> */}
        <Message selectedThreadId={this.state.selectedThreadId} />
        <NotificationContainer />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('session',state.wishlistlist);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    data: ownProps.data,
    localCurrency: state.currency,
  };
}

export default connect(mapStateToProps, {
  updateorder,
  fetchallnewoderlist,
  fetchallcancelledorderlist,
  fetchallreturnorderList,
  fetchallreplaceorderlist,
  // message
  createOrOpenOrderThread,
  fetchOrderThreadMsgList,
  getOrderThreadWithId,
  isSendMessageModalOpen,
  updateBasicOrderthreadData,
  updateOrderStep,
  selleridStore,
  isCurrencyRateApplicable,
  updateinitiateRefund,
  partnerScriptorder,
  downloadorderInvoice,
})(
  reduxForm({
    // validate,
    form: "AllneworderdetailsModalsForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AllneworderdetailsModals)
);
