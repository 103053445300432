import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { ZoomInIcon, ZoomOutIcon, XIcon } from "@heroicons/react/outline";
import LocaleStrings from "../../../languages/index";

export default function ImageCropper(props) {
  var { displaySize, cropperSize, requiredSize, className, displayText } =
    props;
  var [canvasRecource, setCanvasRecource] = useState(props.canvasRecource);
  var [imageUrl, setImagepath] = useState(props.imagepath);
  var [scale, setScale] = useState(1.2);
  var [imageErrorMsg, setImageErrorMsg] = useState("");

  var scaleStep = 0.05;
  var defaultScale = 1.2;
  var minScale = 1;
  var maxScale = 2;
  var editor = "";

  const setEditorRef = (edit) => (editor = edit);

  const onClickSave = () => {
    saveImage(null);
  };

  const saveImage = (callback) => {
    if (editor) {
      var imageType = props.imageType;
      if (!imageType) imageType = "jpg";

      var img;
      if (imageType === "png") img = editor.getImage().toDataURL();
      else img = editor.getImage().toDataURL("image/jpeg", 0.9);
      setImagepath(img);
      props.onImageSave(img);
      if (callback) {
        setTimeout(() => {
          callback();
        }, 2000);
      }
    }
  };

  const resetImage = () => {
    setCanvasRecource("");
    setImagepath("");
    setScale(defaultScale);
    setImageErrorMsg("");

    props.onImageSave("");
    props.onImageChange(false);
  };

  const onDropFile = (files) => {
    // console.log('files :- ', files);
    let file = files[0];
    let requiredWidth = requiredSize.width;
    let requiredHeight = requiredSize.height;
    let reader = new FileReader();
    if (file && file.type.indexOf("image") != -1) {
      reader.onload = (e) => {
        let img = new Image();
        img.src = reader.result;
        img.onload = function (obj) {
          let width = this.width;
          let height = this.height;

          if (width < requiredWidth || height < requiredHeight) {
            setImageErrorMsg("Try to upload bigger size image");
            props.onImageChange(false);
          } else {
            setImageErrorMsg("");
            setCanvasRecource(reader.result);
            props.onImageChange(true);
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const onZoomOut = () => {
    var scale1 = scale;
    scale1 = scale1 - scaleStep;
    if (scale1 < minScale) scale1 = minScale;
    setScale(scale1);
  };

  const onZoomIn = () => {
    var scale1 = scale;
    scale1 = scale1 + scaleStep;
    if (scale1 > maxScale) scale1 = maxScale;
    setScale(scale1);
  };

  var hideDropZoneClass = "";
  var hideCropZoneClass = "hidden";
  var hideControlButton = "hidden";
  var imageDisplayContaner = {
    width: displaySize.width,
    height: displaySize.height,
  };

  if (imageUrl && imageUrl !== "file object not found") {
    hideDropZoneClass = "hidden";
    hideCropZoneClass = "hidden";
    hideControlButton = "";
  }

  if (canvasRecource && !imageUrl) {
    hideDropZoneClass = "hidden";
    hideCropZoneClass = "";
  }
  if (imageErrorMsg != "") {
    hideControlButton = "";
    hideDropZoneClass = "hidden";
  }

  return (
    <>
      <div
        // style={imageDisplayContaner}
        className={`image-section ${hideDropZoneClass}`}>
        <Dropzone onDrop={onDropFile} accept="image/*" multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={`${className} cursor-pointer`}>
              <input {...getInputProps()} />
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-full flex justify-center px-6 pt-5 pb-6 border-2 border-inputbox border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true">
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {displayText ? (
                      <>
                        <div className="flex custom-text1-primary-normal">
                          <p>
                            <span className="text-theme">Upload a image</span>{" "}
                            or drag and drop
                          </p>
                        </div>
                        <p className="custom-text1-secondary-xs-normal">
                          PNG, JPG, (200 * 200)
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      </div>

      {imageErrorMsg && imageErrorMsg != "" ? (
        <div className="image-alert-message-section">
          <div className="alert-message custom-text1-secondary-normal">
            {imageErrorMsg}
          </div>

          <button
            type="button"
            className="danger-icon-button-circle-xs reset-button"
            onClick={resetImage}>
            <XIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        </div>
      ) : (
        <>
          <div
            className={`image-section image-display ${hideControlButton}`}
            style={imageDisplayContaner}>
            <img
              src={imageUrl}
              alt=""
              style={{ height: "100%" }}
              className={`img-responsive`}
            />
            <div className={`cropper-remove-button-position`}>
              <button
                type="button"
                className="danger-icon-button-circle-xs"
                onClick={resetImage}>
                <XIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            </div>
          </div>

          <div className={`space-y-2 ${hideCropZoneClass}`}>
            <AvatarEditor
              image={canvasRecource}
              color={[0, 0, 0, 0.5]} // RGBA
              scale={scale}
              rotate={0}
              ref={setEditorRef}
              disabledrop="true"
              width={cropperSize.width}
              height={cropperSize.height}
              className="editor-canvas"
              style={{ background: "#777" }}
            />
            <div className="space-x-2 flex item-center">
              <button
                type="button"
                className="theme-icon-button-circle-xs"
                onClick={onZoomIn}>
                <ZoomInIcon className="h-4 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="theme-icon-button-circle-xs"
                onClick={onZoomOut}>
                <ZoomOutIcon className="h-4 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="default-button-xs"
                onClick={onClickSave}>
                Crop
              </button>
              <button
                type="button"
                className="danger-icon-button-circle-xs"
                onClick={resetImage}>
                <XIcon className="h-4 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
