import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import LocaleStrings from "../../../../../languages/index";
import { DATE_FORMAT } from "../../../../../../common/constants";

// Import Actions
import {
  isOpenDiscManagModal,
  getSelectedDiscountObj,
  fetchProductsByCateSubCate,
} from "../../../actions/index";

// Common Functions
import { ProductTextLocalized } from "../../../../../../common/common-functions";

class ExpiredDiscountList extends Component {
  constructor(props) {
    super(props);

    // All Binds Functions
  }

  openDiscountManagementModal(e, discountObj) {
    if (Object.keys(discountObj.discountarea_by_iddiscount).length > 0) {
      let idcategory = discountObj.discountarea_by_iddiscount[0].idcategory;
      let idsubcategory =
        discountObj.discountarea_by_iddiscount[0].idsubcategory;

      this.props.fetchProductsByCateSubCate(
        this.props.session,
        idcategory,
        idsubcategory,
        (callBack) => {}
      );
    }

    this.props.getSelectedDiscountObj(discountObj, this.props.session);
    this.props.isOpenDiscManagModal(true);
  }

  render() {
    let language = this.props.language;
    let values = this.props.dismasterObj;
    let discountArea = this.props.discountArea;

    let appliedOn = "";
    if (values.discountarea_by_iddiscount.length > 0) {
      console.log(
        "values.discountarea_by_iddiscount.length",
        values.discountarea_by_iddiscount.length
      );

      console.log(
        "values.discountarea_by_iddiscount[0].discounareaid",
        values.discountarea_by_iddiscount[0].discounareaid
      );

      if (
        values.discountarea_by_iddiscount[0].discounareaid != null &&
        values.discountarea_by_iddiscount[0].discounareaid != ""
      ) {
        console.log("discountArea", discountArea);

        let afterFindObj = {};
        if (discountArea !== undefined && discountArea !== "undefined") {
          afterFindObj = _.find(discountArea, {
            id: values.discountarea_by_iddiscount[0].discounareaid,
          });

          appliedOn = ProductTextLocalized(
            afterFindObj.area_en,
            afterFindObj.area_ar,
            language
          );
        }
      }
    }
    return (
      <>
        {values.active == 0 ? (
          <>
            <tr
              key={this.props.position}
              className="bg-white border-b-2 border-gray-50 cursor-pointer hover-all">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {values.title}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {appliedOn}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {moment(values.validity_from).format(DATE_FORMAT)}
                {" - "}
                {moment(values.validity_end).format(DATE_FORMAT)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {values.discount_percentage}%
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="sales-table-td-text ">
                  <button
                    type="button"
                    className="button"
                    // onClick={(e) => this.openDiscountManagementModal(e, values)}
                  >
                    {LocaleStrings.edit}
                  </button>
                </div>
              </td>
            </tr>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}
// isNegotiationopen

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    discountArea: state.discountArea ? state.discountArea.data : [],
  };
}

export default connect(mapStateToProps, {
  isOpenDiscManagModal,
  getSelectedDiscountObj,
  fetchProductsByCateSubCate,
})(ExpiredDiscountList);
