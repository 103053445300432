import LocaleStrings from "../../../languages/index";
import {
  DISCOUNTMANAGEMENT_MASTER,
  DISCOUNTMANAGEMENT_PRODUCTCATEGORY,
  DISCOUNTMANAGEMENT_CRITERIAMASTER,
  DISCOUNTMANAGEMENT_PRODUCTSUBCATEGORY,
  DISCOUNTMANAGEMENT_DISCOUNTAREASELECTION,
  DISCOUNTMANAGEMENT_PRODUCTLIST,
  IS_DISCOUNTMANAGEMENT_MODAL_OPEN,
  DISCOUNTMANAGEMENT_MASTER_SINGLE,
  COUPONMANAGEMENT_MASTER,
  COUPONMANAGEMENT_MASTER_SINGLE,
  IS_COUPONMANAGEMENT_MODAL_OPEN,
  COUPONMANAGEMENT_SELECTED_TAB,
  ALL_PRODUCTS,
} from "../actions/index";

export var DiscountandCouponTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    {
      name: LocaleStrings.discounts,
      href: "discount",
      current: true,
      tabindex: 1,
    },
    // { name: LocaleStrings.coupons, href: 'coupon', current: false,tabindex:2 },
  ];

  return state;
};
export var DiscountTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.active, href: "#", current: true, tabindex: 1 },
    {
      name: LocaleStrings.inactive_discount,
      href: "#",
      current: false,
      tabindex: 2,
    },
  ];

  return state;
};

/**** Fetch Discount Management Master *****/
export var DiscountMasterStore = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_MASTER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** Get Categories, Subcategories, Criteria,  Discount Area ****/

// PRODUCT BY CATEGORY SUBCATEGORY
export var ProductByCateSubCate = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_PRODUCTLIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// PRODUCT CATEGORY
export var ProductCategory = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_PRODUCTCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// PRODUCT SUBCATEGORY
export var ProductSubCategory = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_PRODUCTSUBCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// CRITERIA MASTER
export var CriteriaMaster = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_CRITERIAMASTER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// DISCOUNT AREA SELECTION
export var DiscountArea = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_DISCOUNTAREASELECTION:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** Store IS modal Open Or not As Discount Management  ****/
export var isDiscManageModalopen = (state = false, action) => {
  if (action.type === IS_DISCOUNTMANAGEMENT_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/**** Store Single Discount Object ****/
export var singleDiscountObject = (state = false, action) => {
  if (action.type === DISCOUNTMANAGEMENT_MASTER_SINGLE) {
    state = action.payload;
  }

  return state;
};

/**** Fetch Coupons Management Master *****/
export var CouponMasterStore = (state = {}, action) => {
  switch (action.type) {
    case COUPONMANAGEMENT_MASTER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** Store IS modal Open Or not As Coupons Management  ****/
export var isCouponManageModalopen = (state = false, action) => {
  if (action.type === IS_COUPONMANAGEMENT_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

// Tabs On Coupons Managment Data Tabs
export var CouponsTabs = (state = [], action) => {
  var state = [
    { name: LocaleStrings.active, href: "#", current: false, position: 0 },
    { name: LocaleStrings.expired, href: "#", current: false, position: 1 },
  ];

  return state;
};

/**** Select Coupons active / Inactive Tabs STORED****/
export var storedCouponsTab = (state = 0, action) => {
  if (action.type === COUPONMANAGEMENT_SELECTED_TAB) {
    state = action.payload;
  }

  return state;
};

/**** Store Single Coupon Object ****/
export var singleCouponObject = (state = false, action) => {
  if (action.type === COUPONMANAGEMENT_MASTER_SINGLE) {
    state = action.payload;
  }

  return state;
};

export var allProducts = (state = {}, action) => {
  switch (action.type) {
    case ALL_PRODUCTS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
