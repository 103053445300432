import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteWishlist, fetchWishlist } from "../../../actions/index";
import Editwishlist from "./editwishlist";
import _ from "lodash";
import LocaleStrings from "../../../../../languages/index";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

class WishlistItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
    };

    // All Binded Functions
    // this.closeModal = this.closeModal.bind(this);
    // this.openModal = this.openModal.bind(this);
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  _handleDelete(e, values, data) {
    var userid = this.props.data.userid;
    // console.log('_handleDelete',userid)
    confirmAlert({
      title: "Delete Wishlist",
      message: `${LocaleStrings.delete_confirmation_suheader_text}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.deleteWishlist(
              this.props.session,
              values,
              (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error == 1) {
                  toast.error(LocaleStrings.errordelete);
                } else {
                  toast.success("Wishlist Deleted");
                  this.props.fetchWishlist(
                    this.props.session,
                    this.props.data.userid,
                    (CallBackResponse) => {}
                  );
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { values } = this.props;
    // console.log("che", values);
    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50">
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ cursor: "pointer" }}>
            <div>{values.name}</div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ cursor: "pointer" }}>
            <div>{_.startCase(values.privacy)}</div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ cursor: "pointer" }}>
            <div>{values.items.length}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div className="action-button-area" style={{ display: "flex" }}>
              <div className="">
                <button
                  className="button"
                  onClick={(e) => this.openModal(e, values)}
                  type="button">
                  View
                </button>
                {this.state.isOpen ? (
                  <Editwishlist
                    isOpen={this.state.isOpen}
                    onClose={this.closeModal}
                    newvalues={this.props.values}
                    newdata={this.props.data}
                    data={this.props.values.items}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="ml-3">
                <button
                  className="button"
                  // onClick={this.deleteNewsCallback.bind(this)}
                  onClick={(e) =>
                    this._handleDelete(e, values, this.props.data)
                  }
                  type="button">
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}
export default connect(mapStateToProps, { deleteWishlist, fetchWishlist })(
  WishlistItems
);
