import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index";

import { SearchIcon } from "@heroicons/react/solid";

// Import Actions
import { isOpenDiscManagModal } from "../../../actions/index";
// Common Functions

class DiscountManagHead extends Component {
  constructor(props) {
    super(props);

    // All Binds Functions
  }

  openDiscountManagementModal() {
    this.props.isOpenDiscManagModal(true);
  }

  render() {
    return (
      <>
        {/* Header Part */}
        <div className="end-item">
          <button
            onClick={(e) => this.openDiscountManagementModal(e)}
            type="button"
            className="lg:w-48 md:w-44 sm:w-44 xs:w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
            {LocaleStrings.add_new_discount}
            <img
              src="../images/product_management_images/add.svg"
              className="h-5 ml-2"
            />
          </button>
        </div>
        {/* <div className="grid gap-4 grid-cols-1 mb-2 md:grid-cols-3 gap-3 sm:grid-cols-2 bg-custWhite rounded end-item">
                    
                    <div className="px-4 ">
                        <button
                            type="button"
                            className="button"
                            onClick={(e) =>
                                this.openDiscountManagementModal(e)
                            }
                        >
                            {LocaleStrings.add_new_discount}
                        </button>
                    </div>
                </div> */}
      </>
    );
  }
}
// isNegotiationopen

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
  };
}

export default connect(mapStateToProps, {
  isOpenDiscManagModal,
})(DiscountManagHead);
