import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index";
import { withRouter, Route, Switch } from "react-router-dom";
import _ from "lodash";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class TransactionmanagementTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndextoShow: 0,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  // // On Tabs change
  onTabsChange = (e, tabindex, callfrom) => {
    if (callfrom === "lg") {
      // For tabs
      this.setState({ tabIndextoShow: parseInt(tabindex) });
    } else {
      // For Select box
      this.setState({ tabIndextoShow: parseInt(e.target.value) });
    }
  };

  onOrderTabSelect(e, href, position, screen) {
    if (screen == "sm") {
      if (e.target.value == "All Transactions") {
        this.props.history.push("/dashboard/transaction/" + "alltransaction");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Charity Payouts") {
        this.props.history.push("/dashboard/transaction/" + "charitypayout");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else {
        this.props.history.push("/dashboard/transaction/" + "payout");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      }
    } else {
      this.setState({ tabIndextoShow: parseInt(position) });
      this.props.history.push("/dashboard/transaction/" + href);
    }
  }

  render() {
    var selectedTab = "";

    return (
      <>
        {/* <div className="px-6 text-2xl font-semibold text-secondary pb-4">
          {LocaleStrings.order_management_heading}
        </div> */}

        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-primary focus:border-primary border-ternary rounded-md"
            // defaultValue={this.props.tabs.find((tab) => tab.current).name}
            onChange={(e) => this.onOrderTabSelect(e, "", "", "sm")}>
            {this.props.tabs.map((tab) => (
              <option
                // key={tab.name} value={tab.tabindex}

                selected={
                  this.props.history.location.pathname ==
                    "/dashboard/transaction/alltransaction" &&
                  tab.href == "alltransaction"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/transaction/payout" && tab.href == "payout"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/transaction/charitypayout" &&
                      tab.href == "charitypayout"
                    ? "selected"
                    : ""
                }>
                {tab.name}
              </option>
            ))}
          </select>
        </div>

        <div className="hidden sm:block">
          {/* <div className="xs:block"> */}
          <nav className="relative z-0 rounded-lg flex  pl-5" aria-label="Tabs">
            {this.props.tabs.map((tab, tabIdx) => (
              <span
                style={{ marginRight: "20px", borderRadius: "6px" }}
                key={tab.name}
                className={classNames(
                  this.state.tabIndextoShow === tab.position
                    ? "text-secondary"
                    : "text-ternary hover:text-secondary",
                  // tabIdx === 0 ? 'rounded-l-lg' : '',
                  // tabIdx === this.props.tabs.length - 1 ? 'rounded-r-lg' : '',
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10 "
                )}
                aria-current={
                  this.state.tabIndextoShow === tab.position
                    ? "page"
                    : undefined
                }
                onClick={(e) =>
                  this.onOrderTabSelect(e, tab.href, tab.position, "lg")
                }>
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    this.props.history.location.pathname ==
                      "/dashboard/transaction/alltransaction" &&
                      tab.href == "alltransaction"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/transaction/payout" &&
                        tab.href == "payout"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/transaction/charitypayout" &&
                        tab.href == "charitypayout"
                      ? "bg-primary"
                      : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </span>
            ))}
          </nav>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.transactionmanagementTabs,
  };
}

export default connect(mapStateToProps, {})(TransactionmanagementTabs);
