import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import ItemList from "./item-list";
import Ads from "./ad";

function HomepagebodyView(props) {
  const { homePagemaindata } = props;

  const itemRender = (items) => {
    return _.map(items, (post, index) => {
      return <ItemList item={post} />;
    });
  };

  const adsRender = (items) => {
    return _.map(items, (post, index) => {
      return <Ads item={post} index={index} />;
    });
  };

  let combinedList = [];

  if (
    homePagemaindata &&
    homePagemaindata.data &&
    homePagemaindata.data.length > 0
  ) {
    combinedList = _.filter(homePagemaindata.data, function (o) {
      return (
        o.section == "product" ||
        (o.section == "banner" && o.bannertype != "header")
      );
    });
  }

  // console.log("adsTemp", adsTemp);
  //console.log("combinedList", combinedList);

  return (
    <>
      {combinedList && combinedList.length > 0
        ? _.map(combinedList, (item, index) => {
            return item.section == "product" ? (
              <div
                className="custom_container rounded-10"
                style={{
                  background: `linear-gradient(180deg, ${item.bgcolor} 0%, #FFFFFF 100%)`,
                }}>
                <div className="p-6 text-black text-22 font-bold">
                  {item.display_name}
                </div>
                <div className="px-6 pb-18">
                  <div className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-15 md:gap-x-4  gap-y-15 md:gap-y-18 ">
                    {itemRender(item.data)}
                  </div>
                </div>
              </div>
            ) : (
              <div className="custom_container">
                {item.data.length == 1 && item.bannertype == "full_width" ? (
                  <div
                    className={`bg-white grid grid-cols-1 md:grid-cols-${item.data.length} gap-15 md:gap-4 py-4 md:py-10`}>
                    {adsRender(item.data)}
                  </div>
                ) : item.data.length > 1 && item.bannertype == "full_width" ? (
                  " "
                ) : (
                  <div
                    className={`bg-white grid grid-cols-2 md:grid-cols-${item.data.length} gap-15 md:gap-4 py-4 md:py-10`}>
                    {adsRender(item.data)}
                  </div>
                )}
              </div>
            );
          })
        : ""}
    </>
  );
}

const mapStateToProps = (state) => ({
  // isLoggedIn: state.isLoggedIn,
  // session: state.session,
  homePagemaindata: state.homePagemaindata,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomepagebodyView);
