import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index.js";
import { Field, reduxForm } from "redux-form";
import { BaseComponent } from "../../../../../common/base-component";
import { classNames } from "../../../../../common/common-functions";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { updatesource } from "../../../../../actions/index";
import { updateSuperAdmin } from "../../actions/index";
import AllGlobalTabsList from "../globaltabs";

class SuperAdminProfileSettings extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      setOpen: false,
      btnLoader: false,
      isBtnDisable: false,
      btnLoaderRgpw: false,
      phone: "",
    };
  }

  /***** Form Submit *****/
  onFormSubmit = (values) => {
    this.setState({ btnLoader: true });
    values.usertype = "superadmin";
    this.props.updateSuperAdmin(this.props.session, values, (response) => {
      // console.log("response :- ", response);
      if (response.success === 0) {
        NotificationManager.error(LocaleStrings.updatederror);
        this.setState({ btnLoader: false });
      } else {
        NotificationManager.success(LocaleStrings.updatedsuccessfully);
        this.setState({ btnLoader: false });
      }
    });
  };

  regenratePwd(e) {
    this.setState({ isBtnDisable: true, btnLoaderRgpw: true });
    let newObject = {
      email: this.props.initialValues.email,
      source: "superadmin",
    };

    // Common Forgot Password
    this.props.updatesource(this.props.session, newObject, (callback) => {
      console.log("callbac", callback);
      if (callback.status === 1) {
        this.setState({ isBtnDisable: false, btnLoaderRgpw: false });
        NotificationManager.success(
          LocaleStrings.password_reset_link_alert_text
        );
        this.props.reset();
        this.closeModal();
      } else {
        this.setState({ isBtnDisable: false, btnLoaderRgpw: false });
        NotificationManager.error(LocaleStrings.email_id_not_register_alert);
      }
    });
  }

  phoneChange = (value, country, e, formattedValue) => {
    this.setState({ phone: value, country: country.dialCode });
  };

  render() {
    var { data, initialValues, handleSubmit } = this.props;
    // console.log("initialValues", initialValues);
    return (
      <>
        <AllGlobalTabsList history={this.props.history} />
        <div className="m-6">
          <form className=" " onSubmit={handleSubmit(this.onFormSubmit)}>
            <div className="flow-root px-10 pb-5 bg-custWhite rounded">
              <p className="my-10">{LocaleStrings.aboutshopez}</p>
              <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 ">
                <div className="px-5 end-item label-forsmallscreen">
                  {LocaleStrings.superadminName}
                </div>
                <div className="mt-3">
                  <Field
                    name="username"
                    type="text"
                    component={this.renderFieldcustomer}
                    className="appearance-none block w-half px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="center-item my-2">
                <button
                  type="button"
                  disabled={this.state.isBtnDisable}
                  className={classNames(
                    this.state.isBtnDisable
                      ? "cursor-not-allowed bigbutton"
                      : "",
                    "btn-primary bigbutton"
                  )}
                  className="bigbutton"
                  onClick={(e) => this.regenratePwd(e)}>
                  <svg
                    className={classNames(
                      this.state.btnLoaderRgpw ? "" : "sr-only",
                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  {LocaleStrings.regeneratepw}
                </button>
              </div>
              <div className="center-item my-2">
                <p className="text-ternary">
                  {LocaleStrings.superadminprofilebigText}
                </p>
              </div>
              <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 mb-5">
                <div className="px-5 end-item label-forsmallscreen">
                  Phone Number
                </div>
                <div className="mt-3">
                  <Field
                    name={"phone"}
                    label={"Phone Number"}
                    mandatory="true"
                    type="text"
                    component={this.renderPhonewithFlag}
                    className="cust-input-field"
                    classNameLabel="text-box-lable"
                    phone={this.state.phone}
                    phoneChange={this.phoneChange}
                  />
                </div>
                <div></div>
              </div>
              <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 ">
                <div className="px-5 end-item label-forsmallscreen">
                  {LocaleStrings.email}
                </div>
                <div className="mt-3">
                  <Field
                    name="email"
                    type="text"
                    isdisabled="true"
                    component={this.renderAllfields}
                    className="appearance-none block w-half px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div></div>
              </div>
              <div className="center-item my-14">
                <button type="submit" className="bigbutton">
                  <svg
                    className={classNames(
                      this.state.btnLoader ? "" : "sr-only",
                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  {LocaleStrings.updateprofile}
                </button>
              </div>
            </div>
          </form>
          <NotificationContainer />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  // console.log("state.user", state.user);
  return {
    session: state.session,
    // data: ownProps.data,
    // initialValues: ownProps.data,
    initialValues: state.user,
  };
}

export default connect(mapStateToProps, {
  updatesource,
  updateSuperAdmin,
})(
  reduxForm({
    form: "OtherSettingForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(SuperAdminProfileSettings)
);
