import React, { Component } from "react";
import { connect } from "react-redux";
import Vendortabs from "./vendortabs";

class MainSellerManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }
  componentDidMount() {
    this.props.history.push("/dashboard/sellermanagement/verified");
  }

  customerdetails = (e, data) => {
    //  console.log("this.props.data", this.props.data)
    this.setState({ showUploadSection: !this.state.showUploadSection });
    //   this.props.data();
  };

  render() {
    return (
      <Vendortabs
        showUploadSection={this.customerdetails}
        history={this.props.history}
      />
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {})(MainSellerManagement);
