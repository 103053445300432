import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index";
import _ from "lodash";
import toast, { Toaster } from "react-hot-toast";
import { SearchIcon } from "@heroicons/react/solid";
// Import Components
import BaseLoader from "../../../../../common/base-loader";
import CouponManagHead from "./couponmanaghead";
import CoupMasterTableList from "./coupmastertablelist";
import CouponsManagementModal from "./addeditcoupmana";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Pagination from "react-js-pagination";

// Import Action
// import { fetchProductCategory,fetchProductSubcategory,fetchCriteriaMaster } from "../../discountsmanagement/actions/index";
import {
  fetchProductCategory,
  fetchProductSubcategory,
  fetchCriteriaMaster,
  fetchCouponMaster,
  searchCouponMaster,
} from "../../actions/index";

class CouponsManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_coupons_management,
      couponSearch: "",
      search: "",
      pageNumber: 1,
    };

    // All Binded Functions
  }

  /*** Call Only When Componenet Mount 1 time) ***/
  componentDidMount() {
    // Get Categories
    this.props.fetchProductCategory(this.props.session, (callBack) => {});

    // Subcategories
    this.props.fetchProductSubcategory(this.props.session, (callBack) => {});

    // Criteria
    this.props.fetchCriteriaMaster(this.props.session, (callBack) => {});

    // Get Coupon Master Data
    this.props.fetchCouponMaster(this.props.session, 0, (callBack) => {
      // console.log('callBack',callBack);
      this.setState({ loading: false });
    });
  }

  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchCouponMaster(this.props.session, 0, (callBack) => {});
    } else {
      this.props.searchCouponMaster(this.props.session, search);
    }
    this.setState({ search, pageNumber });
  };

  paginationCallback = (pageNumber) => {
    //  console.log("pgno", pageNumber)
    if (this.state.search == "") {
      this.props.fetchCouponMaster(
        this.props.session,
        pageNumber,
        (response) => {}
      );
    } else {
      this.props.searchCouponMaster(
        this.props.session,
        this.state.search,
        pageNumber
      );
    }

    this.setState({ ...this.state, pageNumber });
  };

  /**** Render Table Coupons master List ****/
  renderCouponsMasterList() {
    let pageDataLength = this.props.couponMasterStore.data.length;

    let { pageNumber } = this.state;
    let searchText = this.state.couponSearch;
    let filtered = [];

    if (this.state.couponSearch !== "") {
      // If Search Key Found
      let listing = this.props.couponMasterStore;

      filtered = _.filter(listing, function (o) {
        return o.title.toLowerCase().indexOf(_.toLower(searchText)) > -1;
      });
    } else {
      filtered = this.props.couponMasterStore;
    }

    let { storedCouponsTab } = this.props;
    storedCouponsTab = storedCouponsTab ? 0 : 1;

    // return _.map(
    //   _.filter(this.props.couponMasterStore.data,),
    //   (coupmasterObj, index) => {
    //     return (
    //       <CoupMasterTableList
    //         key={coupmasterObj.id}
    //         coupmasterObj={coupmasterObj}
    //         position={index}
    //         pagination={this.paginationCallback}
    //         pageDataLength={pageDataLength}
    //         currentPage={pageNumber}
    //       />
    //     );
    //   }
    // );
    return _.map(this.props.couponMasterStore.data, (coupmasterObj, index) => {
      return (
        <CoupMasterTableList
          key={coupmasterObj.id}
          coupmasterObj={coupmasterObj}
          position={index}
          pagination={this.paginationCallback}
          pageDataLength={pageDataLength}
          currentPage={pageNumber}
        />
      );
    });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <div
            className="grid gap-4 grid-rows-1  md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 mt-5"
            style={{
              overflowX: "hidden",
              overflowY: "hidden",
              display: "block",
            }}>
            <CouponManagHead />
            <div className="grid grid-cols-2 xs:grid-cols-1">
              <div
                className="relative md:w-6/12 xs:w-full text-ternary focus-within:text-secondary smallscreen-search"
                style={{ marginTop: -35 }}>
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                  <SearchIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className="block lg:w-6/12 xs:w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                  placeholder={LocaleStrings.search}
                  type="search"
                  name="search"
                  value={this.state.search}
                  onChange={this.handleSearch}
                  // onChange={(e) =>
                  //   this.setState({ couponSearch: e.target.value })
                  // }
                />
              </div>
            </div>
            <div className="flex flex-col pt-10 pb-2">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-custWhite">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {LocaleStrings.coupons_thead_coupon}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {LocaleStrings.coupons_thead_coupon_code}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {LocaleStrings.discount_thead_valide_until}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {LocaleStrings.action}
                          </th>
                        </tr>
                      </thead>
                      <tbody>{this.renderCouponsMasterList()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Pagination
              activePage={this.state.pageNumber}
              itemsCountPerPage={7}
              totalItemsCount={this.props.couponMasterStore.count}
              pageRangeDisplayed={10}
              onChange={this.paginationCallback}
            />
          </div>
        )}
        <CouponsManagementModal />
        <NotificationContainer />
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  // couponMasterStore: state.couponMasterStore
  //   ? state.couponMasterStore.data
  //   : [],
  couponMasterStore: state.couponMasterStore,

  storedCouponsTab: state.storedCouponsTab,
});

export default connect(mapStateToProps, {
  fetchProductCategory,
  fetchProductSubcategory,
  fetchCriteriaMaster,
  fetchCouponMaster,
  searchCouponMaster,
})(CouponsManagement);
