import _ from "lodash";
import moment from "moment-timezone";
import LocaleStrings from "../components/languages";
// const imageThumbnail = require('image-thumbnail');

// let options = { width: 100, height: 100, responseType: 'base64' }

// export function xyz (filename) {
//   try {
//       const thumbnail = await imageThumbnail('resources/images/dog.jpg', options);
//       console.log(thumbnail);
//   } catch (err) {
//       console.error(err);
//   }
// }

/***** *****/
export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function getExtensionNameFromFile(filename) {
  return filename.slice(
    (Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1
  );
}

/***** Class Join Function *****/
export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

/***** Random Number Generator *****/
export function getRandomNum() {
  return _.random(10000);
}

/**** Integer to decimal convertion [(+num).toFixed(2)] ****/
export function getIntToFloat(num) {
  return Number(num).toFixed(2);
}

/***** Common Function To do localization *****/

export function ProductTextLocalized(text_en, text_ar, local_lang) {
  let returnText =
    local_lang === "ar"
      ? text_ar != null && text_ar.length > 0
        ? text_ar
        : text_en
      : text_en != null && text_en.length > 0
      ? text_en
      : text_ar;
  return returnText;
}

export function timeDiffCalc(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += `${days}`;
  }

  difference += `${hours}`;

  difference += `${minutes}`;

  return difference;
}
// Get Discount Price From Item object

// export function updateMRP(item) {
//     let all_variants = item.all_variants;
//     let price = _.filter(all_variants, function (o) {
//       return o.id === item.variantid;
//     });
//     let productMRP = price.length > 0 ? price[0].price_unit : 0;

//     var discountedPrice = 0.0;
//     var discountpercent = 0.0;
//     if (item.discountactive === true) {
//       let mrp = productMRP;

//       if (item.discount && item.discount.discount_percentage) {
//         discountpercent = item.discount.discount_percentage;
//       }

//       if (item.discount && item.discount.discounttype) {
//         let discounttype = item.discount.discounttype;
//         if (discounttype === "fixed") {
//           discountedPrice = mrp - discountpercent;
//         } else {
//           discountedPrice = mrp - mrp * (discountpercent / 100);
//         }
//       } else {
//         discountedPrice = mrp - mrp * (discountpercent / 100);
//       }
//     }
//     return discountedPrice;
//   }

export function updateMRP(item, variantid) {
  let all_variants = item.all_variants;
  let price = _.filter(all_variants, function (o) {
    return o.id == variantid;
  });
  let productMRP = price.length > 0 ? price[0].price_unit : 0;
  var discountedPrice = productMRP;
  var discountpercent = 0.0;
  var strProductDiscount = "";
  if (item.discountactive == true) {
    let mrp = productMRP;

    if (item.discount && item.discount.discount_percentage) {
      discountpercent = item.discount.discount_percentage;
    }

    if (item.discount && item.discount.discounttype) {
      let discounttype = item.discount.discounttype;
      if (discounttype == "fixed") {
        //strProductDiscount = CURRENCY_RATE + discountpercent + " OFF";
        strProductDiscount = discountpercent + " OFF";
        discountedPrice = mrp - discountpercent;
      } else {
        strProductDiscount = discountpercent + "% OFF";
        discountedPrice = mrp - mrp * (discountpercent / 100);
      }
    } else {
      strProductDiscount = discountpercent + "% OFF";
      discountedPrice = mrp - mrp * (discountpercent / 100);
    }
  }
  return {
    discountedPrice: discountedPrice,
    strProductDiscount: strProductDiscount,
  };
}

export function timeAgo(dateParam) {
  let now = moment();
  let then = moment.utc(dateParam);
  then = then.local();

  let diff = {};
  diff.unit = "just now";
  diff.value = now.diff(then, "seconds");

  if (diff.value > 1) {
    diff.unit = "secs";
  }

  if (diff.unit == "secs" && diff.value > 59) {
    diff.unit = "min";
    diff.value = now.diff(then, "minutes");

    if (diff.value > 1) {
      diff.unit = "mins";
    }
  }
  if (diff.unit == "mins" && diff.value > 59) {
    diff.unit = "hour";
    diff.value = now.diff(then, "hours");

    if (diff.value > 1) {
      diff.unit = "hours";
    }
  }
  if (diff.unit == "hours" && diff.value > 24) {
    diff.unit = "day";
    diff.value = now.diff(then, "days");

    if (diff.value > 1) {
      diff.unit = "days";
    }
  }
  if (diff.unit == "days" && diff.value > 7) {
    diff.unit = "week";
    diff.value = now.diff(then, "weeks");

    if (diff.value > 1) {
      diff.unit = "weeks";
    }
  }
  if (diff.unit == "weeks" && diff.value > 4) {
    diff.unit = "month";
    diff.value = now.diff(then, "months");

    if (diff.value > 1) {
      diff.unit = "months";
    }
  }
  if (diff.unit == "months" && diff.value > 12) {
    diff.unit = "year";
    diff.value = now.diff(then, "years");

    if (diff.value > 1) {
      diff.unit = "years";
    }
  }
  return diff.unit != "just now"
    ? diff.value + " " + diff.unit + " ago"
    : diff.unit;
}
export function converDateIntoLocal(date) {
  var stillUtc = moment.utc(date).toDate();
  var local = moment(stillUtc).local();
  // .format("YYYY-MM-DD HH:mm:ss");
  return local;
}

export function converDateIntoLocalwithformat(date) {
  var stillUtc = moment.utc(date).toDate();
  var local = moment(stillUtc).local().format("D MMM YYYY HH:mm A");
  return local;
}

/*** Replace Tag Finction ***/
export function replaceTags(comment, tags) {
  let tagsArr = tags.split(",");
  let returnText = comment;
  _.map(tagsArr, (item, index) => {
    let sent1 = "<a href='http://" + parseInt(item) + "'>";
    if (returnText.indexOf(sent1) >= 0) {
      returnText = returnText.replace(sent1, "@");
      returnText = returnText.replace("</a>", "");
    }
  });
  let finalArr = returnText.split(" ");

  return finalArr;
}

export function getBilangualeText(value) {
  let text = "";
  if (value === "pcs") {
    text = LocaleStrings.pcs;
  } else if (value === "nos") {
    text = LocaleStrings.nos;
  } else if (value === "nos") {
    text = LocaleStrings.nos;
  } else if (value === "nos") {
    text = LocaleStrings.nos;
  } else if (value === "card") {
    text = "Card";
  } else if (value === "cash") {
    text = LocaleStrings.cash;
  } else if (value === "upi") {
    text = LocaleStrings.upi;
  } else if (value === "netbanking") {
    text = LocaleStrings.netbanking;
  } else if (value === "home") {
    text = LocaleStrings.home;
  } else if (value === "office") {
    text = LocaleStrings.office;
  } else if (value === "other") {
    text = LocaleStrings.other;
  } else if (value === "negotiating") {
    text = LocaleStrings.negotiating;
  } else if (value === "accepted") {
    text = LocaleStrings.accepted;
  } else if (value === "rejected") {
    text = LocaleStrings.rejected;
  } else {
    text = value;
  }
  return text;
}

export function isValidQatarPhoneNumber(phonenumber) {
  var regex = new RegExp(/^(\+974)(3|4|5|6|7)([0-9]{7})$/);

  if (phonenumber == null) {
    return false;
  }

  if (regex.test(phonenumber) == true) {
    return true;
  } else {
    return false;
  }
}

export const loadConfirmationButton = (confirm, text = "") => {
  //

  confirm.current.innerText = text === "" ? "Processing" : text;
  confirm.current.style.opacity = "0.5";
  confirm.current.style.cursor = "default";
  confirm.current.disabled = true;

  confirm.current.nextSibling.style.opacity = "0.5";
  confirm.current.nextSibling.style.cursor = "default";
  confirm.current.nextSibling.disabled = true;
};
