import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../../common/constants";
import _ from "lodash";
import {
  converDateIntoLocal,
  updateMRP,
} from "../../../../../common/common-functions";
import AllitemProductdetailmodal from "../../../sellermanagement/components/tabs/product/allitems-productdetails";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LocaleStrings from "../../../../languages/index";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import {
  fetchunapproveProductlist,
  markapproveProducts,
} from "../../actions/index";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../currency/action/index";
import RejectProductModal from "./reject-productmodal";

class AllforunapprovedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      rejectopenModal: false,
    };
  }

  _handleApprove(e, values) {
    // console.log("values", values);
    var data = values.id;
    confirmAlert({
      title: LocaleStrings.prod_mangement_approve_title,
      message: LocaleStrings.prod_mangement_approve_message,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            NotificationManager.success(
              LocaleStrings.prod_mangement_approve_success
            );
            this.props.markapproveProducts(
              this.props.session,
              data,
              (resource) => {
                this.props.pagination();
                // this.props.fetchunapproveProductlist(
                //   this.props.session,
                //   1,
                //   (CallBackResponse) => {}
                // );
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  openrejectModal = (e) => this.setState({ rejectopenModal: true });
  closerejectModal = () => this.setState({ rejectopenModal: false });

  render() {
    var { values, localCurrency } = this.props;
    // console.log('productvalues', values);
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    var img = `${BASE_IMAGES_URL}/vendor/variations/${values.productimage}?api_key=${APP_API_KEY}`;
    let variantId = values.variantid;
    let priceObj = updateMRP(values, variantId);
    let discountedPrice = priceObj.discountedPrice;

    let tabledate = "";
    tabledate = converDateIntoLocal(values.createdon).format(
      "D MMM YYYY HH:mm A"
    );

    let product_discountedPrice = 0.0;
    let productCurrency = values.currency;

    // product_discountedPrice = discountedPrice;
    product_discountedPrice = isCurrencyRateApplicable(
      productCurrency,
      discountedPrice
    );

    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 cursor-pointer">
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}
            style={{ display: "flex" }}>
            <div className="mx-2">
              <img
                src={img}
                style={{ height: "40px", width: "40px" }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../images/default.jpeg";
                }}
              />
            </div>
            <div>
              {values.productname_en.length > 35
                ? values.productname_en.substring(0, 25) + "..."
                : values.productname_en}
            </div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}>
            {values.store.hasOwnProperty("businessname") ? (
              <div>{values.store.businessname}</div>
            ) : (
              <div>{values.store.storename}</div>
            )}
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}>
            <div>
              {values.saletype == "directsale" ? (
                "Direct Sale"
              ) : (
                <span>{_.startCase(values.saletype)}</span>
              )}
            </div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}>
            <div>
              {currency} {product_discountedPrice}
            </div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}>
            <div>{tabledate}</div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}>
            {values.transaction !== null ? (
              <>
                <div>{_.startCase(values.transaction.paymentstatus)}</div>
              </>
            ) : (
              <div>Not Attempted</div>
            )}
            {/* <div>{_.startCase(values.transaction.paymentstatus)}</div> */}
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={(e) => this.openModal(e, values)}>
            {values.reject_reason !== null ? (
              <>
                <div style={{ color: "red" }}>Yes</div>
                <div>
                  Reject reason:
                  {values.reject_reason.length > 80
                    ? values.reject_reason.substring(0, 40) + "..."
                    : values.reject_reason}
                  {/* {_.startCase(values.reject_reason)} */}
                </div>
              </>
            ) : (
              <div>No</div>
            )}
            {/* <div>{_.startCase(values.transaction.paymentstatus)}</div> */}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div className="action-button-area" style={{ display: "flex" }}>
              <div className="ml-3">
                <button
                  className="button"
                  onClick={(e) => this._handleApprove(e, values)}>
                  Approve
                </button>
              </div>
              <div className="ml-3">
                <button
                  className="button"
                  onClick={(e) => this.openrejectModal(e, values)}>
                  Reject
                </button>
              </div>
            </div>
          </td>
        </tr>
        {this.state.isOpen ? (
          <AllitemProductdetailmodal
            isOpen={this.state.isOpen}
            onClose={this.closeModal}
            productDetails={this.props.values}
            qa={this.props.values.qa}
            // all_variants={this.props.values.all_variants}
          />
        ) : (
          ""
        )}

        {this.state.rejectopenModal ? (
          <RejectProductModal
            isOpen={this.state.rejectopenModal}
            onClose={this.closerejectModal}
            data={this.props.values}
            pagination={this.props.pagination}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  localCurrency: state.currency,
});

export default connect(mapStateToProps, {
  fetchunapproveProductlist,
  markapproveProducts,
  isCurrencyRateApplicable,
})(AllforunapprovedItems);
