import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
  timeDiffCalc,
} from "../../../../../common/constants";
import {
  ProductTextLocalized,
  updateMRP,
  classNames,
} from "../../../../../common/common-functions";
import { currencyCode, currencyRate } from "../../../currency/action/index";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ItemList(props) {
  const history = useHistory();
  const { item, localLanguage, type, localCurrency } = props;
  const [timeText, setTimeText] = useState("");
  const [timeInterval, setTimeInterval] = useState(0);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 2000);

  var auctionText = "";
  var endDate = "";
  if (item.saletype == "auction") {
    let currentDate = moment();
    if (
      item.auction_start_date &&
      item.auction_end_date &&
      currentDate.isAfter(moment(item.auction_start_date)) &&
      currentDate.isBefore(moment(item.auction_end_date))
    ) {
      auctionText = "Ends In";
      endDate = item.auction_end_date;
    } else if (
      item.auction_start_date &&
      item.auction_end_date &&
      currentDate.isBefore(moment(item.auction_start_date)) &&
      currentDate.isBefore(moment(item.auction_end_date))
    ) {
      auctionText = "Starts In";
      endDate = item.auction_start_date;
    } else if (
      item.auction_start_date &&
      item.auction_end_date &&
      currentDate.isAfter(moment(item.auction_start_date)) &&
      currentDate.isAfter(moment(item.auction_end_date))
    ) {
      auctionText = "Expired";
      endDate = "";
    }
  }

  useEffect(() => {
    if (endDate) {
      let timeText = timeDiffCalc(
        new Date(endDate.replace(/-/g, "/")),
        new Date()
      );
      setTimeText(timeText);
    }
  }, [timeInterval, endDate]);

  const all_variants = item.all_variants;
  let price = _.filter(all_variants, function (o) {
    return o.id == item.variantid;
  });
  //console.log("price", price);
  let currency = currencyCode(localCurrency);
  let currencyrate = currencyRate(localCurrency);

  let itemPrice = price.length > 0 ? price[0].price_unit : 0;
  itemPrice = itemPrice * currencyrate;
  let priceObj = updateMRP(item, item.variantid, currencyrate);
  let discountedPrice = priceObj.discountedPrice;
  discountedPrice = discountedPrice * currencyrate;
  let strProductDiscount = priceObj.strProductDiscount;
  //strProductDiscount = strProductDiscount * currencyrate;

  //console.log("item", item);

  let charCount = 30;
  return (
    <>
      <div
        key={item.id}
        className="w-168 flex flex-col rounded-md overflow-hidden hover:shadow-md cursor-pointer">
        <div
          className={classNames(
            type && type == "similar" ? "border border-primary rounded-md" : "",
            "flex-shrink-0 relative imagewrapper bg-white"
          )}>
          <LazyLoadImage
            className={classNames(
              type && type == "similar" ? "rounded-md" : "",
              "h-productimg w-full object-cover"
            )}
            style={{ width: "100%" }}
            src={`${BASE_IMAGES_URL}/vendor/variations/${item.productimage}?api_key=${APP_API_KEY}`}
            effect="blur"
          />
          {/* <img
            className={classNames(
              type && type == "similar" ? "rounded-md" : "",
              "h-productimg w-full object-cover"
            )}
            src={`${BASE_IMAGES_URL}/vendor/variations/${item.productimage}?api_key=${APP_API_KEY}`}
            alt=""
          /> */}

          {item.saletype == "directsale" &&
          item.currentvariant &&
          item.currentvariant.outofstock == 1 ? (
            <div className="absolute top-0 right-0 bg-progress_red text-white text-10  rounded-bl-md px-2">
              Out of Stock
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="flex-1 bg-white pl-2 pr-2 flex flex-col justify-between">
          <div className="text-left">
            <p className="text-xs  md:text-base text-primary font-normal">
              {ProductTextLocalized(
                item.productname_en,
                item.productname_ar,
                localLanguage
              ).length >= charCount
                ? ProductTextLocalized(
                    item.productname_en,
                    item.productname_ar,
                    localLanguage
                  ).substr(0, charCount)
                : ProductTextLocalized(
                    item.productname_en,
                    item.productname_ar,
                    localLanguage
                  )}

              {ProductTextLocalized(
                item.variantname_en,
                item.variantname_en,
                localLanguage
              )}
            </p>
          </div>
        </div>
        <div className="bg-white rounded-md rounded-t-none  pt-1.5 md:pt-3">
          <div className="px-2 mb-1.5">
            {currency == "QAR" ? (
              <p className="text-left text-sm md:text-base lg:text-xl font-bold text-primary">
                {currency}
                {discountedPrice.toFixed(1)}
              </p>
            ) : (
              <div className="flex items-center">
                <div className="text-left text-sm md:text-base font-bold text-primary pr-0.5">
                  US
                </div>
                <div className="text-left text-sm md:text-base lg:text-xl font-bold text-primary">
                  {currency}
                  {discountedPrice.toFixed(2)}
                </div>
              </div>
            )}
          </div>
          {item.saletype != "auction" ? (
            <div className="grid grid-cols-2 gap-2  px-2">
              <div>
                {strProductDiscount ? (
                  currency == "QAR" ? (
                    <p className=" text-10 md:text-sm font-normal text-linethrough line-through">
                      {currency}
                      {itemPrice.toFixed(1)}
                    </p>
                  ) : (
                    <p className="text-10 md:text-sm font-normal text-linethrough line-through">
                      {currency}
                      {itemPrice.toFixed(2)}
                    </p>
                  )
                ) : (
                  ""
                )}
              </div>
              <div className="text-10 md:text-sm font-bold text-discount">
                {strProductDiscount}
              </div>
            </div>
          ) : (
            ""
          )}
          {item.saletype == "auction" ? (
            <>
              <div className="grid grid-cols-3 gap-1 px-2 pb-3 md:pb-4 mt-1.5  md:mt-3.5">
                {auctionText ? (
                  endDate ? (
                    <div className="col-span-2">
                      <div className="text-xs text-secondary">
                        {auctionText}
                      </div>
                      <div>
                        <div className="flex items-center justify-center bg-topnav w-full text-10 md:text-base text-white  rounded-2xl px-2 py-1">
                          {timeText}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-span-2">
                      <div className="text-xs text-secondary">
                        {item.bidcancelled ? "Cancelled" : auctionText}
                      </div>
                      <div>
                        <div
                          className="flex items-center justify-center bg-red-600 w-full text-white rounded-xl pt-1 pb-1"
                          style={{ fontSize: 10 }}>
                          Bids Over
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )}

                <div className="flex items-end px-2">
                  <div className="text-xs flex items-center">
                    <div className="pr-0.5 text-10 md:text-base font-medium text-numbid">
                      {item.numbids}
                    </div>
                    <div className="text-10 md:text-base font-medium text-numbid">
                      {" "}
                      Bids
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-sm flex items-center pb-4 px-2">
                <div className="pr-1.5">
                  <img
                    src="../images/previeweye.svg"
                    className=" w-3.5 md:w-auto"
                  />
                </div>
                <div className="text-11  md:text-sm font-medium text-cardfooter">
                  {item.numwatching}
                </div>
              </div>
            </>
          ) : (
            <div className="grid grid-cols-3 gap-2 px-2 pb-2.5 md:pb-4 mt-3 md:mt-6">
              <div className="flex items-center">
                <div className="pr-xs">
                  <img
                    src="../images/previewstar.svg"
                    className=" w-3.5 md:w-auto"
                  />
                </div>

                <div className="text-11 md:text-sm font-medium text-cardfooter">
                  {item.ratings && item.ratings.avgrating
                    ? item.ratings.avgrating
                    : 0}
                </div>
              </div>
              <div className="flex items-center">
                <div className="pr-xs">
                  <img
                    src="../images/previewlike.svg"
                    className="w-3.5 md:w-auto"
                  />
                </div>
                <div className="text-11 md:text-sm font-medium text-cardfooter">
                  {item.numlikes}
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-11 md:text-sm font-medium text-cardfooter">
                  {item.numsold} Sold
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  // isLoggedIn: state.isLoggedIn,
  // session: state.session
  localLanguage: state.localLanguage,
  localCurrency: state.localCurrency,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
