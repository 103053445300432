import React, { Component } from "react";
import { connect } from "react-redux";
import { converDateIntoLocal } from "../../../../../../common/common-functions";
import _ from "lodash";
import { ProductTextLocalized } from "../../../../../../common/common-functions";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../../currency/action/index";

class ActiveAuctionitems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    var { values, language, localCurrency } = this.props;

    let tabledate = "";
    tabledate = converDateIntoLocal(values.createdon).format(
      "D MMM YYYY HH:mm A"
    );
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 cursor-pointer hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {ProductTextLocalized(
              values.productdetail.productname_en,
              values.productdetail.productname_ar,
              language
            )}
            <div>
              {values.productdetail.hasvariant == "1" ? (
                <span>Variant: {values.productdetail.variant.name_en}</span>
              ) : (
                ""
              )}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {/* ${parseFloat(values.maxbidprice).toFixed(2)} */}
            {currency} {values.maxbidprice.toFixed(2)}
          </td>

          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.timeleft}
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    localCurrency: state.currency,
  };
}
export default connect(mapStateToProps, { isCurrencyRateApplicable })(
  ActiveAuctionitems
);
