import React, { Component } from "react";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment-timezone";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../../common/constants";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import LocaleStrings from "../../../../languages";
import ViewImageModal from "./viewimagemodal";

class TicketMedia extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
      unbanopen: false,
    };
  }

  openModal = (e) => {
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });
  render() {
    var { values, key } = this.props;
    var img = `${BASE_IMAGES_URL}/suggestions/${values}?api_key=${APP_API_KEY}`;
    // console.log(values);
    return (
      <>
        {values.length == 0 ? (
          <>{LocaleStrings.common_no_content}</>
        ) : (
          <>
            <img
              src={img}
              onClick={(e) => this.openModal(e)}
              className="my-2 pr-2"
              style={{ height: "40px", width: "40px" }}
            />
          </>
        )}
        {this.state.isOpen ? (
          <ViewImageModal
            isOpen={this.state.isOpen}
            onClose={this.closeModal}
            image={img}
            // values={this.props.values}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, {})(TicketMedia);
