import React from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../../common/base-component";
import {
  EditRestrictedWords,
  deleteProhibitedWords,
  fetchRestrictedWords,
} from "../../actions/index";
import EditRestrictedWordss from "./editwords";
import LocaleStrings from "../../../../languages/index";
import { confirmAlert } from "react-confirm-alert";
import { NotificationManager } from "react-notifications";

class WordsListItems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
    };
  }
  deleteWordsBtnClick(e, values) {
    // console.log('deleteNewsCallback',values)
    confirmAlert({
      title: "Prohibited Words",
      message: `${LocaleStrings.areyousureyouwanttodelete}`,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            var id = values;
            // console.log('id',id)
            this.props.deleteProhibitedWords(
              this.props.session,
              values,
              (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error) {
                  NotificationManager.error(LocaleStrings.deletederror);
                } else {
                  NotificationManager.success(LocaleStrings.deletedsuccess);
                  this.props.fetchRestrictedWords(
                    this.props.session,
                    (CallBackResponse) => {}
                  );
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  openModal = (e, obj) => {
    this.props.EditRestrictedWords(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  render() {
    var { values, isLoggedIn } = this.props;
    // console.log('words', values);
    return (
      <>
        {values.word_en ? (
          <>
            <tr className="bg-white border-b-2 border-gray-50 hover-all">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                <div>{values.word_en}</div>
                <div>{values.word_ar}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                {/* <div>{values.word_file}</div> */}
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 end-item">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="">
                    <button
                      className="button"
                      onClick={(e) => this.openModal(e, values)}
                      type="button">
                      Edit
                    </button>

                    {this.state.isOpen ? (
                      <EditRestrictedWordss
                        isOpen={this.state.isOpen}
                        onClose={this.closeModal}
                        data={this.props.values}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this.deleteWordsBtnClick(e, values)}>
                      Delete
                    </button>
                    {/* {this.state.open ? (
                      <BanCustomer
                        isOpen={this.state.open}
                        onClose={this.closebanModal}
                        // values={this.props.values}
                      />
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr className="bg-white border-b-2 border-gray-50 hover-all">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                {/* <div>{values.word_ar}</div> */}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                <div>{values.word_file_en}</div>
                <div>{values.word_file_ar}</div>
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 end-item">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="">
                    <button
                      className="button"
                      onClick={(e) => this.openModal(e, values)}
                      type="button">
                      Edit
                    </button>

                    {this.state.isOpen ? (
                      <EditRestrictedWordss
                        isOpen={this.state.isOpen}
                        onClose={this.closeModal}
                        data={this.props.values}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this.deleteWordsBtnClick(e, values)}>
                      Delete
                    </button>
                    {/* {this.state.open ? (
                      <BanCustomer
                        isOpen={this.state.open}
                        onClose={this.closebanModal}
                        // values={this.props.values}
                      />
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log("customerlistingtabs", state.isLoggedIn);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapStateToProps, {
  EditRestrictedWords,
  deleteProhibitedWords,
  fetchRestrictedWords,
})(WordsListItems);
