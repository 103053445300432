import React, { Component } from "react";
import { connect } from "react-redux";
import { currencyCode, currencyRate } from "../../../../currency/action/index";
import _ from "lodash";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
} from "../../../../../../common/constants";

class MostActiveUserProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    var { values, localCurrency } = this.props;
    var img = `${BASE_IMAGES_URL}/vendor/variations/${values.mediafilename}?api_key=${APP_API_KEY}`;
    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 cursor-pointer hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.productname_en}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <img
              className="w-16 h-16 flex-shrink-0  rounded-full"
              src={img}
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "../images/default.jpeg";
              }}
            />
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    localCurrency: state.currency,
  };
}
export default connect(mapStateToProps, {})(MostActiveUserProducts);
