import React, { Component } from "react";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment-timezone";
import TicketMedia from "./ticket-media";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import {
  classNames,
  converDateIntoLocalwithformat,
} from "../../../../../common/common-functions";
import SendReply from "./sendreplymodal";
import _ from "lodash";
import { Sendingreply } from "../../actions/index";
import RepliedModal from "./repliedmodal";
import LocaleStrings from "../../../../languages";

class TicketListitems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
    };
  }

  openModal = (e, obj) => {
    this.props.Sendingreply(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  openrepliedModal = (e, obj) => {
    this.props.Sendingreply(obj);
    this.setState({ open: true });
  };
  closerepliedModal = () => this.setState({ open: false });

  renderList() {
    var items = _.map(this.props.values.media, (values, index) => (
      <TicketMedia key={index} values={values} />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }

  render() {
    var { values } = this.props;
    // console.log('replied', values.media.length);
    let tabledate = "";
    // tabledate = moment(values.createdat).format("D MMM YYYY HH:mm A");

    tabledate = converDateIntoLocalwithformat(values.createdat);

    // console.log(localDateTimeString); // Output will v
    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{values.ticketby.username}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{tabledate}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {_.isEmpty(values.media) ? (
              <div>{LocaleStrings.common_no_content}</div>
            ) : (
              <div className="pr-2 flex">{this.renderList()}</div>
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div className="action-button-area" style={{ display: "flex" }}>
              <div className="">
                {values.replied == 0 ? (
                  <>
                    {" "}
                    <button
                      className="button"
                      onClick={(e) => this.openModal(e, values)}>
                      {LocaleStrings.ticketsReports_sendreplyText}
                    </button>{" "}
                    {this.state.isOpen ? (
                      <SendReply
                        isOpen={this.state.isOpen}
                        onClose={this.closeModal}
                        data={this.props.values}
                        currentPage={this.props.currentPage}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      className="button"
                      // onClick={(e) => this.openrepliedModal(e, values)}
                    >
                      {LocaleStrings.ticketsReports_RepliedText}
                    </button>
                    {this.state.open ? (
                      <RepliedModal
                        isOpen={this.state.open}
                        onClose={this.closerepliedModal}
                        data={this.props.values}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.customermanagementTabs,
    customereditmodal: state.customereditmodal,
    customerlistingtabs: state.customerlistingtabs,
    newtablist: state.newtablist,
  };
}

export default connect(mapStateToProps, {
  Sendingreply,
})(TicketListitems);
