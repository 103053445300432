import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../../languages/index";
import { Fragment } from "react";
import { BaseComponent } from "../../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { reduxForm } from "redux-form";
import Itemswishlist from "./edititems";
import _ from "lodash";
import { Toaster } from "react-hot-toast";

class Editwishlist extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }
  componentDidMount() {}

  renderlist() {
    //    console.log('wish', this.props.data)
    var items = _.map(this.props.data, (values) => (
      <Itemswishlist
        key={values.userid}
        values={values}
        newdata={this.props.newdata}
      />
    ));
    return items;
  }

  render() {
    var { data, newvalues } = this.props;
    //    console.log('test', data)
    //    console.log('newvalues', newvalues)
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: "0" }}>
                  {/* <div className="grid grid-cols-2 mb-10 border-b-2 border-gray-50 pb-4 px-4 py-3 sticky-header">
                <div>{_.startCase(newvalues.privacy)}</div>
                <div className="flex flex-wrap mx-3 end-item">{newvalues.name}</div>
                <div className="flex flex-wrap content-center justify-end">
                  <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  onClick={this.props.onClose}
                  >
                  <span className="sr-only"></span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  </div>
              </div> */}
                  <div className="grid grid-cols-3 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center mt-4">
                      <div className="text-lg leading-6 font-medium text-secondary">
                        {newvalues.name}
                      </div>
                    </div>
                    <div className="absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.props.onClose}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  {newvalues.items.length > 0 ? (
                    <>
                      <div className="p-8">
                        <div className=" max-w-lg mx-auto grid gap-5 lg:grid-cols-2 md:grid-cols-3 sm:grid-cols-2 lg:max-w-none">
                          {this.renderlist()}
                        </div>
                        {/* <div className="py-10 center-item">
                  <button className="button">Delete Wishlist</button>
              </div> */}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-ternary center-item mt-16">
                        No Items Added
                      </div>
                    </>
                  )}
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <Toaster limit={1} />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('session',state.wishlistlist);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    wishlistlist: state.wishlistlist,
    data: ownProps.data,
  };
}

export default connect(
  mapStateToProps,
  {}
)(
  reduxForm({
    // validate,
    form: "EditcustomerForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Editwishlist)
);
