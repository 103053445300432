import React from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../../../common/base-component";
import { editcharity } from "../../../actions/index";
import Editcharityevents from "./editcharity";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
} from "../../../../../../common/constants";
import { ProductTextLocalized } from "../../../../../../common/common-functions";

class CharityListitem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: true,
      unbanopen: false,
    };
  }

  openModal = (e, obj) => {
    this.props.editcharity(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  render() {
    var { values, language } = this.props;
    let image = "";
    if (values.image != "" && values.image != null) {
      image = `${BASE_IMAGES_URL}/${values.image}?api_key=${APP_API_KEY}`;
    }
    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer flex">
            {image != "" ? (
              <img src={image} className="h-16 w-16 rounded mr-4" />
            ) : (
              ""
            )}
            <div>{values.name_en}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>
              {ProductTextLocalized(
                values.description_en &&
                  values.description_en !== "" &&
                  values.description_en.length > 35
                  ? values.description_en.substring(0, 25) + "..."
                  : values.description_en,
                values.description_ar &&
                  values.description_ar !== "" &&
                  values.description_ar.length > 35
                  ? values.description_ar.substring(0, 25) + "..."
                  : values.description_ar,

                language
              )}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {values.percentage !== null ? (
              <div>{values.percentage}%</div>
            ) : (
              <div>-</div>
            )}
            {/* <div>{values.email}</div> */}
          </td>

          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div className="action-button-area" style={{ display: "flex" }}>
              <div className="ml-3">
                <button
                  className="button"
                  onClick={(e) => this.openModal(e, values)}
                  type="button">
                  Edit
                </button>
                {this.state.isOpen ? (
                  <Editcharityevents
                    isOpen={this.state.isOpen}
                    onClose={this.closeModal}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user, language } = state;

  return {
    session,
    user,
    language,
  };
}

export default connect(mapStateToProps, {
  editcharity,
})(CharityListitem);
