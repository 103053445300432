import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";
import {
  sellerTabid,
  newtabseller,
  editsellermodal,
  updateSeller,
  fetchSeller,
} from "../actions/index";
import EditsellerModal from "./editsellermodal";
import BanSeller from "./banseller";
import UnBanSeller from "./unbanseller";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import { BaseComponent } from "../../../../common/base-component";

class SellerlistItem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
      unbanseller: false,
    };

    // // All Binded Functions
    // this.closeModal = this.closeModal.bind(this);
    // this.openModal = this.openModal.bind(this);
  }

  openBanModal = (e, obj) => {
    // console.log('obj',obj)
    var userOngoingOrder = _.filter(this.props.orderMasterList.data, {
      vendorid: obj.userid,
    });
    if (userOngoingOrder.length > 0) {
      NotificationManager.error(
        LocaleStrings.seller_management_ongoingorder_error
      );
    } else {
      this.props.sellerTabid(obj);
      this.setState({ open: true });
    }
  };
  closebanModal = () => this.setState({ open: false });

  openUnBanModal = (e, obj) => {
    this.props.sellerTabid(obj);
    this.setState({ unbanseller: true });
  };
  closeUnbanModal = () => this.setState({ unbanseller: false });

  openModal() {
    this.setState({ isOpen: true });
    // this.props.newtabs(obj);
  }
  openModal = (e, obj) => {
    this.props.sellerTabid(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });
  // closeModal() {
  //     this.setState({isOpen: false});
  // }
  openTabs(obj) {
    // console.log('obj',obj)
    this.props.sellerTabid(obj);
    this.props.newtabseller(obj);
  }

  _banFromBiding(e, values) {
    let callfrom = "verified";
    if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/unverified"
    ) {
      callfrom = "unverified";
    } else if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/verficationrequested"
    ) {
      callfrom = "verficationrequested";
    }
    // console.log('deleteNewsCallback', values)
    let obj = {};
    obj.userid = values.userid;
    obj.banfrom_auction = 1;
    confirmAlert({
      title: "Ban seller from biding",
      message: `Are you sure you want to ban this seller from biding?`,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            var id = values;
            // console.log('id',id)
            this.props.updateSeller(this.props.session, obj, (resource) => {
              // console.log("showresource :- ", resource);
              if (resource.error) {
                NotificationManager.error("Error in banning");
              } else {
                NotificationManager.success("Successfully Banned from auction");
                this.props.pagination();
                // this.props.fetchSeller(
                //   this.props.session,
                //   this.props.currentPage,
                //   callfrom,
                //   (CallBackResponse) => {}
                // );
              }
            });
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  _unbanFromBiding(e, values) {
    // console.log('deleteNewsCallback', values)
    let callfrom = "verified";
    if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/unverified"
    ) {
      callfrom = "unverified";
    } else if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/verficationrequested"
    ) {
      callfrom = "verficationrequested";
    }
    let obj = {};
    obj.userid = values.userid;
    obj.banfrom_auction = 0;
    confirmAlert({
      title: "Unban Seller from biding",
      message: `Are you sure you want to unban this seller from biding?`,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            var id = values;
            // console.log('id',id)
            this.props.updateSeller(this.props.session, obj, (resource) => {
              // console.log("showresource :- ", resource);
              if (resource.error) {
                NotificationManager.error("Cannot unban customer from bidding");
              } else {
                NotificationManager.success("Unbanned from auction");
                this.props.pagination();
                // this.props.fetchSeller(
                //   this.props.session,
                //   this.props.currentPage,
                //   callfrom,
                //   (CallBackResponse) => {}
                // );
              }
            });
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  _goldTickAdd(e, values) {
    let callfrom = "verified";
    if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/unverified"
    ) {
      callfrom = "unverified";
    } else if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/verficationrequested"
    ) {
      callfrom = "verficationrequested";
    }
    // console.log('deleteNewsCallback', values)
    let obj = {};
    obj.userid = values.userid;
    obj.isgold = 1;
    confirmAlert({
      title: "Add Gold Tick For Seller",
      message: `Are you sure you want to add gold tick for this seller?`,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            var id = values;
            // console.log('id',id)
            this.props.updateSeller(this.props.session, obj, (resource) => {
              // console.log("showresource :- ", resource);
              if (resource.error) {
                NotificationManager.error("Error in adding");
              } else {
                NotificationManager.success("Successfully added gold tick");
                this.props.pagination();
                // this.props.fetchSeller(
                //   this.props.session,
                //   this.props.currentPage,
                //   callfrom,
                //   (CallBackResponse) => {}
                // );
              }
            });
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  _goldTickAremove(e, values) {
    let callfrom = "verified";
    if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/unverified"
    ) {
      callfrom = "unverified";
    } else if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/verficationrequested"
    ) {
      callfrom = "verficationrequested";
    }
    // console.log('deleteNewsCallback', values)
    let obj = {};
    obj.userid = values.userid;
    obj.isgold = 0;
    confirmAlert({
      title: "Remove Gold Tick For Seller",
      message: `Are you sure you want remove gold tick for this seller?`,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            var id = values;
            // console.log('id',id)
            this.props.updateSeller(this.props.session, obj, (resource) => {
              // console.log("showresource :- ", resource);
              if (resource.error) {
                NotificationManager.error("Error");
              } else {
                NotificationManager.success("Successfully removed");
                this.props.pagination();
                // this.props.fetchSeller(
                //   this.props.session,
                //   this.props.currentPage,
                //   callfrom,
                //   (CallBackResponse) => {}
                // );
              }
            });
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { values } = this.props;
    // console.log('values1', values);
    return (
      <>
        {values.isactive == true ? (
          <>
            {" "}
            <tr className="bg-white border-b-2 border-gray-50 hover-all">
              {values.isgold == 1 ? (
                <img
                  className={"h-6 w-10 mt-4 mx-4 cursor-pointer"}
                  src={"../images/rightcircle.svg"}
                />
              ) : values.verified == 1 ? (
                <img
                  className={"h-10 w-10 mt-4 mx-4 cursor-pointer"}
                  src={"../images/bluetick.jpeg"}
                />
              ) : (
                <div className="mt-4 mx-6"> - </div>
              )}
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openTabs(values)}>
                <div>{values.username}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openTabs(values)}>
                <div>{values.email}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openTabs(values)}>
                <div>{values.phone}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  {values.banfrom_auction == 0 ? (
                    <div className="">
                      <button
                        className="button"
                        onClick={(e) => this._banFromBiding(e, values)}>
                        Ban From Biding
                      </button>
                    </div>
                  ) : (
                    <div className="">
                      <button
                        className="button"
                        onClick={(e) => this._unbanFromBiding(e, values)}>
                        UnBan From Biding
                      </button>
                    </div>
                  )}
                  {values.isgold == 0 ? (
                    <div className="ml-3">
                      <button
                        className="button"
                        onClick={(e) => this._goldTickAdd(e, values)}>
                        Add Gold Tick
                      </button>
                    </div>
                  ) : (
                    <div className="ml-3">
                      <button
                        className="button"
                        onClick={(e) => this._goldTickAremove(e, values)}>
                        Remove Gold Tick
                      </button>
                    </div>
                  )}
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this.openModal(e, values)}
                      type="button">
                      {LocaleStrings.edit}
                    </button>
                    {/* <EditcustomerModal  values={values}/>  */}
                    {this.state.isOpen ? (
                      <EditsellerModal
                        isOpen={this.state.isOpen}
                        onClose={this.closeModal}
                        currentPage={this.props.currentPage}
                        history={this.props.history}
                        pagination={this.props.pagination}
                        // values={this.props.values}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this.openBanModal(e, values)}>
                      {LocaleStrings.ban}
                    </button>
                    {this.state.open ? (
                      <BanSeller
                        isOpen={this.state.open}
                        onClose={this.closebanModal}
                        currentPage={this.props.currentPage}
                        history={this.props.history}
                        pagination={this.props.pagination}
                        // values={this.props.values}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </td>
            </tr>
          </>
        ) : (
          <>
            {" "}
            <tr className="bg-gray-200 border-b-2 border-gray-50 ">
              {values.isgold == 1 ? (
                <img
                  className={"h-6 w-10 mt-4 mx-4 cursor-pointer"}
                  src={"../images/rightcircle.svg"}
                />
              ) : values.verified == 1 ? (
                <img
                  className={"h-10 w-10 mt-4 mx-4 cursor-pointer"}
                  src={"../images/bluetick.jpeg"}
                />
              ) : (
                <div className="mt-4 mx-6"> - </div>
              )}
              {/* <div></div> */}
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openTabs(values)}>
                <div>{values.username}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openTabs(values)}>
                <div>{values.email}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openTabs(values)}>
                <div>{values.phone}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this.openUnBanModal(e, values)}>
                      {LocaleStrings.unban}
                    </button>{" "}
                    {this.state.unbanseller ? (
                      <UnBanSeller
                        isOpen={this.state.unbanseller}
                        onClose={this.closeUnbanModal}
                        currentPage={this.props.currentPage}
                        history={this.props.history}
                        pagination={this.props.pagination}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.sellermanagementTabs,
    sellereditmodal: state.sellereditmodal,
    sellerlistingtabs: state.sellerlistingtabs,
    newtabsellerlist: state.newtabsellerlist,
    orderMasterList: state.orderMasterList,
  };
}

export default connect(mapStateToProps, {
  sellerTabid,
  newtabseller,
  editsellermodal,
  updateSeller,
  fetchSeller,
})(SellerlistItem);
