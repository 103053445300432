import React from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../../../common/base-component";
import _ from "lodash";
import EditFAQ from "./editfaq";
import {
  foreditfaq,
  deleteFAQ,
  fetchConsumerFaq,
} from "../../../actions/index";
import { NotificationManager } from "react-notifications";
import LocaleStrings from "../../../../../languages/index";
import { confirmAlert } from "react-confirm-alert";
import { ProductTextLocalized } from "../../../../../../common/common-functions";

class Faqlistitems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openModal(e, obj) {
    this.props.foreditfaq(obj);
    this.setState({ isOpen: true });
  }

  // openModal = (e,values) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  opensmallModal(e, obj) {
    this.props.foreditfaq(obj);
    this.setState({ open: true });
  }

  // openModal = (e,values) => this.setState({ isOpen: true });
  closesmallModal = () => this.setState({ open: false });

  _handleDelete(e, values) {
    // console.log("values", values);
    confirmAlert({
      title: LocaleStrings.global_setting_dltfaq,
      message: `${LocaleStrings.delete_confirmation_suheader_text}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.deleteFAQ(this.props.session, values, (resource) => {
              NotificationManager.success(LocaleStrings.common_deleteSucess);
              this.props.fetchConsumerFaq(
                this.props.session,
                (CallBackResponse) => {}
              );
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { values, language } = this.props;
    // console.log("abcd", values);
    return (
      <>
        <div
          className="flow-root px-10 py-5 bg-custWhite rounded mb-5 hidden md:block"
          id={this.props.key}>
          <div className="grid grid-cols-2">
            <div>
              <p>
                {LocaleStrings.global_setting_agentQuestion}{" "}
                {ProductTextLocalized(
                  values.question_en,
                  values.question_ar,
                  language
                )}
              </p>
              <p className="pt-5">
                {LocaleStrings.global_setting_agentAnswer}
                {ProductTextLocalized(
                  values.answer_en,
                  values.answer_ar,
                  language
                )}
              </p>
            </div>
            <div className="end-item">
              {" "}
              <button
                className="button mr-2"
                onClick={(e) => this.openModal(e, values)}>
                {LocaleStrings.edit}
              </button>{" "}
              {this.state.isOpen ? (
                <EditFAQ isOpen={this.state.isOpen} onClose={this.closeModal} />
              ) : (
                ""
              )}
              <button
                className="button"
                onClick={(e) => this._handleDelete(e, values)}>
                {LocaleStrings.delete}
              </button>
            </div>
          </div>
        </div>
        {/* <div className="flow-root px-3 py-5 bg-custWhite rounded mb-5 md:hidden">
          <div>
            <p>{LocaleStrings.global_setting_agentQuestion}  {values.question}</p>
            <p className="pt-5">{LocaleStrings.global_setting_agentAnswer} {_.startCase(values.answer)}</p>
          </div>
          <div className="center-item mt-2">
            {" "}
            <button
              className="button mr-2"
              onClick={(e) => this.opensmallModal(e, values)}
            >
              {LocaleStrings.edit}
            </button>{" "}
            {this.state.open ? (
              <EditFAQ
                isOpen={this.state.open}
                onClose={this.closesmallModal}
              />
            ) : (
              ""
            )}
            <button
              className="button"
              onClick={(e) => this._handleDelete(e, values)}
            >
              {LocaleStrings.delete}
            </button>
          </div>
        </div> */}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
  };
}

export default connect(mapStateToProps, {
  foreditfaq,
  deleteFAQ,
  fetchConsumerFaq,
})(Faqlistitems);
