import _ from "lodash";
import randomstring from "randomstring";
import axios from "axios";
import { INSTANCE_URL, BASE_IMAGES_URL } from "../../../../common/constants";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  uploadEncodedFileLarge,
  uploadEncodedFile,
} from "../../../../common/network-call";

export var ALL_PRODUCT_LIST = "ALL_PRODUCT_LIST";
export var ALL_PRODUCT_CATEGORY = "ALL_PRODUCT_CATEGORY";
export var ALL_PRODUCT_SUBCATEGORY = "ALL_PRODUCT_SUBCATEGORY";
export var MAIN_HEADER_HOMEPAGE = "MAIN_HEADER_HOMEPAGE";
export var MAIN_HOMEPAGE_BODY = "MAIN_HOMEPAGE_BODY";
export var HEADERUPDATE_DATA = "HEADERUPDATE_DATA";
export var PRODUCTUPDATE_DATA = "PRODUCTUPDATE_DATA";
export var BANNERUPDATE_DATA = "BANNERUPDATE_DATA";
export var HOME_PAGEBODY_REDUX = "HOME_PAGEBODY_REDUX";
export var HOMEPAGE_MAINDATA = "HOMEPAGE_MAINDATA";

export function fetchAllproducts(session, callback) {
  var filtertype = encodeURI(`(isactive=1)AND(isdeleted=0)AND(listed=1)`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/products?filter=(${filtertype})`;
  // console.log(url)
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: ALL_PRODUCT_LIST,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchAllCategories(session, callback) {
  var filtertype = encodeURI(`(isactive=1)`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productcategory?filter=(${filtertype})`;
  // console.log(url)
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: ALL_PRODUCT_CATEGORY,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchAllsubCategories(session, callback) {
  var filtertype = encodeURI(`(isactive=1)`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productsubcategory?filter=(${filtertype})`;
  // console.log(url)
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: ALL_PRODUCT_SUBCATEGORY,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchSettingMaster(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/templayoutdata`;

  return (dispatch) => {
    postRequest(
      url,
      {},
      session,
      dispatch,
      (response) => {
        let mainHomepagebody = [];
        let mainHeaderhomePage = [];
        // let data=[];
        const json_layout_Data = response.data;

        let mainHeaderFilter = _.filter(json_layout_Data, function (o) {
          return o.bannertype == "header";
        });

        let headerObj = mainHeaderFilter[0];
        headerObj.image = headerObj.staticdata.image;
        headerObj.linktype = headerObj.staticdata.linktype;
        headerObj.headerType = "mobile";
        if (headerObj.linktype == "product") {
          headerObj.productid = headerObj.staticdata.productid;
        } else if (headerObj.linktype == "subcategory") {
          headerObj.subcategoryid = headerObj.staticdata.subcategoryid;
        } else if (headerObj.linktype == "category") {
          headerObj.categoryid = headerObj.staticdata.categoryid;
        } else {
          headerObj.externallink = headerObj.staticdata.externallink;
        }
        headerObj.value = headerObj.staticdata.value;
        // _.forEach(headerObj.data, function (value) {
        //   let obj = {
        //     image: value.image,
        //     linktype: value.linktype,
        //     mobileimage:value.mobileimage,
        //   };
        //  if(value.linktype == 'product'){
        //   obj.productid = value.value;
        // }else if(value.linktype == 'subcategory'){
        //   obj.subcategoryid = value.value;
        // }else if(value.linktype == 'category'){
        //   obj.categoryid = value.value
        // }else{
        //   obj.externallink = value.value
        // }
        //   data.push(obj);
        // });

        // headerObj.data = data;

        mainHeaderhomePage.push(headerObj);
        mainHomepagebody = _.filter(json_layout_Data, function (o) {
          return o.bannertype !== "header";
        });

        dispatch({
          type: MAIN_HOMEPAGE_BODY,
          payload: { data: mainHomepagebody },
        });
        dispatch({
          type: MAIN_HEADER_HOMEPAGE,
          payload: { data: mainHeaderhomePage },
        });
        dispatch({
          type: HOMEPAGE_MAINDATA,
          payload: { data: response.data },
        });
        callback({ success: 1, data: mainHomepagebody });
      },
      (error) => {}
    );
  };
}

export function updateDataHeader(obj) {
  console.log("obj", obj);

  // if(obj[0].linktype == 'product'){
  //   value['productid'] ={
  //         label: obj[0].productname_en ? obj[0].productname_en : obj[0].label,
  //         name: obj[0].productname_en ? obj[0].productname_en : obj[0].label,
  //         value: obj[0].productid,
  //   }
  // }
  _.forEach(obj[0]?.data, function (value) {
    if (value.linktype == "product") {
      value["id"] = {
        label: value.productname_en ? value.productname_en : value.label,
        name: value.productname_en ? value.productname_en : value.label,
        value: value.productid,
      };
    }
  });
  return (dispatch) => {
    dispatch({ type: HEADERUPDATE_DATA, payload: obj[0] });
  };
}

export function updateFormList(newKey, oldKeys, action) {
  // console.log('newKeyp',newKey);
  let oldRecord = [];

  if (oldKeys.length > 0) {
    oldRecord = oldKeys;
  }

  if (action === "new") {
    oldRecord = newKey;
  }

  if (action === "add") {
    oldRecord.push(newKey);
  } else if (action === "remove") {
    _.remove(oldKeys, {
      id: newKey.id,
    });
  } else if (action === "sort") {
    oldRecord = newKey;
  }

  return (dispatch) => {
    dispatch({ type: MAIN_HOMEPAGE_BODY, payload: { data: oldRecord } });
  };
}

export function updateProductobjhomepage(obj) {
  if (Object.keys(obj).length > 0 && obj.selection_logic == "manual") {
    // obj["data"] = obj.data;

    // console.log('discountObj',discountObj.categories)
    let productDataArr = [];

    _.forEach(obj.data, function (value) {
      // console.log('value',value)
      let id = value.id;
      let productname_en = value.productname_en
        ? value.productname_en
        : value.label;
      var obj1 = {};
      obj1.validitytill = value.validitytill;
      obj1.productname_en = value.productname_en;
      obj1.productname_ar = value.productname_ar;
      obj1.productimage = value.productimage;
      obj1.id = {
        label: productname_en,
        name: productname_en,
        value: id,
      };

      productDataArr.push(obj1);
    });

    obj["data"] = productDataArr;
  }

  return (dispatch) => {
    dispatch({ type: PRODUCTUPDATE_DATA, payload: obj });
  };
}
export function updateBannerobjhomepage(obj) {
  // console.log('obj',obj)
  let headerObj = obj;
  //   if(obj.hasOwnProperty('data') && obj.data.length > 0 ){
  //   let data =[];
  _.forEach(obj.data, function (value) {
    if (value.linktype == "product") {
      let obj = {
        image: value.image,
        linktype: value.linktype,
        mobileimage: value.mobileimage,
      };

      value["id"] = {
        label: value.productname_en ? value.productname_en : value.label,
        name: value.productname_en ? value.productname_en : value.label,
        value: value.productid,
      };
    }
  });
  return (dispatch) => {
    dispatch({ type: BANNERUPDATE_DATA, payload: headerObj });
  };
}

// Header Dynamic Image Uploads
export function updateHomePageHeader(session, values, callback) {
  return (dispatch) => {
    _handleMultipleImageUploadHeaderBanner(
      session,
      values?.data,
      dispatch,
      (image) => {
        if (image.success == 1) {
          values.data = image.data;
          let mainHeaderhomePage = [];
          mainHeaderhomePage.push(values);

          dispatch({
            type: MAIN_HEADER_HOMEPAGE,
            payload: { data: mainHeaderhomePage },
          });
          callback({ success: 1 });
        } else {
          callback({ success: 2, message: image?.message });
        }
      }
    );
  };
}

export function _handleMultipleImageUploadHeaderBanner(
  session,
  uploadFiles,
  dispatch,
  callback
) {
  var promiseArray = [];
  _.map(uploadFiles, (item, index) => {
    // console.log("question item :- ", item);
    promiseArray.push(
      new Promise((resolve, reject) => {
        var file = item?.image ? item.image : "";
        var file2 = item?.mobileimage ? item.mobileimage : "";
        var n = file.search(";base64,");
        var n2 = file2.search(";base64,");

        if (n > 0) {
          var content = file.split(";base64,")[1];
          var filename = `homebanner/${randomstring.generate()}.jpg`;
          // let filename = 'homebanner/web'+ ts + "." + "jpg";
          var imageJson = {
            resource: [
              {
                name: filename,
                type: "file",
                is_base64: true,
                content: content,
              },
            ],
          };
          uploadEncodedFile(
            `${BASE_IMAGES_URL}?check_exist=false`,
            session,
            imageJson,
            dispatch,
            (data) => {
              let imagename = data.name;
              uploadFiles[index].image = imagename;
              // uploadFiles[index].image = data.name;//name changes for uploaded image in db
              resolve();
            },
            (error) => {
              // callback({success:2, message:error});
            }
          );
        } else if (file == "" || file == "removed") {
          uploadFiles[index].image = null;
          resolve();
        } else {
          uploadFiles[index].image = file;
          resolve();
        }

        if (n2 > 0) {
          var content = file2.split(";base64,")[1];
          var filename = `homebanner/${randomstring.generate()}.jpg`;
          // let filename = 'homebanner/mobile'+ ts + "." + "jpg"; // Image Name Creation
          var imageJson = {
            resource: [
              {
                name: filename,
                type: "file",
                is_base64: true,
                content: content,
              },
            ],
          };
          uploadEncodedFile(
            `${BASE_IMAGES_URL}?check_exist=false`,
            session,
            imageJson,
            dispatch,
            (data) => {
              let imagename = data.name;
              uploadFiles[index].mobileimage = imagename;
              // uploadFiles[index].image = data.name;//name changes for uploaded image in db
              resolve();
            },
            (error) => {
              // callback({success:2, message:error});
            }
          );
        } else if (file2 == "" || file2 == "removed") {
          uploadFiles[index].mobileimage = null;
          resolve();
        } else {
          uploadFiles[index].mobileimage = file2;
          resolve();
        }
      })
    );
  });

  Promise.all(promiseArray).then(() => {
    callback({ success: 1, data: uploadFiles });
  });
}

// Header Static Image Upload
export function uploadMediaLarge(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else {
    var blob = new Blob([filecontent], { type: "image/jpeg" });
  }

  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${BASE_IMAGES_URL}/homebanner/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        // console.log('done?',data)
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

// Body Dynamic Image Uploads
export function updateHomePageBanner(
  session,
  values,
  mainHomepagebody,
  index,
  callback
) {
  return (dispatch) => {
    _handleMultipleImageUploadBodyBanner(
      session,
      values?.data,
      dispatch,
      (image) => {
        if (image.success == 1) {
          values.data = image.data;
          mainHomepagebody.data[index] = values;
          dispatch({
            type: MAIN_HOMEPAGE_BODY,
            payload: { data: mainHomepagebody.data },
          });
          callback({ success: 1 });
        } else {
          callback({ success: 2, message: image?.message });
        }
      }
    );
  };
}

export function _handleMultipleImageUploadBodyBanner(
  session,
  uploadFiles,
  dispatch,
  callback
) {
  var promiseArray = [];
  _.map(uploadFiles, (item, index) => {
    // console.log("question item :- ", item);
    promiseArray.push(
      new Promise((resolve, reject) => {
        var file = item?.image ? item.image : "";
        var file2 = item?.mobileimage ? item.mobileimage : "";
        var n = file.search(";base64,");
        var n2 = file2.search(";base64,");

        if (n > 0) {
          var content = file.split(";base64,")[1];
          var filename = `homebanner/${randomstring.generate()}.jpg`;
          // let filename = 'homebanner/web'+ ts + "." + "jpg";
          var imageJson = {
            resource: [
              {
                name: filename,
                type: "file",
                is_base64: true,
                content: content,
              },
            ],
          };
          uploadEncodedFile(
            `${BASE_IMAGES_URL}?check_exist=false`,
            session,
            imageJson,
            dispatch,
            (data) => {
              let imagename = data.name;
              uploadFiles[index].image = imagename;
              // uploadFiles[index].image = data.name;//name changes for uploaded image in db
              resolve();
            },
            (error) => {
              // callback({success:2, message:error});
            }
          );
        } else if (file == "" || file == "removed") {
          uploadFiles[index].image = null;
          resolve();
        } else {
          uploadFiles[index].image = file;
          resolve();
        }

        if (n2 > 0) {
          var content = file2.split(";base64,")[1];
          var filename = `homebanner/${randomstring.generate()}.jpg`;
          // let filename = 'homebanner/mobile'+ ts + "." + "jpg"; // Image Name Creation
          var imageJson = {
            resource: [
              {
                name: filename,
                type: "file",
                is_base64: true,
                content: content,
              },
            ],
          };
          uploadEncodedFile(
            `${BASE_IMAGES_URL}?check_exist=false`,
            session,
            imageJson,
            dispatch,
            (data) => {
              let imagename = data.name;
              uploadFiles[index].mobileimage = imagename;
              // uploadFiles[index].image = data.name;//name changes for uploaded image in db
              resolve();
            },
            (error) => {
              // callback({success:2, message:error});
            }
          );
        } else if (file2 == "" || file2 == "removed") {
          uploadFiles[index].mobileimage = null;
          resolve();
        } else {
          uploadFiles[index].mobileimage = file2;
          resolve();
        }
      })
    );
  });

  Promise.all(promiseArray).then(() => {
    callback({ success: 1, data: uploadFiles });
  });
}

export function updateMainbodyForProduct(
  values,
  mainHomepagebody,
  index,
  callback
) {
  mainHomepagebody.data[index] = values;
  // console.log('mainHomepagebodymainHomepagebody',mainHomepagebody);
  return (dispatch) => {
    dispatch({
      type: MAIN_HOMEPAGE_BODY,
      payload: { data: mainHomepagebody.data },
    });
    callback({ success: 1 });
  };
}

export function reduxUpdateHomePagebody(update) {
  return {
    type: HOME_PAGEBODY_REDUX,
    payload: { updated: update },
  };
}

export function updateTempLayout(session, mainSaveHomePageDataArr, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/templayoutsave`;
  let body = { templayoutarr: mainSaveHomePageDataArr };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export function publishHomePage(session, mainSaveHomePageDataArr, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/publishhomelayout`;
  let body = { templayoutarr: mainSaveHomePageDataArr };
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export function deleteFormList(index, homePageBody, callback) {
  let oldRecord = [];

  homePageBody.splice(index, 1);

  oldRecord = homePageBody;
  // console.log('oldRecord',oldRecord);
  return (dispatch) => {
    dispatch({ type: MAIN_HOMEPAGE_BODY, payload: { data: oldRecord } });
    callback({ success: 1 });
  };
}
