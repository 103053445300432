import axios from "axios";
import {
  APP_API_KEY,
  PUBLIC_API_KEY,
  INSTANCE_URL,
  BASE_URL,
  LOGIN,
  REGISTER,
  FETCH_SPEAKERS,
  SELECT_FEATURE,
  itemCount,
} from "../../../../common/constants";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../../../common/network-call";
import LocaleStrings from "../../../languages";
import _ from "lodash";

export var ALLNEWORDER_LIST = "ALLNEWORDER_LIST";
export var ALLDELIVEREDORDER_LIST = "ALLDELIVEREDORDER_LIST";
export var IS_MESSAGING_SLIDEOVERS_OPEN = "IS_MESSAGING_SLIDEOVERS_OPEN";
export var ORDER_MESSAGING_DETAILS_LIST = "ORDER_MESSAGING_DETAILS_LIST";
export var THREAD_MESSAGE = "THREAD_MESSAGE";
export var ORDER_THREAD_BASICDATA = "ORDER_THREAD_BASICDATA";
export var ORDER_MESSAGING_STEP = "ORDER_MESSAGING_STEP";
export var STORING_SELLERID = "STORING_SELLERID";
export const ORDER_THREAD_MESSAGES = "ORDER_THREAD_MESSAGES"; // Order Messages with Thread ID

export function fetchallnewoderlist(
  session,
  status,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allorders`;
  let values = {};
  if (type === "listing") {
    // Paginated
    values.status = status;
    values.limit = limit;
    values.page = page;
    values.type = type;
  } else {
    // With out pagination [search result]
    values.status = status;
    values.type = type;
    values.searchterm = searchterm;
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (type === "listing") {
          // Paginated
          dispatch({
            type: ALLNEWORDER_LIST,
            payload: { data: response.orders, count: response.count },
          });
        } else {
          // With out pagination [search result]
          dispatch({
            type: ALLNEWORDER_LIST,
            payload: { data: response.orders },
          });
        }
        // // console.log('allproducts', data);
        // dispatch({
        //   type: ALLNEWORDER_LIST,
        //   payload: { data: data.orders },

        // });
        // console.log('data544',payload );
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}
export function fetchalldeliveredorderlist(
  session,
  status,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allorders`;
  let values = {};
  if (type === "listing") {
    // Paginated
    values.status = status;
    values.limit = limit;
    values.page = page;
    values.type = type;
  } else {
    // With out pagination [search result]
    values.status = status;
    values.type = type;
    values.searchterm = searchterm;
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('allproducts', data);
        if (type === "listing") {
          // Paginated
          dispatch({
            type: ALLDELIVEREDORDER_LIST,
            payload: { data: response.orders, count: response.count },
          });
        } else {
          // With out pagination [search result]
          dispatch({
            type: ALLDELIVEREDORDER_LIST,
            payload: { data: response.orders },
          });
        }
        // dispatch({
        //   type: ALLDELIVEREDORDER_LIST,
        //   payload: { data: data.orders },

        // });
        // console.log('data544',payload );
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}

/**** Orders Details Status update Superadmin side *****/
export function updateorder(session, dataObj, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/updateorder`;

  return (dispatch) => {
    postRequest(
      url,
      dataObj,
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/***** MESSAGING Slide-overs open or not *****/
export function isSendMessageModalOpen(isOpen) {
  return { type: IS_MESSAGING_SLIDEOVERS_OPEN, payload: isOpen };
}

/* FETCH ORDER MESSAGE */
export function fetchOrderMessageList(session, sellerid, callback) {
  //const url = `${INSTANCE_URL}/api/v2/shopez/_table/order_thread?related=*&fields=*&filter=firstuserid=${session.userid}&order=id%20DESC`;
  const url = `${INSTANCE_URL}/api/v2/superadmin-datascript/orderthreads`;
  let body = { profileid: sellerid };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ORDER_MESSAGING_DETAILS_LIST,
          payload: { data: response.threads },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/* Update SOME BASIC DATA */
export function updateBasicOrderthreadData(data) {
  return (dispatch) => {
    dispatch({ type: ORDER_THREAD_BASICDATA, payload: data });
  };
}

/* Update current step for order message thread [1 -> all thread list 2 means messgae list]*/
export function updateOrderStep(step) {
  return (dispatch) => {
    dispatch({ type: ORDER_MESSAGING_STEP, payload: step });
  };
}

/**** Get Order Messages With thread id ****/
export function fetchOrderThreadMsgList(session, threadid, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/order_messages?realated=*&fields=*&filter=threadid=${threadid}`;

  // Actual Data Call
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ORDER_THREAD_MESSAGES,
          payload: { data: response?.resource },
        });

        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/****  Get single thread data to into basic thread details ****/
export function getOrderThreadWithId(session, threadid, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/orderthreadmessages`;
  let body = {
    threadid: threadid,
  };

  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: response });
      },
      (error) => {
        // callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Send Order message with Thread ID */
export function sendOrderMessageToStore(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/order_messages`;
  var body = { resource: values };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.resource && response.resource.length > 0) {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

/* If not created create and open order thread */
export function createOrOpenOrderThread(
  session,
  orderId,
  buyerId,
  sellerid,
  callback
) {
  let { userid } = session;
  userid = parseInt(userid);
  buyerId = parseInt(buyerId);
  sellerid = parseInt(sellerid);

  var url = `${INSTANCE_URL}/api/v2/shopez/_table/order_thread?fields=*&filter=((firstuserid=${sellerid})AND(seconduserid=${buyerId})AND(orderid=${orderId}))`;

  // First check if the thread is created or not
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (_.size(response.resource) > 0) {
          // Thread already created
          callback({
            status: 1,
            threadavailable: true,
            message: response.resource[0],
          });
        } else {
          // Create a order thread
          dispatch(
            createOrderThread(session, orderId, buyerId, sellerid, (res) => {
              if (res.status === 1) {
                callback({
                  status: 1,
                  threadavailable: false,
                  message: res?.message?.resource[0],
                });
              } else {
                callback({
                  status: 0,
                  message: LocaleStrings.something_went_wrong,
                });
              }
            })
          );
        }
      },
      (error) => {
        // callback({ status: 0, message: 'LocaleStrings.something_went_wrong' });
      }
    );
  };
}

/* Create thread for order */
export function createOrderThread(
  session,
  orderId,
  buyerId,
  sellerid,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/order_thread`;
  let object = {
    firstuserid: sellerid,
    seconduserid: buyerId,
    orderid: orderId,
  };
  let body = { resource: object };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: response });
      },
      (error) => {
        // callback({ status: 0, message: error });
      }
    );
  };
}

/* Update current step for order message thread [1 -> all thread list 2 means messgae list]*/
export function selleridStore(sellerid) {
  return (dispatch) => {
    dispatch({ type: STORING_SELLERID, payload: { sellerid: sellerid } });
  };
}

export function updateinitiateRefund(session, values, callback) {
  // var url = `${INSTANCE_URL}/api/v2/fatorapay`;old
  var url = `${INSTANCE_URL}/api/v2/skipcashpay`; //skipcash

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.success == true) {
          callback({ status: 1 });
        } else {
          callback({ status: 0 });
        }
      },
      (error) => {}
    );
  };
}

export function partnerScriptorder(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/partnerscript/createorder`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {}
    );
  };
}

export function downloadorderInvoice(session, values, callback) {
  // var url = `${INSTANCE_URL}/api/v2/vendor-datascript/downloadinvoice`;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/downloadinvoice`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.success == true) {
          callback({ success: 1, data: response });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {}
    );
  };
}
