import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import { Field, reduxForm } from "redux-form";
import { BaseComponent, number } from "../../../../../../common/base-component";
import toast from "react-hot-toast";
import {
  updateCommisionSeller,
  fetchSeller,
  updateSellerProductsRefund,
} from "../../../actions/index";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { classNames } from "../../../../../../common/common-functions";

class SellerRefund extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      setOpen: false,
    };
  }

  onFormSubmit = (values) => {
    // console.log("val", values);
    this.setState({ btnLoader: true });
    var data = {};
    var productvalues = {};
    if (values.isshippingcostbear == true) {
      data.isshippingcostbear = 1;
      data.userid = values.userid;
      this.props.updateCommisionSeller(this.props.session, data, (response) => {
        if (response.success === 0) {
          toast.error(LocaleStrings.common_cannotupdate);
          this.setState({ btnLoader: false });
        } else {
          NotificationManager.success(LocaleStrings.common_updated);
          this.setState({ btnLoader: false });
          this.props.fetchSeller(
            this.props.session,
            0,
            (CallBackResponse) => {}
          );
        }
      });
    } else {
      data.isshippingcostbear = 0;
      data.userid = values.userid;
      productvalues.shippingcostbearer = 1;
      this.props.updateSellerProductsRefund(
        this.props.session,
        productvalues,
        data,
        (response) => {
          // console.log("response:-->", response);
          if (response.success === 1) {
            this.props.updateCommisionSeller(
              this.props.session,
              data,
              (response) => {
                if (response.success === 0) {
                  toast.error("Not updated");
                  this.setState({ btnLoader: false });
                } else {
                  NotificationManager.success("Successfully refund updated!");
                  this.setState({ btnLoader: false });
                  this.props.fetchSeller(
                    this.props.session,
                    0,
                    (CallBackResponse) => {}
                  );
                }
              }
            );
          } else {
            toast.error("Not updated");
          }
        }
      );
    }
  };

  render() {
    var { data, handleSubmit } = this.props;
    // console.log("valuesdatarender", this.props.session);
    return (
      <>
        <div className="">
          <form className=" " onSubmit={handleSubmit(this.onFormSubmit)}>
            <div className="flow-root px-10 py-5 bg-custWhite rounded">
              <p className="mb-3 center-item" style={{ fontSize: "20px" }}>
                {LocaleStrings.seller_management_refundSeller}
              </p>
              <div className="mt-5 center-item">
                <Field
                  name="isshippingcostbear"
                  label="Refund By Shopez"
                  component={this.renderFieldCheckbox}
                  type="checkbox"
                  className="cust-check-box"
                  classNameLabel="text-box-lable"
                />
              </div>

              <div className="center-item my-16">
                <button type="submit" className="bigbutton">
                  <svg
                    className={classNames(
                      this.state.btnLoader ? "" : "sr-only",
                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  {LocaleStrings.common_submit}
                </button>
              </div>
            </div>
          </form>
          <NotificationContainer />
        </div>
      </>
    );
  }
}

function validate(val) {
  // console.log("form data", values);
  var errors = {};
  var commission_perrefund = val["commission_perrefund"];
  var commission_perorder = val["commission_perorder"];
  if (commission_perrefund && number(commission_perrefund)) {
    errors["commission_perrefund"] = "Please enter a number";
  }
  if (commission_perorder && number(commission_perorder)) {
    errors["commission_perorder"] = "Please enter a number ";
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  return {
    session: state.session,
    data: ownProps.data,
    initialValues: ownProps.data,
  };
}

export default connect(mapStateToProps, {
  updateCommisionSeller,
  fetchSeller,
  updateSellerProductsRefund,
})(
  reduxForm({
    validate,
    form: "CommissionForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(SellerRefund)
);
