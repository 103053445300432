import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  fetchRejectedNegotiationDay,
  downloadReports,
} from "../../../actions/index";
import RejectedNegoitems from "./rejectednego-items";
import {
  classNames,
  converDateIntoLocal,
} from "../../../../../../common/common-functions";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../../common/base-component";
import { DATE_FORMAT } from "../../../../../../common/constants";
import moment from "moment";

class RejectedNegoToday extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.specs_management,
      SpecsSearchText: "",
      pageNumber: 1,
      btnLoader: false,
      isBtnDisable: false,
      startdate: "",
      enddate: "",
      btnfilterLoader: false,
    };
    this.onSubmitForm = this.onSubmitForm.bind(this);
    // All Binded Functions
  }

  componentDidMount() {
    // this.props.fetchRejectedNegotiationDay(this.props.session, (CallBackResponse) => {
    //   this.setState({ loading: false });
    // });
    let obj = {};

    var date = new Date();
    date.setDate(date.getDate());
    let initialStartdates = converDateIntoLocal(date).format("YYYY-MM-DD");
    let today = new Date();
    let initialEnddates = converDateIntoLocal(today).format("YYYY-MM-DD");

    obj.type = "rejectednegotiations";
    obj.startdate = initialStartdates;
    obj.enddate = initialEnddates;

    this.setState({ startdate: initialStartdates, enddate: initialEnddates });
    this.props.fetchRejectedNegotiationDay(
      this.props.session,
      obj,
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  downloadDashBoardReport = (e) => {
    this.setState({ btnLoader: true, isBtnDisable: true });
    // let type = "rejectednegotiations";
    // let tab = "today";
    let obj = {};
    obj.type = "rejectednegotiations";
    obj.startdate = this.state.startdate;
    obj.enddate = this.state.enddate;
    obj.generate = true;

    this.props.downloadReports(this.props.session, obj, (callBack) => {
      if (callBack.success == 0) {
        NotificationManager.error("Something went Wrong");
      } else {
        window.location.href = callBack.message;
      }
      this.setState({ btnLoader: false, isBtnDisable: false });
    });
  };

  onSubmitForm(values) {
    if (!values.hasOwnProperty("to") || !values.hasOwnProperty("from")) {
      NotificationManager.error("Please add both dates to filter matches");
      return false;
    }
    this.setState({ btnfilterLoader: true });
    let to = moment(values.to).format(DATE_FORMAT);
    let from = moment(values.from).format(DATE_FORMAT);
    let obj = {};
    obj.type = "rejectednegotiations";
    obj.startdate = from;
    obj.enddate = to;

    this.props.fetchRejectedNegotiationDay(
      this.props.session,
      obj,
      (response) => {
        this.setState({ btnfilterLoader: false });
        this.setState({ filter: true });
      }
    );
  }

  onFilterChange = (e, stateChange) => {
    if (stateChange == "from ") {
      let initialStartdates = converDateIntoLocal(e).format("YYYY-MM-DD");
      this.setState({ startdate: initialStartdates });
    } else {
      let initialEnddates = converDateIntoLocal(e).format("YYYY-MM-DD");
      this.setState({ startdate: initialEnddates });
    }
  };

  renderList() {
    var items = _.map(this.props.allrejectednegoToday.data, (values, index) => (
      <RejectedNegoitems key={index} values={values} />
    ));
    return items;
  }

  render() {
    let { handleSubmit } = this.props;
    return (
      <>
        <form
          className=""
          onSubmit={handleSubmit(this.onSubmitForm)}
          encType="multipart/form-data">
          <div className="flex">
            <div className="text-ternary  group flex items-center px-3 py-2 text-sm font-medium rounded-md">
              {" "}
              {LocaleStrings.rejectednegotiation}
            </div>
            <div className="pr-3 flex" style={{ marginLeft: "auto" }}>
              <div className="my-5 flex">
                <p className="pr-3 pt-2">From</p>

                <Field
                  name="from"
                  component={this.renderDatePickerWithLabel1}
                  mandatory={true}
                  autoComplete="off"
                  autoFocus="off"
                  // selected={moment()}

                  className="cust-input-field"
                  classNameLabel="text-box-lable"
                  onChange={(e) => this.onFilterChange(e, "from")}
                />
              </div>
              <div className="my-5 mx-5 flex">
                <p className="pr-3 pt-2">To</p>

                <Field
                  name="to"
                  component={this.renderDatePickerWithLabel1}
                  mandatory={true}
                  autoComplete="off"
                  autoFocus="off"
                  // selected={moment()}
                  // minDate={new Date(moment().format("YYYY,MM,DD"))}
                  className="cust-input-field"
                  classNameLabel="text-box-lable"
                  onChange={(e) => this.onFilterChange(e, "to")}
                />
              </div>
              <div className="my-5">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <svg
                    className={classNames(
                      this.state.btnfilterLoader ? "" : "sr-only",
                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  Filter
                </button>
              </div>
            </div>
          </div>
        </form>
        {this.props.allrejectednegoToday.data == 0 ? (
          <>
            {" "}
            <div className="center-item pt-24 text-lg text-ternary">
              {LocaleStrings.dashboard_Day_noData}
            </div>
          </>
        ) : (
          <>
            {" "}
            <div
              className="grid gap-4 grid-rows-1 px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 mt-5 dashboard-tablescroll scrollbar-hide"
              style={{
                overflowX: "hidden",
                display: "block",
              }}>
              <div className="flex flex-col pb-2">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full ">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-custWhite">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {LocaleStrings.product}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {LocaleStrings.actualprice}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {LocaleStrings.negotiatedprice}
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.renderList()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div style={{display:'flex',justifyContent:'center'}}>
              
              <button
                type="button"
                disabled={this.state.isBtnDisable}
                className={classNames(
                  this.state.isBtnDisable ? "cursor-not-allowed" : "",
                  "button flex"
                )}
                onClick={(e) => this.downloadDashBoardReport()}
              >
                <svg
                  className={classNames(
                    this.state.btnLoader ? "" : "sr-only",
                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                  )}
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  data-icon="loading"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
                {LocaleStrings.common_downloadReport}
              </button>
              
              </div> */}
            </div>
            {/* <NotificationContainer /> */}
          </>
        )}
        {this.props.allrejectednegoToday.data == 0 ? (
          ""
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "20px",
            }}>
            <button
              type="button"
              disabled={this.state.isBtnDisable}
              className={classNames(
                this.state.isBtnDisable ? "cursor-not-allowed" : "",
                "button flex"
              )}
              onClick={(e) => this.downloadDashBoardReport()}>
              <svg
                className={classNames(
                  this.state.btnLoader ? "" : "sr-only",
                  "animate-spin-medium h-5 w-5 rounded-full mx-2"
                )}
                viewBox="0 0 1024 1024"
                focusable="false"
                data-icon="loading"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true">
                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
              </svg>
              {LocaleStrings.common_downloadReport}
            </button>
          </div>
        )}

        <NotificationContainer />
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  let initvals = {};
  var date = new Date();
  date.setDate(date.getDate());
  let initialStartdates = converDateIntoLocal(date).format("YYYY-MM-DD");
  let today = new Date();
  let initialEnddates = converDateIntoLocal(today).format("YYYY-MM-DD");
  initvals.from = initialStartdates;
  initvals.to = initialEnddates;

  return {
    session,
    user,
    allrejectednegoToday: state.allrejectednegoToday,
    initialValues: initvals,
  };
}

// export default connect(mapStateToProps, {
//   fetchRejectedNegotiationDay,
//   downloadReports
// })(RejectedNegoToday);

export default connect(mapStateToProps, {
  fetchRejectedNegotiationDay,
  downloadReports,
})(
  reduxForm({
    form: "RejectedNegoTodayForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(RejectedNegoToday)
);
