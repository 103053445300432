import React, { Component } from "react";
import { connect } from "react-redux";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../../currency/action/index";

import _ from "lodash";

class CompletedAuctionitems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    var { values, localCurrency } = this.props;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 cursor-pointer hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.productdetail.productname_en}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.user.username}
          </td>

          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {" "}
            {currency} {values.bidprice.toFixed(2)}
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    localCurrency: state.currency,
  };
}
export default connect(mapStateToProps, { isCurrencyRateApplicable })(
  CompletedAuctionitems
);
