import { INSTANCE_URL } from "../../../../common/constants";
import { postRequest } from "../../../../common/network-call";

export var ALL_FEEDS = "ALL_FEEDS";

export function fetchFeeds(session, pageNumber, callback) {
  var perPage = 10;
  var offset = pageNumber;

  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/activities`;
  return (dispatch) => {
    postRequest(
      url,
      {
        usertype: "admin",
        type: "listing",
        limit: 10,
        page: offset,
      },
      session,
      dispatch,
      (data) => {
        // console.log("SPONSOR: ", data);
        dispatch({
          type: ALL_FEEDS,
          payload: { data: data.data, count: data.count },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchFeeds(session, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/activities`;

  return (dispatch) => {
    // dispatch(fetchFeeds(session));
    postRequest(
      url,
      {
        usertype: "admin",
        type: "search",
        searchterm: search,
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: ALL_FEEDS,
          payload: { data: data.data },
        });
      },
      (error) => {}
    );
  };
}
