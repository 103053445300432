import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import SellerTransactionListItems from "./sellertransaction-items";
import BaseLoader from "../../../../../../common/base-loader";
import Pagination from "react-js-pagination";
import {
  fetchTransactionSeller,
  searchSellerTransaction,
} from "../../../actions/index";
import _ from "lodash";

var Loader = require("react-loaders").Loader; // import "AwesomeLoaderCss";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Sellertransaction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaderDesc: LocaleStrings.transaction,
      pageNumber: 1,
      search: "",
    };

    // All Binded Functions
  }

  componentDidMount(data) {
    this.props.fetchTransactionSeller(
      this.props.session,
      this.props.data.userid,
      this.state.pageNumber,
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }
  paginationCallback = (pageNumber) => {
    // alert(pageNumber)
    this.props.fetchTransactionSeller(
      this.props.session,
      this.props.data.userid,
      pageNumber,
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchTransactionSeller(
        this.props.session,
        this.props.data.userid,
        this.state.pageNumber,
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchSellerTransaction(
        this.props.session,
        this.props.data.userid,
        search
      );
    }
    this.setState({ search, pageNumber });
  };
  renderList() {
    let pageDataLength = this.props.sellerTransactionList.data.length;

    let { pageNumber } = this.state;
    var items = _.map(this.props.sellerTransactionList.data, (values) => (
      <SellerTransactionListItems
        key={values.userid}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {_.isEmpty(this.props.sellerTransactionList.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.notransactionadded}
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col my-10">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.transactionid}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.transactionamount}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.product}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.type}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.transactiondate}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.status}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.renderList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Pagination
                    activePage={this.state.pageNumber}
                    itemsCountPerPage={10}
                    totalItemsCount={this.props.sellerTransactionList.count}
                    pageRangeDisplayed={10}
                    onChange={this.paginationCallback}
                  />
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state, ownProps) {
  // console.log('statesss', state.sellerTransactionList);
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    data: ownProps.data,
    sellerTransactionList: state.sellerTransactionList,
  };
}

export default connect(mapStateToProps, {
  fetchTransactionSeller,
  searchSellerTransaction,
})(Sellertransaction);
