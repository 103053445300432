import { combineReducers } from "redux";
import { loadState, reset } from "../localStorage";
import { reducer as formReducer } from "redux-form";
import { LOGIN, APP_API_KEY } from "../common/constants.js";

import {
  ACTION_LOGOUT,
  SESSION_TOKEN,
  USER_ID,
  USER_NAME,
  USER_TYPE,
  USER_EMAIL,
  USER_PICTURE,
  IS_APPROVE,
  IS_ACTIVE,
  IS_VENDORTOO,
  CAN_RECEIVE_NOTIFICATION,
  DEVICE_SIZE,
  IS_FORGOT_PASSWORD_OPEN,
  LOCALE_LANGUAGE,
  SET_CURRENCY,
  USER_PHONE,
  COUNTRY_PHONE_LENGTH,
} from "../actions";

import {
  SidebarSelectedMenu,
  SideBarMenus,
  notificationList,
  customNotification,
  all_users,
  isSlideropen,
} from "../components/dashboard/sidebar/reducers/index";

import {
  customermanagementTabs,
  OderTabs,
  NegotiationTabs,
  AuctionTabs,
  customerlist,
  wishlistlist,
  customerlistingtabs,
  newtablist,
  negotiationlist,
  customereditmodal,
  oderlist,
  oderlistDelivered,
  oderlistDisputed,
  watchlist,
  auctionList,
  rejectedNegotiation,
  transactionList,
  orderMasterList,
  customerauctionwonList,
  singleCustomerData,
} from "../components/dashboard/customermanagement/reducers/index";
import {
  sellermanagementTabs,
  sellerlist,
  newtabsellerlist,
  sellerlistingtabs,
  sellereditmodal,
  selleraboutTabs,
  sellerproductTabs,
  selleroderTabs,
  sellernegotiationTabs,
  sellerproductlist,
  sellernewoderlist,
  sellerdeliveredoderlist,
  sellerdisputedoderlist,
  sellernegotiationlist,
  sellernegotiationlistinitiated,
  sellerstoredetails,
  sellernegotationdetails,
  isNegotiationopen,
  sellerdisputedorderTabs,
  StoreContacts,
  sellerSimilarProducts,
  isCommentsOpen,
  commentList,
  sellerTransactionList,
  forSaleProductsList,
  forAuctionProductList,
  isLikesopen,
  productlikesData,
  sellernegotiationlistclosed,
  AuctionTabsseller,
  sellerAuctionlist,
  sellerAuctionlistwon,
  verifiedUnverifiedsellerTabs,
  singleSellerData,
} from "../components/dashboard/sellermanagement/reducers/index";
import {
  ProductManagementTabs,
  allproductslist,
  directSaleproductslist,
  auctionproductslist,
  unapprovedProducList,
} from "../components/dashboard/productmanagements/reducers/index";
import {
  OrderMangementmainTabs,
  allneworderlist,
  alldeliveredorderlist,
  isMessageOpen,
  orderMessageList,
  currentOrderStep,
  basicOrderThreadData,
  storeOrdMessageList,
  storingsellerid,
} from "../components/dashboard/ordermanagement/reducers/index";
import {
  categorylist,
  subcategorylist,
  editablecategorylist,
  editablesubcategorylist,
  subcategorymodalstore,
  productUnderCategory,
  categoryDropdownarr,
  categoryDropdownarr2,
} from "../components/dashboard/categorymanagement/reducers/index";
import {
  DisputeMangementmainTabs,
  allcancelledorderlist,
  allreturnedorderlist,
  allreplaceorderlist,
  auctionsitemnotboughtList,
  ordersnotProcessedlist,
} from "../components/dashboard/disputemanagement/reducers/index";
import {
  specificationlisting,
  specificationProductCategory,
  specificationProductSubCategory,
  specificationProductSubSubCategory,
  specificationProductByCateSubCate,
  isSpecsManageModalopen,
  Golbalspecification,
  Isspecificationmandatory,
  dataforeditspecs,
  specssingleDiscountObject,
} from "../components/dashboard/specsmanagement/reducers/index";
import {
  DiscountandCouponTabs,
  DiscountTabs,
  DiscountMasterStore,
  ProductCategory,
  ProductSubCategory,
  CriteriaMaster,
  DiscountArea,
  ProductByCateSubCate,
  isDiscManageModalopen,
  singleDiscountObject,
  CouponMasterStore,
  isCouponManageModalopen,
  CouponsTabs,
  storedCouponsTab,
  singleCouponObject,
  allProducts,
} from "../components/dashboard/discountmanagement/reducers/index";
import {
  AllglobalTabs,
  Taxtablist,
  global_commissionlist,
  Faqtablist,
  Faqfor,
  allfaq_list,
  edit_faqs,
  all_deliveryagentList,
  ServicesAvailable,
  allAdmin_List,
  Edit_superadmin,
  globalRestrictedWords,
  Edit_restricted_words,
  Edit_delivery_agents,
  shippingCharges,
  DummyDistricts,
  DummyPin_codes,
  listngfeetype,
  editCharges,
  allvendorfaq_list,
  ContentTabs,
  globalRestrictedFile,
  otherfeeTabs,
  zonemasterList,
  zoneorPin,
  charityeventList,
  editcharityevents,
  allModeratorrolelist,
} from "../components/dashboard/globalsettings/reducers/index";
import {
  TicketReportTabs,
  allTickets_listing,
  allReports_listing,
  Appsuggestion_list,
  ticketandsuggestionReply,
  Reports_allview,
  Reply_tosuggestion,
} from "../components/dashboard/ticketsreport/reducers/index";
import { Allfeeds_lists } from "../components/dashboard/feedsmanagement/reducers/index";
import {
  allDasboardStats,
  TimeSpanTabs,
  allusersToday,
  allusersThisweek,
  allusersThismonth,
  allvendorsToday,
  allvendorsThisweek,
  allvendorsThismonth,
  allproductbaseToday,
  allproductbaseThisweek,
  allproductbaseThismonth,
  allnewordersToday,
  allnewordersWeek,
  allnewordersMonth,
  allongoingordersToday,
  allongoingordersWeek,
  allongoingordersMonth,
  allcompletedordersToday,
  allcompletedordersWeek,
  allcompletedordersMonth,
  allcancelledordersToday,
  allcancelledordersWeek,
  allcancelledordersMonth,
  allreplacedordersToday,
  allreplacedordersWeek,
  allreplacedordersMonth,
  allpendingnegoToday,
  allpendingnegoWeek,
  allpendingnegoMonth,
  allacceptednegoToday,
  allacceptednegoWeek,
  allacceptednegoMonth,
  allrejectednegoToday,
  allrejectednegoWeek,
  allrejectednegoMonth,
  allactiveauctionToday,
  allactiveauctionWeek,
  allactiveauctionMonth,
  allcompletedauctionToday,
  allcompletedauctionWeek,
  allcompletedauctionMonth,
  allpaymentsToday,
  allpaymentsWeek,
  allpaymentsMonth,
  DashboardStatsTab,
  StaticticscommonDatePropsReducer,
  MonthOptions,
  chartValues,
  mostactiveusers,
  mostactiveproducts,
  mostactivevendors,
} from "../components/dashboard/dasboardside/reducers/index";
import {
  allVariation,
  variationdata,
  mastervariationProductCat,
  mastervariationProductsubCat,
} from "../components/dashboard/mastervariation/reducers/index";
import {
  alltransactions,
  transactionmanagementTabs,
  payoutDatalist,
  payoutTabs,
  unpaidpayoutDatalist,
  charitypayoutDatalist,
} from "../components/dashboard/transactionmanagement/reducers/index";
import {
  allAddListing,
  addProductByCateSubCate,
  addProductCategory,
  addProductSubCategory,
  NewAddArea,
  positionAdd,
  EditAdds,
  whereTo,
  allproductforAds,
  isaddModalopen,
} from "../components/dashboard/addsmanagement/reducers/index";
import {
  productCard_type,
  product_Limit,
  productType,
  allproductList,
  headerView,
  linkImageTo,
  allcategoryList,
  allsubcategoryList,
  homePageMainheader,
  homePageBody,
  headerDataupdate,
  productDataupdate,
  logicalProductadd,
  bannerDataupdate,
  bannerType,
  homePagebodyRedux,
  homePagemaindata,
} from "../components/dashboard/homepagedesign/reducers/index";

var LoginReducer = (state = true, action) => {
  // need to change it to false
  var state = loadState();

  if (state === undefined) {
    state = {
      isLoggedIn: false,
    };
  }

  if (action.type === LOGIN) {
    state.isLoggedIn = action.payload;
  }

  if (action.type === ACTION_LOGOUT) {
    state.isLoggedIn = action.payload;
  }

  return state.isLoggedIn;
};

// stores Data for session also on localstorage
var TokensReducer = (state = {}, action) => {
  var state = loadState();

  if (state === undefined || state.session === undefined) {
    state = {
      session: {
        apiKey: APP_API_KEY,
        sessionToken: "",
        emailid: "",
        userid: "",
        usertype: "",
      },
    };
  }

  if (action.type === SESSION_TOKEN) {
    if (action.payload.session_token) {
      state.session.sessionToken = action.payload.session_token;
    }
    state.session.emailid = action.payload.email;
    state.session.usertype = action.payload.usertype;
    state.session.userid = action.payload.userid;
  }

  if (action.type === ACTION_LOGOUT) {
    state.session.sessionToken = "";
    state.session.emailid = "";
    state.session.userid = "";
    state.session.usertype = "";
    reset();
  }

  return state.session;
};

// Stores User Related data on localstorage
var UserReducer = (state = {}, action) => {
  var state = loadState();
  if (state === undefined || state.user === undefined) {
    state = {
      user: {
        userid: "",
        username: "",
        usertype: "",
        userimage: "",
        email: "",
        phone: "",
        isapprove: false,
        isactive: false,
        isvendortoo: false,
        canreceivenotification: false,
      },
    };
  }

  // DF user id
  if (action.type === USER_ID) {
    state.user.userid = action.payload;
  }

  if (action.type === USER_NAME) {
    state.user.username = action.payload;
  }
  if (action.type === USER_PHONE) {
    state.user.phone = action.payload;
  }
  if (action.type === USER_TYPE) {
    state.user.isSuperAdmin = action.payload;
  }

  if (action.type === USER_PICTURE) {
    state.user.userimage = action.payload;
  }

  if (action.type === USER_EMAIL) {
    state.user.email = action.payload;
  }

  if (action.type === IS_APPROVE) {
    state.user.isapprove = action.payload;
  }
  if (action.type === IS_ACTIVE) {
    state.user.isactive = action.payload;
  }
  if (action.type === IS_VENDORTOO) {
    state.user.isvendortoo = action.payload;
  }
  if (action.type === CAN_RECEIVE_NOTIFICATION) {
    state.user.canreceivenotification = action.payload;
  }

  if (action.type === ACTION_LOGOUT) {
    state.user.userid = "";
    state.user.username = "";
    state.usertype = "";
    state.user.userimage = "";
    state.user.email = "";
    state.user.phone = "";
    state.user.isapprove = false;
    state.user.isactive = false;
    state.user.isvendortoo = false;
    state.user.canreceivenotification = false;
  }

  return state.user;
};

var phoneLengthCountry = (state = "974", action) => {
  if (action.type === COUNTRY_PHONE_LENGTH) {
    state = action.payload;
  }

  return state;
};

// Is Forgot password modal open
export var isForgotPasswordOpen = (state = false, action) => {
  if (action.type === IS_FORGOT_PASSWORD_OPEN) {
    state = action.payload;
  }

  return state;
};
// Locale Language change
export var localeLanguage = (state = "en", action) => {
  if (action.type === LOCALE_LANGUAGE) {
    state = action.payload;
  }

  return state;
};

export var localCurrency = (state = "QAR", action) => {
  if (action.type === SET_CURRENCY) {
    state = action.payload;
  }
  return state;
};

var rootReducer = combineReducers({
  isLoggedIn: LoginReducer,
  session: TokensReducer,
  user: UserReducer,
  isForgotPasswordOpen: isForgotPasswordOpen,
  language: localeLanguage,
  currency: localCurrency,
  form: formReducer,
  sidebarSelectedMenu: SidebarSelectedMenu,
  customermanagementTabs: customermanagementTabs,
  OderTabs: OderTabs,
  NegotiationTabs: NegotiationTabs,
  AuctionTabs: AuctionTabs,
  customerlist: customerlist,
  wishlistlist: wishlistlist,
  customerlistingtabs: customerlistingtabs,
  newtablist: newtablist,
  negotiationlist: negotiationlist,
  customereditmodal: customereditmodal,
  oderlist: oderlist,
  oderlistDelivered: oderlistDelivered,
  oderlistDisputed: oderlistDisputed,
  watchlist: watchlist,
  auctionList: auctionList,
  customerauctionwonList: customerauctionwonList,
  rejectedNegotiation: rejectedNegotiation,
  transactionList: transactionList,
  orderMasterList: orderMasterList,
  singleCustomerData: singleCustomerData,

  // Seller
  sellerlist: sellerlist,
  sellermanagementTabs: sellermanagementTabs,
  newtabsellerlist: newtabsellerlist,
  sellerlistingtabs: sellerlistingtabs,
  sellereditmodal: sellereditmodal,
  selleraboutTabs: selleraboutTabs,
  sellerproductTabs: sellerproductTabs,
  selleroderTabs: selleroderTabs,
  sellernegotiationTabs: sellernegotiationTabs,
  sellerproductlist: sellerproductlist,
  sellernewoderlist: sellernewoderlist,
  sellerdeliveredoderlist: sellerdeliveredoderlist,
  sellerdisputedoderlist: sellerdisputedoderlist,
  vendorNegotiationListStore: sellernegotiationlist,
  sellernegotiationlistinitiated: sellernegotiationlistinitiated,
  sellernegotiationlistclosed: sellernegotiationlistclosed,
  sellerstoredetails: sellerstoredetails,
  negotiationDetails: sellernegotationdetails,
  isNegotiationopen: isNegotiationopen,
  sellerdisputedorderTabs: sellerdisputedorderTabs,
  StoreContacts: StoreContacts,
  sellerSimilarProducts: sellerSimilarProducts,
  isCommentsOpen: isCommentsOpen,
  commentList: commentList,
  sellerTransactionList: sellerTransactionList,
  forSaleProductsList: forSaleProductsList,
  forAuctionProductList: forAuctionProductList,
  isLikesopen: isLikesopen,
  productlikesData: productlikesData,
  AuctionTabsseller: AuctionTabsseller,
  sellerAuctionlist: sellerAuctionlist,
  sellerAuctionlistwon: sellerAuctionlistwon,
  verifiedUnverifiedsellerTabs: verifiedUnverifiedsellerTabs,
  singleSellerData: singleSellerData,

  // ProductManagement
  ProductManagementTabs: ProductManagementTabs,
  allproductslist: allproductslist,
  directSaleproductslist: directSaleproductslist,
  auctionproductslist: auctionproductslist,
  unapprovedProducList: unapprovedProducList,

  // OrderManagement
  OrderMangementmainTabs: OrderMangementmainTabs,
  allneworderlist: allneworderlist,
  alldeliveredorderlist: alldeliveredorderlist,
  // message
  isMessageOpen: isMessageOpen,
  orderMessageList: orderMessageList,
  currentOrderStep: currentOrderStep,
  basicOrderThreadData: basicOrderThreadData,
  storeOrdMessageList: storeOrdMessageList,
  storingsellerid: storingsellerid,

  // Category Management
  categorylist: categorylist,
  subcategorylist: subcategorylist,
  editablecategorylist: editablecategorylist,
  editablesubcategorylist: editablesubcategorylist,
  subcategorymodalstore: subcategorymodalstore,
  productUnderCategory: productUnderCategory,
  categoryDropdownarr: categoryDropdownarr,
  categoryDropdownarr2: categoryDropdownarr2,

  // Ads Management
  allAddListing: allAddListing,
  addProductByCateSubCate: addProductByCateSubCate,
  addProductCategory: addProductCategory,
  addProductSubCategory: addProductSubCategory,
  NewAddArea: NewAddArea,
  positionAdd: positionAdd,
  EditAdds: EditAdds,
  whereTo: whereTo,
  allproductforAds: allproductforAds,
  isaddModalopen,

  // Dispute Management
  DisputeMangementmainTabs: DisputeMangementmainTabs,
  allcancelledorderlist: allcancelledorderlist,
  allreturnedorderlist: allreturnedorderlist,
  allreplaceorderlist: allreplaceorderlist,
  auctionsitemnotboughtList: auctionsitemnotboughtList,
  ordersnotProcessedlist: ordersnotProcessedlist,

  // Specification Management
  specificationlisting: specificationlisting,
  specsproductCategory: specificationProductCategory,
  specsproductSubCategory: specificationProductSubCategory,
  specificationProductSubSubCategory: specificationProductSubSubCategory,
  specsproductByCateSubCate: specificationProductByCateSubCate,
  isSpecsManageModalopen: isSpecsManageModalopen,
  Golbalspecification: Golbalspecification,
  Isspecificationmandatory: Isspecificationmandatory,
  dataforeditspecs: dataforeditspecs,
  specssingleDiscountObject: specssingleDiscountObject,

  // DiscountandCoupon

  DiscountandCouponTabs: DiscountandCouponTabs,
  DiscountTabs: DiscountTabs,
  // Discount Management
  discountMasterStore: DiscountMasterStore,
  productCategory: ProductCategory,
  productSubCategory: ProductSubCategory,
  criteriaMaster: CriteriaMaster,
  discountArea: DiscountArea,
  productByCateSubCate: ProductByCateSubCate,
  isDiscManageModalopen: isDiscManageModalopen,
  singleDiscountObject: singleDiscountObject,
  //  Coupons
  couponMasterStore: CouponMasterStore,
  isCouponManageModalopen: isCouponManageModalopen,
  couponsTabs: CouponsTabs,
  storedCouponsTab: storedCouponsTab,
  singleCouponObject: singleCouponObject,
  allProducts: allProducts,

  // Home design management

  productCard_type: productCard_type,
  product_Limit: product_Limit,
  productType: productType,
  allproductList: allproductList,
  allcategoryList: allcategoryList,
  allsubcategoryList: allsubcategoryList,
  headerView: headerView,
  linkImageTo: linkImageTo,
  homePageMainheader: homePageMainheader,
  homePageBody: homePageBody,
  headerDataupdate: headerDataupdate,
  productDataupdate: productDataupdate,
  logicalProductadd: logicalProductadd,
  bannerDataupdate: bannerDataupdate,
  bannerType: bannerType,
  homePagebodyRedux: homePagebodyRedux,
  homePagemaindata: homePagemaindata,

  // Feed Management
  Allfeeds_lists: Allfeeds_lists,

  // Global Setting
  AllglobalTabs: AllglobalTabs,
  Taxtablist: Taxtablist,
  global_commissionlist: global_commissionlist,
  Faqtablist: Faqtablist,
  Faqfor: Faqfor,
  allfaq_list: allfaq_list,
  allvendorfaq_list: allvendorfaq_list,
  edit_faqs: edit_faqs,
  all_deliveryagentList: all_deliveryagentList,
  ServicesAvailable: ServicesAvailable,
  allAdmin_List: allAdmin_List,
  Edit_superadmin: Edit_superadmin,
  globalRestrictedWords: globalRestrictedWords,
  Edit_restricted_words: Edit_restricted_words,
  Edit_delivery_agents: Edit_delivery_agents,
  shippingCharges: shippingCharges,
  DummyDistricts: DummyDistricts,
  DummyPin_codes: DummyPin_codes,
  listngfeetype: listngfeetype,
  editCharges: editCharges,
  ContentTabs: ContentTabs,
  globalRestrictedFile: globalRestrictedFile,
  otherfeeTabs: otherfeeTabs,
  zonemasterList: zonemasterList,
  zoneorPin: zoneorPin,
  charityeventList: charityeventList,
  allModeratorrolelist: allModeratorrolelist,
  editcharityevents: editcharityevents,

  //  Tickets&Reports

  TicketReportTabs: TicketReportTabs,
  allTickets_listing: allTickets_listing,
  allReports_listing: allReports_listing,
  Appsuggestion_list: Appsuggestion_list,
  ticketandsuggestionReply: ticketandsuggestionReply,
  Reports_allview: Reports_allview,
  Reply_tosuggestion: Reply_tosuggestion,

  // Master Variation

  allVariation: allVariation,
  variationdata: variationdata,
  mastervariationProductCat: mastervariationProductCat,
  mastervariationProductsubCat: mastervariationProductsubCat,

  // Transaction Management
  alltransactions: alltransactions,
  transactionmanagementTabs: transactionmanagementTabs,
  payoutDatalist: payoutDatalist,
  payoutTabs: payoutTabs,
  unpaidpayoutDatalist: unpaidpayoutDatalist,
  charitypayoutDatalist: charitypayoutDatalist,

  // Dashboard
  allDasboardStats: allDasboardStats,
  TimeSpanTabs: TimeSpanTabs,
  allusersToday: allusersToday,
  allusersThisweek: allusersThisweek,
  allusersThismonth: allusersThismonth,
  allvendorsToday: allvendorsToday,
  allvendorsThisweek: allvendorsThisweek,
  allvendorsThismonth: allvendorsThismonth,
  allproductbaseToday: allproductbaseToday,
  allproductbaseThisweek: allproductbaseThisweek,
  allproductbaseThismonth: allproductbaseThismonth,
  allnewordersToday: allnewordersToday,
  allnewordersWeek: allnewordersWeek,
  allnewordersMonth: allnewordersMonth,
  allongoingordersToday: allongoingordersToday,
  allongoingordersWeek: allongoingordersWeek,
  allongoingordersMonth: allongoingordersMonth,
  allcompletedordersToday: allcompletedordersToday,
  allcompletedordersWeek: allcompletedordersWeek,
  allcompletedordersMonth: allcompletedordersMonth,
  allcancelledordersToday: allcancelledordersToday,
  allcancelledordersWeek: allcancelledordersWeek,
  allcancelledordersMonth: allcancelledordersMonth,
  allreplacedordersToday: allreplacedordersToday,
  allreplacedordersWeek: allreplacedordersWeek,
  allreplacedordersMonth: allreplacedordersMonth,
  allpendingnegoToday: allpendingnegoToday,
  allpendingnegoWeek: allpendingnegoWeek,
  allpendingnegoMonth: allpendingnegoMonth,
  allacceptednegoToday: allacceptednegoToday,
  allacceptednegoWeek: allacceptednegoWeek,
  allacceptednegoMonth: allacceptednegoMonth,
  allrejectednegoToday: allrejectednegoToday,
  allrejectednegoWeek: allrejectednegoWeek,
  allrejectednegoMonth: allrejectednegoMonth,
  allactiveauctionToday: allactiveauctionToday,
  allactiveauctionWeek: allactiveauctionWeek,
  allactiveauctionMonth: allactiveauctionMonth,
  allcompletedauctionToday: allcompletedauctionToday,
  allcompletedauctionWeek: allcompletedauctionWeek,
  allcompletedauctionMonth: allcompletedauctionMonth,
  allpaymentsToday: allpaymentsToday,
  allpaymentsWeek: allpaymentsWeek,
  allpaymentsMonth: allpaymentsMonth,
  DashboardStatsTab: DashboardStatsTab,
  StaticticscommonDatePropsReducer: StaticticscommonDatePropsReducer,
  MonthOptions: MonthOptions,
  chartValues: chartValues,
  mostactiveusers: mostactiveusers,
  mostactivevendors: mostactivevendors,
  mostactiveproducts: mostactiveproducts,

  //Sidebar
  sideBarMenus: SideBarMenus,
  notificationList: notificationList,
  customNotification: customNotification,
  all_users: all_users,
  isSlideropen: isSlideropen,
  sidebarSelectedMenu: SidebarSelectedMenu,

  // phoneLengthCountry
  phoneLengthCountry: phoneLengthCountry,
});

export default rootReducer;
