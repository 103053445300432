import { INSTANCE_URL, itemCount } from "../../../../common/constants";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  postRequestnew,
  postRequestAccess,
} from "../../../../common/network-call";

export var CUSTOMER_LIST = "CUSTOMER_LIST";
export var WISHLIST_LIST = "WISHLIST_LIST";
export var WATCHLIST_LIST = "WATCHLIST_LIST";
export var CUSTOMER_TABID = "CUSTOMER_TABID";
export var CUSTOMER_NEWS = "CUSTOMER_NEWS";
export var NEGOTIATION_LIST = "NEGOTIATION_LIST";
export var EDIT_MODAL = "EDIT_MODAL";
export var ODER_LIST = "ODER_LIST";
export var ODER_LIST_DELIVERED = "ODER_LIST_DELIVERED";
export var ODER_LIST_DISPUTED = "ODER_LIST_DISPUTED";
export var AUCTION_LIST = "AUCTION_LIST";
export var NEGOTIATION_LOST = "NEGOTIATION_LOST";
export var TRANSACTION_LIST = "TRANSACTION_LIST";
export var ORDER_MASTER = "ORDER_MASTER";
export var CUSTOMER_AUCTION_LIST_WON = "CUSTOMER_AUCTION_LIST_WON";
export var SINGLECUSTOMER = "SINGLECUSTOMER";

export function singleCustomerobj(obj) {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_TABID, payload: obj });
  };
}

export function newtabs(menu = false) {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_NEWS, payload: menu });
  };
}
export function editmodal(open = false) {
  return (dispatch) => {
    dispatch({ type: EDIT_MODAL, payload: open });
  };
}

export function fetchCustomer(session, pageCount = 0, callback) {
  // var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  var perPage = 7;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;
  // var url = `${INSTANCE_URL}/api/v2/eventify/_table/speaker?related=appuser_by_speakeridfk&include_count=true&filter=eventid=${session.eventid}&order=createdon%20DESC${offSetQuery}`;
  var orderby = `createdon%20DESC`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?related=users_by_bannedby&include_count=true&filter=(usertype=consumer)${offSetQuery}&order=${orderby}`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data.meta.count);
        dispatch({
          type: CUSTOMER_LIST,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchCustomer(session, search = "", pageCount = 0) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  // console.log('offset',offset)
  var offSetQuery = `&limit=${itemCount}&offset=${offset}`;
  var searchQuery = `((username like %${search}%)OR(email like %${search}%))AND(usertype=consumer)`;
  var filter = encodeURI(`(${searchQuery})`);
  // var url = `${INSTANCE_URL}/api/v2/eventify/_table/sponsor?related=membershiptype_by_categoryid,appuser_by_sponsoridfk&include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?related=users_by_bannedby&include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;

  return (dispatch) => {
    // dispatch(fetchCustomer(session));
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log("SPONSOR: ", data);
        dispatch({
          type: CUSTOMER_LIST,
          payload: { data: data.resource, count: data.meta.count },
        });
      },
      (error) => {}
    );
  };
}

export function fetchWishlist(session, userid, pageNumber, callback) {
  var offset = pageNumber;

  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/wishlists`;
  return (dispatch) => {
    postRequest(
      url,
      {
        userid: userid,
        usertype: "admin",
        type: "listing",
        limit: "10",
        page: offset,
      },
      session,
      dispatch,
      (data) => {
        // console.log('data5', data);
        dispatch({
          type: WISHLIST_LIST,
          payload: { data: data.data, count: 2 },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchwishlist(session, userid, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/wishlists`;
  // console.log("search", search);
  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        userid: userid,
        usertype: "admin",
        type: "search",
        searchterm: search,
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: WISHLIST_LIST,
          payload: { data: data.data },
        });
      },
      (error) => {}
    );
  };
}

export function fetchWatchlist(session, userid, pageNumber, callback) {
  // var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  var offset = pageNumber;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/watchlist`;
  return (dispatch) => {
    postRequest(
      url,
      {
        userid: userid,
        usertype: "admin",
        type: "listing",
        limit: "10",
        page: offset,
        tab: "watchlist",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: WATCHLIST_LIST,
          payload: { data: data.data, count: data.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchWatchlist(session, userid, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/watchlist`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        userid: userid,
        usertype: "admin",
        type: "search",
        searchterm: search,
        tab: "watchlist",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: WATCHLIST_LIST,
          payload: { data: data.data },
        });
      },
      (error) => {}
    );
  };
}

export function fetchAuctionList(
  session,
  userid,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  // var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/watchlist`;
  let values = {};
  if (type === "listing") {
    // Paginated
    values.userid = userid;
    values.limit = limit;
    values.page = page;
    values.type = type;
    values.usertype = "admin";
    values.tab = "bid";
  } else {
    // With out pagination [search result]
    values.userid = userid;
    values.type = type;
    values.searchterm = searchterm;
    values.usertype = "admin";
    values.tab = "bid";
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (data) => {
        // console.log('actionwatchlist', data);
        dispatch({
          type: AUCTION_LIST,
          payload: { data: data.data, count: data.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchOderlist(session, userid, type, callback) {
  // console.log('sessiontoken', session);
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/myorders`;
  return (dispatch) => {
    postRequest(
      url,
      {
        userid: userid,
        source: "admin",
        tab: type,
      },
      session,
      dispatch,
      (data) => {
        if (type == "new") {
          dispatch({
            type: ODER_LIST,
            payload: { data: data.orders },
          });
        } else if (type == "delivered") {
          dispatch({
            type: ODER_LIST_DELIVERED,
            payload: { data: data.orders },
          });
        } else if (type == "disputed") {
          dispatch({
            type: ODER_LIST_DISPUTED,
            payload: { data: data.orders },
          });
        }

        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchNegotiation(session, userid, pageNumber, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/negotiations`;
  return (dispatch) => {
    postRequest(
      url,
      {
        userid: userid,
        usertype: "admin",
        type: "listing",
        limit: 10,
        page: pageNumber,
        status: "won",
        subtype: "consumer",
      },
      session,
      dispatch,
      (data) => {
        // console.log("negotiationwon", data.count);
        dispatch({
          type: NEGOTIATION_LIST,
          payload: { data: data.data, count: data.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchWonNegotiaton(session, userid, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/negotiations`;
  // console.log('search',search)
  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        userid: userid,
        usertype: "admin",
        type: "search",
        searchterm: search,
        status: "won",
        subtype: "consumer",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: NEGOTIATION_LIST,
          payload: { data: data.data },
        });
      },
      (error) => {}
    );
  };
}

export function fetchNegotiationrejected(
  session,
  userid,
  pageNumber,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/negotiations`;
  return (dispatch) => {
    postRequest(
      url,
      {
        userid: userid,
        usertype: "admin",
        type: "listing",
        limit: 10,
        page: pageNumber,
        status: "lost",
        subtype: "consumer",
      },
      session,
      dispatch,
      (data) => {
        // console.log('negotiationlost', data);
        dispatch({
          type: NEGOTIATION_LOST,
          payload: { data: data.data, count: data.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchLostNegotiaton(session, userid, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/negotiations`;
  // console.log('search',search)
  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        userid: userid,
        usertype: "admin",
        type: "search",
        searchterm: search,
        status: "lost",
        subtype: "consumer",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: NEGOTIATION_LOST,
          payload: { data: data.data },
        });
      },
      (error) => {}
    );
  };
}

export function addnewCustomerdf(session, dfdata, callback) {
  // console.log('dfdata',dfdata);
  let url = `${INSTANCE_URL}/api/v2/user/register?login=true`;
  var body = dfdata;
  return (dispatch) => {
    postRequestnew(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

export function updateCustomer(session, values, callback) {
  var body = { resource: { ...values } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // dispatch(fetchCustomer(session));
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function deleteSavedItems(session, id, callback) {
  // console.log(session);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users/${id}`;
  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data.resource);
        callback({ error: 0, result: data });
        dispatch(fetchCustomer(session));
      },
      (error) => {
        callback({ error: 1, message: error });
      }
    );
  };
}

export function deleteWatchlistitems(session, values, data, callback) {
  // console.log('values',values);
  var productid = values.productid;
  var userid = data.userid;
  // console.log('values',productid);
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/removefromwatch`;
  return (dispatch) => {
    postRequest(
      url,
      {
        productid: productid,
        userid: userid,
      },
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        // callback({ error: 0, result: data });
        callback({ success: 1 });
      },
      (error) => {
        // callback({ success: 0 });
      }
    );
  };
}

export function deleteWishlistitems(session, values, callback) {
  // console.log('values',values);
  var id = values.id;
  // console.log('values',id);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/wishlistitems?ids=${id}`;
  // console.log('values',url);
  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        callback({ error: 0, result: data });
      },
      (error) => {
        callback({ error: 1, message: error });
      }
    );
  };
}
export function deleteWishlist(session, values, callback) {
  // console.log('values',values);
  var id = values.id;
  // console.log('values',id);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/wishlist?ids=${id}`;
  // console.log('values',url);
  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        callback({ error: 0, result: data });
      },
      (error) => {
        callback({ error: 1, message: error });
      }
    );
  };
}

export function banCustomer(session, values, callback) {
  var body = { resource: { ...values } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // dispatch(fetchCustomer(session));
        callback({ success: 1 });
      },
      (error) => {
        // callback({ success: 0 });
      }
    );
  };
}

export function fetchTransaction(session, userid, pageNumber, callback) {
  var offset = pageNumber;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/transactions`;
  return (dispatch) => {
    postRequest(
      url,
      {
        foruserid: userid,
        limit: "10",
        page: offset,
        type: "listing",
      },
      session,
      dispatch,
      (data) => {
        // console.log('data5', data.data);
        dispatch({
          type: TRANSACTION_LIST,
          payload: { data: data.data, count: data.count },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchTransaction(session, userid, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/transactions`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        foruserid: userid,
        type: "search",
        searchterm: search,
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: TRANSACTION_LIST,
          payload: { data: data.data },
        });
      },
      (error) => {}
    );
  };
}

export function emaildbCheck(email, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript?type=socialsignupavailable`;
  var params = { email: email };
  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        if (response.result == "success" && response.available === 1) {
          callback({
            success: 1,
          });
        } else {
          callback({
            success: 0,
          });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
        // Note - If available = 1 means the user already registered as a Vendor, Show error
      }
    );
  };
}

export function fetchOrderMaster(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/ordermaster`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('ORDER_MASTER', data.resource);
        dispatch({
          type: ORDER_MASTER,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchcustomerAuctionWonList(
  session,
  userid,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  // var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/watchlist`;
  let values = {};
  if (type === "listing") {
    // Paginated
    values.userid = userid;
    values.limit = limit;
    values.page = page;
    values.type = type;
    values.usertype = "admin";
    values.tab = "bid";
    values.status = "won";
  } else {
    // With out pagination [search result]
    values.userid = userid;
    values.type = type;
    values.searchterm = searchterm;
    values.usertype = "admin";
    values.tab = "bid";
    values.status = "won";
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (data) => {
        // console.log('actionwatchlist', data);
        dispatch({
          type: CUSTOMER_AUCTION_LIST_WON,
          payload: { data: data.data, count: data.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function updateUserEmail(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/updateemail`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch(fetchCustomer(session));
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchsingleCustomer(session, userid, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?related=users_by_bannedby&include_count=true&filter=(userid=${userid})`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data.meta.count);
        dispatch({
          type: SINGLECUSTOMER,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
