import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { BaseComponent } from "../../../../../../common/base-component";
import _ from "lodash";
import CustomerTransactionProducts from "./transactionProducts";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../../currency/action/index";

class TransactionListItems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
      unbanopen: false,
    };
  }

  openModal = (e, obj) => {
    this.props.onSidebarMenuSelected(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  renderList() {
    var items = _.map(this.props.values.products, (values, index) => (
      <CustomerTransactionProducts key={index} values={values} />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }
  render() {
    var { values, localCurrency } = this.props;

    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    let tabledate = "";
    tabledate = moment(values.createdat).format("D MMM YYYY HH:mm A");

    let transaction_amount = 0.0;

    let productCurrency = "QAR";
    if (values.currency && (values.currency != "" || values.currency != null)) {
      productCurrency = values.currency;
    }

    transaction_amount = isCurrencyRateApplicable(
      productCurrency,
      values.amount
    );

    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {values.transactionid}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {currency} {values.amount}
          </td>
          {_.isEmpty(values.products) ? (
            <span className="ml-6"> -</span>
          ) : (
            <span> {this.renderList()}</span>
          )}

          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {tabledate}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {_.startCase(values.paymentstatus)}
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    localCurrency: state.currency,
  };
}

export default connect(mapStateToProps, { isCurrencyRateApplicable })(
  TransactionListItems
);
