import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import "react-notifications/lib/notifications.css";
import { reduxForm, reset, getFormValues } from "redux-form";
import { BaseComponent } from "../../../../common/base-component";
import { updateDataHeader, updateFormList } from "../actions/index";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import "react-form-builder2/dist/app.css";
import HeaderModalhomepage from "./header-modal";
import HomePageSections from "./form-itemhomepage";
import { classNames } from "../../../../common/common-functions";
import { updateTempLayout, fetchSettingMaster } from "../actions/index";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LocaleStrings from "../../../languages";
import PreviewHomePageModal from "./preview/previewmodal";

const SortableItem = SortableElement(({ value, index }) => (
  <HomePageSections key={index} list={value} />
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul className="list-group">
      {items.map((value, index) => {
        if (value) {
          value.index = index;
          return (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          );
        }
      })}
    </ul>
  );
});

class HompagFormBuilder extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      setOpen: false,
      btnmainSaveLoader: false,
      update: false,
    };

    // All Binded Functions
    this.listRef = React.createRef();
    this.draggedId = "";
  }

  componentDidMount() {}

  onSortEnd = ({ oldIndex, newIndex }) => {
    // var arrAvailable = this.props.availableFeatures;
    var arrAvailable = _.filter(this.props.homePageBody, (obj) => {
      return obj.display_order !== 99;
    });

    // console.log('arrAvailable before :- ', arrAvailable);
    arrAvailable = arrayMoveImmutable(arrAvailable, oldIndex, newIndex);

    var newArrAvailable = [];

    //Just map the array and reset the taborder as now taborder is same as the object position
    arrAvailable.map((value, index) => {
      newArrAvailable.push({ ...value, display_order: index + 1 });
    });
    this.props.updateFormList(newArrAvailable, this.props.homePageBody, "sort");
    // this.props.formEdited(true); // This is used for edit form
  };

  dragNewElement = (e, section) => {
    // if()
    let newObj = {};
    if (section == "product") {
      newObj.section = newObj;

      newObj = {
        section: section,
        bgcolor: "#fafafa",
        display_order: this.props.homePageBody.length + 1,
        error: 1,
      };
    } else {
      newObj = {
        section: section,
        bgcolor: "#fafafa",
        display_order: this.props.homePageBody.length + 1,
        error: 1,
      };
    }
    this.props.updateFormList(newObj, this.props.homePageBody, "add");
    this.setState({ update: true });
    // this.handleClick();

    // this.props.formEdited(true); // This is used for edit form
  };

  openModal = (e, obj) => {
    this.props.updateDataHeader(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  mainSaveHomePage = (e) => {
    let { homePageBody, homePageMainheader } = this.props;
    // console.log('homePageMainheader',homePageMainheader)
    let mainSaveHomePageDataArr = [];
    let newhomePageBody = [];
    let newhomePageMainheader = [];

    _.forEach(homePageMainheader, function (value) {
      var deepCopy = _.cloneDeep(value);
      let staticdata = {};
      staticdata.image = deepCopy.image;
      staticdata.linktype = deepCopy.linktype;
      staticdata.value = deepCopy.image;
      if (deepCopy.linktype == "product") {
        staticdata.productid = deepCopy.productid;
      } else if (deepCopy.linktype == "subcategory") {
        staticdata.subcategoryid = deepCopy.subcategoryid;
      } else if (deepCopy.linktype == "category") {
        staticdata.categoryid = deepCopy.categoryid;
      } else {
        staticdata.externallink = deepCopy.externallink;
      }
      deepCopy.staticdata = staticdata;
      // console.log('deepCopy',deepCopy)
      delete deepCopy["image"];
      delete deepCopy["headerType"];
      delete deepCopy["linktype"];
      delete deepCopy["productid"];
      delete deepCopy["value"];
      newhomePageMainheader.push(deepCopy);
    });

    let errorSection = 0;
    // console.log('newhomePageMainheader',newhomePageMainheader);
    _.forEach(homePageBody, function (value) {
      if (value && value.error && value.error == 1) {
        errorSection++;
      }
      if (value.hasOwnProperty("index")) {
        var deepCopy = _.cloneDeep(value);
        delete deepCopy["index"];
        delete deepCopy["productType"];
        if (
          deepCopy.section == "product" &&
          deepCopy.selection_logic != "manual"
        ) {
          deepCopy.data = [];
        }
      }
      newhomePageBody.push(deepCopy);
    });

    this.setState({ btnmainSaveLoader: true });
    mainSaveHomePageDataArr.push(...newhomePageMainheader, ...newhomePageBody);
    if (errorSection > 0) {
      NotificationManager.error(
        "Please complete banner/product details to save homepage"
      );
      this.setState({ btnmainSaveLoader: false });
      return false;
    } else {
      // console.log('mainSaveHomePageDataArr', mainSaveHomePageDataArr);
      this.props.updateTempLayout(
        this.props.session,
        mainSaveHomePageDataArr,
        (response) => {
          if (response.success === 0) {
            NotificationManager.error(LocaleStrings.updatederror);
          } else {
            this.props.fetchSettingMaster(
              this.props.session,
              (CallBackResponse) => {}
            );
            this.setState({ btnmainSaveLoader: false });
            NotificationManager.success(LocaleStrings.updatedsuccessfully);
          }
        }
      );
    }
  };

  render() {
    return (
      <>
        <div className="lg:grid lg:grid-cols-12 py-2 px-6">
          <div className="lg:col-span-6 rounded text-2xl font-semibold text-secondary">
            Home Page Builder
          </div>
          <div className="lg:col-span-6 flex end-item">
            <div className="mr-3">
              <button
                type="button"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={(e) => this.mainSaveHomePage(e)}>
                <svg
                  className={classNames(
                    this.state.btnmainSaveLoader ? "" : "sr-only",
                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                  )}
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  data-icon="loading"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true">
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
                Save HomePage
              </button>
            </div>
            <div>
              <PreviewHomePageModal />
            </div>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-12 p-6">
          <div
            className="lg:col-span-8 rounded p-6"
            style={{
              background: "#fff",
            }}>
            {this.props.homePageMainheader &&
            this.props.homePageMainheader.length > 0 ? (
              <div
                className="grid grid-cols-1 border-1 border-ternary rounded bg-gray-100 "
                style={{ cursor: "pointer" }}
                onClick={(e) =>
                  this.openModal(e, this.props.homePageMainheader)
                }>
                <div className="px-6 pt-2"> Home Page Header</div>
                <div className="lg:grid lg:grid-cols-12 p-6">
                  <div className="lg:col-span-8 rounded p-6 bg-white">
                    Dynamic
                  </div>
                  <div
                    className="lg:col-span-4"
                    style={{
                      background: "#fff",
                      padding: 20,
                      marginLeft: "20px",
                    }}>
                    Static
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.isOpen ? (
              <HeaderModalhomepage
                isOpen={this.state.isOpen}
                onClose={this.closeModal}
                // values={this.props.values}
              />
            ) : (
              ""
            )}
            {this.props.homePageBody && this.props.homePageBody.length > 0 ? (
              <div className="">
                <SortableList
                  items={this.props.homePageBody}
                  onSortEnd={this.onSortEnd}
                  pressDelay={200}
                />
                <div ref={this.listRef}></div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            className="lg:col-span-4"
            style={{ background: "#fff", padding: 20, marginLeft: "20px" }}>
            Dragable Items
            <ul>
              <li
                className="d-flex content-centertab bg-gray-100 rounded mt-5 cursor-grab"
                data-key="textinput"
                header-text="Input"
                data-default="false"
                data-name="Banner"
                data-text="input"
                draggable="true"
                onDragEnd={(e) => this.dragNewElement(e, "banner")}>
                <p className="toolboxname">Add Banner Section</p>
              </li>
              <li
                className="d-flex content-centertab bg-gray-100 rounded mt-5 cursor-grab"
                data-key="textinput"
                header-text="Input"
                data-default="false"
                data-name="Product"
                data-text="number"
                draggable="true"
                onDragEnd={(e) => this.dragNewElement(e, "product")}>
                <p className="toolboxname">Add Product Section</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="center-item mb-10">
          {/* <button type="button" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={(e) => this.mainSaveHomePage(e)}>
                        <svg
                            className={classNames(
                                this.state.btnmainSaveLoader ? "" : "sr-only",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        Save HomePage
                    </button> */}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    productCard_type: state.productCard_type,
    product_Limit: state.product_Limit,
    productType: state.productType,
    allproductList: state.allproductList ? state.allproductList.data : [],
    formValues: getFormValues("HomePageDesignForm")(state),
    homePageBody: state.homePageBody ? state.homePageBody.data : [],
    homePageMainheader: state.homePageMainheader
      ? state.homePageMainheader.data
      : [],
  };
}

// export default connect(mapStateToProps, {})(HomePageDesign);
export default connect(mapStateToProps, {
  updateDataHeader,
  updateFormList,
  updateTempLayout,
  fetchSettingMaster,
})(
  reduxForm({
    form: "HomePageDesignForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(HompagFormBuilder)
);
