import LocalizedStrings from "react-localization";

let LocaleStrings = new LocalizedStrings({
  en: {
    email: "Email",
    password: "Password",
    signin: "Sign in",
    superadmin_signin: "Super Admin  Sign In",
    enteremail_pass: "Enter your email & password to login",
    forgot_your_password: "Forgot your password?",
    create_a_new_account: "Create a new account",
    select_the_type_of_business: "Select the type of business you own",
    or_use_social: "OR USE SOCIAL",
    connect_with_apple: "Connect with Apple",
    connect_with_facebook: "Connect with Facebook",
    connect_with_google: "Connect with Google",
    connect_with_twitter: "Connect with Twitter",
    company: "Company",
    home_business: "Home Business",
    personal_items: "Personal Items",
    login: "Login",
    signout: "Log Out",
    login_form_validation_email_required: "Please enter your email address!",
    login_form_validation_password_required: "Please enter your password!",
    login_user_not_registered: "Your account is not registerd with us!",
    login_user_session_expired: "Your account access session is expired!",
    invalid_email_password: "Incorrect email or, password!",
    invalid_user: "Invalid User!",
    button_logout: "Logout",
    button_yes: "Yes",
    button_no: "No",
    logout_want_to_logout: "Do you really want to Logout?",
    title: "Title",
    notificationtext: "Notification Text",
    sentnotification: "Sent notification",
    sentto: "Sent To",
    date: "Date",
    adImage: "Ad Image",
    linkeditem: "Linked Item",
    frequency: "Frequency",

    // Buttons

    delete: "Delete",
    upgradestorepage: "Upgrade Store Page",

    // Dashboardside
    totalusers: "Total User",
    newvendors: "Total Vendors",
    productslisted: "Product Listed",
    odersmade: "Orders Made",
    statistics: "Statistics",
    last6months: "Last 6 Months",
    userbase: "User Base",
    vendorbase: "Vendor Base",
    today: "Today",
    week: "Week",
    month: "Month",
    name: "Name",
    email: "Email",
    registeredon: "Registered On",
    productbase: "Products Base",
    newoders: "New Orders",
    product: "Product",
    uploadedby: "Uploaded by",
    uploadedon: "Uploaded on",
    oderid: "Order ID",
    odertotal: "Order Total",
    oderby: "Ordered by",
    odersongoing: "Orders Ongoing",
    completedoders: "Completed Orders",
    oderstatus: "Status",
    deliveredon: "Delivered on",
    returnorcancel: "Returned/Cancelled Orders",
    replaceoders: "Replace Orders",
    pendingnegotiations: "Pending Negotiations",
    acceptednegotiations: "Accepted Negotiations",
    actualprice: "Actual Price",
    negotiatedprice: "Negotiated Price",
    rejectednegotiation: "Rejected Negotiation",
    activeauction: "Active Auction",
    transactiontype: "Transaction Type",

    // Delete
    areyousureyouwanttodelete: "Are you sure you want to delete",

    deactivate: "Deactivate",
    delete: "Delete",

    //API CALL
    something_went_wrong: "Something went wrong",
    api_success_status: "Request has succeeded",

    // discountmanagement
    /**** Discount Management ****/
    discount_thead_title: "Title",
    discount_thead_discount: "Discount",
    discount_thead_applied_on: "Applied On",
    discount_thead_valide_until: "Valid Until",
    discount_applicable_rule: "Discount applicable rule",
    discount_applicable_rule_subtext:
      "Select the rule which discount is applicable",
    select_discount_area: "Select discount area",
    discount_product_category: "Product category",
    discount_product_subcategory: "Product sub-category",
    discount_product: "Product",
    discount_added_successfully: "Discount added successfully",
    discount_type_require: "Choose a discount type",
    similar_discount_already_available:
      "Similar combination discount is already available",
    flat: "Flat",
    discount_updated_succesfully: "Discount Updated Successfully",
    inactive_discount: "Inactive",

    product_discount: "Product Discount",
    add_new_discount: "Add New Discount",
    edit_discount: "Edit Discount",
    update_discount: "Update discount",
    discount_title: "Discount title",
    enter_discount_title: "Enter discount title",
    validity_starting_date: "Validity starting date",
    select_starting_date: "Select starting date",
    validity_end_date: "Validity end date",
    select_end_date: "Select end date",
    discount: "Discount",
    product_category_subcategory: "Product category,subcategory",
    select_product_category_subcategory: "Select product category,subcategory",

    /**** Coupons Management ****/
    add_new_coupons: "Add New Coupon",
    coupons_tab_text_active: "Active",
    coupons_tab_text_inactive: "Inactive",
    coupons_thead_coupon: "Coupon",
    coupons_thead_coupon_code: "Coupon Code",
    coupons_thead_above: "above",
    edit_coupon: "Edit Coupon",
    update_coupon: "Update Coupon",
    coupon_title: "Coupon title",
    enter_coupon_title: "Enter coupon title",
    coupon_code: "Coupon code",
    enter_coupon_code: "Enter coupon code",
    coupon_generate_text: "Generate",
    coupon_applicable_rule: "Coupon applicable rule",
    coupon_applicable_rule_subtext:
      "Select the rule which coupon is applicable",
    select_criteria: "Select criteria",
    applicable_time: "Applicable time",
    applicable_time_text: "Number of time applicable",
    cart_amount: "Cart amount",
    enter_cart_amount: "Enter cart amount",
    coupon_added_successfully: "Coupon added successfully",
    coupon_updated_successfully: "Coupon Updated successfully",

    deactivate_confirmation_header_text: "Deactivate coupon",
    deactivate_confirmation_suheader_text:
      "Are you sure you wish to deactivate it? This action cannot be undone",
    coupon_deactivated_successfully: "Coupon deactivated successfully",

    delete_confirmation_header_text: "Delete coupon",
    delete_confirmation_suheader_text:
      "Are you sure you wish to delete it? This action cannot be undone",
    coupon_deleted_successfully: "Coupon Deleted successfully",

    // Toast Texts

    // Customer Management
    about: "About",
    watchlist: "Watchlist",
    wishlist: "Wishlist",
    oders: "Orders",
    auctions: "Auctions",
    negotiation: "Negotiation",
    transaction: "Transaction",
    cancel: "Cancel",
    save: "Save",
    firstname: "First Name",
    lastname: "Last Name",
    firstnamerequire: "Please Enter Your First Name",
    emailrequired: "Please Enter Your Email",
    lastnamerequired: "Please Enter Your Last Name",
    businessnamerequired: "Please Enter Your Business Name",
    businessname: "Business Name",
    phonenumber: "Phone Number",
    phonenumberrequired: "Please Enter Your Phone Number",
    gender: "Gender",
    rathernotsay: "Rather not say",
    male: "Male",
    female: "Female",
    typeofbusiness: "Type of Business",
    homebusiness: "Home Business",
    personalbusiness: "Personal Business",
    company: "Company",
    browse: "Browse",
    addseller: "New Seller",
    addsellerbigtext:
      "After Adding Seller,an auto generated password will be sent to the register email for login",
    bancustomer: "Ban Customer",
    updatecustomer: "Update Customer",
    delivered: "Delivered",
    new: "New",
    disputed: "Disputed",
    private: "Private",
    deletewishlist: "Delete Wishlist",
    wishlist: "Wishlist",
    privacy: "Privacy",
    itemsinlist: "Items in List",
    action: "Action",
    oderid: "Order Id",
    odererby: "Ordered By",
    odertotal: "Order Total",
    oderon: "Ordered On",
    status: "Status",
    items: "Items",
    participated: "Participated",
    won: "Won",
    product: "Product",
    placedbid: "Placed Bid",
    highestbid: "Highest Bid",
    placedon: "Placed On",
    timeleft: "Time Left",
    accepted: "Accepted",
    rejected: "Rejected",
    negotiationprice: "Negotiation Price",
    offersmade: "Offers Made",
    negotiatedon: "Negotiated On",
    transactionid: "SKU",
    transactionamount: "Transaction Amount",
    type: "Type",
    transactiondate: "Transaction Date",
    oderdetails: "Order Details",
    dateofoder: "Date of Order",
    deliveryto: "Delivery To",
    contactnumber: "Contact No",
    cashondelivery: "Cash on Delivery",
    cash: "Cash",
    itemsinorderfromyourshop: "Items in order from your shop",
    canceloder: "Cancel Order",
    negotiationdetails: "Negotiation Details",
    confirm_password: "Confirm Password",
    namerequired: "Please Enter your name",
    addCustomer: "Add Customer",
    removewatchlist: "Remove Watchlist",
    quantity: "Quantity",
    pricedetails: "Price Details",
    productprice: "Product Price",
    discount: "Discount",
    shippingfee: "Shipping Fee",
    taxcharges: "Tax & Charges",
    total: "Total",
    paymentmethod: "Payment Method",
    watchlistdeleted: "WatchList removed Successfully",
    errordelete: "Sorry cannot delete watchlist",
    removefromwatchlist: "Remove From Watchlist",
    removefromwishlist: "Remove From Wishlist",
    noneworders: "No New Orders",
    nodeliverdeorders: "No Delivered Orders",
    nodisputedorders: "No Disputed Orders",
    nonegotiationsaccepted: "No Negotiation Accepted ",
    nonegotiationsrejected: "No Negotiation Rejected",
    noparticipatedAuction: "No Participated Auction",
    nowonAuction: "No Auction Won",
    dateofbirth: "Date of Birth",
    notransactionadded: "No Transaction Added",
    addproducts: "+Add Products",
    superadmin: "SUPER ADMIN",

    //  SellerManagement

    storeprofile: "Store Profile",
    storeprofile_personal_vendor_NA:
      "Store is not available for personal type vendors",
    storeprofile_buildingno: "Building No:",
    storeprofile_zone: "Zone:",
    storeprofile_street: "Street:",
    storeprofile_country: "Country:",
    storeprofile_no_mapimage: "No map image added by vendor",
    banseller: "Ban Seller",
    updateseller: "Update Seller",
    profile: "Profile",
    legaldocuments: "Legal Documents",
    commissionpercentage: "Commission Percentage",
    verifiedstore: "Verified store will recieve a yellow tick on profile",
    approveandverified: "Approve & Mark as verfied",
    commercialregistration: "Commercial registration document",
    filledsignedagreement: "Filled and signed agreement",
    customcomission: "Custom Commission percentage (%) per transaction",
    pernewoder: "Per New Order",
    perrefund: "Per refund",
    allitems: "All Items",
    forsale: "For Sale",
    forauction: "For Auction",
    recommended: "Recommended",
    price: "Price",
    in_transit: "In Transit",
    nowatchlistadded: "No WatchList Added",
    nowishlistadded: "No WishList Added",
    iteminlist: "Items in list",
    cancelled: "Cancelled",
    returned: "Returned",
    replaced: "Replaced",
    initiatedseller: "Initiated",
    closedseller: "Closed",
    negotiatedeller: "Negotiated",

    // Store Verification
    submit_verification: "Submit Verification",
    attach_files: "Attach files",
    attach_document: "Attach document",
    commercial_registration_document: "Commercial registration document",
    agreement_template_duly_filled_and_signed:
      "Agreement template duly filled and signed",
    store_verification_form_header:
      "If you want to become a trusted vendor and earn more custom, get your account verified on shopez!",
    store_verification_form_subheader:
      "Upload the documents and earn a verified mark",
    on_your_profile: "on your profile",
    download_agreement_template: "Download agreement template",
    valid_id_proof: "Valid ID proof",
    upload_only_doc_and_pdf_file_error_text:
      "Not a valid file! upload only doc and pdf file",
    attachfile_to_update_error_text: "Attach files to update",
    commercial_registration_documentnotUploaded:
      "Commercial Registration Document Not Upload",
    filledandsignedagreement_notUploaded:
      "Filled And Signed Agreement Not Upload",
    filledandsignedagreement: "Filled And Signed Agreement ",
    approved: "Approved",
    approvedandverified: "Approve & Mark as Verified",
    verifiedandapproveduser: "You are an approved and verified User",
    yellowtickforverifieduser:
      "Verified User will recieve a blue tick on profile",
    storeprofiledetails: "Store Profile Details",
    storeprofilecover: "Store Cover Image",
    storecontactname: "Contact Name",
    storecontactnumber: "Contact Number",
    storelocation: "Location",
    storemap: "Map",
    awaytime: "Away Time",
    automaticresponse: "Auctomatic response",
    updatecommision: "Update Commissions",

    // Category Management
    category: "Category",
    subcategory: "Sub-Category",
    newcategory: "New Category",
    entercategoryname: "Enter Category Name",
    entersubcategoryname: "Enter SubCategory Name",
    uploadcategoryimage: "Upload category Image",
    nosubadded: "No Subcategory Added",
    editcategory: "Edit Category",
    addsubcatdetails: "+Add Another Sub-category Details",
    editsubcategory: "Edit Subcategory",
    products: "Products",
    imageupload: "Drag & Drop Or Click Here To Upload image",

    // Status Array Text
    order_delivered: "Order Delivered",
    order_dispatched: "Order Dispatched",
    order_Placed: "Order Placed",
    order_cancelled: "Order Cancelled",
    initiate_refund: "Initiate Refund",
    initiate_pickup: "Initiate Pickup",
    return_received: "Return Received",
    return_picked: "Return Picked",
    return_requested: "Return requested",
    replacement_requested: "Replacement requested",
    refund_initiated: "Refund Initiated",
    shipping_status: "Shipping Status",

    refunded: "Refunded",
    payment_pending: "Pending",
    refund_rejected: "Refund Rejected",
    reshipdelivered: "Replace Delivered",
    replace_reshippped: "Replace reshippped",

    confirm_pickeup: "Confirm Pickup",

    cancel_refund: "Cancel refund",
    cancel_refunded: "Cancel refunded",
    cancel_refundrejected: "Refund Rejected",
    initiate_replace_pickup: "Initiate Replacement Pickup",
    return_cancelled: "Returned Cancelled",
    refund_processing: "Refund Processing",

    // Product mangement
    allpublisheditems: "All Published Items",
    unapproved: "Unapproved",
    productdetails: "Product Details",
    productTitle: "Product Title",
    productdescription: "Product Description",
    specification: "Specification",
    returnpolicy: "Return Policy",
    questionanswers: "Question & Answers",

    // Specification Mangement
    specificationame: "Specification Name",
    appliedon: "Applied On",
    appliedfor: "Applied For",
    ismandatory: "is Mandatory",
    addnewspec: "New Specification",
    delete_contact_confirm: "Are you sure want to delete",
    isspecificationglobal: "Is Specification Global",
    select: "Select",
    editspecs: "Edit Specification",
    isspecsmandatory: "Is it Mandatory",

    // Discoun&CouponManagement
    discounts: "Discounts",
    coupons: "Coupons",
    active: "Active",
    expired: "Expired",
    discountname: "Discount Name",
    validity: "Validity",
    discount: "Discount",

    //Form validation text
    pleaseneterpassword: "Please Enter Your Password",
    pleaseenterbussinessname: "Please Enter Your Business Name",
    pleaseconfirmpassword: "Please Confirm Your Password",
    pleaseenterphonenumber: "Please Enter Your Phone Number",

    // Product Details
    product_details: "Product Details",
    return_policy: "Return Policy",
    questions_answers: "Questions & Answers",
    questions_answers_subtext: "Want to Know More About the Product?",
    ask_a_question: "Ask a Question",
    answer: "Answer",
    view_all_questions_answers: "View All Questions & Answers",
    type_your_question: "Type your question",
    type_your_answer: "Type your answer",
    your_answer_submitted_successfully: "Your answer submitted successfully",
    your_question_submitted_successfully:
      "Your question submitted successfully",
    your_comment_submitted_successfully: "Your comment submitted successfully",
    comments: "Comments",
    mentions: "Mentions",
    type_your_comment: "Type your comment",
    reviews: "Reviews",
    likes: "likes",
    en: "En",
    ar: "Ar",
    comments: "Comments",
    mentions: "Mentions",
    shipping: "Shipping",
    selected_variant: "Selected Variant",
    view_all_details: "View All Details",

    //BUTTONS

    newcustomer: "New Customer",
    emailbuyer: "Email Buyer",
    message_buyer: "Message",
    copy_email: "Copy Email",
    copied: "Copied",

    // Side Bar
    dashboard: "Dashboard",
    customer_management: "Customer Management",
    seller_management: "Seller Management",
    order_management: "Order Management",
    dispute_management: "Dispute Management",
    category_management: "Category Management",
    specs_management: "Specs. Management",
    discount_management: "Discount Management",
    adds_management: "Ads Management",
    feeds_management: "Feeds Management",
    global_setting: "Global Settings",
    tickets_reports: "Tickets & Report",
    profile_settings: "Profile Settings",
    projects: "Projects",
    language: "Language",
    currency: "Currency",
    product_management: "Product Management",
    revenue_reports: "Revenue Reports",
    coupons_management: "Coupons Management",
    ratings_reviews: "Ratings & Reviews",
    faq_support: "FAQ & Support",
    legal_docs: "Legal Docs",
    app_suggestions: "App Suggestions",
    email_address: "Email address",
    master_variation: "Master Variation",

    // Forgot Password Modal
    forgot_password: "Forgot Password",
    password_reset_link_text:
      "Please enter the registered email and will send you a password reset link",
    send_reset_link: "Send Reset Link",
    close: "Close",
    something_went_wrong: "Something went wrong",
    api_success_status: "Request has succeeded",
    password_reset_link_alert_text: "Password reset link sent successfully",
    email_id_not_register_alert: "Email Id not registered with Shopez",
    // Product Management
    sort: "Sort",
    filter: "Filter",
    list: "List",
    search: "Search",
    upload_product: "Upload Product",
    edit_product: "Edit Product",
    delete_product: "Delete Product",

    // Add Management
    link_to: "Link To",

    // GlobalSettings
    paymentkeys: "Payment keys",
    taxmanagement: "Tax management",
    commissionmanagement: "Comission Mangement",
    adminmanagement: "Admin Management",
    contentmanagement: "Content Management",
    deliverymanagement: "Delivery Management",
    legaldocs: "Legal Docs",
    faqs: "Faqs",
    othersettings: "Other Settings",
    gatewayname: "Gateway Name",
    keys: "Keys",
    gatewayadminlink: "Gateway Admin Link",
    gatewaybigtext:
      "Gateway's payment related settings has to be done on gateway admin panel itself.",
    updategateway: "Update Gateway",
    tax: "Tax",
    charges: "Charges",
    addcharge_errornotification_zonexistalready:
      "Charges for this zone is already added",
    addcharge_errornotification_addpincode: "Please Add Pin Code",
    updatevatrate: "Update Vat Rate",
    vatBigtext:
      "Vat Amount (with respect to cart value without shipping charge)",
    shippingcompany: "Shipping Company",
    packagesavailable: "Packages available",
    deliverykeys: "Delivery keys",
    customer: "Customer",
    vendor: "Vendor",
    aboutshopez: "About Shopez",
    superadminName: "SuperAdmin Name",
    regeneratepw: "Re-generate Super Admin Password",
    superadminprofilebigText:
      "New password will be emailed to super admin's email",
    contactnumber: "Contact Number",
    supportemail: "Support Email",
    updateprofile: "Update Profile",
    refund: "Global Refund",
    listingFee: "Listing Fee",
    loaderDec_admin_management: "Preparing SuperAdmin",
    superadmin_Edit: "Edit Admin",
    superadmin_Add: "Add Admin",
    global_settingAddRestrictedFile: "Add Restricted File",
    global_settingAddRestrictedWord: "Add Word",
    loaderDesc_content_management: "Preparing Content Management",
    global_setting_Activateagent_message:
      "Are you sure you want to Activate this Delivery Agent?",
    global_setting_Activateagent_header: "Activate Agent",
    global_setting_addAgents: "Add Delivery Agent",
    global_setting_editAgents: "Edit Agent",
    global_setting_noDataAgents: "No Agents",
    loaderDesc_faq: "Preparing Faq",
    global_setting_dltfaq: "Delete FAQ",
    global_setting_agentQuestion: "QUESTION :",
    global_setting_agentAnswer: "ANSWER :",
    global_setting_addNewFaq: "Add New FAQ",
    global_setting_editFaq: "Edit FAQ",
    global_setting_privacy_policy: "Privacy Policy",
    global_setting_updateFile: "Update File",
    global_setting_termsCond: "Terms And Conditions",
    global_setting_VendorSales: "Vendors Sales Policy",
    global_setting_ListingFee_Data: "Listing Fee needs to be added",
    global_setting_ListingFee0: "Negative or 0 Listing Fee not allowed",
    global_setting_ListingFee_updateSuccess:
      "Successfully Listing Fee updated!",
    global_setting_ListingFee_forEachProduct: "Listing Fee For Each Product",
    global_setting_ListingFee: "Listing Fee",
    global_setting_ListingFee_update: "Update ListingFee",

    // Ticket & Reports
    tickets: "Tickets",
    reports: "Reports",
    appsuggestions: "App Suggestions",
    ticketedby: "Ticketed By",
    raisedDate: "Raised Date",
    ticketContent: "Ticket Attachment",
    reportby: "Report By",
    reportdate: "Report Date",
    reporton: "Report On",
    report: "Report ",
    suggestedby: "Suggested By",
    suggesteddate: "Suggested Date",
    suggestedon: "Suggested On",
    suggestion: "Suggestion",
    view: "View",
    discount_int_validation: "Discount must be grater than 0",
    discount_invalid: "Invalid discount value !",
    // All Notification Texts
    updatedsuccessfully: "Updated Successfully",
    updatederror: "Something went wrong! Cannot Update",
    deletedsuccess: "Deleted Successfully",
    deletederror: "Something went wrong! Cannot Delete",
    addedsucessfully: "Added Successfully",
    addingfailed: "Adding Failed",
    today: "Today",
    week: "Week",
    month: "Month",

    // New
    cancel_reason: "Cancel reason",
    cancel_reason_desc: "please specify your cancellation reason here",
    enter_reason: "Enter reason",
    cancel_order: "Cancel Order",
    mark_received: "Mark Received",
    mark_delivered: "Mark Delivered",
    cancel_reason_error_text: "Missing cancel reason",
    alert_head_cancel: "Cancel",
    alert_order_cancel_subtext: "Are you sure do you want cancel?",
    alert_refund_initiate_subtext:
      "Are you sure do you want to initiate a refund?",
    marked_asshipped_successfully: "Marked as Shipped successfully",
    mark_delivered_successfully: "Mark as delivered successfully",
    initiate_pickup_successfully: "Initiate pickup successfully",
    marked_received_successfully: "Marked as received successfully",
    tracking_number_error_text: "Tracking number is missing",
    tracking_number_error_subtext: "Please Input tracking number",

    // Charges
    district: "District",
    zipcode: "Zip Code",
    shippingCharges: "Shipping Charges(QAR)",

    selectdate: "Select Date",

    //Change Currency
    change_currency: "Change Currency",
    select_currency: "Select Currency",
    qatari_riyal: "Qatari Riyal",
    dollar: "United States Dollar",

    // New changes
    // Common
    ban: "Ban",
    unban: "UnBan",
    edit: "Edit",
    common_banFail_message: "Something went Wrong!!! Cannot Ban",
    common_unbanbanFail_message: "Something went wroing!!!Cannot Unban",
    common_banFail_success: "Successfully Banned",
    common_unbanFail_success: "Successfully UnBanned",
    yes: "Yes",
    no: "No",
    common_wentWrong: "Something went wrong!!",
    common_send: "Send",
    common_no_content: "No Attachment Added",
    common_mandatory_required: "Mandatory Fields are required",
    common_password_Error: "Sorry! Password Didn't Match",
    common_email_Exists: "Emailid Already exists",
    common_cannotupdate: "Sorry!! Error in updating",
    common_updated: "Successfully Updated",
    common_cannotadd: "Sorry!! Error in adding",
    common_added: "Successfully Added",
    common_download: "Download",
    common_view: "View",
    common_submit: "Submit",
    common_downloadReport: "Download Report",
    add: "Add",
    common_cannotactivate: "Sorry!! Error in activating",
    common_activated: "Successfully Activated",
    common_deleteError: "Error in Deleting",
    common_deleteSucess: "Successfully Deleted",
    common_repliedSucess: "Successfully Replied",
    // ADS MANAGEMENT
    ads_management_heading: "ADS MANAGEMENT",
    ads_management_save: "Save Add",
    ads_management_afterevery: "after every",
    ads_management_repeat: "Repeat",
    ads_management_dateText:
      "(make sure to use same dates in case your banner displays one)",
    ads_management_validity: "Validity",
    ads_management_uploadad_banner: "Upload Add Banner",
    ads_management_newads: "New Ads",
    ads_management_editads: "Edit Ads",
    ads_management_pleaseaddImage: "Please Add Image Banner",
    ads_management_addDataText: "Please add data to upload Ads",
    ads_management_requiredCategory: "Category needs to be added",
    ads_management_requiredsubCategory: "SubCategory needs to be added",
    ads_management_requiredProduct: "Product needs to be added",
    ads_management_adsonHome: "Home",
    ads_management_adsonCategory: "Category :",
    ads_management_adsonsubCategory: "SubCategory :",
    ads_management_adsonsProduct: "Product :",
    ads_management_editAfterevery: " -time - After every",
    ads_management_editRows: " row(s)",
    ads_management_banAds: "Ban Ads",
    ads_management_unbanAds: "UnBan Ads",
    ads_management_banAds_title:
      " Are you sure you want to ban this Advertisement?",
    ads_management_unbanAds_title:
      " Are you sure you want to unban this Advertisement?",

    // Category Management
    category_management_heading: "CATEGORY MANAGEMENT",
    category_management_selectCategory:
      "Please Select A Category to Add or See its Following Subcategory",

    // Ticket & Reports
    ticketsReports_heading: "TICKETS REPORTS & SUGGESTION",
    ticketsReports_appFeaturetext: "App Feature",
    ticketsReports_RepliedText: "Replied",
    ticketsReports_sendreplyText: "Send Reply",
    ticketsReports_noAppsuggestion: "No App Suggestions",
    ticketsReports_Repliedssuccessfully: "Replied Successfully",
    ticketsReports_replyModalappsuggestion: "App Suggestion Reply",
    ticketsReports_Reportedby: "Reported By :",
    ticketsReports_feedback: "Feedback:",
    ticketsReports_Reportedon: "Reported On :",
    ticketsReports_longText:
      "Your reply will be emailed to the user's registered emailid",
    ticketsReports_noReports: "No Reports",
    ticketsReports_noTickets: "No Tickets Raised",
    ticketsReports_ticketHeading: "Ticket Reply",
    ticketsReports_Ticketedby: "Ticketed By:",

    // Specification Mangement
    specs_heading: "SPECIFICATION MANAGEMENT",
    specs_select_Cat: "Please select category or subcategory",
    specs_no_Data: "No Specification Added",
    specs_add_Header: "New Specification",
    specs_add_Footer: "Add Specification",
    specs_edit_Header: "Update Specification",
    specs_add_Footer: "Add Specification",
    specs_inactive_title: "Inactive Specification",
    specs_inactive_message:
      "Are you sure you want to inactive the specification?",
    specs_inactive_error: "Error in inactivating",
    specs_inactive_successfull: "Successfully Deactivated",
    specs_active_title: "Active Specification",
    specs_active_message: "Are you sure you want to active the specification?",
    specs_active_error: "Error in activating",
    specs_active_successfull: "Successfully activated",
    specs_Inactivate: "Deactivate",
    specs_Activate: "Activate",

    // SELLER MANAGEMNET
    seller_management_heading: "SELLER MANAGEMENT",
    seller_management_errorUnban: "Something went wrong! Cannot Unban Seller",
    seller_management_successUnban: "Seller Unban successfully",
    seller_management_UnbanSeller: "Unban Seller",
    seller_management_UnbanSeller_message:
      "Are you sure you want to unban the seller?",
    seller_management_errorban: "Something went wrong! Cannot ban Seller",
    seller_management_successban: "Seller banned successfully",
    seller_management_banSeller: "Ban Seller",
    seller_management_banSeller_message:
      "Are you sure you want to ban the seller?",
    seller_management_ongoingorder_error:
      "Cannot ban this user as they already have an ongoing order",
    seller_management_add_businesstype: "Type of business",
    seller_management_addSeller: "Add Seller",
    seller_management_editSeller: "Edit Seller",
    seller_management_errorcommission: "Commission needs to be added",
    seller_management_error0commission: "Negative or 0 commision not allowed",
    seller_management_commission_perOrder: "Commision Per Order",
    seller_management_commission_perRefund: "Commision Per Refund",
    seller_management_legalDocs_errorApprove: "Error:Not Approved",
    seller_management_legalDocs_successApprove: "Successfully Approved!",
    seller_management_legalDocs_submitAlldocs: "Please Submit All Documents",
    seller_management_legalDocs_verifiedText:
      "Verified User will recieve a blue tick",
    seller_management_legalDocs_onProfile: "on Profile",
    seller_management_refundSeller: "Refund For Seller",
    seller_management_nego_noinitiatedData: "No Initiated Negotiations",
    seller_management_nego_noaccepteddData: "No Negotiated Negotiations",
    seller_management_nego_noclosedData: "No Closed Negotiations",
    seller_management_storeinfonoData: "Store Information Not Added",
    seller_management_productNodata: "No Product Listed",

    // PRODUCT MANAGEMENT
    prod_mangement_heading: "PRODUCT MANAGEMENT",
    prod_mangementnoData: "No Products",
    prod_mangement_ban_title: "Ban Product",
    prod_mangement_ban_message: "Are you sure you want to ban this product??",
    prod_mangement_unban_title: "UnBan Product",
    prod_mangement_unban_message:
      "Are you sure you want to unban this product??",
    prod_mangement_approve_title: "Approve Product",
    prod_mangement_approve_message:
      "Are you sure you want to approve this product?",
    prod_mangement_approve_success: "Successfully Approved",
    prod_mangement_rejected_success: "Successfully Rejected",

    // MASTER VARIATION
    master_vari_heading: "MASTER VARIATION",
    master_vari_noData: "No variations",
    master_vari_th_CreatedOn: "Created on",
    master_vari_th_Name: "Variation Name",
    master_vari_newvariation: "New Variations",

    // TRANSACTION
    preparing_transaction: "Preparing Transactions",
    transaction_th_heading: "ALL TRANSACTIONS",
    transaction_th_noData: "No Transaction",
    transaction_th_transactionid: "Transaction ID",
    transaction_th_payementmode: "Payment Mode",
    transaction_th_paymenetstatus: "Payment Status",
    transaction_th_rejectedstatus: "Rejected",
    transaction_th_amount: "Amount",
    transaction_th_transactiontype: "Transaction Type",
    transaction_th_paymentby: "Payment By",

    // DASHBOARD
    dashboard_heading: "DASHBOARD",
    dashboard_Day_noData: "No Data Today",
    dashboard_Month_noData: "No Data This Month",
    dashboard_Week_noData: "No Data This Week",
    dashboard_stats_select: "Select a month for Statistics",
    dashboard_stats_noData: "Select a month for Statistics",
    dashboard_Users: "Users",
    dashboard_Vendors: "Vendors",
    dashboard_Products_Listed: "Products Listed",
    dashboard_Orders_made: "Orders made",
    dashboard_th_Won_By: "Won By",
    dashboard_th_WinningBid: "Winning Bid",

    // MOST ACTIVE
    dashboard_mostActive_user: "Most Active Users",
    dashboard_mostActive_vendor: "Most Active Vendors",
    dashboard_mostsold_products: "Most Sold Products",

    // ORDER MANAGEMENT
    order_management_heading: "ORDER MANAGEMENT",
    order_management_noOrders: "No New Orders",
    return_reason: "Return Reason",
    cancellation_reason: "Cancellation reason",
    refund_reject_reason: "Refund rejected reason",

    // Similar discount already available
    similar_discount_already_available: "Similar discount already available",

    // order details

    // New Orders  / Delivered Orders
    copy_email: "Copy Email",
    delivery_to: "Delivery To",
    order_id: "Order ID",
    image: "Image",
    order_date: "Date",
    order_date1: "Order date",
    order_by: "Order by",
    order_total: "Order total",
    order_details: "Order Details",
    sale_status: "Sale Status",
    sale_contact_no: "Contact No",
    email_buyer: "Email Buyer",
    message_buyer: "Message Buyer",
    qty: "Qty",
    feedback_received: "Feedback received",
    feedback_pending: "Feedback pending",
    price_details: "Price Details",
    original_product: "Orginal Product",
    exchanged_product: "Exchanged Product",
    paid_amount: "Paid Amount",
    refund_Amount: "Refund Amount",
    product_price: "Product Price",
    in_transit: "In Transit",
    shipping_fee: "Shipping Fee",
    tax_charges: "Tax & Charges",
    payment_method: "Payment Method",
    total: "Total",
    update_order_status: "Update order status",
    order_details_re_ship_item_text: "Re-ship item",
    product_shipping_text:
      "Product shipping (Add tracking number before updating)",
    tracking_number: "Tracking number",
    mark_as_shipped: "Mark as shipped",
    mark_delivered: "Mark Delivered",
    mark_received: "Mark Received",

    cancellation_reason: "Cancellation reason",
    refund_reject_reason: "Refund rejected reason",
    return_replace_reason: "Return / replace reason",

    return_reason: "Return Reason",
    replace_reason: "Replace Reason",
    cancel_reason: "Cancel reason",
    cancel_reason_desc: "please specify your cancellation reason here",
    enter_reason: "Enter reason",
    cancel_order: "Cancel Order",
    download_invoice: "Download Invoice",
    cancel_reason_error_text: "Missing cancel reason",
    cancel_reason_error_subtext: "Please Input cancel reason",
    tracking_number_error_text: "Tracking number is missing",
    tracking_number_error_subtext: "Please Input tracking number",

    cancel_reason_successfully: "The order has been cancelled!",
    refund_initiated_successfully:
      "The refund has been initiated successfully!",
    shipping_status: "Shipping Status",
    marked_asshipped_successfully: "Marked as Shipped successfully",
    marked_asreshipped_successfully: "Marked as Re-shipped successfully",
    mark_delivered_successfully: "Mark as delivered successfully",
    initiate_pickup_successfully: "Initiate pickup successfully",
    marked_received_successfully: "Marked as received successfully",

    pickup_confirmed_successfully: "Pickup confirmed successfully",

    // Status Array Text
    order_negotiated: "Negotiated",
    order_closed: "Closed",
    order_initiated: "Initiated",
    order_responded: "Responded",
    order_requested: "Requested",

    order_delivered: "Order Delivered",
    order_dispatched: "Order Dispatched",
    order_Placed: "Order Placed",
    order_cancelled: "Order Cancelled",
    initiate_refund: "Initiate Refund",
    initiate_pickup: "Initiate Pickup",
    return_received: "Return Received",
    return_picked: "Return Picked",
    return_requested: "Return requested",
    replacement_requested: "Replacement requested",
    refund_initiated: "Refund Initiated",

    refunded: "Refunded",
    payment_pending: "Pending",
    refund_rejected: "Refund Rejected",
    reshipdelivered: "Replace Delivered",
    replace_reshippped: "Replace re-shipped",

    confirm_pickeup: "Confirm Pickup",

    cancel_refund: "Cancel refund initiated",
    cancel_refunded: "Cancel refunded",
    cancel_refundrejected: "Refund Rejected",

    initiate_replace_pickup: "Initiate Replacement Pickup",
    return_cancelled: "Returned Cancelled",
    replacement_picked: "Replacement picked up",
    replacement_received: "Replacement received",
    refund_processing: "Refund Processing",
  },
  ar: {
    email: "بريد إلكتروني",
    password: "كلمه السر",
    signin: "تسجيل الدخول",
    superadmin_signin: "تسجيل دخول المشرف المتميز",
    enteremail_pass: "أدخل بريدك الإلكتروني وكلمة المرور لتسجيل الدخول",
    forgot_your_password: "نسيت رقمك السري؟",
    create_a_new_account: "انشاء حساب جديد",
    select_the_type_of_business: "حدد نوع العمل التجاري الذي تملكه",
    or_use_social: "أو استخدم وسائل التواصل الاجتماعي",
    connect_with_apple: "تواصل مع Apple",
    connect_with_facebook: "تواصل مع الفيسبوك",
    connect_with_google: "تواصل مع جوجل",
    connect_with_twitter: "تواصل مع Twitter",
    company: "شركة",
    home_business: "الأعمال المنزلية",
    personal_items: "اغراض شخصية",
    login: "تسجيل الدخول",
    signout: "خروج",
    login_form_validation_email_required:
      "الرجاء إدخال عنوان البريد الإلكتروني الخاص بك!",
    login_form_validation_password_required: "من فضلك أدخل رقمك السري!",
    login_user_not_registered: "حسابك غير مسجل معنا!",
    login_user_session_expired: "انتهت جلسة الوصول إلى حسابك!",
    invalid_email_password: "بريد أو كلمة مرورغير صحيحة!",
    invalid_user: "مستخدم غير صالح!",
    button_logout: "تسجيل خروج",
    button_yes: "نعم",
    button_no: "لا",
    logout_want_to_logout: "هل تريد حقًا تسجيل الخروج؟",
    email_address: "عنوان البريد الإلكتروني",

    // Side Bar
    sales_management: "إدارة المبيعات",
    profile_settings: "إعدادات الملف الشخصي",
    projects: "المشاريع",
    language: "لغة",
    currency: "عملة",
    product_management: "ادارة المنتج",
    revenue_reports: "تقارير الإيرادات",
    coupons_management: "إدارة الكوبونات",
    discounts_management: "إدارة الخصومات",
    ratings_reviews: "التقييمات والمراجعات",
    faq_support: "التعليمات والدعم",
    legal_docs: "المستندات القانونية",
    app_suggestions: "اقتراحات التطبيق",

    // Forgot Password Modal
    forgot_password: "هل نسيت كلمة السر",
    password_reset_link_text:
      "يرجى إدخال البريد الإلكتروني المسجل وسوف نرسل لك رابط إعادة تعيين كلمة المرور",
    send_reset_link: "أرسل رابط إعادة التعيين",
    close: "يغلق",
    edit_product: "تحرير المنتج",
    delete_product: "حذف المنتج",

    // Product Management
    sort: "فرز",
    filter: "منقي",
    list: "قائمة",
    search: "يبحث",
    upload_product: "تحميل المنتج",
    newcustomer: "عميل جديد",
    dashboard: "لوحة القيادة",
    customer_management: "ادارة الزبائن",
    order_management: "إدارة الطلبات",
    dispute_management: "إدارة الخلاف",
    category_management: "إدارة الفئة",
    specs_management: "إدارة المواصفات",
    discount_management: "إدارة الخصم",
    adds_management: "يضيف الإدارة",
    feeds_management: "إدارة الأعلاف",
    global_setting: "الإعداد العالمي",
    tickets_reports: "تقارير التذاكر",
    seller_management: "إدارة البائع",

    // Customer Management
    about: "حول",
    watchlist: "قائمة المراقبة",
    wishlist: "قائمة الرغبات",
    oders: "أودرز",
    auctions: "المزادات",
    negotiation: "تفاوض",
    transaction: "عملية تجارية",
    cancel: "إلغاء",
    save: "حفظ",
    firstname: "الاسم الأول",
    lastname: "اسْم العائِلةِ",
    firstnamerequire: "رجاء أدخل a الاسم",
    emailrequired: "من فضلك أدخل بريدك الإلكتروني",
    lastnamerequired: "رجاء أدخل a الاسم",
    businessnamerequired: "من فضلك أدخل اسم عملك",
    businessname: "الإسم التجاري",
    phonenumber: "رقم الهاتف",
    gender: "جنس",
    rathernotsay: "الأفضل أن لا يقال",
    male: "الذكر",
    female: "أنثى",
    typeofbusiness: "الأعمال",
    homebusiness: "الأعمال المنزلية",
    personalbusiness: "عمل شخصي",
    company: "شركة",
    browse: "تصفح",
    addseller: "أضف البائع",
    addsellerbigtext:
      "بعد إضافة البائع ، سيتم إرسال كلمة مرور تم إنشاؤها تلقائيًا إلى البريد الإلكتروني للتسجيل لتسجيل الدخول",
    bancustomer: "حظر الزبون",
    updatecustomer: "تحديث العميل",
    delivered: "ايصال",
    new: "جديد",
    disputed: "المتنازع عليها",
    private: "نشر",
    deletewishlist: "حذف قائمة الرغبات",
    wishlist: "قائمة الرغبات",
    privacy: "الإجمالية",
    itemsinlist: "",
    action: "أجراءات",
    oderid: "رقم التعريف الخاص بالطلب",
    odererby: "ترتيب حسب",
    odertotal: "الطلب الكلي",
    status: "الحالة",
    items: "العناصر",
    participated: "شارك",
    won: "وون",
    product: "منتج",
    placedbid: "تم وضعه",
    highestbid: "أعلى عرض",
    placedon: "موضوع على",
    timeleft: "الوقت المتبقي",
    accepted: "وافقت",
    rejected: "مرفوض",
    actualprice: "السعر الفعلي",
    negotiationprice: "سعر التفاوض",
    offersmade: "تقدم العروض",
    negotiatedon: "التفاوض على",
    transactionid: "رقم المعاملة",
    transactionamount: "قيمة التحويل",
    type: "اكتب",
    transactiondate: "تاريخ الصفقة",
    oderdetails: "تفاصيل الطلب",
    dateofoder: "تغذية التاريخ",
    deliveryto: "تسليم الى",
    contactnumber: "رقم الاتصال",
    cashondelivery: "الدفع عند الاستلام",
    canceloder: "الملغي",
    negotiationdetails: "تفاصيل التفاوض",
    confirm_password: "تأكيد كلمة المرور",
    removewatchlist: "إزالة من قائمة المراقبة",
    quantity: "كمية",
    pricedetails: "تفاصيل السعر",
    productprice: "سعر المنتج",
    discount: "تخفيض",
    shippingfee: "رسوم الشحن",
    taxcharges: "رسوم الشحن",
    total: "مجموع",
    paymentmethod: "طريقة الدفع او السداد",
    superadmin: "مشرف فائق",

    //  Seller Manegment
    storeprofile: "ملف تعريف المتجر",
    banseller: "حظر البائع",
    updateseller: "تحديث البائع",
    profile: "الملف الشخصي",
    legaldocuments: "وثائق قانونية",
    commissionpercentage: "نسبة العمولة",
    verifiedstore:
      "سيتلقى المتجر الذي تم التحقق منه علامة صفراء على الملف الشخصي",
    approveandverified: "الموافقة ووضع علامة محققة",
    commercialregistration: "وثيقة السجل التجاري",
    filledsignedagreement: "اتفاق معبأ وموقع",
    customcomission: "نسبة العمولة الجمركية (٪) لكل معاملة",
    pernewoder: "لكل جديد أو",
    perrefund: "لكل استرداد",
    allitems: "كل الاشياء",
    forsale: "للبيع",
    forauction: "للمزاد",
    price: "السعر",
    in_transit: "في مرحلة انتقالية",
    nowatchlistadded: "لا توجد قائمة مراقبة مضافة",
    nowishlistadded: "لا قائمة الرغبات المضافة",
    iteminlist: "العناصر الموجودة في القائمة",
    cancelled: "ألغيت",
    returned: "عاد",
    replaced: "تم استبداله",

    // Status Array Text
    order_delivered: "أجل تسليم",
    order_dispatched: "تم إرسال الطلب",
    order_Placed: "تم الطلب",
    order_cancelled: "تم الغاء الأمر او الطلب",
    initiate_refund: "بدء استرداد الأموال",
    initiate_pickup: "بدء الالتقاط",
    return_received: "تم استلام الإرجاع",
    return_picked: "تم اختيار العودة",
    return_requested: "طلب الإرجاع",
    replacement_requested: "طلب الاستبدال",
    refund_initiated: "بدأ رد الأموال",
    shipping_status: "حالة الشحن",

    // Product Details
    product_details: "تفاصيل المنتج",
    return_policy: "سياسة العائدات",
    questions_answers: "أسئلة و أجوبة",
    questions_answers_subtext: "هل تريد معرفة المزيد عن المنتج؟",
    ask_a_question: "طرح سؤال",
    answer: "إجابة",
    view_all_questions_answers: "عرض جميع الأسئلة والأجوبة",
    type_your_question: "اكتب سؤالك",
    type_your_answer: "أكتب أجابتك",
    your_answer_submitted_successfully: "تم تقديم إجابتك بنجاح",
    your_question_submitted_successfully: "تم إرسال سؤالك بنجاح",
    your_comment_submitted_successfully: "تم إرسال تعليقك بنجاح",
    comments: "تعليقات",
    mentions: "يذكر",
    type_your_comment: "اكتب تعليقك",

    // Product mangement
    allpublisheditems: "جميع العناصر المنشورة",
    unapproved: "غير موافق عليه",
    productdetails: "تفاصيل المنتج",
    productTitle: "عنوان المنتج",
    productdescription: "وصف المنتج",
    specification: "تخصيص",
    returnpolicy: "سياسة العائدات",
    questionanswers: "أسئلة وإجابات",

    // All Notification Texts
    updatedsuccessfully: "تم التحديث بنجاح",
    updatederror: "هناك خطأ ما! لا يمكن التحديث",
    deletedsuccess: "حذف بنجاح",
    deletederror: "هناك خطأ ما! لا يمكن مسحه",
    addedsucessfully: "تمت الإضافة بنجاح",
    addingfailed: "خطأ: فشلت عملية الإضافة",

    // Category Management
    category: "فئة",
    subcategory: "تصنيف فرعي",
    newcategory: "New Category",
    entercategoryname: "أدخل اسم الفئة",
    entersubcategoryname: "أدخل اسم الفئة الفرعية",
    uploadcategoryimage: "تحميل صورة الفئة",
    nosubadded: "لا توجد فئة فرعية مضافة",
    editcategory: "تحرير الفئة",
    addsubcatdetails: "إضافة تفاصيل فئة فرعية أخرى",
    editsubcategory: "فئة التحرير",
    products: "منتجات",
    imageupload: "أفلت أو انقر هنا لتحميل الصورة",

    // Specification Mangement
    specificationame: "اسم المواصفات",
    appliedon: "تم التطبيق",
    appliedfor: "قدم على",
    ismandatory: "قدم على",
    addnewspec: "المواصفات الجديدة",
    isspecificationglobal: "هي المواصفات العالمية",
    select: "يختار",
    editspecs: "تحرير المواصفات",
    isspecsmandatory: "هل هو إلزامي",

    // Discoun&CouponManagement
    discounts: "الخصومات",
    coupons: "كوبونات",
    active: "نشيط",
    expired: "منتهي الصلاحية",
    discountname: "اسم الخصم",
    validity: "صلاحية",
    discount: "خصم",

    // Delete
    areyousureyouwanttodelete: "هل أنت متأكد أنك تريد حذف",
    yes: "نعم",
    no: "لا",

    //API CALL
    something_went_wrong: "هناك خطأ ما",
    api_success_status: "نجح الطلب",

    // discountmanagement
    /**** Discount Management ****/
    discount_thead_title: "عنوان",
    discount_thead_discount: "خصم",
    discount_thead_applied_on: "تم التطبيق",
    discount_thead_valide_until: "صالح حتى",
    discount_applicable_rule: "قاعدة الخصم المعمول بها",
    discount_applicable_rule_subtext: "حدد القاعدة التي ينطبق الخصم عليها",
    select_discount_area: "حدد منطقة الخصم",
    discount_product_category: "فئة المنتج",
    discount_product_subcategory: "فئة المنتج الفرعية",
    discount_product: "المنتج",
    discount_added_successfully: "تمت إضافة الخصم بنجاح",
    discount_type_require: "اختر نوع الخصم",
    similar_discount_already_available: "خصم مماثل متاح بالفعل",
    flat: "مسطحة",
    discount_updated_succesfully: "تم تحديث الخصم بنجاح",

    product_discount: "خصم المنتج",
    add_new_discount: "أضف خصم جديد",
    edit_discount: "تحرير الخصم",
    update_discount: "تحديث الخصم",
    discount_title: "عنوان الخصم",
    enter_discount_title: "أدخل عنوان الخصم",
    validity_starting_date: "تاريخ بدء الصلاحية",
    select_starting_date: "حدد تاريخ البدء",
    validity_end_date: "تاريخ انتهاء الصلاحية",
    select_end_date: "حدد تاريخ الانتهاء",
    discount: "خصم",
    product_category_subcategory: "فئة المنتج ، الفئة الفرعية",
    select_product_category_subcategory: "حدد فئة المنتج والفئة الفرعية",

    // Status Array Text
    order_delivered: "Order Delivered",
    order_dispatched: "Order Dispatched",
    order_Placed: "Order Placed",
    order_cancelled: "Order Cancelled",
    initiate_refund: "Initiate Refund",
    initiate_pickup: "Initiate Pickup",
    return_received: "Return Received",
    return_picked: "Return Picked",
    return_requested: "Return requested",
    replacement_requested: "Replacement requested",
    refund_initiated: "Refund Initiated",
    shipping_status: "Shipping Status",

    refunded: "Refunded",
    payment_pending: "Pending",
    refund_rejected: "Refund Rejected",
    reshipdelivered: "Replace Delivered",
    replace_reshippped: "Replace reshippped",

    confirm_pickeup: "Confirm Pickup",

    cancel_refund: "Cancel Refund Initiated",
    cancel_refunded: "Cancel refunded",
    cancel_refundrejected: "Refund Rejected",
    initiate_replace_pickup: "Initiate Replacement Pickup",
    return_cancelled: "Returned Cancelled",

    //Form validation text
    pleaseneterpassword: "من فضلك أدخل رقمك السري",
    pleaseenterbussinessname: "الرجاء إدخال اسم عملك",
    pleaseconfirmpassword: "يرجى التأكد من صحة كلمة المرور الخاصة بك",
    pleaseenterphonenumber: "يرجى إدخال رقم الهاتف الخاص بك",

    // Store Verification
    submit_verification: "Submit Verification",
    attach_files: "Attach files",
    attach_document: "Attach document",
    commercial_registration_document: "Commercial registration document",
    agreement_template_duly_filled_and_signed:
      "Agreement template duly filled and signed",
    store_verification_form_header:
      "If you want to become a trusted vendor and earn more custom, get your account verified on shopez!",
    store_verification_form_subheader:
      "Upload the documents and earn a verified mark",
    on_your_profile: "on your profile",
    download_agreement_template: "Download agreement template",
    valid_id_proof: "Valid ID proof",
    upload_only_doc_and_pdf_file_error_text:
      "Not a valid file! upload only csv",
    attachfile_to_update_error_text: "Attach files to update",
    commercial_registration_documentnotUploaded:
      "Commercial Registration Document Not Upload",
    filledandsignedagreement_notUploaded:
      "Filled And Signed Agreement Not Upload",
    filledandsignedagreement: "Filled And Signed Agreement ",
    approved: "Approved",
    approvedandverified: "Approve & Mark as Verified",
    yellowtickforverifieduser:
      "Verified User will recieve a blue tick on profile",
    storeprofiledetails: "Store Profile Details",
    storeprofilecover: "Store Cover Image",
    storecontactname: "Contact Name",
    storecontactnumber: "Contact Number",
    storelocation: "Location",
    storemap: "Map",
    awaytime: "Away Time",
    automaticresponse: "Auctomatic response",

    // GlobalSettings
    paymentkeys: "Payment keys",
    taxmanagement: "Tax management",
    commissionmanagement: "Comission Mangement",
    adminmanagement: "Admin Management",
    contentmanagement: "Content Management",
    deliverymanagement: "Delivery Management",
    legaldocs: "Legal Docs",
    faqs: "Faqs",
    othersettings: "Other Settings",
    gatewayname: "Gateway Name",
    keys: "Keys",
    gatewayadminlink: "Gateway Admin Link",
    gatewaybigtext:
      "Gateway's payment related settings has to be done on gateway admin panel itself.",
    updategateway: "Update Gateway",
    tax: "Tax",
    charges: "Charges",
    updatevatrate: "Update Vat Rate",
    vatBigtext:
      "Vat Amount (with respect to cart value without shipping charge)",
    shippingcompany: "Shipping Company",
    packagesavailable: "Packages available",
    deliverykeys: "Delivery keys",
    customer: "Customer",
    vendor: "Vendor",
    aboutshopez: "About Shopez",
    superadminName: "SuperAdmin Name",
    regeneratepw: "Re-generate Super Admin Password",
    superadminprofilebigText:
      "New password will be emailed to super admin's email",
    contactnumber: "Contact Number",
    supportemail: "Support Email",
    updateprofile: "Update Profile",
    discount_int_validation: "يجب أن يكون الخصم أكبر من 0",
    discount_invalid: "قيمة الخصم غير صالحة!",
    //  Buttons
    edit: "تعديل",
    delete: "حذف",
    emailbuyer: "مشتري البريد الإلكتروني",
    addCustomer: "أضف الزبون",
    upgradestorepage: "ترقية صفحة المتجر",
  },
});

// module.exports = LocaleStrings;
export default LocaleStrings;
