import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index";
import { RadioGroup } from "@headlessui/react";

// Functions Import
import { classNames } from "../../../../common/common-functions";
// Import Action
import { setCurrency } from "../../../../actions/index";
import BaseLoader from "../../../../common/base-loader";

const settings = [
  {
    name: "QAR",
    description: LocaleStrings.qatari_riyal,
    src: "../images/qar.png",
  },
  { name: "USD", description: LocaleStrings.dollar, src: "../images/usd.png" },
];

class Currency extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_currency,
      selected: settings[0],
    };

    // All Binded Functions
  }

  // On Radio Chnage
  onRadioChange = (e) => {
    this.setState({ selected: e });
    this.props.setCurrency(e.name);
  };

  componentDidMount() {
    if (this.props.currency === "USD") {
      this.setState({ selected: settings[1] });
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    this.setState({ loading: true });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            {" "}
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <div className="py-6 responsivePadding">
            <div className="mx-auto px-4 sm:px-6 md:px-8">
              <h1 className="text-2xl font-semibold text-secondary">
                {LocaleStrings.change_currency}
              </h1>
            </div>
            <div className="mx-auto px-4 sm:px-6 md:px-8">
              {/* Replace with your content */}
              <div className="py-4">
                <RadioGroup
                  value={this.state.selected}
                  onChange={(e) => this.onRadioChange(e)}>
                  <RadioGroup.Label className="sr-only">
                    Privacy setting
                  </RadioGroup.Label>
                  <div className="bg-custWhite rounded-md -space-y-px">
                    {settings.map((setting, settingIdx) => (
                      <RadioGroup.Option
                        key={setting.name}
                        value={setting}
                        className={({ checked }) =>
                          classNames(
                            settingIdx === 0
                              ? "rounded-tl-md rounded-tr-md"
                              : "",
                            settingIdx === settings.length - 1
                              ? "rounded-bl-md rounded-br-md"
                              : "",
                            checked
                              ? "bg-primaryLight border-primaryHover z-10"
                              : "border-quaternary",
                            "relative border p-4 flex cursor-pointer focus:outline-none"
                          )
                        }>
                        {({ active, checked }) => (
                          <>
                            <span
                              className={classNames(
                                checked
                                  ? "bg-primary border-transparent"
                                  : "bg-custWhite border-quaternary",
                                active
                                  ? "ring-2 ring-offset-2 ring-primaty"
                                  : "",
                                "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                              )}
                              aria-hidden="true">
                              <span className="rounded-full bg-custWhite w-1.5 h-1.5" />
                            </span>
                            <div className="mx-3 flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className={classNames(
                                  checked ? "text-primary" : "text-secondary",
                                  "block text-sm font-medium"
                                )}>
                                {setting.name}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className={classNames(
                                  checked ? "text-primary" : "text-ternary",
                                  "block text-sm"
                                )}>
                                {setting.description}
                              </RadioGroup.Description>
                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
              {/* /End replace */}
            </div>
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  localCurrency: state.localCurrency,
  currency: state.currency,
});

export default connect(mapStateToProps, { setCurrency })(Currency);
