import React from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../../../common/base-component";
import _ from "lodash";
import LocaleStrings from "../../../../../languages";
import { confirmAlert } from "react-confirm-alert";
import { NotificationManager } from "react-notifications";
import { deleteCharges, EditCharges } from "../../../actions/index";
import EditGlobalCharges from "./editCharges";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../../currency/action/";

class ChargesList extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
    };
  }

  openModal = (e, obj) => {
    this.props.EditCharges(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  deleteChargesBtnClick(e, values) {
    // console.log('deleteNewsCallback',values)
    confirmAlert({
      title: "Shipping Charges",
      message: `${LocaleStrings.areyousureyouwanttodelete}`,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            var id = values;
            // console.log('id',id)
            this.props.deleteCharges(this.props.session, values, (resource) => {
              // console.log("showresource :- ", resource);
              if (resource.error) {
                NotificationManager.error(LocaleStrings.deletederror);
              } else {
                NotificationManager.success(LocaleStrings.deletedsuccess);
              }
            });
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  // renderlistings() {
  //   if (_.isEmpty(this.props.values)) {
  //   } else {
  //     var nameArr = this.props.values.pin_code.split(",");
  //     var items = _.map(nameArr, (allzipcodes, index) => {
  //       return (
  //         <div>
  //           <div className="flex">
  //             <p className="py-2"> {allzipcodes}</p>
  //           </div>
  //         </div>
  //       );
  //     });
  //     return items;
  //   }
  // }

  render() {
    var { values, isLoggedIn } = this.props;
    // console.log('valyues',values)
    // let grand_total     = 0.00;
    // let productCurrency = values.product?.odcurrency;

    // grand_total = isCurrencyRateApplicable(
    //   productCurrency,
    //   values.summary.grand_total
    // );

    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {values.pin_code}
            {values.pin_code ? values.pin_code : "-"}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {/* {this.renderlistings()} */}
            {values.zone_master_by_zone ? values.zone_master_by_zone.zone : "-"}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {/* ${values.charges} */}QAR {parseFloat(values.charges).toFixed(2)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div className="action-button-area" style={{ display: "flex" }}>
              <div className="">
                <button
                  className="button"
                  onClick={(e) => this.openModal(e, values)}
                  type="button">
                  Edit
                </button>

                {this.state.isOpen ? (
                  <EditGlobalCharges
                    isOpen={this.state.isOpen}
                    onClose={this.closeModal}
                    // values={this.props.values}
                  />
                ) : (
                  ""
                )}
              </div>
              {/* <div className="">
                <button
                  className="button"
                  onClick={(e) => this.deleteChargesBtnClick(e, values)}
                  type="button"
                >
                  Delete
                </button>
              </div> */}
            </div>
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log("customerlistingtabs", state.isLoggedIn);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapStateToProps, {
  deleteCharges,
  EditCharges,
  isCurrencyRateApplicable,
})(ChargesList);
