import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "./../../../../languages/index";
import UserTimeSpanTabs from "./dayweekmonthtab";
import VendorTimeSpanTabs from "./vedordayweekmontabs";
import UsersToday from "./dayweekmonthtable/dayindex";
import VendorsToday from "./vendortable/vendordayindex";

class UserVendorBase extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  customerdetails = (e, data) => {
    //  console.log("this.props.data", this.props.data)
    this.setState({ showUploadSection: !this.state.showUploadSection });
    //   this.props.data();
  };

  render() {
    return (
      <div class="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 ">
        <div class="box-border h-128 w-full bg-custWhite mb-5">
          <UsersToday />
          {/* <div class=" grid grid-cols-1 grid-flow-col gap-4 pl-3 mt-1">
            <div className="text-ternary  group flex items-center px-3 py-2 text-sm font-medium rounded-md">
              {" "}
              {LocaleStrings.userbase}
            </div>
          </div>
          <div class="">
            <div className="">
              <UserTimeSpanTabs showUploadSection={this.customerdetails} />
            </div>
          </div> */}
        </div>
        <div class="box-border h-128 w-full bg-custWhite mb-5">
          <VendorsToday />
          {/* <div class=" grid grid-cols-1 grid-flow-col gap-4 pl-3 mt-1">
            <div className="text-ternary  group flex items-center px-3 py-2 text-sm font-medium rounded-md">
              {" "}
              {LocaleStrings.vendorbase}
            </div>
          </div>
          <div class="">
            <div className="">
              <VendorTimeSpanTabs showUploadSection={this.customerdetails} />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {})(UserVendorBase);
