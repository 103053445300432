import React, { Component } from "react";
import { connect } from "react-redux";
import { SearchIcon } from "@heroicons/react/solid";
import LocaleStrings from "../../../../../languages/index";
import _ from "lodash";
import BaseLoader from "../../../../../../common/base-loader";
import Addvendorfaqs from "./addvendorfaq";
import VendorFaqlistitems from "./venfaqlist-items";
import { fetchVendorFaq, searchVendorfaq } from "../../../actions/index";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Pagination from "react-js-pagination";
import { classNames } from "../../../../../../common/common-functions";

class VendorFaqs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveredorderSearch: "",
      loaderDesc: LocaleStrings.loaderDesc_faq,
      loading: true,
      pageNumber: 1,
      search: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchVendorFaq(this.props.session, 0, (CallBackResponse) => {
      this.setState({ loading: false });
    });
  }

  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchVendorFaq(
        this.props.session,
        0,
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchVendorfaq(this.props.session, search);
    }
    this.setState({ search, pageNumber });
  };

  paginationCallback = (pageNumber) => {
    //  console.log("pgno", pageNumber)
    if (this.state.search == "") {
      this.props.fetchVendorFaq(
        this.props.session,
        pageNumber,
        (response) => {}
      );
    } else {
      this.props.searchVendorfaq(
        this.props.session,
        this.state.search,
        pageNumber
      );
    }

    this.setState({ ...this.state, pageNumber });
  };
  renderfaqlist() {
    let pageDataLength = this.props.allvendorfaq_list.data.length;

    let { pageNumber } = this.state;

    var items = _.map(this.props.allvendorfaq_list.data, (values) => (
      <VendorFaqlistitems
        key={values.userid}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    return items;
  }

  render() {
    let { language } = this.props;
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <div
            className="grid gap-4 grid-rows-1 md:px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 mt-5"
            style={{
              overflowX: "hidden",
              overflowY: "hidden",
              display: "block",
            }}>
            <Addvendorfaqs />
            <div
              className="relative md:w-6/12 xs:w-full text-ternary focus-within:text-secondary smallscreen-search "
              style={{ marginTop: -35 }}>
              <div
                className={classNames(
                  language == "en" ? "left-0 " : "right-0",
                  "absolute inset-y-0 flex items-center pointer-events-none px-1"
                )}>
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className={classNames(
                  language == "en" ? "pl-8 pr-3" : "pr-8 pl-3",
                  "block lg:w-6/12 xs:w-full sm:w-full h-full  py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                )}
                placeholder={LocaleStrings.search}
                type="search"
                name="search"
                // onChange={(e) =>
                //   this.setState({ customerSearchText: e.target.value })
                // }
                value={this.state.search}
                onChange={this.handleSearch}
              />
            </div>
            <div className="panel-body mt-6">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    {this.renderfaqlist()}
                  </div>
                </div>
              </div>
            </div>
            <Pagination
              activePage={this.state.pageNumber}
              itemsCountPerPage={7}
              totalItemsCount={this.props.allvendorfaq_list.count}
              pageRangeDisplayed={10}
              onChange={this.paginationCallback}
            />
            <NotificationContainer />
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, language } = state;

  return {
    session,
    user,
    language,
    allvendorfaq_list: state.allvendorfaq_list,
  };
}

export default connect(mapStateToProps, {
  fetchVendorFaq,
  searchVendorfaq,
})(VendorFaqs);
