import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
} from "../../../../../../common/constants";
import { fetchStoredetails } from "../../../actions/index";
import _ from "lodash";
import BaseLoader from "../../../../../../common/base-loader";

class Storedetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: "Store Details",
    };

    // All Binded Functions
  }
  componentDidMount(data) {
    this.props.fetchStoredetails(
      this.props.session,
      this.props.data.userid,
      "open",
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  renderlistings() {
    if (_.isEmpty(this.props.sellerstoredetails.contactnumber)) {
    } else {
      var nameArr = this.props.sellerstoredetails.contactnumber.split(",");
      var items = _.map(nameArr, (allnumbers, index) => {
        return (
          <div>
            <div className="flex">
              <p className="py-2"> {allnumbers}</p>
            </div>
          </div>
        );
      });
      return items;
    }
  }

  render() {
    var { sellerstoredetails, data } = this.props;

    var img = `${BASE_IMAGES_URL}/store/${sellerstoredetails.coverimage}?api_key=${APP_API_KEY}`;
    var map = `${BASE_IMAGES_URL}/store/${sellerstoredetails.mapimage}?api_key=${APP_API_KEY}`;
    var names = sellerstoredetails.contactnumber;

    let qatarlocation = [];
    if (sellerstoredetails && sellerstoredetails.country == "Qatar") {
      qatarlocation = sellerstoredetails.location.split("~");
    }
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {this.props.data.usertype == "personal" ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.storeprofile_personal_vendor_NA}
                </div>
              </>
            ) : _.isEmpty(this.props.sellerstoredetails) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.seller_management_storeinfonoData}
                </div>
              </>
            ) : (
              <>
                <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 px-7 bg-custWhite my-6">
                  <div></div>
                  <div>
                    <div className="text-lg  center-item mt-10">
                      {" "}
                      {data.username} {LocaleStrings.storeprofiledetails}
                    </div>
                    <div className="my-10">
                      <p className="text-gray-400">
                        {LocaleStrings.storeprofilecover}
                      </p>

                      <div className=" ">
                        <img className="h-4/6 w-full" src={img} />
                      </div>
                    </div>
                    <div className="my-10">
                      <p className="text-gray-400">
                        {LocaleStrings.storecontactname}
                      </p>
                      <div className="">{sellerstoredetails.contactname}</div>
                    </div>
                    <div className="my-10">
                      <p className="text-gray-400">
                        {LocaleStrings.storecontactnumber}
                      </p>
                      {this.renderlistings()}
                    </div>
                    <div className="my-10">
                      <p className="text-gray-400">
                        {LocaleStrings.storelocation}
                      </p>
                      {sellerstoredetails?.country == "Qatar" ? (
                        <>
                          <div className="mt-1">
                            {LocaleStrings.storeprofile_country}{" "}
                            {sellerstoredetails?.country}{" "}
                          </div>
                          <div className="mt-1">
                            {LocaleStrings.storeprofile_buildingno}{" "}
                            {qatarlocation[0]}{" "}
                          </div>
                          <div className="mt-1">
                            {LocaleStrings.storeprofile_zone} {qatarlocation[1]}{" "}
                          </div>
                          <div className="mt-1">
                            {LocaleStrings.storeprofile_street}{" "}
                            {qatarlocation[2]}{" "}
                          </div>
                          <div className="mt-1">
                            P.O Box: {sellerstoredetails.zipcode}{" "}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="">
                            {LocaleStrings.storeprofile_country}{" "}
                            {sellerstoredetails.country}
                          </div>
                          <div className="">{sellerstoredetails.location}</div>
                        </>
                      )}
                    </div>

                    <div className="my-10">
                      <p className="text-gray-400">{LocaleStrings.storemap}</p>
                      {sellerstoredetails.mapimage == "" ||
                      sellerstoredetails.mapimage == null ? (
                        <>{LocaleStrings.storeprofile_no_mapimage}</>
                      ) : (
                        <div className="">
                          <img className="h-3/6 w-full" src={map} />
                        </div>
                      )}
                    </div>

                    {sellerstoredetails.awayenddate == "0000-00-00 00:00:00" ||
                    sellerstoredetails.awaystartdate == "0000-00-00 00:00:00" ||
                    sellerstoredetails.awayenddate == null ||
                    sellerstoredetails.awaystartdate == null ||
                    sellerstoredetails.awayenddate == "" ||
                    sellerstoredetails.awaystartdate == "" ? (
                      ""
                    ) : (
                      <>
                        <p className="text-gray-400">
                          {LocaleStrings.awaytime}
                        </p>
                        <div className="mb-10 " style={{ display: "flex" }}>
                          <div className="mr-3">
                            {sellerstoredetails.awaystartdate}
                          </div>
                          <div className="mr-3"> To</div>
                          {sellerstoredetails.awayenddate}
                        </div>
                      </>
                    )}
                    <div className="text-gray-400">
                      {LocaleStrings.automaticresponse}
                    </div>
                    <div className="mb-5">{sellerstoredetails.automessage}</div>
                  </div>
                  <div></div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  //   console.log('sellerstoredetails',state.sellerstoredetails);
  var newObj = {
    ...state.sellerstoredetails.data,
    ...state.StoreContacts.data,
  };
  // console.log("newObj", newObj);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    data: ownProps.data,
    sellerstoredetails: newObj,
  };
}

export default connect(mapStateToProps, { fetchStoredetails })(Storedetails);
