import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";

import { SearchIcon } from "@heroicons/react/solid";
import AuctionListsellerTabs from "./auctiontabs";

var Loader = require("react-loaders").Loader; // import "AwesomeLoaderCss";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class AuctionSellerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    // All Binded Functions
  }

  customerdetails = (e) => {
    this.setState({ showUploadSection: !this.state.showUploadSection });
  };

  render() {
    return (
      <>
        <div className="py-6">
          {/* Header Part */}
          <AuctionListsellerTabs
            data={this.props.data}
            showUploadSection={this.customerdetails}
          />
        </div>
      </>
    );
  }
}
function mapStateToProps(state, ownProps) {
  var { session, user, oderlist } = state;
  // console.log('oderlist',state.oderlist);
  return {
    session,
    user,
    oderlist,
    isLoggedIn: state.isLoggedIn,
    data: ownProps.data,
  };
}
export default connect(mapStateToProps, {})(AuctionSellerList);
