import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../languages/index.js";
import { Fragment } from "react";
import { BaseComponent } from "../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { reduxForm } from "redux-form";
import { postReply } from "../../actions/index";
import _ from "lodash";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../../common/constants";
import moment from "moment";

class ReportViewModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
    };

    // All Binded Functions
  }

  renderStatusMedia = (status_media) => {
    var item = _.map(status_media, (media, index) => {
      var imageUrl = `${BASE_IMAGES_URL}/reviews/${media}?api_key=${APP_API_KEY}`;
      return (
        <div className=" mr-3">
          <img
            src={imageUrl}
            className="border-2 border-quaternary h-20 w-20 "
            alt="image"
          />
        </div>
      );
    });

    return item;
  };

  render() {
    // console.log('initialValues',this.props.initialValues);
    var { data, Reports_allview } = this.props;
    // console.log('Reports_allview',Reports_allview)
    if (Reports_allview.hasOwnProperty("product")) {
      var reportedon = "Reported Product";
      var productimg = `${BASE_IMAGES_URL}/vendor/variations/${Reports_allview.product.productimage}?api_key=${APP_API_KEY}`;
      var ModalConent = (
        <div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-1 lg:max-w-none">
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={productimg}
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-primary">
                    {Reports_allview.product.productname_en}
                  </p>

                  {/* <p className="text-xl font-semibold text-gray-900">1</p> */}
                  <p className="mt-3 text-base text-gray-500">
                    {Reports_allview.product.product_desc_en}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Reports_allview.hasOwnProperty("qa")) {
      var reportedon = " Reported Question & Answer";
      var img = `${BASE_IMAGES_URL}/vendor/variations/${Reports_allview.qa.product.productimage}?api_key=${APP_API_KEY}`;
      var ModalConent = (
        <div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-1 lg:max-w-none">
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={img}
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "../images/product_management_images/default.jpeg";
                  }}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-primary">
                    {Reports_allview.qa.product.productname_en}
                  </p>

                  <p className="text-xl font-semibold text-gray-900">
                    Reported Question:- {Reports_allview.qa.question}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    Answer:- {Reports_allview.qa.answer}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Reports_allview.hasOwnProperty("profile")) {
      var reportedon = "Reported  Profile";
      var userimg = `${BASE_IMAGES_URL}/users/${Reports_allview.profile.userid}.jpg?api_key=${APP_API_KEY}`;
      var ModalConent = (
        <div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-1 lg:max-w-none">
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={userimg}
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../images/user_1.png";
                  }}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-primary">
                    {Reports_allview.profile.username}
                  </p>

                  {/* <p className="text-xl font-semibold text-gray-900">{Reports_allview.profile.username}</p> */}
                  {/* <p className="mt-3 text-base text-gray-500">{Reports_allview.product.product_desc_en}</p> */}
                  <p className="mt-3 text-base text-gray-500">
                    {Reports_allview.profile.email}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {_.startCase(Reports_allview.profile.gender)}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {Reports_allview.profile.usertype !== "vendor" ||
                    Reports_allview.profile.usertype !== "company"
                      ? Reports_allview.profile.dob
                      : ""}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {Reports_allview.profile.phone}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Reports_allview.hasOwnProperty("comment")) {
      var reportedon = "Reported Comment";
      var userimg = `${BASE_IMAGES_URL}/users/${Reports_allview.comment.commentby.userid}.jpg?api_key=${APP_API_KEY}`;
      var ModalConent = (
        <div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-1 lg:max-w-none">
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={userimg}
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../images/user_1.png";
                  }}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="mt-3 text-base text-gray-500">
                    REPORTED COMMENT :- {Reports_allview.comment.comment}
                  </p>
                  {/* <p className="text-xl font-semibold text-gray-900">{Reports_allview.profile.username}</p> */}
                  {/* <p className="mt-3 text-base text-gray-500">{Reports_allview.product.product_desc_en}</p> */}
                  <span className="flex mt-3 ">
                    <p className="text-base text-gray-500">COMMENTED BY :- </p>
                    <p className="text-md font-medium text-primary ml-2">
                      {" "}
                      {Reports_allview.comment.commentby.username}
                    </p>
                  </span>
                  <p className="mt-3 text-base text-gray-500">
                    {_.startCase(Reports_allview.comment.commentby.gender)}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    Commented On : - {Reports_allview.createdat}
                  </p>
                  {/* <p className="mt-3 text-base text-gray-500">{Reports_allview.comment.commentby.phone}</p>  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Reports_allview.hasOwnProperty("review")) {
      var reportedon = "Reported Review";
      var reviewMedia = _.filter(Reports_allview.review.product.reviews, {
        id: Reports_allview.reviewid,
      });
      // console.log("reviewMedia", reviewMedia);
      var media = <div className="p-4">No review media</div>;
      if (reviewMedia.length && reviewMedia[0].media.length > 0) {
        media = (
          <div className="p-4">
            {this.renderStatusMedia(reviewMedia[0].media)}
          </div>
        );
      } else {
      }
      // console.log('reviewMedia',reviewMedia)
      // var userimg = `${BASE_IMAGES_URL}/users/${Reports_allview.comment.commentby.userid}.jpg?api_key=${APP_API_KEY}`;
      var ModalConent = (
        <div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-1 lg:max-w-none">
            <div>Review Media</div>
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              {media}

              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="mt-3 text-base text-gray-500">
                    REPORTED TEXT :- {Reports_allview.review.feedback}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    var img = `${BASE_IMAGES_URL}/users/${data.profile.userid}.jpg?api_key=${APP_API_KEY}`;
    let dob = "";
    dob = moment(data.profile.dob).format("D MMM YYYY");
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.myRef}
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ">
                  <div className="grid gap-4  md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2  border-b-2 border-gray-50 p-6">
                    <div className="">{reportedon}</div>
                    <div className="flex flex-wrap content-center justify-end">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}
                        ref={this.myRef}>
                        <span className="sr-only"></span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div
                    className="grid gap-4  grid-cols-1 mt-5 flex flex-wrap content-center justify-center"
                    style={{ overflowX: "hidden" }}>
                    <div className="mt-1 flex items-center flex flex-wrap content-center justify-center">
                      <div className="">
                        {/* <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg> */}
                        <img
                          className="w-32 h-32 flex-shrink-0 mx-auto rounded-full"
                          src={img}
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "../images/user_1.png";
                          }}
                        />
                        {/* <img src={img}  /> */}
                      </div>
                    </div>
                    <div className="mt-1 flex items-center flex flex-wrap content-center justify-center">
                      {data.profile.username}(
                      {_.startCase(data.profile.usertype)})
                    </div>
                    <hr />
                    {data.profile.gender ? (
                      <div className="mt-1 flex items-center flex flex-wrap content-center justify-center my-3">
                        <img
                          className="h-8 w-8 mx-2"
                          src="../images/about/gender.svg"
                        />
                        {_.startCase(data.profile.gender)}
                      </div>
                    ) : (
                      ""
                    )}
                    {data.profile.dob ? (
                      <div className="flex items-center flex flex-wrap content-center justify-center my-3">
                        <img
                          className="h-8 w-8 mx-2"
                          src="../images/about/calender.svg"
                        />
                        {dob}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="flex items-center flex flex-wrap content-center justify-center my-3">
                      <img
                        className="h-8 w-8 mx-2"
                        src="../images/about/email.svg"
                      />
                      {data.profile.email}
                    </div>
                    {data.profile.phone ? (
                      <div className="flex items-center flex flex-wrap content-center justify-center my-3">
                        <img
                          className="h-8 w-8 mx-2"
                          src="../images/about/phone.svg"
                        />
                        {data.profile.phone}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}
function validate(values) {
  // console.log("Validate ",values);
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.emailrequired;
  }

  if (!values.username) {
    errors.password = LocaleStrings.firstnamerequire;
  }

  if (!values.phone) {
    errors.passcode = LocaleStrings.phonenumberrequired;
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log("session", state.Reports_allview);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    Reports_allview: state.Reports_allview,
    data: ownProps.data,
  };
}

export default connect(mapStateToProps, {
  postReply,
})(
  reduxForm({
    validate,
    form: "VeiwReportModal",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ReportViewModal)
);
