import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import LocaleStrings from "../../../../languages/index";
import _ from "lodash";
import moment from "moment-timezone";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import { ProductTextLocalized } from "../../../../../common/common-functions";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  BASE_STRING,
  validatePercent,
  DATE_FORMAT_WITH_TIME_WITH_SECOND,
} from "../../../../../common/constants";
import { format, addDays, parse } from "date-fns";
// Import Actions
import {
  isCouponManageModalopen,
  fetchProductsByCateSubCate,
  addCouponMaster,
  addCouponRule,
  fetchCouponMaster,
  getSelectedCouponsObj,
  updateCouponMaster,
  updateCouponRule,
} from "../../actions/index";

// Functions Import
import { classNames, isEmpty } from "../../../../../common/common-functions";

// Component Import

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
var voucher_codes = require("voucher-code-generator");

class CouponsManagementModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      loading: true,
      discounttype: "",
      criteriaRuleType: "",
      selectedCateId: "",
      selectedSubCateId: "",
      selectedProductId: "",
      couponText: "",
      statex: "",
      btnLoader: false,
      isBtnDisable: false,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.changestate = this.changestate.bind(this);
    this.handelDiscountType = this.handelDiscountType.bind(this);
  }

  changestate() {
    this.setState({ statex: "0" });
  }

  componentWillUnmount() {
    this.reinitializeAllLocalStates();
  }

  /**** Fake Close ****/
  closeModal1() {}

  /**** CLOSE MODAL  ****/
  closeModal() {
    this.reinitializeAllLocalStates();
  }

  /* Clear all local objects */
  reinitializeAllLocalStates() {
    // Clear Single Discount Object (Pointing to)
    this.props.getSelectedCouponsObj({}, this.props.session);
    this.props.isCouponManageModalopen(false);

    this.setState({ loading: "" });
    this.setState({ discounttype: "" });
    this.setState({ criteriaRuleType: "" });
    this.setState({ selectedSubCateId: "" });
    this.setState({ selectedProductId: "" });
    this.setState({ statex: "" });
    this.setState({ btnLoader: false });
    this.setState({ isBtnDisable: false });
    this.props.reset("addCouponForm");
  }

  /**** OnClick Change ****/
  handelDiscountType(param) {
    this.setState({ discounttype: param });
  }

  /**** Special Text Field ****/
  renderField(field) {
    var {
      meta: { touched, error },
    } = field;

    var className = `form-group text-help  ${
      touched && error ? "text-danger text-xs" : ""
    }`;

    return (
      <>
        <label className={field.classNameLabel}>{field.label}</label>

        <input
          className="focus:ring-primary focus:border-primary block w-full pr-20 sm:text-sm border-ternary rounded-md"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />

        <div className={className}>{touched ? error : ""}</div>
      </>
    );
  }

  /***** Form Submit *****/
  onFormSubmit(values) {
    if (_.isEmpty(values)) {
      // console.log("values", values);
      return false;
    }
    /* Date validation */
    let validity_from = "";
    let validity_end = "";

    if (typeof values.validity_from === "string") {
      validity_from = parse(
        moment(values.validity_from).format("DD-MM-YYYY HH:mm:ss"),
        "dd-MM-yyyy HH:mm:ss",
        new Date()
      );
    } else {
      validity_from = values.validity_from;
    }

    if (typeof values.validity_end === "string") {
      validity_end = parse(
        moment(values.validity_end).format("DD-MM-YYYY HH:mm:ss"),
        "dd-MM-yyyy HH:mm:ss",
        new Date()
      );
    } else {
      validity_end = values.validity_end;
    }

    if (validity_end.getTime() < validity_from.getTime()) {
      NotificationManager.error(
        LocaleStrings.validity_end_date_and_greater_date_validation_text
      );
      return false;
    }

    /* Discount Validation */
    if (!_.isNaN(parseFloat(values.discount))) {
      if (parseFloat(values.discount) < 0) {
        NotificationManager.error(LocaleStrings.discount_int_validation);
        return false;
      }
    } else {
      NotificationManager.error(LocaleStrings.discount_invalid);
      return false;
    }
    if (!_.isNaN(parseFloat(values.times))) {
      if (parseFloat(values.times) < 1) {
        NotificationManager.error("Should be greater than 0");
        return false;
      }
    } else {
      NotificationManager.error("Should be greater than 0");
      return false;
    }

    this.setState({ btnLoader: true }); // Start The button Loader
    this.setState({ isBtnDisable: true }); // Start The button Loader
    let { initialValues } = this.props; // Get initialValues Object
    let { discounttype } = this.state; // Get State Discount Type Value

    if (Object.keys(initialValues).length > 0) {
      // Checking if it's edit
      discounttype =
        discounttype === "" ? initialValues.discounttype : discounttype;
    }

    // Discount Type Not Selected Then Show alert
    if (isEmpty(discounttype)) {
      this.setState({ btnLoader: false });
      this.setState({ isBtnDisable: false });
      NotificationManager.error(LocaleStrings.discount_type_require);
      return false;
    } else {
      // PERCENTAGE VALIDATION
      if (
        discounttype === "percentage" &&
        validatePercent(values.discount) === false
      ) {
        this.setState({ btnLoader: false });
        this.setState({ isBtnDisable: false });
        NotificationManager.error(LocaleStrings.discount_percentage_not_valide);
        return false;
      }
    }

    // Valide

    var obj = {
      discounttype: discounttype,
      title: _.trim(values.title),
      code: _.trim(values.code),
      criteriaid: values.criteriaid,
      min_cart_value: values.min_cart_value ? values.min_cart_value : "null",
      active: values.active,
      validity_from: moment(values.validity_from).format(
        DATE_FORMAT_WITH_TIME_WITH_SECOND
      ),
      validity_end: moment(values.validity_end).format(
        DATE_FORMAT_WITH_TIME_WITH_SECOND
      ),
      discount: values.discount,
      createdby: this.props.session.userid,
    };

    // date Validation
    if (
      moment(values.validity_from).unix() >= moment(values.validity_end).unix()
    ) {
      NotificationManager.error(LocaleStrings.invalid_date_range);
      this.setState({ btnLoader: false }); // End The button Loader
      this.setState({ isBtnDisable: false }); // End The button Loader
      return false;
    } else {
      if (values.id) {
        // Edit Function call [ Edit Discount / Discount Area ]
        obj["id"] = values.id;

        this.props.updateCouponMaster(this.props.session, obj, (callBack) => {
          var obj2 = {
            couponid: values.id,
            id: initialValues.couponrule_by_couponid[0].id,
            categoryid: values.categoryid,
            subcategoryid: values.subcategoryid,
            productid: values.productid,
            times: values.times,
          };

          // Update rules
          this.props.updateCouponRule(this.props.session, obj2, (callBack) => {
            NotificationManager.success(
              LocaleStrings.coupon_updated_successfully
            );
            this.closeModal();
            this.setState({ btnLoader: false });

            // Clear Single Discount Object (Pointing to)
            this.props.getSelectedCouponsObj({}, this.props.session);

            // Refresh Discount Master List
            this.props.fetchCouponMaster(this.props.session, (callBack) => {
              // Close Modal
            });
          });
        });
      } else {
        /**** Add function call ****/
        // Add Coupon
        this.props.addCouponMaster(this.props.session, obj, (callBack) => {
          var obj2 = {
            couponid: callBack.data,
            categoryid: values.categoryid,
            subcategoryid: values.subcategoryid,
            productid: values.productid,
            times: values.times,
          };
          // Add riles
          this.props.addCouponRule(this.props.session, obj2, (callBack) => {
            NotificationManager.success(
              LocaleStrings.coupon_added_successfully
            );
            this.closeModal();
            this.setState({ btnLoader: false });

            // Clear Single Discount Object (Pointing to)
            this.props.getSelectedCouponsObj({}, this.props.session);

            // Refresh Discount Master List
            this.props.fetchCouponMaster(this.props.session, (callBack) => {
              // Close Modal
            });
          });
        });
      }
    }
  }

  // criteria type On Ochange
  _handleCriteriaScreen(e) {
    let ruleType = "";
    if (e.target.value !== "" && e.target.value !== null) {
      ruleType = _.find(this.props.criteriaMaster, {
        id: parseInt(e.target.value),
      }).rule;
    }
    this.setState({ criteriaRuleType: ruleType });
  }

  // Category / Subcategory  / Product Select
  _handlecategoryidScreen(e) {
    let selectedFiledName = e.target.name;
    let selectedFiledValue = e.target.value;

    if (selectedFiledName === "categoryid") {
      // Category
      this.setState({ selectedCateId: selectedFiledValue });

      // If category || Sub-category present fetch Product
      // this.props.fetchProductsByCateSubCate(
      //   this.props.session,
      //   selectedFiledValue,
      //   "",
      //   (callBack) => {}
      // );
    } else if (selectedFiledName === "subcategoryid") {
      // Sub-category
      this.setState({ selectedSubCateId: selectedFiledValue });
      let cateID = this.state.selectedCateId;
      // If category || Sub-category present fetch Product
      this.props.fetchProductsByCateSubCate(
        this.props.session,
        cateID,
        selectedFiledValue,
        (callBack) => {}
      );
    } else if (selectedFiledName === "productid") {
      // product
      this.setState({ selectedProductId: selectedFiledValue });
    } else {
    }
  }

  // Generate 10 digit coupon code
  _handleGenerateBtn() {
    let x = voucher_codes.generate({
      length: 10,
      count: 1,
      charset: BASE_STRING,
    });
    this.props.autofill("code", x[0]);
  }

  // Coupon Input box Onchange
  _handleCodeinput(e) {
    this.setState({ couponText: e.target.value });
  }

  render() {
    let { initialValues, language, handleSubmit, productSubCategory } =
      this.props;
    let { criteriaRuleType, selectedCateId, selectedSubCateId, discounttype } =
      this.state;
    let modalHeadText = "";
    let modalFooterText = "";

    if (Object.keys(initialValues).length > 0) {
      //EDIT
      discounttype =
        discounttype === "" ? initialValues.discounttype : discounttype;
      criteriaRuleType =
        criteriaRuleType === ""
          ? _.find(this.props.criteriaMaster, {
              id: parseInt(initialValues.criteriaid),
            }).rule
          : criteriaRuleType;
      selectedCateId =
        selectedCateId === "" ? initialValues.categoryid : selectedCateId;
      modalHeadText = LocaleStrings.edit_coupon;
      modalFooterText = LocaleStrings.update_coupon;
    } else {
      //ADD
      modalHeadText = LocaleStrings.add_new_coupons;
      modalFooterText = LocaleStrings.add_new_coupons;
    }

    var criteriaRuleOptions = []; // Discount Area
    var discountCateOptions = []; // Category
    var discountSubCateOptions = []; // Sub-Category
    var discountProductOptions = []; // Product

    //Coupon Rule Object Creation
    _.forEach(this.props.criteriaMaster, function (value) {
      var obj = {
        name: ProductTextLocalized(
          value.criteria_en,
          value.criteria_ar,
          language
        ),
        value: value.id,
      };
      criteriaRuleOptions.push(obj);
    });

    //Discount Category Object Creation
    _.forEach(this.props.productCategory, function (value) {
      var obj = {
        name: ProductTextLocalized(
          value.categoryname_en,
          value.categoryname_ar,
          language
        ),
        value: value.id,
      };

      discountCateOptions.push(obj);
    });

    //Discount Sub-Category Object Creation

    let filterSubcateArr = _.filter(this.props.productSubCategory, {
      categoryid: parseInt(selectedCateId),
    });

    _.forEach(filterSubcateArr, function (value) {
      let subSubCategory = _.filter(productSubCategory, {
        parentid: parseInt(value.id),
      });

      if (subSubCategory.length > 0) {
        // Sub sub category if exsist
        _.forEach(subSubCategory, function (subSubObject, subSubIndex) {
          // loop through Sub sub category

          var obj = {
            name: ProductTextLocalized(
              value.subcategoryname_en +
                " - " +
                subSubObject.subcategoryname_en,
              value.subcategoryname_ar +
                " - " +
                subSubObject.subcategoryname_ar,
              language
            ),
            value: subSubObject.id,
          };

          discountSubCateOptions.push(obj);
        });
      } else {
        var obj = {
          name: ProductTextLocalized(
            value.subcategoryname_en,
            value.subcategoryname_ar,
            language
          ),
          value: value.id,
        };

        discountSubCateOptions.push(obj);
      }
    });

    if (Object.keys(initialValues).length > 0) {
      // Edit
      _.forEach(this.props.productByCateSubCate, function (value) {
        var obj = {
          name: ProductTextLocalized(
            value.productname_en,
            value.productname_ar,
            language
          ),
          value: value.id,
        };

        discountProductOptions.push(obj);
      });
    } else {
      // Add
      if (selectedSubCateId) {
        //Discount Product (On select Cate | Subcate) Object Creation
        _.forEach(this.props.productByCateSubCate, function (value) {
          var obj = {
            name: ProductTextLocalized(
              value.productname_en,
              value.productname_ar,
              language
            ),
            value: value.id,
          };

          discountProductOptions.push(obj);
        });
      } else {
        discountProductOptions = [];
      }
    }
    return (
      <>
        <Transition.Root show={this.props.isCoupManageOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.myRef}
            open={this.props.isCoupManageOpen}
            onClose={this.closeModal1}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0 }}>
                  {/* MODAL HEADER */}
                  <div className="grid grid-cols-6 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center mt-4 col-span-4">
                      <div className="text-lg leading-6 font-medium text-secondary">
                        {modalHeadText}
                      </div>
                    </div>
                    <div className="absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.closeModal}
                        ref={this.myRef}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    {/* MODAL HEADER ENDS */}
                    <div className="p-6 space-y-8">
                      <Field
                        name="title"
                        label={LocaleStrings.coupon_title}
                        placeholder={LocaleStrings.enter_coupon_title}
                        type="text"
                        component={this.renderAllfields}
                        mandatory="true"
                        labelposition={LABEL_POSITION_TOP}
                        classNameLabel="text-sm font-medium text-secondary"
                      />
                      <Field
                        name="code"
                        label={LocaleStrings.coupon_code}
                        placeholder={LocaleStrings.enter_coupon_code}
                        type="text"
                        component={this.renderAllfields}
                        mandatory="true"
                        isdisabled={
                          Object.keys(initialValues).length > 0 ? true : ""
                        }
                        labelposition={LABEL_POSITION_TOP}
                        classNameLabel="text-sm font-medium text-secondary"
                      />
                      {Object.keys(initialValues).length > 0 ? (
                        ""
                      ) : (
                        <button
                          className="btn-primary mt-1"
                          type="button"
                          onClick={this._handleGenerateBtn.bind(this)}>
                          {LocaleStrings.coupon_generate_text}
                        </button>
                      )}

                      <Field
                        name="validity_from"
                        label={LocaleStrings.validity_starting_date}
                        placeholder={LocaleStrings.select_validity_from}
                        component={this.renderDateTimePickerWithLabel1}
                        mandatory="true"
                        autoComplete="off"
                        // selected={moment()}
                        minDate={
                          Object.keys(initialValues).length > 0
                            ? ""
                            : new Date(moment().format("YYYY,MM,DD"))
                        }
                        className="cust-input-field"
                        classNameLabel="text-box-lable"
                      />
                      <Field
                        name="validity_end"
                        label={LocaleStrings.validity_end_date}
                        placeholder={LocaleStrings.select_validity_until}
                        component={this.renderDateTimePickerWithLabel1}
                        mandatory="true"
                        minDate={
                          Object.keys(initialValues).length > 0
                            ? ""
                            : new Date(moment().format("YYYY,MM,DD"))
                        }
                        // selected={moment()}
                        className="cust-input-field"
                        classNameLabel="text-box-lable"
                      />

                      <div>
                        <Field
                          name="criteriaid"
                          label={LocaleStrings.select_criteria}
                          placeholder={LocaleStrings.select}
                          component={this.renderSelect}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          opts={criteriaRuleOptions}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          onChange={this._handleCriteriaScreen.bind(this)}
                        />
                      </div>
                      {criteriaRuleType === "min_cart_val" ? (
                        <div>
                          <Field
                            name="min_cart_value"
                            label={LocaleStrings.cart_amount}
                            placeholder={LocaleStrings.enter_cart_amount}
                            type="number"
                            component={this.renderAllfields}
                            mandatory="true"
                            min="0"
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-sm font-medium text-secondary"
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {/* renderField */}
                      <Field
                        name="discount"
                        label={LocaleStrings.discount}
                        placeholder={LocaleStrings.discount}
                        type="text"
                        component={this.renderFieldTextDiscount}
                        labelposition={LABEL_POSITION_TOP}
                        mandatory="true"
                        className="cust-input-field"
                        classNameLabel="text-box-lable"
                        discountType={discounttype}
                        handelDiscountType={this.handelDiscountType}
                      />
                      <div>
                        <p className="text-xs font-semibold text-secondary">
                          {LocaleStrings.coupon_applicable_rule}
                        </p>
                        <p className="mt-1 text-xs text-ternary">
                          {LocaleStrings.coupon_applicable_rule_subtext}
                        </p>
                      </div>

                      <Field
                        name="categoryid"
                        label={LocaleStrings.discount_product_category}
                        placeholder={LocaleStrings.select}
                        component={this.renderSelect}
                        mandatory="true"
                        labelposition={LABEL_POSITION_TOP}
                        opts={discountCateOptions}
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                        onChange={this._handlecategoryidScreen.bind(this)}
                      />

                      <Field
                        name="subcategoryid"
                        label={LocaleStrings.discount_product_subcategory}
                        placeholder={LocaleStrings.select}
                        component={this.renderSelect}
                        mandatory="false"
                        labelposition={LABEL_POSITION_TOP}
                        opts={discountSubCateOptions}
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                        onChange={this._handlecategoryidScreen.bind(this)}
                      />

                      {/* <Field
                           name="productid"
                           label={LocaleStrings.discount_product}
                           placeholder={LocaleStrings.select}
                           component={this.renderSelect}
                           mandatory="false"
                           labelposition={LABEL_POSITION_TOP}
                           opts={discountProductOptions}
                           className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                           classNameLabel="block text-box-lable"
                           onChange={this._handlecategoryidScreen.bind(this)}
                         /> */}

                      <Field
                        name="times"
                        label={LocaleStrings.applicable_time}
                        placeholder={LocaleStrings.applicable_time_text}
                        type="number"
                        component={this.renderFieldText}
                        mandatory="true"
                        min="1"
                        labelposition={LABEL_POSITION_TOP}
                        classNameLabel="text-sm font-medium text-secondary"
                      />
                    </div>
                    <div className="p-6 border-t-2 border-quaternary">
                      {/* Footer */}
                      <button
                        // className="btn-primary"
                        type="submit"
                        disabled={this.state.isBtnDisable}
                        className={classNames(
                          this.state.isBtnDisable ? "cursor-not-allowed" : "",
                          "btn-primary"
                        )}>
                        <svg
                          className={classNames(
                            this.state.btnLoader ? "" : "hidden",
                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                          )}
                          disabled={this.state.isBtnDisable}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        {modalFooterText}
                      </button>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = [
    "title",
    "discount",
    "criteriaid",
    "code",
    "times",
    "categoryid",
  ];

  var requiredDateFields = ["validity_from", "validity_end"];

  requiredFields.forEach((field) => {
    if (!values[field] || _.trim(values[field]) === "") {
      errors[field] = "Required";
    }
  });

  requiredDateFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
  });
  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log('test',state.singleCouponObject)
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isCoupManageOpen: state.isCouponManageModalopen,
    productCategory: state.productCategory
      ? state.productCategory.data !== "undefined"
        ? state.productCategory.data
        : []
      : [],
    productSubCategory: state.productSubCategory
      ? state.productSubCategory.data !== "undefined"
        ? state.productSubCategory.data
        : []
      : [],
    criteriaMaster: state.criteriaMaster
      ? state.criteriaMaster.data !== "undefined"
        ? state.criteriaMaster.data
        : []
      : [],
    productByCateSubCate: state.productByCateSubCate
      ? state.productByCateSubCate.data !== "undefined"
        ? state.productByCateSubCate.data
        : []
      : [],
    initialValues: state.singleCouponObject,
  };
}

export default connect(mapStateToProps, {
  isCouponManageModalopen,
  fetchProductsByCateSubCate,
  addCouponMaster,
  addCouponRule,
  fetchCouponMaster,
  getSelectedCouponsObj,
  updateCouponMaster,
  updateCouponRule,
})(
  reduxForm({
    validate,
    form: "addCouponForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(CouponsManagementModal)
);
