import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, FieldArray } from "redux-form";
import LocaleStrings from "../../../languages/index";
import _ from "lodash";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../common/base-component";
import { ProductTextLocalized } from "../../../../common/common-functions";
import {
  isOpenSpecsManagModal,
  fetchProductsByCateSubCate,
  addnewSpecification,
  fetchSpecification,
  getSelectedSpecsObj,
} from "../actions/index";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon, TrashIcon } from "@heroicons/react/outline";
import { NotificationManager } from "react-notifications";
import { classNames } from "../../../../common/common-functions";

const limit = 10;

class Addspecificationmodal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      discounttype: "",
      specificationAreaType: "",
      selectedCateId: "",
      selectedSubCateId: "",
      selectedProductId: "",
      btnLoader: false,
      mandatoryspecs: "",
      pageNumber: 1,
      btnLoader: false,
      enar: "en",
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {}

  /**** Fake Close ****/
  closeModal1() {}

  /**** Close Modal  ****/
  closeModal() {
    // Clear Single Discount Object (Pointing to)
    this.props.getSelectedSpecsObj({}, this.props.session);
    this.props.isOpenSpecsManagModal(false);
    this.props.reset();
  }

  // /**** OnClick Change ****/
  // handleClickDiscountType(e, param) {
  //   this.setState({ discounttype: param });
  // }

  onFormSubmit(values) {
    this.setState({ btnLoader: true });
    if (Object.keys(values).length === 0) {
      NotificationManager.error(LocaleStrings.common_mandatory_required);
      this.setState({ btnLoader: false });
      return false;
    }
    // console.log('values',values);
    if (
      !values.name_en ||
      !values.name_ar ||
      !values.value_en ||
      !values.value_ar
    ) {
      NotificationManager.error("Please Fill All Mandatory Fields");
      this.setState({ btnLoader: false });
      return false;
    }

    if (
      _.trim(values.name_en) == "" ||
      _.trim(values.name_ar) == "" ||
      _.trim(values.value_en) == "" ||
      _.trim(values.value_ar) == ""
    ) {
      NotificationManager.error("Please Fill All Mandatory Fields");
      this.setState({ btnLoader: false });
      return false;
    }
    let value_enArr = [];
    let value_arArr = [];
    let value_errorArr = [];

    value_enArr = _.split(values.value_en, ",");
    value_arArr = _.split(values.value_ar, ",");

    if(value_enArr.length != value_arArr.length){
      this.setState({ btnLoader: false });
      NotificationManager.error("Please add same number of comma separted values for both languages");
      return false;
    }else{
      let blankvalue = 0;
      _.forEach(value_enArr,(v,i) =>{
        if(_.trim(v) == ""){
          blankvalue = 1;
        }
     })
     _.forEach(value_arArr,(v1,i1) =>{
      if(_.trim(v1) == ""){
        blankvalue = 1;
      }
     });

     if(blankvalue != 0){
      this.setState({ btnLoader: false });
      NotificationManager.error("Please add same number of comma separted values for both languages");
      return false;
     }
    }
    // if (values.value.length > 0) {
    //   values.value.map((val, key) => {
    //     let obj = val.value_en != "" ? val.value_en : null;
    //     let obj1 = val.value_ar != "" ? val.value_ar : null;
    //     if (obj == null || obj1 == null) {
    //       value_errorArr.push(1);
    //     }
    //     value_enArr.push(obj);
    //     value_arArr.push(obj1);
    //   });
    //   var value_en_String = value_enArr.join(",");
    //   var value_ar_String = value_arArr.join(",");
    // } else {
    //   value_errorArr.push(1);
    // }
    // if (value_errorArr.length > 0) {
    //   NotificationManager.error("Please add value for both languages");
    //   this.setState({ btnLoader: false });
    //   return false;
    // }
    // if (values.isglobal == 1) {
    //   var specificationadded = {
    //     isglobal: values.isglobal,
    //     name_en: values.name_en ? values.name_en : null,
    //     name_ar: values.name_ar ? values.name_ar : null,
    //     ismandatory: values.ismandatory,
    //     value_en: value_en_String,
    //     value_ar: value_ar_String,
    //     isvariant: values.isvariant == true ? 1 : 0,
    //   };
    //   // console.log("specificationadded", specificationadded);
    //   this.props.addnewSpecification(
    //     this.props.session,
    //     specificationadded,
    //     (response) => {
    //       if (response.success === 0) {
    //         this.setState({ btnLoader: false });
    //         NotificationManager.error(LocaleStrings.addingfailed);
    //       } else {
    //         NotificationManager.success(LocaleStrings.addedsucessfully);
    //         this.setState({ btnLoader: false });
    //         this.props.isOpenSpecsManagModal(false);
    //         this.props.reset();
    //         this.props.fetchSpecification(
    //           this.props.session,
    //           limit,
    //           this.state.pageNumber,
    //           "listing",
    //           "",
    //           (CallBackResponse) => {}
    //         );
    //       }
    //     }
    //   );
    // } else if (values.isglobal == 0) {
    if (!values.hasOwnProperty("idcategory")) {
      NotificationManager.error(LocaleStrings.specs_select_Cat);
      this.setState({ btnLoader: false });
      return false;
    } else {
      if (!values.hasOwnProperty("idsubcategory")) {
        let specscategory = [];
        values.idcategory.map((val, key) => {
          let obj = val.value;
          specscategory.push(obj);
        });
        var arrspecscategoryString = specscategory.join(",");

        // console.log("arrspecscategoryString", arrspecscategoryString);
        // console.log("arrspecsSubcategoryString", arrspecsSubcategoryString);
        var specificationadded = {
          // isglobal: values.isglobal,
          categoryids: arrspecscategoryString,
          // subcategoryids: arrspecsSubcategoryString,
          name_en: values.name_en ? values.name_en : null,
          name_ar: values.name_ar ? values.name_ar : null,
          ismandatory: values.ismandatory,
          // value_en: value_en_String,
          // value_ar: value_ar_String,
          value_en: values.value_en ? values.value_en : null,
          value_ar: values.value_ar ? values.value_ar : null,
          isvariant: values.isvariant == true ? 1 : 0,
        };
        // console.log("specificationadded", specificationadded);
        this.props.addnewSpecification(
          this.props.session,
          specificationadded,
          (response) => {
            if (response.success === 0) {
              this.setState({ btnLoader: false });
              NotificationManager.error(LocaleStrings.addingfailed);
              this.props.reset();
            } else {
              this.setState({ btnLoader: false });
              NotificationManager.success(LocaleStrings.addedsucessfully);
              this.props.isOpenSpecsManagModal(false);
              this.props.reset();
              this.props.fetchSpecification(
                this.props.session,
                limit,
                this.state.pageNumber,
                "listing",
                "",
                (CallBackResponse) => {}
              );
            }
          }
        );
      } else {
        let specscategory = [];
        values.idcategory.map((val, key) => {
          let obj = val.value;
          specscategory.push(obj);
        });
        var arrspecscategoryString = specscategory.join(",");
        let specssubcategory = [];
        values.idsubcategory.map((val, key) => {
          let obj = val.value;
          specssubcategory.push(obj);
        });
        var arrspecsSubcategoryString = specssubcategory.join(",");
        // console.log("arrspecscategoryString", arrspecscategoryString);
        // console.log("arrspecsSubcategoryString", arrspecsSubcategoryString);
        var specificationadded = {
          // isglobal: values.isglobal,
          categoryids: arrspecscategoryString,
          subcategoryids: arrspecsSubcategoryString,
          name_en: values.name_en ? values.name_en : null,
          name_ar: values.name_ar ? values.name_ar : null,
          ismandatory: values.ismandatory,
          value_en: values.value_en ? values.value_en : null,
          value_ar: values.value_ar ? values.value_ar : null,
          isvariant: values.isvariant == true ? 1 : 0,
        };
        // console.log("specificationadded", specificationadded);
        this.props.addnewSpecification(
          this.props.session,
          specificationadded,
          (response) => {
            if (response.success === 0) {
              this.setState({ btnLoader: false });
              NotificationManager.error(LocaleStrings.addingfailed);
              this.props.reset();
            } else {
              this.setState({ btnLoader: false });
              NotificationManager.success(LocaleStrings.addedsucessfully);
              this.props.isOpenSpecsManagModal(false);
              this.props.reset();
              this.props.fetchSpecification(
                this.props.session,
                limit,
                this.state.pageNumber,
                "listing",
                "",
                (CallBackResponse) => {}
              );
            }
          }
        );
      }
    }
    // }
  }

  // Discount Area On Ochange
  _handleSpecification(e) {
    let areaType = "";
    if (e.target.value != "" && e.target.value != null) {
      areaType = _.find(this.props.Golbalspecification, {
        id: parseInt(e.target.value),
      }).name;
    }
    this.setState({ specificationAreaType: areaType });
  }
  _handlemandatory(e) {
    let mandateType = "";
    if (e.target.value != "" && e.target.value != null) {
      mandateType = _.find(this.props.Golbalspecification, {
        id: parseInt(e.target.value),
      }).name;
    }
    this.setState({ mandatoryspecs: mandateType });
  }

  // Category / Subcategory  / Product Select
  _handleCategoryScreen(e) {
    let selectedFiledValue = _.map(e, (el) => parseInt(el.value));
    this.setState({ selectedCateId: selectedFiledValue });
  }

  /**** INPUT LANGUAGE CHANGE en - ar ****/
  handleInputLanguageChange(e) {
    if (parseInt(e.target.value) === 1) {
      this.setState({ enar: "ar" });
    } else {
      this.setState({ enar: "en" });
    }
  }

  renderspecificationValue = ({ fields, meta: { error, submitFailed } }) => (
    <ul>
      <li style={{ padding: 0 }}>
        <a
          className="underline end-item text-primaryLight cursor-pointer"
          onClick={() => fields.push({})}>
          +Add Values
        </a>
        {submitFailed && error && <span>{error}</span>}
      </li>

      {fields.map((specsvalue, index) => {
        var currentInxedData = fields.get(index);
        let showDelBtn = fields.length === 1 ? " hidden" : "";
        if (fields.get(index).id) {
          showDelBtn = " hidden ";
        }
        //  imagePreviewUrl = currentInxedData?.image;
        return (
          <li key={index} style={{ padding: 0 }}>
            <div className={showDelBtn + "mt-2"} style={{ textAlign: "end" }}>
              <button
                type="button"
                title="Remove Member"
                className="text-end"
                onClick={() => fields.remove(index)}>
                <TrashIcon
                  className="h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
              </button>
            </div>
            <div className="mt-2">
              <Field
                name={`${specsvalue}.value_` + this.state.enar}
                type="text"
                component={this.renderAllfields}
                label={"Value_" + this.state.enar}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );

  render() {
    let { initialValues, language, handleSubmit } = this.props;
    let { specificationAreaType, selectedCateId, discounttype, enar } =
      this.state;
    let modalHeadText = "";
    let modalFooterText = "";
    let { specsproductCategory } = this.props;

    // ADD
    modalHeadText = LocaleStrings.specs_add_Header;
    modalFooterText = LocaleStrings.specs_add_Footer;

    var specificationAreaOptions = []; // Specs Area
    var specificationCateOptions = []; // Category
    var specificationSubCateOptions = []; // Sub-Category
    var discountProductOptions = []; // Product

    //Discount Area Object Creation
    _.forEach(this.props.Golbalspecification, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name),
        value: value.id,
      };

      specificationAreaOptions.push(obj);
    });

    // if (specificationAreaType === "No") {
    //Discount Category Object Creation
    _.forEach(this.props.specsproductCategory, function (value) {
      var obj = {
        label: ProductTextLocalized(
          value.categoryname_en,
          value.categoryname_ar,
          language
        ),
        value: value.id.toString(),
      };

      specificationCateOptions.push(obj);
    });
    // }

    // if (specificationAreaType === "No") {
    //Discount Sub-Category Object Creation
    let filterSubcateArr = _.filter(
      this.props.specsproductCategory,
      function (object) {
        return _.includes(selectedCateId, object.id);
      }
    );
    // console.log('filterSubcateArr',filterSubcateArr)

    // let filterSubcateArr = _.filter(this.props.specsproductSubCategory, {
    //   categoryid: parseInt(selectedCateId),
    // });

    // _.forEach(filterSubcateArr, function (value) {
    //   var obj = {
    //     label: ProductTextLocalized(
    //       value.subcategoryname_en,
    //       value.subcategoryname_ar,
    //       language
    //     ),
    //     value: value.id,
    //   };

    //   specificationSubCateOptions.push(obj);
    // });
    // }

    // if (specificationAreaType === "product") {
    //   //Discount Product (On select Cate | Subcate) Object Creation
    //   _.forEach(this.props.specsproductByCateSubCate, function (value) {
    //     var obj = {
    //       name: ProductTextLocalized(
    //         value.productname_en,
    //         value.productname_ar,
    //         language
    //       ),
    //       value: value.id,
    //     };

    //     discountProductOptions.push(obj);
    //   });
    // }

    if (filterSubcateArr.length > 0) {
      _.forEach(filterSubcateArr, function (value) {
        if (
          value.productsubcategory_by_categoryid &&
          value.productsubcategory_by_categoryid.length > 0
        ) {
          _.forEach(value.productsubcategory_by_categoryid, function (v) {
            var obj = {
              label: ProductTextLocalized(
                v.subcategoryname_en,
                v.subcategoryname_ar,
                language
              ),
              value: v.id,
            };

            specificationSubCateOptions.push(obj);
          });
        }
      });
    }

    return (
      <>
        <Transition.Root show={this.props.isDiscManageOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isDiscManageOpen}
            onClose={this.closeModal1}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0 }}>
                  {/* MOdal Header */}
                  <div className="border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center mt-4">
                      <div className="text-lg leading-6 font-medium text-secondary">
                        {modalHeadText}
                      </div>
                    </div>
                    <div className="sm:block absolute top-0 right-0  pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.closeModal}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    {/* MOdal Header Ends*/}
                    <div className="p-6 space-y-8">
                      <div style={{ display: "flex" }}>
                        <label className="mr-5 text-box-lable">
                          {LocaleStrings.language}
                        </label>

                        <Field
                          name="language"
                          label={LocaleStrings.en}
                          component={this.renderFieldRadioNew}
                          type="radio"
                          value="0"
                          className="mx-2"
                          onChange={this.handleInputLanguageChange.bind(this)}
                        />

                        <Field
                          name="language"
                          label={LocaleStrings.ar}
                          component={this.renderFieldRadioNew}
                          type="radio"
                          value="1"
                          className="mx-2"
                          onChange={this.handleInputLanguageChange.bind(this)}
                        />
                      </div>
                      {/* <div>
                        <Field
                          name="isglobal"
                          label={LocaleStrings.isspecificationglobal}
                          placeholder={LocaleStrings.select}
                          component={this.renderSelect}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          opts={specificationAreaOptions}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          onChange={this._handleSpecification.bind(this)}
                          // value={this.props.initialValues.type}
                        />
                      </div> */}
                      <div>
                        <Field
                          name="idcategory"
                          label={LocaleStrings.category}
                          placeholder={LocaleStrings.select}
                          options={specificationCateOptions}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          onChange={this._handleCategoryScreen.bind(this)}
                          isMulti={true}
                        />
                      </div>
                      <div>
                        <Field
                          name="idsubcategory"
                          label={LocaleStrings.subcategory}
                          placeholder={LocaleStrings.select}
                          options={specificationSubCateOptions}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          // onChange={this._handleCategoryScreen.bind(this)}
                          isMulti={true}
                        />
                      </div>
                      <div>
                        <Field
                          name={"name_" + enar}
                          label={LocaleStrings.specificationame}
                          placeholder={
                            LocaleStrings.specificationame + "_" + enar
                          }
                          type="text"
                          component={this.renderAllfields}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-medium text-secondary"
                        />
                      </div>
                      <div className="">
                        {/* <FieldArray
                          name={"value"}
                          component={this.renderspecificationValue}
                          values={this.state.enar}
                        /> */}
                        <Field
                          name={"value_" + enar}
                          label="Value"
                          placeholder="Add values"
                          mandatory="true"
                          component={this.renderFieldTextareaNew}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-box-lable"
                          className="py-3 px-4 block w-full shadow-sm text-secondary focus:ring-primary focus:border-teal-500 border border-warm-gray-300 rounded-md"
                        />
                      </div>

                      <Field
                        name="ismandatory"
                        label={LocaleStrings.isspecsmandatory}
                        placeholder={LocaleStrings.select}
                        component={this.renderSelect}
                        mandatory="true"
                        labelposition={LABEL_POSITION_TOP}
                        opts={specificationAreaOptions}
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                        onChange={this._handlemandatory.bind(this)}
                        // value={this.props.initialValues.type}
                      />

                      <div className="">
                        <Field
                          name="isvariant"
                          label="IS VARIANT"
                          component={this.renderFieldCheckbox1}
                          type="checkbox"
                          className="cust-check-box"
                          classNameLabel="text-box-lable"
                        />
                      </div>

                      <div className="p-6 center-item">
                        {/* Footer  */}
                        <button
                          type="submit"
                          className="lg:w-48 md:w-44 sm:w-44 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "sr-only",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          {modalFooterText}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = ["name_en", "ismandatory"];
  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = "Required";
    }
  });

  return errors;
}
function mapStateToProps(state) {
  var { session, user } = state;
  let newObj = {};
  // if (state.global_commissionlist)
  newObj = {
    language: "0",
    value: [
      {
        value_en: "",
        value_ar: "",
      },
    ],
  };
  // console.log('state.specsproductSubCategory',state.specsproductSubCategory)
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isDiscManageOpen: state.isSpecsManageModalopen,
    specsproductCategory: state.specsproductCategory
      ? state.specsproductCategory.data != "undefined"
        ? state.specsproductCategory.data
        : []
      : [],
    specsproductSubCategory: state.specsproductSubCategory
      ? state.specsproductSubCategory.data != "undefined"
        ? state.specsproductSubCategory.data
        : []
      : [],
    // initialValues: state.specssingleDiscountObject,
    Golbalspecification: state.Golbalspecification,
    initialValues: newObj,
  };
}

export default connect(mapStateToProps, {
  isOpenSpecsManagModal,
  fetchProductsByCateSubCate,
  fetchSpecification,
  addnewSpecification,
  getSelectedSpecsObj,
})(
  reduxForm({
    validate,
    form: "addSpecificationForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Addspecificationmodal)
);
