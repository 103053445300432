import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "./../../../../languages/index";
import { fetchAllstats } from "../../actions/index";
import { onSidebarMenuSelected } from "../../../sidebar/actions/index";

class DashboardReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.dashboard,
    };

    // All Binded Functions
  }

  // toUser = () => {
  //   this.props.history.push('/dashboard/customermanagement')
  //   this.props.onSidebarMenuSelected(2);
  // }
  // toVendors = () => {
  //   this.props.history.push('/dashboard/sellermanagement')
  //   this.props.onSidebarMenuSelected(3);
  // }

  // toProduct = () => {
  //   this.props.history.push('/dashboard/productmanagement/allitems')
  //   this.props.onSidebarMenuSelected(3);
  // }
  // toOrder = () => {
  //   this.props.history.push('/dashboard/ordermanagement/new')
  //   this.props.onSidebarMenuSelected(4);
  // }
  render() {
    let { allDasboardStats } = this.props;
    // console.log(this.props.allDasboardStats);
    return (
      <>
        {/* {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : ( */}
        <div className="grid lg:grid-cols-4 xs:grid-cols-2">
          <div
            className="flow-root px-6 py-3 bg-custWhite rounded ml-2 mb-4 boxes"
            // onClick={this.toUser}
          >
            <p className=" text-lg font-medium ">{LocaleStrings.totalusers}</p>
            <p className="text-ternary py-6  text-md font-medium">
              {allDasboardStats.numberstats.totalusers}{" "}
              {LocaleStrings.dashboard_Users}
            </p>
          </div>
          <div
            className="flow-root px-6 py-3 bg-custWhite rounded ml-2 mb-4 boxes"
            // onClick={this.toVendors}
          >
            <p className=" text-lg text-lgfont-medium ">
              {LocaleStrings.newvendors}
            </p>
            <p className="text-ternary py-6  text-md font-medium ">
              {allDasboardStats.numberstats.newvendors}{" "}
              {LocaleStrings.dashboard_Vendors}
            </p>
          </div>
          <div
            className="flow-root px-6 py-3 bg-custWhite rounded ml-2 mb-4 boxes"
            // onClick={this.toProduct}
          >
            <p className="text-lg font-medium ">
              {LocaleStrings.productslisted}
            </p>
            <p className="text-ternary py-6  text-md font-medium">
              {allDasboardStats.numberstats.productlisted}{" "}
              {LocaleStrings.dashboard_Products_Listed}
            </p>
          </div>
          <div
            className="flow-root px-6 py-3 bg-custWhite rounded ml-2 mb-4 boxes "
            // onClick={this.toOrder}
          >
            <p className="  text-lg font-medium ">{LocaleStrings.odersmade}</p>
            <p className="text-ternary py-6  text-md font-medium ">
              {allDasboardStats.numberstats.ordersmade}{" "}
              {LocaleStrings.dashboard_Orders_made}
            </p>
          </div>
        </div>
        {/* )} */}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log("allDasboardStats", state.allDasboardStats);
  return {
    session,
    user,
    // allDasboardStats:state.allDasboardStats
    allDasboardStats: state.allDasboardStats
      ? state.allDasboardStats.data
        ? state.allDasboardStats.data
        : []
      : [],
  };
}

export default connect(mapStateToProps, {
  fetchAllstats,
  onSidebarMenuSelected,
})(DashboardReports);
