import Recat, { Component } from "react";
import { connect } from "react-redux";
import { XIcon } from "@heroicons/react/outline";
import LocaleStrings from "../../../languages/index";
import Allcancelledorders from "./Allcancellededorders/index";
import Allreturnedorders from "./Allreturnedorders/index";
import Allreplaceorders from "./Allreplaceorders/index";
import { fetchallreturnorderList } from "../actions/index";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class DisputeManagementTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndextoShow: 1,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  // On Tabs change
  onTabsChange = (e, tabindex, callfrom) => {
    if (callfrom === "lg") {
      // For tabs
      this.setState({ tabIndextoShow: parseInt(tabindex) });
    } else {
      // For Select box
      this.setState({ tabIndextoShow: parseInt(e.target.value) });
    }
  };

  onDisputeTabSelect(e, href, position, screen) {
    if (screen == "sm") {
      if (e.target.value == "Return") {
        this.props.history.push("/dashboard/disputemanagement/return");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Cancelled") {
        this.props.history.push("/dashboard/disputemanagement/cancelled");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Replace") {
        this.props.history.push("/dashboard/disputemanagement/replace");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Orders Not Processed") {
        this.props.history.push(
          "/dashboard/disputemanagement/ordersnotprocessed"
        );
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Auction Items Not Bought") {
        this.props.history.push(
          "/dashboard/disputemanagement/auctionitemsnotbought"
        );
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      }
    } else {
      this.setState({ tabIndextoShow: parseInt(position) });
      this.props.history.push("/dashboard/disputemanagement/" + href);
    }
  }

  render() {
    var selectedTab = "";
    return (
      <>
        <div className="px-6 text-2xl font-semibold text-secondary mb-6">
          DISPUTE MANAGEMENT
        </div>

        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-primary focus:border-primary border-ternary rounded-md"
            // defaultValue={this.props.tabs.find((tab) => tab.current).name}
            onChange={(e) => this.onDisputeTabSelect(e, "", "", "sm")}>
            {this.props.tabs.map((tab) => (
              <option
                // key={tab.name} value={tab.tabindex}

                selected={
                  this.props.history.location.pathname ==
                    "/dashboard/disputemanagement/return" &&
                  tab.href == "return"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/disputemanagement/cancelled" &&
                      tab.href == "cancelled"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/disputemanagement/replace" &&
                      tab.href == "replace"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/disputemanagement/ordersnotprocessed" &&
                      tab.href == "ordersnotprocessed"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/disputemanagement/auctionitemsnotbought" &&
                      tab.href == "auctionitemsnotbought"
                    ? "selected"
                    : ""
                }>
                {tab.name}
              </option>
            ))}
          </select>
        </div>

        <div className="hidden sm:block">
          {/* <div className="xs:block"> */}
          <nav className="relative z-0 rounded-lg flex  pl-5" aria-label="Tabs">
            {this.props.tabs.map((tab, tabIdx) => (
              <span
                style={{ marginRight: "20px", borderRadius: "6px" }}
                key={tab.name}
                className={classNames(
                  this.state.tabIndextoShow === tab.position
                    ? "text-secondary"
                    : "text-ternary hover:text-secondary",
                  // tabIdx === 0 ? 'rounded-l-lg' : '',
                  // tabIdx === this.props.tabs.length - 1 ? 'rounded-r-lg' : '',
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10 "
                )}
                aria-current={
                  this.state.tabIndextoShow === tab.position
                    ? "page"
                    : undefined
                }
                onClick={(e) =>
                  this.onDisputeTabSelect(e, tab.href, tab.position, "lg")
                }>
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    this.props.history.location.pathname ==
                      "/dashboard/disputemanagement/return" &&
                      tab.href == "return"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/disputemanagement/cancelled" &&
                        tab.href == "cancelled"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/disputemanagement/replace" &&
                        tab.href == "replace"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/disputemanagement/ordersnotprocessed" &&
                        tab.href == "ordersnotprocessed"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/disputemanagement/auctionitemsnotbought" &&
                        tab.href == "auctionitemsnotbought"
                      ? "bg-primary"
                      : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </span>
            ))}
          </nav>
        </div>
        {/* <div className="py-6 px-6">
                  
                    <div>
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                            Select a tab
                            </label>
                            <select
                            id="tabs"
                            name="tabs"
                            className="block w-full focus:ring-primary focus:border-primary border-ternary rounded-md"
                            defaultValue={this.props.tabs.find((tab) => tab.current).name}
                            onChange = {(e) =>
                                this.onTabsChange(e,0,'sm')
                            }
                            >
                            {this.props.tabs.map((tab) => (
                                
                                <option key={tab.name} value={tab.tabindex}  selected={((this.state.tabIndextoShow === tab.tabindex) ? 'selected' : '')} >{tab.name}</option>
                            ))}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <nav className="relative z-0 rounded-lg flex  " aria-label="Tabs">
                            {this.props.tabs.map((tab, tabIdx) => (
                                <span
                                style={{marginRight:'20px', borderRadius:'6px'}}
                                key={tab.name}
                                className={classNames(
                                    (this.state.tabIndextoShow === tab.tabindex ) ? 'text-secondary' : 'text-ternary hover:text-secondary',
                                    tabIdx === 0 ? 'rounded-l-lg' : '',
                                    tabIdx === this.props.tabs.length - 1 ? 'rounded-r-lg' : '',
                                    'cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10 '
                                )}
                                aria-current={(this.state.tabIndextoShow === tab.tabindex ) ? 'page' : undefined}
                                onClick = {(e) =>
                                    this.onTabsChange(e,tab.tabindex,'lg')
                                }
                                >
                                <span>{tab.name}</span>
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        (this.state.tabIndextoShow === tab.tabindex ) ? 'bg-primary' : 'bg-transparent',
                                    'absolute inset-x-0 bottom-0 h-0.5'
                                    )}
                                />
                                </span>
                            ))}
                            </nav>
                        </div>
                        <div className="">
                            <div className="">
                                    <div className={this.state.tabIndextoShow === 1 ? '' : 'hidden'} ><Allreturnedorders/></div>
                                    <div className={this.state.tabIndextoShow === 2 ? '' : 'hidden'} ><Allcancelledorders/></div>
                                    <div className={this.state.tabIndextoShow === 3 ? '' : 'hidden'} ><Allreplaceorders/></div>
                               
                            </div>
                        </div>
                    </div>
                </div> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.DisputeMangementmainTabs,
  };
}

export default connect(mapStateToProps, {
  fetchallreturnorderList,
})(DisputeManagementTabs);
