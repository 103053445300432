import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index.js";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import { classNames } from "../../../../../common/common-functions";
import { BaseComponent } from "../../../../../common/base-component";
import _ from "lodash";
import HeaderbannerView from "./headerbannerview";
import HomepagebodyView from "./homepagebodyview";
import {
  publishHomePage,
  updateTempLayout,
  fetchSettingMaster,
} from "../../actions";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
class PreviewHomePageModal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      btnLoader: false,
      show: false,
      show1: false,
      errorExist: false,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }
  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }

  onSubmitForm(values) {
    this.setState({ btnLoader: true });

    let { homePageBody, homePageMainheader } = this.props;
    let mainSaveHomePageDataArr = [];
    let newhomePageBody = [];
    let newhomePageMainheader = [];
    console.log("homePageMainheader", homePageMainheader);
    // header changes
    _.forEach(homePageMainheader, function (value) {
      var deepCopy = _.cloneDeep(value);
      let staticdata = {};
      // console.log('deepCopy', deepCopy)
      staticdata.image = deepCopy.image;
      staticdata.linktype = deepCopy.linktype;
      staticdata.value = deepCopy.image;
      // storing values with names
      if (deepCopy.linktype == "product") {
        staticdata.productid = deepCopy.productid;
      } else if (deepCopy.linktype == "subcategory") {
        staticdata.subcategoryid = deepCopy.subcategoryid;
      } else if (deepCopy.linktype == "category") {
        staticdata.categoryid = deepCopy.categoryid;
      } else if (deepCopy.linktype == "external") {
        staticdata.externallink = deepCopy.externallink;
      }
      // deleting extra data added to show in the fields of header
      deepCopy.staticdata = staticdata;
      delete deepCopy["image"];
      delete deepCopy["externalLink"];
      delete deepCopy["externallink"];
      delete deepCopy["headerType"];
      delete deepCopy["linktype"];
      delete deepCopy["productid"];
      delete deepCopy["value"];
      newhomePageMainheader.push(deepCopy);
    });
    let errorSection = 0;
    console.log("homePageBody", homePageBody);
    // body section changes
    _.forEach(homePageBody, function (value) {
      if (value && value.error && value.error == 1) {
        errorSection++;
      }
      if (value.hasOwnProperty("index")) {
        // removing extra params required previously
        var deepCopy = _.cloneDeep(value);
        delete deepCopy["index"];
        delete deepCopy["productType"];
        // clearing data array if logic is not manual
        if (
          deepCopy.section == "product" &&
          deepCopy.selection_logic != "manual"
        ) {
          deepCopy.data = [];
        }
        // adding logic to only send product id and validity till
        if (
          deepCopy.section == "product" &&
          deepCopy.selection_logic == "manual"
        ) {
          let data = [];
          _.forEach(deepCopy.data, function (productData) {
            let obj = {};
            obj.id = productData.id;
            obj.validitytill = productData.validitytill;
            data.push(obj);
          });
          deepCopy["data"] = data;
        }
      }
      newhomePageBody.push(deepCopy);
    });
    console.log("newhomePageBody", newhomePageBody);
    // main array
    mainSaveHomePageDataArr.push(...newhomePageMainheader, ...newhomePageBody);
    console.log("mainSaveHomePageDataArr", mainSaveHomePageDataArr);

    if (errorSection > 0) {
      // Stoping if any error
      NotificationManager.error(
        "Please complete banner/product details to save homepage"
      );
      return false;
    } else {
      this.props.updateTempLayout(
        this.props.session,
        mainSaveHomePageDataArr,
        (response) => {
          // console.log("response :- ", response);
          if (response.success === 0) {
            NotificationManager.error(LocaleStrings.updatederror);
            this.setState({ btnLoader: false });
          } else {
            this.props.publishHomePage(
              this.props.session,
              mainSaveHomePageDataArr,
              (response) => {
                // console.log("response :- ", response);
                if (response.success === 0) {
                  NotificationManager.error(
                    "Something Went Wrong While Publishing"
                  );
                  this.setState({ btnLoader: false });
                } else {
                  this.setState({ btnLoader: false });
                  this.setState({ open: false });
                  NotificationManager.success("Saved & Published Successfully");
                  this.props.fetchSettingMaster(
                    this.props.session,
                    (CallBackResponse) => {}
                  );
                }
              }
            );
          }
        }
      );
    }
  }

  render() {
    // console.log("in", this.props.initialValues);
    var { handleSubmit, homePagemaindata } = this.props;
    return (
      <>
        <div className="" style={{ overflowX: "hidden" }}>
          <div className="flex flex-wrap content-center justify-end ">
            <button onClick={this.openModal} type="button" className="button">
              Preview and Publish
            </button>
            <Transition.Root show={this.state.open} as={Fragment}>
              <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                open={this.state.open}
                onClose={this.closeModal}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full bigmodal-preview">
                      {" "}
                      <div
                        className="pb-4"
                        style={{ borderBottom: "2px solid #fafafa" }}>
                        <div className=" sm:block  pt-4 pl-4 ">HomePage</div>
                        <div className=" sm:block absolute top-0 right-0 pt-4 pr-4 end-item">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            onClick={this.closeModal}>
                            <span className="sr-only">
                              {LocaleStrings.close}
                            </span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <form
                        className=""
                        onSubmit={handleSubmit(this.onSubmitForm)}
                        encType="multipart/form-data">
                        <div>
                          <div className="border-transparent p-5">
                            {homePagemaindata && homePagemaindata.length > 0 ? (
                              <>
                                <HeaderbannerView />
                                <HomepagebodyView />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* <div className="text-ternary  group flex items-center px-3 py-2 text-sm font-medium rounded-md">{LocaleStrings.addsellerbigtext}</div> */}
                        <div className="p-5">
                          <div className="flex justify-end">
                            <button
                              onClick={this.closeModal}
                              type="button"
                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              {LocaleStrings.cancel}
                            </button>
                            <button
                              type="submit"
                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              <svg
                                className={classNames(
                                  this.state.btnLoader ? "" : "sr-only",
                                  "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                )}
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="loading"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true">
                                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                              </svg>
                              Save & Publish
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        </div>
        <NotificationContainer />
      </>
    );
  }
}

var validate = (values) => {
  // console.log("values", values);
  var errors = {};

  return errors;
};

function mapStateToProps(state) {
  var initVals = { gender: "" };
  var { session, user } = state;
  return {
    session,
    user,
    homePagemaindata: state.homePagemaindata ? state.homePagemaindata.data : [],
    homePageBody: state.homePageBody ? state.homePageBody.data : [],
    homePageMainheader: state.homePageMainheader
      ? state.homePageMainheader.data
      : [],
  };
}

export default connect(mapStateToProps, {
  publishHomePage,
  updateTempLayout,
  fetchSettingMaster,
})(
  reduxForm({
    validate,
    form: "PreviewHomePageModalform",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(PreviewHomePageModal)
);
