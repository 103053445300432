import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import { Field, reduxForm } from "redux-form";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../../common/base-component";
import {
  APP_API_KEY,
  BASE_IMAGES_URL,
} from "../../../../../../common/constants";
import { verifyvendor } from "../../../actions/index";
import toast from "react-hot-toast";
import { classNames } from "../../../../../../common/common-functions";

class LegalDocuments extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      setOpen: false,
      btnLoader: false,
    };
  }

  openModal = (e, data) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  _handleDownload(event, filename) {
    if (
      filename !== null &&
      filename !== "null" &&
      filename !== undefined &&
      filename !== ""
    ) {
      let filepath = `${BASE_IMAGES_URL}/store/${filename}?api_key=${APP_API_KEY}`;
      window.open(filepath);
    }
  }

  /***** Form Submit *****/
  onFormSubmit = (values) => {
    this.setState({ btnLoader: true });
    var id = values.userid;
    // console.log('this.props.session',this.props.session)
    if (values.usertype != "vendor") {
      if (values.idproof !== null && values.agreement_template !== null) {
        this.props.verifyvendor(this.props.session, id, (response) => {
          // console.log("response :- ", response);
          if (response.success === 0) {
            toast.error(LocaleStrings.seller_management_legalDocs_errorApprove);
            this.setState({ btnLoader: false });
          } else {
            toast.success(
              LocaleStrings.seller_management_legalDocs_successApprove
            );
            this.setState({ btnLoader: false });
            setTimeout(() => {
              this.props.history.push("dashboard/sellermanagement/verified");
            }, 1000);

            // alert("success");
          }
        });
      } else {
        toast.error(LocaleStrings.seller_management_legalDocs_submitAlldocs);
        this.setState({ btnLoader: false });
        // alert('no')
      }
    } else {
      if (
        values.commercial_reg_doc !== null &&
        values.agreement_template !== null
      ) {
        this.props.verifyvendor(this.props.session, id, (response) => {
          // console.log("response :- ", response);
          if (response.success === 0) {
            toast.error(LocaleStrings.seller_management_legalDocs_errorApprove);
            this.setState({ btnLoader: false });
          } else {
            toast.success(
              LocaleStrings.seller_management_legalDocs_successApprove
            );
            this.setState({ btnLoader: false });
            setTimeout(() => {
              this.props.history.push("dashboard/sellermanagement/verified");
            }, 1000);
            // alert("success");
          }
        });
      } else {
        toast.error(LocaleStrings.seller_management_legalDocs_submitAlldocs);
        this.setState({ btnLoader: false });
        // alert('no')
      }
    }
  };

  render() {
    var { data, initialValues, handleSubmit } = this.props;
    // console.log("data", data);
    console.log("his", this.props.history);
    return (
      <>
        <div className="">
          {data.usertype == "personal" ? (
            <div className="center-item mt-48">
              Documents For Personal Type Vendor are not available
            </div>
          ) : (
            <form className=" " onSubmit={handleSubmit(this.onFormSubmit)}>
              <div className="flow-root px-10 py-5 bg-custWhite rounded">
                {data.usertype == "homebiz" ? (
                  <p className="mb-3">Valid Id proof</p>
                ) : (
                  <p className="mb-3">
                    {LocaleStrings.commercial_registration_document}
                  </p>
                )}

                {data.usertype == "homebiz" ? (
                  <>
                    {data.idproof != null ? (
                      <>
                        <Field
                          name="idproof"
                          // label={LocaleStrings.commercial_registration_document}
                          autofocus="false"
                          placeholder={LocaleStrings.attach_document}
                          type="text"
                          component={this.renderFieldText}
                          mandatory="false"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-medium text-secondary"
                        />

                        <div className="end-item">
                          <button
                            type="button"
                            onClick={(e) =>
                              this._handleDownload(e, this.props.data.idproof)
                            }
                            className="button  mt-5">
                            {LocaleStrings.common_download}
                          </button>
                        </div>
                      </>
                    ) : (
                      <p className="mb-3">No id proof added</p>
                    )}
                  </>
                ) : (
                  <>
                    {data.commercial_reg_doc != null ? (
                      <>
                        <Field
                          name="commercial_reg_doc"
                          // label={LocaleStrings.commercial_registration_document}
                          autofocus="false"
                          placeholder={LocaleStrings.attach_document}
                          type="text"
                          component={this.renderFieldText}
                          mandatory="false"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-medium text-secondary"
                        />

                        <div className="end-item">
                          <button
                            type="button"
                            onClick={(e) =>
                              this._handleDownload(
                                e,
                                this.props.data.commercial_reg_doc
                              )
                            }
                            className="button  mt-5">
                            {/* {LocaleStrings.common_download} */}
                            {LocaleStrings.common_view}
                          </button>
                        </div>
                      </>
                    ) : (
                      <p className="mb-3">
                        Commercial Registration Document not added
                      </p>
                    )}
                  </>
                )}
              </div>
              <div className="flow-root px-10 py-5 bg-custWhite mt-5 rounded">
                <p className="mb-3">{LocaleStrings.filledandsignedagreement}</p>
                {data.agreement_template == null ? (
                  <>
                    <div classname="mt-5">
                      <p className="text-gray-400">
                        {LocaleStrings.filledandsignedagreement_notUploaded}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <Field
                      name="agreement_template"
                      // label={LocaleStrings.agreement_template_duly_filled_and_signed}
                      placeholder={LocaleStrings.attach_document}
                      type="text"
                      component={this.renderFieldText}
                      mandatory="false"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-medium text-secondary"
                    />
                    <div className="end-item">
                      <button
                        type="button"
                        onClick={(e) =>
                          this._handleDownload(
                            e,
                            this.props.data.agreement_template
                          )
                        }
                        className="button  mt-5">
                        {LocaleStrings.common_view}
                      </button>
                    </div>
                  </>
                )}
              </div>
              {data.verified == true ? (
                <>
                  {" "}
                  <div className="center-item mt-5">
                    <p>{LocaleStrings.verifiedandapproveduser}</p>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="center-item my-5">
                    {LocaleStrings.seller_management_legalDocs_verifiedText}
                    <img
                      className={"h-4 w-4 mt-1 mx-1 cursor-pointer"}
                      src={"../images/bluetick.jpeg"}
                    />
                    {LocaleStrings.seller_management_legalDocs_onProfile}
                  </div>
                  <div className="center-item mt-5">
                    <button
                      //   onClick={}
                      type="submit"
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 ">
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "sr-only",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.approvedandverified}
                    </button>
                    {/* <button type="submit" className="button">
                      {LocaleStrings.approvedandverified}
                    </button> */}
                  </div>
                </>
              )}
            </form>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // console.log('ownProps.data', ownProps.data);
  return {
    session: state.session,
    data: ownProps.data,
    initialValues: ownProps.data,
  };
}

export default connect(mapStateToProps, {
  verifyvendor,
})(
  reduxForm({
    form: "LegalDocumentForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(LegalDocuments)
);
