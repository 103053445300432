import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, TrashIcon } from "@heroicons/react/outline";
import { Field, reduxForm, FieldArray } from "redux-form";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../common/base-component";
import _ from "lodash";
import {
  ProductTextLocalized,
  classNames,
} from "../../../../common/common-functions";
import { NotificationManager } from "react-notifications";
import { addVariations, fetchVariations } from "../actions/index";

class NewVariations extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      showerror: false,
      enar: "en",
      btnLoader: false,
      selectedCateId: "",
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {
    // if (
    //   this.props.mastervariationProductCat &&
    //   this.props.mastervariationProductCat.length > 0
    // ) {
    //   let defaultCateId = _.head(
    //     _.filter(this.props.productCategorylist, { isactive: true })
    //   ).id;
    //   this.setState({ defaultCateId: defaultCateId });
    // }
  }
  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
    this.props.reset();
  }

  onSubmitForm(values) {
    let value_enArr = [];
    let value_arArr = [];
    let value_errorArr = [];
    // if (values.value.length > 0) {
    //   values.value.map((val, key) => {
    //     let obj = val.value_en != "" ? val.value_en : null;
    //     let obj1 = val.value_ar != "" ? val.value_ar : null;
    //     if (obj == null || obj1 == null) {
    //       value_errorArr.push(1);
    //     }
    //     value_enArr.push(obj);
    //     value_arArr.push(obj1);
    //   });
    //   var value_en_String = value_enArr.join(",");
    //   var value_ar_String = value_arArr.join(",");
    // } else {
    //   value_errorArr.push(1);
    // }
    // if (value_errorArr.length > 0) {
    //   NotificationManager.error("Please add value for both languages");
    //   this.setState({ btnLoader: false });
    //   return false;
    // }

    this.setState({ btnLoader: true });
    if (Object.keys(values).length === 0) {
      this.setState({ btnLoader: false });
      NotificationManager.error(LocaleStrings.common_mandatory_required);
      return false;
    }
    if (
      !values.name_en ||
      !values.name_ar ||
      !values.value_en ||
      !values.value_ar
    ) {
      NotificationManager.error("Variation name is required in both languages");
      this.setState({ btnLoader: false });
      return false;
    }

    if (
      _.trim(values.name_en) == "" ||
      _.trim(values.name_ar) == "" ||
      _.trim(values.value_en) == "" ||
      _.trim(values.value_ar) == ""
    ) {
      NotificationManager.error("Please Fill All Mandatory Fields");
      this.setState({ btnLoader: false });
      return false;
    }
    value_enArr = _.split(values.value_en, ",");
    value_arArr = _.split(values.value_ar, ",");

    if (value_enArr.length != value_arArr.length) {
      this.setState({ btnLoader: false });
      NotificationManager.error(
        "Please add same number of comma separted values for both languages"
      );
      return false;
    } else {
      let blankvalue = 0;
      _.forEach(value_enArr, (v, i) => {
        if (_.trim(v) == "") {
          blankvalue = 1;
        }
      });
      _.forEach(value_arArr, (v1, i1) => {
        if (_.trim(v1) == "") {
          blankvalue = 1;
        }
      });

      if (blankvalue != 0) {
        this.setState({ btnLoader: false });
        NotificationManager.error(
          "Please add same number of comma separted values for both languages"
        );
        return false;
      }
    }

    if (
      values.hasOwnProperty("idcategory") &&
      (values.idcategory == null || values.idcategory == "")
    ) {
      this.setState({ btnLoader: false });
      NotificationManager.error(LocaleStrings.common_mandatory_required);
      return false;
    }
    if (
      !values.hasOwnProperty("idcategory") &&
      !values.hasOwnProperty("idsubcategory")
    ) {
      this.setState({ btnLoader: false });
      NotificationManager.error(LocaleStrings.common_mandatory_required);
      return false;
    } else if (
      values.hasOwnProperty("idcategory") &&
      !values.hasOwnProperty("idsubcategory")
    ) {
      this.setState({ btnLoader: false });
      NotificationManager.error(LocaleStrings.common_mandatory_required);
      return false;
      // let variationcategory = [];
      // values.idcategory.map((val, key) => {
      //   let obj = val.value;
      //   variationcategory.push(obj);
      // });
      // var arrvariationcategoryString = variationcategory.join(",");

      // var variationadded = {
      //   categoryid: arrvariationcategoryString,
      //   name_en: values.name_en,
      //   name_ar: values.name_ar,
      //   subcategoryid: "",
      //   value_en: values.value_en ? values.value_en : null,
      //   value_ar: values.value_ar ? values.value_ar : null,
      // };
      // this.props.addVariations(
      //   this.props.session,
      //   variationadded,
      //   (response) => {
      //     if (response.success === 0) {
      //       NotificationManager.error(LocaleStrings.addingfailed);
      //       this.props.reset();
      //     } else {
      //       this.props.reset();
      //       this.props.fetchVariations(
      //         this.props.session,
      //         10,
      //         1,
      //         "listing",
      //         "",
      //         (CallBackResponse) => {
      //           NotificationManager.success(LocaleStrings.common_added);
      //           NotificationManager.success(LocaleStrings.addedsucessfully);
      //           this.setState({ btnLoader: false });
      //           this.setState({ open: false });
      //         }
      //       );
      //     }
      //   }
      // );
    } else if (
      values.hasOwnProperty("idcategory") &&
      values.hasOwnProperty("idsubcategory")
    ) {
      let variationcategory = [];
      values.idcategory.map((val, key) => {
        let obj = val.value;
        variationcategory.push(obj);
      });
      var arrvariationcategoryString = variationcategory.join(",");
      let variationsubcategory = [];
      values.idsubcategory.map((val, key) => {
        let obj = val.value;
        variationsubcategory.push(obj);
      });
      var arrvariationSubcategoryString = variationsubcategory.join(",");
      var variationadded = {
        categoryid: arrvariationcategoryString,
        subcategoryid: arrvariationSubcategoryString,
        name_en: values.name_en,
        name_ar: values.name_ar,
        value_en: values.value_en ? values.value_en : null,
        value_ar: values.value_ar ? values.value_ar : null,
      };
      this.props.addVariations(
        this.props.session,
        variationadded,
        (response) => {
          if (response.success === 0) {
            NotificationManager.error(LocaleStrings.addingfailed);
            this.props.reset();
          } else {
            this.props.reset();
            this.props.fetchVariations(
              this.props.session,
              10,
              1,
              "listing",
              "",
              (CallBackResponse) => {
                NotificationManager.success(LocaleStrings.common_added);
                this.setState({ btnLoader: false });
                this.setState({ open: false });
              }
            );
          }
        }
      );
    }
    // this.props.addVariations(this.props.session, values, (response) => {
    //   if (response.success === 0) {
    //     NotificationManager.error(LocaleStrings.common_cannotadd);
    //     this.setState({ btnLoader: false });
    //   } else {
    //     NotificationManager.success(LocaleStrings.common_added);
    //     this.setState({ btnLoader: false });
    //     this.props.onClose();
    //     this.props.fetchVariations(
    //       this.props.session,
    //       0,
    //       (CallBackResponse) => {}
    //     );
    //   }
    // });
  }

  handleInputLanguageChange(e) {
    if (parseInt(e.target.value) === 1) {
      this.setState({ enar: "ar" });
    } else {
      this.setState({ enar: "en" });
    }
  }

  _handleCategoryScreen(e) {
    let selectedFiledValue = _.map(e, (el) => parseInt(el.value));
    this.setState({ selectedCateId: selectedFiledValue });
  }
  // Category / Subcategory  / Product Select

  rendervariationValue = ({ fields, meta: { error, submitFailed } }) => (
    <ul>
      <li style={{ paddingTop: 0 }}>
        <a
          className="underline end-item text-primaryLight cursor-pointer"
          onClick={() => fields.push({})}>
          +Add Values
        </a>
        {submitFailed && error && <span>{error}</span>}
      </li>

      {fields.map((variationvalue, index) => {
        var currentInxedData = fields.get(index);
        let showDelBtn = fields.length === 1 ? " hidden" : "";
        if (fields.get(index).id) {
          showDelBtn = " hidden ";
        }
        //  imagePreviewUrl = currentInxedData?.image;
        return (
          <li key={index}>
            <div className={showDelBtn} style={{ textAlign: "end" }}>
              <button
                type="button"
                title="Remove Member"
                className="text-end"
                onClick={() => fields.remove(index)}>
                <TrashIcon
                  className="h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
              </button>
            </div>
            <div className="mt-2">
              <Field
                name={`${variationvalue}.value_` + this.state.enar}
                type="text"
                component={this.renderAllfields}
                label={"Value_" + this.state.enar}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );

  render() {
    var { handleSubmit, language, initialValues } = this.props;
    var { enar, selectedCateId } = this.state;

    var variationCateOptions = []; // Category
    var variationSubCateOptions = []; // Sub-Category

    //Discount Category Object Creation
    _.forEach(this.props.mastervariationProductCat.data, function (value) {
      var obj = {
        label: ProductTextLocalized(
          value.categoryname_en,
          value.categoryname_ar,
          language
        ),
        value: value.id.toString(),
      };

      variationCateOptions.push(obj);
    });

    //Discount Sub-Category Object Creation
    let filterSubcateArr = _.filter(
      this.props.mastervariationProductCat.data,
      function (object) {
        // return _.includes(selectedCateId, object.categoryid);
        return _.includes(selectedCateId, object.id);
      }
    );

    // let filterSubcateArr1 = _.filter(
    //   this.props.mastervariationProductsubCat.data,
    //   function (object) {
    //     // return _.includes(selectedCateId, object.categoryid);
    //     return _.includes(selectedCateId, object.categoryid);
    //   }
    // );
    // console.log("filterSubcateArr1", filterSubcateArr1);
    if (filterSubcateArr.length > 0) {
      _.forEach(filterSubcateArr, function (value) {
        if (
          value.productsubcategory_by_categoryid &&
          value.productsubcategory_by_categoryid.length > 0
        ) {
          _.forEach(value.productsubcategory_by_categoryid, function (v) {
            var obj = {
              label: ProductTextLocalized(
                v.subcategoryname_en,
                v.subcategoryname_ar,
                language
              ),
              value: v.id,
            };

            variationSubCateOptions.push(obj);
          });
        }
      });
    }

    return (
      <div className=" mt-5" style={{ overflowX: "hidden" }}>
        <div className="flex flex-wrap content-center justify-end ">
          <button
            onClick={this.openModal}
            type="submit"
            className="lg:w-48 md:w-44 sm:w-44 xs:w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
            {LocaleStrings.master_vari_newvariation}
            <img
              src="../images/product_management_images/add.svg"
              className="h-5 ml-2"
            />
          </button>
          <Transition.Root show={this.state.open} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed z-10 inset-0 overflow-y-auto"
              open={this.state.open}
              onClose={this.closeModal}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                  <div
                    className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                    style={{ padding: 0 }}>
                    {/* MOdal Header */}
                    <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                      <div className="mt-4">
                        <div className="text-md leading-6 font-medium text-secondary pl-3">
                          New Variations
                        </div>
                      </div>
                      <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                        <button
                          type="button"
                          className="btn-closeModal"
                          onClick={this.closeModal}>
                          <span className="sr-only">{LocaleStrings.close}</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <form
                      className=""
                      onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
                      {/* MOdal Header Ends*/}
                      <div className="p-4" style={{ display: "flex" }}>
                        <label className="mr-5 text-box-lable">
                          {LocaleStrings.language}
                        </label>

                        <Field
                          name="language"
                          label={LocaleStrings.en}
                          component={this.renderFieldRadioNew}
                          type="radio"
                          value="0"
                          className="mx-2"
                          onChange={this.handleInputLanguageChange.bind(this)}
                        />

                        <Field
                          name="language"
                          label={LocaleStrings.ar}
                          component={this.renderFieldRadioNew}
                          type="radio"
                          value="1"
                          className="mx-2"
                          onChange={this.handleInputLanguageChange.bind(this)}
                        />
                      </div>

                      <div className="m-5">
                        <Field
                          name="idcategory"
                          label={LocaleStrings.category}
                          placeholder={LocaleStrings.select}
                          options={variationCateOptions}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          onChange={this._handleCategoryScreen.bind(this)}
                          isMulti={true}
                          mandatory="true"
                        />
                      </div>
                      <div className="m-5">
                        <Field
                          name="idsubcategory"
                          label={LocaleStrings.subcategory}
                          placeholder={LocaleStrings.select}
                          options={variationSubCateOptions}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          // onChange={this._handleCategoryScreen.bind(this)}
                          isMulti={true}
                        />
                      </div>
                      <div className="m-5">
                        <Field
                          name={"name_" + enar}
                          mandatory="true"
                          placeholder={"Variation " + enar}
                          label="Variation Name"
                          type="text"
                          component={this.renderAllfields}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        />
                      </div>

                      {/* <FieldArray
                        name={"value"}
                        component={this.rendervariationValue}
                        values={this.state.enar}
                      /> */}
                      <div className="m-5">
                        <Field
                          name={"value_" + enar}
                          label="Add Value"
                          placeholder="Add values"
                          mandatory="true"
                          component={this.renderFieldTextareaNew}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-box-lable"
                          className="py-3 px-4 block w-full shadow-sm text-secondary focus:ring-primary focus:border-teal-500 border border-warm-gray-300 rounded-md"
                        />
                      </div>
                      <div className="my-10">
                        <div className="flex justify-center">
                          <button type="submit" className="button">
                            <svg
                              className={classNames(
                                this.state.btnLoader ? "" : "sr-only",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    );
  }
}

function validate(values) {
  var errors = {};
  var errors = {};
  var requiredFields = ["name_en", "idcategory"];

  requiredFields.forEach((field) => {
    // if (typeof values[field] != 'undefined'){
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
    // }
  });
  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log('productByCateSubCate',state.addProductByCateSubCate)
  let newObj = {};

  // if (state.global_commissionlist)
  newObj = {
    language: "0",
    value: [
      {
        value_en: "",
        value_ar: "",
      },
    ],
  };
  return {
    session,
    user,
    language: state.language,
    initialValues: newObj,
    mastervariationProductCat: state.mastervariationProductCat,
    mastervariationProductsubCat: state.mastervariationProductsubCat,
  };
}

export default connect(mapStateToProps, {
  addVariations,
  fetchVariations,
})(
  reduxForm({
    validate,
    form: "ADDVARIATION",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(NewVariations)
);
