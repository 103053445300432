import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import "react-notifications/lib/notifications.css";
import { reduxForm } from "redux-form";
import { BaseComponent } from "../../../../common/base-component";
import {
  fetchAllproducts,
  updateProductobjhomepage,
  updateBannerobjhomepage,
  deleteFormList,
  reduxUpdateHomePagebody,
} from "../actions/index";
import ProductModalhomepage from "./productsection-modal";
import Bannermodalhomepage from "./bannersection-modal";
import { XIcon, TrashIcon } from "@heroicons/react/outline";
import { confirmAlert } from "react-confirm-alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LocaleStrings from "../../../languages";

class HomePageSections extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      selection_type: "",
      dragablebox: [],
      isOpen: false,
      setOpen: false,
      isBannerOpen: false,
      update: false,
    };

    // All Binded Functions
  }

  componentDidMount() {}

  openModal = (e, obj) => {
    this.props.updateProductobjhomepage(obj);
    this.setState({ isOpen: true });
  };
  openBannerModal = (e, obj) => {
    this.props.updateBannerobjhomepage(obj);
    this.setState({ isBannerOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });
  closeBannerModal = () => this.setState({ isBannerOpen: false });

  _handleDelete(e, index) {
    confirmAlert({
      title: "Delete Section",
      message: "Are you sure you want to delete this section??",
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.deleteFormList(
              index,
              this.props.homePageBody,
              (response) => {
                this.props.reduxUpdateHomePagebody(true);
                NotificationManager.success(LocaleStrings.common_deleteSucess);
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    let { selection_type } = this.state;
    let { list, index, key } = this.props;

    let productboxStyle = {};
    let bannerborderStyle = {};
    if (list.section == "product" && !list.error) {
      productboxStyle = {
        background: `linear-gradient(180deg, ${list.bgcolor} 0%, #FFFFFF 100%)`,
        // cursor:'grab'
      };
    } else if (list.section == "product" && list.error == 1) {
      productboxStyle = {
        background: `linear-gradient(180deg, ${list.bgcolor} 0%, #FFFFFF 100%)`,
        border: `1px solid red`,
        // cursor:'grab'
      };
    } else if (list.section == "banner" && list.error == 1) {
      bannerborderStyle = {
        // background: `linear-gradient(180deg, ${list.bgcolor} 0%, #FFFFFF 100%)`,
        border: `1px solid red`,
        // cursor:'grab'
      };
    }

    return (
      <>
        {list.section == "product" ? (
          <>
            <div
              className="lg:grid lg:grid-cols-12 p-6 my-6 cursor-grab"
              style={productboxStyle}>
              <div
                className="lg:col-span-10 rounded text-xl font-semibold text-secondary"
                onClick={(e) => this.openModal(e, list)}>
                <div>{_.startCase(list.section)}</div>
              </div>
              <div className="lg:col-span-2 flex -end-item">
                {list.error && list.error == 1 ? (
                  <div>
                    <img className="h-7 w-7 mr-4" src="../images/warning.png" />
                  </div>
                ) : (
                  <div></div>
                )}
                {this.props.homePageBody &&
                this.props.homePageBody.length > 1 ? (
                  <button
                    type="button"
                    title="Remove Member"
                    className="end-item"
                    onClick={(e) => this._handleDelete(e, list.index)}>
                    <TrashIcon
                      className="h-6 w-6 text-red-500"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            {this.state.isOpen ? (
              <ProductModalhomepage
                isOpen={this.state.isOpen}
                onClose={this.closeModal}
                index={list.index}
                bgcolor={list.bgcolor}
                // values={this.props.values}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <div
              className="lg:grid lg:grid-cols-12 p-6 my-6 cursor-grab bg-gray-100"
              style={bannerborderStyle}>
              <div
                className="lg:col-span-10 rounded text-xl font-semibold text-secondary"
                onClick={(e) => this.openBannerModal(e, list)}>
                <div>{_.startCase(list.section)}</div>
              </div>
              <div className="lg:col-span-2 flex -end-item">
                {list.error && list.error == 1 ? (
                  <div>
                    <img className="h-7 w-7 mr-4" src="../images/warning.png" />
                  </div>
                ) : (
                  <div></div>
                )}
                {this.props.homePageBody &&
                this.props.homePageBody.length > 1 ? (
                  <button
                    type="button"
                    title="Remove Member"
                    className="end-item"
                    onClick={(e) => this._handleDelete(e, list.index)}>
                    <TrashIcon
                      className="h-6 w-6 text-red-500"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            {this.state.isBannerOpen ? (
              <Bannermodalhomepage
                isOpen={this.state.isBannerOpen}
                onClose={this.closeBannerModal}
                index={list.index}
                // values={this.props.values}
              />
            ) : (
              ""
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    productCard_type: state.productCard_type,
    product_Limit: state.product_Limit,
    productType: state.productType,
    allproductList: state.allproductList ? state.allproductList.data : [],
    homePageBody: state.homePageBody ? state.homePageBody.data : [],
  };
}

// export default connect(mapStateToProps, {})(HomePageDesign);
export default connect(mapStateToProps, {
  fetchAllproducts,
  updateProductobjhomepage,
  updateBannerobjhomepage,
  deleteFormList,
  reduxUpdateHomePagebody,
})(
  reduxForm({
    form: "HomePageDesignForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(HomePageSections)
);
