import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  APP_API_KEY,
  BASE_IMAGES_URL,
} from "../../../../../../common/constants";
import moment from "moment-timezone";
import {
  isNegotiationOpen,
  fetchNegotiationsellerdetails,
} from "../../../actions/index";
import { ProductTextLocalized } from "../../../../../../common/common-functions";
import { currencyCode, currencyRate } from "../../../../currency/action/index";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class NegotiationTableList extends Component {
  constructor(props) {
    super(props);

    // All Binds Functions
  }

  /***** Open nego Detals On Click *****/
  _handleNegoDetails(e, threadid) {
    this.props.fetchNegotiationsellerdetails(
      this.props.session,
      threadid,
      (callBack) => {}
    );
    this.props.isNegotiationOpen(true);
  }

  render() {
    // console.log(this.props.negotiationObj);
    var { negotiationObj, localCurrency } = this.props;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    let callFrom = this.props.callFrom;
    let local_lang = this.props.language;
    var productImageUrl = `${BASE_IMAGES_URL}/vendor/variations/${this.props.negotiationObj.productimage}?api_key=${APP_API_KEY}`;
    let productname = ProductTextLocalized(
      this.props.negotiationObj.productname_en,
      this.props.negotiationObj.productname_ar,
      local_lang
    ); // language
    let currencychoice =
      this.props.negotiationObj.purchasedby.currencychoice === "USD"
        ? "$"
        : "-";
    let payment_method = this.props.negotiationObj.payment_method;
    let quantity = this.props.negotiationObj.quantity;

    let status = "";
    status = this.props.negotiationObj.status;

    var img = `${BASE_IMAGES_URL}/vendor/variations/${negotiationObj.productimage}?api_key=${APP_API_KEY}`;
    let tabledate = "";
    tabledate = moment(negotiationObj.createdon).format("D MMM YYYY HH:mm A");
    return (
      <>
        {" "}
        <tr
          key={this.props.position}
          className="cursor-pointer bg-white border-b-2 border-gray-50"
          onClick={(e) =>
            this._handleNegoDetails(
              e,
              this.props.negotiationObj.negotiationthreadid
            )
          }>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ display: "flex" }}>
            <div className="mr-2">
              <img src={img} style={{ height: "40px", width: "40px" }} />
            </div>
            <div>
              <div>
                {negotiationObj.productname_en.length > 40
                  ? negotiationObj.productname_en.substring(0, 30) + "..."
                  : negotiationObj.productname_en}
              </div>
              <div className="text-xs text-ternary">
                {negotiationObj.product_desc_en.length > 40
                  ? negotiationObj.product_desc_en.substring(0, 40) + "..."
                  : negotiationObj.product_desc_en}
              </div>
            </div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ cursor: "pointer" }}>
            <div>
              {currency} {negotiationObj.originalprice}
            </div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ cursor: "pointer" }}>
            <div>
              {/* ${getIntToFloat(negotiationObj.price)} */}
              {currency} {negotiationObj.price}
            </div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ cursor: "pointer" }}>
            <div>{negotiationObj.attempts}/5</div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ cursor: "pointer" }}>
            <div>{tabledate}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div>{_.startCase(negotiationObj.status)}</div>
          </td>
        </tr>
      </>
    );
  }
}
// isNegotiationopen

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isNegotiationopen: state.isNegotiationopen,
    localCurrency: state.currency,
  };
}

export default connect(mapStateToProps, {
  isNegotiationOpen,
  fetchNegotiationsellerdetails,
})(NegotiationTableList);
