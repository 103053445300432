import React, { Component } from "react";
import { connect } from "react-redux";

import { converDateIntoLocal } from "../../../../../../common/common-functions";
import _ from "lodash";
import moment from "moment-timezone";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";

import LocaleStrings from "../../../../../languages";

import toast, { Toaster } from "react-hot-toast";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

class VendorsDataStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    var { values } = this.props;

    let tabledate = "";
    tabledate = converDateIntoLocal(values.createdon).format(
      "D MMM YYYY HH:mm A"
    );

    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 cursor-pointer hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.username}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.email}
          </td>

          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {tabledate}
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
  };
}
export default connect(mapStateToProps, {})(VendorsDataStats);
