import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../../languages/index.js";
import { Fragment } from "react";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import { updateLegalDocs } from "../../../actions/index";
import { Toaster } from "react-hot-toast";
import "react-phone-input-2/lib/material.css";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { classNames } from "../../../../../../common/common-functions";

class UpdateFile extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      enar: "en",
      btnLoader: false,
    };

    // All Binded Functions
  }

  onSubmitForm(data) {
    this.setState({ btnLoader: true });
    if (
      data.hasOwnProperty("terms_conditions_en") &&
      data.hasOwnProperty("terms_conditions_ar")
    ) {
      let blankspaces_en = [];
      var terms_conditions_en_text = data.terms_conditions_en
        .replace(/<[^>]*>?/gm, "")
        .replace(/&nbsp;/g, "");

      blankspaces_en.push(_.trim(terms_conditions_en_text));

      let blankspaces_ar = [];
      var terms_conditions_ar_text = data.terms_conditions_ar
        .replace(/<[^>]*>?/gm, "")
        .replace(/&nbsp;/g, "");

      blankspaces_ar.push(_.trim(terms_conditions_ar_text));
      // console.log('1',blankspaces)
      if (blankspaces_en[0] == "" || blankspaces_ar[0] == "") {
        NotificationManager.error("Terms & Condition Cannoot Be Empty");
        return false;
      } else {
        let values = {
          id: 1,
          terms_conditions_en: blankspaces_en[0],
          terms_conditions_ar: blankspaces_ar[0],
        };

        this.props.updateLegalDocs(this.props.session, values, (response) => {
          if (response.success === 0) {
            NotificationManager.error(LocaleStrings.updatederror);
          } else {
            this.props.onClose();
            NotificationManager.success(LocaleStrings.updatedsuccessfully);
          }
        });
      }
    }
  }

  /**** INPUT LANGUAGE CHANGE en - ar ****/
  handleInputLanguageChange(e) {
    if (parseInt(e.target.value) === 1) {
      this.setState({ enar: "ar" });
    } else {
      this.setState({ enar: "en" });
    }
  }

  render() {
    // console.log('initialValues',this.props.initialValues);
    var { handleSubmit, pristine, reset, submitting, invalid } = this.props;
    let { enar } = this.state;
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.myRef}
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 bigmodalTinyMCe">
                  <div className="grid gap-4  md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3  border-b-2 border-gray-50 pb-4">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center">
                      {LocaleStrings.global_setting_termsCond}
                    </div>
                    <div className="flex flex-wrap content-center justify-end">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}
                        ref={this.myRef}>
                        <span className="sr-only"></span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className="space-y-8 divide-y divide-gray-200 mt-5"
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                    encType="multipart/form-data">
                    <div style={{ display: "flex" }}>
                      <label className="mr-5 text-box-lable">
                        {LocaleStrings.language}
                      </label>

                      <Field
                        name="language"
                        label={LocaleStrings.en}
                        component={this.renderFieldRadioNew}
                        type="radio"
                        value="0"
                        className="mx-2"
                        onChange={this.handleInputLanguageChange.bind(this)}
                      />

                      <Field
                        name="language"
                        label={LocaleStrings.ar}
                        component={this.renderFieldRadioNew}
                        type="radio"
                        value="1"
                        className="mx-2"
                        onChange={this.handleInputLanguageChange.bind(this)}
                      />
                    </div>
                    <div className="pt-5">
                      <div className="mb-5">
                        <Field
                          name={"terms_conditions_" + enar}
                          label={"Terms_conditions_" + enar}
                          placeholder="terms_conditions_en"
                          mandatory="true"
                          type="minimal"
                          component={this.renderRichTextareaWithLabelTinymceNew}
                          labelposition={LABEL_POSITION_TOP}
                          height={300}
                          classNameLabel="block text-box-lable"
                        />
                      </div>
                      <div className="center-item pt-8">
                        <button
                          //   onClick={}
                          type="submit"
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 ">
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "sr-only",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          {LocaleStrings.global_setting_updateFile}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <Toaster limit={1} />
      </>
    );
  }
}
function validate(values) {
  var errors = {};
  var terms_conditions_en = values["terms_conditions_en"];
  var terms_conditions_ar = values["terms_conditions_ar"];
  //  console.log('terms_conditions_en',terms_conditions_en)

  if (!terms_conditions_en || terms_conditions_en.trim() === "") {
    errors.terms_conditions_en = "Required";
  }
  if (!terms_conditions_ar || terms_conditions_ar.trim() === "") {
    errors.terms_conditions_ar = LocaleStrings.required;
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log("global_commissionlist", state.global_commissionlist);
  let newObj = {};
  if (state.global_commissionlist)
    newObj = {
      terms_conditions_en: state.global_commissionlist
        ? state.global_commissionlist.data
          ? state.global_commissionlist.data.terms_conditions_en
          : ""
        : "",
      terms_conditions_ar: state.global_commissionlist
        ? state.global_commissionlist.data
          ? state.global_commissionlist.data.terms_conditions_ar
          : ""
        : "",
      language: "0",
    };

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    // initialValues: state.global_commissionlist.data,
    initialValues: newObj,
  };
}

export default connect(mapStateToProps, { updateLegalDocs })(
  reduxForm({
    validate,
    form: "TermsAndConditionForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(UpdateFile)
);
