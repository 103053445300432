import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import {
  converDateIntoLocal,
  ProductTextLocalized,
  getIntToFloat,
} from "../../../../../common/common-functions";
import AllneworderdetailsModals from "../../../ordermanagement/components/Allnewoders/allnewordermodal";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../currency/action/index";
import LocaleStrings from "../../../../languages";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { ordernotprocessedreminder } from "../../actions/index";

class Ordernotprocesseditems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      cancelorderDetailsisOpen: false,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  openRejectModal = (e) => this.setState({ setOpen: true });
  closeRejectModal = () => this.setState({ setOpen: false });
  opencancelorderDetailsModal = (e) =>
    this.setState({ cancelorderDetailsisOpen: true });
  closecancelorderDetailsModal = () =>
    this.setState({ cancelorderDetailsisOpen: false });

  _sendReminder(e, values) {
    console.log("values", values);
    let obj = {};
    obj.orderdetailid = values.orderdetailid;

    confirmAlert({
      title: "Send Reminder",
      message: `Are you sure you want to send reminder?`,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            var id = values;
            // console.log('id',id)
            this.props.ordernotprocessedreminder(
              this.props.session,
              obj,
              (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error) {
                  NotificationManager.error("Something went wrong!");
                } else {
                  NotificationManager.success("Reminder Sent to the user");
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { values, language, localCurrency } = this.props;
    // console.log('replace', values);

    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    let tabledate = "";
    tabledate = converDateIntoLocal(values.product.createdon).format(
      "D MMM YYYY"
    );
    let grand_total = 0.0;
    let productCurrency = values.product?.odcurrency;

    grand_total = values?.summary.grand_total;
    if (values.product.currentstatus.status == "replace") {
      var status = "Replacement Requested";
    } else if (values.product.currentstatus.status == "reship") {
      var status = "Replace Reshipped";
    } else if (values.product.currentstatus.status == "intransit") {
      var status = "Intransit";
    } else if (values.product.currentstatus.status == "returnpicked") {
      var status = "Return Picked";
    } else if (values.product.currentstatus.status == "refunded") {
      var status = "Refunded";
    } else if (values.product.currentstatus.status == "refundrejected") {
      var status = "Refund Rejected";
    } else if (values.product.currentstatus.status == "return") {
      var status = "Return Requested";
    } else if (values.product.currentstatus.status == "returnrequested") {
      var status = "Return";
    } else if (values.product.currentstatus.status == "placed") {
      var status = "Placed";
    }
    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50" id={this.props.key}>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
            onClick={(e) => this.opencancelorderDetailsModal(e, values)}>
            <div>{values.product.invoice_no}</div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
            onClick={(e) => this.opencancelorderDetailsModal(e, values)}>
            <div>{values.product.sku}</div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
            onClick={(e) => this.opencancelorderDetailsModal(e, values)}>
            <div>{values.product.seller.username}</div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
            onClick={(e) => this.opencancelorderDetailsModal(e, values)}>
            <div>
              {currency} {grand_total.toFixed(2)}{" "}
            </div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
            onClick={(e) => this.opencancelorderDetailsModal(e, values)}>
            <div>{values.purchasedby.username}</div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
            onClick={(e) => this.opencancelorderDetailsModal(e, values)}>
            <div>{tabledate}</div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
            onClick={(e) => this.opencancelorderDetailsModal(e, values)}>
            {status}
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
            onClick={(e) => this.opencancelorderDetailsModal(e, values)}>
            {/* <div>
              {ProductTextLocalized(
                values.product.productname_en,
                values.product.productname_ar,
                language
              )}
            </div> */}
            <div>
              {values.product.productname_en.length > 35
                ? values.product.productname_en.substring(0, 35) + "..."
                : values.product.productname_en}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {values.product.currentstatus.status !== "refunded" ||
            values.product.currentstatus.status !== "refundrejected" ? (
              <div className="action-button-area" style={{ display: "flex" }}>
                <div className="">
                  <button
                    className="button"
                    onClick={(e) => this._sendReminder(e, values)}
                    type="button">
                    Send Reminder
                  </button>
                </div>
              </div>
            ) : values.product.currentstatus.status == "refunded" ? (
              <div>Refunded</div>
            ) : values.product.currentstatus.status == "refundrejected" ? (
              <div>Refund Rejected</div>
            ) : (
              ""
            )}
          </td>
        </tr>
        {this.state.cancelorderDetailsisOpen ? (
          <AllneworderdetailsModals
            isOpen={this.state.cancelorderDetailsisOpen}
            onClose={this.closecancelorderDetailsModal}
            data={this.props.values}
            newKey="ordernotprocessed"
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    localCurrency: state.currency,
  };
}

export default connect(mapStateToProps, {
  ordernotprocessedreminder,
  isCurrencyRateApplicable,
})(Ordernotprocesseditems);
