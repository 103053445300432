import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
// import { showError, showSuccess } from "ReduxErrorBar";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import _ from "lodash";
import {
  ProductTextLocalized,
  classNames,
} from "../../../../../common/common-functions";

import {
  addDeliveryAgents,
  fetchAllDeliveryPartners,
} from "../../actions/index";
import { NotificationManager } from "react-notifications";

class AddnewDeliveryAgent extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      btnLoader: false,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }
  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
    this.props.reset();
  }

  onSubmitForm(values) {
    let packages = [];
    if (
      !values.packagename ||
      values.packagename.length == 0 ||
      values.packagename == ""
    ) {
      // alert("done");
      NotificationManager.error("Please add Services");
      return false;
    }
    this.setState({ btnLoader: true });
    values.packagename.map((val, key) => {
      let obj = val.value;
      packages.push(obj);
    });
    // var  allpackages = packages.join(",");

    var deliveryagentAddData = {
      packages: packages,
      companyname: values.companyname,
      keycode: values.keycode,
      toedit: 0,
    };
    this.props.addDeliveryAgents(
      this.props.session,
      deliveryagentAddData,
      (response) => {
        if (response.success === 0) {
          this.setState({ loading: false });
          // alert("error");
          NotificationManager.error(LocaleStrings.addingfailed);
        } else {
          this.props.fetchAllDeliveryPartners(
            this.props.session,
            1,
            (CallBackResponse) => {
              this.setState({ loading: false });
            }
          );
          this.setState({ open: false });
          // alert("done");
          NotificationManager.success(LocaleStrings.addedsucessfully);
        }
      }
    );
  }

  render() {
    var { handleSubmit } = this.props;

    var DeliveryPackagesOpts = [];

    _.forEach(this.props.ServicesAvailable, function (value) {
      var obj = {
        label: ProductTextLocalized(value.name),
        value: value.id.toString(),
        // value: value.value,
      };

      DeliveryPackagesOpts.push(obj);
    });

    return (
      <div className=" mt-5 mr-6" style={{ overflowX: "hidden" }}>
        <div className="flex flex-wrap content-center justify-end px-2">
          <button
            onClick={this.openModal}
            type="submit"
            className="lg:w-48 md:w-44 sm:w-44 xs:w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
            {LocaleStrings.global_setting_addAgents}
            <img
              src="../images/product_management_images/add.svg"
              className="h-5 ml-2"
            />
          </button>
          <Transition.Root show={this.state.open} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed z-10 inset-0 overflow-y-auto"
              open={this.state.open}
              onClose={this.closeModal}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                  <div
                    className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                    style={{ padding: 0 }}>
                    {/* MOdal Header */}
                    <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                      <div className="flex flex-wrap content-center justify-center mt-4">
                        <div className="text-md leading-6 font-medium text-secondary">
                          {LocaleStrings.global_setting_addAgents}
                        </div>
                      </div>
                      <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                        <button
                          type="button"
                          className="btn-closeModal"
                          onClick={this.closeModal}>
                          <span className="sr-only">{LocaleStrings.close}</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <form
                      className=" p-6"
                      onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
                      <Field
                        name="companyname"
                        label="Company Name"
                        placeholder="Company Name"
                        type="text"
                        component={this.renderFieldcustomer}
                        className="appearance-none block w-half px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                      <div className="mb-5">
                        <Field
                          name="keycode"
                          label="Key Code"
                          placeholder="Key Code"
                          mandatory="false"
                          component={this.renderFieldTextareaNew}
                          maxlength={200}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-box-lable"
                          className="py-3 px-4 block w-full shadow-sm text-secondary focus:ring-primary focus:border-teal-500 border border-warm-gray-300 rounded-md"
                        />
                      </div>
                      <div className="mb-5">
                        <Field
                          name="packagename"
                          label="Services Available"
                          placeholder={LocaleStrings.select}
                          options={DeliveryPackagesOpts}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          isMulti={true}
                        />
                      </div>

                      <div className="w-full bottom-0 absolute mb-10">
                        <div className="flex justify-center">
                          <button type="submit" className="button">
                            <svg
                              className={classNames(
                                this.state.btnLoader ? "" : "sr-only",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.save}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    );
  }
}

function validate(values) {
  var errors = {};
  var keycode = values["keycode"];
  var companyname = values["companyname"];

  if (!keycode || keycode.trim() === "") {
    errors["keycode"] = "Required";
  }

  if (!companyname || companyname.trim() === "") {
    errors["companyname"] = "Required";
  }

  return errors;
}
function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    language: state.language,
    ServicesAvailable: state.ServicesAvailable,
  };
}

export default connect(mapStateToProps, {
  fetchAllDeliveryPartners,
  addDeliveryAgents,
})(
  reduxForm({
    validate,
    form: "ADDDELIVERYAGENT",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddnewDeliveryAgent)
);
