import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { APP_API_KEY, BASE_IMAGES_URL } from "../../../../common/constants";
import { isCutomnotificationOpen } from "../actions/index";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

class ViewCustomUsers extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      loading: true,
      loaderDesc: "Preparing Likes",
      modalHeaderText: "Likes",
    };

    // All Binded Functions
    this.closeSlideOvers = this.closeSlideOvers.bind(this);
  }

  componentDidMount() {
    // let { session,productID } = this.props;
    // this._isMounted = true;
    // if (this._isMounted) {
    //   setInterval(() => {
    //       if(this.props.isSlideropen){
    //       }
    //   }, 3000);
    // }
  }

  componentWillUnmount() {
    // this._isMounted = false;
  }

  /**** Close Slide Over  ****/
  closeSlideOvers() {
    this.props.isCutomnotificationOpen(false);
  }

  // // Render Product Likes List
  renderlist = (e) => {
    //  console.log('customUserstest',this.props.customUsers)
    let { customUsers } = this.props;

    let item = _.map(this.props.values, (notificationforUsers, index) => {
      var productImageUrl = `${BASE_IMAGES_URL}/users/${notificationforUsers.userid}.jpg?api_key=${APP_API_KEY}`;
      // console.log('hover',notificationforUsers);
      return (
        <li
          key={index}
          className={"py-4 bg-custWhite px-4  table-hover-transition"}>
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0">
              <img
                className="h-10 w-10 rounded-full"
                src={productImageUrl}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../images/user_1.png";
                }}
                alt="Image"
              />
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-secondary truncate">
                {notificationforUsers.username}
              </p>
            </div>
            {/* <div className="text-ternary text-xs">
                        {converDateIntoLocal(notificationforUsers.createdon).format(DATE_FORMAT_TO_SHOW_SHORT)}
                    </div> */}
          </div>
        </li>
      );
    });

    return item;
  };

  render() {
    let { isSlideropen, language, session } = this.props;
    let { modalHeaderText } = this.state;

    // Price Functionality

    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 overflow-hidden z-50"
            initialFocus={this.myRef}
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="absolute inset-0 overflow-hidden">
              <Dialog.Overlay className="absolute inset-0" />

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <div className="w-screen max-w-md">
                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                      <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="slider-modal-headtext">
                              Notification Sent To
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                className="btn-closeModal"
                                onClick={this.props.onClose}
                                ref={this.myRef}>
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <ul
                          role="list"
                          className="-my-5 divide-y divide-gray-200 mt-2 mb-2">
                          {this.renderlist()}
                        </ul>
                      </div>
                      <div className="flex-shrink-0 px-4 py-4  justify-center">
                        {/* FOOTER */}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isSlideropen: state.isSlideropen,
    notificationList: state.notificationList ? state.all_users.data : [],
  };
}

export default connect(mapStateToProps, {
  isCutomnotificationOpen,
})(ViewCustomUsers);
