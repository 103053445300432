import moment from "moment";
import LocaleStrings from "../components/languages";
export const DEVELOPMENT_TYPE = "shopez-vendor";

// ========= Dev ============ //
export var MAIN_URL = "http://shopez.teks.co.in"; // Dev

export var INSTANCE_URL = "http://shopez.teks.co.in"; // Dev
export var FRONTEND_TICKET_URL = "https://tickets.eventify.io/events/"; // Test
// export var BASE_URL = "http://shopez.teks.co.in/api/v2";

// Stripe
export var STRIPE_PUBLISHABLE_KEY = "pk_test_mYqHtpyz7nX68JwESQs6bMQ8"; // Test
export var STRIPE_SECRET_KEY = "sk_test_QGxZXg6tB3T2hsxkUDV762Wi"; // Test
export const STRIPE_CLIENTID = "ca_FHZmJN9uD8yzTD2iI25j0RgqcKvJbUYl"; // Test

export var DOMAIN_NAME = DEVELOPMENT_TYPE === "shopez-vendor-web.io";
export var BASE_IMAGES_URL = `${INSTANCE_URL}/api/v2/files`;
export var BASE_INLINE_UPLOAD_BASE_URL = `${INSTANCE_URL}/eventifyscripts/uploadinline.php`; // 'http://app.eventify.io:83/eventifyscripts/uploadinline.php';
export var BASE_INLINE_IMAGE_URL =
  "http://staging.teks.co.in/eventify/eventifyscripts/";

export var TUTORIAL_VIDEO_URL = `${INSTANCE_URL}/api/v2/files/eventify/tutorialvids/`;

export var IMAGE_PATH = "../../../../../../../style/images/";
export var NEW_IMAGE_PATH = "../../../../../../../style/images/v2_icons/";

export var POLLRESULTPDF_PATH = `${INSTANCE_URL}/api/v2/files/eventify/pdfs/`;
export var SCHEDULEPDF_PATH = `${INSTANCE_URL}/api/v2/files/eventify/pdfs/`;
// old
// export var APP_API_KEY =
//   "23617836dfd5176076cac6b2827dda6e948b262a85b691248498ed8640171a86";
// new
export var APP_API_KEY =
  "55161aa8bc4d87d7ee880ac2d10ede8a3e16432ee9829df4211d605451223df4";
export var UPLOAD_FILES_API_KEY = APP_API_KEY; //'7ee3543d9a4d75a86ff4af6fc614fa989693bf35f5fa8dfe519c0f56c86231ce';
export var ACCESS_FILES_API_KEY =
  "23617836dfd5176076cac6b2827dda6e948b262a85b691248498ed8640171a86"; //APP_API_KEY;//'b5cb82af7b5d4130f36149f90aa2746782e59a872ac70454ac188743cb55b0ba';
export var PUBLIC_API_KEY =
  "23617836dfd5176076cac6b2827dda6e948b262a85b691248498ed8640171a86";
export var ONE_SIGNAL_KEY = "OTBmOGQzYWItNGEzOS00YjI0LWIyZDQtZjZjMTZiYjg0MDJj";
export var ONE_SIGNAL_ID = "5f63255c-7a1c-4d93-acd0-c76d599374aa";
export var GOOGLE_MAP_KEY = "AIzaSyCFUITnYyLeEW_nmAxZgYbMpfKQwLAlLMU";
export var DATE_FORMAT_SM = "yyyy-MM-dd HH:mm:ss";

export var BASE_STRING =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789ABCDEFGHIJKLMN56789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456";

export var LOGIN = "LOGIN";
export var REGISTER = "REGISTER";
export var SELECT_APP_ICON = "SELECTAPPICON";
export var UPDATE_UNSAVED_STATUS = "UPDATE_UNSAVED_STATUS";
export var SELECT_FEATURE = "select_feature";
export var SELECTED_CONTENT_FEATURE = "SELECTED_CONTENT_FEATURE";
export var CHANGE_PASSWORD = "CHANGE_PASSWORD";
export var EDIT_CONTENT_ICON_NAME = "EDIT_CONTENT_ICON_NAME";
export var CONTENT_TABS = "CONTENT_TABS";
export var UPDATE_FILES_UPLOAD_PROGRESS = "UPDATE_FILES_UPLOAD_PROGRESS";
export var FILES_UPLOAD_FAILED = "FILES_UPLOAD_FAILED";
export var FILES_UPLOADED = "FILES_UPLOADED";
export var CONTENT_FILE_TYPE_EVENTGUIDE = "eventguidefile";
export var CONTENT_FILE_TYPE_SPEAKER = "speakerfile";
export var CONTENT_FILE_TYPE_SPONSOR = "sponsorfile";
export var CONTENT_FILE_TYPE_EXHIBITOR = "exhibitorfile";
export var TO_CHANGE_EMAIL = "TO_CHANGE_EMAIL";
export var COMMON_FAIL_MESSAGE = "Something went wrong!!";

export const EVENTIFY_FEE = 1.0; // amount
export const STRIPE_FEE = 2.9;
export const STRIPE_FEE_EXTRA = 0.3;
export var MAX_INTEREST_COUNT = 20;
export var DATE_FORMAT = "YYYY-MM-DD";
export var DATE_FORMAT_WITH_TIME = "YYYY-MM-DD HH:mm";
export var DATE_FORMAT_WITH_TIME_WITH_SECOND = "YYYY-MM-DD HH:mm:ss";
export const isMobile = window.innerWidth <= 500;
export var DATE_FORMAT_TO_SHOW = "D MMM YYYY hh:mm A";

// CURRENCY
export var CURRENCY_RATE = 1;
export var CURRENCY = "$";

export var CURRENCY_RATE_USD = 1;
export var CURRENCY_RATE_QR = 3.65;
export var CURRENCY_USD = "$";
export var CURRENCY_QR = "QAR";

export var HIGHEND_PASSWORD = "uB9*#R!z";
export var DATE_FORMAT_TO_SHOW_SHORT = "D MMM YYYY";

export var itemCount = calculateItemCount();
export var DISPLAY_DATE_FORMAT = getLocaleShortDateString();
export var DISPLAY_LOCAL_DATE_FORMAT = localeDateFormat();
export const isTablet = determineIfTablet();

export function validatePercent(value) {
  // console.log("value", value);
  var re = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
  return re.test(value);
}

function determineIfTablet() {
  var width = window.innerWidth;
  var height = window.innerHeight;

  var istablet = false;

  if (
    (width === 1366 && height === 1024) ||
    (height === 1365 && width === 1024)
  ) {
    //Check for iPad Pro
    istablet = true;
  } else if (
    (width === 1024 && height === 768) ||
    (height === 768 && width === 1024)
  ) {
    //Check for iPad
    istablet = true;
  }
  return istablet;
}

function calculateItemCount() {
  //dynamically calcualting number of items to show as per height of the screen
  var height = window.innerHeight - 250; //250 is calculated to deduct extra space used other than just table
  var maxrowheight = 50;

  var count = Math.ceil(height / maxrowheight);

  return count < 1 ? 1 : count;
}

function getLocaleShortDateString() {
  var d = new Date(1992, 0, 7);
  var f = {
    "ar-SA": "dd/MM/yy",
    "bg-BG": "dd.M.yyyy",
    "ca-ES": "dd/MM/yyyy",
    "zh-TW": "yyyy/M/d",
    "cs-CZ": "d.M.yyyy",
    "da-DK": "dd-MM-yyyy",
    "de-DE": "dd.MM.yyyy",
    "el-GR": "d/M/yyyy",
    "en-US": "M/d/yyyy",
    "fi-FI": "d.M.yyyy",
    "fr-FR": "dd/MM/yyyy",
    "he-IL": "dd/MM/yyyy",
    "hu-HU": "yyyy. MM. dd.",
    "is-IS": "d.M.yyyy",
    "it-IT": "dd/MM/yyyy",
    "ja-JP": "yyyy/MM/dd",
    "ko-KR": "yyyy-MM-dd",
    "nl-NL": "d-M-yyyy",
    "nb-NO": "dd.MM.yyyy",
    "pl-PL": "yyyy-MM-dd",
    "pt-BR": "d/M/yyyy",
    "ro-RO": "dd.MM.yyyy",
    "ru-RU": "dd.MM.yyyy",
    "hr-HR": "d.M.yyyy",
    "sk-SK": "d. M. yyyy",
    "sq-AL": "yyyy-MM-dd",
    "sv-SE": "yyyy-MM-dd",
    "th-TH": "d/M/yyyy",
    "tr-TR": "dd.MM.yyyy",
    "ur-PK": "dd/MM/yyyy",
    "id-ID": "dd/MM/yyyy",
    "uk-UA": "dd.MM.yyyy",
    "be-BY": "dd.MM.yyyy",
    "sl-SI": "d.M.yyyy",
    "et-EE": "d.MM.yyyy",
    "lv-LV": "yyyy.MM.dd.",
    "lt-LT": "yyyy.MM.dd",
    "fa-IR": "MM/dd/yyyy",
    "vi-VN": "dd/MM/yyyy",
    "hy-AM": "dd.MM.yyyy",
    "az-Latn-AZ": "dd.MM.yyyy",
    "eu-ES": "yyyy/MM/dd",
    "mk-MK": "dd.MM.yyyy",
    "af-ZA": "yyyy/MM/dd",
    "ka-GE": "dd.MM.yyyy",
    "fo-FO": "dd-MM-yyyy",
    "hi-IN": "dd-MM-yyyy",
    "ms-MY": "dd/MM/yyyy",
    "kk-KZ": "dd.MM.yyyy",
    "ky-KG": "dd.MM.yy",
    "sw-KE": "M/d/yyyy",
    "uz-Latn-UZ": "dd/MM yyyy",
    "tt-RU": "dd.MM.yyyy",
    "pa-IN": "dd-MM-yy",
    "gu-IN": "dd-MM-yy",
    "ta-IN": "dd-MM-yyyy",
    "te-IN": "dd-MM-yy",
    "kn-IN": "dd-MM-yy",
    "mr-IN": "dd-MM-yyyy",
    "sa-IN": "dd-MM-yyyy",
    "mn-MN": "yy.MM.dd",
    "gl-ES": "dd/MM/yy",
    "kok-IN": "dd-MM-yyyy",
    "syr-SY": "dd/MM/yyyy",
    "dv-MV": "dd/MM/yy",
    "ar-IQ": "dd/MM/yyyy",
    "zh-CN": "yyyy/M/d",
    "de-CH": "dd.MM.yyyy",
    "en-GB": "dd/MM/yyyy",
    "es-MX": "dd/MM/yyyy",
    "fr-BE": "d/MM/yyyy",
    "it-CH": "dd.MM.yyyy",
    "nl-BE": "d/MM/yyyy",
    "nn-NO": "dd.MM.yyyy",
    "pt-PT": "dd-MM-yyyy",
    "sr-Latn-CS": "d.M.yyyy",
    "sv-FI": "d.M.yyyy",
    "az-Cyrl-AZ": "dd.MM.yyyy",
    "ms-BN": "dd/MM/yyyy",
    "uz-Cyrl-UZ": "dd.MM.yyyy",
    "ar-EG": "dd/MM/yyyy",
    "zh-HK": "d/M/yyyy",
    "de-AT": "dd.MM.yyyy",
    "en-AU": "d/MM/yyyy",
    "es-ES": "dd/MM/yyyy",
    "fr-CA": "yyyy-MM-dd",
    "sr-Cyrl-CS": "d.M.yyyy",
    "ar-LY": "dd/MM/yyyy",
    "zh-SG": "d/M/yyyy",
    "de-LU": "dd.MM.yyyy",
    "en-CA": "dd/MM/yyyy",
    "es-GT": "dd/MM/yyyy",
    "fr-CH": "dd.MM.yyyy",
    "ar-DZ": "dd-MM-yyyy",
    "zh-MO": "d/M/yyyy",
    "de-LI": "dd.MM.yyyy",
    "en-NZ": "d/MM/yyyy",
    "es-CR": "dd/MM/yyyy",
    "fr-LU": "dd/MM/yyyy",
    "ar-MA": "dd-MM-yyyy",
    "en-IE": "dd/MM/yyyy",
    "es-PA": "MM/dd/yyyy",
    "fr-MC": "dd/MM/yyyy",
    "ar-TN": "dd-MM-yyyy",
    "en-ZA": "yyyy/MM/dd",
    "es-DO": "dd/MM/yyyy",
    "ar-OM": "dd/MM/yyyy",
    "en-JM": "dd/MM/yyyy",
    "es-VE": "dd/MM/yyyy",
    "ar-YE": "dd/MM/yyyy",
    "en-029": "MM/dd/yyyy",
    "es-CO": "dd/MM/yyyy",
    "ar-SY": "dd/MM/yyyy",
    "en-BZ": "dd/MM/yyyy",
    "es-PE": "dd/MM/yyyy",
    "ar-JO": "dd/MM/yyyy",
    "en-TT": "dd/MM/yyyy",
    "es-AR": "dd/MM/yyyy",
    "ar-LB": "dd/MM/yyyy",
    "en-ZW": "M/d/yyyy",
    "es-EC": "dd/MM/yyyy",
    "ar-KW": "dd/MM/yyyy",
    "en-PH": "M/d/yyyy",
    "es-CL": "dd-MM-yyyy",
    "ar-AE": "dd/MM/yyyy",
    "es-UY": "dd/MM/yyyy",
    "ar-BH": "dd/MM/yyyy",
    "es-PY": "dd/MM/yyyy",
    "ar-QA": "dd/MM/yyyy",
    "es-BO": "dd/MM/yyyy",
    "es-SV": "dd/MM/yyyy",
    "es-HN": "dd/MM/yyyy",
    "es-NI": "dd/MM/yyyy",
    "es-PR": "dd/MM/yyyy",
    "am-ET": "d/M/yyyy",
    "tzm-Latn-DZ": "dd-MM-yyyy",
    "iu-Latn-CA": "d/MM/yyyy",
    "sma-NO": "dd.MM.yyyy",
    "mn-Mong-CN": "yyyy/M/d",
    "gd-GB": "dd/MM/yyyy",
    "en-MY": "d/M/yyyy",
    "prs-AF": "dd/MM/yy",
    "bn-BD": "dd-MM-yy",
    "wo-SN": "dd/MM/yyyy",
    "rw-RW": "M/d/yyyy",
    "qut-GT": "dd/MM/yyyy",
    "sah-RU": "MM.dd.yyyy",
    "gsw-FR": "dd/MM/yyyy",
    "co-FR": "dd/MM/yyyy",
    "oc-FR": "dd/MM/yyyy",
    "mi-NZ": "dd/MM/yyyy",
    "ga-IE": "dd/MM/yyyy",
    "se-SE": "yyyy-MM-dd",
    "br-FR": "dd/MM/yyyy",
    "smn-FI": "d.M.yyyy",
    "moh-CA": "M/d/yyyy",
    "arn-CL": "dd-MM-yyyy",
    "ii-CN": "yyyy/M/d",
    "dsb-DE": "d. M. yyyy",
    "ig-NG": "d/M/yyyy",
    "kl-GL": "dd-MM-yyyy",
    "lb-LU": "dd/MM/yyyy",
    "ba-RU": "dd.MM.yy",
    "nso-ZA": "yyyy/MM/dd",
    "quz-BO": "dd/MM/yyyy",
    "yo-NG": "d/M/yyyy",
    "ha-Latn-NG": "d/M/yyyy",
    "fil-PH": "M/d/yyyy",
    "ps-AF": "dd/MM/yy",
    "fy-NL": "d-M-yyyy",
    "ne-NP": "M/d/yyyy",
    "se-NO": "dd.MM.yyyy",
    "iu-Cans-CA": "d/M/yyyy",
    "sr-Latn-RS": "d.M.yyyy",
    "si-LK": "yyyy-MM-dd",
    "sr-Cyrl-RS": "d.M.yyyy",
    "lo-LA": "dd/MM/yyyy",
    "km-KH": "yyyy-MM-dd",
    "cy-GB": "dd/MM/yyyy",
    "bo-CN": "yyyy/M/d",
    "sms-FI": "d.M.yyyy",
    "as-IN": "dd-MM-yyyy",
    "ml-IN": "dd-MM-yy",
    "en-IN": "dd-MM-yyyy",
    "or-IN": "dd-MM-yy",
    "bn-IN": "dd-MM-yy",
    "tk-TM": "dd.MM.yy",
    "bs-Latn-BA": "d.M.yyyy",
    "mt-MT": "dd/MM/yyyy",
    "sr-Cyrl-ME": "d.M.yyyy",
    "se-FI": "d.M.yyyy",
    "zu-ZA": "yyyy/MM/dd",
    "xh-ZA": "yyyy/MM/dd",
    "tn-ZA": "yyyy/MM/dd",
    "hsb-DE": "d. M. yyyy",
    "bs-Cyrl-BA": "d.M.yyyy",
    "tg-Cyrl-TJ": "dd.MM.yy",
    "sr-Latn-BA": "d.M.yyyy",
    "smj-NO": "dd.MM.yyyy",
    "rm-CH": "dd/MM/yyyy",
    "smj-SE": "yyyy-MM-dd",
    "quz-EC": "dd/MM/yyyy",
    "quz-PE": "dd/MM/yyyy",
    "hr-BA": "d.M.yyyy.",
    "sr-Latn-ME": "d.M.yyyy",
    "sma-SE": "yyyy-MM-dd",
    "en-SG": "d/M/yyyy",
    "ug-CN": "yyyy-M-d",
    "sr-Cyrl-BA": "d.M.yyyy",
    "es-US": "M/d/yyyy",
  };

  var l = navigator.language ? navigator.language : navigator["userLanguage"],
    y = d.getFullYear(),
    m = d.getMonth() + 1,
    d = d.getDate();
  f = l in f ? f[l] : "MM/dd/yyyy";
  return f.toUpperCase();
}

function localeDateFormat() {
  var localeData = moment.localeData();
  var userDateFormat = localeData.longDateFormat("L");
  //To be determined properly.
  userDateFormat = "DD-MM-YYYY HH:mm";
  return userDateFormat;
}

export function converDateIntoLocal(date) {
  var stillUtc = moment.utc(date).toDate();
  var local = moment(stillUtc).local();
  // .format("DD-MM-YYYY HH:mm");
  return local;
}

// Get Device height (calculate)
export var contentHeight = calculateHeight();

function calculateHeight() {
  if (window.innerHeight < 665) {
    return window.innerHeight + 60;
  } else {
    return 0;
  }
}

export function validateEmail(email) {
  var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  return re.test(email);
}

export function validateUrl(url) {
  var re =
    /^(?:(?:(?:https?|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
  return re.test(url);
}

export function validateOnlyNums(value) {
  var re = /^\d+$/;
  var check = re.test(value);

  return check;
}

export function validatePhoneNumbers(value) {
  let re =
    /^[\+]?[(]?[0-9]{2,5}[)]?[-\s\.]?[0-9]{2,5}[-\s\.]?[0-9]{2,5}[-\s\.]?[0-9]{4,6}$/im;
  // let re = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/
  return re.test(value);
}

export function timeDiffCalc(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += `${days}D `;
  }

  difference += `${hours}h `;

  difference += `${minutes}m`;

  return difference;
}

export function validateWhitespace(value) {
  return value === null || value.match(/^ *$/) !== null;
}

export function validateSpecialChar(string) {
  var re = /[~`!@#$%\^&*()+=\-\[\]\\';,/{}|\\":<>\?]/g;
  return re.test(string);
}

export var dashboardContentWidth = calculatedDashboardContentWidth();

function calculatedDashboardContentWidth() {
  return window.innerWidth - (isTablet ? 0 : 320);
}

// Calculate Device width
export var chartWidth = calculatedChartWidth();

function calculatedChartWidth() {
  return window.innerWidth - 200;
}

export var usersChartWidth = calculatedUsersChartWidth();

function calculatedUsersChartWidth() {
  return window.innerWidth - 400;
}

export var fullDashboardContentWidth = calculatedFullDashboardContentWidth();

function calculatedFullDashboardContentWidth() {
  return window.innerWidth - 5;
}

export var contentContainerWidth = calculatedContentContainerWidth();

function calculatedContentContainerWidth() {
  // return (window.innerWidth-(isTablet?0:320)-275);
  return window.innerWidth - 324;
}

export var ticketsContentContainerWidth =
  calculatedTicketsContentContainerWidth();

function calculatedTicketsContentContainerWidth() {
  return window.innerWidth - 324;
}

export var commonContainerWidth = calculatedCommonContainerWidth();

function calculatedCommonContainerWidth() {
  return window.innerWidth - 325;
}

export var chooserPopupHeight = calculatedChooserPopupHeight();

function calculatedChooserPopupHeight() {
  return window.innerHeight - 150;
}

export var chooserPopupGridHeight = calculatedChooserPopupGridHeight();

function calculatedChooserPopupGridHeight() {
  return window.innerHeight - 170;
}

export var courseContainerWidth = calculatedCourseContainerWidth();
function calculatedCourseContainerWidth() {
  return window.innerWidth - 325;
}

export var GRAPESJE_EDITOR = "http://staging.teks.co.in/udayan/grapejs1/";

export function statusarray() {
  let statusArr = [
    { name: "delivered", nametoshow: LocaleStrings.order_delivered },
    { name: "intransit", nametoshow: LocaleStrings.in_transit },
    { name: "dispatched", nametoshow: LocaleStrings.order_dispatched },
    { name: "placed", nametoshow: LocaleStrings.order_Placed },
    { name: "cancelled", nametoshow: LocaleStrings.order_cancelled },
    { name: "returnreceived", nametoshow: LocaleStrings.return_received },
    { name: "returnpicked", nametoshow: LocaleStrings.return_picked },
    { name: "initiatepickup", nametoshow: LocaleStrings.initiate_pickup },
    { name: "return", nametoshow: LocaleStrings.return_requested },
    { name: "replace", nametoshow: LocaleStrings.replacement_requested },
    { name: "refund", nametoshow: LocaleStrings.refund_initiated },
    { name: "pending", nametoshow: LocaleStrings.payment_pending },
    { name: "refundrejected", nametoshow: LocaleStrings.refund_rejected },
    { name: "refunded", nametoshow: LocaleStrings.refunded },
    { name: "reshipdelivered", nametoshow: LocaleStrings.reshipdelivered },
    { name: "reship", nametoshow: LocaleStrings.replace_reshippped },
    { name: "cancelrefund", nametoshow: LocaleStrings.cancel_refund },
    { name: "cancelrefunded", nametoshow: LocaleStrings.cancel_refunded },
    {
      name: "cancelrefundrejected",
      nametoshow: LocaleStrings.cancel_refundrejected,
    },
    {
      name: "initiatereplacepickup",
      nametoshow: LocaleStrings.initiate_replace_pickup,
    },
    {
      name: "cancelreturn",
      nametoshow: LocaleStrings.return_cancelled,
    },
    {
      name: "cancelledrefundprocessing",
      nametoshow: LocaleStrings.refund_processing,
    },
    {
      name: "returnrefundprocessing",
      nametoshow: LocaleStrings.refund_processing,
    },
  ];
  return statusArr;
}

export const loadConfirmationButton = (confirm, text = "") => {
  // console.log(confirm);

  confirm.current.innerText = text === "" ? "Processing..." : text;
  confirm.current.style.opacity = "0.5";
  confirm.current.style.cursor = "default";
  confirm.current.disabled = true;

  confirm.current.nextSibling.style.opacity = "0.5";
  confirm.current.nextSibling.style.cursor = "default";
  confirm.current.nextSibling.disabled = true;
};
