import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../languages/index";
import { Fragment } from "react";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import NotificationList from "./notificationList";
import { NotificationManager } from "react-notifications";
import { classNames } from "../../../../common/common-functions";
import { addNotifications, fetchNotificationlist } from "../actions/index";

const notification_to = [
  { name: "Global", value: "global", index: 0 },
  { name: "Vendors Only", value: "vendors", index: 1 },
  { name: "Customers Only", value: "customers", index: 2 },
  { name: "Custom", value: "custom", index: 3 },
];

class GlobalNotification extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      notficationsending_to: notification_to,
      customFilter_state: false,
      btnLoader: false,
      enar: "en",
    };
    this.onSubmitForm = this.onSubmitForm.bind(this);
    // All Binded Functions
  }
  componentDidMount() {}

  _handleCustomfilters(e) {
    if (e.target.value == "custom") {
      this.setState({ customFilter_state: true });
    } else {
      this.setState({ customFilter_state: false });
    }
  }
  /**** INPUT LANGUAGE CHANGE en - ar ****/
  handleInputLanguageChange(e) {
    if (parseInt(e.target.value) === 1) {
      this.setState({ enar: "ar" });
    } else {
      this.setState({ enar: "en" });
    }
  }
  onSubmitForm(values) {
    // console.log('va;ue',values)

    let notification_title = "";
    let notification_text = "";
    // console.log('this.state.enar',this.state.enar)

    if (
      !values.hasOwnProperty("notification_text_en") &&
      !values.hasOwnProperty("notification_text_ar")
    ) {
      NotificationManager.error("Description of any one language is mandatory");
      return false;
    }

    if (
      !values.hasOwnProperty("notification_title_en") &&
      !values.hasOwnProperty("notification_title_ar")
    ) {
      NotificationManager.error("Title of any one language is mandatory");
      return false;
    }
    this.setState({ btnLoader: true });
    if (values.notification_for == "custom") {
      if (
        values.notification_for == "custom" &&
        values.hasOwnProperty("userid")
      ) {
        let customUserid = [];
        values.userid.map((val, key) => {
          let obj = val.value;
          customUserid.push(obj);
        });
        var customUserid_for = customUserid.join(",");

        // var body = {
        //   notification_for: values.notification_for,
        //   notification_text: notification_text,
        //   notification_title: notification_title,
        //   userids: customUserid_for,
        // };

        values.userids = customUserid_for;
        console.log("va;ues", values);
        debugger;

        this.props.addNotifications(this.props.session, values, (response) => {
          if (response.success === 0) {
            NotificationManager.error(LocaleStrings.addingfailed);
            this.props.reset();
          } else {
            this.props.fetchNotificationlist(
              this.props.session,
              (CallBackResponse) => {}
            );
            this.props.onClose();
            NotificationManager.success("Notification sent Successfully");
          }
        });
      } else if (
        values.notification_for == "custom" &&
        !values.hasOwnProperty("userid")
      ) {
        this.setState({ btnLoader: false });
        NotificationManager.error(
          "Please add user(s) to send custom notifications"
        );
        return false;
      }
    } else {
      // var body = {
      //   notification_for: values.notification_for,
      //   notification_text: notification_text,
      //   notification_title: notification_title,
      // };
      // console.log("vaues2", values);
      // debugger;
      this.props.addNotifications(this.props.session, values, (response) => {
        if (response.success === 0) {
          NotificationManager.error(LocaleStrings.addingfailed);
        } else {
          this.props.fetchNotificationlist(
            this.props.session,
            (CallBackResponse) => {}
          );
          this.props.onClose();
          NotificationManager.success("Notification sent Successfully");
        }
      });
    }
  }

  render() {
    var { handleSubmit } = this.props;
    let { enar } = this.state;
    var customFilterOpts = []; // Category
    var allUserOpts = [];

    _.forEach(this.props.customNotification, function (value) {
      var obj = {
        label: value.name,
        value: value.value,
      };

      customFilterOpts.push(obj);
    });
    _.forEach(this.props.all_users, function (value) {
      var obj = {
        label: value.username,
        value: value.userid.toString(),
      };

      allUserOpts.push(obj);
    });

    return (
      <Transition.Root show={this.props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.props.isOpen}
          onClose={this.props.onClose}
          initialFocus={this.myRef}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide bigmodal"
                style={{ padding: 0 }}>
                <div className="grid grid-cols-3 border-b-2 border-gray-200 pb-4 sticky-header z-50">
                  <div></div>
                  <div className="flex flex-wrap content-center justify-center mt-4">
                    Notification
                  </div>
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4 ">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      onClick={this.props.onClose}
                      ref={this.myRef}>
                      <span className="sr-only">{LocaleStrings.close}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>

                <form
                  className="p-8"
                  onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                  encType="multipart/form-data">
                  <div className="my-5" style={{ display: "flex" }}>
                    <label className="mr-5 text-box-lable">
                      {LocaleStrings.language}
                    </label>

                    <Field
                      name="language"
                      label={LocaleStrings.en}
                      component={this.renderFieldRadioNew}
                      type="radio"
                      value="0"
                      className="mx-2"
                      onChange={this.handleInputLanguageChange.bind(this)}
                    />

                    <Field
                      name="language"
                      label={LocaleStrings.ar}
                      component={this.renderFieldRadioNew}
                      type="radio"
                      value="1"
                      className="mx-2"
                      onChange={this.handleInputLanguageChange.bind(this)}
                    />
                  </div>
                  <div className="mb-5">
                    <p>Compose new notification</p>
                    {_.map(this.state.notficationsending_to, (opt, index) => {
                      return (
                        <Field
                          name="notification_for"
                          label={opt.name}
                          value={opt.value}
                          lebelAlign="R"
                          type="radio"
                          onChange={this._handleCustomfilters.bind(this)}
                          component={this.renderFieldRadio1}
                        />
                      );
                    })}
                  </div>
                  {this.state.customFilter_state == true ? (
                    <>
                      <div className="mb-5">
                        <Field
                          name="userid"
                          label="Users"
                          placeholder={LocaleStrings.select}
                          options={allUserOpts}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          // onChange={this._handleCategoryScreen.bind(this)}
                          isMulti={true}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="mb-5">
                    <Field
                      name={"notification_title_" + enar}
                      label={"Notification Title"}
                      placeholder={LocaleStrings.title}
                      type="text"
                      component={this.renderFieldsAll}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="mb-5">
                    {/* <Field
                      name={"notification_text_" + enar}
                      label={"Notification Description"}
                      mandatory="true"
                      type="minimal"
                      component={this.renderRichTextareaWithLabelTinymceNew}
                      labelposition={LABEL_POSITION_TOP}
                      height={300}
                      classNameLabel="block text-box-lable"
                    /> */}

                    <Field
                      name={"notification_text_" + enar}
                      label={"Notification Description"}
                      placeholder="Enter description"
                      mandatory="false"
                      component={this.renderFieldTextareaNew}
                      maxlength={2000}
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-box-lable"
                      className="py-3 px-4 block w-full shadow-sm text-secondary focus:ring-primary focus:border-teal-500 border border-warm-gray-300 rounded-md"
                    />
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <svg
                          className={classNames(
                            this.state.btnLoader ? "" : "sr-only",
                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        Send notification
                      </button>
                    </div>
                  </div>
                </form>

                <div className="p-8 pt-0">
                  <NotificationList />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function validate(values) {
  // console.log("values", values);
  var errors = {};
  // var requiredFields = ["notification_title", "notificationtext_en"];

  // requiredFields.forEach((field) => {
  //   // if (typeof values[field] != 'undefined'){
  //   if (!values[field] || values[field] === "") {
  //     errors[field] = "Required";
  //   }
  //   // }
  // });

  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log("all_users", state.all_users);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    data: ownProps.data,
    customNotification: state.customNotification,
    all_users: state.all_users ? state.all_users.data : [],
    initialValues: { notification_for: "global", language: "0" },

    // sellerSimilarProducts:state.sellerSimilarProducts
  };
}

export default connect(mapStateToProps, {
  addNotifications,
  fetchNotificationlist,
})(
  reduxForm({
    validate,
    form: "GlobalNotificationForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(GlobalNotification)
);
