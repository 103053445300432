import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index";
import { reduxForm } from "redux-form";
import { BaseComponent } from "../../../../../../common/base-component";
import UpdatePrivacyPolicy from "./updateprivacy";

class PrivacyPolicy extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  openModal = (e) => {
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  render() {
    var { data, initialValues, handleSubmit } = this.props;
    // console.log("data", data);
    return (
      <>
        <div className="my-8">
          <div className="flow-root px-10 py-10 bg-custWhite rounded">
            <div className="grid grid-cols-2">
              {" "}
              <div>
                <p className="">
                  {LocaleStrings.global_setting_privacy_policy}
                </p>
              </div>
              <div className="end-item">
                <button
                  type="button"
                  className="button"
                  onClick={(e) => this.openModal(e)}>
                  {LocaleStrings.global_setting_updateFile}
                </button>{" "}
                {this.state.isOpen ? (
                  <UpdatePrivacyPolicy
                    isOpen={this.state.isOpen}
                    onClose={this.closeModal}
                    // values={this.props.values}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // console.log('ownProps.data', ownProps.data);
  return {
    session: state.session,
    data: ownProps.data,
    initialValues: ownProps.data,
  };
}

export default connect(
  mapStateToProps,
  {}
)(
  reduxForm({
    form: "LegalDocumentForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(PrivacyPolicy)
);
