import React, { Component } from "react";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment-timezone";
import {BASE_IMAGES_URL, APP_API_KEY} from "../../../../../common/constants";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import LocaleStrings from "../../../../languages";


class TicketModalMedia extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
      unbanopen: false,
    };
  }

  render() {
    var { values,key } = this.props;
    var img = `${BASE_IMAGES_URL}/suggestions/${values}?api_key=${APP_API_KEY}`;
  // console.log(values);
    return (
      <>
      {values.length == 0 ? (<>{LocaleStrings.common_no_content}</>):(<><img src={img}   className="my-2 pr-4" style={{height:'80px',width:'160px'}}/>
          </>)}
     
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,

  };
}

export default connect(mapStateToProps, {

})(TicketModalMedia);
