import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../languages/index.js";
import { Fragment } from "react";
import { BaseComponent } from "../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { reduxForm } from "redux-form";
import { banSeller, fetchSeller } from "../actions/index";
import { validateEmail } from "../../../../common/constants";
import "react-phone-input-2/lib/material.css";
import _ from "lodash";
import { NotificationManager } from "react-notifications";

class BanSeller extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      phone: "",
      setPhone: "",
    };

    // All Binded Functions
  }
  onSubmitForm(data) {
    let { user } = this.props;
    let callfrom = "verified";
    if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/unverified"
    ) {
      callfrom = "unverified";
    } else if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/verficationrequested"
    ) {
      callfrom = "verficationrequested";
    }
    var values = { userid: data.userid, isactive: 0, bannedby: user.userid };
    // this.setState({ loading: true });
    this.props.banSeller(this.props.session, values, (response) => {
      // console.log("response :- ", response);
      if (response.success === 0) {
        this.props.onClose();
        NotificationManager.error(LocaleStrings.seller_management_errorban);
      } else {
        NotificationManager.success(LocaleStrings.seller_management_successban);
        this.props.pagination();
        // this.props.fetchSeller(
        //   this.props.session,
        //   this.props.currentPage,
        //   callfrom,
        //   (CallBackResponse) => {}
        // );
        this.props.onClose();
        // alert("success");
      }
    });
  }

  render() {
    // console.log('initialValues',this.props.initialValues);
    var { handleSubmit } = this.props;
    return (
      <Transition.Root show={this.props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={this.myRef}
          open={this.props.isOpen}
          onClose={this.props.onClose}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="grid gap-4  md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 mb-10 border-b-2 border-gray-50 pb-4">
                  <div></div>
                  <div className="flex flex-wrap content-center justify-center">
                    {LocaleStrings.seller_management_banSeller}
                  </div>
                  <div className="flex flex-wrap content-center justify-end">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      onClick={this.props.onClose}
                      ref={this.myRef}>
                      <span className="sr-only"></span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <form
                  className="space-y-8 divide-y divide-gray-200 mt-5"
                  onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                  encType="multipart/form-data">
                  {LocaleStrings.seller_management_banSeller_message}
                  <div className="pt-5">
                    <div className="">
                      <button
                        //   onClick={}
                        type="submit"
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 ">
                        {LocaleStrings.yes}
                      </button>
                      <button
                        onClick={this.props.onClose}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 ">
                        {LocaleStrings.no}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
function validate(values) {
  // console.log("Validate ",values);
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.emailrequired;
  }

  if (!values.username) {
    errors.password = LocaleStrings.firstnamerequire;
  }

  if (!values.phone) {
    errors.passcode = LocaleStrings.phonenumberrequired;
  }

  if (values.email) {
    if (!validateEmail(values.email)) {
      errors.email = LocaleStrings.emailrequired;
    }
  }
  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('session',session);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.customermanagementTabs,
    sellerlistingtabs: state.sellerlistingtabs,
    newtablist: state.newtablist,
    customerlist: state.customerlist,
    initialValues: state.sellerlistingtabs,
  };
}

export default connect(mapStateToProps, {
  banSeller,
  fetchSeller,
})(
  reduxForm({
    validate,
    form: "EditsellerForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(BanSeller)
);
