import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from "../../../../common/network-call";

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  MAIN_URL,
  DEVELOPMENT_TYPE,
} from "../../../../common/constants";

import _ from "lodash";

import LocaleStrings from "../../../languages/index";

// Data Discount
export const DISCOUNTMANAGEMENT_MASTER = "DISCOUNTMANAGEMENT_MASTER";
export const DISCOUNTMANAGEMENT_PRODUCTCATEGORY =
  "DISCOUNTMANAGEMENT_PRODUCTCATEGORY";
export const DISCOUNTMANAGEMENT_PRODUCTSUBCATEGORY =
  "DISCOUNTMANAGEMENT_PRODUCTSUBCATEGORY";
export const DISCOUNTMANAGEMENT_CRITERIAMASTER =
  "DISCOUNTMANAGEMENT_CRITERIAMASTER";
export const DISCOUNTMANAGEMENT_DISCOUNTAREASELECTION =
  "DISCOUNTMANAGEMENT_DISCOUNTAREASELECTION";
export const DISCOUNTMANAGEMENT_PRODUCTLIST = "DISCOUNTMANAGEMENT_PRODUCTLIST";
export const DISCOUNTMANAGEMENT_MASTER_SINGLE =
  "DISCOUNTMANAGEMENT_MASTER_SINGLE";
// Modal
export const IS_DISCOUNTMANAGEMENT_MODAL_OPEN =
  "IS_DISCOUNTMANAGEMENT_MODAL_OPEN";

// Data Coupon
export const COUPONMANAGEMENT_MASTER = "COUPONMANAGEMENT_MASTER";
export const COUPONMANAGEMENT_MASTER_SINGLE = "COUPONMANAGEMENT_MASTER_SINGLE";
export const COUPONMANAGEMENT_SELECTED_TAB = "COUPONMANAGEMENT_SELECTED_TAB";
// Modal Coupon
export const IS_COUPONMANAGEMENT_MODAL_OPEN = "IS_COUPONMANAGEMENT_MODAL_OPEN";
export const ALL_PRODUCTS = "ALL_PRODUCTS";

/**** Fetch Product Category data  ****/
export function fetchProductCategory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productcategory?filter=(isactive=1)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: DISCOUNTMANAGEMENT_PRODUCTCATEGORY,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Product Subcategory Data ****/
export function fetchProductSubcategory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productsubcategory?filter=(isactive=1)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: DISCOUNTMANAGEMENT_PRODUCTSUBCATEGORY,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Criteria Master Data ****/
export function fetchCriteriaMaster(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/criteriamaster`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: DISCOUNTMANAGEMENT_CRITERIAMASTER,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Discount Area Data ****/
export function fetchDiscountArea(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discountareaselection`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: DISCOUNTMANAGEMENT_DISCOUNTAREASELECTION,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Discount Master data List ****/
export function fetchDiscountMaster(session, pageCount = 0, callback) {
  var perPage = 7;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;
  var orderby = `createdon%20DESC`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discount_master?include_count=true&${offSetQuery}&order=${orderby}&related=discountarea_by_iddiscount`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('dis',response)
        dispatch({
          type: DISCOUNTMANAGEMENT_MASTER,
          payload: { data: response.resource, count: response.meta.count },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        // console.log('error',error)
        // callback({ status: 0 ,message: LocaleStrings.something_went_wrong});
      }
    );
  };
}

export function searchDiscountMaster(session, search = "", pageCount = 0) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${itemCount}&offset=${offset}`;
  var searchQuery = `(title like %${search}%)`;
  var filter = encodeURI(`(${searchQuery})`);
  // var url = `${INSTANCE_URL}/api/v2/eventify/_table/sponsor?related=membershiptype_by_categoryid,appuser_by_sponsoridfk&include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;
  // var url = `${INSTANCE_URL}/api/v2/shopez/_table/discount_master?include_count=true&${offSetQuery}&order=${orderby}&fields=*&related=discountarea_by_iddiscount`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discount_master?include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}&related=discountarea_by_iddiscount`;
  return (dispatch) => {
    dispatch(fetchDiscountMaster(session));
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("SPONSOR: ", data);
        dispatch({
          type: DISCOUNTMANAGEMENT_MASTER,
          payload: { data: response.resource, count: response.meta.count },
        });
      },
      (error) => {}
    );
  };
}

/**** Fetch Products List ****/
/* Parameters
  
  If only category selection is available
  [“categoryid”:<selected-categoryid>,”discount”:1]

  If subcategory selection is available
	[“categoryid”:<selected-categoryid>,
  “subcategoryid”:<selected-subcategoryid>]

  */
export function fetchProductsByCateSubCate(
  session,
  selected_categoryid,
  selected_subcategoryid,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/listproductsbycategory`;

  if (
    (selected_categoryid == null || selected_categoryid == "") &&
    (selected_subcategoryid == null || selected_subcategoryid == "")
  ) {
    return (dispatch) => {
      dispatch({
        type: DISCOUNTMANAGEMENT_PRODUCTLIST,
        payload: {},
      });
    };
  } else {
    if (selected_subcategoryid == null || selected_subcategoryid == "") {
      var object = {
        categoryid: selected_categoryid,
        discount: 1,
        usertype: "superadmin",
      };
    } else {
      var object = {
        categoryid: selected_categoryid,
        subcategoryid: selected_subcategoryid,
        usertype: "superadmin",
      };
    }

    // Actual Data Call
    return (dispatch) => {
      postRequest(
        url,
        object,
        session,
        dispatch,
        (response) => {
          dispatch({
            type: DISCOUNTMANAGEMENT_PRODUCTLIST,
            payload: { data: response.data },
          });

          callback({ status: 1, message: LocaleStrings.api_success_status });
        },
        (error) => {
          callback({ status: 0, message: LocaleStrings.something_went_wrong });
        }
      );
    };
  }
}

// export function fetchProductsByCateSubCate(session,selected_categoryid,selected_subcategoryid,callback) {

//   var url = `${INSTANCE_URL}/api/v2/shopez/_table/productsubcategory?filter=(id=${selected_subcategoryid})`;

//   return (dispatch) => {
//     getRequest(
//     url,
//     session,
//     dispatch,
//     (response) => {

//        dispatch({

//         type: DISCOUNTMANAGEMENT_PRODUCTLIST,
//         payload: { data: response.resource },

//       });

//         callback({ status: 1 ,message: LocaleStrings.api_success_status});
//     },
//     (error) => {
//         callback({ status: 0 ,message: LocaleStrings.something_went_wrong});
//     }
//   );
// };

// }

/**** Update Discount Master *****/
export function updateDiscountMaster(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discount_master`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Add Discount Master ****/

export function addDiscountMaster(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discount_master`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource[0].id,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Add Discount Area *****/
export function addDiscountArea(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discountarea`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Validate Discount Area { Validate discount (Call this before adding or updating discount) } ****/
export function validatedDiscount(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/validatediscount`;
  let body = { rules: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('response',response)

        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Update Discount area ****/
export function updateDiscountArea(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discountarea`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Is Open Discount management modal ****/
export function isOpenDiscManagModal(isOpen) {
  return { type: IS_DISCOUNTMANAGEMENT_MODAL_OPEN, payload: isOpen };
}

/**** Single Object For Discount ****/
export function getSelectedDiscountObj(discountObj = {}, session) {
  if (Object.keys(discountObj).length > 0) {
    // console.log('discountObj',discountObj)
    discountObj["discounareaid"] =
      discountObj.discountarea_by_iddiscount[0].discounareaid;
    discountObj["idcategory"] =
      discountObj.discountarea_by_iddiscount[0].idcategory;
    discountObj["idsubcategory"] =
      discountObj.discountarea_by_iddiscount[0].idsubcategory;
    discountObj["idproduct"] =
      discountObj.discountarea_by_iddiscount[0].idproduct;
  }

  return { type: DISCOUNTMANAGEMENT_MASTER_SINGLE, payload: discountObj };
}

export function fetchCouponMaster(session, pageCount = 0, callback) {
  var perPage = 7;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;
  var orderby = `createdon%20DESC`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponmaster?include_count=true&${offSetQuery}&order=${orderby}&related=couponrule_by_couponid`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        //  console.log('response',response);
        dispatch({
          type: COUPONMANAGEMENT_MASTER,
          payload: { data: response.resource, count: response.meta.count },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        // callback({ status: 0 ,message: LocaleStrings.something_went_wrong});
      }
    );
  };
}

export function searchCouponMaster(session, search = "", pageCount = 0) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${itemCount}&offset=${offset}`;
  var searchQuery = `(title like %${search}%)`;
  var filter = encodeURI(`(${searchQuery})`);
  // var url = `${INSTANCE_URL}/api/v2/eventify/_table/sponsor?related=membershiptype_by_categoryid,appuser_by_sponsoridfk&include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;
  // var url = `${INSTANCE_URL}/api/v2/shopez/_table/discount_master?include_count=true&${offSetQuery}&order=${orderby}&fields=*&related=discountarea_by_iddiscount`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponmaster?include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}&related=couponrule_by_couponid`;
  return (dispatch) => {
    dispatch(fetchCouponMaster(session));
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("SPONSOR: ", data);
        dispatch({
          type: COUPONMANAGEMENT_MASTER,
          payload: { data: response.resource, count: response.meta.count },
        });
      },
      (error) => {}
    );
  };
}

/**** Is Open Coupons management modal ****/
export function isCouponManageModalopen(isOpen) {
  return { type: IS_COUPONMANAGEMENT_MODAL_OPEN, payload: isOpen };
}

/**** Select Coupons active / Inactive Tabs ****/
export function selectedCouponsTab(selected) {
  return { type: COUPONMANAGEMENT_SELECTED_TAB, payload: selected };
}

/**** Add & Edit ****/

/**** Add Coupon Rule *****/
export function addCouponRule(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponrule`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Add Coupon Master ****/

export function addCouponMaster(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponmaster`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource[0].id,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Update Coupon Rules ****/
export function updateCouponRule(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponrule`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Update Coupon Master *****/
export function updateCouponMaster(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponmaster`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Deactivate Coupon With Coupon Id ****/
export function deactivateCoupon(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponmaster`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Single Object For Coupons ****/
export function getSelectedCouponsObj(coupmasterObj = {}, session) {
  if (Object.keys(coupmasterObj).length > 0) {
    coupmasterObj["couponruleid"] = coupmasterObj.couponrule_by_couponid[0].id;
    coupmasterObj["categoryid"] =
      coupmasterObj.couponrule_by_couponid[0].categoryid;
    coupmasterObj["subcategoryid"] =
      coupmasterObj.couponrule_by_couponid[0].subcategoryid;
    coupmasterObj["productid"] =
      coupmasterObj.couponrule_by_couponid[0].productid;
    coupmasterObj["times"] = coupmasterObj.couponrule_by_couponid[0].times;
  }

  return { type: COUPONMANAGEMENT_MASTER_SINGLE, payload: coupmasterObj };
}

export function deleteCoupon(session, object, callback) {
  var filter = encodeURI(`filter=(id=${object.id})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponmaster?${filter}`;

  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

export function fetchProducts(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/products`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('responseresponse',response)
        dispatch({
          type: ALL_PRODUCTS,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        // callback({ status: 0 ,message: LocaleStrings.something_went_wrong});
      }
    );
  };
}
