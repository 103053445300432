import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../languages/index.js";
import { Fragment } from "react";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../common/base-component";
import { XIcon, TrashIcon } from "@heroicons/react/outline";
import { Field, FieldArray, reduxForm, reset, getFormValues } from "redux-form";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-phone-input-2/lib/material.css";
import _ from "lodash";
import Dropzone from "react-dropzone";
import { isEmpty, classNames } from "../../../../common/common-functions";
import ImageCropper from "../../categorymanagement/components/imagecropper";
import HomepageImageCropper from "./hompageimgcropper";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../common/constants";
import { uploadMediaLarge, updateHomePageHeader } from "../actions/index";

class HeaderModalhomepage extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      static_image_linkedto: "",
      banner_For: "",
      fileUrl: "",
      fileArrNew: {},
      subcatfileUrl: "",
      subcatfileArr: {},
      btnLoader: false,
    };

    // All Binded Functions
  }

  onSubmitForm(values) {
    console.log("values", values);
    this.setState({ btnLoader: true });
    let staticdata = {};
    staticdata.linktype = values.linktype;
    // staticdata.image = values.image;
    if (values.linktype == "product") {
      staticdata.value = values.productid;
    } else if (values.linktype == "category") {
      staticdata.value = values.categoryid;
    } else if (values.linktype == "subcategory") {
      staticdata.value = values.subcategoryid;
    } else if (values.linktype == "external") {
      staticdata.value = values.externallink;
    }

    let errorImage = 0;
    _.forEach(values.data, function (value, i) {
      // console.log('value',value)
      if (
        !value.hasOwnProperty("image") ||
        !value.hasOwnProperty("mobileimage")
      ) {
        errorImage++;
      } else {
        if (value.image == "" || value.mobileimage == "") {
          errorImage++;
        }
      }
    });
    // console.log('errorImage',errorImage)
    // console.log('errorImage',errorImage)
    if (errorImage > 0) {
      NotificationManager.error(
        "Please add Image for both web and mobile view"
      );
      this.setState({ btnLoader: false });
      return false;
    } else {
      let resource_coverimage = "";
      let uploaderCounter = 0;
      let filetobeupload = [];
      let arrPromise = [];
      //let { fileArrNew } = this.state;

      // console.log("---------", this.state.fileArrNew);
      if (Object.keys(this.state.fileArrNew).length > 0) {
        // Image Exist
        let fileArrNew = this.state.fileArrNew[0];

        let ts = new Date().getTime(); // Timestamp

        let medianame = "static" + ts + "." + "png"; // Image Name Creation
        resource_coverimage = medianame;

        let ext = fileArrNew.name.split(".").pop(); // File extension

        let coverJson = {
          // type: "cover",
          type: "image",
          name: medianame,
          media: fileArrNew.url,
        };
        filetobeupload.push(coverJson);
        let counter = filetobeupload.length;

        filetobeupload.map((item, index) => {
          arrPromise.push(
            new Promise((resolve, reject) => {
              this.props.uploadMediaLarge(
                this.props.session,
                item,
                (response) => {
                  if (response.success === 0) {
                    resolve();
                  } else {
                    uploaderCounter++;
                    resolve();
                  }
                }
              );
            })
          );
        });
        // console.log('medianame',medianame)
        let imageName = "homebanner/" + medianame;
        staticdata.image = imageName;

        let manualData = [];
        _.forEach(values.data, function (value, i) {
          let obj = {};
          if (value.linktype == "product") {
            obj.productid = value.productid.value;
            obj.linktype = value.linktype;
            obj.image = value.image;
            obj.mobileimage = value.mobileimage;
            obj.productname_en = value.productid.label;
            obj.productname_ar = value.productid.label;
          } else {
            obj = value;
          }
          manualData.push(obj);
        });

        values["data"] = manualData;

        let body = { ...values, image: imageName, staticdata };
        // console.log('body',body)
        Promise.all(arrPromise).then(() => {
          if (counter == uploaderCounter) {
            // After file upload
            this.props.updateHomePageHeader(
              this.props.session,
              body,
              (response) => {
                this.setState({ fileArr: {} });
                this.setState({ fileArrNew: {} });
                setTimeout(() => {
                  NotificationManager.success("Updated Successfully");
                  this.setState({ btnLoader: false });
                  this.props.onClose();
                }, 2000);
              }
            );
          }
        });
      } else {
        // Without Image

        let manualData = [];
        _.forEach(values.data, function (value, i) {
          let obj = {};
          if (value.linktype == "product") {
            obj.productid = value.id.value;
            obj.linktype = value.linktype;
            obj.image = value.image;
            obj.mobileimage = value.mobileimage;
            obj.productname_en = value.id.label;
            obj.productname_ar = value.id.label;
            delete value["id"];
          } else {
            delete value["id"];
            obj = value;
          }
          manualData.push(obj);
        });

        values["data"] = manualData;

        let body = { ...values };

        this.props.updateHomePageHeader(
          this.props.session,
          body,
          (response) => {
            this.setState({ fileArr: {} });
            this.setState({ fileArrNew: {} });
            setTimeout(() => {
              NotificationManager.success("Updated Successfully");
              this.setState({ btnLoader: false });
              this.props.onClose();
            }, 2000);
          }
        );
      }
    }
  }

  renderMobileImageUpload = ({
    fields,
    meta: { error, submitFailed },
    updateImage,
    updateImageChange,
    updateDynamicLinks,
  }) => (
    <ul>
      <li className="end-item" style={{ paddingTop: 20 }}>
        <button
          className=" end-item text-primaryLight cursor-pointer button"
          type="button"
          onClick={() => fields.push({})}>
          +Add Images
        </button>
        {submitFailed && error && <span>{error}</span>}
      </li>
      <div className="grid grid-cols-2">
        {fields.map((mobileheaderBanner, index) => {
          var currentInxedData = fields.get(index);
          var imagePreviewUrl = "";

          var linkdynamicImageToArr = [];
          var allCategoryListArr = [];
          var allsubCategoryListArr = [];
          var allProductlistArr = [];

          _.forEach(this.props.linkImageTo, function (value) {
            var obj = {
              name: value.name,
              value: value.value,
            };

            linkdynamicImageToArr.push(obj);
          });
          _.forEach(this.props.allcategoryList, function (value) {
            var obj = {
              name: value.categoryname_en,
              value: parseInt(value.id),
            };

            allCategoryListArr.push(obj);
          });
          _.forEach(this.props.allsubcategoryList, function (value) {
            var obj = {
              name: value.subcategoryname_en,
              value: parseInt(value.id),
            };

            allsubCategoryListArr.push(obj);
          });
          _.forEach(this.props.allproductList, function (value) {
            let name = "";
            if (value.sku !== null) {
              name = "(" + value.sku + ")" + value.productname_en;
            } else {
              name = value.productname_en;
            }

            var obj = {
              // name: value.productname_en,
              label: name,
              value: value.id.toString(),
              // name: value.productname_en,
              // value: value.id,
            };

            allProductlistArr.push(obj);
          });
          if (
            currentInxedData?.mobileimage &&
            currentInxedData?.mobileimage.search(";base64,") > 0
          ) {
            imagePreviewUrl = currentInxedData?.mobileimage;
          } else if (currentInxedData?.mobileimage == null) {
            imagePreviewUrl = "";
          } else {
            imagePreviewUrl = `${BASE_IMAGES_URL}/${currentInxedData?.mobileimage}?api_key=${APP_API_KEY}`;
          }

          let linkType = "";
          if (this.props.formValues && this.props.formValues.data.length > 0)
            var added = _.filter(this.props.formValues.data, (obj, i) => {
              return i == index;
            });
          if (added && added.length > 0) {
            linkType = added[0].linktype;
          }

          //  imagePreviewUrl = currentInxedData?.image;
          return (
            <li
              key={index}
              className="mx-2"
              style={{
                border: "1px solid gray",
                borderRadius: "15px",
                marginTop: "15px",
              }}>
              {index != 0 ? (
                <div className="end-item mb-2">
                  <button
                    type="button"
                    title="Remove Member"
                    className="end-item"
                    onClick={() => fields.remove(index)}>
                    <TrashIcon
                      className="h-6 w-6 text-red-500"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              ) : (
                ""
              )}

              <div className="grid grid-cols-2">
                <div>Upload Image</div>
                <div className="end-item">
                  <HomepageImageCropper
                    displaySize={{ width: 100, height: 100 }} // For image display style
                    requiredSize={{ width: 200, height: 200 }} // For image size required validation
                    cropperSize={{ width: 50, height: 50 }} // Cropper display size. Note its add 50px for padding
                    onImageSave={(img) => updateImage(index, img)}
                    onImageChange={(bool) => updateImageChange(index, bool)}
                    imagepath={imagePreviewUrl}
                    isdipalyText={false}
                    imageType="jpg"
                    className="drop-zone-area-custom-image"
                  />
                  <Field
                    name={`${mobileheaderBanner}.mobileimage`}
                    type="text"
                    component={this.renderHiddenFieldText}
                  />
                </div>
              </div>
              <Field
                name={`${mobileheaderBanner}.linktype`}
                label="Link to"
                placeholder={"Select"}
                component={this.renderSelect}
                mandatory="true"
                opts={linkdynamicImageToArr}
                className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md mb-5"
                classNameLabel="block text-box-lable"
                onChange={(e) => updateDynamicLinks(index, e)}
              />
              <span></span>

              {linkType == "category" ? (
                <Field
                  name={`${mobileheaderBanner}.categoryid`}
                  label="Category"
                  placeholder={"Select"}
                  component={this.renderSelect}
                  mandatory="true"
                  opts={allCategoryListArr}
                  className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                  classNameLabel="block text-box-lable"
                />
              ) : linkType == "external" ? (
                <Field
                  name={`${mobileheaderBanner}.externallink`}
                  label="External Link"
                  placeholder="External Link"
                  type="text"
                  mandatory="true"
                  component={this.renderAllfields}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              ) : linkType == "subcategory" ? (
                <Field
                  name={`${mobileheaderBanner}.subcategoryid`}
                  label="Subcategory"
                  placeholder={"Select"}
                  component={this.renderSelect}
                  mandatory="true"
                  opts={allsubCategoryListArr}
                  className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                  classNameLabel="block text-box-lable"
                />
              ) : linkType == "product" ? (
                <Field
                  name={`${mobileheaderBanner}.id`}
                  label="Product"
                  placeholder={"Select"}
                  component={this.renderSelect}
                  mandatory="true"
                  opts={allProductlistArr}
                  className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                  classNameLabel="block text-box-lable"
                />
              ) : (
                ""
              )}
            </li>
          );
        })}
      </div>
    </ul>
  );

  onProfileImageSave = (index, img) => {
    this.props.autofill(`data[${index}].mobileimage`, img == "" ? "" : img);
  };
  onDynamicLinksChange = (index, e) => {};

  onProfileImageChange = (index, bool) => {};
  // web

  renderWebImageUpload = ({
    fields,
    meta: { error, submitFailed },
    updateImageweb,
    updateImageChangeweb,
    updateDynamicLinksweb,
    updateImage,
    updateImageChange,
    updateDynamicLinks,
  }) => (
    <ul>
      <li className="end-item" style={{ paddingTop: 20 }}>
        <button
          className=" end-item text-primaryLight cursor-pointer button"
          onClick={() => fields.push({})}>
          +Add Images
        </button>
        {submitFailed && error && <span>{error}</span>}
      </li>
      <div className="grid grid-cols-1">
        {fields.map((webheaderBanner, index) => {
          var currentInxedData = fields.get(index);
          var imagePreviewUrl = "";
          var imagePreviewUrlMob = "";
          // if (
          //   currentInxedData?.image &&
          //   currentInxedData?.image.search(";base64,") > 0
          // ) {
          //   imagePreviewUrl = currentInxedData?.image;
          // }

          var linkdynamicImageToArr = [];
          var allCategoryListArr = [];
          var allsubCategoryListArr = [];
          var allProductlistArr = [];

          _.forEach(this.props.linkImageTo, function (value) {
            var obj = {
              name: value.name,
              value: value.value,
            };

            linkdynamicImageToArr.push(obj);
          });
          _.forEach(this.props.allcategoryList, function (value) {
            var obj = {
              name: value.categoryname_en,
              value: value.id,
            };

            allCategoryListArr.push(obj);
          });
          _.forEach(this.props.allsubcategoryList, function (value) {
            var obj = {
              name: value.subcategoryname_en,
              value: value.id,
            };

            allsubCategoryListArr.push(obj);
          });
          _.forEach(this.props.allproductList, function (value) {
            let name = "";
            if (value.sku !== null) {
              name = "(" + value.sku + ")" + value.productname_en;
            } else {
              name = value.productname_en;
            }

            var obj = {
              // name: value.productname_en,
              label: name,
              value: value.id.toString(),
              // name: value.productname_en,
              // value: value.id,
            };

            allProductlistArr.push(obj);
          });
          // Image web
          if (
            currentInxedData?.image &&
            currentInxedData?.image.search(";base64,") > 0
          ) {
            imagePreviewUrl = currentInxedData?.image;
          } else if (currentInxedData?.image == null) {
            imagePreviewUrl = "";
          } else {
            imagePreviewUrl = `${BASE_IMAGES_URL}/${currentInxedData?.image}?api_key=${APP_API_KEY}`;
          }
          // Image Mobile
          if (
            currentInxedData?.mobileimage &&
            currentInxedData?.mobileimage.search(";base64,") > 0
          ) {
            imagePreviewUrlMob = currentInxedData?.mobileimage;
          } else if (currentInxedData?.mobileimage == null) {
            imagePreviewUrlMob = "";
          } else {
            imagePreviewUrlMob = `${BASE_IMAGES_URL}/${currentInxedData?.mobileimage}?api_key=${APP_API_KEY}`;
          }

          //  imagePreviewUrl = currentInxedData?.image;
          let linkType = "";
          if (this.props.formValues && this.props.formValues.data.length > 0)
            var added = _.filter(this.props.formValues.data, (obj, i) => {
              return i == index;
            });
          if (added && added.length > 0) {
            linkType = added[0].linktype;
          }

          return (
            <li
              key={index}
              className="mx-2"
              style={{
                border: "1px solid gray",
                borderRadius: "15px",
                marginTop: "15px",
              }}>
              {index != 0 ? (
                <div className="end-item mb-2">
                  <button
                    type="button"
                    title="Remove Member"
                    className="end-item"
                    onClick={() => fields.remove(index)}>
                    <TrashIcon
                      className="h-6 w-6 text-red-500"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="flex-">
                <div className="grid grid-cols-1">
                  <div>
                    Upload Mobile Image (Recommended Size Minimum 350px wide and
                    150px tall)
                  </div>
                  <div className="mt-4 ml-2">
                    <HomepageImageCropper
                      // displaySize={{ width: 200, height: 200 }} // For image display style
                      // requiredSize={{ width: 300, height: 300 }} // For image size required validation
                      // cropperSize={{ width: 300, height: 300 }} // Cropper display size. Note its add 50px for padding
                      displaySize={{ width: 350, height: 150 }} // For image display style
                      requiredSize={{ width: 350, height: 150 }} // For image size required validation
                      cropperSize={{ width: 350, height: 150 }} //
                      onImageSave={(img) => updateImage(index, img)}
                      onImageChange={(bool) => updateImageChange(index, bool)}
                      imagepath={imagePreviewUrlMob}
                      isdipalyText={false}
                      imageType="jpg"
                      className="drop-zone-area-custom-image"
                    />
                    <Field
                      name={`${webheaderBanner}.mobileimage`}
                      type="text"
                      component={this.renderHiddenFieldText}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 ml-auto">
                  <div className="mt-4">
                    Upload Web Image (Recommended Size Minimum 1000px wide and
                    300px tall)
                  </div>
                  <div className="mt-4">
                    <HomepageImageCropper
                      displaySize={{ width: 1000, height: 300 }} // For image display style
                      requiredSize={{ width: 1000, height: 300 }} // For image size required validation
                      cropperSize={{ width: 1000, height: 300 }} // Cropper display size. Note its add 50px for padding
                      onImageSave={(img) => updateImageweb(index, img)}
                      onImageChange={(bool) =>
                        updateImageChangeweb(index, bool)
                      }
                      imagepath={imagePreviewUrl}
                      isdipalyText={false}
                      imageType="jpg"
                      className="drop-zone-area-custom-image"
                    />
                    <Field
                      name={`${webheaderBanner}.image`}
                      type="text"
                      component={this.renderHiddenFieldText}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <Field
                  name={`${webheaderBanner}.linktype`}
                  label="Link to"
                  placeholder={"Select"}
                  component={this.renderSelect}
                  mandatory="true"
                  opts={linkdynamicImageToArr}
                  className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md mb-5"
                  classNameLabel="block text-box-lable"
                  onChange={(e) => updateDynamicLinksweb(index, e)}
                />
              </div>
              {linkType == "product" ? (
                <Field
                  name={`${webheaderBanner}.id`}
                  label="Product"
                  placeholder={"Select"}
                  options={allProductlistArr}
                  component={this.renderAutoComplete}
                  labelposition={LABEL_POSITION_TOP}
                  // labelKey="label"
                  // valueKey="value"
                  className="mt-1 block w-full mb-4 pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                  classNameLabel="block text-box-lable"
                  isMulti={false}
                />
              ) : linkType == "external" ? (
                <Field
                  name={`${webheaderBanner}.externallink`}
                  label="External Link"
                  placeholder="External Link"
                  type="text"
                  mandatory="true"
                  component={this.renderAllfields}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              ) : linkType == "subcategory" ? (
                <Field
                  name={`${webheaderBanner}.subcategoryid`}
                  label="Subcategory"
                  placeholder={"Select"}
                  component={this.renderSelect}
                  mandatory="true"
                  opts={allsubCategoryListArr}
                  className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                  classNameLabel="block text-box-lable"
                />
              ) : linkType == "category" ? (
                <Field
                  name={`${webheaderBanner}.categoryid`}
                  label="Category"
                  placeholder={"Select"}
                  component={this.renderSelect}
                  mandatory="true"
                  opts={allCategoryListArr}
                  className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                  classNameLabel="block text-box-lable"
                />
              ) : (
                ""
              )}
            </li>
          );
        })}
      </div>
    </ul>
  );

  onProfileImageSaveWeb = (index, img) => {
    this.props.autofill(`data[${index}].image`, img == "" ? "" : img);
  };
  onDynamicLinksChangeweb = (index, e) => {};
  onProfileImageChangeweb = (index, bool) => {};

  onDropFiles = async (files) => {
    // console.log('ondrop',files)
    let file = files[0];
    let filearr = [];

    // console.log("file", file);

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e) => {
      file.url = e.target.result;
      // file.name   = file.name;
      // file.url = reader.result;
      filearr.push(file);
    };
    this.setState({ fileArrNew: filearr });
    // console.log('fileData',fileData);
    // console.log('URL.createObjectURL(fileData)',URL.createObjectURL(fileData));
    this.setState({ fileUrl: URL.createObjectURL(file) });
  };

  handleViewChange(e) {
    if (e.target.value === "mobile") {
      this.setState({ banner_For: "mobile" });
    } else {
      this.setState({ banner_For: "web" });
    }
  }

  _handlestaticImageLinked(e) {
    this.setState({ static_image_linkedto: e.target.value });
  }

  render() {
    // console.log('initialValues',this.props.initialValues);
    var { handleSubmit, initialValues } = this.props;

    let { fileUrl, enar, static_image_linkedto, banner_For } = this.state;
    if (initialValues && initialValues.hasOwnProperty("headerType")) {
      //EDIT
      banner_For = banner_For === "" ? initialValues.headerType : banner_For;
      static_image_linkedto =
        static_image_linkedto === ""
          ? initialValues.linktype
          : static_image_linkedto;
    }
    // banner_For =
    // banner_For === "" ? initialValues.headerType  ? : discounttype;
    let coverimage = "";
    if (isEmpty(fileUrl) && initialValues) {
      coverimage = `${BASE_IMAGES_URL}/${initialValues.image}?api_key=${APP_API_KEY}`;
      //coverimage = `${BASE_IMAGES_URL}/store/${initialValues.coverimage}?api_key=${APP_API_KEY}`;
    } else {
      coverimage = fileUrl;
    }

    var linkstaticImageToArr = [];
    var allCategoryListArr = [];
    var allsubCategoryListArr = [];
    var allProductlistArr = [];

    _.forEach(this.props.linkImageTo, function (value) {
      var obj = {
        name: value.name,
        value: value.value,
      };

      linkstaticImageToArr.push(obj);
    });
    _.forEach(this.props.allcategoryList, function (value) {
      var obj = {
        name: value.categoryname_en,
        value: value.id,
      };

      allCategoryListArr.push(obj);
    });
    _.forEach(this.props.allsubcategoryList, function (value) {
      var obj = {
        name: value.subcategoryname_en,
        value: value.id,
      };

      allsubCategoryListArr.push(obj);
    });
    _.forEach(this.props.allproductList, function (value) {
      var obj = {
        name: value.productname_en,
        value: value.id,
      };

      allProductlistArr.push(obj);
    });
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.myRef}
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 bigmodal">
                  <div className="grid grid-cols-3 border-b-2 border-gray-50 pb-4">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center">
                      Edit HomePage Header
                    </div>
                    <div className="flex flex-wrap content-center justify-end">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}
                        ref={this.myRef}>
                        <span className="sr-only"></span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className="space-y-8 divide-y divide-gray-200 mt-5"
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                    encType="multipart/form-data">
                    <div className="lg:grid lg:grid-cols-12 px-6">
                      <div className="lg:col-span-8 rounded pb-4 ">
                        Dynamic Images
                      </div>
                      <div className="lg:col-span-4 rounded pl-4 pb-4">
                        Static Image
                      </div>
                      <div className="lg:col-span-8 rounded p-6 bg-gray-100">
                        <div className="grid grid-cols-2">
                          <div>Upload Image</div>
                          <div className="end-item">
                            {_.map(this.props.headerView, (opt, index) => {
                              //  console.log(opt)
                              return (
                                <Field
                                  name={`headerType`}
                                  label={opt.name}
                                  value={opt.value}
                                  //checked={index == 0 ? true : false}
                                  onChange={this.handleViewChange.bind(this)}
                                  lebelAlign="R"
                                  type="radio"
                                  component={this.renderFieldRadio1}
                                />
                              );
                            })}
                          </div>
                        </div>
                        {/* {banner_For == 'mobile' ? (
                          // <span>2</span>
                          <FieldArray
                            name="data"
                            component={this.renderMobileImageUpload}
                            updateImage={this.onProfileImageSave}
                            updateImageChange={this.onProfileImageChange}
                            updateDynamicLinks={this.onDynamicLinksChange}

                          />
                        ) : banner_For == 'web' ? ( */}
                        <FieldArray
                          name="data"
                          component={this.renderWebImageUpload}
                          updateImageweb={this.onProfileImageSaveWeb}
                          updateImageChangeweb={this.onProfileImageChangeweb}
                          updateDynamicLinksweb={this.onDynamicLinksChangeweb}
                          updateImage={this.onProfileImageSave}
                          updateImageChange={this.onProfileImageChange}
                          updateDynamicLinks={this.onDynamicLinksChange}
                        />
                        {/* ) : ('')} */}
                      </div>
                      <div
                        className="lg:col-span-4 bg-gray-100"
                        style={{
                          padding: 20,
                          marginLeft: "20px",
                        }}>
                        <div class="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                          <div className="dropzonecover" key={`drop`}>
                            <div className="img_wrpcover relative">
                              <img src={coverimage} className="dropimgcover" />
                            </div>

                            <Dropzone
                              onDrop={(e) => this.onDropFiles(e)}
                              accept="image/jpeg, image/png ,image.jpg"
                              multiple={false}>
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  {...getRootProps()}
                                  className="h-full w-full absolute top-0">
                                  <input {...getInputProps()} />
                                  <p className="paragraph-seconday text-xs pt-10">
                                    Upload Static Image for Web
                                  </p>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 mt-5">
                          <div className="mr-2">
                            <Field
                              name="linktype"
                              label="Link to"
                              placeholder={"Select"}
                              component={this.renderSelect}
                              mandatory="true"
                              opts={linkstaticImageToArr}
                              className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                              classNameLabel="block text-box-lable"
                              onChange={this._handlestaticImageLinked.bind(
                                this
                              )}
                            />
                          </div>
                          <div>
                            {static_image_linkedto == "external" ? (
                              <Field
                                name="externallink"
                                label="External Link"
                                placeholder="External Link"
                                type="text"
                                mandatory="true"
                                component={this.renderAllfields}
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                              />
                            ) : static_image_linkedto == "category" ? (
                              <Field
                                name="categoryid"
                                label="Category"
                                placeholder={"Select"}
                                component={this.renderSelect}
                                mandatory="true"
                                opts={allCategoryListArr}
                                className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                                classNameLabel="block text-box-lable"
                              />
                            ) : static_image_linkedto == "subcategory" ? (
                              <Field
                                name="subcategoryid"
                                label="Subcategory"
                                placeholder={"Select"}
                                component={this.renderSelect}
                                mandatory="true"
                                opts={allsubCategoryListArr}
                                className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                                classNameLabel="block text-box-lable"
                              />
                            ) : static_image_linkedto == "product" ? (
                              <Field
                                name="productid"
                                label="Product"
                                placeholder={"Select"}
                                component={this.renderSelect}
                                mandatory="true"
                                opts={allProductlistArr}
                                className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                                classNameLabel="block text-box-lable"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-5">
                      <div className="flex justify-end">
                        <div className="tooltip mt-2">
                          <img
                            loading="lazy"
                            src="../images/eventinfo.svg"
                            class="cc-sidebar-logo"
                          />
                          <span className="tooltiptextleft">
                            Save main homepage to see preview
                          </span>
                        </div>
                        <button
                          type="submit"
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "sr-only",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          Save Header
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <NotificationContainer />
      </>
    );
  }
}
function validate(values) {
  // console.log('values',values)
  // var errors = {};
  var errors = { data: [] };
  var requiredFields = ["bannertype"];

  var data = values["data"];

  data?.forEach((v, i) => {
    errors.data.push({});
    if (!v?.linktype || v?.linktype.trim() === "") {
      errors.data[i].linktype = "Required";
    }
    if (!v?.linktype || v?.linktype.trim() === "external") {
      // errors.data[i].externallink =
      //   "Required";
      if (!v?.externallink || v?.externallink === "") {
        errors.data[i].externallink = "Required";
      }
    } else if (!v?.linktype || v?.linktype.trim() === "product") {
      // errors.data[i].productid =
      // "Required";
      if (!v?.productid || v?.productid === "") {
        errors.data[i].productid = "Required";
      }
    } else if (!v?.linktype || v?.linktype.trim() === "category") {
      // errors.data[i].categoryid =
      // "Required";
      if (!v?.categoryid || v?.categoryid === "") {
        errors.data[i].categoryid = "Required";
      }
    } else if (!v?.linktype || v?.linktype.trim() === "subcategory") {
      // errors.data[i].subcategoryid =
      // "Required";
      if (!v?.subcategoryid || v?.subcategoryid === "") {
        errors.data[i].subcategoryid = "Required";
      }
    }
  });

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
  });
  // console.log("errors :- ", errors);
  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  let initvals = {};
  if (state.headerDataupdate) {
    initvals = state.headerDataupdate;
    delete initvals["staticdata"];
  }
  // console.log('headerDataupdate', state.headerDataupdate);
  return {
    session,
    user,
    linkImageTo: state.linkImageTo,
    headerView: state.headerView,
    allcategoryList: state.allcategoryList ? state.allcategoryList.data : [],
    allsubcategoryList: state.allsubcategoryList
      ? state.allsubcategoryList.data
      : [],
    allproductList: state.allproductList ? state.allproductList.data : [],
    // initialValues: state.homePageMainheader.data,
    formValues: getFormValues("HeaderSectionForm")(state),
    initialValues: initvals,
  };
}

export default connect(mapStateToProps, {
  updateHomePageHeader,
  uploadMediaLarge,
})(
  reduxForm({
    validate,
    form: "HeaderSectionForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(HeaderModalhomepage)
);
