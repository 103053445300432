import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import _ from "lodash";
import {
  fetchVariations,
  fetchProductCategory,
  fetchProductSubcategory,
  fetchvariationsallCategory,
} from "../actions/index";
import Variationlisting from "./variationlist-items";
import Pagination from "react-js-pagination";
import { NotificationContainer } from "react-notifications";
import BaseLoader from "../../../../common/base-loader";
import NewVariations from "./addvariations";
import { classNames } from "../../../../common/common-functions";

const limit = 10;

class MasterVariations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveredorderSearch: "",
      loaderDesc: "Variations",
      loading: true,
      pageNumber: 1,
      search: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation.data, {
      href: answer,
    });

    if (singleNavigationRow == undefined) {
      this.props.history.push("/login");
    }

    this.props.fetchVariations(
      this.props.session,
      limit,
      this.state.pageNumber,
      "listing",
      "",
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
    // this.props.fetchProductCategory(
    //   this.props.session,
    //   (CallBackResponse) => {}
    // );
    // this.props.fetchProductSubcategory(
    //   this.props.session,
    //   (CallBackResponse) => {}
    // );
    // this.props.fetchProductSubcategory(
    //   this.props.session,
    //   (CallBackResponse) => {}
    // );
    this.props.fetchvariationsallCategory(
      this.props.session,
      (CallBackResponse) => {}
    );
  }

  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchVariations(
        this.props.session,
        limit,
        pageNumber,
        "listing",
        "",
        (CallBackResponse) => {}
      );
    } else {
      this.props.fetchVariations(
        this.props.session,
        limit,
        pageNumber,
        "search",
        search,
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    }
    this.setState({ search, pageNumber });
  };

  paginationCallback = (pageNumber) => {
    this.props.fetchVariations(
      this.props.session,
      limit,
      pageNumber,
      "listing",
      "",
      (response) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  rendervariationList() {
    let pageDataLength = this.props.allVariation.data.length;

    let { pageNumber } = this.state;

    var items = _.map(this.props.allVariation.data, (values, index) => (
      <Variationlisting
        key={index}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    return items;
  }

  render() {
    let { language } = this.props;
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {/* <SpecificationopenModal/> */}
            <div
              className="grid gap-4 grid-rows-1 px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 "
              style={{
                overflowX: "hidden",
                overflowY: "hidden",
                display: "block",
              }}>
              <div className="text-2xl font-semibold text-secondary">
                {LocaleStrings.master_vari_heading}
              </div>
              <NewVariations />
              <div
                className="relative md:w-6/12 xs:w-full text-ternary focus-within:text-secondary smallscreen-search"
                style={{ marginTop: -35 }}>
                <div
                  className={classNames(
                    language == "en" ? "left-0 " : "right-0",
                    "absolute inset-y-0 flex items-center pointer-events-none px-1"
                  )}>
                  <SearchIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className={classNames(
                    language == "en" ? "pl-8 pr-3" : "pr-8 pl-3",
                    "block lg:w-6/12 xs:w-full sm:w-full h-full  py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                  )}
                  placeholder={LocaleStrings.search}
                  type="search"
                  name="search"
                  // onChange={(e) =>
                  //   this.setState({ customerSearchText: e.target.value })
                  // }
                  value={this.state.search}
                  onChange={this.handleSearch}
                />
              </div>
            </div>
            {_.isEmpty(this.props.allVariation.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.master_vari_noData}
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col pt-10 pb-2 px-6">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.master_vari_th_Name} EN
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.master_vari_th_Name} AR
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.master_vari_th_CreatedOn}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.action}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.rendervariationList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <Pagination
                  activePage={this.state.pageNumber}
                  itemsCountPerPage={10}
                  totalItemsCount={this.props.allVariation.count}
                  pageRangeDisplayed={10}
                  onChange={this.paginationCallback}
                />
              </>
            )}
          </>
        )}
        {/* <Addspecificationmodal /> */}
        <NotificationContainer />
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, language } = state;
  // console.log('state.specificationlisting',state.specificationlisting)
  return {
    session,
    user,
    language,
    allVariation: state.allVariation,
    navigation: state.sideBarMenus,
  };
}

export default connect(mapStateToProps, {
  fetchVariations,
  fetchProductCategory,
  fetchProductSubcategory,
  fetchvariationsallCategory,
})(MasterVariations);
