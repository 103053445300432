import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import "react-notifications/lib/notifications.css";
import { reduxForm } from "redux-form";
import { BaseComponent } from "../../../../../common/base-component";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../../common/constants";

class CategoryorderItem extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      selection_type: "",
      dragablebox: [],
      isOpen: false,
      setOpen: false,
      isBannerOpen: false,
      update: false,
    };

    // All Binded Functions
  }

  componentDidMount() {}

  render() {
    let { list } = this.props;
    // console.log('list',list)
    var img = `${BASE_IMAGES_URL}/vendor/productcategories/${list.image}?api_key=${APP_API_KEY}`;
    let grayColor = "#f9f9f9";

    let productboxStyle = {
      background: `linear-gradient(180deg, ${grayColor} 0%, #FFFFFF 100%)`,
      // cursor:'grab'
    };

    return (
      <>
        <div
          className="grid grid-cols-1 border-1 border-ternary  p-6 rounded mt-5 cursor-grab "
          style={productboxStyle}>
          <div className="flex">
            <div>{list.categoryname_en}</div>
            <div style={{ marginLeft: "auto" }}>
              <img src={img} style={{ height: "40px", width: "40px" }} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log("  allAddListing:state.allAddListing", state.allAddListing);
  return {
    session,
    user,
  };
}

// export default connect(mapStateToProps, {})(HomePageDesign);
export default connect(
  mapStateToProps,
  {}
)(
  reduxForm({
    form: "CategoryorderItemForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(CategoryorderItem)
);
