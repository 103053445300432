import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index";
import { BaseComponent } from "../../../../common/base-component";
import { reduxForm } from "redux-form";
import _ from "lodash";
import { fetchNotificationlist } from "../actions/index";
import NotificationListitem from "./notificationList-items";

class NotificationList extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loaderDesc: "Notifications",
    };

    // All Binded Functions
  }
  componentDidMount() {}

  rendernotificationList() {
    var items = _.map(this.props.notificationList.data, (values) => (
      <NotificationListitem key={values.userid} values={values} />
    ));
    return items;
  }

  render() {
    return (
      <>
        {/* <div className="relative w-6/12 text-ternary focus-within:text-secondary ">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
            <SearchIcon className="h-5 w-5" aria-hidden="true" />
          </div>
          <input
            id="search-field"
            className="block lg:w-6/12 sm:w-full h-full pl-8 pr-3 py-2 border-2 border-gray-200 text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
            placeholder={LocaleStrings.search}
            type="search"
            name="search"

            //  value={this.state.search}
            // onChange={this.handleSearch}
          />
        </div> */}

        <div className="panel-body mt-6">
          {/* <TableContent /> */}
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-custWhite">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {LocaleStrings.sentnotification}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {LocaleStrings.date}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {LocaleStrings.sentto}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {LocaleStrings.action}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.rendernotificationList()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('notificationList',state.notificationList);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    data: ownProps.data,
    notificationList: state.notificationList,
  };
}

export default connect(mapStateToProps, {
  fetchNotificationlist,
})(
  reduxForm({
    // validate,
    form: "",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(NotificationList)
);
