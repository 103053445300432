import React, { Component } from "react";
import RichTextEditor from "../common/RichTextEditor";
import RichTextTinymceEditor from "../common/RichTextTinymceEditor";
import Select from "react-select";
// import Button from "react-bootstrap-button-loader";
import DatePicker from "react-datepicker";
import moment from "moment";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { DATE_FORMAT, DATE_FORMAT_SM, DISPLAY_DATE_FORMAT } from "./constants";
import LocaleStrings from "../components/languages/index";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import PhoneInput from "react-phone-input-2";
import { format, addDays, parse } from "date-fns";
import { classNames } from "./common-functions";

export var trimValue = (value) => value && value.trim();
export var notOnlySpecialChar = (value) =>
  value && !/^[a-zA-Z0-9- ]*$/.test(value)
    ? "Name can not contain special characters"
    : undefined;
export var LABEL_POSITION_TOP = "TOP";
export var LABEL_POSITION_LEFT = "LEFT";

// ======================== redux-form validate props variables ========================
export const required = (value) => (value ? undefined : "Required");
export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
// const maxLength15 = maxLength(15)
export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
// export const minLength2 = minLength(2)
export const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;
export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;
export const maxValue = (max) => (value) =>
  value && value > max ? `Must be at most ${max}` : undefined;
// export const minValue13 = minValue(13)
export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
export const tooYoung = (value) =>
  value && value < 13
    ? "You do not meet the minimum age requirement!"
    : undefined;
export const aol = (value) =>
  value && /.+@aol\.com/.test(value)
    ? "Really? You still use AOL for your email?"
    : undefined;
export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? "Only alphanumeric characters"
    : undefined;
export const noSpecialChar = (value) =>
  value && !/^[a-zA-Z0-9 ]*$/.test(value)
    ? "Can not contain special characters"
    : undefined;
export const phoneNumber = (value) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? "Invalid phone number, must be 10 digits"
    : undefined;

// ======================== redux-form normalize props variables ========================
export var trim = (value) => value && value.trim();
export const upper = (value) => value && value.toUpperCase();
export const lower = (value) => value && value.toLowerCase();
export const lessThan = (otherField) => (value, previousValue, allValues) =>
  value < allValues[otherField] ? value : previousValue;
export const greaterThan = (otherField) => (value, previousValue, allValues) =>
  value > allValues[otherField] ? value : previousValue;

// *********** Tried trim formatter for 'redux-form' format props but didn't work (dated: Sep 25, 2020) *********** //
// ======================== redux-form format props variables ========================
export var trimFormat = (value) => {
  if (!value) return "";
  return value.trim();
};

// export var trimFormat = value => {return value.trim()}
// export var trimFormat = value => value && value.trim()
// ************************************************************************************************************** //

export class BaseComponent extends Component {
  renderStartDatePicker(field) {
    //debugger;
    var { input, selected, startDate, endDate, minDate, disabled } = field;
    var value = input.value ? moment(input.value) : selected;
    return (
      <DatePicker
        selectsStart
        selected={value}
        className="form-control"
        dateFormat="YYYY-MM-DD"
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        disabled={disabled}
        onChange={input.onChange}
        name={input.name}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
    );
  }

  renderEndDatePicker(field) {
    var { input, selected, startDate, endDate, minDate, maxDate, disabled } =
      field;
    var value = input.value ? moment(input.value) : selected;
    return (
      <DatePicker
        selectsEnd
        selected={value}
        className="form-control"
        dateFormat="YYYY-MM-DD"
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        onChange={input.onChange}
        name={input.name}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
    );
  }

  renderDatePickerWithLabel(field) {
    //debugger;
    var { input, selected, disabled } = field;

    var value = input.value ? moment(input.value) : null;
    var selectedValue = value ? value : selected;

    var divClassName = `form-group row ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass}`;
    var labelClasses = "control-label-top col-sm-12";
    var inputClasses = "col-sm-12 col-sm-offset-0";

    return (
      <div className={divClassName}>
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className={inputClasses}>
          <DatePicker
            {...input}
            value={value}
            selected={selectedValue}
            className="form-control"
            dateFormat={DATE_FORMAT}
            disabled={disabled}
            name={input.name}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
          <div className="text-help label-text-help">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderFieldText(field) {
    var labelposition = field.labelposition;
    var formdiv = "";
    if (field.formdiv !== undefined && field.formdiv === "false") {
      formdiv = "";
    }
    var divClassName = `form-group row ${formdiv} ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }
    return (
      <div className={divClassName}>
        {field.label !== "" ? (
          <label className={labelClasses}>
            {field.label}
            <span className="text-primary">
              {field.mandatory === "true" ? "*" : ""}
            </span>
          </label>
        ) : null}
        <div className={inputClasses}>
          {field.type === "password" ? (
            <input
              className="form-control"
              disabled
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              autoComplete="new-password"
            />
          ) : (
            <input
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
            />
          )}
          <div className="text-help label-text-help">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderHiddenFieldText(field) {
    var divClassName = `row ${field.meta.error ? "has-danger" : ""}`;
    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var inputClasses = "col-sm-12 col-sm-offset-0";

    return (
      <div className={divClassName}>
        <div className={inputClasses} style={{ display: "none" }}>
          <input
            className="form-control"
            type={field.type}
            placeholder={field.placeholder}
            {...field.input}
          />
        </div>
        <div className="col-sm-12 text-help label-text-help">
          {field.meta.error ? field.meta.error : ""}
        </div>
      </div>
    );
  }

  renderFieldTextarea(field) {
    var labelposition = field.labelposition;
    var divClassName = `form-group row ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass}`;

    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }
    return (
      <div className={divClassName}>
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className={inputClasses}>
          <textarea
            className={field.className}
            placeholder={field.placeholder}
            {...field.input}
            maxLength={field.maxlength ? field.maxlength : ""}></textarea>
          <div className="text-help label-text-help">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderCheckbox(field) {
    let { label, opts, input, meta } = field;
    var divClassName = `form-group row ${
      meta.touched && meta.error ? "has-danger" : ""
    }`;

    return (
      <div className={divClassName}>
        <label className="control-label-top col-sm-12">
          {label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className="col-sm-12 col-sm-offset-0">
          {opts.map((option, index) => {
            return (
              <div key={index}>
                <label
                  className="custom-container-checkbox"
                  style={{ marginBottom: 5 }}>
                  {option.text}
                  <input
                    type="checkbox"
                    name={`${input.name}[${index}]`}
                    value={option.value}
                    checked={input.value.indexOf(option.value) !== -1}
                    onChange={(event) => {
                      const newValue = [...input.value];
                      if (event.target.checked) {
                        newValue.push(option.value);
                      } else {
                        newValue.splice(newValue.indexOf(option.value), 1);
                      }
                      return input.onChange(newValue);
                    }}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            );
          })}
          <div className="text-help label-text-help">
            {meta.touched ? meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderReactMultiSelectCheckboxesWithManageButton(field) {
    var { opts, input, placeholder, labelposition, meta } = field;
    var defaultVal = input.value;
    var values = [];
    var selected = [];
    var defaultLabel = { placeholderButtonLabel: placeholder, value: [] };

    if (defaultVal && defaultVal != "") {
      if (Array.isArray(defaultVal)) {
        values = defaultVal;
      } else {
        values = defaultVal.toString().split(",");
      }

      values.forEach((item) => {
        var added = _.filter(opts, (obj) => {
          return obj.value == item;
        });
        selected.push(added[0]);
      });

      defaultLabel.value = selected;
    }

    var divClassName = `form-group row ${
      meta.invalid && meta.error ? "has-danger" : ""
    }`;
    var labelClasses = "control-label col-sm-3";
    var inputClasses = "col-sm-5 col-sm-offset-0";
    var buttonClasses = "col-sm-4 col-sm-offset-0 text-right";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = "control-label-top col-sm-12 m-0 mb-5";
      inputClasses = "col-sm-8 col-sm-offset-0 pr-0";
      buttonClasses = "col-sm-4 col-sm-offset-0 text-right";
    }

    return (
      <div className={divClassName}>
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className={inputClasses}>
          <ReactMultiSelectCheckboxes
            {...input.value}
            value={selected}
            onChange={(opts) => {
              return input.onChange(opts.map((p) => p.value));
            }}
            placeholderButtonLabel={placeholder}
            getDropdownButtonLabel={(defaultLabel) => {
              return Array.isArray(defaultLabel.value) &&
                defaultLabel.value.length > 0
                ? `${defaultLabel.value.length} Selected`
                : placeholder;
            }}
            options={opts}
            className="react-multi-select-checkboxes-custom-css"
          />
          <div className="text-help label-text-help">
            {meta.invalid && meta.error ? meta.error : ""}
          </div>
        </div>
        <div className={`${buttonClasses}`}>
          <button
            className="btn btn-custom-blue"
            bsStyle=""
            onClick={field.onManageClick}>
            {LocaleStrings.button_manage_category}
          </button>
        </div>
      </div>
    );
  }

  renderReactMultiSelectCheckboxes(field) {
    var { opts, input, placeholder, labelposition, meta } = field;
    var defaultVal = input.value;
    var values = [];
    var selected = [];
    var defaultLabel = { placeholderButtonLabel: placeholder, value: [] };

    if (defaultVal && defaultVal != "") {
      if (Array.isArray(defaultVal)) {
        values = defaultVal;
      } else {
        values = defaultVal.toString().split(",");
      }

      values.forEach((item) => {
        var added = _.filter(opts, (obj) => {
          return obj.value == item;
        });
        selected.push(added[0]);
      });

      defaultLabel.value = selected;
    }

    var divClassName = `form-group row ${
      meta.invalid && meta.error ? "has-danger" : ""
    }`;
    var labelClasses = "control-label col-sm-3";
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = "control-label-top col-sm-12";
      inputClasses = "col-sm-12 col-sm-offset-0";
    }

    return (
      <div className={divClassName}>
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className={inputClasses}>
          <ReactMultiSelectCheckboxes
            {...input.value}
            value={selected}
            onChange={(opts) => {
              return input.onChange(opts.map((p) => p.value));
            }}
            placeholderButtonLabel={placeholder}
            getDropdownButtonLabel={(defaultLabel) => {
              return Array.isArray(defaultLabel.value) &&
                defaultLabel.value.length > 0
                ? `${defaultLabel.value.length} Selected`
                : placeholder;
            }}
            options={opts}
            className="react-multi-select-checkboxes-custom-css"
          />
          <div className="text-help label-text-help">
            {meta.invalid && meta.error ? meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderSelect(field) {
    var isdisabled = field.isdisabled ? "disabled" : "";

    var divClassName = ` ${
      field.meta.touched && field.meta.error ? "text-danger text-xs" : ""
    }`;

    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (field.labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }
    return (
      <div>
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className={inputClasses}>
          <select
            className={field.className}
            disabled={isdisabled}
            {...field.input}>
            <option value="" key={field.label}>
              {field.placeholder}
            </option>
            {field.opts.map((object, index) => {
              return (
                <option key={`${object.value}-${index}`} value={object.value}>
                  {object.name ? object.name : object.text}
                </option>
              );
            })}
          </select>
          <div className={divClassName}>
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderSelectWithDelete(field) {
    var divClassName = `form-group row ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    return (
      <div className={divClassName}>
        <label className="control-label-top col-sm-12 mt-0">
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className="col-sm-12 col-sm-offset-0 d-flex">
          <select className="form-control" {...field.input}>
            <option value="" key={field.label}>
              {field.placeholder}
            </option>
            {field.opts.map((object, index) => {
              return (
                <option key={`${object.value}-${index}`} value={object.value}>
                  {object.name}
                </option>
              );
            })}
          </select>
          <div className="text-help label-text-help">
            {field.meta.touched ? field.meta.error : ""}
          </div>
          {/* </div> */}
          {/* <div className="col-sm-2"> */}
          <button
            bsStyle=""
            type="button"
            className="i-custom-table-delete ml-10"
            onClick={field.deleteaction}>
            <img
              className="i-custom-table"
              src="style/images/custom-icons/shape-1317-copy-3.svg"
            />
          </button>
        </div>
      </div>
    );
  }

  renderRichTextarea(field) {
    var divClassName = `form-group ${divClassName}`;
    return (
      <div className={divClassName}>
        <RichTextEditor type={field.type} {...field} />
        <div className="text-help label-text-help">
          {field.meta.touched ? field.meta.error : ""}
        </div>
      </div>
    );
  }

  renderRichTextareaWithLabel(field) {
    var labelposition = field.labelposition;
    var divClassName = `form-group row ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass}`;

    var labelClasses = "control-label col-sm-3";
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = "control-label-top col-sm-12";
      inputClasses = "col-sm-12 col-sm-offset-0";
    }
    return (
      <div className={divClassName}>
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className={inputClasses} style={{ paddingRight: 37 }}>
          <RichTextEditor type={field.type} {...field} />
          <div className="text-help label-text-help">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  // renderRichTextareaWithLabelTinymce(field) {
  //   var labelposition = field.labelposition;
  //   var divClassName = `form-group row ${field.meta.error ? "has-danger" : ""}`;

  //   var labelClasses = "control-label col-sm-3";
  //   var inputClasses = "col-sm-9 col-sm-offset-0";

  //   if (labelposition === LABEL_POSITION_TOP) {
  //     labelClasses = "control-label-top col-sm-12";
  //     inputClasses = "col-sm-12 col-sm-offset-0";
  //   }
  //   return (
  //     <div className={divClassName}>
  //       <label className={labelClasses}>
  //         {field.label}
  //         <span className="text-primary">
  //           {field.mandatory === "true" ? "*" : ""}
  //         </span>
  //       </label>
  //       <div className={inputClasses}>
  //         <RichTextTinymceEditor type={field.type} {...field} />
  //         <div className="text-help label-text-help">
  //           {field.meta.error ? field.meta.error : ""}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  renderRichTextareaWithLabelTinymce(field) {
    //var isdisabled = ((field.isdisabled) ? 'disabled' : '');
    var {
      meta: { touched, error },
      discountType,
    } = field;

    var className = `form-group text-help  ${
      touched && error ? "text-danger text-xs" : ""
    }`;

    var labelposition = field.labelposition;
    var extraClasses = field.className ? field.className : "";

    var formdiv = "";

    if (field.formdiv !== undefined && field.formdiv === "false") {
      formdiv = "";
    }

    var divClassName = `form-group row ${formdiv} ${
      field.meta.touched && field.meta.error ? "text-danger" : ""
    }`;

    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }

    return (
      <div className={inputClasses}>
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className={inputClasses}>
          <RichTextTinymceEditor type={field.type} {...field} />
          <div className={className}>
            {field.meta.error ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderAutoCompleteAsync(field) {
    var divClassName = `form-group row ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass}`;

    return (
      <div className={divClassName}>
        <label className="control-label col-sm-3">
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className="col-sm-9 col-sm-offset-0">
          <Select.Async
            {...field.input}
            multi={false}
            value={field.input.value || ""}
            valueKey={field.valueKey}
            placeholder={field.placeholder}
            labelKey={field.labelKey}
            loadOptions={field.loadOptions}
            onBlur={() => field.input.onBlur(field.input.value)}
          />
          <div className="text-help label-text-help">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderAutoComplete(field) {
    var divClassName = `form-group row ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass}`;

    return (
      <div className={divClassName}>
        <label className="control-label-top col-sm-12">
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className="col-sm-12 col-sm-offset-0">
          <Select
            {...field.input}
            multi={field.isMulti ? true : false}
            value={field.input.value || ""}
            valueKey={field.valueKey}
            placeholder={field.placeholder}
            labelKey={field.labelKey}
            options={field.options}
            onBlur={() => field.input.onBlur(field.input.value)}
          />
          <div className="text-help label-text-help">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderStartDateTimePicker(field) {
    //debugger;
    var { input, selected, startDate, endDate, minDate, disabled } = field;
    var value = input.value ? input.value : selected;
    return (
      <div className="form-group">
        <label className="control-label-top col-sm-12">
          {" "}
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className="col-sm-12 col-sm-offset-0">
          <DatePicker
            selectsStart
            selected={value}
            className="form-control"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="YYYY-MM-DD HH:mm"
            timeCaption="time"
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            disabled={disabled}
            onChange={input.onChange}
            name={input.name}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
          <div className="text-help label-text-help"></div>
        </div>
      </div>
    );
  }

  renderFieldSelect(field) {
    return (
      <div className="form-group">
        <label>{field.label}</label>
        <select className="form-control dropdown-height-border">
          <option value="">{field.placeholder}</option>
          {_.map(field.opts, (item) => {
            return <option value={item.value}>{item.name}</option>;
          })}
        </select>
      </div>
    );
  }

  renderFieldCheckbox(field) {
    return (
      <div>
        <div className="relative flex items-start">
          <div className="flex items-center h-5 mt-1">
            <input
              type={field.type}
              {...field.input}
              className={field.className}
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor="candidates"
              className={field.classNameLabel}
              style={{ fontSize: "16px" }}>
              {field.label}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderFieldCheckbox1(field) {
    return (
      <div>
        <div className="relative flex items-start">
          <div className="flex items-center h-5">
            <input
              type={field.type}
              {...field.input}
              className={field.className}
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor="specs"
              className={field.classNameLabel}
              style={{ fontSize: "15px" }}>
              {field.label}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderFieldRadio(field) {
    return (
      <div>
        <label
          className="custom-container-radio liveqa-label"
          style={{ marginBottom: 5 }}>
          {field.label}
          <input {...field.input} type={field.type} />
          <span className="radiomark"></span>
        </label>
      </div>
    );
  }

  renderFieldcustomer(field) {
    var {
      meta: { touched, error },
    } = field;
    var className = `form-group login-element mt-1 w-full ${
      touched && error ? "text-red-600 text-xs" : ""
    }`;
    return (
      <div className={className} style={{ marginTop: "0px" }}>
        <label>{field.label}</label>
        <input
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />
        <div className="text-help h-4">{touched ? error : ""}</div>
      </div>
    );
  }
  renderFieldAds(field) {
    var {
      meta: { touched, error },
    } = field;
    var className = `form-group login-element mt-1 w-full ${
      touched && error ? "text-red-600 text-xs" : ""
    }`;
    return (
      <div className={className} style={{ marginTop: "0px", width: "150px" }}>
        <label>{field.label}</label>
        <input
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />
        <div className="text-help h-4">{touched ? error : ""}</div>
      </div>
    );
  }

  renderFieldcenter(field) {
    var {
      meta: { touched, error },
    } = field;
    var className = `form-group login-element mt-1 w-half center-item ${
      touched && error ? "text-red-600 text-xs" : ""
    }`;
    return (
      <div className={className} style={{ marginTop: "0px" }}>
        <label>{field.label}</label>
        <input
          className="appearance-none center-item block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />
        <div className="text-help h-4">{touched ? error : ""}</div>
      </div>
    );
  }

  renderFieldDescription(field) {
    var {
      meta: { touched, error },
    } = field;
    var className = `form-group login-element mt-1 w-full ${
      touched && error ? "text-red-600 text-xs" : ""
    }`;
    return (
      <div className={className} style={{ marginTop: "0px" }}>
        <label>{field.label}</label>
        <input
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />
        <div className="text-help h-4">{touched ? error : ""}</div>
      </div>
    );
  }

  renderFieldRadiocustomer(field) {
    return (
      <div className="" style={{ display: "inline" }}>
        <input {...field.input} type={field.type} className="ml-2" />
        <span className="radiomark "></span>
        <label className="text-ternary text-sm font-medium rounded-md ml-2 ">
          {field.label}
        </label>
      </div>
    );
  }

  renderDatePickerWithLabel1(field) {
    var { input, selected, disabled, label, labelposition, meta } = field;

    var value = input.value
      ? moment(input.value).format(DATE_FORMAT)
      : selected
      ? moment(selected).format(DATE_FORMAT)
      : null;
    var selectedValue = value
      ? moment(value)
      : selected
      ? selected
      : moment(moment().format(DATE_FORMAT));

    var divClassName = `form-group row ${
      meta.touched && meta.error ? "text-red-600 text-xs" : ""
    }`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-12 col-sm-offset-0 ";

    if (labelposition === LABEL_POSITION_LEFT) {
      labelClasses = "control-label col-xs-12 col-sm-12 col-md-12";
      inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
    }

    return (
      <div className={divClassName}>
        {label !== "" ? (
          <label className={labelClasses}>
            {label}
            <span className="text-primary">
              {field.mandatory === "true" ? "*" : ""}
            </span>
          </label>
        ) : null}

        <div className={inputClasses}>
          <DatePicker
            className="form-control"
            name={input.name}
            {...input}
            value={value}
            // selected={selectedValue}
            dateFormat={DATE_FORMAT}
            disabled={disabled}
            minDate={field?.minDate ? field.minDate : ""}
            className={field.className}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            yearDropdownItemNumber={50}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
          <div className="text-help label-text-help">
            {meta.touched ? meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  // renderPhonewithFlag = (field) => {
  //   var labelposition = field.labelposition;
  //   var formdiv = "";
  //   if (field.formdiv !== undefined && field.formdiv === "false") {
  //     formdiv = "";
  //   }
  //   var divClassName = `form-group row ${formdiv} ${
  //     field.meta.touched && field.meta.error ? "has-danger" : ""
  //   }`;
  //   divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
  //   var labelClasses = field.classNameLabel;
  //   var inputClasses = "col-sm-9 col-sm-offset-0 w-full";

  //   if (labelposition === LABEL_POSITION_TOP) {
  //     labelClasses = field.classNameLabel;
  //     inputClasses = "col-sm-12 col-sm-offset-0";
  //   }
  //   return (
  //     <div className={divClassName} >
  //       {field.label !== "" ? (
  //         <label className={labelClasses}>
  //           {/* {field.label} */}
  //           <span className="text-primary">
  //             {field.mandatory === "true" ? "*" : ""}
  //           </span>
  //         </label>
  //       ) : null}
  //       <div className={inputClasses}>
  //         <PhoneInput
  //           {...field.input}
  //           placeholder={field.placeholder}
  //           inputStyle={{
  //             width: "100%",
  //             height: 38,
  //           }}
  //           // value={field.phone}
  //           // onChange={() => field.setPhone()}
  //           // inputProps={{
  //           //   name: field.name,
  //           //   required: true,
  //           // }}
  //         />
  //         <div className="text-error label-text-help">
  //           {field.meta.touched ? field.meta.error : ""}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  renderPhonewithFlag = (field) => {
    //var isdisabled = ((field.isdisabled) ? 'disabled' : '');
    var {
      meta: { touched, error },
    } = field;

    var { phoneIndex } = field;

    if (
      phoneIndex === "" ||
      phoneIndex === "null" ||
      phoneIndex === null ||
      phoneIndex === undefined ||
      phoneIndex === "undefined"
    ) {
      phoneIndex = 0;
    }

    var className = `form-group text-help  ${
      touched && error ? "text-danger text-xs" : ""
    }`;

    var labelposition = field.labelposition;
    //var extraClasses  = field.className?field.className:'';
    var formdiv = "";
    if (field.formdiv !== undefined && field.formdiv === "false") {
      formdiv = "";
    }
    var divClassName = `form-group row ${formdiv} ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }

    return (
      <div className={divClassName}>
        {field.label !== "" ? (
          <label className={labelClasses}>
            {field.label}
            <span className="text-primary">
              {field.mandatory === "true" ? "*" : ""}
            </span>
          </label>
        ) : null}
        <div className={inputClasses}>
          <PhoneInput
            country={"qa"}
            countryCodeEditable={false}
            {...field.input}
            placeholder={field.placeholder}
            inputStyle={{
              width: "100%",
              height: 38,
            }}
            // value={field.phone}
            onChange={(value, country, e, formattedValue) =>
              field.phoneChange(value, country, e, formattedValue, phoneIndex)
            }
            inputProps={{
              name: field.input.name,
            }}
          />
          <div className={className}>{touched ? error : ""}</div>
        </div>
      </div>
    );
  };

  renderFieldRadio1 = (field, checked) => {
    var divClassName = `form-group radio-smallscreen row ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    return (
      <div className={divClassName} style={{ display: "inline" }}>
        <label className="font-medium text-base text-loginLabel cursor-pointer mx-3">
          {field.lebelAlign ? "" : field.label}
          <input
            {...field.input}
            type={field.type}
            className={field.className}
          />
          &nbsp;
          <span className="radiomark"></span>
          {field.lebelAlign === "R" ? field.label : ""}
        </label>
      </div>
    );
  };

  renderFieldNewText(field) {
    var isdisabled = field.isdisabled ? "disabled" : "";
    var {
      meta: { touched, error },
    } = field;

    var className = `form-group text-help  ${
      touched && error ? "text-danger text-xs" : ""
    }`;

    var labelposition = field.labelposition;
    var extraClasses = field.className ? field.className : "";
    var formdiv = "";
    if (field.formdiv !== undefined && field.formdiv === "false") {
      formdiv = "";
    }
    var divClassName = `form-group row ${formdiv} ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }
    return (
      <div className={divClassName}>
        {field.label !== "" ? (
          <label className={labelClasses}>
            {field.label}
            <span className="text-primary">
              {field.mandatory === "true" ? "*" : ""}
            </span>
          </label>
        ) : null}
        <div className={inputClasses}>
          {field.type === "password" ? (
            <input
              className="form-control"
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              autoComplete="new-password"
            />
          ) : field.type === "number" ? (
            <input
              className={"cust-input-field " + extraClasses}
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              min={field.min ? field.min : ""}
            />
          ) : (
            <input
              className={"cust-input-field " + extraClasses}
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              disabled={isdisabled}
            />
          )}
          <div className={className}>{touched ? error : ""}</div>
        </div>
      </div>
    );
  }

  renderAutoComplete(field) {
    var divClassName = `form-group row ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass}`;
    // console.log('field.input.value',field.input.value)
    return (
      <div className={divClassName}>
        <label className="control-label-top col-sm-12">
          {field.label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className="col-sm-12 col-sm-offset-0">
          <Select
            {...field.input}
            isMulti={field.isMulti ? true : false}
            value={field.input.value || ""}
            valueKey={field.valueKey}
            placeholder={field.placeholder}
            labelKey={field.labelKey}
            options={field.options}
            onBlur={() => field.input.onBlur(field.input.value)}
          />
          <div className="text-help label-text-help">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderDateTimePickerWithLabel_New = (field) => {
    // console.log('field :- ', field);
    var { input, disabled, label, labelposition, meta } = field;

    var DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";

    var selectedValue = input?.value
      ? input.value
      : field?.selected
      ? field.selected
      : null;
    // console.log('Type :- ', typeof selectedValue);
    if (typeof selectedValue === "string") {
      selectedValue = new Date(selectedValue);
    }

    // debugger;
    // console.log('selected :- ', field.selected);
    // console.log('selectedValue :- ', selectedValue);

    var className = `form-group text-help  ${
      meta.touched && meta.error ? "text-danger text-xs" : ""
    }`;

    var divClassName = `form-group row ${
      meta.touched && meta.error ? "text-danger" : ""
    }`;

    var labelClasses = "control-label-top col-sm-12";
    var inputClasses = "col-sm-12 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_LEFT) {
      labelClasses = "control-label col-xs-12 col-sm-12 col-md-3";
      inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
    }

    return (
      <div className={divClassName}>
        {label !== "" ? (
          <label className={field.classNameLabel}>
            {label}
            <span className="text-primary">
              {field.mandatory === "true" ? "*" : ""}
            </span>
          </label>
        ) : null}

        <div className={inputClasses}>
          <DatePicker
            className="form-control"
            isClearable
            placeholderText={field?.placeholder ? field.placeholder : ""}
            name={input.name}
            {...input}
            selected={selectedValue}
            showTimeSelect
            timeIntervals={10}
            dateFormat={DATE_FORMAT_SM}
            disabled={disabled}
            onChange={input.onChange}
            className={field.className}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
          <div className={className}>{meta.touched ? meta.error : ""}</div>
        </div>
      </div>
    );
  };

  rendernewFieldNewText(field) {
    var isdisabled = field.isdisabled ? "disabled" : "";
    var {
      meta: { touched, error },
    } = field;

    var className = `form-group text-help  ${
      touched && error ? "text-danger text-xs" : ""
    }`;

    var labelposition = field.labelposition;
    var extraClasses = field.className ? field.className : "";
    var formdiv = "";
    if (field.formdiv !== undefined && field.formdiv === "false") {
      formdiv = "";
    }
    var divClassName = `form-group row ${formdiv} ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }
    return (
      <div className={divClassName}>
        {field.label !== "" ? (
          <label className={labelClasses}>
            {field.label}
            <span className="text-primary">
              {field.mandatory === "true" ? "*" : ""}
            </span>
          </label>
        ) : null}
        <div className={inputClasses}>
          {field.type === "password" ? (
            <input
              className="form-control"
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              autoComplete="new-password"
            />
          ) : field.type === "number" ? (
            <input
              className={"cust-input-field " + extraClasses}
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              min={field.min ? field.min : ""}
            />
          ) : (
            <input
              className={"cust-input-field " + extraClasses}
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              disabled={isdisabled}
            />
          )}
          <div className={className}>{touched ? error : ""}</div>
        </div>
      </div>
    );
  }
  renderFieldTextareaNew(field) {
    var className = `form-group text-help  ${
      field.meta.touched && field.meta.error ? "text-danger text-xs" : ""
    }`;

    var labelposition = field.labelposition;
    var divClassName = `form-group row ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass}`;

    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }
    return (
      <div className={divClassName}>
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className={inputClasses}>
          <textarea
            className={field.className}
            placeholder={field.placeholder}
            {...field.input}
            maxLength={field.maxlength ? field.maxlength : ""}></textarea>
          <div className={className}>
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderFieldsAll(field) {
    var isdisabled = field.isdisabled ? "disabled" : "";
    var {
      meta: { touched, error },
    } = field;

    var className = `form-group text-help  ${
      touched && error ? "text-danger text-xs" : ""
    }`;

    var labelposition = field.labelposition;
    var extraClasses = field.className ? field.className : "";
    var formdiv = "";
    if (field.formdiv !== undefined && field.formdiv === "false") {
      formdiv = "";
    }
    var divClassName = `form-group row ${formdiv} ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }
    return (
      <div className={divClassName}>
        {field.label !== "" ? (
          <label className={labelClasses}>
            {field.label}
            <span className="text-primary">
              {field.mandatory === "true" ? "*" : ""}
            </span>
          </label>
        ) : null}
        <div className={inputClasses}>
          {field.type === "password" ? (
            <div className="relative">
              <input
                className={"cust-input-field " + extraClasses}
                type={field.show ? "text" : "password"}
                placeholder={field.placeholder}
                {...field.input}
                maxLength={field.maxlength ? field.maxlength : ""}
                autoComplete="new-password"
              />

              {/*  Pssword Icons */}
              <div className="absolute flex items-center text-sm top-3.5 right-3.5 cursor-pointer">
                {field.show ? (
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="eye"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                    onClick={() => field.setShow(!field.show)}>
                    <path
                      d="M81.8 537.8a60.3 60.3 0 010-51.5C176.6 286.5 319.8 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c-192.1 0-335.4-100.5-430.2-300.2z"
                      fill="var(--theme-color)"></path>
                    <path
                      d="M512 258c-161.3 0-279.4 81.8-362.7 254C232.6 684.2 350.7 766 512 766c161.4 0 279.5-81.8 362.7-254C791.4 339.8 673.3 258 512 258zm-4 430c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z"
                      fill="var(--theme-color)"></path>
                    <path
                      d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258s279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766z"
                      fill="var(--theme-color)"></path>
                    <path
                      d="M508 336c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"
                      fill="var(--theme-color)"></path>
                  </svg>
                ) : (
                  <svg
                    onClick={() => field.setShow(!field.show)}
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="eye-invisible"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path
                      d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"
                      fill="var(--ternary-text-color)"></path>
                    <path
                      d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"
                      fill="var(--ternary-text-color)"></path>
                  </svg>
                )}
              </div>
            </div>
          ) : field.type === "number" ? (
            <input
              className={"cust-input-field " + extraClasses}
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              min={field.min ? field.min : ""}
            />
          ) : (
            <input
              className={"cust-input-field " + extraClasses}
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              disabled={isdisabled}
            />
          )}
          <div className={className}>{touched ? error : ""}</div>
        </div>
      </div>
    );
  }

  renderRichTextareaWithLabelTinymceNew(field) {
    //var isdisabled = ((field.isdisabled) ? 'disabled' : '');
    var {
      meta: { touched, error },
      discountType,
    } = field;

    var className = `form-group text-help  ${
      touched && error ? "text-danger text-xs" : ""
    }`;

    var labelposition = field.labelposition;
    var extraClasses = field.className ? field.className : "";

    var formdiv = "";

    if (field.formdiv !== undefined && field.formdiv === "false") {
      formdiv = "";
    }

    var divClassName = `form-group row ${formdiv} ${
      field.meta.touched && field.meta.error ? "text-danger" : ""
    }`;

    divClassName = ` ${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }

    return (
      <div className={inputClasses}>
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className={inputClasses}>
          <RichTextTinymceEditor type={field.type} {...field} />
          <div className={className}>
            {field.meta.error ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderFieldRadioNew(field) {
    return (
      <div className="" style={{ display: "inline" }}>
        <label className="custom-container-radio mr-10">
          {field.lebelAlign ? "" : field.label}
          <input
            {...field.input}
            type={field.type}
            className={field.className}
          />
          <span className="radiomark"></span>
          {field.lebelAlign === "R" ? field.label : ""}
        </label>
      </div>
    );
  }

  renderAllfields(field) {
    var isdisabled = field.isdisabled ? "disabled" : "";
    var {
      meta: { touched, error },
    } = field;

    var className = `form-group text-help  ${
      touched && error ? "text-danger text-xs" : ""
    }`;

    var labelposition = field.labelposition;
    var extraClasses = field.className ? field.className : "";
    var formdiv = "";
    if (field.formdiv !== undefined && field.formdiv === "false") {
      formdiv = "";
    }
    var divClassName = `form-group row ${formdiv} ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }
    return (
      <div className={divClassName}>
        {field.label !== "" ? (
          <label className={labelClasses}>
            {field.label}
            <span className="text-primary">
              {field.mandatory === "true" ? "*" : ""}
            </span>
          </label>
        ) : null}
        <div className={inputClasses}>
          {field.type === "password" ? (
            <div className="relative">
              <input
                className="appearance-none block w-full pl-3 pr-7 py-2 border border-ternary rounded-md shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                type={field.show ? "text" : "password"}
                placeholder={field.placeholder}
                {...field.input}
                maxLength={field.maxlength ? field.maxlength : ""}
                autoComplete="new-password"
              />

              {/*  Pssword Icons */}
              <div className="absolute flex items-center text-sm top-3.5 right-3.5 cursor-pointer">
                {field.show ? (
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="eye"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                    onClick={() => field.setShow(!field.show)}>
                    <path
                      d="M81.8 537.8a60.3 60.3 0 010-51.5C176.6 286.5 319.8 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c-192.1 0-335.4-100.5-430.2-300.2z"
                      fill="var(--theme-color)"></path>
                    <path
                      d="M512 258c-161.3 0-279.4 81.8-362.7 254C232.6 684.2 350.7 766 512 766c161.4 0 279.5-81.8 362.7-254C791.4 339.8 673.3 258 512 258zm-4 430c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z"
                      fill="var(--theme-color)"></path>
                    <path
                      d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258s279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766z"
                      fill="var(--theme-color)"></path>
                    <path
                      d="M508 336c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"
                      fill="var(--theme-color)"></path>
                  </svg>
                ) : (
                  <svg
                    onClick={() => field.setShow(!field.show)}
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="eye-invisible"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path
                      d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"
                      fill="var(--ternary-text-color)"></path>
                    <path
                      d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"
                      fill="var(--ternary-text-color)"></path>
                  </svg>
                )}
              </div>
            </div>
          ) : field.type === "number" ? (
            <input
              className={"cust-input-field " + extraClasses}
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              min={field.min ? field.min : ""}
            />
          ) : (
            <input
              className={"cust-input-field " + extraClasses}
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              disabled={isdisabled}
            />
          )}
          <div className={className}>{touched ? error : ""}</div>
        </div>
      </div>
    );
  }
  renderDatePickerWithLabelAgelimit(field) {
    //debugger;
    var { input, selected, disabled } = field;

    var value = input.value ? input.value : null;
    var selectedValue = value ? value : selected;

    var divClassName = `form-group row ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-12 col-sm-offset-0";
    let today = new Date();
    const days_to_subtract = 4745;
    return (
      <div className={divClassName}>
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className={inputClasses}>
          <DatePicker
            {...input}
            value={value}
            selected={selectedValue}
            dateFormat={DATE_FORMAT_SM}
            disabled={disabled}
            name={input.name}
            className={field.className}
            style={{ width: "100%" }}
            maxDate={
              new Date(today.valueOf() - days_to_subtract * 24 * 60 * 60 * 1000)
            }
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />

          <div className="text-help label-text-help">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  renderFieldNumber = (field) => {
    var {
      meta: { touched, error },
    } = field;

    var className = `form-group text-help  ${
      touched && error ? "text-danger text-xs" : ""
    }`;

    var labelposition = field.labelposition;

    var formdiv = "";

    if (field.formdiv !== undefined && field.formdiv === "false") {
      formdiv = "";
    }

    var divClassName = `form-group row ${formdiv} ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;

    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }

    field.input.value = field.input.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..?)\../g, "$1");

    return (
      <div className={divClassName}>
        {field.label !== "" ? (
          <label className={labelClasses}>
            {field.label}
            <span className="text-primary">
              {field.mandatory === "true" ? "*" : ""}
            </span>
          </label>
        ) : null}
        <div className={inputClasses}>
          <input
            className="cust-input-field"
            type={field.type}
            placeholder={field.placeholder}
            {...field.input}
            maxLength={field.maxlength ? field.maxlength : ""}
            min={field.min ? field.min : ""}
            max={field.max ? field.max : ""}
          />
          <div className={className}>{touched ? error : ""}</div>
        </div>
      </div>
    );
  };

  renderDateTimePickerWithLabel1 = (field) => {
    var { input, disabled, label, labelposition, meta } = field;

    var DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

    var selectedValue = input?.value
      ? input.value
      : field?.selected
      ? field.selected
      : null;

    if (typeof selectedValue === "string") {
      selectedValue = parse(
        moment(selectedValue).format("DD-MM-YYYY HH:mm:ss"),
        "dd-MM-yyyy HH:mm:ss",
        new Date()
      );
    }
    // debugger;

    var className = `form-group text-help  ${
      meta.touched && meta.error ? "text-danger text-xs" : ""
    }`;

    var divClassName = `form-group row ${
      meta.touched && meta.error ? "text-danger" : ""
    }`;

    //var labelClasses = 'control-label-top col-sm-12';
    var inputClasses = "col-sm-12 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_LEFT) {
      //labelClasses = 'control-label col-xs-12 col-sm-12 col-md-3';
      inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
    }

    return (
      <div className={divClassName}>
        {label !== "" ? (
          <label className={field.classNameLabel}>
            {label}
            <span className="text-primary">
              {field.mandatory === "true" ? "*" : ""}
            </span>
          </label>
        ) : null}

        <div className={inputClasses}>
          <DatePicker
            isClearable
            placeholderText={field?.placeholder ? field.placeholder : ""}
            name={input.name}
            {...input}
            autoComplete="off"
            autoCorrect="off"
            spellCheck="off"
            selected={selectedValue}
            showTimeSelect
            timeIntervals={10}
            timeFormat={`HH:mm`}
            dateFormat={DATE_TIME_FORMAT}
            minDate={field?.minDate ? field.minDate : ""}
            disabled={disabled}
            onChange={input.onChange}
            className={field.className}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            yearDropdownItemNumber={50}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
          <div className={className}>{meta.touched ? meta.error : ""}</div>
        </div>
      </div>
    );
  };

  /**** For Flat and percentage Discount Only ****/
  renderFieldTextDiscount(field) {
    //var isdisabled = ((field.isdisabled) ? 'disabled' : '');
    var {
      meta: { touched, error },
      discountType,
    } = field;

    var className = `form-group text-help  ${
      touched && error ? "text-danger text-xs" : ""
    }`;

    var labelposition = field.labelposition;
    var extraClasses = field.className ? field.className : "";

    var formdiv = "";

    if (field.formdiv !== undefined && field.formdiv === "false") {
      formdiv = "";
    }

    var divClassName = `form-group row ${formdiv} ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;

    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }
    return (
      <div className={divClassName}>
        {field.label !== "" ? (
          <label className={labelClasses}>
            {field.label}
            <span className="text-primary">
              {field.mandatory === "true" ? "*" : ""}
            </span>
          </label>
        ) : null}
        <div className={inputClasses}>
          <div className="relative">
            <input
              className={"cust-input-field " + extraClasses}
              type="text"
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
            />

            {/*  Pssword Icons */}
            <div className="absolute flex items-center text-sm top-2.5 right-3.5 cursor-pointer">
              {/* {field.show ? ( */}
              <span
                className={classNames(
                  discountType === "fixed" ? "text-primary" : "",
                  "cursor-pointer hover:text-primary"
                )}
                onClick={(e) => field.handelDiscountType("fixed")}>
                {LocaleStrings.flat}
              </span>
              <span className="px-2 text-ternary">|</span>
              <span
                className={classNames(
                  discountType === "percentage" ? "text-primary" : "",
                  "cursor-pointer hover:text-primary"
                )}
                onClick={(e) => field.handelDiscountType("percentage")}>
                %
              </span>
              {/* )} */}
            </div>
          </div>

          <div className={className}>{touched ? error : ""}</div>
        </div>
      </div>
    );
  }

  renderFileuploadfield(field) {
    var labelposition = field.labelposition;
    var formdiv = "";
    if (field.formdiv !== undefined && field.formdiv === "false") {
      formdiv = "";
    }
    var divClassName = `form-group row ${formdiv} ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }
    return (
      <div className={divClassName}>
        {field.label !== "" ? (
          <label className={labelClasses}>
            {field.label}
            <span className="text-primary">
              {field.mandatory === "true" ? "*" : ""}
            </span>
          </label>
        ) : null}
        <div className={inputClasses}>
          {field.type === "password" ? (
            <input
              className="form-control"
              disabled
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              autoComplete="new-password"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          ) : (
            <input
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          )}
          <div className="text-help label-text-help">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }
  renderDatalistSelect(field) {
    // console.log('datalistoptn',field.datalistoptn)
    var isdisabled = field.isdisabled ? "disabled" : "";
    var {
      meta: { touched, error },
    } = field;

    var className = `form-group text-help  ${
      touched && error ? "text-danger text-xs" : ""
    }`;

    var labelposition = field.labelposition;
    var extraClasses = field.className ? field.className : "";
    var formdiv = "";
    if (field.formdiv !== undefined && field.formdiv === "false") {
      formdiv = "";
    }
    var divClassName = `form-group row ${formdiv} ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;
    divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }

    return (
      <div className={divClassName}>
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className="col-sm-12 col-sm-offset-0">
          <input
            className={"cust-input-field " + extraClasses}
            type={field.type}
            placeholder={field.placeholder}
            {...field.input}
            list={field.list}
            disabled={isdisabled}
          />
          <datalist id={field.list}>
            {field.datalistoptn.map((item, key) => (
              <option key={item.value}>{item.name}</option>
            ))}
          </datalist>
          <div className={className}>
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  // Above all
}
