import LocaleStrings from "../../../languages/index";
import {
  CATEGORY_LIST,
  SUBCATEGORY_LIST,
  EDITABLE_CATEGORY,
  EDITABLE_SUBCATEGORY,
  SUBCATEGORY_MODAL,
  CATEGORY_RELATED_PRODUCT,
} from "../actions/index";

export function categorylist(state = {}, action) {
  switch (action.type) {
    case CATEGORY_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function subcategorylist(state = {}, action) {
  switch (action.type) {
    case SUBCATEGORY_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function editablecategorylist(state = {}, action) {
  switch (action.type) {
    case EDITABLE_CATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function editablesubcategorylist(state = {}, action) {
  switch (action.type) {
    case EDITABLE_SUBCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function subcategorymodalstore(state = {}, action) {
  switch (action.type) {
    case SUBCATEGORY_MODAL:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}

export var productUnderCategory = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_RELATED_PRODUCT:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var categoryDropdownarr = (state = [], action) => {
  var state = [
    { name: "Active", value: 1, id: 1 },
    { name: "Inactive", value: 0, id: 2 },
  ];

  return state;
};
export var categoryDropdownarr2 = (state = [], action) => {
  var state = [
    { name: "Active", value: true, id: 1 },
    { name: "Inactive", value: false, id: 2 },
  ];

  return state;
};
