import { INSTANCE_URL, itemCount } from "../../../../common/constants";
import {
  getRequest,
  postRequest,
  patchRequest,
} from "../../../../common/network-call";

export var MASTERALL_TRANSACTION = "MASTERALL_TRANSACTION";
export var PAYOUTS_DATA = "PAYOUTS_DATA";
export var UNPAID_PAYOUTS_DATA = "UNPAID_PAYOUTS_DATA";
export var CHARITY_PAYOUTS_DATA = "CHARITY_PAYOUTS_DATA";

export function fetchmasterTransaction(session, pageCount = 0, callback) {
  var perPage = 10;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;
  var orderby = `createdat%20DESC`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/transactions?related=*&include_count=true&${offSetQuery}&order=${orderby}`;
  // console.log(url)
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: MASTERALL_TRANSACTION,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchTransaction(session, search = "", pageCount = 0) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${itemCount}&offset=${offset}`;
  var searchQuery = `(transactionid like %${search}%)`;
  var filter = encodeURI(`(${searchQuery})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/transactions?related=users_by_paymentby&include_count=true&filter=(${filter})&order=createdat%20DESC${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log("SPONSOR: ", data);
        dispatch({
          type: MASTERALL_TRANSACTION,
          payload: { data: data.resource, count: data.meta.count },
        });
      },
      (error) => {}
    );
  };
}

export function fetchPayouts(
  session,
  limit,
  page,
  startdate,
  enddate,
  payout,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/payout`;
  let values = {};

  // Paginated
  values.limit = limit;
  values.page = page;
  values.startdate = startdate;
  values.enddate = enddate;
  values.payout = payout;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PAYOUTS_DATA,
          payload: { data: response.items, count: response.count },
        });
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}

export function fetchunpaidPayouts(
  session,
  limit,
  page,
  startdate,
  enddate,
  payout,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/payout`;
  let values = {};

  // Paginated
  values.limit = limit;
  values.page = page;
  values.startdate = startdate;
  values.enddate = enddate;
  values.payout = payout;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: UNPAID_PAYOUTS_DATA,
          payload: { data: response.items, count: response.count },
        });
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}

export function updateOrderPayout(session, updateOrderPayoutArr, callback) {
  var body = { resource: updateOrderPayoutArr };

  var url = `${INSTANCE_URL}/api/v2/shopez/_table/ordermaster/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchcharityPayouts(
  session,
  limit,
  page,
  startdate,
  enddate,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/charitypayout`;
  let values = {};

  // Paginated
  values.limit = limit;
  values.page = page;
  values.startdate = startdate;
  values.enddate = enddate;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CHARITY_PAYOUTS_DATA,
          payload: { data: response.items, count: response.count },
        });
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}
