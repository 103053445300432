import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index.js";
import { Field, reduxForm } from "redux-form";
import { classNames } from "../../../../../common/common-functions";
import { BaseComponent } from "../../../../../common/base-component";
import _ from "lodash";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../../common/constants";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";

class HeaderbannerView extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  render() {
    let { homePagemaindata } = this.props;
    let headerBanner = [];
    if (homePagemaindata && homePagemaindata && homePagemaindata.length > 0) {
      headerBanner = _.filter(homePagemaindata, function (o) {
        return o.section == "banner" && o.bannertype == "header";
      });
    }
    return (
      <>
        {headerBanner &&
        headerBanner.length > 0 &&
        headerBanner[0].data &&
        headerBanner[0].data.length > 0 ? (
          <div
            className="mt-0 md:mt-22 mb-0 xl:mb-30 lg:mb-30 md:mb-30 rounded custom_container grid grid-cols-4"
            //   style={
            //     props.deviceSize.isMobile ? { paddingLeft: 0, paddingRight: 0 } : {}
            //   }
          >
            <div className="col-span-4 md:col-span-3">
              <Carousel
                autoPlay={true}
                showArrows={true}
                showThumbs={false}
                infiniteLoop={true}
                showStatus={false}>
                {_.map(headerBanner[0].data, (item, index) => {
                  return (
                    <div className="relative cursor-pointer bannerwrapper">
                      <LazyLoadImage
                        src={`${BASE_IMAGES_URL}/${item.image}?api_key=${APP_API_KEY}`}
                        //   className={classNames(
                        //     props.deviceSize.isMobile ? "" : "rounded ",
                        //     "w-auto h-150 md:h-318 object-cover"
                        //   )}
                        className="w-auto h-150 md:h-318 object-cover"
                        effect="blur"
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <div className="pl-4 hidden md:block py-4">
              {headerBanner &&
              headerBanner.length > 0 &&
              headerBanner[0].staticdata &&
              headerBanner[0].staticdata.image ? (
                <div className="relative cursor-pointer bannerwrapper">
                  <LazyLoadImage
                    src={`${BASE_IMAGES_URL}/${headerBanner[0].staticdata.image}?api_key=${APP_API_KEY}`}
                    className="w-auto object-cover rounded"
                    effect="blur"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className="flex items-center mt-4 mb-0 md:mb-4 rounded custom_container">
            hi
          </div>
        )}
      </>
    );
  }
}

var validate = (values) => {
  // console.log("values", values);
  var errors = {};

  return errors;
};

function mapStateToProps(state) {
  var initVals = { gender: "" };
  var { session, user } = state;
  return {
    session,
    user,
    homePagemaindata: state.homePagemaindata ? state.homePagemaindata.data : [],
  };
}

export default connect(
  mapStateToProps,
  {}
)(
  reduxForm({
    validate,
    form: "HeaderbannerViewform",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(HeaderbannerView)
);
