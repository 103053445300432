import React from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../common/base-component";
import _ from "lodash";
import renderHTML from "react-render-html";
import moment from "moment";
import ViewCustomUsers from "./viewCustomusers";
import {
  isCutomnotificationOpen,
  deleteNotifications,
  fetchNotificationlist,
} from "../actions/index";
import { confirmAlert } from "react-confirm-alert";
import LocaleStrings from "../../../languages/index";
import { NotificationManager } from "react-notifications";
import { ProductTextLocalized } from "../../../../common/common-functions";

class NotificationListitem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
    };
  }

  deleteNotifications(e, values) {
    // console.log('deleteNewsCallback',values)
    confirmAlert({
      title: "Delete Notification",
      message: "Are you sure you want to delete this notification?",
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            this.props.deleteNotifications(
              this.props.session,
              values,
              (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error == 1) {
                  NotificationManager.error("Error in deleeting notififcation");
                } else {
                  NotificationManager.success(
                    "Notification Deleted Successfully"
                  );
                  this.props.fetchNotificationlist(
                    this.props.session,
                    (CallBackResponse) => {}
                  );
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  openModal = (e, obj) => {
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  render() {
    var { values, language } = this.props;
    // console.log('valueslisting', values);
    let text = "";
    let desc = ProductTextLocalized(
      values.notificationtext_en,
      values.notificationtext_ar,
      language
    );
    console.log("desc", desc.length);
    return (
      <>
        {values.notification_for == "custom" ? (
          <>
            {" "}
            <tr className="bg-white border-b-2 border-gray-50 hover-all">
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openModal(e, values)}>
                {desc != "" && desc != null ? (
                  <>{desc.length > 35 ? desc.substring(0, 35) + "..." : desc}</>
                ) : (
                  ""
                )}
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openModal(e, values)}>
                <div>{moment(values.createdat).format("D MMM YYYY")}</div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                onClick={(e) => this.openModal(e, values)}>
                <div>{_.startCase(values.notification_for)}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="">
                    <button
                      className="button"
                      type="button"
                      onClick={(e) => this.deleteNotifications(e, values)}>
                      Delete
                    </button>
                    {/* <EditcustomerModal  values={values}/>  */}
                  </div>
                </div>
              </td>
              {this.state.isOpen ? (
                <ViewCustomUsers
                  isOpen={this.state.isOpen}
                  onClose={this.closeModal}
                  values={this.props.values.user}
                />
              ) : (
                ""
              )}
            </tr>
          </>
        ) : (
          <tr className="bg-white border-b-2 border-gray-50 hover-all">
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
              {desc != "" && desc != null ? (
                <>{desc.length > 35 ? desc.substring(0, 35) + "..." : desc}</>
              ) : (
                ""
              )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
              <div>{moment(values.createdat).format("D MMM YYYY")}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              <div>{_.startCase(values.notification_for)}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              <div className="action-button-area" style={{ display: "flex" }}>
                <div className="">
                  <button
                    className="button"
                    type="button"
                    onClick={(e) => this.deleteNotifications(e, values)}>
                    Delete
                  </button>
                </div>
              </div>
            </td>
          </tr>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
  };
}

export default connect(mapStateToProps, {
  isCutomnotificationOpen,
  deleteNotifications,
  fetchNotificationlist,
})(NotificationListitem);
