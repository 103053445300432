import {
  INSTANCE_URL,
  itemCount,
  BASE_IMAGES_URL,
} from "../../../../common/constants";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  uploadEncodedFileLarge,
  postRequestnew,
  postRequestAccess,
  deleteBulkRequest,
} from "../../../../common/network-call";
import _ from "lodash";
import { USER_NAME, USER_PHONE } from "../../../../actions/index";

export var GLOBAL_COMMISSION = "GLOBAL_COMMISSION";
export var ALL_FAQ = "ALL_FAQ";
export var ALL_VENDORFAQ = "ALL_VENDORFAQ";
export var EDIT_FAQ = "EDIT_FAQ";
export var DELIVERYAGENTS_LIST = "DELIVERYAGENTS_LIST";
export var ADMIN_LISTING = "ADMIN_LISTING";
export var EDITADMIN_DATA = "EDITADMIN_DATA";
export var RESTRICTED_WORDS = "RESTRICTED_WORDS";
export var EDIT_RESTRICTED_WORDS = "EDIT_RESTRICTED_WORDS";
export var EDIT_AGENTS = "EDIT_AGENTS";
export var SHIPPING_CHARGES = "SHIPPING_CHARGES";
export var EDIT_GLOBALCHARGES = "EDIT_GLOBALCHARGES";
export var RESTRICTED_FILE = "RESTRICTED_FILE";
export var ZONE_MASTER = "ZONE_MASTER";
export var CHARITY_EVENTS = "CHARITY_EVENTS";
export var EDIT_CHARITYEVENTS = "EDIT_CHARITYEVENTS";
export var ALLMODERATOR_ROLE = "ALLMODERATOR_ROLE";

export function fetchglobalCommision(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/settings_master`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: GLOBAL_COMMISSION,
          payload: { data: data.resource[0] },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function updateGlobalCommission(session, values, callback) {
  var body = { resource: { ...values } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/settings_master`;
  // console.log('body',body)
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch(fetchglobalCommision(session));
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchConsumerFaq(session, pageCount = 0, callback) {
  var perPage = 7;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;
  var orderby = `createdon%20DESC`;
  // var url=`${INSTANCE_URL}/api/v2/shopez/_table/faq?filter=(faqfor=consumer)`
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/faq?include_count=true&filter=(faqfor=consumer)&${offSetQuery}&order=${orderby}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('consumer', data);
        dispatch({
          type: ALL_FAQ,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchCustomerfaq(session, search = "", pageCount = 0) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${itemCount}&offset=${offset}`;
  var searchQuery = `(question_en like %${search}%)OR(answer_en like %${search}%)OR(question_ar like %${search}%)OR(answer_ar like %${search}%)`;
  var filter = encodeURI(`(${searchQuery})`);
  // var url = `${INSTANCE_URL}/api/v2/eventify/_table/sponsor?related=membershiptype_by_categoryid,appuser_by_sponsoridfk&include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/faq?include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;

  return (dispatch) => {
    // dispatch(fetchConsumerFaq(session));
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log("SPONSOR: ", data);
        dispatch({
          type: ALL_FAQ,
          payload: { data: data.resource, count: data.meta.count },
        });
      },
      (error) => {}
    );
  };
}
export function fetchVendorFaq(session, pageCount = 0, callback) {
  var perPage = 7;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;
  var orderby = `createdon%20DESC`;
  // var url=`${INSTANCE_URL}/api/v2/shopez/_table/faq?filter=(faqfor=consumer)`
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/faq?include_count=true&filter=(faqfor=vendor)&${offSetQuery}&order=${orderby}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        console.log("consumer", data);
        dispatch({
          type: ALL_VENDORFAQ,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchVendorfaq(session, search = "", pageCount = 0) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${itemCount}&offset=${offset}`;
  var searchQuery = `(question_en like %${search}%)OR(answer_en like %${search}%)OR(question_ar like %${search}%)OR(answer_ar like %${search}%)`;
  var filter = encodeURI(`(${searchQuery})`);
  // var url = `${INSTANCE_URL}/api/v2/eventify/_table/sponsor?related=membershiptype_by_categoryid,appuser_by_sponsoridfk&include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/faq?include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;

  return (dispatch) => {
    // dispatch(fetchVendorFaq(session));
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log("SPONSOR: ", data);
        dispatch({
          type: ALL_VENDORFAQ,
          payload: { data: data.resource, count: data.meta.count },
        });
      },
      (error) => {}
    );
  };
}

export function addfaq(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/shopez/_table/faq`;
  let body = { resource: { ...values } };
  //let body = { resource: { ...values, eventid: session.eventid } };
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

export function foreditfaq(editfaqs) {
  return (dispatch) => {
    dispatch({ type: EDIT_FAQ, payload: editfaqs });
  };
}

export function updateFaqs(session, values, callback) {
  var body = { resource: { ...values } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/faq/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch(fetchConsumerFaq(session));
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function deleteFAQ(session, values, callback) {
  var filter = encodeURI(`filter=(id=${values.id})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/faq?${filter}`;
  // console.log('values',url);
  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        callback({ error: 0, result: data });
      },
      (error) => {
        callback({ error: 1, message: error });
      }
    );
  };
}

export function fetchAllDeliveryPartners(session, pageNumber, callback) {
  var offset = pageNumber;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/deliveryservices`;
  return (dispatch) => {
    postRequest(
      url,
      {
        limit: "10",
        page: offset,
        type: "listing",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: DELIVERYAGENTS_LIST,
          payload: { data: data.data, count: data.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchDeliverAgent(session, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/deliveryservices`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        type: "search",
        searchterm: search,
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: DELIVERYAGENTS_LIST,
          payload: { data: data.data },
        });
      },
      (error) => {}
    );
  };
}

export function fetchModeratorAdmin(session, pageCount = 0, callback) {
  var perPage = 7;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;
  var orderby = `createdon%20DESC`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?related=*&include_count=true&filter=(usertype=moderator)&${offSetQuery}&order=${orderby}`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: ADMIN_LISTING,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchModeratorAdmin(session, search = "", pageCount = 0) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${itemCount}&offset=${offset}`;
  var searchQuery = `((username like %${search}%) AND (usertype=moderator))`;

  var filter = encodeURI(`(${searchQuery})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?related=*&include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: ADMIN_LISTING,
          payload: { data: data.resource, count: data.meta.count },
        });
      },
      (error) => {}
    );
  };
}

export function addnewAdmin(session, dfdata, callback) {
  // console.log('dfdata',dfdata);
  let url = `${INSTANCE_URL}/api/v2/user/register?login=true`;
  var body = dfdata;
  return (dispatch) => {
    postRequestnew(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

export function updateAdmin(session, values, callback) {
  var body = { resource: { ...values } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch(fetchModeratorAdmin(session, 0));
        callback({ success: 1 });
      },
      (error) => {
        // callback({ success: 0 });
      }
    );
  };
}

export function onEditadmin(editadmindata) {
  let rolesArr = [];

  _.forEach(editadmindata.admin_role_by_admin, function (value) {
    var obj = {
      label: value.name,
      value: value.position.toString(),
    };

    rolesArr.push(obj);
  });

  editadmindata["roles"] = rolesArr;
  return (dispatch) => {
    dispatch({ type: EDITADMIN_DATA, payload: editadmindata });
  };
}

export function updateLegalDocs(session, values, callback) {
  var body = { resource: values };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/settings_master`;
  // console.log('body',values)
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch(fetchglobalCommision(session));
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function updateSuperAdmin(session, values, callback) {
  // console.log('usertype',values)
  var body = { resource: { ...values } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        //  console.log('call',callback)
        callback({ success: 1 });
        dispatch({
          type: USER_NAME,
          payload: values.username,
        });
        dispatch({
          type: USER_PHONE,
          payload: values.phone,
        });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchRestrictedWords(session, pageCount = 0, callback) {
  var perPage = 7;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;
  var orderby = `createdon%20DESC`;

  var url = `${INSTANCE_URL}/api/v2/shopez/_table/content?include_count=true&filter=(id!=7)&${offSetQuery}&order=${orderby}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: RESTRICTED_WORDS,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchRestrictedwords(session, search = "", pageCount = 0) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${itemCount}&offset=${offset}`;
  var searchQuery = `(word_en like %${search}%) OR (word_ar like %${search}%)`;
  var filter = encodeURI(`(${searchQuery})`);
  // var url = `${INSTANCE_URL}/api/v2/eventify/_table/sponsor?related=membershiptype_by_categoryid,appuser_by_sponsoridfk&include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/content?include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;

  return (dispatch) => {
    // dispatch(fetchRestrictedWords(session));
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log("SPONSOR: ", data);
        dispatch({
          type: RESTRICTED_WORDS,
          payload: { data: data.resource, count: data.meta.count },
        });
      },
      (error) => {}
    );
  };
}

export function EditRestrictedWords(editwords) {
  return (dispatch) => {
    dispatch({ type: EDIT_RESTRICTED_WORDS, payload: editwords });
  };
}

export function uploadMediaLargeRestrictedWord(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else if (filetype == "image") {
    var blob = new Blob([filecontent], { type: "image/jpeg" });
  } else {
    var blob = new Blob([filecontent], { type: filetype });
  }

  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${BASE_IMAGES_URL}/contents/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function addRestrictedWords(session, restrictedwords, callback) {
  var body = { resource: { ...restrictedwords } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/content`;

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function updateRestrictedWords(session, restrictedwords, callback) {
  var body = { resource: { ...restrictedwords } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/content`;

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function addDeliveryAgents(session, deliveryagentAddData, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/adddeliveryservice`;
  return (dispatch) => {
    postRequest(
      url,
      {
        companyname: deliveryagentAddData.companyname,
        keycode: deliveryagentAddData.keycode,
        packages: deliveryagentAddData.packages,
        toedit: 0,
      },
      session,
      dispatch,
      (response) => {
        // console.log('responsesuccess',response)
        callback({ success: 1 });
      },
      (error) => {
        // console.log('error',error)
        callback({ success: 0 });
      }
    );
  };
}

export function editagents(editAgentdata = {}, session) {
  // console.log('editAgentdata',editAgentdata)
  if (Object.keys(editAgentdata).length > 0) {
    var DeliveryPackages = [];

    _.forEach(editAgentdata.packages, function (value) {
      var obj = {
        label: value.packagename_en,
        value: value.id.toString(),
        // value: value.value,
      };

      DeliveryPackages.push(obj);
    });
    editAgentdata["packagename"] = DeliveryPackages;
  }
  return (dispatch) => {
    dispatch({ type: EDIT_AGENTS, payload: editAgentdata });
  };
}

export function updateDeliveryAgents(session, deliveryagentAddData, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/adddeliveryservice`;
  return (dispatch) => {
    postRequest(
      url,
      {
        companyname: deliveryagentAddData.companyname,
        keycode: deliveryagentAddData.keycode,
        packages: deliveryagentAddData.packages,
        toedit: 1,
        isactive: 1,
        idcompany: deliveryagentAddData.idcompany,
      },
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}
export function Deactivate_Activate_Agents(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/adddeliveryservice`;
  return (dispatch) => {
    postRequest(
      url,
      {
        companyname: values.companyname,
        keycode: values.keycode,
        packages: values.packages,
        toedit: 1,
        isactive: values.isactive,
        idcompany: values.idcompany,
      },
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchShippingCharges(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/shipping_charges?related=*`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('SHIPPING_CHARGES', data);
        dispatch({
          type: SHIPPING_CHARGES,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function deleteCharges(session, values, callback) {
  var filter = encodeURI(`filter=(id=${values.id})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/shipping_charges?${filter}`;
  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (data) => {
        callback({ error: 0, result: data });
        dispatch(fetchShippingCharges(session));
      },
      (error) => {
        callback({ error: 1, message: error });
      }
    );
  };
}

export function postShippingCharges(session, data, callback) {
  var body = { resource: { ...data } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/shipping_charges`;

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function EditCharges(editcharges = {}) {
  return { type: EDIT_GLOBALCHARGES, payload: editcharges };
}

export function updateShippingCharges(session, data, callback) {
  var body = { resource: { ...data } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/shipping_charges`;

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function deleteProhibitedWords(session, values, callback) {
  var filter = encodeURI(`filter=(id=${values.id})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/content?${filter}`;
  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (data) => {
        callback({ error: 0, result: data });
        dispatch(fetchRestrictedWords(session));
      },
      (error) => {
        callback({ error: 1, message: error });
      }
    );
  };
}

export function emaildbCheck(email, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript?type=socialsignupavailable`;
  var params = { email: email };
  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        if (response.result == "success" && response.available === 1) {
          callback({
            success: 1,
          });
        } else {
          callback({
            success: 0,
          });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
        // Note - If available = 1 means the user already registered as a Vendor, Show error
      }
    );
  };
}

export function updateProductglobalbearerunset(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/globalbearerunset`;
  return (dispatch) => {
    postRequest(
      url,
      {
        session_token: session.session_token,
      },
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchRestrictedFile(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/content?include_count=true&filter=(id=7)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: RESTRICTED_FILE,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchZoneMaster(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/zone_master?filter=(isactive=1)&related=*&`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: ZONE_MASTER,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchCharityEvents(session, pageCount = 0, callback) {
  var perPage = 10;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;
  var orderby = `created_on%20DESC`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/charity_events?include_count=true&${offSetQuery}&order=${orderby}`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: CHARITY_EVENTS,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function addnewCharity(session, values, callback) {
  // console.log('dfdata',dfdata);
  let url = `${INSTANCE_URL}/api/v2/shopez/_table/charity_events`;
  var body = { resource: { ...values } };
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

export function editcharity(editcharity = {}) {
  return { type: EDIT_CHARITYEVENTS, payload: editcharity };
}

export function updatecharityEvents(session, values, callback) {
  var body = { resource: { ...values } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/charity_events`;

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchallModeratorrole(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/admin_role`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: ALLMODERATOR_ROLE,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function addAdminRoles(session, rolesArr, callback) {
  let url = `${INSTANCE_URL}/api/v2/shopez/_table/admin`;
  let body = { resource: { ...rolesArr } };
  console.log("body", body);
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, result: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function deleteAdminRoles(session, prevRolesArr, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/admin`;
  let body = { resource: { ...prevRolesArr } };
  return (dispatch) => {
    deleteBulkRequest(
      url,
      session,
      body,
      dispatch,
      (data) => {
        callback({ success: 1, result: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function uploadcharityImage(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else {
    var blob = new Blob([filecontent], { type: "image/jpeg" });
  }
  // console.log('blobimage',blob)
  dataMedia.append("files", blob, filename);
  // console.log('dataMedia',dataMedia)
  return (dispatch) => {
    uploadEncodedFileLarge(
      `${BASE_IMAGES_URL}/charity_events/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        // console.log('done?',data)
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}
