import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../languages/index.js";
import { Fragment } from "react";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import {
  updateAdmin,
  deleteAdminRoles,
  addAdminRoles,
  fetchModeratorAdmin,
} from "../../actions/index";
import { Toaster } from "react-hot-toast";
import { validateEmail } from "../../../../../common/constants";
import "react-phone-input-2/lib/material.css";
import _, { values } from "lodash";
import { NotificationManager } from "react-notifications";
import { classNames } from "../../../../../common/common-functions";

const gender = [
  { name: "Rather not say", value: "", index: 0 },
  { name: "MALE", value: "male", index: 1 },
  { name: "FEMALE", value: "female", index: 2 },
];

class EditAdmin extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      setPhone: "",
      genderOptions: gender,
      newGender: "",
      btnLoader: false,
      phone: "",
      country: "",
    };

    // All Binded Functions
  }

  onSubmitForm(data) {
    this.setState({ btnLoader: true });
    if (data.roles == "" || data.roles.length == 0) {
      NotificationManager.error("Please add roles to add admin");
      this.setState({ btnLoader: false });
      return false;
    }

    if (this.props.initialValues.roles == data.roles) {
      var values = { ...data };
      this.props.updateAdmin(this.props.session, values, (response) => {
        console.log("response :- ", response);
        if (response.success === 0) {
          this.setState({ btnLoader: false });
          NotificationManager.error(LocaleStrings.updatederror);
        } else {
          this.props.onClose();
          this.setState({ btnLoader: false });
          NotificationManager.success(LocaleStrings.updatedsuccessfully);
          // alert("success");
        }
      });
    } else {
      this.props.deleteAdminRoles(
        this.props.session,
        this.props.initialValues.admin_by_useridfk,
        (response) => {
          if (response.success === 0) {
            this.setState({ btnLoader: false });
            this.props.onClose();
            NotificationManager.success(LocaleStrings.updatedsuccessfully);
          } else {
            let rolesdataArr = [];
            if (data.roles.length > 0) {
              _.forEach(data.roles, function (value) {
                let obj = {
                  roleid: value.value,
                  useridfk: data.userid,
                };
                rolesdataArr.push(obj);
              });
            }
            this.props.addAdminRoles(
              this.props.session,
              rolesdataArr,
              (response) => {
                if (response.success === 0) {
                  this.setState({ btnLoader: false });
                  NotificationManager.error(LocaleStrings.addingfailed);
                } else {
                  let editdata = {
                    name: data.name,
                    phone: data.phone,
                    gender: data.gender,
                    userid: data.userid,
                  };
                  this.props.updateAdmin(
                    this.props.session,
                    editdata,
                    (response) => {
                      // console.log("response :- ", response);
                      if (response.success === 0) {
                        NotificationManager.error(LocaleStrings.updatederror);
                      } else {
                        this.setState({ btnLoader: false });
                        this.props.onClose();
                        NotificationManager.success(
                          LocaleStrings.updatedsuccessfully
                        );
                      }
                    }
                  );
                }
              }
            );
          }
        }
      );
    }
  }

  phoneChange = (value, country, e, formattedValue) => {
    this.setState({ phone: value, country: country.dialCode });
  };

  render() {
    var { handleSubmit, allModeratorrolelist } = this.props;
    let adminRoleopts = [];

    if (allModeratorrolelist && allModeratorrolelist.data.length > 0) {
      _.forEach(allModeratorrolelist.data, function (value) {
        var obj = {
          label: value.name,
          value: value.position.toString(),
        };

        adminRoleopts.push(obj);
      });
    }
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.myRef}
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="grid gap-4  grid-cols-2 mb-2 border-b-2 border-gray-50 pb-4">
                    <div className="flex flex-wrap mx-6">
                      {LocaleStrings.superadmin_Edit}
                    </div>
                    <div className="flex flex-wrap content-center justify-end">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}
                        ref={this.myRef}>
                        <span className="sr-only"></span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className="space-y-8 divide-y divide-gray-200 mt-5"
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                    encType="multipart/form-data">
                    <div>
                      <div className="border-transparent">
                        <div className="mb-5 mt-2">
                          <Field
                            name="roles"
                            label="Roles"
                            placeholder={LocaleStrings.select}
                            options={adminRoleopts}
                            component={this.renderAutoComplete}
                            labelposition={LABEL_POSITION_TOP}
                            labelKey="label"
                            valueKey="value"
                            className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                            classNameLabel="block text-box-lable"
                            isMulti={true}
                          />
                        </div>
                        <div className="mb-5">
                          <Field
                            name="email"
                            placeholder={LocaleStrings.email}
                            label="Email"
                            type="email"
                            isdisabled={true}
                            component={this.renderAllfields}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                          />
                        </div>
                        <div className="mb-5">
                          <Field
                            name="username"
                            placeholder={LocaleStrings.name}
                            label="Name"
                            type="text"
                            mandatory="true"
                            component={this.renderAllfields}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                          />
                        </div>
                        <div className="mb-5">
                          <Field
                            name={"phone"}
                            label={"Phone Number"}
                            mandatory="true"
                            type="text"
                            component={this.renderPhonewithFlag}
                            className="cust-input-field"
                            classNameLabel="text-box-lable"
                            phone={this.state.phone}
                            phoneChange={this.phoneChange}
                          />
                        </div>

                        <div></div>

                        <div className="mb-5">
                          <p>{LocaleStrings.gender}</p>
                          {_.map(this.state.genderOptions, (opt, index) => {
                            return (
                              <Field
                                name="gender"
                                label={opt.name}
                                value={opt.value}
                                //checked={index == 0 ? true : false}
                                // onChange={this._handleGenderchange.bind(this)}
                                lebelAlign="R"
                                type="radio"
                                component={this.renderFieldRadio1}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="pt-5">
                      <div className="flex justify-center">
                        <button
                          //   onClick={}
                          type="submit"
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 ">
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "sr-only",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          {LocaleStrings.save}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <Toaster limit={1} />
      </>
    );
  }
}
function validate(values) {
  // console.log("values", values);
  var errors = {};

  var email = values["email"];
  var username = values["username"];
  var dob = values["dob"];
  var phone = values["phone"];
  var password = values["password"];
  var new_password = values["new_password"];
  var requiredFields = ["email", "new_password", "password", "username"];
  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = "Required";
    }
  });

  if (values.email) {
    if (!validateEmail(values.email)) {
      errors.email = "Invalid email";
    }
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('session',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    initialValues: state.Edit_superadmin,
    allModeratorrolelist: state.allModeratorrolelist,
  };
}

export default connect(mapStateToProps, {
  updateAdmin,
  deleteAdminRoles,
  addAdminRoles,
  fetchModeratorAdmin,
})(
  reduxForm({
    validate,
    form: "EditadminForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EditAdmin)
);
