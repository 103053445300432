import {
  ALL_PRODUCT_LIST,
  ALL_PRODUCT_CATEGORY,
  ALL_PRODUCT_SUBCATEGORY,
  MAIN_HEADER_HOMEPAGE,
  MAIN_HOMEPAGE_BODY,
  HEADERUPDATE_DATA,
  PRODUCTUPDATE_DATA,
  BANNERUPDATE_DATA,
  HOME_PAGEBODY_REDUX,
  HOMEPAGE_MAINDATA,
} from "../actions/index";

export var productCard_type = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Grid", value: "grid", id: 0 },
    { name: "List View", value: "list", id: 1 },
    // { name: "Cards Slider", value: "slider", id: 2 },
  ];

  return state;
};
export var product_Limit = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: 6, value: 6, id: 0 },
    { name: 12, value: 12, id: 1 },
    { name: 18, value: 18, id: 2 },
    { name: 24, value: 24, id: 3 },
  ];

  return state;
};

export var productType = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Logical", value: "logical", id: 0 },
    { name: "Manual", value: "manual", id: 1 },
  ];

  return state;
};

export var allproductList = (state = {}, action) => {
  switch (action.type) {
    case ALL_PRODUCT_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var logicalProductadd = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "New Arrivals", value: "new_arrival", id: 0 },
    { name: "Hot Bids", value: "hot_bid", id: 1 },
    { name: "Top Discounts", value: "top_discount", id: 2 },
    { name: "Most Sold", value: "most_sold", id: 3 },
    { name: "Most Liked", value: "most_like", id: 4 },
  ];

  return state;
};

export var headerView = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Mobile View", value: "mobile", id: 0 },
    { name: "Web View", value: "web", id: 1 },
  ];

  return state;
};

export var linkImageTo = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "External", value: "external", id: 0 },
    { name: "Category", value: "category", id: 1 },
    { name: "Sub-Category", value: "subcategory", id: 2 },
    { name: "Product", value: "product", id: 3 },
  ];

  return state;
};

export var allcategoryList = (state = {}, action) => {
  switch (action.type) {
    case ALL_PRODUCT_CATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var allsubcategoryList = (state = {}, action) => {
  switch (action.type) {
    case ALL_PRODUCT_SUBCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var homePageMainheader = (state = {}, action) => {
  switch (action.type) {
    case MAIN_HEADER_HOMEPAGE:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var homePageBody = (state = {}, action) => {
  switch (action.type) {
    case MAIN_HOMEPAGE_BODY:
      console.log("mainhomeredux", action);
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var headerDataupdate = (state = {}, action) => {
  switch (action.type) {
    case HEADERUPDATE_DATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var productDataupdate = (state = {}, action) => {
  switch (action.type) {
    case PRODUCTUPDATE_DATA:
      console.log("action", action);
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var bannerDataupdate = (state = {}, action) => {
  switch (action.type) {
    case BANNERUPDATE_DATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var bannerType = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Full Width Banner", value: "full_width", id: 0 },
    { name: "Half Width Banner", value: "half_width", id: 1 },
  ];

  return state;
};

export var homePagebodyRedux = (state = {}, action) => {
  switch (action.type) {
    case HOME_PAGEBODY_REDUX:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var homePagemaindata = (state = {}, action) => {
  switch (action.type) {
    case HOMEPAGE_MAINDATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
