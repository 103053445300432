import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";

import { SearchIcon } from "@heroicons/react/solid";
import WishlistItems from "./wishlist-items";
import _ from "lodash";
import { fetchWishlist, searchwishlist } from "../../../actions/index.js";
import toast, { Toaster } from "react-hot-toast";
import Pagination from "react-js-pagination";
import BaseLoader from "../../../../../../common/base-loader";

var Loader = require("react-loaders").Loader; // import "AwesomeLoaderCss";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class WishList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      pageNumber: 1,
      search: "",
      loaderDesc: "Preparing Wishlist",
      open: false,
      setOpen: false,
      wishlistSearchText: "",
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount(data) {
    this.props.fetchWishlist(
      this.props.session,
      this.props.data.userid,
      this.state.pageNumber,
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }
  paginationCallback = (pageNumber) => {
    // alert(pageNumber)
    this.props.fetchWishlist(
      this.props.session,
      this.props.data.userid,
      pageNumber,
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchWishlist(
        this.props.session,
        this.props.data.userid,
        this.state.pageNumber,
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchwishlist(
        this.props.session,
        this.props.data.userid,
        search
      );
    }
    this.setState({ search, pageNumber });
  };

  renderList() {
    let pageDataLength = this.props.wishlistlist.data.length;

    let { pageNumber } = this.state;
    var items = _.map(this.props.wishlistlist.data, (values) => (
      <WishlistItems
        key={values.userid}
        values={values}
        data={this.props.data}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div className="py-6">
              {/* Header Part */}
              <div class="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-3 ">
                <div className="justify-items-center">
                  <label htmlFor="search-field" className="sr-only">
                    {LocaleStrings.search}
                  </label>
                  <div className="relative w-full text-ternary focus-within:text-secondary ">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                      placeholder={LocaleStrings.search}
                      type="search"
                      name="search"
                      value={this.state.search}
                      onChange={this.handleSearch}
                    />
                  </div>
                </div>
              </div>
            </div>
            {_.isEmpty(this.props.wishlistlist.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.nowishlistadded}
                </div>
              </>
            ) : (
              <>
                {/* <div className="py-6">
                 
                  <div class="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-3 ">
                    <div className="justify-items-center">
                      <label htmlFor="search-field" className="sr-only">
                        {LocaleStrings.search}
                      </label>
                      <div className="relative w-full text-ternary focus-within:text-secondary ">
                        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                          <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                          id="search-field"
                          className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                          placeholder={LocaleStrings.search}
                          type="search"
                          name="search"
                          value={this.state.search}
                          onChange={this.handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.wishlist}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.privacy}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.iteminlist}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.action}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.renderList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <Pagination
                  activePage={this.state.pageNumber}
                  itemsCountPerPage={10}
                  totalItemsCount={this.props.wishlistlist.count}
                  pageRangeDisplayed={10}
                  onChange={this.paginationCallback}
                />
                <Toaster limit={1} />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // console.log('state.wishlistlist', state.wishlistlist);
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    wishlistlist: state.wishlistlist,
    data: ownProps.data,
    language: state.language,
  };
}

export default connect(mapStateToProps, {
  fetchWishlist,
  searchwishlist,
})(WishList);
