import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import { fetchTransaction, searchTransaction } from "../../../actions/index";
import _ from "lodash";
import TransactionListItems from "./transactionlist-items";
import Pagination from "react-js-pagination";
import BaseLoader from "../../../../../../common/base-loader";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class TransactionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: "Preparing Transaction",
      pageNumber: 1,
      search: "",
    };

    // All Binded Functions
  }

  componentDidMount(data) {
    this.props.fetchTransaction(
      this.props.session,
      this.props.data.userid,
      this.state.pageNumber,
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  paginationCallback = (pageNumber) => {
    // alert(pageNumber)
    this.props.fetchTransaction(
      this.props.session,
      this.props.data.userid,
      pageNumber,
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchTransaction(
        this.props.session,
        this.props.data.userid,
        this.state.pageNumber,
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchTransaction(
        this.props.session,
        this.props.data.userid,
        search
      );
    }
    this.setState({ search, pageNumber });
  };

  renderList() {
    let pageDataLength = this.props.transactionList.data.length;

    let { pageNumber } = this.state;
    var items = _.map(this.props.transactionList.data, (values) => (
      <TransactionListItems
        key={values.userid}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }
  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div className="py-6">
              {/* Header Part */}
              <div class="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-3 ">
                <div className="justify-items-center">
                  <label htmlFor="search-field" className="sr-only">
                    {LocaleStrings.search}
                  </label>
                  <div className="relative w-full text-ternary focus-within:text-secondary ">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                      placeholder={LocaleStrings.search}
                      type="search"
                      name="search"
                      value={this.state.search}
                      onChange={this.handleSearch}
                    />
                  </div>
                </div>
              </div>
            </div>
            {_.isEmpty(this.props.transactionList.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.notransactionadded}
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.transactionid}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.transactionamount}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.product}
                              </th>

                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.transactiondate}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.status}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.renderList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Pagination
                    activePage={this.state.pageNumber}
                    itemsCountPerPage={10}
                    totalItemsCount={this.props.transactionList.count}
                    pageRangeDisplayed={10}
                    onChange={this.paginationCallback}
                  />
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // console.log('statesss', state.transactionList);
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    data: ownProps.data,
    transactionList: state.transactionList,
  };
}

export default connect(mapStateToProps, {
  fetchTransaction,
  searchTransaction,
})(TransactionList);
