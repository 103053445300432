import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteSavedItems } from "../../../actions/index";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
} from "../../../../../../common/constants";
import { ProductTextLocalized } from "../../../../../../common/common-functions";
import { currencyCode, currencyRate } from "../../../../currency/action/index";

class NegotiationrejectedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
    };

    // All Binded Functions
    // this.closeModal = this.closeModal.bind(this);
    // this.openModal = this.openModal.bind(this);
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  render() {
    var { values, language, localCurrency } = this.props;
    // console.log("nego", values);
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    var img = `${BASE_IMAGES_URL}/vendor/variations/${values.productimage}?api_key=${APP_API_KEY}`;
    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50">
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ display: "flex" }}>
            <div className="mr-2">
              <img src={img} style={{ height: "40px", width: "40px" }} />
            </div>
            <div>
              <div>
                {ProductTextLocalized(
                  values.productname_en.length > 35
                    ? values.productname_en.substring(0, 25) + "..."
                    : values.productname_en,
                  values.productname_ar.length > 35
                    ? values.productname_ar.substring(0, 25) + "..."
                    : values.productname_ar,
                  language
                )}

                {/* {values.productname_en.length > 80 ? values.productname_en.substring(1,40)+'...' : values.productname_en} */}
              </div>
              <div className="text-xs text-ternary">
                {ProductTextLocalized(
                  values.product_desc_en.length > 80
                    ? values.product_desc_en.substring(0, 40) + "..."
                    : values.product_desc_en,
                  values.product_desc_ar.length > 80
                    ? values.product_desc_ar.substring(0, 30) + "..."
                    : values.product_desc_ar,
                  language
                )}
              </div>
              {/* <div>{values.productname_en.length > 80 ? values.productname_en.substring(1,40)+'...' : values.productname_en}</div>
          
          <div className="text-xs text-ternary">{values.product_desc_en.length > 80 ? values.product_desc_en.substring(1,40)+'...' : values.product_desc_en}</div> */}
            </div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ cursor: "pointer" }}>
            <div>
              {/* ${parseFloat(values.price).toFixed(2) } */}
              {currency} {parseFloat(values.price * currencyrate).toFixed(2)}
            </div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ cursor: "pointer" }}>
            <div>
              {currency}{" "}
              {parseFloat(values.originalprice * currencyrate).toFixed(2)}
              {/* ${parseFloat(values.originalprice).toFixed(2) } */}
            </div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ cursor: "pointer" }}>
            <div>{values.attempts}/5</div>
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            style={{ cursor: "pointer" }}>
            <div>{values.createdon}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div>Rejected</div>
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
    localCurrency: state.currency,
  };
}
export default connect(mapStateToProps, { deleteSavedItems })(
  NegotiationrejectedItems
);
