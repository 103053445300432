import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import { isForgotPasswordOpen } from "../../../../../../actions/index";
import { fetchOderlist } from "../../../actions/index";
import _ from "lodash";
import Newoderitemslist from "./newoder-items";
import BaseLoader from "../../../../../../common/base-loader";

var Loader = require("react-loaders").Loader; // import "AwesomeLoaderCss";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Newoderitems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      open: false,
      setOpen: false,
      newOrderSearch: "",
      loaderDesc: "Preparing NewOrders",
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }

  componentDidMount(data) {
    this.props.fetchOderlist(
      this.props.session,
      this.props.data.userid,
      "new",
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  renderList() {
    let searchText = this.state.newOrderSearch;
    let filtered = [];

    if (this.state.newOrderSearch !== "") {
      // If Search Key Found
      let listing = this.props.oderlist.data;

      filtered = _.filter(listing, function (o) {
        return o.invoice_no.toLowerCase().indexOf(_.toLower(searchText)) > -1;
      });
    } else {
      filtered = this.props.oderlist.data;
    }
    var items = _.map(filtered, (values) => (
      <Newoderitemslist key={values.id} values={values} />
    ));
    //  console.log('check', this.state.pageNumber);
    return items;
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {_.isEmpty(this.props.oderlist.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  No New Orders
                </div>
              </>
            ) : (
              <>
                <div className="relative  text-ternary focus-within:text-secondary ">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    className="block lg:w-3/12 sm:w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                    placeholder={LocaleStrings.search}
                    type="search"
                    name="search"
                    onChange={(e) =>
                      this.setState({ newOrderSearch: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col mt-5">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.oderid}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.odertotal}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.odererby}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.oderon}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.status}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.items}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.renderList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // console.log("statesss", state.oderlist);
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    oderlist: state.oderlist,
    data: ownProps.data,
  };
}
export default connect(mapStateToProps, {
  isForgotPasswordOpen,
  fetchOderlist,
})(Newoderitems);
