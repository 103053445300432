import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../languages/index";
import { Fragment } from "react";
import { BaseComponent } from "../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import { Toaster } from "react-hot-toast";
import "react-phone-input-2/lib/material.css";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { classNames } from "../../../../../common/common-functions";
import {
  markrejectProducts,
  fetchunapproveProductlist,
} from "../../actions/index";

class RejectProductModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      btnLoader: false,
    };

    // All Binded Functions
  }

  onSubmitForm(values) {
    this.setState({ btnLoader: true });
    if (this.props.data) {
      this.props.markrejectProducts(
        this.props.session,
        this.props.data.id,
        values.reject_reason,
        (resource) => {
          if (resource.success == 1) {
            this.setState({ btnLoader: false });
            this.props.onClose();
            NotificationManager.success(
              LocaleStrings.prod_mangement_rejected_success
            );
            this.props.pagination();
            // this.props.fetchunapproveProductlist(
            //   this.props.session,
            //   1,
            //   (CallBackResponse) => {}
            // );
          } else {
            this.setState({ btnLoader: false });
            this.props.onClose();
            NotificationManager.error("Something went wrong");
          }
        }
      );
    }
  }

  render() {
    var { handleSubmit } = this.props;
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.myRef}
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="grid grid-cols-2 mb-10- border-b-2 border-gray-50 pb-4">
                    <div className="flex flex-wrap content-center justify-center">
                      Reject Unapproved Product
                    </div>
                    <div className="flex flex-wrap content-center justify-end">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}
                        ref={this.myRef}>
                        <span className="sr-only"></span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className="space-y-8  mt-5"
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                    encType="multipart/form-data">
                    <div>
                      <div className="border-transparent">
                        <div className="mb-5">
                          {/* <Field
                            name="reject_reason"
                            label="Reject Reason"
                            placeholder="Add reject reason"
                            type="textarea"
                            mandatory="true"
                            component={this.renderFieldTextarea}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                          /> */}
                          <Field
                            name={"reject_reason"}
                            label="Reject Reason"
                            placeholder="Add reject reason"
                            mandtory="true"
                            type="text"
                            component={this.renderFieldTextarea}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm h-12"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pt-5">
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 ">
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "sr-only",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}
function validate(values) {
  var errors = {};
  var requiredFields = ["reject_reason"];

  requiredFields.forEach((field) => {
    // if (typeof values[field] != 'undefined'){
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
    // }
  });

  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    data: ownProps.data,
  };
}

export default connect(mapStateToProps, {
  markrejectProducts,
  fetchunapproveProductlist,
})(
  reduxForm({
    validate,
    form: "RejectProductModalForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(RejectProductModal)
);
