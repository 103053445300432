import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index";
import { SearchIcon } from "@heroicons/react/solid";
import { fetchWatchlist, searchWatchlist } from "../../../actions";
import Watchlistproducts from "./watchlist-items";
import _ from "lodash";
import Pagination from "react-js-pagination";
import BaseLoader from "../../../../../../common/base-loader";
import { NotificationContainer } from "react-notifications";
var Loader = require("react-loaders").Loader; // import "AwesomeLoaderCss";

class WatchList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      pageNumber: 1,
      search: "",
      loaderDesc: "Preparing Watchlist",
    };

    // All Binded Functions
  }

  componentDidMount(data) {
    this.props.fetchWatchlist(
      this.props.session,
      this.props.data.userid,
      this.state.pageNumber,
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  paginationCallback = (pageNumber) => {
    // alert(pageNumber)
    this.props.fetchWatchlist(
      this.props.session,
      this.props.data.userid,
      pageNumber,
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };
  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchWatchlist(
        this.props.session,
        this.props.data.userid,
        this.state.pageNumber,
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchWatchlist(
        this.props.session,
        this.props.data.userid,
        search
      );
    }
    this.setState({ search, pageNumber });
  };
  renderProductList() {
    let pageDataLength = this.props.watchlist.data.length;

    let { pageNumber } = this.state;
    // return <Watchlistproducts data={this.props.watchlist.data}/>
    var items = _.map(this.props.watchlist.data, (values) => (
      <Watchlistproducts
        key={values.userid}
        values={values}
        data={this.props.data}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div class="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-3 mt-5">
              <div className="justify-items-center">
                <label htmlFor="search-field" className="sr-only">
                  {LocaleStrings.search}
                </label>
                <div className="relative w-full text-ternary focus-within:text-secondary ">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                    placeholder={LocaleStrings.search}
                    type="search"
                    name="search"
                    value={this.state.search}
                    onChange={this.handleSearch}
                  />
                </div>
              </div>
            </div>
            {_.isEmpty(this.props.watchlist.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.nowatchlistadded}
                </div>
              </>
            ) : (
              <>
                <div className="py-6">
                  {/* Header Part */}
                  {/* <div class="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-3 ">
                    <div className="justify-items-center">
                      <label htmlFor="search-field" className="sr-only">
                        {LocaleStrings.search}
                      </label>
                      <div className="relative w-full text-ternary focus-within:text-secondary ">
                        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                          <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                          id="search-field"
                          className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                          placeholder={LocaleStrings.search}
                          type="search"
                          name="search"
                          value={this.state.search}
                          onChange={this.handleSearch}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className=" max-w-lg mx-auto grid gap-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 lg:max-w-none">
                  {this.renderProductList()}
                </div>
                <Pagination
                  activePage={this.state.pageNumber}
                  itemsCountPerPage={10}
                  totalItemsCount={this.props.watchlist.count}
                  pageRangeDisplayed={10}
                  onChange={this.paginationCallback}
                />
                <NotificationContainer />
              </>
            )}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state, ownProps) {
  // console.log("state.watchlist", state.watchlist);
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    watchlist: state.watchlist,
    data: ownProps.data,
  };
}

export default connect(mapStateToProps, {
  fetchWatchlist,
  searchWatchlist,
})(WatchList);
