import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "./../../../../languages/index";
import { Field, reduxForm } from "redux-form";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import _ from "lodash";
import { fetchChartValues } from "../../actions/index";
import { Chart } from "react-google-charts";

class BarChartJS extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
    // this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  render() {
    if (_.isEmpty(this.props.chartValues.data.listed)) {
      var sold = parseInt(this.props.chartValues.data.sold[0].count);
      var data = [
        ["", ""],
        ["Product Sold", sold],
        ["Product Listed", ""],
      ];
    } else if (_.isEmpty(this.props.chartValues.data.sold)) {
      var listed = parseInt(this.props.chartValues.data.listed[0].count);
      var data = [
        ["", ""],
        ["Product Sold", ""],
        ["Product Listed", listed],
      ];
    } else {
      // console.log('1')
      var sold = this.props.chartValues.data.sold;
      var listed = this.props.chartValues.data.listed;
      let totalSold = 0;
      let totalListed = 0;
      sold.forEach((item) => {
        totalSold += item.count;
      });
      listed.forEach((item) => {
        totalListed += item.count;
      });

      var data = [
        ["", ""],
        ["Product Sold", totalSold],
        ["Product Listed", totalListed],
      ];
    }
    // Chart options to disable legend and tooltip
    const options = {
      legend: { position: "none" },
    };
    return (
      <div className="App">
        <Chart
          chartType="ColumnChart"
          data={data}
          options={options} // Pass options here
          width="100%"
          height="400px"
          legendToggle
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log('chartValueschartValueschartValues',state.chartValues)
  // initVals = {};

  return {
    session,
    user,
    chartValues: state.chartValues,
  };
}

export default connect(
  mapStateToProps,
  {}
)(
  reduxForm({
    // validate,
    form: "BarChartJSForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(BarChartJS)
);
