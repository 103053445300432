import LocaleStrings from "../../../languages/index";
import {
  ALL_CANCELLEDORDER_LIST,
  All_RETURNEDORDER_LIST,
  All_REPLACEORDER_LIST,
  AUCTIONITESM_WONNOTBOUGHT,
  ORDERSNOT_PROCESSED,
} from "../actions";

export var DisputeMangementmainTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    {
      name: LocaleStrings.returned,
      href: "return",
      current: true,
      tabindex: 1,
    },
    {
      name: LocaleStrings.cancelled,
      href: "cancelled",
      current: false,
      tabindex: 2,
    },
    { name: "Replace", href: "replace", current: false, tabindex: 3 },
    {
      name: "Orders Not Processed",
      href: "ordersnotprocessed",
      current: false,
      tabindex: 4,
    },
    {
      name: "Auction Items Not Bought",
      href: "auctionitemsnotbought",
      current: false,
      tabindex: 4,
    },
    // { name: "Refund Rejected", href: '#', current: false,tabindex:4 },
  ];

  return state;
};

export function allcancelledorderlist(state = {}, action) {
  switch (action.type) {
    case ALL_CANCELLEDORDER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allreturnedorderlist(state = {}, action) {
  switch (action.type) {
    case All_RETURNEDORDER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function allreplaceorderlist(state = {}, action) {
  switch (action.type) {
    case All_REPLACEORDER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function auctionsitemnotboughtList(state = {}, action) {
  switch (action.type) {
    case AUCTIONITESM_WONNOTBOUGHT:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function ordersnotProcessedlist(state = {}, action) {
  switch (action.type) {
    case ORDERSNOT_PROCESSED:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
