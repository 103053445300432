import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

class QuestionAnswers extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
         
          
        };
        
        // All Binded Functions  

    }
   componentDidMount (){
       
   }




    render () {
       var { newqa,reportedOn ,reportedObject} = this.props;
    
       var questionStyle = {};
       var answerStyle = {};
       if(reportedOn == 'answer' && reportedObject.qa.id == newqa.id){
       answerStyle = {
          color:'#FF0000'
        };
       }
       if(reportedOn == 'question' && reportedObject.qa.id == newqa.id){
         questionStyle = {
          color:'#FF0000'
        };
       }
      //  ../images/product_management_images/add.svg
        return (
               <>
               <div className="mb-4" style={{display:'flex'}}>
                 <div className="mt-1"><img src="../images/product_management_images/qa.svg"/></div>
               <div className="">
               <div className="ml-3 flex">
                 <p style={questionStyle}>{newqa.question}</p>
                 
                 {/* {reportedOn == 'question' && reportedObject.qa.id == newqa.id ?(
                   <img src="../images/warning.png" aria-hidden="true" style={{height: '25px', width: '25px',marginLeft: '10px'}}/>
                 ):('')} */}
                 </div>
                 <div className="ml-3 flex">
               <p className=" text-xs" style={answerStyle}>{newqa.answer}</p>
               {/* {reportedOn == 'answer' && reportedObject.qa.id == newqa.id ?(
                   <img src="../images/warning.png" aria-hidden="true" style={{height: '25px', width: '25px', marginLeft: '10px'}}/>
                 ):('')} */}
                </div>
               </div>
               </div>
               </>

        );
    }

}


function mapStateToProps(state) {
    var { session, user } = state;
    // console.log('session',state.wishlistlist);
    return {
        session,
        user,
        isLoggedIn: state.isLoggedIn,

    };
}

export default connect(mapStateToProps, {
 
}) (reduxForm({
    // validate,
    form:'EditcustomerForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }) (QuestionAnswers));