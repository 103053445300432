import LocaleStrings from "../../../languages/index";
// Store vendor Product list
import {
  SELLER_LIST,
  SELLER_TABID,
  SELLER_NEWS,
  EDIT_SELLERMODAL,
  SELLER_PRODUCT_LIST,
  SELLER_ODER_LIST,
  SELLERDELIVER_ODER_LIST,
  SELLERDISPUTED_ODER_LIST,
  NEGOTIATIONSELLER_LIST,
  NEGOTIATIONSELLER_INITIATED_LIST,
  NEGOTIATIONSELLER_CLOSED_LIST,
  STORE_DETAILS,
  NEGOTIATIONSELLERDETAILS_LIST,
  IS_NEGO_SLIDEOVERS_MODAL_OPEN,
  PROFILESETTINGS_STORECONTACTS,
  SIMILAR_PRODUCTS,
  IS_PRODUCT_COMMENTS_MODAL_OPEN,
  COMMENT_LIST,
  TRANSACTION_LIST_SELLER,
  SELLER_PRODUCT_LIST_SALE,
  SELLER_PRODUCT_LIST_AUCTION,
  IS_PRODUCT_LIKES_MODAL_OPEN,
  PRODUCTLIKES_LIST,
  SELLER_AUCTION_LIST,
  SELLER_AUCTION_LIST_WON,
  SINGLESELLER,
} from "../actions";

export function sellerlist(state = {}, action) {
  switch (action.type) {
    case SELLER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}

export var verifiedUnverifiedsellerTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "Verified", href: "verified", current: true, tabindex: 1 },
    {
      name: "Verification Requested",
      href: "verficationrequested",
      current: true,
      tabindex: 2,
    },
    { name: "Unverified", href: "unverified", current: false, tabindex: 3 },
  ];

  return state;
};

export var sellermanagementTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.about, href: "#", current: true, tabindex: 1 },
    {
      name: LocaleStrings.storeprofile,
      href: "#",
      current: false,
      tabindex: 2,
    },
    { name: LocaleStrings.product, href: "#", current: false, tabindex: 3 },
    { name: LocaleStrings.oders, href: "#", current: false, tabindex: 4 },
    // { name: LocaleStrings.auctions, href: '#', current: false,tabindex:5 },
    { name: LocaleStrings.negotiation, href: "#", current: false, tabindex: 5 },
    { name: LocaleStrings.transaction, href: "#", current: false, tabindex: 6 },
    // { name: LocaleStrings.transaction, href: '#', current: false,tabindex:7 },
  ];

  return state;
};
export var selleraboutTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.profile, href: "#", current: true, tabindex: 1 },
    {
      name: LocaleStrings.legaldocuments,
      href: "#",
      current: false,
      tabindex: 2,
    },
    { name: "Tax and Commision", href: "#", current: false, tabindex: 3 },
    { name: "Refund", href: "#", current: false, tabindex: 4 },
  ];

  return state;
};
export var sellerproductTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.allitems, href: "#", current: true, tabindex: 1 },
    { name: LocaleStrings.forsale, href: "#", current: false, tabindex: 2 },
    { name: LocaleStrings.forauction, href: "#", current: false, tabindex: 3 },
  ];

  return state;
};
export var sellernegotiationTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    {
      name: LocaleStrings.initiatedseller,
      href: "#",
      current: true,
      tabindex: 1,
    },
    {
      name: LocaleStrings.closedseller,
      href: "#",
      current: false,
      tabindex: 2,
    },
    {
      name: LocaleStrings.negotiatedeller,
      href: "#",
      current: false,
      tabindex: 3,
    },
  ];

  return state;
};

export var selleroderTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.new, href: "#", current: true, tabindex: 1 },
    { name: LocaleStrings.delivered, href: "#", current: false, tabindex: 2 },
    { name: LocaleStrings.disputed, href: "#", current: false, tabindex: 3 },
    // { name: LocaleStrings.replaced, href: '#', current: false,tabindex:4 },
    // { name: LocaleStrings.cancelled, href: '#', current: false,tabindex:5 },
  ];

  return state;
};
export var sellerdisputedorderTabs = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.cancelled, href: "#", current: true, tabindex: 1 },
    { name: LocaleStrings.returned, href: "#", current: false, tabindex: 2 },
    { name: LocaleStrings.replaced, href: "#", current: false, tabindex: 3 },
  ];

  return state;
};

export function sellerlistingtabs(state = {}, action) {
  switch (action.type) {
    case SELLER_TABID:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}

export function newtabsellerlist(state = {}, action) {
  switch (action.type) {
    case SELLER_NEWS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}

export function sellereditmodal(state = {}, action) {
  switch (action.type) {
    case EDIT_SELLERMODAL:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}

export function sellerproductlist(state = {}, action) {
  switch (action.type) {
    case SELLER_PRODUCT_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function sellernewoderlist(state = {}, action) {
  switch (action.type) {
    case SELLER_ODER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function sellerdeliveredoderlist(state = {}, action) {
  switch (action.type) {
    case SELLERDELIVER_ODER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function sellerdisputedoderlist(state = {}, action) {
  switch (action.type) {
    case SELLERDISPUTED_ODER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function sellernegotiationlist(state = {}, action) {
  switch (action.type) {
    case NEGOTIATIONSELLER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function sellernegotiationlistinitiated(state = {}, action) {
  switch (action.type) {
    case NEGOTIATIONSELLER_INITIATED_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function sellernegotiationlistclosed(state = {}, action) {
  switch (action.type) {
    case NEGOTIATIONSELLER_CLOSED_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function sellerstoredetails(state = {}, action) {
  switch (action.type) {
    case STORE_DETAILS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function sellerSimilarProducts(state = {}, action) {
  switch (action.type) {
    case SIMILAR_PRODUCTS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function sellernegotationdetails(state = {}, action) {
  switch (action.type) {
    case NEGOTIATIONSELLERDETAILS_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export var isNegotiationopen = (state = false, action) => {
  if (action.type === IS_NEGO_SLIDEOVERS_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

export var StoreContacts = (state = {}, action) => {
  if (action.type == PROFILESETTINGS_STORECONTACTS) {
    state = action.payload;
  }
  return state;
};

export var isCommentsOpen = (state = false, action) => {
  if (action.type === IS_PRODUCT_COMMENTS_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

// Comment List Data
export var commentList = (state = {}, action) => {
  if (action.type === COMMENT_LIST) {
    state = action.payload;
  }

  return state;
};
// Comment List Data
export var sellerTransactionList = (state = {}, action) => {
  if (action.type === TRANSACTION_LIST_SELLER) {
    state = action.payload;
  }

  return state;
};
export var forSaleProductsList = (state = {}, action) => {
  if (action.type === SELLER_PRODUCT_LIST_SALE) {
    state = action.payload;
  }

  return state;
};
export var forAuctionProductList = (state = {}, action) => {
  if (action.type === SELLER_PRODUCT_LIST_AUCTION) {
    state = action.payload;
  }

  return state;
};
export var sellerAuctionlist = (state = {}, action) => {
  if (action.type === SELLER_AUCTION_LIST) {
    state = action.payload;
  }

  return state;
};
export var sellerAuctionlistwon = (state = {}, action) => {
  if (action.type === SELLER_AUCTION_LIST_WON) {
    state = action.payload;
  }

  return state;
};

/***** Likes Modal Modal *****/
export var isLikesopen = (state = false, action) => {
  if (action.type === IS_PRODUCT_LIKES_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

// Product List Data
export var productlikesData = (state = {}, action) => {
  if (action.type === PRODUCTLIKES_LIST) {
    state = action.payload;
  }

  return state;
};

export var AuctionTabsseller = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: LocaleStrings.participated, href: "#", current: true, tabindex: 1 },
    { name: LocaleStrings.won, href: "#", current: false, tabindex: 2 },
  ];

  return state;
};

export function singleSellerData(state = {}, action) {
  switch (action.type) {
    case SINGLESELLER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
