import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import moment from "moment-timezone";
import _ from "lodash";
import {
  getIntToFloat,
  ProductTextLocalized,
} from "../../../../../common/common-functions";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../../common/constants";
import {
  editsubcategory,
  deletesubCategory,
  activeInactivesubCategory,
  fetchsubCategory,
  checkifProductUnderCategory,
  getrelatedCountcatsubcat,
  activatedeactiveCatsubcat,
} from "../../actions/index";
import EditsubcategoryModal from "./editsubcategory";
import Addsubcategory from "../addsubcategory";
import LocaleStrings from "../../../../languages/index.js";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";

class SubCategorylistItems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };
  }
  componentWillReceiveProps() {}

  openModal = (e, obj) => {
    this.props.editsubcategory(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  inactiveSubCategoryBtnClick(e, values, type) {
    // console.log('inactiveSubCategoryBtnClick',values)
    // this.props.checkifProductUnderCategory(
    //   this.props.session,
    //   values.id,
    //   'subcategory',
    //   (CallBackResponse) => {

    //   if(CallBackResponse.data.canenable == false){
    //     // console.log('hello')
    //     toast.error("Cannot Deactivate Category");
    //   }else {

    let countParams = {
      subcategoryid: values.id,
      isactive: 0,
      subtype: type,
    };
    this.props.getrelatedCountcatsubcat(
      this.props.session,
      countParams,
      (callback) => {
        if (callback.success != 0) {
          let counponcount = callback?.data?.coupon_count;
          let discountcount = callback?.data?.discount_count;
          let product_count = callback?.data?.product_count;
          let subsubcategory_count = callback?.data?.subsubcategory_count;
          let specification_count = callback?.data?.specification_count;

          let title = "Are you sure you want to deactivate this subcategory? ";

          if (
            parseInt(counponcount) > 0 ||
            parseInt(discountcount) > 0 ||
            parseInt(product_count) > 0 ||
            parseInt(subsubcategory_count) > 0 ||
            parseInt(specification_count) > 0
          ) {
            title = title + "This subcategory is already associated with ";
          }

          if (parseInt(counponcount) > 0) {
            title = title + `${counponcount} Coupon(s)`;
          }
          if (parseInt(discountcount) > 0) {
            title = title + `, ${discountcount} Discount(s)`;
          }
          if (parseInt(product_count) > 0) {
            title = title + `, ${product_count} Product(s)`;
          }

          if (parseInt(subsubcategory_count) > 0) {
            title = title + `, ${subsubcategory_count} Sub-Subcategory(s)`;
          }
          if (parseInt(specification_count) > 0) {
            title = title + `, ${specification_count} Specification(s)`;
          }

          confirmAlert({
            title: "Deactivate SubCategory",
            message: title,
            buttons: [
              {
                label: LocaleStrings.yes,
                onClick: () => {
                  // var id = values;
                  // let body = {
                  //   resource: {
                  //     id: values.id,
                  //     categoryid: values.categoryid,
                  //     isactive: 0,
                  //   },
                  // };

                  let body = {
                    subcategoryid: values.id,
                    isactive: 0,
                    subtype: type,
                  };
                  this.props.activatedeactiveCatsubcat(
                    this.props.session,
                    body,
                    (resource) => {
                      // console.log("showresource :- ", resource);
                      if (resource.error) {
                        toast.error("Error in inactivating");
                      } else {
                        toast.success("Deactivate Successfully");
                        this.props.fetchsubCategory(
                          this.props.session,
                          this.props.subcategorymodalstore.id,
                          (CallBackResponse) => {}
                        );
                      }
                    }
                  );
                },
              },
              {
                label: LocaleStrings.no,
                onClick: () => {},
              },
            ],
          });
        }
      }
    );

    //   }
    //   }
    // );
  }
  activeSubCategoryBtnClick(e, values, type) {
    // console.log('inactiveSubCategoryBtnClick',values)
    confirmAlert({
      title: "Activate SubCategory",
      message: "Are you sure you want to active category",
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            var id = values;
            // let body = {
            //   resource: {
            //     id: values.id,
            //     categoryid: values.categoryid,
            //     isactive: 1,
            //   },
            // };
            let body = { subcategoryid: values.id, isactive: 1, subtype: type };
            this.props.activatedeactiveCatsubcat(
              this.props.session,
              body,
              (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error) {
                  toast.error("Error in activating");
                } else {
                  toast.success("Successfully activated");
                  this.props.fetchsubCategory(
                    this.props.session,
                    this.props.subcategorymodalstore.id,
                    (CallBackResponse) => {}
                  );
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { values, language } = this.props;
    // console.log("check2", values);

    return (
      <>
        {values.isactive == true ? (
          <>
            {values.subcategories.length == 0 ? (
              <>
                <div className="grid grid-cols-2 border-b-2 border-gray-50 px-5 py-4">
                  <div>{values.subcategoryname_en}</div>
                  <div className="end-item" style={{ display: "flex" }}>
                    <div className="">
                      <button
                        className="button"
                        type="button"
                        onClick={(e) => this.openModal(e, values)}>
                        Edit
                      </button>
                      {this.state.isOpen ? (
                        <EditsubcategoryModal
                          isOpen={this.state.isOpen}
                          onClose={this.closeModal}
                          data={this.props.values}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="ml-3">
                      <button
                        className="button"
                        onClick={(e) =>
                          this.inactiveSubCategoryBtnClick(e, values, "sub")
                        }>
                        Deactivate
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* <div className="end-item"></div> */}
                <div className="border-b-2 border-gray-50 px-5 py-4 h-60">
                  {/* <div className="grid grid-cols-2 border-b-2 border-gray-50 px-5 py-4"> */}
                  <div className="grid grid-cols-2 ">
                    <div>
                      {" "}
                      <p>{values.subcategoryname_en}:</p>
                    </div>
                    {/* <div className="subcat-multi overflow-x-auto  scrollbar-hide">
                    <p>{values.subcategoryname_en}:</p>
                    {values
                      ? values.subcategories
                        ? values.subcategories.map((val, key) => {
                            return (
                              <>
                                <p className="pl-2 text-ternary">
                                  {ProductTextLocalized(
                                    val.subcategoryname_en,
                                    val.subcategoryname_ar,
                                    language
                                  )}
                                  ,
                                </p>
                              </>
                            );
                          })
                        : ""
                      : ""}
                  </div> */}
                    <div className="end-item" style={{ display: "flex" }}>
                      <div className="">
                        <button
                          className="button"
                          type="button"
                          onClick={(e) => this.openModal(e, values)}>
                          Edit
                        </button>
                        {this.state.isOpen ? (
                          <EditsubcategoryModal
                            isOpen={this.state.isOpen}
                            onClose={this.closeModal}
                            data={this.props.values}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="ml-3">
                        <button
                          className="button"
                          onClick={(e) =>
                            this.inactiveSubCategoryBtnClick(e, values, "sub")
                          }>
                          Deactivate
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="subcat-multi- overflow-y-auto h-40 scrollbar-hide">
                    {/* <p>{values.subcategoryname_en}:</p> */}
                    {values
                      ? values.subcategories
                        ? values.subcategories.map((val, key) => {
                            return (
                              <>
                                <div className="grid grid-cols-2 p-4">
                                  <div className="">
                                    <p className="pl-2 text-ternary">
                                      {ProductTextLocalized(
                                        val.subcategoryname_en,
                                        val.subcategoryname_ar,
                                        language
                                      )}
                                      {/* , */}
                                    </p>
                                  </div>
                                  <div className="end-item">
                                    {val.isactive == "1" ||
                                    val.isactive == 1 ? (
                                      <button
                                        className="button"
                                        onClick={(e) =>
                                          this.inactiveSubCategoryBtnClick(
                                            e,
                                            val,
                                            "subsub"
                                          )
                                        }>
                                        Deactivate
                                      </button>
                                    ) : (
                                      <button
                                        className="button"
                                        onClick={(e) =>
                                          this.activeSubCategoryBtnClick(
                                            e,
                                            val,
                                            "subsub"
                                          )
                                        }>
                                        Activate
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : ""
                      : ""}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {values.subcategories.length == 0 ? (
              <>
                <div className="grid grid-cols-2 border-b-2 border-gray-50 bg-gray-50 px-5 py-4">
                  <div>{values.subcategoryname_en}</div>
                  <div className="end-item" style={{ display: "flex" }}>
                    {/* <div className="">
                  <button
                    className="button"
                    type="button"
                    onClick={(e) => this.openModal(e, values)}
                  >
                    Edit
                  </button>
                  {this.state.isOpen ? (
                    <EditsubcategoryModal
                      isOpen={this.state.isOpen}
                      onClose={this.closeModal}
                      data={this.props.values}
                    />
                  ) : (
                    ""
                  )}
                </div> */}
                    <div className="ml-3">
                      <button
                        className="button"
                        onClick={(e) =>
                          this.activeSubCategoryBtnClick(e, values, "sub")
                        }>
                        Activate
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="border-b-2 border-gray-50 bg-gray-50 px-5 py-4 h-60">
                  {/* <div className="grid grid-cols-2 border-b-2 border-gray-50 px-5 py-4"> */}
                  <div className="grid grid-cols-2 ">
                    <div>
                      {" "}
                      <p>{values.subcategoryname_en}:</p>
                    </div>
                    {/* <p>:</p> */}
                    {/* {values
                      ? values.subcategories
                        ? values.subcategories.map((val, key) => {
                            return (
                              <>
                                <p className="pl-2 text-ternary">
                                  {ProductTextLocalized(
                                    val.subcategoryname_en,
                                    val.subcategoryname_ar,
                                    language
                                  )}
                                  ,
                                </p>
                              </>
                            );
                          })
                        : ""
                      : ""} */}
                    <div className="end-item" style={{ display: "flex" }}>
                      {/* <div className="">
                              <button className="button" type="button" 
                               onClick={(e) => this.openModal(e, values)}
                              >
                                Edit
                              </button>
                              {this.state.isOpen ? (
                                    <EditsubcategoryModal
                                      isOpen={this.state.isOpen}
                                      onClose={this.closeModal}
                                      data={this.props.values}
                                    />
                                  ) : (
                                    ""
                              )}
                            </div> */}
                      <div className="ml-3">
                        <button
                          className="button"
                          onClick={(e) =>
                            this.activeSubCategoryBtnClick(e, values)
                          }>
                          Activate
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="subcat-multi- overflow-y-auto h-40 scrollbar-hide">
                    {/* <p>{values.subcategoryname_en}:</p> */}
                    {values
                      ? values.subcategories
                        ? values.subcategories.map((val, key) => {
                            return (
                              <>
                                <div className="grid grid-cols-2 p-4">
                                  <div className="">
                                    <p className="pl-2 text-ternary">
                                      {ProductTextLocalized(
                                        val.subcategoryname_en,
                                        val.subcategoryname_ar,
                                        language
                                      )}
                                      {/* , */}
                                    </p>
                                  </div>
                                  <div className="end-item">
                                    {val.isactive == "1" ||
                                    val.isactive == 1 ? (
                                      <button
                                        className="button"
                                        onClick={(e) =>
                                          this.inactiveSubCategoryBtnClick(
                                            e,
                                            val,
                                            "subsub"
                                          )
                                        }>
                                        Deactivate
                                      </button>
                                    ) : (
                                      <button
                                        className="button"
                                        onClick={(e) =>
                                          this.activeSubCategoryBtnClick(
                                            e,
                                            val,
                                            "subsub"
                                          )
                                        }>
                                        Activate
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : ""
                      : ""}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    subcategorymodalstore: state.subcategorymodalstore,
  };
}

export default connect(mapStateToProps, {
  editsubcategory,
  deletesubCategory,
  activeInactivesubCategory,
  fetchsubCategory,
  checkifProductUnderCategory,
  activatedeactiveCatsubcat,
  getrelatedCountcatsubcat,
})(SubCategorylistItems);
