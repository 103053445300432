import React, { Component } from "react";
import { connect } from "react-redux";
import { converDateIntoLocal } from "../../../../common/common-functions";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import EditspecificationModal from "./editspecification";
import LocaleStrings from "../../../languages";
import {
  Editspecification,
  fetchProductsByCateSubCate,
  getSelectedSpecsObj,
  isOpenSpecsManagModal,
  fetchSpecification,
  updateSpecs,
  inactiveSpecsCount,
  inactiveactiveSpecs,
} from "../actions/index";
import { NotificationManager } from "react-notifications";

const limit = 10;
class SpecificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
      unbanopen: false,
      pageNumber: 1,
    };
  }

  closeModal = () => {
    this.props.getSelectedSpecsObj({}, this.props.session);
    this.setState({ isOpen: false });
  };

  openSpecsManagementModal(e, obj) {
    // console.log("discountObj", discountObj);
    if (Object.keys(obj).length > 0) {
      let idcategory = obj.categoryids;
      let idsubcategory = obj.subcategoryids;

      this.props.fetchProductsByCateSubCate(
        this.props.session,
        idcategory,
        idsubcategory,
        (callBack) => {}
      );
    }

    this.props.getSelectedSpecsObj(obj, this.props.session);
    this.setState({ isOpen: true });
  }

  InactiveSpecs(e, values) {
    this.props.inactiveSpecsCount(
      this.props.session,
      values.id,
      (callbackResponse) => {
        if (
          callbackResponse &&
          callbackResponse.success &&
          callbackResponse.success == 1
        ) {
          let productCount = 0;
          if (callbackResponse.data && callbackResponse.data.product_count) {
            productCount = callbackResponse.data.product_count;
          }
          let messageText =
            "Are you sure you want to deactivate specification?";
          if (productCount > 0) {
            messageText = `Are you sure you want to deactivate specification? Doing this will unlist  ${productCount} product(s) associated with it? Activating this won't list the product automatically. Needs to be done manually`;
          }

          confirmAlert({
            title: `Deactivate Specification`,
            message: messageText,
            buttons: [
              {
                label: LocaleStrings.yes,
                onClick: () => {
                  let body = { specificationid: values.id, isactive: 0 };

                  this.props.inactiveactiveSpecs(
                    this.props.session,
                    body,
                    (resource) => {
                      if (resource.error) {
                        NotificationManager.error(
                          LocaleStrings.specs_inactive_error
                        );
                      } else {
                        NotificationManager.success(
                          LocaleStrings.specs_inactive_successfull
                        );
                        this.props.fetchSpecification(
                          this.props.session,
                          limit,
                          this.props.currentPage,
                          "listing",
                          "",
                          (CallBackResponse) => {}
                        );
                      }
                    }
                  );
                },
              },
              {
                label: LocaleStrings.no,
                onClick: () => {},
              },
            ],
          });
        }
      }
    );
  }

  ActiveSpecs(e, values) {
    confirmAlert({
      title: LocaleStrings.specs_active_title,
      message: LocaleStrings.specs_active_message,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            let body = { specificationid: values.id, isactive: 1 };
            this.props.inactiveactiveSpecs(
              this.props.session,
              body,
              (resource) => {
                if (resource.error) {
                  NotificationManager.error(LocaleStrings.specs_active_error);
                } else {
                  NotificationManager.success(
                    LocaleStrings.specs_active_successfull
                  );
                  this.props.fetchSpecification(
                    this.props.session,
                    limit,
                    this.props.currentPage,
                    "listing",
                    "",
                    (CallBackResponse) => {}
                  );
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { values } = this.props;
    // console.log('converDateIntoLocal',values)
    // if(!_.isEmpty(selectedVariant)){
    //   selectedVariantDisplay = ProductTextLocalized(selectedVariant[0].name_en, selectedVariant[0].name__ar, language);
    // }

    let appliedfor = "";
    if (_.isEmpty(values.categories) && _.isEmpty(values.subcategories)) {
      appliedfor = "All";
    } else if (!_.isEmpty(values.categories) && !_.isEmpty(values.categories)) {
      appliedfor = (
        <div>
          <p>{values.categories.length} category</p>
          <p>{values.subcategories.length} sub-category</p>
        </div>
      );
    }
    // else if(values.productcategory_by_categoryids !== null  && values.productsubcategory_by_subcategoryids !== null){
    //   appliedfor = values.productcategory_by_categoryids.categoryname_en + " "+ values.productsubcategory_by_subcategoryids.subcategoryname_en;
    // }

    let tabledate = "";
    tabledate = converDateIntoLocal(values.createdat).format(
      "D MMM YYYY HH:mm A"
    );
    if (values.isactive == true) {
      var mainDivStyle = {
        backgroundColor: "#fff",
      };
    } else {
      var mainDivStyle = {
        backgroundColor: "f5f5f5",
      };
    }

    return (
      <>
        <tr
          className=" border-b-2 border-gray-50  hover-all"
          style={mainDivStyle}>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.name_en}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {tabledate}
          </td>

          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {appliedfor}
          </td>
          {values.ismandatory == true ? (
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {LocaleStrings.yes}
            </td>
          ) : (
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {LocaleStrings.no}
            </td>
          )}
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <div className="action-button-area" style={{ display: "flex" }}>
              <div className="">
                <button
                  className="button"
                  onClick={(e) => this.openSpecsManagementModal(e, values)}
                  type="button">
                  Edit
                </button>
                {this.state.isOpen ? (
                  <EditspecificationModal
                    isOpen={this.state.isOpen}
                    onClose={this.closeModal}
                    // values={this.props.values}
                  />
                ) : (
                  ""
                )}
              </div>
              {values.isactive == true ? (
                <div className="ml-3">
                  <button
                    className="button"
                    onClick={(e) => this.InactiveSpecs(e, values)}>
                    {LocaleStrings.specs_Inactivate}
                  </button>
                </div>
              ) : (
                <div className="ml-3">
                  <button
                    className="button"
                    onClick={(e) => this.ActiveSpecs(e, values)}>
                    {LocaleStrings.specs_Activate}
                  </button>
                </div>
              )}
            </div>
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
  };
}
export default connect(mapStateToProps, {
  Editspecification,
  fetchProductsByCateSubCate,
  isOpenSpecsManagModal,
  getSelectedSpecsObj,
  fetchSpecification,
  updateSpecs,
  inactiveSpecsCount,
  inactiveactiveSpecs,
})(SpecificationList);
