import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
// import { showError, showSuccess } from "ReduxErrorBar";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../../common/base-component";
import _ from "lodash";
import {
  ProductTextLocalized,
  classNames,
} from "../../../../../../common/common-functions";
import { updateFaqs } from "../../../actions/index";
import { NotificationManager } from "react-notifications";

class EditFAQ extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      forWhom: "",
      btnLoader: false,
      enar: "en",
    };

    // All Binded Functions
    // this.closeModal = this.closeModal.bind(this);
    // this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onSubmitForm(values) {
    this.setState({ btnLoader: true });
    var faqdata = values;
    if (
      values.question_en == "" ||
      values.question_ar == "" ||
      values.question_en == null ||
      values.question_ar == null
    ) {
      this.setState({ btnLoader: false });
      NotificationManager.error("Question required in both languages");
      return false;
    }
    if (
      values.answer_en == "" ||
      values.answer_ar == "" ||
      values.answer_ar == null ||
      values.answer_en == null
    ) {
      this.setState({ btnLoader: false });
      NotificationManager.error("Answer required in both languages");
      return false;
    }
    this.props.updateFaqs(this.props.session, values, (response) => {
      if (response.success === 0) {
        this.props.onClose();
        NotificationManager.error(LocaleStrings.updatederror);
      } else {
        this.props.onClose();
        NotificationManager.success(LocaleStrings.updatedsuccessfully);
      }
    });
  }
  handleInputLanguageChange(e) {
    if (parseInt(e.target.value) === 1) {
      this.setState({ enar: "ar" });
    } else {
      this.setState({ enar: "en" });
    }
  }

  render() {
    var { handleSubmit, initialValues } = this.props;
    var { enar } = this.state;

    var faq_for = [];

    _.forEach(this.props.Faqfor, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name),
        value: value.value,
      };

      faq_for.push(obj);
    });

    return (
      <Transition.Root show={this.props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.props.isOpen}
          onClose={this.props.onClose}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                style={{ padding: 0 }}>
                {/* MOdal Header */}
                <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                  <div className="flex flex-wrap mx-6 mt-4">
                    <div className="text-md leading-6 font-medium text-secondary">
                      {LocaleStrings.global_setting_editFaq}
                    </div>
                  </div>
                  <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                    <button
                      type="button"
                      className="btn-closeModal"
                      onClick={this.props.onClose}>
                      <span className="sr-only">{LocaleStrings.close}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <form
                  className=" p-6"
                  onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
                  <Field
                    name="language"
                    label={LocaleStrings.en}
                    component={this.renderFieldRadioNew}
                    type="radio"
                    value="0"
                    className="mx-2"
                    onChange={this.handleInputLanguageChange.bind(this)}
                  />

                  <Field
                    name="language"
                    label={LocaleStrings.ar}
                    component={this.renderFieldRadioNew}
                    type="radio"
                    value="1"
                    className="mx-2"
                    onChange={this.handleInputLanguageChange.bind(this)}
                  />
                  <Field
                    // name="question"
                    name={`question_` + enar}
                    label="Question"
                    placeholder="Question"
                    mandatory="true"
                    type="text"
                    component={this.renderAllfields}
                    className="appearance-none block w-half px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                  />

                  <div className="mb-5">
                    <Field
                      name={`answer_` + enar}
                      label="Answer"
                      mandatory="true"
                      component={this.renderAllfields}
                      maxlength={200}
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-box-lable"
                      className="py-3 px-4 block w-full shadow-sm text-secondary focus:ring-primary focus:border-teal-500 border border-warm-gray-300 rounded-md"
                    />
                  </div>

                  <div className="mb-5">
                    <Field
                      name="faqfor"
                      label="FAQ FOR"
                      placeholder={LocaleStrings.select}
                      component={this.renderSelect}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      opts={faq_for}
                      className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                      classNameLabel="block text-box-lable"
                      // onChange={this._handlefor.bind(this)}
                      // value={this.props.initialValues.type}
                    />
                  </div>

                  <div className="w-full bottom-0 absolute mb-10">
                    <div className="flex justify-center">
                      <button type="submit" className="button">
                        <svg
                          className={classNames(
                            this.state.btnLoader ? "" : "sr-only",
                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        {LocaleStrings.save}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = ["faqfor", "question", "answer"];
  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = "Required";
    }
  });

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  let initvals = state.edit_faqs;
  initvals.language = "0";
  return {
    session,
    user,
    language: state.language,
    Faqfor: state.Faqfor,
    edit_faqs: state.edit_faqs,
    initialValues: initvals,
  };
}

export default connect(mapStateToProps, {
  updateFaqs,
})(
  reduxForm({
    validate,
    form: "Editfaqfor",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EditFAQ)
);
