import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index";
import _ from "lodash";
import BaseLoader from "../../../../../../common/base-loader";
import Pagination from "react-js-pagination";

// Import Actions
import { fetchNegotiationseller } from "../../../actions/index";

// Component Import
import NegotiatedTableList from "./negotiated-items";
import Negotiate from "./negotiationslider";

/* This example requires Tailwind CSS v2.0+ */
import { SearchIcon } from "@heroicons/react/solid";

class SellerNogtiated extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_negotiationsoffers,
      negoSearchText: "",
      pageNumber: 1,
      search: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    // Call API to Fetch Data
    this.props.fetchNegotiationseller(
      this.props.session,
      this.props.data.userid,
      this.state.pageNumber,
      "listing",
      "",
      (callBack) => {
        this.setState({ loading: false });
      }
    );
  }

  paginationCallback = (pageNumber) => {
    // alert(pageNumber)
    this.props.fetchNegotiationseller(
      this.props.session,
      this.props.data.userid,
      pageNumber,
      "listing",
      "",
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };
  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchNegotiationseller(
        this.props.session,
        this.props.data.userid,
        this.state.pageNumber,
        "listing",
        "",
        (CallBackResponse) => {}
      );
    } else {
      this.props.fetchNegotiationseller(
        this.props.session,
        this.props.data.userid,
        this.state.pageNumber,
        "search",
        search
      );
    }
    this.setState({ search, pageNumber });
  };

  // Render Order List Tbody
  renderNegotiationList() {
    let pageDataLength = this.props.vendorNegotiationData.data.length;

    let { pageNumber } = this.state;
    return _.map(
      this.props.vendorNegotiationData.data,
      (negotiationObj, index) => {
        return (
          <NegotiatedTableList
            key={negotiationObj.orderid}
            negotiationObj={negotiationObj}
            position={index}
            callFrom="nego"
            pagination={this.paginationCallback}
            pageDataLength={pageDataLength}
            currentPage={pageNumber}
          />
        );
      }
    );
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div className="relative  text-ternary focus-within:text-secondary ">
              <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className="block lg:w-3/12 sm:w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                placeholder={LocaleStrings.search}
                type="search"
                name="search"
                value={this.state.search}
                onChange={this.handleSearch}
              />
            </div>
            {this.props.data.usertype == "personal" ? (
              <div className="center-item lg:mt-48 text-lg text-ternary">
                No Negotiation for Personal Type Vendors
              </div>
            ) : _.isEmpty(this.props.vendorNegotiationData.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.seller_management_nego_noaccepteddData}
                </div>
              </>
            ) : (
              <>
                {" "}
                <div className="flex flex-col mt-6">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.product}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.actualprice}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.negotiatedprice}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Offers left
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.negotiatedon}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.status}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.renderNegotiationList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Pagination
                    activePage={this.state.pageNumber}
                    itemsCountPerPage={10}
                    totalItemsCount={this.props.vendorNegotiationData.count}
                    pageRangeDisplayed={10}
                    onChange={this.paginationCallback}
                  />
                </div>
                <Negotiate />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    vendorNegotiationData: state.vendorNegotiationListStore,
    // vendorNegotiationData: state.vendorNegotiationListStore
    //   ? state.vendorNegotiationListStore.data
    //     ? state.vendorNegotiationListStore.data
    //     : []
    //   : [],
  };
}

export default connect(mapStateToProps, {
  // salesmanagementStatboxSelected,
  fetchNegotiationseller,
})(SellerNogtiated);
