import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../../common/constants";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Ads(props) {
  const history = useHistory();
  let { item } = props;

  return (
    <div className="rounded cursor-pointer flex  justify-center md:flex-shrink-0">
      <LazyLoadImage
        src={`${BASE_IMAGES_URL}/${item.image}?api_key=${APP_API_KEY}`}
        className="h-full w-full rounded-sm "
        effect="blur"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Ads);
