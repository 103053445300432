import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../common/base-component";
import _ from "lodash";
import {
  converDateIntoLocal,
  ProductTextLocalized,
  getIntToFloat,
} from "../../../../../common/common-functions";
import { confirmAlert } from "react-confirm-alert";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../currency/action/index";
import LocaleStrings from "../../../../languages";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { wonbidsnotpurchasedreminder } from "../../actions/index";

class AuctionitemsnotboughtItems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      cancelorderDetailsisOpen: false,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  _sendReminder(e, values) {
    console.log("values", values);
    let obj = {};
    obj.bidid = values.id;

    confirmAlert({
      title: "Send Reminder",
      message: `Are you sure  you want to send reminder?`,
      buttons: [
        {
          label: LocaleStrings.yes,
          onClick: () => {
            var id = values;
            // console.log('id',id)
            this.props.wonbidsnotpurchasedreminder(
              this.props.session,
              obj,
              (resource) => {
                // console.log("showresource :- ", resource);
                if (resource.error) {
                  NotificationManager.error("Something went wrong!");
                } else {
                  NotificationManager.success("Reminder Sent to the user");
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { values, language, localCurrency } = this.props;
    // console.log('replace', values);

    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    let tabledate = "";
    tabledate = converDateIntoLocal(values.createdon).format("D MMM YYYY");
    let grand_total = 0.0;
    let productCurrency = values.product?.odcurrency;

    // grand_total = values?.summary.grand_total;

    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50" id={this.props.key}>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>
              {values.product.productname_en.length > 35
                ? values.product.productname_en.substring(0, 35) + "..."
                : values.product.productname_en}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>
              {currency} {values.bidprice}{" "}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{values.userdetails.username}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {_.startCase(values.status)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {tabledate}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div className="action-button-area" style={{ display: "flex" }}>
              <div className="">
                <button
                  className="button"
                  type="button"
                  onClick={(e) => this._sendReminder(e, values)}>
                  Send Reminder
                </button>
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    localCurrency: state.currency,
  };
}

export default connect(mapStateToProps, {
  wonbidsnotpurchasedreminder,
  isCurrencyRateApplicable,
})(AuctionitemsnotboughtItems);
