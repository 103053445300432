import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index";
import moment from "moment-timezone";
import _ from "lodash";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../../currency/action/index";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class ChatList extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  componentDidMount() {}

  render() {
    let { item, session, localCurrency } = this.props;
    let currency = currencyCode(localCurrency);

    return (
      <>
        {item.price == 0 ? (
          <div className="flex justify-start">
            <div>
              <div
                className="bg-quaternary px-3.5 py-3"
                style={{ borderRadius: "22px 22px 22px 5px" }}>
                <div className="flex">
                  <div className="text-primary text-base font-medium">
                    {_.startCase(LocaleStrings.status)}:
                  </div>
                  <div
                    className={classNames(
                      item.status == "rejected"
                        ? "text-danger "
                        : "text-success ",
                      "text-base font-medium mx-1"
                    )}>
                    {_.startCase(item.status)}
                  </div>
                </div>
                <div className="text-ternary text-xs">
                  {moment(item.createdat).format("LT")}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-end">
            <div>
              <div
                className="bg-primary text-custWhite px-3 py-2"
                style={{ borderRadius: "22px 22px 5px 22px" }}>
                {currency} {parseFloat(item.price).toFixed(2)}
              </div>
              <div className="text-ternary text-xs">
                {moment(item.createdat).format("LT")}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    localCurrency: state.currency,
  };
}

export default connect(mapStateToProps, {})(ChatList);
