import {
  SELECTED_SIDEBAR_MENU,
  NOTIFICATION_LIST,
  ALl_USERS,
  CUSTOMNOTIFICATIOSLIDER,
  ADMIN_ROLES,
} from "../actions/index";
import LocaleStrings from "../../../languages/index.js";

// Initial select tab is "SELECTED_SIDEBAR_MENU"
export const SidebarSelectedMenu = (state = 1, action) => {
  if (action.type == SELECTED_SIDEBAR_MENU) {
    state = action.payload;
  }
  return state;
};

// export const SideBarMenus = (state = [], action) => {
//   //iconName is optional
//   var state = [
//   { name: LocaleStrings.dashboard, href: 'dashboardside', icon: "dashboard", current: true,position:1 },
//   { name: 'Home Page Builder', href: 'homepagebuilder', icon: "adsmanagement", current: false,position:2 },
//   { name: "Ads management", href: 'adsmanagement', icon: "currency", current: false,position:3 },
//   { name: LocaleStrings.customer_management, href: 'customermanagement', icon: "customermanagement", current: false,position:4 },
//   { name: LocaleStrings.seller_management, href: 'sellermanagement', icon: "sellermanagement", current: false,position:5 },
//   { name: LocaleStrings.product_management, href: 'productmanagement', icon: "productmanagement", current: false,position:6},
//   { name: LocaleStrings.order_management, href: 'ordermanagement', icon: "myorders", current: false,position:7 },
//   { name: LocaleStrings.dispute_management, href: 'disputemanagement', icon: "dispute", current: false,position:8 },
//   { name: LocaleStrings.category_management, href: 'categorymanagement', icon: "allcategories", current: false,position:9 },
//   { name: LocaleStrings.specs_management, href: 'specsmanagement', icon: "specs", current: false,position:10 },
//   { name: LocaleStrings.master_variation, href: 'mastervariation', icon: "mastervariation", current: false,position:11 },
//   { name: "Transaction", href: 'transaction', icon: "transaction", current: false,position:12 },
//   { name: LocaleStrings.discount_management, href: 'discountmanagement', icon: "mycoupons", current: false,position:13 },
//   // { name: LocaleStrings.adds_management, href: 'addsmanagement', icon: "adsmanagement", current: false,position:13 },
//   { name: LocaleStrings.feeds_management, href: 'feedsmanagement', icon: "feedmanagement", current: false,position:14 },
//   { name: LocaleStrings.global_setting, href: 'globalsettings', icon: "globalsettings", current: false,position:15 },
//   { name: LocaleStrings.tickets_reports, href: 'tickets_reports', icon: "ticket", current: false,position:16 },
//   { name: LocaleStrings.language, href: 'language', icon: "language", current: false,position:17 },
//   // { name: LocaleStrings.currency, href: 'currency', icon: "currency", current: false,position:18 },

//     ];

//   return state;
// };

export function notificationList(state = {}, action) {
  switch (action.type) {
    case NOTIFICATION_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
export function all_users(state = {}, action) {
  switch (action.type) {
    case ALl_USERS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}

export const customNotification = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "All companies", value: "company", id: 0 },
    { name: "All Home Business", value: "home", id: 1 },
    { name: "All Personal Business", value: "personal", id: 2 },
    { name: "All Customers", value: "customers", id: 3 },
  ];
  return state;
};

export var isSlideropen = (state = false, action) => {
  if (action.type === CUSTOMNOTIFICATIOSLIDER) {
    state = action.payload;
  }

  return state;
};

export function SideBarMenus(state = {}, action) {
  switch (action.type) {
    case ADMIN_ROLES:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
}
// export default (SidebarSelectedMenu,SideBarMenus);
