import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import { BaseComponent } from "../../../../../../common/base-component";
import Oderdetailmodal from "./oderdetailsmodal";
import { ProductTextLocalized } from "../../../../../../common/common-functions";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../../currency/action/index";

class DeliveredCutomerorders extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openModal = (e) => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  render() {
    var { values, language, localCurrency } = this.props;
    // console.log("values",values)
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    let tabledate = "";
    tabledate = moment(values.createdon).format("D MMM YYYY HH:mm A");

    let grandtotal = 0.0;
    let productCurrency = "QAR";
    if (values.currency && (values.currency != "" || values.currency != null)) {
      productCurrency = values.currency;
    }

    grandtotal = values?.order_details.summary.grand_total;

    return (
      <>
        <tr
          className="bg-white border-b-2 border-gray-50"
          onClick={(e) => this.openModal(e, values)}>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{values.invoice_no}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>
              {/* ${values.originalprice} */}
              {currency} {grandtotal.toFixed(2)}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{values.order_details.delivery_address.username}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>{tabledate}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            {_.startCase(values.currentstatus.status)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
            <div>
              {ProductTextLocalized(
                values.productname_en.length > 35
                  ? values.productname_en.substring(0, 25) + "..."
                  : values.productname_en,
                values.productname_ar.length > 35
                  ? values.productname_ar.substring(0, 25) + "..."
                  : values.productname_ar,

                language
              )}
            </div>
          </td>
        </tr>
        {this.state.isOpen ? (
          <Oderdetailmodal
            isOpen={this.state.isOpen}
            onClose={this.closeModal}
            // data={values.items}
            data={this.props.values}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.customermanagementTabs,
    customereditmodal: state.customereditmodal,
    customerlistingtabs: state.customerlistingtabs,
    newtablist: state.newtablist,
    localCurrency: state.currency,
  };
}
export default connect(mapStateToProps, { isCurrencyRateApplicable })(
  DeliveredCutomerorders
);
