import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import {
  fetchSpecification,
  fetchProductCategory,
  fetchProductSubcategory,
  fetchSubSubcategory,
  fetchspecsallCategory,
} from "../actions/index";
import SpecificationList from "./specslist-items";
import _ from "lodash";
import Addspecificationmodal from "./addspecification";
import SpecificationopenModal from "./addbutton";
import Pagination from "react-js-pagination";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import BaseLoader from "../../../../common/base-loader";
import { classNames } from "../../../../common/common-functions";

const limit = 10;

class SpecsManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.specs_management,
      pageNumber: 1,
      search: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation.data, {
      href: answer,
    });

    if (singleNavigationRow == undefined) {
      this.props.history.push("/login");
    }
    this.props.fetchSpecification(
      this.props.session,
      limit,
      this.state.pageNumber,
      "listing",
      "",
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
    // Get Categories
    // this.props.fetchProductCategory(this.props.session, (callBack) => {});

    // // Subcategories
    // this.props.fetchProductSubcategory(this.props.session, (callBack) => {});
    // this.props.fetchSubSubcategory(this.props.session, (callBack) => {});
    this.props.fetchspecsallCategory(this.props.session, (callBack) => {});
  }

  paginationCallback = (pageNumber) => {
    this.props.fetchSpecification(
      this.props.session,

      limit,
      pageNumber,
      "listing",
      "",
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    var searchText = event.target.value;
    var pageNumber = 1;

    if (searchText === "") {
      this.setState({ loading: true });
      // Paginated list
      /* 
        Fetch transaction history list [paginated]
        New Params : {foruserid:userid,"limit":<num of rows to show>,"page":curpage,type:"listing"}
        */
      this.props.fetchSpecification(
        this.props.session,

        limit,
        pageNumber,
        "listing",
        "",
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    } else {
      /* 
        search list without pagination
        Params: {foruserid:userid,type:"search",searchterm:<search text>}
        */
      this.props.fetchSpecification(
        this.props.session,
        limit,
        pageNumber,
        "search",
        searchText,
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    }
    this.setState({ searchText, pageNumber });
  };

  renderList() {
    let pageDataLength = this.props.specificationlisting.data.length;

    let { pageNumber } = this.state;
    // console.log('check');
    var items = _.map(this.props.specificationlisting.data, (values, index) => (
      <SpecificationList
        key={index}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }

  render() {
    let { language } = this.props;
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {/* <SpecificationopenModal/> */}
            <div
              className="grid gap-4 grid-rows-1 px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 "
              style={{
                overflowX: "hidden",
                overflowY: "hidden",
                display: "block",
              }}>
              <div className="mb-4 text-2xl font-semibold text-secondary">
                {LocaleStrings.specs_heading}
              </div>
              <div className="grid lg:grid-cols-2 xs:grid-cols-1">
                <div className="relative  text-ternary focus-within:text-secondary ">
                  <div
                    className={classNames(
                      language == "en" ? "left-0 " : "right-0",
                      "absolute inset-y-0 flex items-center pointer-events-none px-1"
                    )}>
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    className={classNames(
                      language == "en" ? "pl-8 pr-3" : "pr-8 pl-3",
                      "block lg:w-6/12 xs:w-full sm:w-full h-full  py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                    )}
                    placeholder={LocaleStrings.search}
                    type="search"
                    name="search"
                    value={this.state.searchText}
                    onChange={this.handleSearch}
                  />
                </div>
                <SpecificationopenModal />
              </div>
            </div>
            {_.isEmpty(this.props.specificationlisting.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.specs_no_Data}
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col pt-10 pb-2 px-6">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.specificationame}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.appliedon}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.appliedfor}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.ismandatory}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.action}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.renderList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/* {this.state.searchText === "" ? ( */}
                <Pagination
                  activePage={this.state.pageNumber}
                  itemsCountPerPage={10}
                  totalItemsCount={this.props.specificationlisting.count}
                  pageRangeDisplayed={10}
                  onChange={this.paginationCallback}
                />
                {/* ) : (
                  ""
                )} */}

                {/* <Toaster limit={1} /> */}
              </>
            )}
          </>
        )}
        <Addspecificationmodal />
        <NotificationContainer />
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, language } = state;
  // console.log('state.specificationlisting',state.specificationlisting)
  return {
    session,
    user,
    language,
    specificationlisting: state.specificationlisting,
    navigation: state.sideBarMenus,
  };
}

export default connect(mapStateToProps, {
  fetchSpecification,
  fetchProductCategory,
  fetchProductSubcategory,
  fetchSubSubcategory,
  fetchspecsallCategory,
})(SpecsManagement);
