import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index";
import _ from "lodash";
import { fetchalldeliveredorderlist } from "../../actions/index";
import AllDeliveredoderitemslist from "./alldelivered-items";
import { SearchIcon } from "@heroicons/react/solid";
import BaseLoader from "../../../../../common/base-loader";
import Pagination from "react-js-pagination";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import OrderManagementTabs from "../ordermanagementtabs";
import Message from "../message";
import { classNames } from "../../../../../common/common-functions";

const limit = 10;

class Alldeliveredorders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: "Preparing Delivered Orders",
      pageNumber: 1,
      searchText: "",
      selectedThreadId: "",
    };

    // All Binded Functions
  }

  componentDidMount(data) {
    var status = "delivered";
    this.props.fetchalldeliveredorderlist(
      this.props.session,
      status,
      limit,
      this.state.pageNumber,
      "listing",
      "",
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  paginationCallback = (pageNumber) => {
    var status = "delivered";

    this.props.fetchalldeliveredorderlist(
      this.props.session,
      status,
      limit,
      pageNumber,
      "listing",
      "",
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    var status = "delivered";
    var searchText = event.target.value;
    var pageNumber = 1;

    if (searchText === "") {
      // this.setState({ loading: true });
      // Paginated list
      /* 
        Fetch transaction history list [paginated]
        New Params : {foruserid:userid,"limit":<num of rows to show>,"page":curpage,type:"listing"}
        */
      this.props.fetchalldeliveredorderlist(
        this.props.session,
        status,
        limit,
        pageNumber,
        "listing",
        "",
        (CallBackResponse) => {
          // this.setState({ loading: false });
        }
      );
    }
    // else {
    /* 
        search list without pagination
        Params: {foruserid:userid,type:"search",searchterm:<search text>}
        */
    // this.props.fetchalldeliveredorderlist(
    //   this.props.session,
    //   status,
    //   limit,
    //   pageNumber,
    //   "search",
    //   searchText,
    //   (CallBackResponse) => {
    //     this.setState({ loading: false });
    //   }
    // );
    // }
    this.setState({ searchText, pageNumber });
  };

  searchOrders = (e) => {
    let { searchText } = this.state;
    var status = "delivered";
    var pageNumber = 1;

    if (searchText === "") {
      // this.setState({ loading: true });
      // Paginated list
      /* 
        Fetch transaction history list [paginated]
        New Params : {foruserid:userid,"limit":<num of rows to show>,"page":curpage,type:"listing"}
        */
      this.props.fetchalldeliveredorderlist(
        this.props.session,
        status,
        limit,
        pageNumber,
        "listing",
        "",
        (CallBackResponse) => {
          // this.setState({ loading: false });
        }
      );
    } else {
      /* 
        search list without pagination
        Params: {foruserid:userid,type:"search",searchterm:<search text>}
        */
      this.props.fetchalldeliveredorderlist(
        this.props.session,
        status,
        limit,
        pageNumber,
        "search",
        searchText,
        (CallBackResponse) => {
          // this.setState({ loading: false });
        }
      );
    }
  };

  renderalldeliveredlist() {
    let pageDataLength = this.props.alldeliveredorderlist.data.length;
    let { searchText, pageNumber } = this.state;
    var items = _.map(
      this.props.alldeliveredorderlist.data,
      (values, index) => (
        <AllDeliveredoderitemslist
          key={index}
          values={values}
          pagination={this.paginationCallback}
          pageDataLength={pageDataLength}
          currentPage={pageNumber}
        />
      )
    );
    return items;
  }

  render() {
    let { language } = this.props;
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <OrderManagementTabs history={this.props.history} />
            <div className=" p-6">
              <div class="relative">
                {language == "en" ? (
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </div>
                ) : (
                  ""
                )}
                <input
                  type="search"
                  name="search"
                  id="search-field"
                  value={this.state.searchText}
                  onChange={this.handleSearch}
                  class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search Products"
                  required
                />
                <button
                  type="button"
                  onClick={(e) => this.searchOrders(e)}
                  className={classNames(
                    language == "en" ? "right-2.5" : "left-2.5",
                    "text-white absolute  bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  )}>
                  Search
                </button>
              </div>
            </div>
            {_.isEmpty(this.props.alldeliveredorderlist.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  No Delivered Orders
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col p-6">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-custWhite">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.oderid}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Sku
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Seller
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.odertotal}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.odererby}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.oderon}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.status}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {LocaleStrings.items}
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.renderalldeliveredlist()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.searchText === "" ? (
                  <Pagination
                    activePage={this.state.pageNumber}
                    itemsCountPerPage={10}
                    totalItemsCount={this.props.alldeliveredorderlist.count}
                    pageRangeDisplayed={10}
                    onChange={this.paginationCallback}
                  />
                ) : (
                  ""
                )}
                <NotificationContainer />
                <Message selectedThreadId={this.state.selectedThreadId} />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  // console.log("alldeliveredorderlist", state.alldeliveredorderlist);
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    language: state.language,
    session: state.session,
    alldeliveredorderlist: state.alldeliveredorderlist,
  };
}

export default connect(mapStateToProps, {
  fetchalldeliveredorderlist,
})(Alldeliveredorders);
