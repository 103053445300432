import { getHeaders } from "../../../../common/common-utils";
import axios from "axios";
import {
  APP_API_KEY,
  PUBLIC_API_KEY,
  INSTANCE_URL,
  BASE_URL,
  LOGIN,
  REGISTER,
  FETCH_SPEAKERS,
  SELECT_FEATURE,
  itemCount,
} from "../../../../common/constants";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../../../common/network-call";
import { values } from "lodash";

export var ALL_CANCELLEDORDER_LIST = "ALL_CANCELLEDORDER_LIST";
export var All_RETURNEDORDER_LIST = "All_RETURNEDORDER_LIST";
export var All_REPLACEORDER_LIST = "All_REPLACEORDER_LIST";
export var AUCTIONITESM_WONNOTBOUGHT = "AUCTIONITESM_WONNOTBOUGHT";
export var ORDERSNOT_PROCESSED = "ORDERSNOT_PROCESSED";

export function fetchallcancelledorderlist(
  session,
  status,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allorders`;
  let values = {};
  if (type === "listing") {
    // Paginated
    values.status = status;
    values.limit = limit;
    values.page = page;
    values.type = type;
  } else {
    // With out pagination [search result]
    values.status = status;
    values.type = type;
    values.searchterm = searchterm;
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('allproducts', data);
        if (type === "listing") {
          // Paginated
          dispatch({
            type: ALL_CANCELLEDORDER_LIST,
            payload: { data: response.orders, count: response.count },
          });
        } else {
          // With out pagination [search result]
          dispatch({
            type: ALL_CANCELLEDORDER_LIST,
            payload: { data: response.orders },
          });
        }
        // dispatch({
        //   type: ALL_CANCELLEDORDER_LIST,
        //   payload: { data: data.orders },

        // });
        // console.log('data544',payload );
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}
export function fetchallreplaceorderlist(
  session,
  status,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allorders`;
  let values = {};
  if (type === "listing") {
    // Paginated
    values.status = status;
    values.limit = limit;
    values.page = page;
    values.type = type;
  } else {
    // With out pagination [search result]
    values.status = status;
    values.type = type;
    values.searchterm = searchterm;
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('allproducts', data);
        if (type === "listing") {
          // Paginated
          dispatch({
            type: All_REPLACEORDER_LIST,
            payload: { data: response.orders, count: response.count },
          });
        } else {
          // With out pagination [search result]
          dispatch({
            type: All_REPLACEORDER_LIST,
            payload: { data: response.orders },
          });
        }
        // dispatch({
        //   type: ALL_CANCELLEDORDER_LIST,
        //   payload: { data: data.orders },

        // });
        // console.log('data544',payload );
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}
export function fetchallreturnorderList(
  session,
  status,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/allorders`;
  let values = {};
  if (type === "listing") {
    // Paginated
    values.status = status;
    values.limit = limit;
    values.page = page;
    values.type = type;
  } else {
    // With out pagination [search result]
    values.status = status;
    values.type = type;
    values.searchterm = searchterm;
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('allproducts', data);
        if (type === "listing") {
          // Paginated
          dispatch({
            type: All_RETURNEDORDER_LIST,
            payload: { data: response.orders, count: response.count },
          });
        } else {
          // With out pagination [search result]
          dispatch({
            type: All_RETURNEDORDER_LIST,
            payload: { data: response.orders },
          });
        }
        // dispatch({
        //   type: All_RETURNEDORDER_LIST,
        //   payload: { data: data.orders },

        // });
        // console.log('data544',payload );
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}

export function approveRefund(session, approvevalues, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/updateorder`;
  // console.log('values',url);
  return (dispatch) => {
    postRequest(
      url,
      approvevalues,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        callback({ success: 1, data: data });
      },
      (error) => {
        // callback({ error: 1, message: error });
      }
    );
  };
}
export function rejectRefund(session, rejectedvalues, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/updateorder`;
  // console.log('values',url);
  return (dispatch) => {
    postRequest(
      url,
      rejectedvalues,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        callback({ success: 1, data: data });
      },
      (error) => {
        // callback({ error: 1, message: error });
      }
    );
  };
}

// export function fetchallreturnorderList(
//   session,
//   status,
//   limit,
//   page,
//   type,
//   searchterm,
//   callback) {
//   var url=`${INSTANCE_URL}/api/v2/superadmin-datascript/allorders`
//   let values = {};
//   if (type === "listing") {
//     // Paginated
//     values.status =status;
//     values.limit = limit;
//     values.page = page;
//     values.type = type;
//   } else {
//     // With out pagination [search result]
//     values.status =status;
//     values.type = type;
//     values.searchterm = searchterm;
//   }
//   return (dispatch) => {
//     postRequest(
//       url,
//       values,
//       session,
//       dispatch,
//       (response) => {
//         // console.log('allproducts', data);
//         if (type === "listing") {
//           // Paginated
//           dispatch({
//             type: All_RETURNEDORDER_LIST,
//             payload: { data: response.orders, count: response.count },
//           });
//         } else {
//           // With out pagination [search result]
//           dispatch({
//             type: All_RETURNEDORDER_LIST,
//             payload: { data: response.orders },
//           });
//         }
//         // dispatch({
//         //   type: All_RETURNEDORDER_LIST,
//         //   payload: { data: data.orders },

//         // });
//         // console.log('data544',payload );
//         callback({ success: 1, data: response});
//       },
//       (error) => {}
//     );
//   };
// }

export function fetchBidsitemswonnotbought(
  session,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/wonbidsnotpurchased`;
  let values = {};
  if (type === "listing") {
    // Paginated
    values.limit = limit;
    values.page = page;
    // values.type = type;
  } else {
    // With out pagination [search result]

    values.searchterm = searchterm;
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('allproducts', data);
        if (type === "listing") {
          // Paginated
          dispatch({
            type: AUCTIONITESM_WONNOTBOUGHT,
            payload: { data: response.data, count: response.total },
          });
        } else {
          // With out pagination [search result]
          dispatch({
            type: AUCTIONITESM_WONNOTBOUGHT,
            payload: { data: response.data },
          });
        }

        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}

export function fetchordernotProcessed(
  session,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/ordernotprocessed`;
  let values = {};
  if (type === "listing") {
    // Paginated
    values.limit = limit;
    values.page = page;
    // values.type = type;
  } else {
    // With out pagination [search result]

    values.searchterm = searchterm;
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('allproducts', data);
        if (type === "listing") {
          // Paginated
          dispatch({
            type: ORDERSNOT_PROCESSED,
            payload: { data: response.data, count: response.total },
          });
        } else {
          // With out pagination [search result]
          dispatch({
            type: ORDERSNOT_PROCESSED,
            payload: { data: response.data },
          });
        }

        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}
export function wonbidsnotpurchasedreminder(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/wonbidsnotpurchasedreminder`;

  return (dispatch) => {
    postRequest(
      url,
      obj,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}
export function ordernotprocessedreminder(session, obj, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/ordernotprocessedreminder`;

  return (dispatch) => {
    postRequest(
      url,
      obj,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}
