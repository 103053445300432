import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../languages/index.js";
import { Fragment } from "react";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../common/base-component";
import { XIcon, TrashIcon } from "@heroicons/react/outline";
import { Field, FieldArray, reduxForm, reset, getFormValues } from "redux-form";
import "react-phone-input-2/lib/material.css";
import _ from "lodash";
import { isEmpty, classNames } from "../../../../common/common-functions";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
  DATE_FORMAT_WITH_TIME_WITH_SECOND,
} from "../../../../common/constants";
import { SketchPicker } from "react-color";
import {
  updateMainbodyForProduct,
  reduxUpdateHomePagebody,
} from "../actions/index";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
class ProductModalhomepage extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      static_image_linkedto: "",
      banner_For: "",
      fileUrl: "",
      fileArrNew: {},
      subcatfileUrl: "",
      subcatfileArr: {},
      addproduct_type: "",
      selectedColor: "",
      pickerVisible: false,
      btnLoader: false,
    };

    // All Binded Functions
  }
  componentDidMount() {
    this.setState({ selectedColor: this.props.bgcolor });
  }

  onSubmitForm(values) {
    // console.log('values123',values);
    if (values.productType == "manual" && values.data.length == 0) {
      NotificationManager.error("Add porduct to save ");
      return false;
    }
    values.bgcolor = this.state.selectedColor;
    let { index, homePageBody } = this.props;
    this.setState({ btnLoader: true });
    let manualData = [];
    if (values.productType == "manual") {
      values.selection_logic = "manual";
      if (values.data.length > 0) {
        delete values["error"];
        _.forEach(values.data, function (value, i) {
          // console.log('value1234567',value)
          let obj = {};
          obj.id = value.id.value;
          obj.productname_en = value.id.label;
          obj.productname_ar = value.id.label;
          obj.validitytill = moment(value.validitytill).format(
            DATE_FORMAT_WITH_TIME_WITH_SECOND
          );
          manualData.push(obj);
        });
        values.data = manualData;
      }
    } else if (values.productType == "logical") {
      delete values["error"];
      values.data = [];
    }
    // console.log('final values',values)
    this.props.updateMainbodyForProduct(
      values,
      homePageBody,
      index,
      (CallBackResponse) => {
        this.props.reduxUpdateHomePagebody(true);

        setTimeout(() => {
          this.setState({ btnLoader: false });
          NotificationManager.success("Saved Successfully");
          this.props.onClose();
        }, 3000);
      }
    );
  }

  closePicker = () => {
    this.setState({ pickerVisible: false });
  };

  openColorPicker = () => {
    if (!this.state.pickerVisible) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState((prevState) => ({
      pickerVisible: !prevState.pickerVisible,
    }));
    // this.setState({ pickerVisible: !this.state.pickerVisible });
  };

  handleOutsideClick = (e) => {
    if (!this.node.contains(e.target)) this.openColorPicker();
  };

  handleColorChange = ({ hex }) => {
    this.setState({ selectedColor: _.toUpper(hex) });
  };

  renderManualProductAdd = ({ fields, meta: { error, submitFailed } }) => (
    <ul>
      <li className="end-item" style={{ paddingTop: 20 }}>
        <button
          className=" end-item text-primaryLight cursor-pointer button"
          onClick={() => fields.push({})}>
          +Add New Product
        </button>
        {submitFailed && error && <span>{error}</span>}
      </li>
      <div className="grid grid-cols-2">
        {fields.map((manualproductadd, index) => {
          var allProductlistArr = [];
          _.forEach(this.props.allproductList, function (value) {
            let name = "";
            if (value.sku !== null) {
              name = "(" + value.sku + ")" + value.productname_en;
            } else {
              name = value.productname_en;
            }

            var obj = {
              // name: value.productname_en,
              label: name,
              value: value.id.toString(),
              // name: value.productname_en,
              // value: value.id,
            };

            allProductlistArr.push(obj);
          });

          return (
            <li
              key={index}
              className="mx-2"
              style={{
                border: "1px solid gray",
                borderRadius: "15px",
                marginTop: "15px",
              }}>
              <div className="end-item mb-2">
                <button
                  type="button"
                  title="Remove Member"
                  className="end-item"
                  onClick={() => fields.remove(index)}>
                  <TrashIcon
                    className="h-6 w-6 text-red-500"
                    aria-hidden="true"
                  />
                </button>
              </div>

              <Field
                name={`${manualproductadd}.id`}
                label="Product"
                placeholder={"Select"}
                options={allProductlistArr}
                component={this.renderAutoComplete}
                labelposition={LABEL_POSITION_TOP}
                // labelKey="label"
                // valueKey="value"
                className="mt-1 block w-full mb-4 pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                classNameLabel="block text-box-lable"
                isMulti={false}

                // name={`${manualproductadd}.id`}
                // label="Product"
                // placeholder={"Select"}
                // component={this.renderSelect}
                // mandatory="true"
                // opts={allProductlistArr}
                // className="mt-1 block w-full  pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md mb-5"
                // classNameLabel="block text-box-lable"
              />
              <div className="mt-5">
                <Field
                  name={`${manualproductadd}.validitytill`}
                  label="Validity Till"
                  component={this.renderDatePickerWithLabel1}
                  mandatory={false}
                  autoComplete="off"
                  // selected={moment()}
                  minDate={new Date(moment().format("YYYY,MM,DD"))}
                  className="appearance-none  block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                  classNameLabel=""
                />
              </div>
            </li>
          );
        })}
      </div>
    </ul>
  );

  handleaddProductTypeChange(e) {
    if (e.target.value === "manual") {
      this.setState({ addproduct_type: "manual" });
    } else {
      this.setState({ addproduct_type: "logical" });
    }
  }

  render() {
    // console.log('initialValues',this.props.initialValues);
    var { handleSubmit, initialValues } = this.props;
    //  console.log('this.state.contactNumber_render',initialValues)
    let { fileUrl, enar, addproduct_type, selectedColor } = this.state;
    if (initialValues && initialValues.hasOwnProperty("selection_logic")) {
      //EDIT
      selectedColor =
        selectedColor === "" ? initialValues.bgcolor : selectedColor;

      if (initialValues.selection_logic !== "manual") {
        initialValues.data = [];
        addproduct_type = addproduct_type === "" ? "logical" : addproduct_type;
        initialValues.productType = "logical";
      } else {
        addproduct_type = addproduct_type === "" ? "manual" : addproduct_type;
        initialValues.productType = "manual";
      }
    } else {
      initialValues.productType = "logical";
      addproduct_type = addproduct_type === "" ? "logical" : addproduct_type;
      addproduct_type = addproduct_type === "" ? "#fafafa" : addproduct_type;
      selectedColor =
        selectedColor === "" ? initialValues.bgcolor : selectedColor;
    }
    // banner_For =
    // banner_For === "" ? initialValues.headerType  ? : discounttype;
    let coverimage = "";
    if (isEmpty(fileUrl) && initialValues && initialValues.staticdata) {
      coverimage = `${BASE_IMAGES_URL}/${initialValues.staticdata.image}?api_key=${APP_API_KEY}`;
      //coverimage = `${BASE_IMAGES_URL}/store/${initialValues.coverimage}?api_key=${APP_API_KEY}`;
    } else {
      coverimage = fileUrl;
    }

    var linkstaticImageToArr = [];
    var allProductlistArr = [];
    var productCard_type = [];
    var product_Limit = [];
    var allproduct_ListArr = [];
    var logicalConditionArr = [];

    _.forEach(this.props.linkImageTo, function (value) {
      var obj = {
        name: value.name,
        value: value.value,
      };

      linkstaticImageToArr.push(obj);
    });
    _.forEach(this.props.allproductList, function (value) {
      var obj = {
        name: value.productname_en,
        value: value.id,
      };

      allProductlistArr.push(obj);
    });

    _.forEach(this.props.productCard_type, function (value) {
      var obj = {
        name: value.name,
        value: value.value,
      };

      productCard_type.push(obj);
    });

    _.forEach(this.props.product_Limit, function (value) {
      var obj = {
        name: value.name,
        value: value.value,
      };

      product_Limit.push(obj);
    });
    _.forEach(this.props.allproductList, function (value) {
      var obj = {
        name: value.productname_en,
        value: value.id,
      };

      allproduct_ListArr.push(obj);
    });
    _.forEach(this.props.logicalProductadd, function (value) {
      var obj = {
        name: value.name,
        value: value.value,
      };

      logicalConditionArr.push(obj);
    });
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.myRef}
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 bigmodal">
                  <div className="grid grid-cols-3 mb-10 border-b-2 border-gray-50 pb-4">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center">
                      Edit Product
                    </div>
                    <div className="flex flex-wrap content-center justify-end">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}
                        ref={this.myRef}>
                        <span className="sr-only"></span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className="space-y-8 divide-y divide-gray-200 mt-5"
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                    encType="multipart/form-data">
                    <div className="flex">
                      <div className="w-4/12">
                        <Field
                          name="display_name"
                          type="text"
                          component={this.renderAllfields}
                          label={"Display Name"}
                        />
                      </div>
                      <div className="w-4/12 ml-8">
                        <Field
                          name="card_type"
                          label="Card Type"
                          placeholder={"Select"}
                          component={this.renderSelect}
                          mandatory="true"
                          opts={productCard_type}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                        />
                      </div>
                    </div>
                    <div
                      className="flex mt-5 mb-5"
                      style={{ borderColor: "#fff" }}>
                      <div className="w-4/12">
                        <Field
                          name="item_limit"
                          label="Product Limit"
                          placeholder={"Select"}
                          component={this.renderSelect}
                          mandatory="true"
                          opts={product_Limit}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                        />
                      </div>
                      <div
                        className="w-4/12 ml-8"
                        ref={(node) => {
                          this.node = node;
                        }}>
                        <span className="flex">
                          <div
                            className="h-12 w-24"
                            style={{ background: selectedColor }}></div>
                          <button
                            className="button h-12 ml-4"
                            type="button"
                            onClick={this.openColorPicker}>
                            Change Color
                          </button>
                        </span>
                        {this.state.pickerVisible == true ? (
                          <SketchPicker
                            color={selectedColor}
                            onChangeComplete={this.handleColorChange}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="flex mt-5" style={{ borderColor: "#fff" }}>
                      <div>Add Product</div>
                      <div
                        className="end-item mr-10"
                        style={{ marginLeft: "auto" }}>
                        {_.map(this.props.productType, (opt, index) => {
                          return (
                            <Field
                              name="productType"
                              label={opt.name}
                              value={opt.value}
                              onChange={this.handleaddProductTypeChange.bind(
                                this
                              )}
                              lebelAlign="R"
                              type="radio"
                              component={this.renderFieldRadio1}
                            />
                          );
                        })}
                      </div>
                    </div>

                    {addproduct_type == "logical" ? (
                      <div className="w-4/12" style={{ borderColor: "#fff" }}>
                        <Field
                          name="selection_logic"
                          label="Selection Logic"
                          placeholder={"Select"}
                          component={this.renderSelect}
                          mandatory="true"
                          opts={logicalConditionArr}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                        />
                      </div>
                    ) : (
                      <FieldArray
                        name="data"
                        component={this.renderManualProductAdd}
                      />
                    )}
                    <div className="pt-5">
                      <div className="flex justify-end">
                        <div className="tooltip mt-2">
                          <img
                            loading="lazy"
                            src="../images/eventinfo.svg"
                            class="cc-sidebar-logo"
                          />
                          <span className="tooltiptextleft">
                            Save main homepage to see preview
                          </span>
                        </div>
                        <button
                          type="submit"
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "sr-only",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          Save Product
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <NotificationContainer />
      </>
    );
  }
}
function validate(values) {
  // console.log('values',values)
  // var errors = {};
  var errors = { data: [] };
  var requiredFields = ["item_limit", "card_type", "display_name"];

  var data = values["data"];
  if (values.productType == "manual") {
    data?.forEach((v, i) => {
      errors.data.push({});
      if (!v?.productid || v?.productid === "") {
        errors.data[i].productid = "Required";
      }
      if (!v?.validitytill || v?.validitytill === "") {
        errors.data[i].validitytill = "Required";
      }
    });
  } else {
    requiredFields = [
      "item_limit",
      "card_type",
      "display_name",
      "selection_logic",
    ];
  }

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
  });
  // console.log("errors :- ", errors);
  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  // debugger;
  return {
    session,
    user,
    linkImageTo: state.linkImageTo,
    headerView: state.headerView,
    allproductList: state.allproductList ? state.allproductList.data : [],
    formValues: getFormValues("ProductModalhomepageForm")(state),
    initialValues: state.productDataupdate,
    productCard_type: state.productCard_type,
    product_Limit: state.product_Limit,
    productType: state.productType,
    logicalProductadd: state.logicalProductadd,
    homePageBody: state.homePageBody,
  };
}

export default connect(mapStateToProps, {
  updateMainbodyForProduct,
  reduxUpdateHomePagebody,
})(
  reduxForm({
    validate,
    form: "ProductModalhomepageForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ProductModalhomepage)
);
