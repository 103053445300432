import { INSTANCE_URL, itemCount } from "../../../../common/constants";
import {
  getRequest,
  postRequest,
  patchRequest,
} from "../../../../common/network-call";
import LocaleStrings from "../../../languages";
import _ from "lodash";

export var ALL_VARIATIONS = "ALL_VARIATIONS";

// Data
export const ADDSMANAGEMENT_PRODUCTCATEGORY = "ADDSMANAGEMENT_PRODUCTCATEGORY";
export const ADDSMANAGEMENT_PRODUCTSUBCATEGORY =
  "ADDSMANAGEMENT_PRODUCTSUBCATEGORY";
export const ADDSMANAGEMENT_PRODUCTLIST = "ADDSMANAGEMENT_PRODUCTLIST";
export const EDITVARIATIONDATA = "EDITVARIATIONDATA";
export const MASTERVARIATION_PRODUCTCATEGORY =
  "MASTERVARIATION_PRODUCTCATEGORY";
export const MASTERVARIATION_PRODUCTSUBCATEGORY =
  "MASTERVARIATION_PRODUCTSUBCATEGORY";

export function fetchVariations(
  session,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/variationmaster`;
  let values = {};
  // console.log('type',type)
  if (type === "listing") {
    // Paginated

    values.limit = limit;
    values.page = page;
    values.type = type;
  } else {
    // With out pagination [search result]

    values.type = type;
    values.searchterm = searchterm;
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (type === "listing") {
          // Paginated
          dispatch({
            type: ALL_VARIATIONS,
            payload: { data: response.data, count: response.count },
          });
        } else {
          // With out pagination [search result]
          dispatch({
            type: ALL_VARIATIONS,
            payload: { data: response.data },
          });
        }
        // console.log('negotiation', data);
        // dispatch({
        //   type: SPECS_LIST,
        //   payload: { data: data.data },

        // });
        callback({ success: 1, data: response });
      },
      (error) => {}
    );
  };
}

export function addVariations(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/shopez/_table/variation_master`;
  // console.log('newAddArrdata',newAddArrdata)
  let body = { resource: { ...values } };
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

export function onEditvariation(values) {
  if (Object.keys(values).length > 0) {
    values["idsubcategory"] = values.subcategoryid;
    let categoryids = [];

    _.forEach(values.categories, function (value) {
      var obj = {
        label: value.categoryname_en,
        value: value.id.toString(),
      };

      categoryids.push(obj);
    });

    values["idcategory"] = categoryids;
    let subcategoryids = [];
    _.forEach(values.subcategories, function (value) {
      var obj = {
        label: value.subcategoryname_en,
        value: value.id.toString(),
      };

      subcategoryids.push(obj);
    });
    values["idsubcategory"] = subcategoryids;

    // value formation for renderautocomplete
    let value = [];
    if (
      values.value_en !== "" &&
      values.value_ar !== null &&
      values.value_ar !== "" &&
      values.value_ar !== null
    ) {
      let value_enArr = _.split(values.value_en, ",");
      let value_arArr = _.split(values.value_ar, ",");
      value = value_enArr.map((val, index) => {
        // Use the Object.assign() method to create a new object
        // with the properties from the original arrays
        return Object.assign(
          {},
          {
            value_en: val,
            value_ar: value_arArr[index],
          }
        );
      });
    }
    values["value"] = value;
    values["value_en1"] = values.value_en;
    values["value_ar1"] = values.value_ar;
    values["value_en"] = "";
    values["value_ar"] = "";
  }
  return (dispatch) => {
    dispatch({ type: EDITVARIATIONDATA, payload: values });
  };
}

export function updateVariation(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/shopez/_table/variation_master`;
  // console.log('newAddArrdata',newAddArrdata)
  let body = { resource: { ...values } };
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

export function fetchProductCategory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productcategory?filter=(isactive=1)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: MASTERVARIATION_PRODUCTCATEGORY,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Product Subcategory Data ****/
export function fetchProductSubcategory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productsubcategory?filter=(isactive=1)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: MASTERVARIATION_PRODUCTSUBCATEGORY,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Cat & Subcat Data Together ****/
export function fetchvariationsallCategory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/productmanagement/categorylist`;

  return (dispatch) => {
    postRequest(
      url,
      {},
      session,
      dispatch,
      (response) => {
        let subcateArr = [];

        _.forEach(response.resource, (val, index) => {
          if (
            val.productsubcategory_by_categoryid &&
            val.productsubcategory_by_categoryid.length > 0
          ) {
            _.forEach(val.productsubcategory_by_categoryid, (v, i) => {
              subcateArr.push(v);
            });
          }
        });
        // console.log("subcateArr", subcateArr);

        dispatch({
          type: MASTERVARIATION_PRODUCTCATEGORY,
          payload: { data: response.resource },
        });
        dispatch({
          type: MASTERVARIATION_PRODUCTSUBCATEGORY,
          payload: { data: subcateArr },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Cat & Subcat Data Together ****/
export function productcountbyvariant(session, variantid, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/productcountbyvariant`;

  let values = {};
  values.mastervariantid = variantid;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Cat & Subcat Data Together ****/
export function deleteVariant(session, variantid, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/deletevariant`;

  let values = {};
  values.mastervariantid = variantid;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}
