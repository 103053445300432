import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../../../common/base-component";
import _, { values } from "lodash";
import { classNames } from "../../../../../../common/common-functions";
import {
  updateShippingCharges,
  fetchShippingCharges,
} from "../../../actions/index";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

class EditGlobalCharges extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      btnLoader: false,
      selectedzoneorpin: "",
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }
  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
    this.props.reset();
  }

  onSubmitForm(values) {
    this.setState({ btnLoader: true });
    // if(this.state.selectedzoneorpin == "pin_code"){
    //   if( values.pin_code == ''){
    //     NotificationManager.error('Please Add Pin Code');
    //     this.setState({ btnLoader: false });
    //     return false;
    //   }
    // }else{
    //   if(values.zone == ''){
    //     NotificationManager.error('Please Add Zone');
    //     this.setState({ btnLoader: false });
    //     return false;

    //   }
    // }
    // if(this.state.selectedzoneorpin == "pin_code"){
    //   var data = {
    //     id:values.id,
    //     district: values.district,
    //     charges: values.charges,
    //     pin_code: values.pin_code,
    //     zone:null
    //   };

    //   this.props.updateShippingCharges(this.props.session, data, (response) => {
    //     if (response.success === 0) {
    //       this.setState({ loading: false });
    //       // alert("error");
    //       NotificationManager.error(LocaleStrings.addingfailed);
    //     } else {
    //       this.props.fetchShippingCharges(
    //         this.props.session,
    //         (CallBackResponse) => {}
    //       );
    //       this.setState({ open: false });
    //       this.props.onClose();
    //       // alert("done");
    //       this.props.reset();
    //       NotificationManager.success(LocaleStrings.addedsucessfully);
    //     }
    //   });

    // }else{

    var data = {
      id: values.id,
      // district: values.district,
      charges: values.charges,
      // zone: values.zone,
      // pin_code:null
    };

    this.props.updateShippingCharges(this.props.session, data, (response) => {
      if (response.success === 0) {
        this.setState({ loading: false });
        this.setState({ btnLoader: false });
        // alert("error");
        NotificationManager.error(LocaleStrings.addingfailed);
      } else {
        this.props.fetchShippingCharges(
          this.props.session,
          (CallBackResponse) => {}
        );
        this.setState({ btnLoader: false });
        this.setState({ open: false });
        this.props.onClose();
        this.props.reset();
        NotificationManager.success(LocaleStrings.addedsucessfully);
      }
    });

    // }
    // this.setState({ btnLoader: true });
    // let pincodesComaseparated = [];
    // values.pin_codes.map((val, key) => {
    //   let obj = val.value;
    //   pincodesComaseparated.push(obj);
    // });
    // var selectedPincodes = pincodesComaseparated.join(",");

    // var data = {
    //   district: values.district,
    //   charges: values.charges,
    //   pin_code: selectedPincodes,
    //   id: this.props.initialValues.id,
    // };

    // this.props.updateShippingCharges(this.props.session, data, (response) => {
    //   if (response.success === 0) {
    //     this.setState({ loading: false });
    //     // alert("error");
    //     NotificationManager.error(LocaleStrings.addingfailed);
    //   } else {
    //     this.props.fetchShippingCharges(
    //       this.props.session,
    //       (CallBackResponse) => {}
    //     );
    //     this.props.onClose();
    //     // alert("done");
    //     NotificationManager.success(LocaleStrings.addedsucessfully);
    //   }
    // });
  }

  _handleposition(e) {
    let mandateType = "";
    if (e.target.value != "" && e.target.value != null) {
      mandateType = _.find(this.props.zoneorPin, {
        value: e.target.value,
      }).value;
    }
    this.setState({ selectedzoneorpin: mandateType });
  }

  render() {
    var { handleSubmit } = this.props;

    let { selectedzoneorpin } = this.state;

    if (
      this.props.initialValues &&
      this.props.initialValues.zoneorpin == "zone"
    ) {
      selectedzoneorpin = selectedzoneorpin === "" ? "zone" : selectedzoneorpin;
    } else {
      selectedzoneorpin =
        selectedzoneorpin === "" ? "pin_code" : selectedzoneorpin;
    }

    var districtsOpts = [];
    var zonecodesOpts = [];
    var zoneorPincodesOpts = [];
    _.forEach(this.props.DummyDistricts, function (value) {
      var obj = {
        name: value.name,
        // value: value.id.toString(),
        value: value.value,
      };

      districtsOpts.push(obj);
    });

    _.forEach(this.props.zonemasterList.data, function (value) {
      var obj = {
        name: value.zone,
        value: value.id,
      };

      zonecodesOpts.push(obj);
    });

    _.forEach(this.props.zoneorPin, function (value) {
      var obj = {
        name: value.name,
        value: value.value,
      };

      zoneorPincodesOpts.push(obj);
    });
    console.log("selectedzoneorpin", selectedzoneorpin);
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                  style={{ padding: 0 }}>
                  {/* MOdal Header */}
                  <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div className="flex flex-wrap content-center justify-center mt-4">
                      <div className="text-lg leading-6 font-medium text-secondary">
                        Edit Shipping Charges
                      </div>
                    </div>
                    <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.props.onClose}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className=" p-6"
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
                    <div className="mb-5">
                      {/* <Field
                          name="district"
                          label="Select District"
                          placeholder={LocaleStrings.select}
                          component={this.renderSelect}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          opts={districtsOpts}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                        /> */}
                    </div>
                    {/* <div className="mb-5">
                        <Field
                          name="zoneorpin"
                          label="Select Zone / Pin"
                          placeholder={LocaleStrings.select}
                          component={this.renderSelect}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          onChange={this._handleposition.bind(this)}
                          opts={zoneorPincodesOpts}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                        />
                      </div> */}
                    {/* {selectedzoneorpin == 'zone' ? (
                         <div className="mb-5">
                         <Field
                           name="zone"
                           label="Select Zone"
                           placeholder={LocaleStrings.select}
                           component={this.renderSelect}
                           mandatory="true"
                          //  isdisabled="true"
                           labelposition={LABEL_POSITION_TOP}
                           opts={zonecodesOpts}
                           className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                           classNameLabel="block text-box-lable"
                         />
                       </div>
                      ):selectedzoneorpin == 'pin_code' ?(
                        <div className="mb-5">
                      <Field
                        name="pin_code"
                        placeholder="Pincode"
                        label="Pincode"
                        type="text"
                        component={this.renderAllfields}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                      </div>
                      ):('')} */}

                    <div>
                      <Field
                        name="charges"
                        label="Set Price(QAR)"
                        placeholder="Set Price(QAR)"
                        type="number"
                        mandatory="true"
                        component={this.renderAllfields}
                        className="appearance-none block w-half px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                    </div>

                    <div className="my-10">
                      <div className="flex justify-center">
                        <button type="submit" className="button">
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "sr-only",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          Update Charge
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = ["charges"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
  });

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;

  let valueObj = state.editCharges;
  let initvals = {};
  if (valueObj) {
    if (valueObj.pin_code != null) {
      valueObj.zoneorpin = "pin_code";
    } else {
      valueObj.zoneorpin = "zone";
    }
    initvals = valueObj;
  }
  return {
    session,
    user,
    language: state.language,
    DummyDistricts: state.DummyDistricts,
    DummyPin_codes: state.DummyPin_codes,
    initialValues: initvals,
    zonemasterList: state.zonemasterList,
    zoneorPin: state.zoneorPin,
  };
}

export default connect(mapStateToProps, {
  updateShippingCharges,
  fetchShippingCharges,
})(
  reduxForm({
    validate,
    form: "EditGlobalCharges",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EditGlobalCharges)
);
