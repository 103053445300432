import React, { Component } from "react";
import { connect } from "react-redux";
import AllGlobalTabsList from "./globaltabs";
import { fetchglobalCommision } from "../actions/index";
import BaseLoader from "../../../../common/base-loader";

class GlobalSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: "Preparing Global Settings",
    };

    // All Binded Functions
  }
  componentDidMount() {
    this.props.fetchglobalCommision(this.props.session, (CallBackResponse) => {
      this.setState({ loading: false });
      this.props.history.push("/dashboard/globalsettings/charges");
    });
  }

  customerdetails = (e, data) => {
    //  console.log("this.props.data", this.props.data)
    this.setState({ showUploadSection: !this.state.showUploadSection });
    //   this.props.data();
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <AllGlobalTabsList
              showUploadSection={this.customerdetails}
              history={this.props.history}
            />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, {
  fetchglobalCommision,
})(GlobalSettings);
