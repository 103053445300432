import {
  INSTANCE_URL,
  itemCount,
  BASE_IMAGES_URL,
} from "../../../../common/constants";
import {
  getRequest,
  postRequest,
  patchRequest,
  postRequestnew,
  uploadEncodedFileLarge,
  postRequestAccess,
} from "../../../../common/network-call";
import LocaleStrings from "../../../languages";

export var SELLER_LIST = "SELLER_LIST";
export var SELLER_TABID = "SELLER_TABID";
export var SELLER_NEWS = "SELLER_NEWS";
export var EDIT_SELLERMODAL = "EDIT_SELLERMODAL";
export var SELLER_PRODUCT_LIST = "SELLER_PRODUCT_LIST";
export var SELLER_ODER_LIST = "SELLER_ODER_LIST";
export var SELLERDELIVER_ODER_LIST = "SELLERDELIVER_ODER_LIST";
export var SELLERDISPUTED_ODER_LIST = "SELLERDISPUTED_ODER_LIST";
export var NEGOTIATIONSELLER_LIST = "NEGOTIATIONSELLER_LIST";
export var STORE_DETAILS = "STORE_DETAILS";
export var SALESMANAGEMENT_STATBOX_SELECTED =
  "SALESMANAGEMENT_STATBOX_SELECTED";
export var IS_NEGO_SLIDEOVERS_MODAL_OPEN = "IS_NEGO_SLIDEOVERS_MODAL_OPEN";
export var NEGOTIATIONSELLERDETAILS_LIST = "NEGOTIATIONSELLERDETAILS_LIST";
export var NEGOTIATIONSELLER_INITIATED_LIST =
  "NEGOTIATIONSELLER_INITIATED_LIST";
export var NEGOTIATIONSELLER_CLOSED_LIST = "NEGOTIATIONSELLER_CLOSED_LIST";
export var PROFILESETTINGS_STORECONTACTS = "PROFILESETTINGS_STORECONTACTS";
export var SIMILAR_PRODUCTS = "SIMILAR_PRODUCTS";
export var FETCH_SELLER_SEARCH = "FETCH_SELLER_SEARCH";
export var SELLER_AUCTION_LIST = "SELLER_AUCTION_LIST";
export var SELLER_AUCTION_LIST_WON = "SELLER_AUCTION_LIST_WON";

export const IS_PRODUCT_COMMENTS_MODAL_OPEN = "IS_PRODUCT_COMMENTS_MODAL_OPEN";
export const COMMENT_LIST = "COMMENT_LIST";
export const TRANSACTION_LIST_SELLER = "TRANSACTION_LIST_SELLER";
export const SELLER_PRODUCT_LIST_SALE = "SELLER_PRODUCT_LIST_SALE";
export const SELLER_PRODUCT_LIST_AUCTION = "SELLER_PRODUCT_LIST_AUCTION";
export const IS_PRODUCT_LIKES_MODAL_OPEN = "IS_PRODUCT_LIKES_MODAL_OPEN";
export const PRODUCTLIKES_LIST = "PRODUCTLIKES_LIST"; // Product Likes
export const SINGLESELLER = "SINGLESELLER"; // Product Likes

export function sellerTabid(obj) {
  return (dispatch) => {
    dispatch({ type: SELLER_TABID, payload: obj });
  };
}

export function newtabseller(menu = false) {
  return (dispatch) => {
    dispatch({ type: SELLER_NEWS, payload: menu });
  };
}
export function editsellermodal(open = false) {
  return (dispatch) => {
    dispatch({ type: EDIT_SELLERMODAL, payload: open });
  };
}

export function fetchSeller(session, pageCount = 0, callfrom, callback) {
  var perPage = 7;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;
  var orderby = `createdon%20DESC`;
  // var filter =
  if (callfrom == "verified") {
    var filter = encodeURI(
      `(((usertype=vendor)OR(usertype=personal)OR(usertype=homebiz))AND(verified=1))`
    );
  } else if (callfrom == "unverified") {
    var filter = encodeURI(
      `(((usertype=vendor) OR (usertype=homebiz) OR (usertype=personal))AND(verified=0)AND((agreement_template is null)OR(agreement_template='')))`
    );
  } else {
    var filter = encodeURI(
      `(((usertype=vendor) OR (usertype=homebiz) OR (usertype=personal))AND(verified=0)AND((agreement_template is not null)OR(agreement_template !='')))`
    );
  }

  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?include_count=true&filter=${filter}&${offSetQuery}&order=${orderby}`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('sellerlist', data);
        dispatch({
          type: SELLER_LIST,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchSeller(session, search = "", callfrom, pageCount = 0) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${itemCount}&offset=${offset}`;

  // var searchQuery = `((username like %${search}%)OR(email like %${search}%))AND((usertype=vendor)OR(usertype=personal)OR(usertype=homebiz))`;
  // var filter = encodeURI(`(${searchQuery})AND(${filterNew})`);

  if (callfrom == "verified") {
    var filter = encodeURI(
      `(((username like %${search}%)OR(email like %${search}%))AND((usertype=vendor)OR(usertype=personal)OR(usertype=homebiz))AND(verified=1))`
    );
  } else if (callfrom == "unverified") {
    var filter = encodeURI(
      `(((username like %${search}%)OR(email like %${search}%))AND((usertype=vendor) OR (usertype=homebiz) OR (usertype=personal))AND(verified=0)AND((agreement_template is null)OR(agreement_template='')))`
    );
  } else {
    var filter = encodeURI(
      `(((username like %${search}%)OR(email like %${search}%))AND((usertype=vendor) OR (usertype=homebiz) OR (usertype=personal))AND(verified=0)AND((agreement_template is not null)OR(agreement_template !='')))`
    );
  }
  // var url = `${INSTANCE_URL}/api/v2/eventify/_table/sponsor?related=membershiptype_by_categoryid,appuser_by_sponsoridfk&include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;

  return (dispatch) => {
    // dispatch(fetchSeller(session));
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log("SPONSOR: ", data);
        dispatch({
          type: SELLER_LIST,
          payload: { data: data.resource, count: data.meta.count },
        });
      },
      (error) => {}
    );
  };
}

export function addnewSeller(session, sellerdata, callback) {
  // console.log('dfdata',sellerdata);
  let url = `${INSTANCE_URL}/api/v2/user/register?login=true`;

  return (dispatch) => {
    postRequestnew(
      url,
      sellerdata,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

export function uploadMediaLargeSellerDocs(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else if (filetype == "image") {
    var blob = new Blob([filecontent], { type: "image/jpeg" });
  } else {
    var blob = new Blob([filecontent], { type: filetype });
  }

  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${BASE_IMAGES_URL}/store/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function updateSeller(session, sellerdata, callback) {
  var body = { resource: { ...sellerdata } };
  // console.log('body',body)
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // dispatch(fetchSeller(session));
        callback({ success: 1 });
      },
      (error) => {
        // callback({ success: 0 });
      }
    );
  };
}

export function fetchProductList(session, userid, pageNumber, callback) {
  var perPage = 7;
  var offset = pageNumber;

  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/fetchmyproducts`;
  return (dispatch) => {
    postRequest(
      url,
      {
        userid: userid,
        usertype: "superadmin",
        limit: perPage,
        page: offset,
        tab: "all",
      },
      session,
      dispatch,
      (response) => {
        //  console.log('resprod',response.products)
        dispatch({
          type: SELLER_PRODUCT_LIST,
          payload: { data: response.products, count: response.totalrows },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}
export function searchAllitemProducts(session, userid, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/fetchmyproducts`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        userid: userid,
        usertype: "superadmin",
        type: "search",
        searchterm: search,
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: SELLER_PRODUCT_LIST,
          payload: { data: data.products },
        });
      },
      (error) => {}
    );
  };
}
export function fetchProductListSale(session, userid, pageNumber, callback) {
  var perPage = 7;
  var offset = pageNumber;

  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/fetchmyproducts`;
  return (dispatch) => {
    postRequest(
      url,
      {
        userid: userid,
        usertype: "superadmin",
        limit: perPage,
        page: offset,
        tab: "directsale",
      },
      session,
      dispatch,
      (response) => {
        //  console.log('resprod',response.products)
        dispatch({
          type: SELLER_PRODUCT_LIST_SALE,
          payload: { data: response.products, count: response.totalrows },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}
export function searchSaleProducts(session, userid, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/fetchmyproducts`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        userid: userid,
        usertype: "superadmin",
        type: "search",
        searchterm: search,
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: SELLER_PRODUCT_LIST_SALE,
          payload: { data: data.products },
        });
      },
      (error) => {}
    );
  };
}
export function fetchProductListAuction(session, userid, pageNumber, callback) {
  var perPage = 7;
  var offset = pageNumber;

  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/fetchmyproducts`;
  return (dispatch) => {
    postRequest(
      url,
      {
        userid: userid,
        usertype: "superadmin",
        limit: perPage,
        page: offset,
        tab: "auction",
      },
      session,
      dispatch,
      (response) => {
        //  console.log('resprod',response.products)
        dispatch({
          type: SELLER_PRODUCT_LIST_AUCTION,
          payload: { data: response.products, count: response.totalrows },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}
export function searchAuctionProducts(session, userid, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/fetchmyproducts`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        userid: userid,
        usertype: "superadmin",
        type: "search",
        searchterm: search,
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: SELLER_PRODUCT_LIST_AUCTION,
          payload: { data: data.products },
        });
      },
      (error) => {}
    );
  };
}

export function fetchnewSellerOder(session, userid, pageNumber, callback) {
  var perPage = 7;
  var offset = pageNumber;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/orders`;
  return (dispatch) => {
    postRequest(
      url,
      {
        userid: userid,
        status: "new",
        usertype: "superadmin",
        limit: perPage,
        page: offset,
      },
      session,
      dispatch,
      (data) => {
        // console.log('oderdataseller', data.orders);
        dispatch({
          type: SELLER_ODER_LIST,
          payload: { data: data.orders, count: data.totalrows },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchNewsellerorders(session, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/orders`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        searchterm: search,
        status: "new",
        usertype: "superadmin",
        type: "search",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: SELLER_ODER_LIST,
          payload: { data: data.orders },
        });
      },
      (error) => {}
    );
  };
}

export function fetchdeliveredSellerOder(
  session,
  userid,
  pageNumber,
  callback
) {
  // console.log('sessiontoken', session);
  var perPage = 7;
  var offset = pageNumber;

  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/orders`;
  return (dispatch) => {
    postRequest(
      url,
      {
        userid: userid,
        status: "delivered",
        usertype: "superadmin",
        limit: perPage,
        page: offset,
      },
      session,
      dispatch,
      (data) => {
        // console.log('oderdataseller', data.orders);
        dispatch({
          type: SELLERDELIVER_ODER_LIST,
          payload: { data: data.orders, count: data.totalrows },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchDeliveredsellerorders(session, userid, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/orders`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        userid: userid,
        searchterm: search,
        status: "delivered",
        usertype: "superadmin",
        type: "search",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: SELLERDELIVER_ODER_LIST,
          payload: { data: data.orders },
        });
      },
      (error) => {}
    );
  };
}

export function fetchdisputedSellerOder(session, userid, pageNumber, callback) {
  // console.log('sessiontoken', session);
  var perPage = 7;
  var offset = pageNumber;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/orders`;
  return (dispatch) => {
    postRequest(
      url,
      {
        userid: userid,
        status: "disputed",
        usertype: "superadmin",
        limit: perPage,
        page: offset,
      },
      session,
      dispatch,
      (data) => {
        // console.log('oderdataseller', data);
        dispatch({
          type: SELLERDISPUTED_ODER_LIST,
          payload: { data: data.orders, count: data.totalrows },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchDisputedsellerorders(session, userid, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/orders`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        userid: userid,
        searchterm: search,
        status: "disputed",
        usertype: "superadmin",
        type: "search",
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: SELLERDISPUTED_ODER_LIST,
          payload: { data: data.orders },
        });
      },
      (error) => {}
    );
  };
}

export function fetchNegotiationseller(
  session,
  userid,
  pageNumber,
  type,
  searchterm,
  callback
) {
  var offset = pageNumber;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/negotiations`;
  // console.log('url',url);
  let values = {};
  if (type === "listing") {
    // Paginated
    values.usertype = "admin";
    values.userid = userid;
    values.type = "listing";
    values.limit = "10";
    values.page = offset;
    values.status = "negotiated";
    values.subtype = "vendor";
    // values.status = "won";
    // usertype: "admin",
    // userid: userid,
    // type: "listing",
    // limit: "10",
    // page: offset,
    // status: "initiated",
  } else {
    // With out pagination [search result]
    values.usertype = "admin";
    values.userid = userid;
    values.type = "search";
    values.searchterm = searchterm;
    values.status = "negotiated";
    values.subtype = "vendor";
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      // {
      //   usertype: "admin",
      //   userid: userid,
      //   type: "listing",
      //   limit: "10",
      //   page: offset,
      //   status: "negotiated",
      // },
      session,
      dispatch,
      (data) => {
        // console.log('negotiation', data);
        dispatch({
          type: NEGOTIATIONSELLER_LIST,
          payload: { data: data.data, count: data.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchNegotiationsellerinitiated(
  session,
  userid,
  pageNumber,
  type,
  searchterm,
  callback
) {
  var offset = pageNumber;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/negotiations`;
  // console.log('url',url);

  let values = {};
  if (type === "listing") {
    // Paginated
    values.usertype = "admin";
    values.userid = userid;
    values.type = "listing";
    values.limit = "10";
    values.page = offset;
    values.status = "initiated";
    values.subtype = "vendor";
    // values.status = "won";
    // usertype: "admin",
    // userid: userid,
    // type: "listing",
    // limit: "10",
    // page: offset,
    // status: "initiated",
  } else {
    // With out pagination [search result]
    values.usertype = "admin";
    values.userid = userid;
    values.type = "search";
    values.searchterm = searchterm;
    values.status = "initiated";
    values.subtype = "vendor";
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      // {
      //   usertype: "admin",
      //   userid: userid,
      //   type: "listing",
      //   limit: "10",
      //   page: offset,
      //   status: "initiated",
      // },
      session,
      dispatch,
      (data) => {
        // console.log('negotiation', data);
        dispatch({
          type: NEGOTIATIONSELLER_INITIATED_LIST,
          payload: { data: data.data, count: data.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
export function fetchNegotiationsellerclosed(
  session,
  userid,
  pageNumber,
  type,
  searchterm,
  callback
) {
  var offset = pageNumber;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/negotiations`;
  // console.log('url',url);
  let values = {};
  if (type === "listing") {
    // Paginated
    values.usertype = "admin";
    values.userid = userid;
    values.type = "listing";
    values.limit = "10";
    values.page = offset;
    values.status = "closed";
    values.subtype = "vendor";
    // values.status = "won";
    // usertype: "admin",
    // userid: userid,
    // type: "listing",
    // limit: "10",
    // page: offset,
    // status: "initiated",
  } else {
    // With out pagination [search result]
    values.usertype = "admin";
    values.userid = userid;
    values.type = "search";
    values.searchterm = searchterm;
    values.status = "closed";
    values.subtype = "vendor";
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      // {
      //   usertype: "admin",
      //   userid: userid,
      //   type: "listing",
      //   limit: "10",
      //   page: offset,
      //   status: "closed",
      // },
      session,
      dispatch,
      (data) => {
        // console.log('negotiation', data);
        dispatch({
          type: NEGOTIATIONSELLER_CLOSED_LIST,
          payload: { data: data.data, count: data.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
// export function fetchStoredetails(session,userid, callback) {
//   var url=`${INSTANCE_URL}/api/v2/shopez/_table/store?filter=(createdby=${userid})`
//   return (dispatch) => {
//     getRequest(
//       url,
//       session,
//       dispatch,
//       (data) => {
//         // console.log('storedetails.data', data.resource[0]);
//         dispatch({
//           type: STORE_DETAILS,
//           payload: { data: data.resource[0] },

//         });
//         callback({ success: 1, data: data });
//       },
//       (error) => {}
//     );
//   };
// }

export function fetchNegotiationsellerdetails(session, threadid, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/negotiationdetails`;
  // console.log('threadid',threadid);
  return (dispatch) => {
    postRequest(
      url,
      {
        threadid: threadid,
        usertype: "admin",
      },
      session,
      dispatch,
      (data) => {
        // console.log('negotiation', data);
        dispatch({
          type: NEGOTIATIONSELLERDETAILS_LIST,
          payload: { data: data.data },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function salesmanagementStatboxSelected(obj = "") {
  return { type: SALESMANAGEMENT_STATBOX_SELECTED, payload: obj };
}

export function isNegotiationOpen(isOpen) {
  return { type: IS_NEGO_SLIDEOVERS_MODAL_OPEN, payload: isOpen };
}

export function updatenegotiation(session, threadid, status, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/updatenegotiation`;

  return (dispatch) => {
    postRequest(
      url,
      {
        threadid: threadid,
        type: "status",
        status: status,
      },
      session,
      dispatch,
      (response) => {
        //  console.log('hiu',response);

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

export function verifyvendor(session, id, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/verifyandapproveuser`;
  return (dispatch) => {
    postRequest(
      url,
      {
        theuserid: id,
      },
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

// export function fetchStoredetails(session,userid, callback) {
//   var url=`${INSTANCE_URL}/api/v2/shopez/_table/store?filter=(createdby=${userid})`
//   return (dispatch) => {
//     getRequest(
//       url,
//       session,
//       dispatch,
//       (data) => {
//         // console.log('storedetails.data', data.resource[0]);
//         dispatch({
//           type: STORE_DETAILS,
//           payload: { data: data.resource[0] },

//         });
//         callback({ success: 1, data: data });
//       },
//       (error) => {}
//     );
//   };
// }

/**** fetch store details ****/
export function fetchStoredetails(session, userid, condition, callback) {
  console.log("condition");
  if (condition == "open") {
    var url = `${INSTANCE_URL}/api/v2/shopez/_table/store?filter=createdby=${userid}`;

    return (dispatch) => {
      getRequest(
        url,
        session,
        dispatch,
        (response) => {
          if (response.resource.length > 0) {
            let storeid = response.resource[0].storeid;
            dispatch({
              type: STORE_DETAILS,
              payload: { data: response.resource[0] },
            });

            // Call Contacts deytails (storeid)
            dispatch(fetchStoreContacts(session, storeid));
          }

          callback({ status: 1, message: LocaleStrings.api_success_status });
        },
        (error) => {
          callback({ status: 0, message: LocaleStrings.something_went_wrong });
        }
      );
    };
  } else {
    return (dispatch) => {
      dispatch({
        type: STORE_DETAILS,
        payload: { data: {} },
      });
      dispatch({
        type: PROFILESETTINGS_STORECONTACTS,
        payload: { data: {} },
      });
    };
  }
}

/****  Fetch Contacts ****/
export function fetchStoreContacts(session, storeid) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/store_contacts?filter=storeid=${storeid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILESETTINGS_STORECONTACTS,
          payload: { data: response.resource[0] },
        });

        ///callback({ status: 1 ,message: LocaleStrings.api_success_status});
      },
      (error) => {
        //callback({ status: 0 ,message: LocaleStrings.something_went_wrong});
      }
    );
  };
}

export function updateCommisionSeller(session, values, callback) {
  var body = { resource: { ...values } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch(fetchSeller(session));
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchSimilarProducts(session, productid, callback) {
  // console.log('sessiontoken', session);
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/similarproducts`;
  return (dispatch) => {
    postRequest(
      url,
      {
        productid: productid,
        withlimit: 0,
      },
      session,
      dispatch,
      (data) => {
        // console.log('fetchSimilarProducts', data);
        dispatch({
          type: SIMILAR_PRODUCTS,
          payload: { data: data.data },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

/**** Product QA Modal Open ****/
export function isCommentsModalOpen(isOpen) {
  return { type: IS_PRODUCT_COMMENTS_MODAL_OPEN, payload: isOpen };
}

/**** Fetch Comment List ****/
export function fetchCommentList(session, productid, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/comments`;
  let values = { productid: productid };

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //  console.log('res',response);

        dispatch({
          type: COMMENT_LIST,
          payload: { data: response.comments },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

export function banSeller(session, values, callback) {
  var body = { resource: { ...values } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // dispatch(fetchSeller(session));
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchTransactionSeller(session, userid, pageNumber, callback) {
  var offset = pageNumber;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/transactions`;
  return (dispatch) => {
    postRequest(
      url,
      {
        foruserid: userid,
        limit: "10",
        page: offset,
        type: "listing",
      },
      session,
      dispatch,
      (data) => {
        // console.log('data5', data.data);
        dispatch({
          type: TRANSACTION_LIST_SELLER,
          payload: { data: data.data, count: data.count },
        });
        // console.log('data544',payload );
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchSellerTransaction(session, userid, search) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/transactions`;

  return (dispatch) => {
    // dispatch(fetchWatchlist(session));
    postRequest(
      url,
      {
        foruserid: userid,
        type: "search",
        searchterm: search,
      },
      session,
      dispatch,
      (data) => {
        dispatch({
          type: TRANSACTION_LIST_SELLER,
          payload: { data: data.data },
        });
      },
      (error) => {}
    );
  };
}

export function emaildbCheck(email, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript?type=socialsignupavailable`;
  var params = { email: email };
  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        if (response.result == "success" && response.available === 1) {
          callback({
            success: 1,
          });
        } else {
          callback({
            success: 0,
          });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
        // Note - If available = 1 means the user already registered as a Vendor, Show error
      }
    );
  };
}

/**** Product Likes Modal Open ****/
export function isLikesModalOpen(isOpen) {
  return { type: IS_PRODUCT_LIKES_MODAL_OPEN, payload: isOpen };
}

export function fetchproductlikes(session, productid, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/productlikes`;
  let values = { productid: productid };

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PRODUCTLIKES_LIST,
          payload: { data: response.likes },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

export function updateSellerProductsRefund(
  session,
  productvalues,
  data,
  callback
) {
  var createdby = data.userid;
  var body = { resource: { ...productvalues } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/products?filter=(createdby=${createdby})`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchsellerAuctionList(
  session,
  userid,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  // var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/watchlist`;
  let values = {};
  if (type === "listing") {
    // Paginated
    values.userid = userid;
    values.limit = limit;
    values.page = page;
    values.type = type;
    values.usertype = "admin";
    values.tab = "bid";
  } else {
    // With out pagination [search result]
    values.userid = userid;
    values.type = type;
    values.searchterm = searchterm;
    values.usertype = "admin";
    values.tab = "bid";
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (data) => {
        // console.log('actionwatchlist', data);
        dispatch({
          type: SELLER_AUCTION_LIST,
          payload: { data: data.data, count: data.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchsellerAuctionWonList(
  session,
  userid,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  // var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/watchlist`;
  let values = {};
  if (type === "listing") {
    // Paginated
    values.userid = userid;
    values.limit = limit;
    values.page = page;
    values.type = type;
    values.usertype = "admin";
    values.tab = "bid";
    values.status = "won";
  } else {
    // With out pagination [search result]
    values.userid = userid;
    values.type = type;
    values.searchterm = searchterm;
    values.usertype = "admin";
    values.tab = "bid";
    values.status = "won";
  }
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (data) => {
        // console.log('actionwatchlist', data);
        dispatch({
          type: SELLER_AUCTION_LIST_WON,
          payload: { data: data.data, count: data.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function featureUnfeatureproducts(session, data, callback) {
  var body = { resource: { ...data } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/products/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function updateProducts(session, vendorid, isapproved, callback) {
  var body = { resource: { isapproved: isapproved } };
  // console.log('body',body)
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/products?filter=(createdby=${vendorid})`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // dispatch(fetchSeller(session));
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function updateSellerProducts(body, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/products`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      {},
      dispatch,
      (response) => {
        // console.log('responsesuccess',response)
        callback({ success: 1 });
      },
      (error) => {
        // console.log('error',error)
        callback({ success: 0 });
      }
    );
  };
}

export function updateVendorEmail(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/updateemail`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchsingleSeller(session, userid, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?related=users_by_bannedby&include_count=true&filter=(userid=${userid})`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data.meta.count);
        dispatch({
          type: SINGLESELLER,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
