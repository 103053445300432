import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../../languages/index";
import { Fragment } from "react";
import { BaseComponent } from "../../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { reduxForm } from "redux-form";
import _ from "lodash";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
} from "../../../../../../common/constants";
import QuestionAnswers from "./qa";
import moment from "moment";
import {
  updateMRP,
  ProductTextLocalized,
  classNames,
} from "../../../../../../common/common-functions";
import renderHTML from "react-render-html";
import {
  fetchSimilarProducts,
  isCommentsModalOpen,
  isLikesModalOpen,
} from "../../../actions/index";
import DetailsImgCarousel from "./detailsimgcarousel";
import ViewComments from "./viewcomments";
import ViewLikes from "./viewlikes.js";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "../../../../currency/action/index";

var selectionArr = []; // Array for refkeys for Variant [XL] [L]
class AllitemProductdetailmodal extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      loading: true,
      btnLoader: false,
      isDefault: true,
      viewAllDetailsState: false,
      viewAllOues: false,
      allVariants: [],
      productVariants: [],
      variantId: [],
      selectedVariant: [],
    };

    // All Binded Functions
  }
  componentDidMount() {
    let { productDetails, language } = this.props;

    this.setState({ variantId: productDetails.variantid });
    // console.log('variantId',this.state.variantId)
    // All Variants
    if (productDetails.all_variants && productDetails.all_variants.length > 0) {
      let allVariants = [];

      _.map(productDetails.all_variants, (obj, index) => {
        obj.selected = obj.id == productDetails.variantid ? true : false;
        allVariants.push(obj);
      });

      this.setState({ allVariants: allVariants });
    }

    let selectedVariantArr = _.filter(
      productDetails.all_variants,
      function (o) {
        return o.id == productDetails.variantid;
      }
    );

    // Product Variants
    if (
      productDetails.product_variants &&
      productDetails.product_variants.length > 0
    ) {
      let productVariants = [];
      _.map(productDetails.product_variants, (obj, index) => {
        let variantnames = [];
        if (obj.variantnames && obj.variantnames.length > 0) {
          _.map(obj.variantnames, (data, index) => {
            data.selected =
              selectedVariantArr &&
              selectedVariantArr.length > 0 &&
              selectedVariantArr[0].refkey.indexOf(data.refkey) !== -1
                ? true
                : false;
            variantnames.push(data);
          });
        }
        obj.variantnames = variantnames;
        productVariants.push(obj);
      });
      this.setState({ productVariants: productVariants });
    }

    this.setState({ selectedVariant: selectedVariantArr });
  }

  ProgressBar = (rating, total) => {
    let progressPercentage = (rating / total) * 100;

    return (
      <div className="h-2 w-full bg-gray-300 rounded-md">
        {/* {progressPercentage > 0 ? ( */}
        <div
          style={{ width: "150px" }}
          className={`h-full rounded-md ${
            progressPercentage > 30
              ? "bg-green-500"
              : progressPercentage > 20 && progressPercentage < 30
              ? "bg-red-500"
              : "bg-orange-500"
          }`}></div>
        {/* ) : (
            ""
          )} */}
      </div>
    );
  };

  viewComments = () => {
    this.props.isCommentsModalOpen(true);
  };
  viewLikes = () => {
    this.props.isLikesModalOpen(true);
  };

  ReviewList = (item) => {
    var userimg = `${BASE_IMAGES_URL}/users/${item.ratedby.userid}.jpg?api_key=${APP_API_KEY}`;

    var mainDivStyle = {};
    if (
      this.props.reportedOn == "review" &&
      item.id == this.props.reportedObject.review.id
    ) {
      mainDivStyle = {
        color: "#FF0000",
      };
    }
    return (
      <div className="pb-9">
        <div className="flex">
          {item.ratedby ? (
            <div>
              <img
                // src={`${BASE_IMAGES_URL}/users/${item.ratedby.userimage}?api_key=${APP_API_KEY}`}
                src={userimg}
                className="h-10 w-10 rounded-full"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../images/user_1.png";
                }}
              />
            </div>
          ) : (
            <div className="bg-delivery rounded-full w-46 h-46"></div>
          )}
          <div className="pl-2 align-top">
            <div className="text-sm font-medium text-secondary truncate">
              {item.ratedby && item.ratedby.username
                ? item.ratedby.username
                : ""}
            </div>
            <div className="text-sm text-ternary truncate">
              {moment(item.createdat).format("D MMM YYYY")}
            </div>
          </div>
        </div>
        <div className="flex pt-2">
          {item.productrating && item.productrating > 0
            ? _.times(item.productrating, (i) => {
                return (
                  <div className="pr-3">
                    <img
                      src="../images/product_management_images/yellowstar.svg"
                      className="w-5"
                    />
                  </div>
                );
              })
            : ""}

          {item.productrating &&
          item.productrating > 0 &&
          5 - item.productrating > 0
            ? _.times(5 - item.productrating, (i) => {
                return (
                  <div className="pr-3">
                    <img
                      src="../images/product_management_images/graystar.svg"
                      className="w-5"
                    />
                  </div>
                );
              })
            : ""}
        </div>
        {/* <div className="text-sm font-semibold text-secondary truncate pt-2">
          {LocaleStrings.reviews}
        </div> */}
        <div className="flex">
          <div className="text-xs font-medium mt-1" style={mainDivStyle}>
            {item.feedback}
          </div>
          {this.props.reportedOn == "review" &&
          item.id == this.props.reportedObject.review.id ? (
            <img
              src="../images/warning.png"
              aria-hidden="true"
              style={{ height: "25px", width: "25px", marginLeft: "10px" }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  /**** Arrow Function for BASE VARIANT LOOP THROUGH ****/
  renderBaseVariants = () => {
    let { language, productDetails } = this.props;
    let { isDefault } = this.state;

    if (isDefault) {
      // For default make it blck so do not take each render value
      selectionArr = [];
    }

    var item = _.map(
      productDetails.product_variants,
      (basevariantsObj, index) => {
        if (isDefault) {
          // For default MAke array with all first veriant

          selectionArr.push(basevariantsObj.variantnames[0].refkey);
        }

        let variant = ProductTextLocalized(
          basevariantsObj.name_en,
          basevariantsObj.name_ar,
          language
        );

        return (
          <div className="">
            <div className=" inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-ternary" />
            </div>
            <div className="py-2">
              <p className="text-sm  text-secondary">{variant}</p>
            </div>
            <div className="py-2">
              {
                /**** Product variants name ****/
                _.map(
                  basevariantsObj.variantnames,
                  (variantnamesObj, indexInner) => {
                    let variantName = ProductTextLocalized(
                      variantnamesObj.name_en,
                      variantnamesObj.name_ar,
                      language
                    );

                    return isDefault === true && indexInner === 0 ? (
                      <button
                        type="button"
                        className={classNames(
                          isDefault
                            ? "border-primary text-primary"
                            : "border-ternary text-secondary",
                          "mx-2 inline-flex items-center px-2.5 py-1.5 border  shadow-sm text-xs font-medium rounded  bg-white hover:bg-quaternary"
                        )}
                        onClick={(e) =>
                          this._handelVariantBtnClick(
                            variantnamesObj.refkey,
                            index
                          )
                        }>
                        {variantName}
                      </button>
                    ) : (
                      <button
                        type="button"
                        // className="border-ternary text-secondary mx-2 inline-flex items-center px-2.5 py-1.5 border  shadow-sm text-xs font-medium rounded  bg-white hover:bg-quaternary"
                        className={classNames(
                          selectionArr[index] === variantnamesObj.refkey
                            ? "border-primary text-primary"
                            : "border-ternary text-secondary",
                          "mx-2 inline-flex items-center px-2.5 py-1.5 border  shadow-sm text-xs font-medium rounded  bg-white hover:bg-quaternary"
                        )}
                        onClick={(e) =>
                          this._handelVariantBtnClick(
                            variantnamesObj.refkey,
                            index
                          )
                        }>
                        {variantName}
                      </button>
                    );
                  }
                )
              }
            </div>
          </div>
        );
      }
    );

    return item;
  };

  /**** Variant Names XL/L Button Click ****/
  _handelVariantBtnClick(refkey, index) {
    if (selectionArr[index] !== refkey) {
      // array modified
      selectionArr[index] = refkey;
    }

    this.setState({ isDefault: false });
  }

  renderProductVariant = (variantnames, pos) => {
    let { language } = this.props;

    if (variantnames && variantnames.length > 0) {
      return _.map(variantnames, (vari, index) => {
        return (
          <button
            type="button"
            onClick={
              vari.selected
                ? null
                : () => this.makeVariantSelection(vari.refkey, pos)
            }
            className={classNames(
              vari.selected
                ? "border-primary text-primary vari-smalscreen"
                : "border-ternary text-secondary vari-smalscreen",
              "justify-items-center mx-2  px-2.5 py-1.5 border  shadow-sm text-xs font-medium rounded  bg-white hover:bg-quaternary"
            )}
            key={index}>
            {ProductTextLocalized(vari.name_en, vari.name_ar, language)}
          </button>
        );
      });
    }
  };

  makeVariantSelection = (refkey, pos) => {
    let { productVariants, allVariants } = this.state;

    if (Object.keys(productVariants).length > 0) {
      let productVariantsArr = [];
      let selectedVariantKeys = [];

      _.map(productVariants, (obj, row) => {
        let variantnames = [];
        if (obj.variantnames && obj.variantnames.length > 0) {
          _.map(obj.variantnames, (data, index) => {
            if (pos == row) {
              data.selected = data.refkey == refkey ? true : false;
              if (data.selected) {
                selectedVariantKeys.push(refkey);
              }
            } else {
              data.selected = data.selected;
              if (data.selected) {
                selectedVariantKeys.push(data.refkey);
              }
            }

            variantnames.push(data);
          });
        }

        obj.variantnames = variantnames;
        productVariantsArr.push(obj);
      });

      this.setState({ productVariants: productVariantsArr });

      let selectedVariantKey = selectedVariantKeys.join("-");

      if (allVariants.length > 0) {
        let allVariantsArr = [];

        _.map(allVariants, (obj, index) => {
          obj.selected = obj.refkey == selectedVariantKey ? true : false;
          allVariantsArr.push(obj);
        });

        this.setState({ allVariants: allVariantsArr });

        let selectedVariantArr = _.filter(allVariantsArr, function (o) {
          return o.selected;
        });

        if (selectedVariantArr && selectedVariantArr.length > 0) {
          this.setState({ selectedVariant: selectedVariantArr });
          this.setState({ variantId: selectedVariantArr[0].id });
        }
      }
    }
  };

  /**** Arrow Function for images ****/
  renderFirstImgs = () => {
    let { selectedVariant } = this.state;
    let { productDetails } = this.props;

    var item = _.map(productDetails.all_variants, (mediaObject, index) => {
      var imageUrl = `${BASE_IMAGES_URL}/vendor/variations/${mediaObject.media[0].thumbfilename}?api_key=${APP_API_KEY}`;

      return (
        <div className="border-2 border-quaternary mx-1">
          <img src={imageUrl} className="h-10 w-10 " alt="image" />
        </div>
      );
    });

    return item;
  };

  renderlist() {
    //    console.log('qa',this.props.qa)
    var items = _.map(this.props.qa, (values) => (
      <QuestionAnswers
        key={values.userid}
        newqa={values}
        reportedOn={
          this.props.reportedOn == "question" ||
          this.props.reportedOn == "answer"
            ? this.props.reportedOn
            : ""
        }
        reportedObject={
          this.props.reportedOn == "question" ||
          this.props.reportedOn == "answer"
            ? this.props.reportedObject
            : {}
        }
      />
    ));
    return items;
  }

  render() {
    let { productDetails, user, language, productCategorylist, localCurrency } =
      this.props;
    let {
      viewAllDetailsState,
      productVariants,
      allVariants,
      variantId,
      selectedVariant,
      viewAllOues,
    } = this.state;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    // console.log('this.props.productDetails',this.props.productDetails)

    let categoryNames = [];
    let subCategoryNames = [];
    let userName = user.username;
    let productName = "";
    let productDesc = "";
    let specificationDesc = "";
    let productConditionName = "";
    let selectedVariantDisplay = "";
    let returnpolicy = "";

    let itemPrice =
      selectedVariant &&
      selectedVariant.length > 0 &&
      selectedVariant[0].price_unit
        ? selectedVariant[0].price_unit
        : 0;

    let priceObj = updateMRP(productDetails, variantId);
    let discountedPrice = priceObj.discountedPrice;
    let strProductDiscount = priceObj.strProductDiscount;

    let product_discountedPrice = 0.0;
    let product_shippingPrice = 0.0;
    let product_itemPrice = 0.0;
    let productCurrency = productDetails.currency;

    // product_itemPrice = itemPrice;
    // product_discountedPrice =discountedPrice;
    // product_shippingPrice =  productDetails.shipping_cost;
    product_itemPrice = isCurrencyRateApplicable(productCurrency, itemPrice);

    product_discountedPrice = isCurrencyRateApplicable(
      productCurrency,
      discountedPrice
    );

    product_shippingPrice = isCurrencyRateApplicable(
      productCurrency,
      productDetails.shipping_cost
    );

    //  selectionArr data.return_time_details.name_en
    productName = ProductTextLocalized(
      productDetails.productname_en,
      productDetails.productname_ar,
      language
    );
    productDesc = ProductTextLocalized(
      productDetails.product_desc_en,
      productDetails.product_desc_ar,
      language
    );
    specificationDesc = ProductTextLocalized(
      productDetails.specification_desc_en,
      productDetails.specification_desc_ar,
      language
    );
    returnpolicy = ProductTextLocalized(
      productDetails.return_time_details.name_en,
      productDetails.return_time_details.name_ar,
      language
    );
    productConditionName = ProductTextLocalized(
      productDetails.product_condition.condition_en,
      productDetails.product_condition.condition_ar,
      language
    );

    let avgRating =
      productDetails &&
      productDetails.ratings &&
      productDetails.ratings.avgrating
        ? productDetails.ratings.avgrating
        : 0;
    let remRating = 5 - avgRating;

    let sortedData = [];
    if (
      productDetails &&
      productDetails.reviews &&
      productDetails.reviews.length > 0
    ) {
      sortedData = _.orderBy(productDetails.reviews, ["createdat"], ["desc"]);
    }

    if (!_.isEmpty(selectedVariant)) {
      selectedVariantDisplay = ProductTextLocalized(
        selectedVariant[0].name_en,
        selectedVariant[0].name__ar,
        language
      );
    }

    let filterdCategory = _.filter(productCategorylist, (cateObj, index) => {
      return (
        _.indexOf(
          _.split(productDetails.categoryids, ",").map(Number),
          cateObj.id
        ) > -1
      );
    });

    _.forEach(filterdCategory, (fltdCateObject, index) => {
      // Get category names
      categoryNames.push(
        ProductTextLocalized(
          fltdCateObject.categoryname_en,
          fltdCateObject.categoryname_ar,
          language
        )
      );
    });

    // Fetch Subcategory Names
    _.forEach(filterdCategory, (seletedCateObject, index) => {
      _.forEach(
        seletedCateObject.productsubcategory_by_categoryid,
        (cateSubcateObject) => {
          if (
            _.indexOf(
              _.split(productDetails.subcategoryids, ",").map(Number),
              cateSubcateObject.id
            ) > -1
          ) {
            subCategoryNames.push(
              ProductTextLocalized(
                cateSubcateObject.subcategoryname_en,
                cateSubcateObject.subcategoryname_ar,
                language
              )
            );
          }
        }
      );
    });
    // console.log('reportedObject',this.props.reportedObject)
    // console.log('reportedOn',this.props.reportedOn)

    return (
      <Transition.Root show={this.props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.props.isOpen}
          onClose={this.props.onClose}
          initialFocus={this.myRef}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide bigmodal"
                style={{ padding: 0 }}>
                <div className="grid xs:grid-cols-2 lg:grid-cols-3 border-b-2 border-gray-200 pb-4 sticky-header z-50">
                  <div className="hidden lg:block"></div>
                  <div className="flex flex-wrap content-center justify-center mt-4">
                    {LocaleStrings.productdetails}
                  </div>
                  <div className="xs:block absolute top-0 right-0 pt-4 pr-4 ">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      onClick={this.props.onClose}
                      ref={this.myRef}>
                      <span className="sr-only">{LocaleStrings.close}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="p-6">
                  {!_.isEmpty(selectedVariant) ? (
                    <DetailsImgCarousel selectedVariant={selectedVariant} />
                  ) : (
                    ""
                  )}

                  <div className="grid grid-cols-3 my-5">
                    <div>
                      <div className="flex items-center space-x-2">
                        <div className="flex flex-shrink-0 -space-x-1">
                          {productDetails.likes.map((like) => (
                            <img
                              key={like.id}
                              className="max-w-none h-8 w-8 rounded-full ring-2 ring-white"
                              // src={${FILES_URL}/users/${like.likedby}.jpg?api_key=${APP_API_KEY}}
                              src={`${BASE_IMAGES_URL}/users/${like.likedby}.jpg?api_key=${APP_API_KEY}`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../images/user_1.png";
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div
                      className="smallscreen-like"
                      style={{ display: "flex" }}>
                      <img
                        className="h-8 w-8 cursor-pointer mt-3"
                        src="../images/product_management_images/like.svg"
                        onClick={(e) => this.viewLikes()}></img>
                      <p
                        className="text-base font-semibold text-secondary px-2 my-auto"
                        onClick={(e) => this.viewLikes()}>
                        {productDetails.numlikes}
                      </p>
                      <p
                        className="text-base font-semibold text-secondary my-auto"
                        onClick={(e) => this.viewLikes()}>
                        Likes
                      </p>
                    </div>
                    <div className="end-item">
                      <button
                        className="bg-blue-100 border-2 border-primary p-2 rounded"
                        onClick={(e) => this.viewComments()}>
                        {productDetails.numcomments} {LocaleStrings.comments}
                      </button>
                      {this.props.reportedOn == "comment" ? (
                        <img
                          src="../images/warning.png"
                          style={{
                            height: "25px",
                            width: "25px",
                            marginTop: "7px",
                            marginLeft: "10px",
                          }}
                          aria-hidden="true"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* Pricing */}
                  <div className="flex">
                    <div>
                      <p className="text-base font-semibold text-secondary ">
                        {/* ${discountedPrice} */}
                        {currency} {product_discountedPrice}
                      </p>
                    </div>
                    <div
                      className={classNames(
                        productDetails.discountactive !== 1 ? "hidden" : "",
                        ""
                      )}>
                      <p className="text-base line-through px-2">
                        {/* ${itemPrice} */}
                        {currency} {product_itemPrice}
                      </p>
                    </div>
                    <div
                      className={classNames(
                        productDetails.discountactive !== 1 ? "hidden" : "",
                        ""
                      )}>
                      <p className="text-base text-danger ">
                        {strProductDiscount}
                      </p>
                    </div>
                  </div>

                  {/* Vendor and Product Name */}
                  <p
                    className={classNames(
                      productDetails.shipping_type !== "local"
                        ? "my-2"
                        : "hidden",
                      "text-base text-ternary"
                    )}>
                    {/* +$ {productDetails.shipping_cost}  */}+ {currency}{" "}
                    {product_shippingPrice}
                    {LocaleStrings.shipping}
                  </p>

                  <p className="text-base font-semibold text-primary ">
                    {productDetails.store.username}
                  </p>

                  <p className="text-sm font-semibold text-secondary">
                    {productName}
                  </p>

                  {/* Ratings Reviews and solds */}
                  <div className="flex mt-4">
                    <div className="flex items-center text-sm font-medium ">
                      <img
                        className="h-4 w-4"
                        src={"../images/product_management_images/bluestar.svg"}
                      />
                      <div className="ml-1">
                        <p className="text-base font-semibold text-ternary ">
                          {productDetails.ratings.totalratings}
                        </p>
                      </div>
                    </div>
                    <div className="px-4">
                      <p className="text-base text-primary px-2">
                        {productDetails.ratings.totalreviews}{" "}
                        {LocaleStrings.reviews}
                      </p>
                    </div>
                    <div>
                      <p className="text-base text-ternary ">
                        {productDetails.numsold} Sold
                      </p>
                    </div>
                  </div>

                  {/* <div className="py-4">
                    {this.renderBaseVariants()}
                </div> */}
                  {/* Render Variants */}
                  <div className="border-t border-primary">
                    {productVariants.length > 0
                      ? _.map(productVariants, (prodV, index) => {
                          return (
                            <div key={index}>
                              <div className="mt-2 text-sm  text-secondary">
                                {ProductTextLocalized(
                                  prodV.name_en,
                                  prodV.name_ar,
                                  language
                                )}
                              </div>
                              <div
                                className={classNames(
                                  index + 1 < productVariants.length
                                    ? "border-b border-primary"
                                    : "",
                                  "mt-2.5"
                                )}>
                                <div className="pb-2.5 grid grid-cols-3 xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-4">
                                  {this.renderProductVariant(
                                    prodV.variantnames,
                                    index,
                                    productDetails
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>

                  {/* Selected Variant */}
                  <div>
                    <p className="mt-1 text-xs text-ternary">
                      {LocaleStrings.selected_variant}:
                      <span className="text-secondary">
                        {" "}
                        {selectedVariantDisplay}{" "}
                      </span>
                    </p>
                  </div>

                  {/* Render Images */}
                  {!_.isEmpty(selectedVariant) ? (
                    <div className="relative z-0 inline-flex shadow-sm rounded-md  mt-4">
                      {this.renderFirstImgs()}
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Specification and Product Description */}
                  <div className="block mt-4">
                    <p className="text-xs font-semibold text-secondary">
                      {LocaleStrings.productdescription}:
                    </p>
                    <p className="mt-1 text-xs text-ternary">{productDesc}</p>
                  </div>
                  <div className="block mt-4">
                    <p className="text-xs font-semibold text-secondary">
                      {LocaleStrings.specification}:
                    </p>
                    <p className="mt-1 text-xs text-ternary">
                      {specificationDesc}
                    </p>
                  </div>

                  {/* <div className="block mt-4">
                    <p className="text-xs font-semibold text-secondary">{LocaleStrings.specification}:</p>
                    <pre className="mt-1 text-xs text-ternary">{renderHTML(specificationDesc)}</pre>
                </div> */}

                  <ul
                    role="list"
                    className=" divide-y divide-quaternary border-t-2 border-b-2 border-quaternary mt-4"
                    onClick={(e) =>
                      this.setState({
                        viewAllDetailsState: !viewAllDetailsState,
                      })
                    }>
                    <li className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition">
                      <div className="flex items-center space-x-4">
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-primary truncate">
                            {LocaleStrings.view_all_details}
                          </p>
                        </div>
                        <div>
                          <img
                            className={classNames(
                              viewAllDetailsState ? "rotate-90" : "",
                              "h-8 transform  px-2 duration-300 ease-in-out"
                            )}
                            src="../images/product_management_images/arrow-right.svg"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div
                    className={classNames(
                      viewAllDetailsState ? "" : "hidden",
                      "mt-4 duration-300 ease-in-out"
                    )}>
                    <div className="block mt-4">
                      <p className="text-xs font-semibold text-secondary">
                        {LocaleStrings.productdescription}:
                      </p>
                      <p className="mt-1 text-xs text-ternary">{productDesc}</p>
                    </div>

                    <div className="block mt-4 mx-4">
                      {productDetails.categories &&
                      productDetails.categories.length > 0
                        ? _.map(productDetails.categories, (cate, index) => {
                            return (
                              <div key={index}>
                                <div className="flex">
                                  <p className="text-ternary mr-3 text-xs">
                                    Category:
                                  </p>
                                  <p className="text-ternary text-xs">
                                    {" "}
                                    {cate.categoryname_en}
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                      {productDetails.subcategories &&
                      productDetails.subcategories.length > 0
                        ? _.map(
                            productDetails.subcategories,
                            (subcate, index) => {
                              return (
                                <div key={index}>
                                  <div className="flex">
                                    <p className="text-ternary mr-3 text-xs">
                                      SubCategory:
                                    </p>
                                    <p className="text-ternary text-xs">
                                      {" "}
                                      {subcate.subcategoryname_en}
                                    </p>
                                  </div>
                                </div>
                              );
                            }
                          )
                        : ""}
                      {/* <p className="mt-1 text-xs text-ternary">{LocaleStrings.category}: 
                            {' '}{_.join(categoryNames,',')}
                        </p>
                        <p className="mt-1 text-xs text-ternary">{LocaleStrings.subcategory}:
                            {' '}{_.join(subCategoryNames,',')}
                        </p>
                        <p className="mt-1 text-xs text-ternary">{LocaleStrings.condition}:
                            {' '}{productConditionName}{' '}
                        </p> */}
                      <p className="mt-1 text-xs text-ternary">
                        {LocaleStrings.selected_variant}:{" "}
                        {selectedVariantDisplay}{" "}
                      </p>
                    </div>

                    <div className="block mt-4">
                      <p className="text-xs font-semibold text-secondary">
                        {LocaleStrings.specification}:
                      </p>
                      <p className="mt-1 text-xs text-ternary">
                        {renderHTML(specificationDesc)}
                      </p>
                    </div>
                  </div>

                  {/* Return Policy */}
                  <div className="block mt-4">
                    <p className="text-xs font-semibold text-secondary">
                      {LocaleStrings.return_policy}:
                    </p>
                    <p className="mt-1 text-xs text-ternary">{returnpolicy}</p>
                  </div>

                  {/* Actual Review Graph*/}
                  <div className="text-base font-semibold text-primary flex mt-4">
                    <span>{LocaleStrings.reviews}</span>
                    {this.props.reportedOn == "review" ? (
                      <img
                        src="../images/warning.png"
                        aria-hidden="true"
                        style={{
                          height: "25px",
                          width: "25px",
                          marginLeft: "10px",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="text-left pt-2.5 flex">
                    {avgRating && avgRating > 0 ? (
                      _.times(avgRating, (i) => {
                        return (
                          <div className="pr-3">
                            <img src="../images/product_management_images/yellowstar.svg" />
                          </div>
                        );
                      })
                    ) : (
                      <div>No Reviews</div>
                    )}

                    {avgRating && avgRating > 0 && remRating > 0
                      ? _.times(remRating, (i) => {
                          return (
                            <div className="pr-3">
                              <img src="../images/product_management_images/graystar.svg" />
                            </div>
                          );
                        })
                      : ""}

                    {avgRating && avgRating > 0 ? (
                      <div className="pl-lg text-primary text-base font-medium">
                        {avgRating} out of 5
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {productDetails &&
                  productDetails.ratings &&
                  productDetails.ratings.totalratings &&
                  productDetails.ratings.totalreviews ? (
                    <div className="text-left pt-3.5 flex text-sm text-cardfooter">
                      {productDetails.ratings.totalratings} Rating And{" "}
                      {productDetails.ratings.totalreviews} Reviews
                    </div>
                  ) : (
                    ""
                  )}
                  {productDetails &&
                  productDetails.ratings &&
                  productDetails.ratings.totalratings > 0
                    ? _.times(5, (i) => {
                        return (
                          <div className="text-left pt-3.5 flex">
                            <div>
                              <img
                                src="../images/product_management_images/bluestar.svg"
                                className="w-6"
                              />
                            </div>
                            <div className="pl-2 text-primary text-sm">
                              {5 - i} Star
                            </div>
                            <div className="pl-2 w-240 xl:w-292 lg:w-292 md:w-292 flex items-center">
                              {productDetails.ratings
                                ? this.ProgressBar(
                                    productDetails.ratings[`rating${5 - i}`],
                                    productDetails.ratings.totalratings
                                  )
                                : "rrrr"}
                            </div>
                            <div className="pl-2 text-cardfooter text-sm">
                              {productDetails.ratings
                                ? productDetails.ratings[`rating${5 - i}`]
                                : 0}
                            </div>
                          </div>
                        );
                      })
                    : ""}

                  {sortedData.length > 0 ? (
                    <div className="text-left pt-10">
                      {sortedData.slice(0, 3).map((item, index) => {
                        return this.ReviewList(item);
                      })}
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Questions & Answers */}
                  {/* <QaWithLimit productID={productDetails.id}/> */}
                  {/* View Comments */}
                  <ViewComments
                    productID={productDetails.id}
                    reportedOn={
                      this.props.reportedOn == "comment"
                        ? this.props.reportedOn
                        : ""
                    }
                    reportedObject={
                      this.props.reportedOn == "comment"
                        ? this.props.reportedObject
                        : {}
                    }
                  />

                  <ViewLikes productID={productDetails.id} />

                  <div className="mt-5">
                    <div className="mb-5">{LocaleStrings.questionanswers}</div>
                    <ul
                      role="list"
                      className=" divide-y divide-quaternary border-t-2 border-b-2 border-quaternary my-4"
                      onClick={(e) =>
                        this.setState({ viewAllOues: !viewAllOues })
                      }>
                      <li className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition">
                        <div className="flex items-center space-x-4">
                          <div className="flex min-w-0">
                            <p className="text-sm font-medium text-primary truncate">
                              Question & Answers
                            </p>
                            {this.props.reportedOn == "question" ||
                            this.props.reportedOn == "answer" ? (
                              <img
                                src="../images/warning.png"
                                aria-hidden="true"
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  marginLeft: "10px",
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div style={{ marginLeft: "auto" }}>
                            <img
                              className={classNames(
                                viewAllOues ? "rotate-90" : "",
                                "h-8 transform  px-2 duration-300 ease-in-out"
                              )}
                              src="../images/product_management_images/arrow-right.svg"
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div
                      className={classNames(
                        viewAllOues ? "" : "hidden",
                        "mt-4 duration-300 ease-in-out mb-5"
                      )}>
                      {this.props.qa.length > 0 ? (
                        <span> {this.renderlist()}</span>
                      ) : (
                        <div>No Question Answers</div>
                      )}
                    </div>
                    {/* {this.renderlist()} */}
                  </div>

                  {/* <div className="my-4">Similar Products<CommonSimilarProducts data={this.props.productDetails}/></div> */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  //   console.log('sellerSimilarProducts',state.sellerSimilarProducts);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    data: ownProps.data,
    localCurrency: state.currency,
    // sellerSimilarProducts:state.sellerSimilarProducts
  };
}

export default connect(mapStateToProps, {
  fetchSimilarProducts,
  isCommentsModalOpen,
  isLikesModalOpen,
  isCurrencyRateApplicable,
})(
  reduxForm({
    // validate,
    form: "ProductDetails",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AllitemProductdetailmodal)
);
