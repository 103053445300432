import { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index";
import SearchNewSeller from "./searchnewseller";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Vendortabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndextoShow: 1,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  // On Tabs change
  onTabsChange = (e, tabindex, callfrom) => {
    if (callfrom === "lg") {
      // For tabs
      this.setState({ tabIndextoShow: parseInt(tabindex) });
    } else {
      // For Select box
      this.setState({ tabIndextoShow: parseInt(e.target.value) });
    }
  };

  onSellerchange(e, href, position, screen) {
    if (screen == "sm") {
      if (e.target.value == "Verified") {
        this.props.history.push("/dashboard/sellermanagement/verified");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Verification Requested") {
        this.props.history.push(
          "/dashboard/sellermanagement/verficationrequested"
        );
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      } else if (e.target.value == "Unverified") {
        this.props.history.push("/dashboard/sellermanagement/unverified");
        this.setState({ tabIndextoShow: parseInt(e.target.value) });
      }
    } else {
      this.setState({ tabIndextoShow: parseInt(position) });
      this.props.history.push("/dashboard/sellermanagement/" + href);
    }
  }

  render() {
    var selectedTab = "";
    return (
      <>
        <div className="grid grid-cols-2">
          <div className="text-2xl font-semibold text-secondary pl-5 pb-5">
            {LocaleStrings.seller_management_heading}
          </div>
          <div className="end-item mx-2 pb-5 pr-5">
            <SearchNewSeller history={this.props.history} />{" "}
          </div>
        </div>

        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-primary focus:border-primary border-ternary rounded-md"
            // defaultValue={this.props.tabs.find((tab) => tab.current).name}
            onChange={(e) => this.onSellerchange(e, "", "", "sm")}>
            {this.props.tabs.map((tab) => (
              <option
                // key={tab.name} value={tab.tabindex}

                selected={
                  this.props.history.location.pathname ==
                    "/dashboard/sellermanagement/verified" &&
                  tab.href == "verified"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/sellermanagement/verficationrequested" &&
                      tab.href == "verficationrequested"
                    ? "selected"
                    : this.props.history.location.pathname ==
                        "/dashboard/sellermanagement/unverified" &&
                      tab.href == "unverified"
                    ? "selected"
                    : ""
                }>
                {tab.name}
              </option>
            ))}
          </select>
        </div>

        <div className="hidden sm:block">
          {/* <div className="xs:block"> */}
          <nav className="relative z-0 rounded-lg flex  pl-5" aria-label="Tabs">
            {this.props.tabs.map((tab, tabIdx) => (
              <span
                style={{ marginRight: "20px", borderRadius: "6px" }}
                key={tab.name}
                className={classNames(
                  this.state.tabIndextoShow === tab.position
                    ? "text-secondary"
                    : "text-ternary hover:text-secondary",
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10 "
                )}
                aria-current={
                  this.state.tabIndextoShow === tab.position
                    ? "page"
                    : undefined
                }
                onClick={(e) =>
                  this.onSellerchange(e, tab.href, tab.position, "lg")
                }>
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    this.props.history.location.pathname ==
                      "/dashboard/sellermanagement/verified" &&
                      tab.href == "verified"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/sellermanagement/verficationrequested" &&
                        tab.href == "verficationrequested"
                      ? "bg-primary"
                      : this.props.history.location.pathname ==
                          "/dashboard/sellermanagement/unverified" &&
                        tab.href == "unverified"
                      ? "bg-primary"
                      : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </span>
            ))}
          </nav>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.verifiedUnverifiedsellerTabs,
  };
}

export default connect(mapStateToProps, {})(Vendortabs);
