import React from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../languages/index.js";
import { Fragment } from "react";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import moment from "moment-timezone";
import {
  updateSeller,
  fetchSeller,
  uploadMediaLargeSellerDocs,
  updateProducts,
  updateVendorEmail,
  fetchsingleSeller,
} from "../actions/index";
import "react-phone-input-2/lib/material.css";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { DATE_FORMAT_WITH_TIME_WITH_SECOND } from "../../../../common/constants";
import {
  classNames,
  isValidQatarPhoneNumber,
} from "../../../../common/common-functions";
import { countryPhoneLength } from "../../../../actions/index.js";

var threeArr = [{}, {}, {}];
// Constant
let validateExtention = ["doc", "docm", "docx", "docx", "pdf"];

const gender = [
  { name: "RATHER NOT SAY", value: "", index: 0 },
  { name: "MALE", value: "male", index: 1 },
  { name: "FEMALE", value: "female", index: 2 },
];
const typeofbusiness = [
  { name: "Company", value: "company", index: 0 },
  { name: "Home Business", value: "home", index: 1 },
  { name: "Personal Business", value: "personal", index: 2 },
];

class EditsellerModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alertMessage: "",
      showerror: false,
      loading: false,
      setPhone: "",
      genderOptions: gender,
      typeofbusiness: typeofbusiness,
      business: "company",
      btnLoader: false,
      phone: "",
      country: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    if (this.props.initialValues) {
      if (
        this.props.initialValues.hasOwnProperty("businesstype") &&
        this.props.initialValues.businesstype !== null
      ) {
        this.setState({ business: this.props.initialValues.businesstype });
      }
    }
  }

  onSubmitForm(values) {
    if (values.phone == null || values.phone == "") {
      NotificationManager.error("Phone No is required");
      return false;
    }
    let callfrom = "verified";
    if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/unverified"
    ) {
      callfrom = "unverified";
    } else if (
      this.props.history.location.pathname ==
      "/dashboard/sellermanagement/verficationrequested"
    ) {
      callfrom = "verficationrequested";
    }
    this.setState({ btnLoader: true });
    if (this.props.initialValues.email !== values.email) {
      let val = {};
      // val.emailtype = 'updateemail';
      val.email = values.email;
      val.userid = values.userid;
      this.props.updateVendorEmail(this.props.session, val, (response) => {
        if (Object.keys(threeArr[1]).length === 0) {
          let dob = null;
          let usertype = "vendor";
          if (this.state.business == "personal" && values.dob != null) {
            dob = moment(values.dob).format(DATE_FORMAT_WITH_TIME_WITH_SECOND);
          }

          var sellerdata = {
            userid: this.props.initialValues.userid,
            dfuserid: this.props.initialValues.dfuserid,
            name: values.username,
            dob: dob,
            display_name: values.username,
            first_name: values.username,
            last_name: "",
            new_password: values.password,
            gender: values.gender,
            phone: values.phone.replace(/[- )( +]/g, ""),
            username: values.username,
            businessname: values.businessname,
            callfrom: "updatevendor",
            // businesstype: values.businesstype,
          };

          this.props.updateSeller(
            this.props.session,
            sellerdata,
            (response) => {
              this.props.fetchsingleSeller(
                this.props.session,
                values.userid,
                (resource) => {}
              );
              if (response.success === 0) {
                this.setState({ loading: false });
                NotificationManager.error(LocaleStrings.addingfailed);
              } else {
                this.props.onClose();
                this.props.pagination();
                // this.props.fetchSeller(
                //   this.props.session,
                //   this.props.currentPage,
                //   callfrom,
                //   (CallBackResponse) => {}
                // );
                NotificationManager.success(LocaleStrings.updatedsuccessfully);
              }
            }
          );
        } else {
          // Upload Section
          let uploaderCounter = 0;
          let filetobeupload = [];
          let arrPromise = [];

          // Blank File Name array

          let commercial_reg_doc = "";
          let agreement_template = "";
          let idproof = "";

          _.forEach(threeArr, function (value, index) {
            if (Object.keys(value).length > 0) {
              if (index === 0) {
                commercial_reg_doc = value.name;
              }

              if (index === 1) {
                agreement_template = value.name;
              }
              if (index === 2) {
                idproof = value.name;
              }
              let coverJson = {
                type: value.type,
                name: value.name,
                media: value.url,
              };

              filetobeupload.push(coverJson);
            }
          });

          let counter = filetobeupload.length;

          filetobeupload.map((item, index) => {
            arrPromise.push(
              new Promise((resolve, reject) => {
                this.props.uploadMediaLargeSellerDocs(
                  this.props.session,
                  item,
                  (response) => {
                    if (response.success === 0) {
                      resolve();
                    } else {
                      uploaderCounter++;
                      resolve();
                    }
                  }
                );
              })
            );
          });

          Promise.all(arrPromise).then(() => {
            if (uploaderCounter === counter) {
              var sellerdata = {
                userid: this.props.initialValues.userid,
                dfuserid: this.props.initialValues.dfuserid,
                name: values.username,
                dob: null,
                display_name: values.username,
                username: values.username,
                first_name: values.username,
                last_name: "",
                gender: values.gender,
                new_password: values.password,
                phone: values.phone.replace(/[- )( +]/g, ""),
                commercial_reg_doc: commercial_reg_doc,
                agreement_template: agreement_template,
                businessname: values.businessname,
                callfrom: "updatevendor",
              };
              // console.log('sellerdata',sellerdata)
              // After file upload
              this.props.updateSeller(
                this.props.session,
                sellerdata,
                (response) => {
                  this.props.fetchsingleSeller(
                    this.props.session,
                    values.userid,
                    (resource) => {}
                  );
                  if (response.success === 0) {
                    this.props.onClose();
                    NotificationManager.error(LocaleStrings.addingfailed);
                  } else {
                    threeArr[0] = {};
                    this.props.onClose();
                    this.props.pagination();
                    // this.props.fetchSeller(
                    //   this.props.session,
                    //   this.props.currentPage,
                    //   callfrom,
                    //   (CallBackResponse) => {}
                    // );
                    NotificationManager.success(
                      LocaleStrings.updatedsuccessfully
                    );
                  }
                }
              );
            } else {
              NotificationManager.error(LocaleStrings.addingfailed);
              this.props.onClose();
            }
          });
        }
      });
    } else {
      if (Object.keys(threeArr[1]).length === 0) {
        let dob = null;

        if (this.state.business == "personal" && values.dob != null) {
          dob = moment(values.dob).format(DATE_FORMAT_WITH_TIME_WITH_SECOND);
        }

        var sellerdata = {
          userid: this.props.initialValues.userid,
          dfuserid: this.props.initialValues.dfuserid,
          email: values.email,
          name: values.username,
          dob: dob,
          display_name: values.username,
          first_name: values.username,
          last_name: "",
          new_password: values.password,
          gender: values.gender,
          phone: values.phone.replace(/[- )( +]/g, ""),
          username: values.username,
          businessname: values.businessname,
          callfrom: "updatevendor",
          // businesstype: values.businesstype,
        };

        this.props.updateSeller(this.props.session, sellerdata, (response) => {
          this.props.fetchsingleSeller(
            this.props.session,
            values.userid,
            (resource) => {}
          );
          if (response.success === 0) {
            this.setState({ loading: false });

            NotificationManager.error(LocaleStrings.addingfailed);
          } else {
            // console.log("1");
            this.props.onClose();
            this.props.pagination();
            // this.props.fetchSeller(
            //   this.props.session,
            //   this.props.currentPage,
            //   callfrom,
            //   (CallBackResponse) => {}
            // );
            NotificationManager.success(LocaleStrings.updatedsuccessfully);
          }
        });
      } else {
        // Upload Section
        let uploaderCounter = 0;
        let filetobeupload = [];
        let arrPromise = [];

        // Blank File Name array

        let commercial_reg_doc = "";
        let agreement_template = "";
        let idproof = "";

        _.forEach(threeArr, function (value, index) {
          if (Object.keys(value).length > 0) {
            if (index === 0) {
              commercial_reg_doc = value.name;
            }

            if (index === 1) {
              agreement_template = value.name;
            }
            if (index === 2) {
              idproof = value.name;
            }
            let coverJson = {
              type: value.type,
              name: value.name,
              media: value.url,
            };

            filetobeupload.push(coverJson);
          }
        });

        let counter = filetobeupload.length;

        filetobeupload.map((item, index) => {
          arrPromise.push(
            new Promise((resolve, reject) => {
              this.props.uploadMediaLargeSellerDocs(
                this.props.session,
                item,
                (response) => {
                  if (response.success === 0) {
                    resolve();
                  } else {
                    uploaderCounter++;
                    resolve();
                  }
                }
              );
            })
          );
        });

        Promise.all(arrPromise).then(() => {
          if (uploaderCounter === counter) {
            var sellerdata = {
              userid: this.props.initialValues.userid,
              dfuserid: this.props.initialValues.dfuserid,
              email: values.email,
              name: values.username,
              dob: null,
              display_name: values.username,
              username: values.username,
              first_name: values.username,
              last_name: "",
              gender: values.gender,
              new_password: values.password,
              phone: values.phone.replace(/[- )( +]/g, ""),
              commercial_reg_doc: commercial_reg_doc,
              agreement_template: agreement_template,
              businessname: values.businessname,
              callfrom: "updatevendor",
            };
            // console.log('sellerdata',sellerdata)
            // After file upload
            this.props.updateSeller(
              this.props.session,
              sellerdata,
              (response) => {
                this.props.fetchsingleSeller(
                  this.props.session,
                  values.userid,
                  (resource) => {}
                );
                if (response.success === 0) {
                  this.props.onClose();
                  NotificationManager.error(LocaleStrings.addingfailed);
                } else {
                  threeArr[0] = {};
                  this.props.onClose();
                  this.props.pagination();
                  // this.props.fetchSeller(
                  //   this.props.session,
                  //   this.props.currentPage,
                  //   callfrom,
                  //   (CallBackResponse) => {}
                  // );
                  NotificationManager.success(
                    LocaleStrings.updatedsuccessfully
                  );
                }
              }
            );
          } else {
            NotificationManager.error(LocaleStrings.addingfailed);
            this.props.onClose();
          }
        });
      }
    }
  }

  _handleSelect(event, typeindex) {
    const reader = new FileReader(); // Define reader
    let file = event.target.files[0]; // Take Selected file
    reader.readAsArrayBuffer(file); // Read the file

    // On File Load
    reader.onload = (e) => {
      file.url = e.target.result;
      // file.name   = file.name;
      // file.url = reader.result;
      //filearr.push(file);
    };

    let ext = file.name.split(".").pop(); // File extension

    if (_.indexOf(validateExtention, ext) === -1) {
      // Validate File Extensions
      NotificationManager.error(
        LocaleStrings.upload_only_doc_and_pdf_file_error_text
      );
    } else {
      this.props.autofill(typeindex, file.name);
      if (typeindex === "commercial_reg_doc") {
        threeArr[0] = file;
      } else if (typeindex === "agreement_template") {
        threeArr[1] = file;
      } else if (typeindex === "idproof") {
        threeArr[2] = file;
      }
    }
  }

  onbusinesstypeChange(e) {
    var newbusinesstype = e.target.value;
    this.setState({ business: newbusinesstype });
  }

  phoneChange = (value, country, e, formattedValue) => {
    this.setState({ phone: value, country: country.dialCode });
    this.props.countryPhoneLength(country.dialCode);
  };

  render() {
    // console.log('initialValues',this.props.initialValues);
    var { handleSubmit, initialValues } = this.props;
    return (
      <Transition.Root show={this.props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={this.myRef}
          open={this.props.isOpen}
          onClose={this.props.onClose}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="grid grid-cols-2 mb-2 border-b-2 border-gray-50 pb-4">
                  <div className="flex flex-wrap mx-3">
                    {LocaleStrings.seller_management_editSeller}
                  </div>
                  <div className="flex flex-wrap content-center justify-end">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      onClick={this.props.onClose}
                      ref={this.myRef}>
                      <span className="sr-only"></span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <form
                  className="space-y-8 divide-y divide-gray-200"
                  onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
                  encType="multipart/form-data">
                  <div>
                    <div className="border-transparent">
                      <div className="mb-5">
                        <Field
                          name="email"
                          label="Email"
                          placeholder={LocaleStrings.email}
                          // isdisabled={true}
                          type="email"
                          component={this.renderAllfields}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                      <div className="mb-5">
                        <Field
                          name="username"
                          label="User Name"
                          placeholder={LocaleStrings.name}
                          type="text"
                          component={this.renderAllfields}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                      <div className="mb-5">
                        <Field
                          name="businessname"
                          label={
                            this.state.business == "personal"
                              ? "Nickname"
                              : "Businessname"
                          }
                          placeholder={
                            this.state.business == "personal"
                              ? "Nickname"
                              : "Businessname"
                          }
                          type="text"
                          component={this.renderAllfields}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                      <div className="mb-5">
                        <Field
                          name={"phone"}
                          label={"Phone Number"}
                          mandatory="true"
                          type="text"
                          component={this.renderPhonewithFlag}
                          className="cust-input-field"
                          classNameLabel="text-box-lable"
                          phone={this.state.phone}
                          phoneChange={this.phoneChange}
                        />
                      </div>
                      {/* <div className="mb-5">
                        <Field
                          name="dob"
                          label="Date of birth"
                          component={this.renderDatePickerWithLabel1}
                          mandatory={false}
                          selected={moment()}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                          classNameLabel="font-medium text-secondary"
                        />
                      </div> */}
                      <div className="mb-5">
                        <p>Gender</p>
                        {_.map(this.state.genderOptions, (opt, index) => {
                          return (
                            <Field
                              name="gender"
                              label={opt.name}
                              value={opt.value}
                              //checked={index == 0 ? true : false}
                              // onChange={this._handleGenderchange.bind(this)}
                              lebelAlign="R"
                              type="radio"
                              component={this.renderFieldRadio1}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {/* <div className="mb-5 border-none">
                    <p>{LocaleStrings.seller_management_add_businesstype}</p>
                    {_.map(this.state.typeofbusiness, (opt, index) => {
                      return (
                        <Field
                          name="businesstype"
                          label={opt.name}
                          value={opt.value}
                          //checked={index == 0 ? true : false}
                          onChange={this.onbusinesstypeChange.bind(this)}
                          lebelAlign="R"
                          type="radio"
                          component={this.renderFieldRadio1}
                        />
                      );
                    })}
                  </div> */}
                  {initialValues.usertype !== "personal" ? (
                    <div>
                      {initialValues.usertype === "vendor" ? (
                        <div className=" border-none">
                          <Field
                            name="commercial_reg_doc"
                            label="Commercial Registration Document"
                            placeholder="Commercial Registration Document"
                            type="text"
                            component={this.renderFieldText}
                            mandatory="false"
                            isdisabled={true}
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-sm font-medium text-secondary"
                          />

                          <div className="flex justify-end">
                            <label
                              htmlFor="file-upload-commercial_reg_doc"
                              className="relative btn-primary-outline mt-2 cursor-pointer flex border-2 border-gray-200 rounded p-2">
                              <img
                                className={"h-4 w-4 "}
                                src={"../images/attachfile.svg"}
                              />
                              <span>{LocaleStrings.attach_files}</span>
                              <input
                                id="file-upload-commercial_reg_doc"
                                name="file-upload-commercial_reg_doc"
                                type="file"
                                className="sr-only"
                                onChange={(e) =>
                                  this._handleSelect(e, "commercial_reg_doc")
                                }
                              />
                            </label>
                          </div>
                        </div>
                      ) : (
                        <div className="border-none">
                          <Field
                            name="idproof"
                            label="Valid Id Proof"
                            placeholder="Valid Id Proof"
                            type="text"
                            component={this.renderFieldText}
                            mandatory="false"
                            isdisabled={true}
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-sm font-medium text-secondary"
                          />

                          <div className="flex justify-end">
                            <label
                              htmlFor="file-upload-idproof"
                              className="relative btn-primary-outline mt-2 cursor-pointer flex border-2 border-gray-200 rounded p-2">
                              <img
                                className={"h-4 w-4 "}
                                src={"../images/attachfile.svg"}
                              />
                              <span>{LocaleStrings.attach_files}</span>
                              <input
                                id="file-upload-idproof"
                                name="file-upload-idproof"
                                type="file"
                                className="sr-only"
                                onChange={(e) =>
                                  this._handleSelect(e, "idproof")
                                }
                              />
                            </label>
                          </div>
                        </div>
                      )}
                      {/* <div className=" border-none">
                        <Field
                          name="commercial_reg_doc"
                          label="Commercial Registration Document"
                          placeholder="Commercial Registration Document"
                          type="text"
                          component={this.renderFieldText}
                          mandatory="false"
                          isdisabled={true}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-medium text-secondary"
                        />

                        <div className="flex justify-end">
                          <label
                            htmlFor="file-upload-commercial_reg_doc"
                            className="relative btn-primary-outline mt-2 cursor-pointer flex border-2 border-gray-200 rounded p-2"
                          >
                            <img
                              className={"h-4 w-4 "}
                              src={"../images/attachfile.svg"}
                            />
                            <span>{LocaleStrings.attach_files}</span>
                            <input
                              id="file-upload-commercial_reg_doc"
                              name="file-upload-commercial_reg_doc"
                              type="file"
                              className="sr-only"
                              onChange={(e) =>
                                this._handleSelect(e, "commercial_reg_doc")
                              }
                            />
                          </label>
                        </div>
                      </div> */}

                      <div className=" border-none">
                        <Field
                          name="agreement_template"
                          label="Filled and signed agreement template"
                          placeholder="Filled and signed agreement template"
                          type="text"
                          component={this.renderFieldText}
                          mandatory="false"
                          isdisabled={true}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-medium text-secondary"
                        />

                        <div className="flex justify-end">
                          <label
                            htmlFor="file-upload-agreement_template"
                            className="relative btn-primary-outline mt-2 cursor-pointer flex border-2 border-gray-200 rounded p-2">
                            <img
                              className={"h-4 w-4 "}
                              src={"../images/attachfile.svg"}
                            />
                            <span>{LocaleStrings.attach_files}</span>
                            <input
                              id="file-upload-agreement_template"
                              name="file-upload-agreement_template"
                              type="file"
                              className="sr-only"
                              onChange={(e) =>
                                this._handleSelect(e, "agreement_template")
                              }
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mb-5  border-none">
                      <Field
                        name="dob"
                        label={LocaleStrings.dateofbirth}
                        component={this.renderDatePickerWithLabel1}
                        mandatory={false}
                        autoComplete="off"
                        // selected={moment()}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        classNameLabel=""
                      />
                    </div>
                  )}

                  <div className="pt-5">
                    <div className="flex justify-center">
                      <button
                        //   onClick={}
                        type="submit"
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 ">
                        <svg
                          className={classNames(
                            this.state.btnLoader ? "" : "sr-only",
                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        {LocaleStrings.save}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
function validate(values, ownProps) {
  // console.log('values',values)
  var errors = {};
  var gender = values["gender"];
  var email = values["email"];
  var username = values["username"];
  var businessname = values["businessname"];
  // var phone = values["phone"];

  var requiredFields = ["email", "username", "businessname"];

  requiredFields.forEach((field) => {
    // if (typeof values[field] != 'undefined'){
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
    // }
  });

  if (!email || email.trim() === "") {
    errors["email"] = "Required";
  }
  if (!businessname || businessname.trim() === "") {
    errors["businessname"] = "Required";
  }
  if (!username || username.trim() === "") {
    errors["username"] = "Required";
  }

  let countrycode = ownProps.phoneLengthCountry;

  if (countrycode == "974" && values.phone) {
    let phone = values.phone.replace(/[- )( +]/g, "");
    if (isValidQatarPhoneNumber("+" + phone) == false) {
      errors.phone = "Please enter valid phone number";
    }
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log("initialValues", state.sellerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    tabs: state.customermanagementTabs,
    sellerlistingtabs: state.sellerlistingtabs,
    newtablist: state.newtablist,
    customerlist: state.customerlist,
    initialValues: state.sellerlistingtabs,
    phoneLengthCountry: state.phoneLengthCountry,
  };
}

export default connect(mapStateToProps, {
  updateSeller,
  fetchSeller,
  uploadMediaLargeSellerDocs,
  updateProducts,
  updateVendorEmail,
  fetchsingleSeller,
  countryPhoneLength,
})(
  reduxForm({
    validate,
    form: "EditsellerForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EditsellerModal)
);
