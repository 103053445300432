import React, { Component } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import LocaleStrings from "../../../../languages/index";
import { Fragment, useState } from "react";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
  number,
} from "../../../../../common/base-component";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../../common/constants";
import {
  approveRefund,
  fetchallreturnorderList,
  fetchallcancelledorderlist,
  fetchallreplaceorderlist,
} from "../../actions/index";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { classNames } from "../../../../../common/common-functions";

class ApproveRejectOrders extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      btnLoader: false,
    };

    // All Binded Functions
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }
  componentDidMount() {}

  onSubmitForm(values) {
    // console.log('values',values)
    if (values.grand_total == "") {
      NotificationManager.error("Mandatory Fields are required");
      return false;
    }
    let status = "refunded";
    var data = this.props.data;
    if (this.props.newKey == "cancelled") {
      status = "cancelrefunded";
    }
    var approvevalues = {
      status: status,
      refundamount: values.grand_total,
      orderid: data.orderid,
      orderdetailsid: data.product.currentstatus.orderdetailid,
    };
    this.setState({ btnLoader: true });
    this.props.approveRefund(this.props.session, approvevalues, (callback) => {
      if (callback.success == 1) {
        this.setState({ btnLoader: false });
        NotificationManager.success("Successfully approved");
        this.props.onClose();
        if (this.props.newKey == "return") {
          this.props.fetchallreturnorderList(
            this.props.session,
            "return",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else if (this.props.newKey == "replace") {
          this.props.fetchallreplaceorderlist(
            this.props.session,
            "replace",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        } else {
          this.props.fetchallcancelledorderlist(
            this.props.session,
            "cancelled",
            10,
            1,
            "listing",
            "",
            (CallBackResponse) => {}
          );
        }
      } else {
        this.setState({ btnLoader: false });
        NotificationManager.error("Sorry cannot approve");
        this.props.onClose();
      }
    });
  }

  /*** Render Status Media ***/
  renderStatusMedia = (status_media) => {
    var item = _.map(status_media, (media, index) => {
      var imageUrl = `${BASE_IMAGES_URL}/orders/${media.medianame}?api_key=${APP_API_KEY}`;
      return (
        <div className="px-6">
          <img
            src={imageUrl}
            className="border-2 border-quaternary h-20 w-20 "
            alt="image"
          />
        </div>
      );
    });

    return item;
  };

  render() {
    var { data, initialValues } = this.props;
    // console.log("data", data);
    var { handleSubmit } = this.props;
    var modalheadText = "";
    if (this.props.newKey == "return") {
      modalheadText = "Approve Returned Orders";
    } else if (this.props.newKey == "replace") {
      modalheadText = "Approve Replace Orders";
    } else {
      modalheadText = "Approve Cancelled Orders";
    }
    var reason = "";
    var statusMedia = [];
    if (this.props.newKey == "return") {
      var statusArr = this.props.data.product.statuses;

      var cancelReason = _.filter(statusArr, {
        status: "return",
      });

      if (cancelReason.length > 0) {
        reason = cancelReason[0].returnreason;
      }
      if (cancelReason.length > 0 && cancelReason[0].status_media.length > 0) {
        statusMedia = cancelReason[0].status_media;
      }
    } else if (this.props.newKey == "cancelled") {
      var statusArr = this.props.data.product.statuses;

      var cancelReason = _.filter(statusArr, {
        status: "cancelled",
      });

      if (cancelReason.length > 0) {
        reason = cancelReason[0].cancelreason;
      }
      if (cancelReason.length > 0 && cancelReason[0].status_media.length > 0) {
        statusMedia = cancelReason[0].status_media;
      }
    }

    //   console.log('reason',reason)
    //  console.log('statusMedia',statusMedia)
    return (
      <>
        {/* <div className="transitionmodal"> */}
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isOpen}
            onClose={this.props.onClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: "0" }}>
                  <div className="grid grid-cols-2 border-b-2 border-gray-200 pb-4 sticky-header z-50">
                    <div className="flex flex-wrap content-center justify-center mt-4 ml-4">
                      {modalheadText}
                    </div>
                    <div className=" sm:block absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.props.onClose}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className=""
                    onSubmit={handleSubmit(this.onSubmitForm)}
                    encType="multipart/form-data">
                    <div className="p-6">
                      <div clasName="py-6">
                        Purchased By : {_.trim(data.purchasedby.username)}
                      </div>
                    </div>
                    <div className="p-6">
                      <div clasName="">Reason : {_.startCase(reason)}</div>
                    </div>
                    {!_.isEmpty(statusMedia) ? (
                      <div className="flex my-2">
                        {this.renderStatusMedia(statusMedia)}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="m-5">
                      <Field
                        name="grand_total"
                        mandatory="true"
                        placeholder="Refundable amount"
                        label="Refundable amount"
                        type="text"
                        component={this.renderAllfields}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div className="pt-5">
                      <div className="flex justify-center mb-5">
                        <button
                          onClick={this.props.onClose}
                          type="button"
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          {LocaleStrings.cancel}
                        </button>
                        <button
                          type="submit"
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "sr-only",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          Approve
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* </div> */}
      </>
    );
  }
}

function validate(val) {
  // console.log("form data", values);
  var errors = {};
  var grand_total = val["grand_total"];
  if (grand_total && number(grand_total)) {
    errors["grand_total"] = "Please enter a number";
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  let intVal = {};
  intVal.grand_total =
    ownProps.data.summary.grand_total - ownProps.data.summary.shipping;
  console.log("intVal", intVal);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    data: ownProps.data,
    initialValues: intVal,
  };
}

export default connect(mapStateToProps, {
  approveRefund,
  fetchallreturnorderList,
  fetchallcancelledorderlist,
  fetchallreplaceorderlist,
})(
  reduxForm({
    validate,
    form: "ApproveForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ApproveRejectOrders)
);

// export default connect(mapStateToProps, {approveRefund})(ApproveRejectOrders);
