import React from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../../common/base-component";
import _ from "lodash";

class Packages extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  render() {
    var { values } = this.props;
    // console.log("abcd", values);

    return (
      <>
        <div>{values.packagename_en}</div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
  };
}

export default connect(mapStateToProps, {})(Packages);
