import {
  INSTANCE_URL,
  itemCount,
  BASE_IMAGES_URL,
} from "../../../../common/constants";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  uploadEncodedFileLarge,
} from "../../../../common/network-call";
import LocaleStrings from "../../../languages/index";
import { ProductTextLocalized } from "../../../../common/common-functions";

export var ALL_ADD_LIST = "ALL_ADD_LIST";

// Data
export const ADDSMANAGEMENT_PRODUCTCATEGORY = "ADDSMANAGEMENT_PRODUCTCATEGORY";
export const ADDSMANAGEMENT_PRODUCTSUBCATEGORY =
  "ADDSMANAGEMENT_PRODUCTSUBCATEGORY";
export const ADDSMANAGEMENT_PRODUCTLIST = "ADDSMANAGEMENT_PRODUCTLIST";
export const EDITADVERTISEMENT_DATA = "EDITADVERTISEMENT_DATA";
export const ADDSMANAGEMENT_ALLPRODUCTLIST = "ADDSMANAGEMENT_ALLPRODUCTLIST";
export const OPEN_ADDMODAL = "OPEN_ADDMODAL";

export function fetchAllAdds(session, pageCount = 0, callback) {
  var perPage = 7;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;
  // var url = `${INSTANCE_URL}/api/v2/eventify/_table/speaker?related=appuser_by_speakeridfk&include_count=true&filter=eventid=${session.eventid}&order=createdon%20DESC${offSetQuery}`;
  var orderby = `createdon%20DESC`;
  // var url=`${INSTANCE_URL}/api/v2/shopez/_table/ads_management?related=productcategory_by_categoryids,productsubcategory_by_subcategoryids,products_by_productid`
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/ads_management?related=productcategory_by_categoryids,products_by_whereto_productid,productcategory_by_whereto_categoryid,productsubcategory_by_subcategoryids,products_by_productid&productcategory_by_categoryids.fields=id,categoryname_en,categoryname_ar&products_by_whereto_productid.fields=id,productname_en,productname_ar&productcategory_by_whereto_categoryid.fields=id,categoryname_en,categoryname_ar&productsubcategory_by_subcategoryids.fields=id,subcategoryname_en,subcategoryname_ar&products_by_productid.fields=id,productname_en,productname_ar&include_count=true${offSetQuery}&order=${orderby}`;
  // console.log(url)
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: ALL_ADD_LIST,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function searchAdds(session, search = "", pageCount = 0) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${itemCount}&offset=${offset}`;
  var searchQuery = `(whereto like %${search}%)OR(add_area like %${search}%)`;
  var filter = encodeURI(`(${searchQuery})`);
  // var url = `${INSTANCE_URL}/api/v2/eventify/_table/sponsor?related=membershiptype_by_categoryid,appuser_by_sponsoridfk&include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;
  // var url=`${INSTANCE_URL}/api/v2/shopez/_table/users?include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/ads_management?&filter=(${filter})&related=productcategory_by_categoryids,products_by_whereto_productid,productcategory_by_whereto_categoryid,productsubcategory_by_subcategoryids,products_by_productid&productcategory_by_categoryids.fields=id,categoryname_en,categoryname_ar&products_by_whereto_productid.fields=id,productname_en,productname_ar&productcategory_by_whereto_categoryid.fields=id,categoryname_en,categoryname_ar&productsubcategory_by_subcategoryids.fields=id,subcategoryname_en,subcategoryname_ar&products_by_productid.fields=id,productname_en,productname_ar&include_count=true${offSetQuery}`;

  return (dispatch) => {
    // dispatch(fetchAllAdds(session));
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log("SPONSOR: ", data);
        dispatch({
          type: ALL_ADD_LIST,
          payload: { data: data.resource, count: data.meta.count },
        });
      },
      (error) => {}
    );
  };
}

/**** Fetch Product Category data  ****/
export function fetchProductCategory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productcategory?fields=id,categoryname_en,categoryname_ar&filter=(isactive=1)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ADDSMANAGEMENT_PRODUCTCATEGORY,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Product Subcategory Data ****/
export function fetchProductSubcategory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productsubcategory?fields=id,parentid,subcategoryname_en,subcategoryname_ar&filter=(isactive=1)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ADDSMANAGEMENT_PRODUCTSUBCATEGORY,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

// export function fetchProductsByCateSubCate(session,callback) {
//   var url = `${INSTANCE_URL}/api/v2/shopez/_table/products`;

//     // Actual Data Call
//     return (dispatch) => {
//       getRequest(
//         url,
//         session,
//         dispatch,
//         (response) => {
//           // console.log("response,", response);
//           dispatch({
//             type: ADDSMANAGEMENT_PRODUCTLIST,
//             payload: { data: response.resource },
//           });

//           callback({ status: 1, message: LocaleStrings.api_success_status });
//         },
//         (error) => {
//           callback({ status: 0, message: LocaleStrings.something_went_wrong });
//         }
//       );
//     };

// }

export function fetchProductsByCateSubCate(
  session,
  selected_categoryid,
  selected_subcategoryid,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-datascript/listproductsbycategory`;

  if (
    (selected_categoryid == null || selected_categoryid == "") &&
    (selected_subcategoryid == null || selected_subcategoryid == "")
  ) {
    return (dispatch) => {
      dispatch({
        type: ADDSMANAGEMENT_PRODUCTLIST,
        payload: {},
      });
    };
  } else {
    if (selected_subcategoryid == null || selected_subcategoryid == "") {
      var object = {
        categoryid: selected_categoryid,
        discount: 1,
        usertype: "superadmin",
      };
    } else {
      var object = {
        categoryid: selected_categoryid,
        subcategoryid: selected_subcategoryid,
        usertype: "superadmin",
      };
    }

    // Actual Data Call
    return (dispatch) => {
      postRequest(
        url,
        object,
        session,
        dispatch,
        (response) => {
          dispatch({
            type: ADDSMANAGEMENT_PRODUCTLIST,
            payload: { data: response.data },
          });

          callback({ status: 1, message: LocaleStrings.api_success_status });
        },
        (error) => {
          callback({ status: 0, message: LocaleStrings.something_went_wrong });
        }
      );
    };
  }
}

export function uploadNewBanner(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else {
    var blob = new Blob([filecontent], { type: "image/jpeg" });
  }
  // console.log('blobimage',blob)
  dataMedia.append("files", blob, filename);
  // console.log('dataMedia',dataMedia)
  return (dispatch) => {
    uploadEncodedFileLarge(
      `${BASE_IMAGES_URL}/adbanners/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        // console.log('done?',data)
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function addAdvertisement(session, newAddArrdata, callback) {
  let url = `${INSTANCE_URL}/api/v2/shopez/_table/ads_management`;
  // console.log('newAddArrdata',newAddArrdata)
  let body = { resource: { ...newAddArrdata } };
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

// export function onEditadvertisement(editadmindata) {
//   return (dispatch) => {
//       dispatch({type: EDITADVERTISEMENT_DATA, payload: editadmindata});
//   }
// }

export function onEditadvertisement(ediAddobj = {}, language) {
  if (Object.keys(ediAddobj).length > 0) {
    if (ediAddobj.add_area == "category") {
      let id = ediAddobj.categoryids;
      ediAddobj.categoryids = {
        label: ProductTextLocalized(
          ediAddobj.productcategory_by_categoryids.categoryname_en,
          ediAddobj.productcategory_by_categoryids.categoryname_ar,
          language
        ),
        value: id.toString(),
      };
    } else if (ediAddobj.add_area == "subcategory") {
      let id = ediAddobj.subcategoryids;
      ediAddobj.subcategoryids = {
        label: ProductTextLocalized(
          ediAddobj.productsubcategory_by_subcategoryids.subcategoryname_en,
          ediAddobj.productsubcategory_by_subcategoryids.subcategoryname_ar,
          language
        ),
        value: id.toString(),
      };
    } else if (ediAddobj.add_area == "product") {
      let id = ediAddobj.productid;
      ediAddobj.productid = {
        label: ProductTextLocalized(
          ediAddobj.products_by_productid.productname_en,
          ediAddobj.products_by_productid.productname_ar,
          language
        ),
        value: id.toString(),
      };
    }

    if (ediAddobj.whereto == "category") {
      let id = ediAddobj.whereto_categoryid;
      ediAddobj.whereto_categoryid = {
        label: ProductTextLocalized(
          ediAddobj.productcategory_by_whereto_categoryid.categoryname_en,
          ediAddobj.productcategory_by_whereto_categoryid.categoryname_ar,
          language
        ),
        value: id.toString(),
      };
    } else if (ediAddobj.whereto == "product") {
      let id = ediAddobj.whereto_productid;
      ediAddobj.whereto_productid = {
        label: ProductTextLocalized(
          ediAddobj.products_by_whereto_productid.productname_en,
          ediAddobj.products_by_whereto_productid.productname_ar,
          language
        ),
        value: id.toString(),
      };
    }
  }
  // console.log("ediAddobj", ediAddobj);
  return { type: EDITADVERTISEMENT_DATA, payload: ediAddobj };
}

export function updateAdvertisement(session, newAddArrdata, callback) {
  let url = `${INSTANCE_URL}/api/v2/shopez/_table/ads_management`;
  // console.log('newAddArrdata',newAddArrdata)
  let body = { resource: { ...newAddArrdata } };
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

export function banAdvertisement(session, values, callback) {
  var body = { resource: { ...values } };
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/ads_management/`;
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch(fetchAllAdds(session));
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function deleteAds(session, values, callback) {
  var filter = encodeURI(`filter=(id=${values.id})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/ads_management?${filter}`;
  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        callback({ error: 0, result: data });
      },
      (error) => {
        callback({ error: 1, message: error });
      }
    );
  };
}

export function fetchallProducts(session, callback) {
  var filtertype = encodeURI(`(isactive=1)AND(isdeleted=0)AND(listed=1)`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/products?filter=(${filtertype})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ADDSMANAGEMENT_ALLPRODUCTLIST,
          payload: { data: response.resource },
        });

        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}

export function openAddModal(data) {
  console.log("data", data);
  return (dispatch) => {
    dispatch({ type: OPEN_ADDMODAL, payload: data });
  };
}
