import React from "react";
import { connect } from "react-redux";
import {
  BASE_IMAGES_URL,
  APP_API_KEY,
} from "../../../../../../common/constants";
import { BaseComponent } from "../../../../../../common/base-component";
import { ProductTextLocalized } from "../../../../../../common/common-functions";

class TransactionProducts extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      open: false,
      unbanopen: false,
    };
  }

  render() {
    var { values, language } = this.props;
    var img = `${BASE_IMAGES_URL}/vendor/variations/${values.productimage}?api_key=${APP_API_KEY}`;
    // console.log(img);
    return (
      <>
        <td
          className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
          style={{ display: "flex" }}>
          <div className="mr-2">
            <img src={img} style={{ height: "40px", width: "40px" }} />
          </div>
          <div>
            {ProductTextLocalized(
              values.productname_en.length > 35
                ? values.productname_en.substring(0, 25) + "..."
                : values.productname_en,
              values.productname_ar.length > 35
                ? values.productname_ar.substring(0, 25) + "..."
                : values.productname_ar,

              language
            )}
            {/* {values.productname_en} */}
          </div>
        </td>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    language: state.language,
  };
}

export default connect(mapStateToProps, {})(TransactionProducts);
