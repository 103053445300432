import React, { Component } from "react";
import { connect } from "react-redux";
import { SearchIcon } from "@heroicons/react/solid";
import LocaleStrings from "../../../../languages";
import _ from "lodash";
import BaseLoader from "../../../../../common/base-loader";
import AddnewDeliveryAgent from "./adddeliveryagent";
import {
  fetchAllDeliveryPartners,
  searchDeliverAgent,
} from "../../actions/index";
import ListDeliveryAgents from "./agent-list";
import { NotificationContainer } from "react-notifications";
import Pagination from "react-js-pagination";
import AllGlobalTabsList from "../globaltabs";
import { classNames } from "../../../../../common/common-functions";

class DeliveryAgent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveredorderSearch: "",
      loaderDesc: "Delivery Management",
      loading: true,
      pageNumber: 1,
      search: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchAllDeliveryPartners(
      this.props.session,
      this.state.pageNumber,
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  paginationCallback = (pageNumber) => {
    // alert(pageNumber)
    this.props.fetchAllDeliveryPartners(
      this.props.session,
      pageNumber,
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchAllDeliveryPartners(
        this.props.session,
        this.state.pageNumber,
        (CallBackResponse) => {}
      );
    } else {
      this.props.searchDeliverAgent(this.props.session, search);
    }
    this.setState({ search, pageNumber });
  };

  renderagentlist() {
    let pageDataLength = this.props.all_deliveryagentList.data.length;

    let { pageNumber } = this.state;
    var items = _.map(this.props.all_deliveryagentList.data, (values) => (
      <ListDeliveryAgents
        key={values.userid}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    return items;
  }

  render() {
    let { language } = this.props;
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <AllGlobalTabsList history={this.props.history} />
            <div className="relative md:w-6/12 xs:w-full text-ternary focus-within:text-secondary smallscreen-search mt-6 ml-6">
              <div
                className={classNames(
                  language == "en" ? "left-0 " : "right-0",
                  "absolute inset-y-0 flex items-center pointer-events-none px-1"
                )}>
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className={classNames(
                  language == "en" ? "pl-8 pr-3" : "pr-8 pl-3",
                  "block lg:w-6/12 xs:w-full sm:w-full h-full  py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                )}
                placeholder={LocaleStrings.search}
                type="search"
                name="search"
                // onChange={(e) =>
                //   this.setState({ customerSearchText: e.target.value })
                // }
                value={this.state.search}
                onChange={this.handleSearch}
              />
            </div>
            {_.isEmpty(this.props.all_deliveryagentList.data) ? (
              <>
                <div className="center-item lg:mt-48 text-lg text-ternary">
                  {LocaleStrings.global_setting_noDataAgents}
                </div>
              </>
            ) : (
              <>
                <div
                  className="grid gap-4 grid-rows-1 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1 mt-5 smallscreen-search"
                  style={{
                    overflowX: "hidden",
                    overflowY: "hidden",
                    display: "block",
                    marginTop: "-53px",
                  }}>
                  <AddnewDeliveryAgent />

                  <div className="panel-body m-6">
                    <div className="flex flex-col">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-custWhite">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {LocaleStrings.shippingcompany}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {LocaleStrings.packagesavailable}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {LocaleStrings.deliverykeys}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {LocaleStrings.action}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{this.renderagentlist()}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Pagination
                    activePage={this.state.pageNumber}
                    itemsCountPerPage={10}
                    totalItemsCount={this.props.all_deliveryagentList.count}
                    pageRangeDisplayed={10}
                    onChange={this.paginationCallback}
                  />
                  <NotificationContainer />
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, language } = state;

  return {
    session,
    user,
    language,
    all_deliveryagentList: state.all_deliveryagentList,
  };
}

export default connect(mapStateToProps, {
  fetchAllDeliveryPartners,
  searchDeliverAgent,
})(DeliveryAgent);
