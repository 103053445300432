import React, { Component } from "react";
import { connect } from "react-redux";
import { converDateIntoLocal } from "../../../../../../common/common-functions";
import _ from "lodash";
import { currencyCode, currencyRate } from "../../../../currency/action/index";

class DashBoardNeworderItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    var { values, localCurrency } = this.props;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    return (
      <>
        <tr className="bg-white border-b-2 border-gray-50 cursor-pointer hover-all">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {values.sku}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {/* ${parseFloat(grandTotal).toFixed(2) } */}
            {/* {currency} {parseFloat(grandTotal * currencyrate).toFixed(2)} */}
            {currency} {parseFloat(values.ordertotal).toFixed(2)}
          </td>

          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {_.startCase(values.status)}
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    localCurrency: state.currency,
  };
}
export default connect(mapStateToProps, {})(DashBoardNeworderItems);
