import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import DashboardReports from "./mainreports/index";
import DashboardStatistics from "./statistics/index";
import UserVendorBase from "./uservendorbase/index";
import ProductsNeworders from "./productsneworders";
import OngoingandCompletedoders from "./ongoingandcompletedoders";
import ReturnedandReplacedoders from "./returnedandreplacedoders";
import PendingAcceptedNegotiation from "./pendingacceptednegotiation";
import RejectedNegotiationsActiveauction from "./rejectednegotiationactiveauction";
import CompletedAuctionandPayments from "./compauctionandpayements";
import MostActiveandsold from "./mostactive/index";
import LocaleStrings from "../../../languages/index.js";
import { fetchAllstats } from "../actions/index";
import BaseLoader from "../../../../common/base-loader";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../common/base-component";

class Dashboardside extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: "Preparing Dashboard",
    };

    // All Binded Functions
  }
  componentDidMount() {
    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation.data, {
      href: answer,
    });

    if (singleNavigationRow == undefined) {
      this.props.history.push("/login");
    }
    this.props.fetchAllstats(this.props.session, (CallBackResponse) => {
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <div
            className="grid gap-4 grid-rows-1 px-6 md:grid-cols-3 sm:grid-rows-3 lg:grid-rows-1 "
            style={{
              overflowX: "auto",
              //overflowX:'hidden',
              display: "block",
            }}>
            <div className="mb-4 text-2xl font-semibold text-secondary">
              {LocaleStrings.dashboard_heading}
            </div>
            <div className="">
              <DashboardReports history={this.props.history} />
            </div>
            <div className="mt-6">
              <DashboardStatistics />
            </div>
            <div className="mt-6">
              <UserVendorBase />
            </div>
            <div className="mt-6">
              <ProductsNeworders />
            </div>
            <div className="mt-6">
              <OngoingandCompletedoders />
            </div>
            <div className="mt-6">
              <ReturnedandReplacedoders />
            </div>
            <div className="mt-6">
              <PendingAcceptedNegotiation />
            </div>
            <div className="mt-6">
              <RejectedNegotiationsActiveauction />
            </div>
            <div className="mt-6">
              <CompletedAuctionandPayments />
            </div>
            <div className="mt-6">
              <MostActiveandsold />
            </div>
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  navigation: state.sideBarMenus,
});

export default connect(mapStateToProps, {
  fetchAllstats,
})(Dashboardside);
